import Model from '@/models/model.js';
import UserRoleModel from '@/models/userRole.js';

/**
 * UserAccesses model class
 */
export default class UserAccessesModel extends Model {
    /**
     * id
     *
     * @type {Number}
     */
    id = 0;

    /**
     * name
     *
     * @type {String}
     */
    name = '';

    /**
     * accessDataTo
     *
     * @type {Date|null}
     */
    accessDataTo = null;

    /**
    * permission group
    *
    * @type {Number}
    */
    permissionGroup = 0;

    /**
    * access type
    *
    * @type {String}
    */
    accessType = '';

    /**
    * authorised sports
    *
    * @type {String[]}
    */
    authorisedSports = [];

    /**
    * class constructor
    *
    * @param {object} [data = {}]
    */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.userAccessId || 0;
            this.eqId = data.eqId || '';
            this.role = UserRoleModel.from(data.role || data.userRole);
            this.accessType = data.accessType || '';
            this.accessDataTo = this.toDate(data.allowAccessTillDate) || this.toDate(data.accessDataTo) || null;
            this.allowAccessToAllTournaments = data.allTournaments || data.allowAccessToAllTournaments || false;
            this.authorisedTournamentEqIds = data.tournamentEqIds || [];
            this.allowAccessToAllSeries = data.allSeries || false;
            this.allowAccessToAllTournamentTypes = data.allTournamentTypes || false;
            this.authorisedTournamentTypeEqIds = data.tournamentTypeEqIds || [];
            this.allowAccessToAllSports = data.allSports || false;
            this.authorisedSports = data.sports || [];
            this.allowAccessToAllMatches = data.allMatches || false;
            this.authorisedMatchEqIds = data.matchEqIds || [];
            this.authorisedSeriesEqIds = data.seriesEqIds || [];

            this.name = data.name || '';
            this.roleId = 0;
            this.userRoleId = data.userRoleId || 0;

            this.accessToAllTournamentsDisplay = this.allowAccessToAllTournaments ? 'All' : this.authorisedTournamentEqIds.length > 0 ? this.authorisedTournamentEqIds.length : 'None';
            this.accessToAllSeriesDisplay = this.allowAccessToAllSeries ? 'All' : this.authorisedSeriesEqIds.length > 0 ? this.authorisedSeriesEqIds.length : 'None';
            this.allowAccessToAllTournamentTypesDisplay = this.allowAccessToAllTournamentTypes ? 'All' : this.authorisedTournamentTypeEqIds.length > 0 ? this.authorisedTournamentTypeEqIds.length : 'None';
            this.allowAccessToAllSportsDisplay = this.allowAccessToAllSports ? 'All' : this.authorisedSports.length > 0 ? this.authorisedSports.length : 'None';
            this.allowAccessToAllMatchesDisplay = this.allowAccessToAllMatches ? 'All' : this.authorisedMatchEqIds.length > 0 ? this.authorisedMatchEqIds.length : 'None';

            this.enabledMatches = [];

            if (this.role) {
                this.permissionGroup = this.role.id;
                this.roleId = this.role.id;
                this.name = this.role.name;
            }
        }
    }

    /**
     * update user role
     *
     * @param {object} data
     */
    updateRole(data){
        this.role = UserRoleModel.from(data);
    }

    /**
     * check if feature is allowed for user
     *
     * @param {string} feature
     * @param {string[]} [actions]
     *
     * @returns {boolean}
     */
    isAllowed(feature, actions = []){
        return Boolean(
            this.role &&
            this.role.allows(feature, actions)
        );
    }

    toJSON(){
        if (this.accessType === 'SeriesAndTournament') {
            this.allowAccessToAllMatches = true;
            this.authorisedMatchEqIds = [];
        }
        else
        {
            this.allowAccessToAllTournaments = false;
            this.allowAccessToAllSeries = false;
            this.allowAccessToAllTournamentTypes = false;
            this.allowAccessToAllSports = false;
            this.authorisedTournamentEqIds = [];
            this.authorisedSeriesEqIds = [];
            this.authorisedTournamentTypeEqIds = [];
            this.authorisedSports = [];
        }

        return {
            userRoleId: this.permissionGroup,
            accessType: this.accessType,
            allowAccessTillDate: this.accessDataTo != null ? this.fromDate(this.accessDataTo) : null,
            allTournaments: this.allowAccessToAllTournaments,
            allSeries: this.allowAccessToAllSeries,
            allTournamentTypes: this.allowAccessToAllTournamentTypes,
            allSports: this.allowAccessToAllSports,
            allMatches: this.allowAccessToAllMatches,
            tournamentEqIds: this.authorisedTournamentEqIds,
            seriesEqIds: this.authorisedSeriesEqIds,
            tournamentTypeEqIds: this.authorisedTournamentTypeEqIds,
            sports: this.authorisedSports,
            matchEqIds: this.authorisedMatchEqIds,
        };
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
