<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="(sportId === constants.rankingTypes.mens ? $t('mensWorldRankings') : $t('womensWorldRankings')) + ' - ' + $tc('calculations', 2)"
        />

        <subNavigation v-bind:data-config="subnavigation" />

        <calculationList
            v-bind:title="$tc('calculation', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:sport="sportId"
            v-bind:rowRemovePermission="permissions.rankings.calculations.delete"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:buttons>
                <actionButton
                    class="m-0 btn-success float-right"
                    v-bind:to="{ name: 'administrationRankingCalculationsCalculationRatingCreate' }"
                >
                    {{ $t('createNew') }}
                </actionButton>
            </template>
        </calculationList>

        <weightingList
            v-bind:title="$t('eventWeightings')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:sport="sportId"
            v-bind:hide="hideElement(2)"
        />

        <rankingUnionList
            v-bind:title="$t('rankingUnions')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:sport="sportId"
            v-bind:permission="permissions.rankings.teams.update"
            v-bind:hide="hideElement(3)"
        >
            <template v-slot:buttons>
                <actionButton
                    class="m-0 btn-success float-right"
                    v-bind:permission="permissions.rankings.teams.create"
                    v-bind:to="{ name: 'administrationRankingCalculationsRankingUnionCreate' }"
                >
                    {{ $t('createNew') }}
                </actionButton>
            </template>
        </rankingUnionList>
    </div>
</template>

<script>
import subNavigation from '@/components/subnavigation.vue';
import detailsHeader from '@/components/detailsHeader.vue';
import rankingBase from '@/views/administration/rankings/base.vue';
import calculationList from '@/components/lists/administration/calculation.vue';
import weightingList from '@/components/lists/administration/weighting.vue';
import rankingUnionList from '@/components/lists/administration/rankingUnion.vue';

export default {
    name: 'administrationRankingCalculationsIndex',
    extends: rankingBase,
    components: {
        subNavigation,
        detailsHeader,
        calculationList,
        weightingList,
        rankingUnionList,
    },
    data() {
        return {
            rowProps: {},
            subnavigation: {
                active: 0,
                elements: {
                    0: { label: this.$t('all'), },
                    1: { label: this.$tc('calculation', 2), },
                    2: { label: this.$t('eventWeightings'), },
                    3: { label: this.$t('rankingUnions'), },
                }
            },
        }
    },
    methods: {
        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },
    },
};
</script>
