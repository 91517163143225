<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'userRimsForm',
    extends: formComponent,
    props: {
    },
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'permissionGroup',
                        type: 'vselectPermissionGroup',
                        placeholder: this.$t('select'),
                        label: this.$t('permissionGroup'),
                        required: true,
                    },
                    {
                        id: 'accessDataTo',
                        type: 'date',
                        label: this.$t('allowAccessUntil'),
                        placeholder: this.$t('selectDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: false,
                    },
                ]),
                {
                    id: 'accessType',
                    type: 'radioselect',
                    selected: 'seriesTournament',
                    options: {
                        SeriesAndTournament: this.$t('seriesAndTournaments'),
                        Matches: this.$tc('Match', 2),
                    },
                    // stacked: true,
                    plain: false,
                    label: this.$t('accessLevel'),
                    required: true,
                },
                this.createFormRow([
                    {
                        id: 'allowAccessToAllSeries',
                        type: 'checkbox',
                        text: this.$t('allSeries'),
                        disabled: this.readonly,
                        value: true,
                    },
                    {
                        id: 'allowAccessToAllTournaments',
                        type: 'checkbox',
                        text: this.$t('allTournaments'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'allowAccessToAllTournamentTypes',
                        type: 'checkbox',
                        text: this.$t('allTournamentTypes'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'allowAccessToAllSports',
                        type: 'checkbox',
                        text: this.$t('allSports'),
                        disabled: this.readonly,
                    },
                ], 'accessType === "SeriesAndTournament"'),
                this.createFormRow([
                    {
                        id: 'allowAccessToAllMatches',
                        type: 'checkbox',
                        text: this.$t('allMatches'),
                        disabled: this.readonly,
                    },
                ], 'accessType === "Matches"'),
                this.createFormRow([
                    {
                        id: 'authorisedSeriesEqIds',
                        type: 'vselectSeries',
                        placeholder: this.$t('select'),
                        label: this.$tc('series', 2),
                        closeOnSelect: false,
                        multiple: true,
                        valueProperty: 'eqId',
                        dependency: 'allowAccessToAllSeries === false',
                    },
                    {
                        id: 'authorisedTournamentEqIds',
                        type: 'vselectTournament',
                        placeholder: this.$t('select'),
                        label: this.$tc('tournament', 2),
                        closeOnSelect: false,
                        multiple: true,
                        valueProperty: 'eqId',
                        dependency: 'allowAccessToAllTournaments === false',
                    },
                    {
                        id: 'authorisedTournamentTypeEqIds',
                        type: 'vselectTournamentType',
                        placeholder: this.$t('select'),
                        label: this.$t('tournamentTypes'),
                        closeOnSelect: false,
                        multiple: true,
                        valueProperty: 'eqId',
                        dependency: 'allowAccessToAllTournamentTypes === false',
                    },
                    {
                        id: 'authorisedSports',
                        type: 'vselectSport',
                        placeholder: this.$t('select'),
                        label: this.$tc('sport', 2),
                        closeOnSelect: false,
                        multiple: true,
                        dependency: 'allowAccessToAllSports === false',
                    },
                ], 'accessType === "SeriesAndTournament"'),
                {
                    id: 'filteredMatches',
                    type: 'slot',
                    dependency: 'accessType === "matches"',
                },
                {
                    id: 'enabledMatches',
                    type: 'slot',
                    dependency: 'accessType === "Matches" && allowAccessToAllMatches === false',
                },
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
