<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editTournamentTypeGroup')"
            v-bind:waitFor="apiData.tournamentTypeGroup"
            v-bind:waitForRetry="reloadTournamentTypeGroup"
            v-on:submit="updateTournamentTypeGroup"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentTypeGroupList"
                    v-bind:loadingWhile="apiData.tournamentTypeGroupUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentTypeGroupUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <editTournamentTypeGroupForm
                    v-bind:headline="$t('tournamentTypeGroupInformation')"
                    v-bind:model="tournamentTypeGroup"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import editTournamentTypeGroupForm from '@/components/forms/editTournamentTypeGroup.vue';

import TournamentTypeGroupModel from '@/models/tournamentTypeGroup.js';

export default {
    name: 'administrationTournamentTypeGroupEdit',
    extends: base,
    components: {
        validations,
        editTournamentTypeGroupForm,
    },
    props: {
        dataTournamentTypeGroupId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                tournamentTypeGroup: this.loadTournamentTypeGroup(),
                tournamentTypeGroupUpdate: null,
            },
            tournamentTypeGroup: null,
            error: null,
        };
    },
    computed: {
        toTournamentTypeGroupList(){
            return { name: 'administrationTournamentTypeGroupIndex' };
        },
    },
    methods: {
        loadTournamentTypeGroup(){
            return this.$api.call.tournament.tournamentTypeGroupGetById(this.dataTournamentTypeGroupId).then(data => {
                this.tournamentTypeGroup = TournamentTypeGroupModel.from(data);
            });
        },
        reloadTournamentTypeGroup(){
            this.apiData.tournamentTypeGroup = this.loadTournamentTypeGroup();
        },
        updateTournamentTypeGroup(){
            this.apiData.tournamentTypeGroupUpdate = this.$api.call.tournament.tournamentTypeGroupUpdateById(
                this.dataTournamentTypeGroupId,
                this.tournamentTypeGroup.toJSON()
            ).then(response => {
                this.error = null;
                this.$router.push(this.toTournamentTypeGroupList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentTypeGroup update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
