<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToMatchDetailsLink"
        >
            <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
        </actionButton>

        <matchReportsHeader
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            v-bind:title="$t('matchTeamsheetSummary')"
            v-bind:data="teamsheets"
            v-bind:teamOneHeadCoach="teamOneHeadCoach"
            v-bind:teamTwoHeadCoach="teamTwoHeadCoach"
            v-bind:matchOfficials="matchOfficials"
        />

        <wrapper
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            noPadding noLine noShadow waitSilent
        >
            <h2 class="text-center mb-5 mt-5">{{ $t('lineup') }}</h2>
            <b-table
                v-bind:fields="tableFields"
                v-bind:items="lineup"
                class="m-0"
                responsive
                hover
                >
                <template #cell(leftDateOfBirth)="data">
                    <span v-html="data.value"></span>
                </template>
                <template #cell(rightDateOfBirth)="data">
                    <span v-html="data.value"></span>
                </template>
            </b-table>

            <h2 class="text-center mb-5 mt-5">{{ $t('subs') }}</h2>
            <b-table
                v-bind:fields="tableFields"
                v-bind:items="subs"
                class="m-0"
                responsive
                hover>
                <template #cell(leftDateOfBirth)="data">
                    <span v-html="data.value"></span>
                </template>
                <template #cell(rightDateOfBirth)="data">
                    <span v-html="data.value"></span>
                </template>
            </b-table>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import matchReportsHeader from '@/components/matchReportsHeader.vue';

import MatchTeamsheetModel from '@/models/matchTeamsheet.js';
import MatchModel from '@/models/match.js';

export default {
    name: 'commonMatchTeamsheet',
    extends: base,
    components: {
        icon,
        matchReportsHeader,
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                teamsheets: this.loadTeamsheets(),
            },
            matchTournaments: [],
            matchOfficials: [],
            teamsheets: null,
            teamsheetTeam1Lineup: null,
            teamsheetTeam1Replacement: null,
            teamsheetTeam2Lineup: null,
            teamsheetTeam2Replacement: null,
            teamOneHeadCoach: '',
            teamTwoHeadCoach: '',
            tableFields: [
                {
                    key: 'leftFrontRowEligible',
                    label: 'FRE',
                    headerTitle: this.$t('frontRowEligible'),
                    tdClass: 'match-teamsheet-fre',
                },
                {
                    key: 'leftWeight',
                    label: 'KG',
                    headerTitle: this.$t('kilograms'),
                    tdClass: 'match-teamsheet-icon',
                    class: 'text-center',
                },
                {
                    key: 'leftHeight',
                    label: 'CM',
                    headerTitle: this.$t('centimeters'),
                    tdClass: 'match-teamsheet-icon text-center',
                    class: 'text-center',
                },
                {
                    key: 'leftDateOfBirth',
                    label: 'BOD (Age)',
                    headerTitle: this.$t('dateOfBirth'),
                    class: 'text-center',
                    tdClass: 'match-teamsheet-summary-dob',
                },
                {
                    key: 'leftName',
                    label: this.$t('name'),
                    class: 'text-right',
                    tdClass: 'match-teamsheet-summary-name',
                },
                {
                    key: 'number',
                    label: this.$t('lineup'),
                    class: 'text-center',
                },
                {
                    key: 'rightName',
                    label: this.$t('name'),
                    class: 'text-left',
                    tdClass: 'match-teamsheet-summary-name',
                },
                {
                    key: 'rightDateOfBirth',
                    label: 'DOB (Age)',
                    headerTitle: this.$t('dateOfBirth'),
                    class: 'text-center',
                    tdClass: 'match-teamsheet-summary-dob',
                },
                {
                    key: 'rightHeight',
                    label: 'CM',
                    headerTitle: this.$t('centimeters'),
                    tdClass: 'match-teamsheet-icon',
                    class: 'text-center',
                },
                {
                    key: 'rightWeight',
                    label: 'KG',
                    headerTitle: this.$t('kilograms'),
                    tdClass: 'match-teamsheet-icon',
                    class: 'text-center',
                },
                {
                    key: 'rightFrontRowEligible',
                    label: 'FRE',
                    headerTitle: this.$t('frontRowEligible'),
                    tdClass: 'match-teamsheet-fre',
                },
            ],
            firstMatchInTournament: null,
        };
    },
    computed: {
        backToMatchDetailsLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
        lineup(){
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return [];
            }

            const teamsheetTeam1Lineup = this.teamsheets.teams[0].team.teamSheets.filter((item) => item.positionName !== 'Replacement');
            const teamsheetTeam2Lineup = this.teamsheets.teams[1].team.teamSheets.filter((item) => item.positionName !== 'Replacement');

            return this.formatTableData(teamsheetTeam1Lineup, teamsheetTeam2Lineup);
        },
        subs(){
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return [];
            }

            const teamsheetTeam1Subs = this.teamsheets.teams[0].team.teamSheets.filter((item) => item.positionName === 'Replacement');
            const teamsheetTeam2Subs = this.teamsheets.teams[1].team.teamSheets.filter((item) => item.positionName === 'Replacement');

            return this.formatTableData(teamsheetTeam1Subs, teamsheetTeam2Subs, true);
        },
    },
    methods: {
        loadTeamsheets(){
            return this.$api.call.match.matchReportsTeamsheetsGetByMatchId(this.dataMatchId).then(result => {
                this.teamsheets = new MatchTeamsheetModel(result);

                const teamOneHeadCoach = this.teamsheets.teams[0].team.teamSheets.filter((item) => item.positionName === 'Head Coach');
                this.teamOneHeadCoach = teamOneHeadCoach[0]?.displayName || '';

                const teamTwoHeadCoach = this.teamsheets.teams[1].team.teamSheets.filter((item) => item.positionName === 'Head Coach');
                this.teamTwoHeadCoach = teamTwoHeadCoach[0]?.displayName || '';

                if(this.teamsheets.match.tournaments.length){
                    this.$api.call.match.matchSearch({
                        tournamentEqId: this.teamsheets.match.tournaments[0].eqId,
                        paging: {
                            pageIndex: 0,
                            pageSize: 100
                        }
                    }).then(matchData => {
                        const matches = (matchData ? (matchData.data || []) : []).map(data => new MatchModel(data));

                        if(matches.length){
                            const sortedMatches = matches.sort(function(a, b){ return a.kickOff - b.kickOff });
                            this.firstMatchInTournament = sortedMatches[0];
                        }
                    });
                }

                return this.getMatchOfficials();
            });
        },
        reloadTeamsheets(){
            this.apiData.teamsheets = this.loadTeamsheets();
        },
        getMatchOfficials() {
            return this.$api.call.match.matchOfficialsGetByMatchId(this.dataMatchId).then(result => {
                this.matchOfficials = (result || []).map(official => ({
                    position: official.positionName,
                    name: official.displayName,
                    union: official.unionName,
                }));
            });
        },
        formatTableData(players1, players2, isSub = false){
            const map = {};
            const fillMap = (player, side) => {
                const number = player.getPlayerAttribute('shirtNumber');
                if(!number){
                    return;
                }
                if(!map[number]){
                    map[number] = {
                        number,
                        left: null,
                        right: null,
                    };
                }
                map[number][side] = player;
            };

            players1.forEach(player => fillMap(player, 'left'));
            players2.forEach(player => fillMap(player, 'right'));

            return Object.entries(map).sort(([number1], [number2]) => number1 - number2).map(([key, player]) => player).map(data => ({
                leftFrontRowEligible: this.formatFrontRowEligibility(data.left),
                leftWeight: data.left?.getPlayerAttribute('weight') || data.left?.weight || '',
                leftHeight: data.left?.getPlayerAttribute('height') || data.left?.height || '',
                leftDateOfBirth: this.formatDateOfBirth(data.left),
                leftName: this.formatName(data.left, true),
                number: data.number,
                rightName: this.formatName(data.right),
                rightDateOfBirth: this.formatDateOfBirth(data.right),
                rightHeight: data.right?.getPlayerAttribute('height') || data.right?.height || '',
                rightWeight: data.right?.getPlayerAttribute('weight') || data.right?.weight || '',
                rightFrontRowEligible: this.formatFrontRowEligibility(data.right),
            }));
        },
        formatName(teamsheet, isLeftSide = false){
            if(!teamsheet){
                return '';
            }

            let name = teamsheet.getName();

            if(teamsheet.getPlayerAttribute('isCaptain')){
                if(isLeftSide){
                    name = '(c) ' + name;
                }
                else
                {
                    name += ' (c)';
                }
            }

            return name;
        },
        formatFrontRowEligibility(teamsheet){
            if(!teamsheet){
                return '';
            }

            let frontRowEligibilities = '';

            const isTightHead = teamsheet.getPlayerAttribute('isTighthead') || teamsheet.isTighthead || false;
            const isHooker = teamsheet.getPlayerAttribute('isHooker') || teamsheet.isHooker || false;
            const isLoosehead = teamsheet.getPlayerAttribute('isLoosehead') || teamsheet.isLoosehead || false;

            const values = [isTightHead, isHooker, isLoosehead];
            const labels = ['TH', 'HK', 'LH'];
            const seperator = ', ';

            for(let i = 0; i < values.length; i++){
                if(values[i]){
                    frontRowEligibilities += labels[i] + seperator;
                }
            }

            if(frontRowEligibilities.length){
                frontRowEligibilities = frontRowEligibilities.substring(0, frontRowEligibilities.length - seperator.length);
            }

            return frontRowEligibilities;
        },
        formatDateOfBirth(teamsheet){
            if(!teamsheet){
                return '';
            }

            let dateOfBirth = this.$fd(teamsheet.dateOfBirth, 'date') || '';

            if(this.teamsheets?.match?.kickOff){
                const ageOnMatchDay = teamsheet.getAge(this.teamsheets.match.kickOff);

                if(ageOnMatchDay){
                    if (ageOnMatchDay < 19){
                        dateOfBirth += ' <span class="text-danger">(' + ageOnMatchDay + ')</span>';
                    }
                    else
                    {
                        dateOfBirth += ' (' + ageOnMatchDay + ')';
                    }
                }
            }

            return dateOfBirth;
        },
    },
};
</script>
