<template>
    <div class="pageContent">
        <locationList
            v-bind:title="$tc('place', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.locations.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'administrationLocationCreate' }"
                >
                    {{ $t('createPlace') }}
                </actionButton>
            </template>
        </locationList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import locationList from '@/components/lists/location.vue';

export default {
    name: 'administrationLocationIndex',
    extends: base,
    components: {
        locationList,
    },
    data(){
        return {
            rowProps: {
                showVenue: true,
                showEdit: true,
                showDelete: true,
                showSelect: false,
            },
        };
    },
};
</script>
