<script>
import pickModal from '@/components/modals/pick.vue';
import teamList from '@/components/lists/team.vue';

/**
 * team pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'teamPickModal',
    extends: pickModal,
    components: {
        teamList,
    },
    props: {
        showCancel: {
            type: Boolean,
            required: false,
            default: true,
        },
        showClearSelection: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data(){
        return {
            listElement: teamList,
        };
    },
};
</script>
