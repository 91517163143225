<template>
    <div className="pageContent">
        <list
            v-bind:items="persons"
            v-bind:title="$t('mergePersonnel')"
            v-on:submit="mergePersons"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    type="submit"
                    v-bind:disabled="!hasRequiredPersonCount"
                    v-bind:loadingWhile="apiData.mergePersons"
                >
                    {{ $t('merge') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations
                    v-bind:warnings="[$t('mergePersonnelNotice')]"
                    v-bind:errors="errorMessages"
                />
                <personRow
                    showUnion showPersonId showRemove
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <personRow
                    showUnion showPersonId showRemove
                    removeSilent
                    v-bind:person="person"
                    v-bind:role="role"
                    v-on:remove="removePerson"
                />
            </template>
            <template v-slot:empty>
                <div/>
            </template>
            <template v-slot:footer>
                <simpleRow
                    v-bind:text="$t('clickToAdd', { type: $tc('person') })"
                    showAdd
                    v-b-modal.personPick
                />
            </template>
        </list>

        <personPickModal
            id="personPick"
            multiselect
            v-bind:title="$t('searchPersons')"
            v-on:select="addPerson"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';

import personRow from '@/components/rows/person.vue';
import simpleRow from '@/components/rows/simple.vue';

import personPickModal from '@/components/modals/pick/person.vue';

export default {
    name: 'administrationManagementPositionsIndex',
    extends: base,
    components: {
        validations,
        personRow,
        simpleRow,
        personPickModal,
    },
    data(){
        return {
            apiData: {
                mergePersons: null,
            },
            persons: [],
            error: null,
        };
    },
    computed: {
        hasRequiredPersonCount(){
            return (this.persons.length === 2);
        },
        errorMessages(){
            if(!this.error){
                return undefined;
            }

            const messages = this.error.getErrorMessages();

            const mergeFrom = (this.persons[0] || { label(){ return '' } });
            const mergeTo = (this.persons[1] || { label(){ return '' } });
            const mergeFromStr = 'EqId ' + (mergeFrom.eqId || '');
            const mergeToStr = 'EqId ' + (mergeTo.eqId || '');

            if(!messages.length && this.error.message && this.error.message.includes(mergeFromStr) && this.error.message.includes(mergeToStr)){
                return [
                    this.error.message.replace(mergeFromStr, mergeFrom.label()).replace(mergeToStr, mergeTo.label())
                ];
            }
            else if(!messages.length){
                return [
                    this.$t('requestError')
                ];
            }

            return messages;
        },
    },
    methods: {
        addPerson(persons){
            persons.forEach(element => {
                this.persons.push(element);
            });

            this.apiData.mergePersons = null;
            this.error = null;
        },
        removePerson(data){
            this.persons = this.persons.filter(person => person.id !== data.person.id);

            this.apiData.mergePersons = null;
            this.error = null;
        },
        mergePersons(){
            const data = {
                sourcePersonEqId: ((this.persons[0] || {}).eqId || ''),
                destinationPersonEqId: ((this.persons[1] || {}).eqId || ''),
            };

            this.apiData.mergePersons = this.$api.call.person.personMerge(data).then(response => {
                this.error = null;
                this.goToPersonDetail((this.persons[1] || {}).id);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('person merge failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
        goToPersonDetail(id){
            this.$router.push({ name: 'commonPersonDetail', params: { dataPersonId: id } });
        },
    },
};
</script>
