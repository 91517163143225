<template>
    <div class="pageContent">
        <list
            v-bind:title="$t('arrangeOrderingParameters')"
            v-bind:items="orderParameters"
            v-bind:waitFor="apiData.tournamentStandingType"
            v-bind:waitForRetry="reloadTournamentStandingType"
            v-on:change="updateParameterOrder"
            v-on:submit="saveOrdering"
            doubleButtons
            isForm
            draggable
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentStandingTypeDetail"
                    v-bind:loadingWhile="apiData.tournamentStandingTypeUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentStandingTypeUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations
                    v-bind:notices="[$t('dragDropParametersDesiredOrder')]"
                    v-bind:errors="error ? error.getErrorMessages($t('requestError')) : undefined"
                />
                <tournamentStandingTypeParameterRow
                    showOrder showName showParameterTypeName
                    isHeadline
                />
            </template>
            <template v-slot="{ item: parameters, role }">
                <tournamentStandingTypeParameterRow
                    showOrder showName showParameterTypeName
                    v-bind:parameters="parameters"
                    v-bind:standingTypeId="dataTournamentStandingTypeId"
                    v-bind:standingTypeName="tournamentStandingType.name"
                    v-bind:role="role"
                />
            </template>
        </list>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import tournamentStandingTypeParameterRow from '@/components/rows/administration/tournamentStandingTypeParameter.vue';

import TournamentStandingTypeModel from '@/models/tournamentStandingType.js';

export default {
    name: 'administrationTournamentStandingTypeOrderingParameter',
    extends: base,
    components: {
        validations,
        tournamentStandingTypeParameterRow,
    },
    props: {
        dataTournamentStandingTypeId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            rowProps: {
                showOrder: true,
                showEventStandingType: true,
                showParameterTypeName: true,
            },
            apiData: {
                tournamentStandingType: this.loadTournamentStandingType(),
                tournamentStandingTypeUpdate: null,
            },
            tournamentStandingType: null,
            error: null,
            orderParameters: [],
        };
    },
    computed: {
        toTournamentStandingTypeDetail(){
            return { name: 'administrationTournamentStandingTypeDetail', params: { dataTournamentStandingTypeId: this.dataTournamentStandingTypeId } };
        },
    },
    methods: {
        loadTournamentStandingType(){
            return this.$api.call.tournament.tournamentStandingTypeGetById(this.dataTournamentStandingTypeId).then(data => {
                if (data !== undefined) {
                    this.tournamentStandingType = new TournamentStandingTypeModel(data);

                    this.orderParameters = this.tournamentStandingType.orderParameters;
                }
            });
        },
        reloadTournamentStandingType(){
            this.apiData.tournamentStandingType = this.loadTournamentStandingType();
        },
        updateParametersPositions(){
            this.orderParameters.forEach((parameter, i) => {
                parameter.value = i + 1;
            });
        },
        updateParameterOrder(parameters){
            this.orderParameters = parameters;
            this.updateParametersPositions();
        },
        saveOrdering(){
            const dataToSave = {
                standingTypeId: this.dataTournamentStandingTypeId,
                parameterIds: [],
            };

            this.orderParameters.forEach((parameter, i) => {
                dataToSave.parameterIds.push(parameter.id);
            });

            this.apiData.tournamentStandingTypeUpdate = this.$api.call.tournament.tournamentStandingTypeParameterOrderUpdate(dataToSave).then(data => {
                if (data !== undefined) {
                    this.$router.push(this.toTournamentStandingTypeDetail);
                }
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentStandingType order update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
};
</script>
