<script>
import vselectElement from '../vselect.vue';
import RankingRun from '@/models/rankingRun.js';

/**
 * vselect element for RankingRun items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectRankingRunElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            // add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                let sport = null;
                let year = null;

                if(config.dataDependency && config.dataDependency.sport){
                    sport = this.dataAllValues.sport;

                    //force reloading options when sport changes
                    this.addOptionReloadListener('dataAllValues.sport', true);
                }
                else if(config.data && config.data.sport){
                    sport = config.data.sport;
                }

                if(config.dataDependency && config.dataDependency.year){
                    year = this.dataAllValues.year;

                    //force reloading options when year changes
                    this.addOptionReloadListener('dataAllValues.year', true);
                }
                else if(config.data && config.data.year){
                    year = config.data.year;
                }

                if (year) {
                    return this.$api.call.rankings.runGetBySportAndYear(sport, year)
                        .then(result => (result || []).map(data => new RankingRun(data)))
                        .then(items => items.filter(item => !text || item.label().includes(text)))
                        .then(filteredItems => filteredItems.sort(function(a, b){ return b.effectiveDate.getTime() - a.effectiveDate.getTime() }));
                }

                return Promise.resolve([]);
            };

            return config;
        },
    },
};
</script>
