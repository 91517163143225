import Api from '@/services/api.js';
import Log from '@/services/log.js';
import ModelProxy from '@/models/proxy.js';

/**
 * base model class
 * @abstract
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class Model {
    /**
     * api instance
     *
     * @type {Api}
     */
    _api;

    /**
     * log service instance
     *
     * @type {Log}
     */
    _log;

    /**
     * static api instance
     * @static
     *
     * @type {Api}
     */
    static _api;

    /**
     * static log service instance
     * @static
     *
     * @type {Log}
     */
    static _log;

    /**
     * list of changes made to public properties
     * key: property name
     * value: old value
     *
     * Provided by ModelProxy
     * @see ./proxy.js
     *
     * @type {object}
     */
    _changedProperties = {};

    /**
     * model object id
     *
     * @type {number|null}
     */
    id = null;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        if(data){
            this.id = data.id || null;
        }
    }

    /**
     * vselect option label
     * @abstract
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return '';
    }

    /**
     * transform date data to date object. will keep date objects as is.
     *
     * @param {string|Date} data
     * @param {boolean}     [nullable = true]
     * @param {boolean}     [getOffset = false]
     *
     * @returns {Date|number}
     */
    toDate(data, nullable = true, getOffset = false){
        //detect empty input
        if(nullable && !data){
            return null;
        }

        //keep if already date object
        if(data instanceof Date){
            if(getOffset){
                return data.getTimezoneOffset() * -1;
            }
            return data;
        }

        //check if date format is with timezone, if so remove to force to interpret as UTC
        let offset = null;
        if(typeof data === 'string' && data.toLowerCase().endsWith('z')){
            data = data.slice(0, -1);
        }
        else if(typeof data === 'string' && (offset = data.match(/([-+]\d{1,2}:\d{1,2})/g)?.shift())){
            data = data.substring(0, data.length - offset.length);
        }

        //convert to object
        const date = new Date(data);

        //detect invalid date
        if(nullable && isNaN(date.getTime())){
            return null;
        }

        //return offset (in minutes) if needed
        if(getOffset && typeof offset === 'string'){
            let offsetNegative = offset[0] === '-';
            offset = offset.substring(1).split(':');
            offset = parseInt(offset.shift() || 0, 10) * 60 + parseInt(offset.shift() || 0, 10);
            if(isNaN(offset)){
                return null;
            }

            if(offsetNegative){
                return -offset;
            }
            return offset;
        }
        else if(getOffset){
            return null;
        }

        return date;
    }

    toDateTime(data){
        if(!data){
            return '';
        }

        if(!(data instanceof Date)){
            data = this.toDate(data);
        }

        return this.fromDate(data)?.match(/\d{2}:\d{2}:\d{2}/)?.shift() || '';
    }

    toDateOffset(data){
        return this.toDate(data, true, true);
    }

    toLocalDate(data, nullable = true){
        //detect empty input
        if(nullable && !data){
            return null;
        }

        //keep if already date object
        if(data instanceof Date){
            return data;
        }

        //check if date format without timezone, if so add to allow displaying as local timezone
        const dateRegex = /^\d{4}-(0\d|1[0-2])-([0-2]\d|3[01])T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
        if(typeof data === 'string' && dateRegex.test(data)){
            data += 'Z';
        }

        //convert to object
        const date = new Date(data);

        //detect invalid date
        if(nullable && isNaN(date.getTime())){
            return null;
        }

        return date;
    }

    /**
     * transform date object to date data
     *
     * @param {Date}    date
     * @param {boolean} [nullable = true]
     *
     * @returns {String}
     */
    fromDate(date, nullable = true){
        //detect empty input
        if(nullable && !date){
            return null;
        }

        //needs to be date
        if(!(date instanceof Date)){
            return null;
        }

        //convert to UTC datetime string
        return (new Date(date.getTime() - date.getTimezoneOffset() * 60000)).toJSON();
    }

    fromDateTime(date, time, offset){
        if(!(date instanceof Date)){
            return null;
        }

        //clone date object
        date = new Date(date);

        //set time if given
        if(typeof time === 'string' || time instanceof String){
            time = time.split(':');
            date.setHours(time.shift() || 0, time.shift() || 0, time.shift() || 0);
        }

        //convert to string
        let result = this.fromDate(date);

        //set offset if given
        if(result && offset){
            offset = parseInt(offset, 10);
            offset = (offset < 0 ? '-' : '+') + String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0') + ':' + String(Math.abs(offset) % 60).padStart(2, '0');

            result = result.replace('Z', offset);
        }

        return result;
    }

    /**
     * set api instance
     *
     * @throws TypeError
     *
     * @param {Api} api
     */
    setApi(api){
        if(!(api instanceof Api)){
            throw TypeError('unable to set model api property: invalid argument');
        }

        this._api = api;
    }

    /**
     * set log service instance
     *
     * @throws TypeError
     *
     * @param {Log} log
     */
    setLog(log){
        if(!(log instanceof Log)){
            throw TypeError('unable to set model log property: invalid argument');
        }

        this._log = log;
    }

    /**
     * static set api instance
     * @static
     *
     * @throws TypeError
     *
     * @param {Api} api
     */
    static setApi(api){
        if(!(api instanceof Api)){
            throw TypeError('unable to set static model api property: invalid argument');
        }

        this._api = api;
    }

    /**
     * static set log service instance
     * @static
     *
     * @throws TypeError
     *
     * @param {Log} log
     */
    static setLog(log){
        if(!(log instanceof Log)){
            throw TypeError('unable to set static model log property: invalid argument');
        }

        this._log = log;
    }

    /**
     * get object property
     *
     * @param {string} key
     *
     * @throws TypeError
     *
     * @returns {*}
     */
    getProperty(key){
        if(!key){
            throw TypeError('unable to get model property: invalid parameter');
        }

        return this[key];
    }

    /**
     * get object properties. returns all if no specifics given.
     *
     * @param {string[]} [keys = null]
     *
     * @returns {object}
     */
    getProperties(keys = null){
        const result = {};

        if(!keys){
            keys = Object.keys(this).filter(key => {
                return (key[0] !== '_');
            });
        }

        if(typeof keys !== 'object' || typeof keys.map !== 'function'){
            throw TypeError('unable to get model properties: invalid parameter');
        }

        keys.forEach(key => {
            result[key] = this.getProperty(key);
        });

        return result;
    }

    /**
     * create shallow clone
     *
     * @returns {Model}
     */
    clone(){
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    /**
     * convert data to model, or simply return if already.
     * @static
     *
     * @param {object}  data
     * @param {boolean} [nullable = true]
     * @param {...*} [params]
     *
     * @returns {Model|null}
     */
    static from(data, nullable = true, ...params){
        if(nullable && !data){
            return null;
        }

        if(data instanceof this){
            return data;
        }

        let object = new this(data, ...params);

        object = ModelProxy.init(object);

        //object.setApi(this._api);
        //object.setLog(this._log);

        return object;
    }

    /**
     * the method removes objects with all properties null
     * example:
     * if object is
     *  {
     *     "passport": {
                "passportScanUrl": null,
                "passportId": null
            },
            "person": {
                "placeOfBirthLocationEqId": null,
                "isPlayerOfFinal": false,
                "isImpactPlayer": false
            },
     *  }
    *
     * the method returns
     * {
     *     "person": {
                "placeOfBirthLocationEqId": null,
                "isPlayerOfFinal": false,
                "isImpactPlayer": false
            },
     * }
     * @param {object} object
     * @returns {object}
     */
    removeObjectsWherePropertiesAreNull(object){
        for (const [key, value] of Object.entries(object)) {
            if (Object.values(value).every(x => x === null)) {
                delete object[key];
            }
        }

        return object;
    }
}
