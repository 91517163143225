<script>
import formComponent from '@/components/form.vue';

import constants from '@/constants.js';

/**
 * component
 */
export default {
    name: 'personBiographyForm',
    extends: formComponent,
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'firstName',
                        type: 'text',
                        label: this.$t('firstName'),
                        disabled: true,
                    },
                    {
                        id: 'lastName',
                        type: 'text',
                        label: this.$t('lastName'),
                        disabled: true,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'nickname',
                        type: 'text',
                        label: this.$t('nickname'),
                        placeholder: this.$t('nickname'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'namePronunciation',
                        type: 'text',
                        label: this.$t('namePronunciation'),
                        placeholder: this.$t('namePronunciation'),
                        disabled: this.readonly,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'startPlaying',
                        type: 'text',
                        label: this.$t('firstGame'),
                        placeholder: this.$t('firstGame'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'rugbyFirstSteps',
                        type: 'text',
                        label: this.$t('debutInWorldSeries'),
                        placeholder: this.$t('debutInWorldSeries'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'highlightsCareer',
                        type: 'text',
                        label: this.$t('careerHighlight'),
                        placeholder: this.$t('careerHighlight'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'favouriteSeriesLocation',
                        type: 'text',
                        label: this.$t('favouriteLocation'),
                        placeholder: this.$t('favouriteLocation'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'favouriteAthletes',
                        type: 'text',
                        label: this.$t('otherSportsPersonsForInspiration'),
                        placeholder: this.$t('otherSportsPersonsForInspiration'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'rugbyPlayingFamily',
                        type: 'text',
                        label: this.$t('otherFamilyWrPlayer'),
                        placeholder: this.$t('otherFamilyWrPlayer'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'bestAdviceGiven',
                        type: 'text',
                        label: this.$t('bestAdviceGiven'),
                        placeholder: this.$t('bestAdviceGiven'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'favouriteMovie',
                        type: 'text',
                        label: this.$t('favouriteMovie'),
                        placeholder: this.$t('favouriteFood'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'favouriteFood',
                        type: 'text',
                        label: this.$t('favouriteFood'),
                        placeholder: this.$t('favouriteMovie'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'tournamentsPlayedPoints',
                        type: 'number',
                        label: this.$t('numberOfTournamentsPlayed'),
                        placeholder: this.$t('numberOfTournamentsPlayed'),
                        disabled: this.readonly,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'height',
                        type: 'number',
                        label: this.$t('height'),
                        placeholder: this.$t('height'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'weight',
                        type: 'number',
                        label: this.$t('weight'),
                        placeholder: this.$t('weight'),
                        disabled: this.readonly,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'hand',
                        type: 'vselect',
                        label: this.$t('dominantHand'),
                        placeholder: this.$t('dominantHand'),
                        options: [
                            {
                                id: constants.dominantSides.left,
                                label: this.$t('left'),
                            },
                            {
                                id: constants.dominantSides.right,
                                label: this.$t('right'),
                            },
                            {
                                id: constants.dominantSides.both,
                                label: this.$t('ambidextrous'),
                            },
                        ],
                        disabled: this.readonly,
                    },
                    {
                        id: 'foot',
                        type: 'vselect',
                        label: this.$t('dominantFoot'),
                        placeholder: this.$t('dominantFoot'),
                        options: [
                            {
                                id: constants.dominantSides.left,
                                label: this.$t('left'),
                            },
                            {
                                id: constants.dominantSides.right,
                                label: this.$t('right'),
                            },
                            {
                                id: constants.dominantSides.both,
                                label: this.$t('twoFooted'),
                            },
                        ],
                        disabled: this.readonly,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                footDexterity: 'foot',
                handDexterity: 'hand',
                weight: 'height',
                height: 'weight',
                numberOfTournamentsPlayerdPoints: 'tournamentsPlayedPoints',
                nameOfPrononciation: 'namePronunciation',
                favoriteMovie: 'favouriteMovie',
                favoriteFood: 'favouriteFood',
                bestPeaceOfAdvice: 'bestAdviceGiven',
                otherPlayingFamilyMembers: 'rugbyPlayingFamily',
                inspirationAthelets: 'favouriteAthletes',
                favoriteSeriesLocation: 'favouriteSeriesLocation',
                highlightOfCarrer: 'highlightsCareer',
                worldSeriesDebut: 'rugbyFirstSteps',
                startsPlayerRugbyAt: 'startPlaying',
            };
        },
    },
};
</script>
