<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('logout')"
            v-bind:waitFor="loading"
        >
            <div class="m-3 text-center">
                <p>
                    {{ $t('loggedOut') }}
                </p>

                <b-button v-bind:to="homeRoute">
                    {{ $t('login')}}
                </b-button>
            </div>
        </wrapper>
    </div>
</template>

<script>
import wrapper from '@/components/wrapper.vue';
import constants from '@/constants.js';

export default {
    name: 'logoutPage',
    components: {
        wrapper,
    },
    data(){
        return {
            loading: null,
        };
    },
    computed: {
        homeRoute(){
            return { name: constants.routeNames.home };
        },
    },
    created(){
        if(this.$auth && this.$auth.isAuthorized()){
            this.loading = this.$auth.logout();
        }
    },
}
</script>
