import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class TermsOfParticipationService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/terms-of-participation-service/api/';

    /**
     * base path parts
     *
     * @type {object}
     */
    _paths = {
        teamDeclaration: 'team-declaration',
    };

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // Competition

    /**
     *
     */
    competitionGetById(id){
        return this.get(['competition', id]);
    }

    /**
     *
     */
    competitionUpdateById(id, data){
        return this.put(['competition', id], data);
    }

    // CompetionTermsOfParticipation

    /**
     *
     */
    competionTermsOfParticipationGetById(id){
        return this.get(['competion-terms-of-participation', id]);
    }

    /**
     *
     */
    competionTermsOfParticipationGetAllByCompetitionId(id){
        return this.get(['competion-terms-of-participation', 'competition-id', id]);
    }

    /**
     *
     */
    competionTermsOfParticipationUpdateById(id, data){
        return this.put(['competion-terms-of-participation', id], data);
    }

    /**
     *
     */
    competionTermsOfParticipationDeleteById(id){
        return this.delete(['competion-terms-of-participation', id]);
    }

    // TermsOfParticipation

    /**
     *
     */
    termsOfParticipationGetAll(){
        return this.get(['terms-of-participation']);
    }

    /**
     *
     */
    termsOfParticipationCreate(data){
        return this.post('terms-of-participation', data);
    }

    /**
     *
     */
    termsOfParticipationUpdateById(id, data){
        return this.put(['terms-of-participation', id], data);
    }

    /**
     *
     */
    termsOfParticipationDeleteById(id){
        return this.delete(['terms-of-participation', id]);
    }

    /**
     *
     */
    termsOfParticipationUpdateDefaultsByCompetitionType(type, data){
        return this.patch(['terms-of-participation', type], data);
    }

    // CompetionTeam

    /**
     *
     */
    competionTeamGetAllByCompetionId(id){
        return this.get(['competion-team', id]);
    }

    // CompetionTeamTermsOfParticipationDocument

    /**
     *
     */
    competionTeamTermsOfParticipationDocumentGetAllByCompetionTeamId(id){
        return this.get(['competion-team-terms-of-participation-document', id]);
    }

    /**
     *
     */
    competionTeamTermsOfParticipationDocumentUpdateById(id, data){
        return this.put(['competion-team-terms-of-participation-document', id], data);
    }

    // CompetionPersonnel

    /**
     *
     */
    competionPersonnelGetAllByCompetionTeamId(id){
        return this.get(['competion-personnel', id]);
    }

    // CompetionPersonnelTermsOfParticipationDocument

    /**
     *
     */
    competionPersonnelTermsOfParticipationDocumentGetAllByCompetionPersonnelId(id){
        return this.get(['competion-personnel-terms-of-participation-document', id]);
    }

    /**
     *
     */
    competionPersonnelTermsOfParticipationDocumentUpdateById(id, data){
        return this.put(['competion-personnel-terms-of-participation-document', id], data);
    }

    // Declaration Templates/Workflow

    declarationTemplateById(id){
        return this.get(['declaration-template', id]);
    }

    declarationWorkflowsById(id){
        return this.get(['lookups', 'DeclarationWorkflow', 'byId', id]);
    }

    declarationWorkflowsByTypeAndSport(type, sport){
        return this.get(['declaration-workflow', type, sport]);
    }

    // Declaration

    declarationTeamsById(declarationId){
        return this.get(['declaration', declarationId, 'teams']);
    }

    declarationTeamDeclarationById(teamDeclarationId){
        return this.get([this._paths.teamDeclaration, teamDeclarationId]);
    }

    declarationTeamDeclarationStepSubmit(teamDeclarationId, teamDeclarationStepId){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'steps', teamDeclarationStepId, 'submit']);
    }

    declarationTeamDeclarationPersons(teamDeclarationId, teamDeclarationModuleId){
        return this.get([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons']);
    }

    declarationTeamDeclarationKit(teamDeclarationId, teamDeclarationModuleId, kitType = 'primary'){
        return this.get([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'kit', kitType]);
    }

    declarationTeamDeclarationKitUpdate(teamDeclarationId, teamDeclarationModuleId, kitType = 'primary', data){
        return this.patch([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'kit', kitType], data);
    }

    declarationTeamAddPerson(teamDeclarationId, teamDeclarationModuleId, query){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons'], query);
    }

    declarationTeamAddPersons(teamDeclarationId, teamDeclarationModuleId, query){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons'], query, {
            'x-sportradar-timeout': 60000,
        });
    }

    declarationTeamUpdatePerson(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, query){
        return this.put([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId], query);
    }

    declarationTeamDeletePerson(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId){
        return this.delete([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId]);
    }

    declarationTeamDeclarationPassportUpdate(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.put([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'passport'], data);
    }

    declarationTeamDeclarationGetEligiblePersons(teamDeclarationId, roleType = null, isMedic = null){
        return this.get([this._paths.teamDeclaration, teamDeclarationId, 'eligible-persons'], {
            roleType: roleType || undefined,
            isMedic: ((isMedic !== null && isMedic !== undefined) ? Boolean(isMedic) : undefined),
        });
    }

    declarationDeleteById(workflowType, declarationId){
        return this.delete(['declaration', workflowType, declarationId]);
    }

    declarationTeamDeclarationDeleteById(declarationId){
        return this.delete([this._paths.teamDeclaration, declarationId]);
    }

    // Series

    seriesDeclarationById(id){
        return this.get(['declaration', 'series', id]);
    }

    seriesDeclarationCreate(data){
        return this.post(['declaration', 'series'], data);
    }

    seriesDeclarationUpdateById(id, data){
        return this.put(['declaration', 'series', id], data);
    }

    seriesDeclarationBySeriesEqId(seriesEqId){
        return this.get(['series', seriesEqId, 'declaration']);
    }

    seriesDeclarationSearch(query){
        return this.post(['declaration', 'series', 'search'], query);
    }

    seriesDeclarationGetById(seriesDeclarationId) {
        return this.get(['declaration', 'series', seriesDeclarationId]);
    }

    // Team Declaration Module
    teamDeclarationModuleStatus(teamDeclarationId, teamDeclarationModuleId){
        return this.get([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'status']);
    }

    teamDeclarationModuleUpdateStatus(teamDeclarationId, teamDeclarationModuleId, data){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'status'], data);
    }

    teamDeclarationModulePersonStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId){
        return this.get([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'person', teamDeclarationPersonId, 'status']);
    }

    teamDeclarationModuleUpdatePersonStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'status'], data);
    }

    teamDeclarationModuleUpdatePersonReplace(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'replace'], data);
    }

    teamDeclarationModulePersonBioStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId){
        return this.get([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'person', teamDeclarationPersonId, 'bio', 'status']);
    }

    teamDeclarationModuleUpdatePersonBioStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'bio', 'status'], data);
    }

    teamDeclarationModulePersonPassportStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId){
        return this.get([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'person', teamDeclarationPersonId, 'passport', 'status']);
    }

    teamDeclarationModuleUpdatePersonPassportStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'passport', 'status'], data);
    }

    teamDeclarationModulePersonMedicalCertificateStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId){
        return this.get([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'person', teamDeclarationPersonId, 'medical-certificate', 'status']);
    }

    teamDeclarationModuleUpdatePersonMedicalCertificateStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'medical-certificate', 'status'], data);
    }

    teamDeclarationModuleUpdatePersonMedicalCertificate(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.put([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'medical-certificate'], data);
    }

    teamDeclarationModulePersonHeadshotStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId){
        return this.get([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'person', teamDeclarationPersonId, 'headshot', 'status']);
    }

    teamDeclarationModuleUpdatePersonHeadshotStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.post([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'headshot', 'status'], data);
    }

    teamDeclarationModuleUpdatePersonHeadshot(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.put([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'headshot'], data);
    }

    teamDeclarationModuleUpdateField(teamDeclarationId, teamDeclarationModuleId, fieldName, data){
        return this.put([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'fields', fieldName], data);
    }

    teamDeclarationModuleUpdatePersonBio(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data){
        return this.put([this._paths.teamDeclaration, teamDeclarationId, 'modules', teamDeclarationModuleId, 'persons', teamDeclarationPersonId, 'bio'], data);
    }

    // Tournament
    tournamentDeclarationSearch(query){
        return this.post(['declaration', 'tournament', 'search'], query);
    }

    tournamentDeclarationById(tournamentDeclarationId) {
        return this.get(['declaration', 'tournament', tournamentDeclarationId]);
    }

    tournamentDeclarationByTournamentEqId(tournamentEqId){
        return this.get(['tournament', tournamentEqId, 'declaration']);
    }

    tournamentDeclarationCreate(data){
        return this.post(['declaration', 'tournament'], data);
    }

    tournamentDeclarationUpdateById(id, data){
        return this.put(['declaration', 'tournament', id], data);
    }

    // Person

    /**
     *
     */
    personGetByPersonEqId(eqId){
        return this.get(['person', eqId]);
    }

    // Match Declaration
    matchDeclarationSearch(data) {
        return this.post(['declaration', 'match', 'search'], data);
    }

    matchDeclarationGetById(matchDeclarationId) {
        return this.get(['declaration', 'match', matchDeclarationId]);
    }

    matchDeclarationCreate(data){
        return this.post(['declaration', 'match'], data);
    }

    matchDeclarationUpdateById(id, data){
        return this.put(['declaration', 'match', id], data);
    }

    matchDeclarationByMatchEqId(matchEqId){
        return this.get(['match', matchEqId, 'declaration']);
    }
}
