<template>
    <div class="pageContent">
        <teamList
            v-bind:title="$tc('team', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.teams.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'commonTeamCreate' }"
                >
                    {{ $t('createTeam') }}
                </actionButton>
            </template>
        </teamList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import teamList from '@/components/lists/team.vue';

export default {
    name: 'commonTeamIndex',
    extends: base,
    components: {
        teamList,
    },
    data(){
        return {
            rowProps: {
                showSport: true,
                showType: true,
                showUnion: true,
                showRemove: true,
            },
        };
    },
};
</script>
