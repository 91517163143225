<template>
    <div class="pageContent formWrapper">
        <div class="headline-wrapper mb-3" v-if="headline || this.$scopedSlots.buttons">
            <component v-bind:is="headline ? 'h' + headlineLevel : 'div'" v-bind:class="'h' + (largeHeadline ? headlineLevel - 1 : headlineLevel)">{{ headline }}</component>
            <div class="headline-buttons" v-if="$scopedSlots.buttons">
                <!--
                @slot Module buttons
                -->
                <slot name="buttons"/>
            </div>
        </div>
        <messageBlock v-bind:data-message="errorMsg" data-type="error"/>
        <formBlock
            v-bind:data-id="dataId"
            v-bind:data-config="formConfig"
            v-bind:data-values="initValues"
            v-bind:data-model="model"
            v-bind:data-errors="mappedErrors"
            v-bind:data-disabled="isProcessing"
            v-bind:class="formClasses"
            v-on:change="onChange"
            v-on:submit="onSubmit"
            v-on:abort="onAbort"
        >
            <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
                <!--
                @slot All slots are passed through with all their data
                -->
                <slot v-bind:name="name" v-bind="data"/>
            </template>
        </formBlock>
    </div>
</template>

<script>
import messageBlock from '../components/blocks/message.vue';
import formBlock from '../components/blocks/form.vue';
import Model from '../models/model.js';

export default {
    name: 'formComponent',
    components: {
        messageBlock,
        formBlock,
    },
    props: {
        /**
         * form element configuration
         */
        elements: {
            type: Array,
            mandatory: true,
            default(){
                return [];
            }
        },
        /**
         * initial form input values
         */
        values: {
            type: Object,
            default: null,
        },
        /**
         * model to use. will bind value changes to the corresponding model attribute values.
         */
        model: {
            type: Model,
            default: null,
        },
        /**
         * list of element ids that are forced into error state
         */
        errors: {
            type: Array,
            default(){
                return [];
            }
        },
        /**
         * form headline
         */
        headline: {
            type: String,
            mandatory: false,
            default: '',
        },
        /**
         * form id
         */
        dataId: {
            type: String,
            mandatory: false,
            default: '',
        },
        /**
         * show form in large format (labels in their own line instead of side by side with the inputs)
         */
        large: {
            type: Boolean,
            mandatory: false,
            default: false,
        },
        /**
         * toggle all form elements to readonly (if respected in element config)
         */
        readonly: {
            type: Boolean,
            mandatory: false,
            default: false,
        },
        /**
         * headline element level (h1, h2, ...)
         */
        headlineLevel: {
            type: Number,
            default: 2,
        },
        /**
         * show large headline
         */
        largeHeadline: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            formValues: {},
            errorMsg: null,
            isProcessing: false,
        };
    },
    methods: {
        onChange(data){
            this.formValues = data;

            /**
             * form values have changed
             *
             * @param {object} data Form data
             */
            this.$emit('change', this.formValues);
        },
        onSubmit(data){
            /**
             * form has been submitted
             *
             * @param {object} data Form data
             */
            this.$emit('submit', data);
        },
        onAbort(){
            /**
             * form has been aborted
             */
            this.$emit('abort');
        },
        createFormRow(elements = [], dependency = null, hidden = null, columnsLg = 3, columnsMd = null, columnsSm = null){
            return {
                type: 'row',
                props: {
                    cols: Math.max(1, Math.min(12, columnsSm || 1)),
                    'cols-md': Math.max(1, Math.min(12, columnsMd || Math.ceil(columnsLg / 2))),
                    'cols-lg': Math.max(1, Math.min(12, columnsLg)),
                },
                cols: elements.map(element => ({
                    elements: [element],
                    dependency: element.dependency,
                    hidden: element.hidden,
                })),
                dependency: dependency,
                hidden: hidden,
            };
        },
        createFormRowColumn(elements = [], dependency = null, hidden = null){
            return this.createFormRow(elements, dependency, hidden, 1);
        },
        createToggleable(config, enabledDependency, disabledDependency){
            return [
                Object.assign({}, config, {
                    dependency: enabledDependency,
                }),
                Object.assign({}, config, {
                    dependency: disabledDependency,
                    disabled: true,
                    plaintext: true,
                }),
            ];
        },
        generateMappingProviderControls(){
            if (!this.model || !this.model.mappingProviders){
                return [];
            }

            return this.model.mappingProviders.map(mappingProvider => ({
                id: mappingProvider.name.toLowerCase(),
                type: 'text',
                label: mappingProvider.title,
                placeholder: this.$t('enterId'),
                //plaintext: this.readonly,
                //disabled: this.readonly,
                hidden: this.hideMapping,
            }));
        },
    },
    computed: {
        elementsConfig(){
            return null;
        },
        confirmButtonLabel(){
            return this.$t('save');
        },
        abortButtonLabel(){
            return null;
        },
        formConfig(){
            return {
                elements: this.elementsConfig || this.elements,
                confirmButton: this.confirmButtonLabel || undefined,
                abortButton: this.abortButtonLabel || undefined,
            };
        },
        initValues(){
            return this.values;
        },
        formClasses(){
            return {
                large: this.large,
            };
        },
        errorIdMap(){
            //overwrite to set error id mappings

            //format:
            //return { apiPropertyId: 'formFieldId', ... };

            //example:
            //return { forename: 'firstName', surname: 'lastName' };

            return null;
        },
        mappedErrors(){
            if(!this.errorIdMap){
                return this.errors;
            }

            return this.errors.map(id => this.errorIdMap[id] || id);
        },
    },
};
</script>
