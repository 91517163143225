import Model from '@/models/model.js';
import MatchTeamModel from '@/models/matchTeam.js';
import PlayerModel from '@/models/person/player.js';

/**
 * match teamsheets team model class
 */
export default class MatchTeamsheetTeamModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            let team = {
                id: data.id || data.matchTeamId || 0,
                countryCode: data.countryCode || '',
                countryName: data.countryName || '',
                disciplinary: data.disciplinary || null,
                firstHalfScore: data.firstHalfScore || 0,
                isPlayingAtHome: data.isPlayingAtHome || false,
                isTeam1: data.isTeam1 || false,
                name: data.name || data.teamName || data.displayOverride || '',
                result: data.result || '',
                score: data.score || 0,
                scoring: data.scoring || null,
                teamAliasEqId: data.teamAliasEqId || '',
                teamCode: data.teamCode || '',
                teamEqId: data.teamEqId || '',
                teamStatuses: data.teamStatuses || [],
                teamType: data.teamType || '',
                unionName: data.unionName || '',

                teamSheet: (data.teamSheet || []).map(data => PlayerModel.from(data)),
            };

            this.team = MatchTeamModel.from(team);
        }
    }
}
