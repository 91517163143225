<template>
    <div class="pageContent">
        <list
            v-bind:title="$t('inactivityReportType', { type: this.ucFirst(this.dataRankingType) })"
            v-bind:items="intervalSteps"
            v-bind:waitFor="apiData.teams"
            v-bind:waitForRetry="reloadTeams"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-on:click="printSegment()"
                >
                    {{ $t('print') }}
                </actionButton>
            </template>
            <template v-slot="{ item: intervalStep }">
                <div class="mt-4 mb-3 h3">
                    {{ intervalStep.label }}
                </div>
                <div v-for="team in intervalStep.teams" v-bind:key="team.id" class="pl-3">
                    {{ $t('inactivityTeamMessage', { team: team.name, lastMatchDate: $fd(team.lastPlayedMatch.kickOff, 'dateLong'), lastMatchOpponent: getOpponentTeamName(team) })}}
                </div>
            </template>
        </list>
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';

import TeamModel from '@/models/team.js';

export default {
    name: 'administrationRankingInactivity',
    extends: rankingBase,
    data(){
        return {
            apiData: {
                teams: this.loadTeams(),
            },
            intervalSteps: [],
            minMonths: 9, //group everything below together
            maxMonths: 48, //use as maximum
        };
    },
    computed: {
        now(){
            return new Date();
        },
    },
    methods: {
        loadTeams(){
            //need to wait a tick for computed sportId value
            if(!this.sportId){
                this.$nextTick(() => this.reloadTeams());
                return new Promise(() => {});
            }

            return this.$api.call.rankings.teamInactivity(this.sportId).then(result => {
                const teams = (result || []).map(data => new TeamModel(data));
                const intervalSteps = [];

                //order by lastMatch date (desc)
                teams.sort((a, b) => a.lastPlayedMatch?.kickOff - b.lastPlayedMatch?.kickOff);

                //create interval steps based on months since lastMatch date
                teams.forEach(team => {
                    const diff = this.getMonthDiff(team.lastPlayedMatch?.kickOff);
                    const current = intervalSteps[intervalSteps.length - 1]?.months;

                    //create interval step when month diff changes
                    if(diff !== current){
                        let label = diff;
                        if(label === 0 && this.minMonths > 0){
                            label = `0 - ${this.minMonths - 1}`;
                        }
                        else if(label === this.maxMonths){
                            label = this.$t('numberOrMore', { number: label });
                        }

                        intervalSteps.push({
                            months: diff,
                            label: this.$t('monthsOfInactivity', { number: label }),
                            teams: [],
                        });
                    }

                    //add team to interval step
                    intervalSteps[intervalSteps.length - 1].teams.push(team);
                });

                this.intervalSteps = intervalSteps;
            });
        },
        reloadTeams(){
            this.apiData.teams = this.loadTeams();
        },
        getMonthDiff(kickOff){
            if(!kickOff){
                return 0;
            }

            const monthDiff = this.now.getMonth() - kickOff.getMonth();
            const yearDiff = this.now.getYear() - kickOff.getYear();
            const diff = monthDiff + yearDiff * 12;

            if(diff < this.minMonths){
                return 0;
            }

            return Math.min(diff, this.maxMonths);
        },
        getOpponentTeamName(team){
            return team.lastPlayedMatch?.teams.filter(option => team.eqId !== option.team?.eqId).shift().name;
        },
        ucFirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
}
</script>
