<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
    >
        <template v-slot="{ formId, submit }">
            <editPersonRoleForm
                v-bind:dataId="formId"
                v-bind:headline="$t('personRole')"
                v-bind:errors="errorIds"
                v-bind:model="personRole"
                v-on:submit="submit"
            >
            </editPersonRoleForm>
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import editPersonRoleForm from '@/components/forms/editPersonRole.vue';

import PersonRoleModel from '@/models/personRole.js';

/**
 * person role edit modal component
 */
export default {
    name: 'editPersonRoleModal',
    extends: base,
    components: {
        editPersonRoleForm,
    },
    props: {
        personRole: {
            type: PersonRoleModel,
            default(){
                return new PersonRoleModel();
            }
        },
        create: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        payload(){
            const personRole = this.personRole;

            if (this.create) {
                personRole.personId = this.personId;
            }

            return personRole;
        },
    },
};
</script>
