import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonTournamentIndex from '@/views/common/tournament/index.vue';
import commonTournamentCreate from '@/views/common/tournament/create.vue';
import commonTournamentDetail from '@/views/common/tournament/detail.vue';
import commonTournamentEdit from '@/views/common/tournament/edit.vue';

import tournamentMatchRoutes from './tournament/match.js';
import tournamentTeamRoutes from './tournament/team.js';

export default {
    path: '/tournament/',
    component: pageFrame,
    children: [
        {
            name: 'commonTournamentIndex',
            path: constants.routePaths.index,
            component: commonTournamentIndex,
            props: (route) => ({ teamEqId: route.query.teamEqId }),
            meta: {
                permission: permissions.tournaments.list,
            },
        },
        {
            name: 'commonTournamentCreate',
            path: constants.routePaths.create,
            component: commonTournamentCreate,
            meta: {
                permission: permissions.tournaments.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.tournamentId),
            component: pageFrame,
            children: [
                {
                    name: 'commonTournamentDetail',
                    path: constants.routePaths.index,
                    component: commonTournamentDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                {
                    name: 'commonTournamentEdit',
                    path: constants.routePaths.edit,
                    component: commonTournamentEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.update,
                    },
                },
                tournamentMatchRoutes,
                tournamentTeamRoutes,
            ],
        },
    ],
};
