import Model from '@/models/model.js';

/**
 * union region model class
 */
export default class UnionRegionModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * description
     *
     * @type {string}
     */
     description = '';

     /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
     constructor(data = {}){
         super(data);

         if(data){
             this.id = data.id || data.name || data.unionRegionId || 0; /** remove data.name when ID implemented in BE */
             this.eqId = data.eqId || '';
             this.name = data.name || data.regionName || '';
             this.description = data.description || '';
         }
     }

     /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
     label(vue = null){
         return this.name;
     }
}
