import Model from '@/models/model.js';

/**
 * standing type model class
 */
export default class StandingTypeModel extends Model {
    /**
     * id
     *
     * @type {number}
     */
    id = 0;

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * sport
     *
     * @type {string}
     */
    sport = '';

    /**
     * typeId
     *
     * @type {number}
     */
    typeId = 0;

    /**
     * typeName
     *
     * @type {string}
     */
    typeName = '';

    /**
     * value
     *
     * @type {number}
     */
    value = 0;

    /**
     * discriminator
     *
     * @type {number}
     */
    discriminator = 0;

    /**
     * group
     *
     * @type {string}
     */
    group = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.discriminator = data.discriminator || 0;
            this.group = data.group || '';
            this.name = data.name || '';
            this.sport = data.sport || '';
            this.typeId = data.typeId || 0;
            this.typeName = data.typeName || '';
            this.value = data.value || 0;
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
