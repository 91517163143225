<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:waitFor="apiData.team"
            v-bind:waitForRetry="reloadTeam"
            v-bind:title="$t('teamDetails')"
            v-bind:name="team ? team.getName() : ''"
            v-bind:imagePath="team ? team.getImgPath() : null"
            v-bind:details="detailData"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="secondary"
                    v-bind:to="editTeamLink(dataTeamId)"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <list
            v-bind:items="teamTournaments"
            v-bind:title="$t('latestTournaments')"
            v-bind:waitFor="apiData.teamTournaments"
            v-bind:waitForRetry="reloadTeamTournaments"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:buttons>
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    v-bind:to="viewAllTournamentsByTeamEqId(team.eqId)"
                    v-if="team"
                >
                    {{ $t('viewAllTournaments') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <tournamentRow
                    showDetail showDateRange showSport showEventType
                    isHeadline
                />
            </template>
            <template v-slot="{ item: tournament, role }">
                <tournamentRow
                    showDetail showDateRange showSport showEventType
                    v-bind:tournament="tournament"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:items="teamMatches"
            v-bind:title="$t('latestMatches')"
            v-bind:waitFor="apiData.teamMatches"
            v-bind:waitForRetry="reloadTeamMatches"
            v-bind:hide="hideElement(2)"
        >
            <template v-slot:buttons>
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    v-bind:to="viewAllMatchesByTeamEqId(team.eqId)"
                    v-if="team"
                >
                    {{ $t('viewAllMatches') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <matchRow
                    showScore showVenue showKickOffTime showDetail
                    v-bind:showTeamOneLogo="false"
                    v-bind:showTeamTwoLogo="false"
                    isHeadline
                />
            </template>
            <template v-slot="{ item: match, role }">
                <matchRow
                    showScore showVenue showKickOffTime showDetail
                    v-bind:showTeamOneLogo="false"
                    v-bind:showTeamTwoLogo="false"
                    v-bind:match="match"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:items="teamNameHistory"
            v-bind:title="$t('teamAlias')"
            v-bind:waitFor="apiData.teamNameHistory"
            v-bind:waitForRetry="reloadTeamNameHistory"
            v-bind:hide="hideElement(3)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.teams.aliases.update"
                    v-b-modal.modal-aliases-date-change
                >
                    {{ $t('aliasDateChanges') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:permission="permissions.teams.aliases.create"
                    v-b-modal.modal-createEditTeamAlias
                >
                    {{ $t('addNewAlias') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <teamAliasRow
                    showNameShort showTeamCode showDateFrom showEdit
                    isHeadline
                />
            </template>
            <template v-slot="{ item: teamAlias, role }">
                <teamAliasRow
                    showNameShort showTeamCode showDateFrom showEdit
                    v-bind:teamAlias="teamAlias"
                    v-bind:role="role"
                    v-bind:editPermission="permissions.teams.aliases.update"
                    v-bind:updateHandler="updateTeamNameHistory"
                    v-on:updated="teamNameHistoryChanged"
                />
            </template>
        </list>

        <teamAliasesDateChangeModal
            id="modal-aliases-date-change"
            v-bind:title="$t('teamAliasDateChange')"
            v-bind:team="team"
            v-bind:teamId="dataTeamId"
            v-bind:teamNameHistory="teamNameHistory"
            v-bind:confirmHandler="changeDateTeamNameHistory"
            v-on:confirmed="changeDateTeamNameHistoryComplete"
            v-if="team"
        />

        <editCreateTeamAliasModal
            id="modal-createEditTeamAlias"
            v-bind:title="$t('createTeamAlias')"
            v-bind:teamId="dataTeamId"
            v-bind:create="true"
            v-bind:confirmHandler="createTeamNameHistory"
            v-on:confirmed="teamNameHistoryChanged"
            v-if="team"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import detailsHeader from '@/components/detailsHeader.vue';
import teamAliasesDateChangeModal from '@/components/modals/teamAliasesDateChange.vue';
import editCreateTeamAliasModal from '@/components/modals/editCreateTeamAlias.vue';
import subNavigation from '@/components/subnavigation.vue';
import tournamentRow from '@/components/rows/tournament.vue';
import teamAliasRow from '@/components/rows/teamAlias.vue';
import matchRow from '@/components/rows/match.vue';

import TeamModel from '@/models/team.js';
import TournamentModel from '@/models/tournament.js';
import MatchModel from '@/models/match.js';
import TeamAliasModel from '@/models/teamAlias.js';

export default {
    name: 'commonTeamDetail',
    extends: base,
    components: {
        detailsHeader,
        teamAliasesDateChangeModal,
        editCreateTeamAliasModal,
        subNavigation,
        teamAliasRow,
        tournamentRow,
        matchRow,
    },
    props: {
        dataTeamId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            team: this.loadTeam(),
        };

        return {
            apiData: {
                team: this.apiData.team,
                teamTournaments: this.loadTeamTournaments(),
                teamMatches: this.loadTeamMatches(),
                teamNameHistory: this.loadTeamNameHistory(),
            },
            team: null,
            teamTournaments: [],
            teamMatches: [],
            teamNameHistory: [],

            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$t('latestTournaments'),
                    },
                    2: {
                        label: this.$t('latestMatches'),
                    },
                    3: {
                        label: this.$t('teamAlias'),
                    },
                }
            },
        };
    },
    methods: {
        editTeamLink(id){
            return { name: 'commonTeamEdit', params: { dataTeamId: id } };
        },
        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },
        viewAllTournamentsByTeamEqId(teamEqId){
            return { name: 'commonTournamentIndex', query: { teamEqId: teamEqId } };
        },
        viewAllMatchesByTeamEqId(teamEqId){
            return { name: 'commonMatchIndex', query: { teamEqId: teamEqId } };
        },

        loadTeam(){
            return this.$api.call.union.unionTeamGetById(this.dataTeamId).then(data => {
                this.team = new TeamModel(data);

                //reload stuff that require the tournament eqid so they can finally load
                this.reloadTeamTournaments();
                this.reloadTeamMatches();
                this.reloadTeamNameHistory();
            });
        },
        reloadTeam(){
            this.apiData.team = this.loadTeam();
        },

        loadTeamTournaments(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.team){
                return new Promise((resolve, reject) => this.apiData.team.catch(reject));
            }

            return this.$api.call.tournament.tournamentSearch({
                teamEqIds: this.team.eqId ? [this.team.eqId] : null,
                dateSortingOrder: 'Desc',
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(result => {
                this.teamTournaments = (result.data || []).map(data => new TournamentModel(data));
            });
        },
        reloadTeamTournaments(){
            this.apiData.teamTournaments = this.loadTeamTournaments();
        },

        loadTeamMatches(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.team){
                return new Promise((resolve, reject) => this.apiData.team.catch(reject));
            }

            return this.$api.call.match.matchSearch({
                team1EqId: this.team.eqId,
                kickoffDateDirection: 'Desc',
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(result => {
                this.teamMatches = (result ? (result.data || []) : []).map(data => new MatchModel(data));
            });
        },
        reloadTeamMatches(){
            this.apiData.teamMatches = this.loadTeamMatches();
        },

        loadTeamNameHistory(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.team){
                return new Promise((resolve, reject) => this.apiData.team.catch(reject));
            }

            return this.$api.call.union.unionTeamGetTeamNameHistoryById(this.dataTeamId).then(result => {
                Object.values(result).forEach(entry => {
                    this.teamNameHistory.push(new TeamAliasModel(entry));
                });
            });
        },
        reloadTeamNameHistory(){
            this.apiData.teamNameHistory = this.loadTeamNameHistory();
        },

        changeDateTeamNameHistory(data) {
            return this.$api.call.union.teamAliasValidFromUpdate(this.dataTeamId, data);
        },
        changeDateTeamNameHistoryComplete(){
            this.teamNameHistory = [];
            this.reloadTeamNameHistory();
        },

        createTeamNameHistory(teamAlias) {
            const aliasToSave = {
                name: teamAlias.name,
                nameShort: teamAlias.nameShort,
                nameAlternative: teamAlias.nameAlternative,
                teamCode: teamAlias.teamCode,
                teamCodeAnnual: teamAlias.teamCodeAnnual,
                dateFrom: teamAlias.dateFrom ? teamAlias.dateFrom.toISOString() : null,
            };

            return this.$api.call.union.teamAliasCreate(this.dataTeamId, aliasToSave);
        },
        updateTeamNameHistory(teamAlias) {
            const aliasToSave = {
                teamAliasId: teamAlias.id,
                name: teamAlias.name,
                nameShort: teamAlias.nameShort,
                nameAlternative: teamAlias.nameAlternative,
                teamCode: teamAlias.teamCode,
                teamCodeAnnual: teamAlias.teamCodeAnnual,
                dateFrom: teamAlias.dateFrom ? teamAlias.dateFrom.toISOString() : null,
            };

            return this.$api.call.union.teamAliasUpdate(this.dataTeamId, aliasToSave);
        },
        teamNameHistoryChanged(){
            this.teamNameHistory = [];
            this.reloadTeamNameHistory();
        },
    },
    computed: {
        detailData(){
            if(!this.team){
                return [];
            }

            return [
                {
                    label: this.$t('country'),
                    content: this.team.countryName ? this.team.countryName : 'unknown',
                },
                {
                    label: this.$tc('union', 1),
                    content: this.team.unionName ? this.team.unionName : 'unknown',
                },
                {
                    label: this.$t('teamType'),
                    content: this.team.teamType ? this.team.teamType : 'unknown',
                },
                {
                    label: this.$tc('sport', 1),
                    content: this.team.sport ? this.team.sport : 'unknown',
                },
            ];
        },
    },
};
</script>
