<script>
import itemList from '@/components/itemList.vue';
import personMappingRow from '@/components/rows/personMapping.vue';

/**
 * person mapping list component
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default {
    name: 'personMappingList',
    extends: itemList,
    components: {
        personMappingRow,
    },
    data(){
        return {
            //overwrites
            rowElement: personMappingRow,
            rowItemPropName: 'personMapping',
        };
    },
};
</script>
