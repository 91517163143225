<script>
import vselectElement from '../vselect.vue';
import TeamKitModel from '@/models/person/teamkit.js';

/**
 * vselect element for TeamKit items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectTeamKitElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                //match team kit filtering done in frontend due to api not providing the functionality
                return this.$api.call.match.matchTeamAllAvailableTournamentKitsGetByMatchTeamId(config.byMatchTeamId)
                    .then(result => (result || []).map(data => new TeamKitModel(data)))
                    .then(items => items.filter(item => !text || item.label().toLowerCase().includes(text.toLowerCase())));
            };

            return config;
        },
    },
};
</script>
