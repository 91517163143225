<template>
    <modal/>
</template>

<script>
import modal from '@/components/modal.vue';

/**
 * base modal component
 * @abstract
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    components: {
        modal,
    },
    props: {
        /**
         * modal id
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * modal title
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * handler to be executed when confirmation is completed.
         * if function return {Promise}, its result will be depending on the promise state.
         * other function return values will be ignored, except for {Boolean} false.
         */
        confirmHandler: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * handler to be executed when cancel button is clicked.
         * if function return {Promise}, its result will be depending on the promise state.
         * other function return values will be ignored, except for {Boolean} false.
         */
        cancelHandler: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * modal is used for review purpose
         * replaces buttons with accept/reject and can be used for further review adjustments
         */
        reviewMode: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            errorIds: [],
        };
    },
    computed: {
        modalProps(){
            return {
                id: this.id,
                title: this.title,
                'title-class': 'h1',
                centered: true,
                'no-close-on-backdrop': true,
                size: 'lg',
                scrollable: true,
                'ok-title': (this.reviewMode ? this.$t('accept') : this.$t('save')),
                'ok-variant': 'success',
                'cancel-title': (this.reviewMode ? this.$t('reject') : this.$t('cancel')),
                'cancel-variant': (this.reviewMode ? 'danger' : 'secondary'),
                confirmHandler: this.confirmHandler,
                confirmHandlerData: this.payload,
                cancelHandler: this.cancelHandler,
                cancelHandlerData: this.payload,
            };
        },
        modalEvents(){
            return {
                errorIds: this.setErrorIds,
                show: this.onShow,
                ok: this.onOk,
                cancel: event => this.$emit('cancel', event),
                change: event => this.$emit('change', event),
                close: event => this.$emit('close', event),
                hide: event => this.$emit('hide', event),
                hidden: event => this.$emit('hidden', event),
                shown: event => this.$emit('shown', event),
            };
        },
        payload(){
            //overwrite to set modal payload to use for confirm event data and confirmHandler method call parameter
            return null;
        },
    },
    methods: {
        setErrorIds(errorIds){
            this.errorIds = errorIds;
        },
        onShow(){
            this.errorIds = [];
        },
        onOk(event){
            this.$emit('confirmed', {
                payload: this.payload,
                event: event,
                data: event,
            });
        },
    },
};
</script>
