<script>
import formComponent from '@/components/form.vue';
import CalculationModel from '@/models/calculations.js';

export default {
    name: 'createEditRankingCalculationForm',
    extends: formComponent,
    props: {
        model: {
            type: CalculationModel,
        },
        subscriptionTypes: {
            type: Array,
            default(){
                return [];
            },
        }
    },
    methods: {},
    computed: {
        resultTypeOptions(){
            return [
                {
                    id: 'win',
                    label: this.$t('win'),
                },
                {
                    id: 'lose',
                    label: this.$t('lose'),
                },
            ];
        },
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'homeTeamPointsBoost',
                        type: 'number',
                        label: this.$t('homeTeamPointsBoost'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'winByMoreThreshold',
                        type: 'number',
                        label: this.$t('winByMoreThreshould'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'winByMoreMultiplier',
                        type: 'number',
                        label: this.$t('winByMoreMultiplier'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'startDate',
                        type: 'date',
                        label: this.$t('startDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'endDate',
                        type: 'date',
                        label: this.$t('endDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
                {
                    type: 'hline',
                },
                {
                    id: 'ratingChangesWin',
                    type: 'headline',
                    text: this.$t('ratingChanges', { type: this.$t('win') }),
                },
                this.createFormRow([
                    {
                        id: 'winLowerPointGapLimit',
                        type: 'number',
                        label: this.$t('lowerPointGapLimit'),
                    },
                    {
                        id: 'winLowerRatingChange',
                        type: 'number',
                        label: this.$t('lowerRatingChange'),
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'winUpperPointGapLimit',
                        type: 'number',
                        label: this.$t('upperPointGapLimit'),
                    },
                    {
                        id: 'winUpperRatingChange',
                        type: 'number',
                        label: this.$t('upperRatingChange'),
                    },
                ]),
                {
                    id: 'ratingChangesLose',
                    type: 'headline',
                    text: this.$t('ratingChanges', { type: this.$t('lose') }),
                },
                this.createFormRow([
                    {
                        id: 'loseLowerPointGapLimit',
                        type: 'number',
                        label: this.$t('lowerPointGapLimit'),
                    },
                    {
                        id: 'loseLowerRatingChange',
                        type: 'number',
                        label: this.$t('lowerRatingChange'),
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'loseUpperPointGapLimit',
                        type: 'number',
                        label: this.$t('upperPointGapLimit'),
                    },
                    {
                        id: 'loseUpperRatingChange',
                        type: 'number',
                        label: this.$t('upperRatingChange'),
                    },
                ]),
                {
                    id: 'ratingChangesDraw',
                    type: 'headline',
                    text: this.$t('ratingChanges', { type: this.$tc('draw', 1) }),
                },
                this.createFormRow([
                    {
                        id: 'drawLowerPointGapLimit',
                        type: 'number',
                        label: this.$t('lowerPointGapLimit'),
                    },
                    {
                        id: 'drawLowerRatingChange',
                        type: 'number',
                        label: this.$t('lowerRatingChange'),
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'drawUpperPointGapLimit',
                        type: 'number',
                        label: this.$t('upperPointGapLimit'),
                    },
                    {
                        id: 'drawUpperRatingChange',
                        type: 'number',
                        label: this.$t('upperRatingChange'),
                    },
                ]),
            ];
        },
        abortButtonLabel(){
            return null;
        },
        confirmButtonLabel(){
            return null;
        },
    },
};
</script>
