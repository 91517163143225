<template>
    <div v-bind:class="blockClasses">
        <h2 v-if="dataName">{{ dataName }}</h2>

        <component
            v-bind:is="hasFormWrapper ? 'div' : 'b-form'"
            v-bind:id="dataId"
            v-bind:novalidate="hasFormWrapper ? undefined : !formValidationEnabled"
            v-on:submit.prevent="onSubmit"
            v-on:reset.prevent="onReset"
            v-if="show"
        >
            <elementsBlock
                v-bind:data-config="elements"
                v-bind:data-values="values"
                v-bind:data-errors="dataErrors"
                v-bind:data-namespace="'form' + (dataId || '')"
                v-on:change="onElementsChange"
                v-on:init="onElementsInit"
            >
                <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
                    <!--
                    @slot All slots are passed through with all their data
                    @binding {object} values Current form values
                    -->
                    <slot v-bind:name="name" v-bind="data" v-bind:values="values"/>
                </template>
            </elementsBlock>
        </component>
    </div>
</template>

<script>
import base from './base.vue';
import elementsBlock from './elements.vue';
import Model from '../../models/model.js';
import constants from '@/constants.js';

/**
 * form block component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'formBlock',
    extends: base,
    components: {
        elementsBlock,
    },
    props: {
        /**
         * form id
         */
        dataId: {
            type: String,
            required: true,
        },
        /**
         * form name
         */
        dataName: {
            type: String,
            required: false,
        },
        /**
         * form element config
         */
        dataConfig: {
            type: Object,
            required: true,
        },
        /**
         * initial form values
         */
        dataValues: {
            type: Object,
            default: null,
        },
        /**
         * model to get values from and set values to
         */
        dataModel: {
            type: Model,
            default: null,
        },
        /**
         * array of element ids that will be forced into error state
         */
        dataErrors: {
            type: Array,
            default(){
                return [];
            }
        },
        /**
         * disable form
         */
        dataDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            values: (this.dataModel ? this.dataModel.getProperties() : Object.assign({}, this.dataValues)) || {},
            model: this.dataModel || null,
            actionButtonName: 'formActionButton',
            actionButtonValueConfirm: 1,
            actionButtonValueAbort: 0,
            show: true,
        };
    },
    methods: {
        onElementsChange(values){
            this.values = values;
        },
        onElementsInit(values){
            this.onElementsChange(values);

            /**
             * Form has been initialized
             *
             * @param {Model|object} model|values Model if provided, otherwise values
             */
            this.$emit('init', this.model ? this.model : this.values);
        },
        onSubmit(e){
            if(this.values[this.actionButtonName] === this.actionButtonValueAbort){
                /**
                 * Form cancel/abort
                 */
                this.$emit('abort');
                return;
            }

            const values = Object.assign({}, this.values);
            delete values[this.actionButtonName];

            /**
             * Form submit
             *
             * @param {object} values Form values
             */
            this.$emit('submit', values);
        },
        onReset(e){
            Object.assign(this.values, (this.model ? this.model.getProperties() : this.dataValues) || {});

            // Trick to reset/clear native browser form validation state
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        }
    },
    computed: {
        route(){
            return this.dataRoute;
        },
        elements(){
            if(!this.dataConfig){
                return [];
            }

            const buttons = [];
            (this.dataConfig.buttons || []).forEach((config) => {
                buttons.push(config);
            });

            if(this.dataConfig.confirmButton !== undefined){
                buttons.push({
                    text: (typeof this.dataConfig.confirmButton === 'string' ? this.dataConfig.confirmButton : 'Confirm'),
                    icon: '✔',
                    name: this.actionButtonName,
                    value: this.actionButtonValueConfirm,
                    action: 'submit',
                    disabled: this.dataDisabled,
                });
            }
            if(this.dataConfig.abortButton !== undefined){
                buttons.push({
                    text: (typeof this.dataConfig.abortButton === 'string' ? this.dataConfig.abortButton : 'Abort'),
                    icon: '✖',
                    name: this.actionButtonName,
                    value: this.actionButtonValueAbort,
                    action: 'submit',
                    disabled: this.dataDisabled,
                });
            }

            return (this.dataConfig.elements || []).concat(buttons.length ? [{ type: 'buttons', buttons: buttons }] : []);
        },
        hasFormWrapper(){
            let component = this;

            while((component = component.$parent)){
                if(component.$options.name === 'wrapper' && component.isForm){
                    return true;
                }
            }

            return false;
        },
        formValidationEnabled(){
            return (constants.options && constants.options.formValidationEnabled);
        },
    },
    watch: {
        values: {
            deep: true,
            handler(values){
                if(this.model){
                    for(const key in values){
                        if(Object.prototype.hasOwnProperty.call(values, key) && this.model[key] !== undefined){
                            this.model[key] = values[key];
                        }
                    }
                }

                /**
                 * Form values have been changed
                 *
                 * @param {Model|object} model|values Model if provided, otherwise values
                 */
                this.$emit('change', this.model ? this.model : values);
            },
        },
        dataValues: {
            deep: true,
            handler(values){
                if(this.model){
                    return;
                }

                Object.assign(this.values, values || {});
            },
        },
        dataModel: {
            deep: true,
            handler(model){
                this.model = model || null;
                Object.assign(this.values, (model ? model.getProperties() : this.dataValues) || {});
            },
        },
    },
};
</script>
