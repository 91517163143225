<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createTeam')"
            v-bind:waitFor="apiData.mappingProviders"
            v-bind:waitForRetry="reloadMappingProviders"
            v-on:submit="createTeam"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTeamList"
                    v-bind:loadingWhile="apiData.teamCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.teamCreation"
                >
                    {{ $t('createTeam') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || mappingWarning">
                <validations
                    v-bind:warnings="mappingWarning ? [mappingWarning] : undefined"
                    v-bind:errors="error.getErrorMessages($t('requestError'))"
                    v-if="error || mappingWarning"/>
            </template>
            <template v-slot>
                <createTeamForm
                    v-bind:headline="$t('teamInformation')"
                    v-bind:model="team"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingCreateMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import createTeamForm from '@/components/forms/createTeam.vue';

import TeamModel from '@/models/team.js';

export default {
    name: 'commonTeamCreate',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingCreateMixins,
    ],
    components: {
        validations,
        createTeamForm,
    },
    data(){
        return {
            apiData: {
                teamCreation: null,
            },
            team: TeamModel.from({}),
            error: null,
            mappingWarning: null,
        };
    },
    computed: {
        toTeamList(){
            return { name: 'commonTeamIndex' };
        },
    },
    methods: {
        loadMappingProviders(){
            this.team = TeamModel.from({});

            return this.loadEntityMappingProviders(this.team);
        },
        createTeam(){
            let queue = Promise.resolve();

            //if logo has changed and is now a File object, upload to storage and update team logo storage information
            if(this.team._changedProperties.logo !== undefined && this.team.logo instanceof File){
                queue = queue.then(() => this.$api.call.union.storeFiles(this.team.logo)).then(storageFile => {
                    this.team.setLogoStorage(storageFile);
                }, error => {
                    //set custom file upload validation error
                    error.errors.logo = this.$t('fileUploadError');

                    throw error;
                });
            }

            //submit team
            if(!this.team.id){
                queue = queue.then(() => this.$api.call.union.unionTeamCreate(this.team.toJSON()).then(data => {
                    this.team.id = data.id;
                    this.team.eqId = data.eqId;

                    //create mappings
                    return this.createMappings(this.team);
                }));
            }

            //map team to mapping providers
            if(this.team.eqId){
                queue = this.createMappingsUsingQueue(this.team, queue);
            }

            //wait for logo upload if needed, then save team
            queue = queue.then(() => {
                this.error = null;
                this.goToTeamDetail(this.team.id);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('team creation failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.teamCreation = queue;
        },
        goToTeamDetail(id){
            this.$router.push({ name: 'commonTeamDetail', params: { dataTeamId: id } });
        },
    },
}
</script>
