<template>
    <div class="pageContent">
        <administrationApiConsumerList
            v-bind:title="$t('apiManagement')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.apiConsumer.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'administrationApiConsumerCreate' }"
                >
                    {{ $t('createApiCustomer') }}
                </actionButton>
            </template>
        </administrationApiConsumerList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import administrationApiConsumerList from '@/components/lists/administration/apiConsumer.vue';

export default {
    name: 'administrationApiConsumerIndex',
    extends: base,
    components: {
        administrationApiConsumerList,
    },
    data(){
        return {
            rowProps: {
                showTournamentGroup: true,
                showParticipants: true,
                showDetail: true,
                showDelete: true,
            },
        };
    },
};
</script>
