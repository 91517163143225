<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-on:show="handleOpen"
        size="xl"
    >
        <template v-slot="{ formId, submit }">
            <editStandingForm
                v-bind:dataId="formId"
                v-bind:model="standing"
                v-bind:sport="sport"
                v-bind:errors="errorIds"
                v-on:submit="submit"
            />
            <list v-bind:items="standing.teams">
                <template v-slot:header>
                    <listHeader
                        v-bind:elements="rowHeader"
                        class="d-none d-md-block"
                    />
                </template>
                <template v-slot="{ item: standingTeam }">
                    <addEditTournamentTeamStandingForm
                        v-bind:dataId="formId"
                        v-bind:model="standingTeam"
                        v-bind:errors="errorIds"
                        v-bind:standingTeams="standingTeams"
                        v-on:submit="submit"
                    />
                </template>
                <template v-slot:footer>
                    <b-button
                        class="mt-6"
                        variant="outline-primary"
                        v-on:click="addNewRow"
                    >
                        {{ $t('add') }}
                    </b-button>
                </template>
            </list>
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import list from '@/components/list.vue';
import listHeader from '@/components/listHeader.vue';
import editStandingForm from '@/components/forms/editStanding.vue';
import addEditTournamentTeamStandingForm from '@/components/forms/addEditTournamentTeamStanding.vue'

import TournamentStandingModel from '@/models/tournamentStanding.js';
import StandingTeamModel from '@/models/standingTeam.js';

const rowHeaderClasses = 'card-text py-sm-4 py-md-2 w-20 col-3';

/**
 * standing edit modal component
 */
export default {
    name: 'editStandingModal',
    extends: base,
    components: {
        list,
        listHeader,
        editStandingForm,
        addEditTournamentTeamStandingForm,
    },
    props: {
        standing: {
            type: TournamentStandingModel,
            default(){
                return new TournamentStandingModel();
            }
        },
        standingTeams: {
            type: Array,
            default(){
                return [];
            }
        },
        sport: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            rowHeader: [
                {
                    label: this.$t('tournamentTeamId'),
                    cssclass: rowHeaderClasses,
                },
                {
                    label: this.$t('tournamentTeamNameShort'),
                    cssclass: rowHeaderClasses,
                },
                {
                    label: this.$t('forceOrder'),
                    cssclass: rowHeaderClasses,
                },
                {
                    label: this.$t('deductPoints'),
                    cssclass: rowHeaderClasses,
                },
            ],
        };
    },
    computed: {
        payload(){
            return this.standing.clone();
        },
    },
    methods: {
        handleOpen(){
            // defined to handle event raised from b-modal
        },
        handleConfirm(bvModalEvt){
            // cancel hide
            bvModalEvt.preventDefault();

            this.$emit('confirm', this.standing.clone());
        },
        addNewRow() {
            this.standing.teams.push(new StandingTeamModel());
        },
    },
};
</script>
