import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class UnionService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/union-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // union

    /**
     *
     */
    unionSearch(query){
        return this.post(['union', 'search'], query);
    }

    /**
     *
     */
    unionGetByByName(name){
        return this.get(['union', name]);
    }

    /**
     *
     */
    unionGetById(id){
        return this.get(['union', id]);
    }

    /**
     *
     */
    unionGetByEqId(eqId){
        return this.get(['lookups', 'union', 'byEqId', eqId]);
    }

    /**
     *
     */
    unionCreate(data){
        return this.post('union', data);
    }

    /**
     *
     */
    unionUpdateByName(name, data){
        return this.put(['union', name], data);
    }

    /**
     *
     */
    unionUpdateById(id, data){
        return this.put(['union', id], data);
    }

    /**
     *
     */
    unionDeleteById(id){
        return this.delete(['union', id]);
    }

    // region

    /**
     *
     */
    regionGetAllByUnion(id){
        return this.get(['region', id]);
    }

    /**
     *
     */
    regionGetById(id){
        return this.get(['region', id]);
    }

    /**
     *
     */
    regionCreate(data){
        return this.post('region', data);
    }

    /**
     *
     */
    regionUpdateById(id, data){
        return this.put(['region', id], data);
    }

    /**
     *
     */
    regionDeleteById(id){
        return this.delete(['region', id]);
    }

    /**
     *
     */
    regionGetAll(){
        return this.get(['union-region']);
    }

    // union team

    /**
     *
     */
    unionTeamGetAllByUnion(union){
        return this.get(['union-team', union]);
    }

    /**
     *
     */
    unionTeamGetById(id, aliases){
        return this.get(['team', id]);
    }

    /**
     *
     */
    unionTeamCreate(data){
        return this.post('team', data);
    }

    /**
     *
     */
    unionTeamUpdateById(id, data){
        return this.put(['team', id], data);
    }

    /**
     *
     */
    unionTeamDeleteById(id){
        return this.delete(['union-team', id]);
    }

    /**
     *
     */
    teamSearch(query){
        return this.post(['team', 'search'], query);
    }

    /**
     *
     */
    teamDeleteById(id){
        return this.delete(['team', id]);
    }

    /**
     *
     */
    teamLookupsByEqId(eqId){
        return this.get(['lookups', 'Team', 'byEqId', eqId]);
    }

    // TeamAlias

    /**
     *
     */
    teamAliasCreate(id, data){
        return this.post(['team', id, 'alias'], data);
    }

    /**
     *
     */
    teamAliasUpdate(id, data){
        return this.put(['team', id, 'alias'], data);
    }

    /**
     *
     */
    teamAliasValidFromUpdate(id, data){
        return this.put(['team', id, 'aliases'], data);
    }

    /**
     *
     */
    teamAliasDeleteById(id){
        return this.delete(['team-alias', id]);
    }

    /**
     *
     */
    unionTeamGetTeamNameHistoryById(id){
        return this.get(['team', id, 'aliases']);
    }

    // team type

    /**
     *
     */
    getTeamTypes(){
        return this.get(['team-type']);
    }

    // sport

    /**
     *
     */
    getSports(){
        return this.get(['sport']);
    }

    // team

    /**
     *
     */
    teamById(id){
        return this.get(['team', id]);
    }

    /**
     *
     */
    teamByEqId(eqId){
        return this.get(['lookups', 'Team', 'byEqId', eqId]);
    }

    /**
     *
     */
    teamNameHistoryById(id){
        return this.get(['team', 'name-history', id]);
    }
}
