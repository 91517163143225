<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
    >
        <template v-slot="{ formId, submit }">
            <changePublishStatusForm
                v-bind:dataId="formId"
                v-bind:values="formValues"
                v-bind:errors="errorIds"
                v-on:change="updatePublishStatus"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import changePublishStatusForm from '@/components/forms/changePublishStatus.vue';

/**
 *
 */
export default {
    name: 'changePublishStatusModal',
    extends: base,
    components: {
        changePublishStatusForm,
    },
    data() {
        return {
            matchPublishStatus: '',
        };
    },
    props: {
        publishStatus: {
            type: String,
            default: null,
        },
    },
    methods: {
        updatePublishStatus(values){
            this.matchPublishStatus = values.publishStatus;
        },
    },
    computed: {
        payload(){
            return this.matchPublishStatus;
        },
        formValues(){
            return { publishStatus: this.publishStatus };
        },
    },
};
</script>
