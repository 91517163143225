import PersonModel from '@/models/person.js';
import PositionModel from '@/models/position.js';
import UnionModel from '@/models/union.js';

/**
 * official model class
 */
export default class OfficialModel extends PersonModel {
    /**
     * basic role type
     *
     * @type {string}
     */
    roleType = 'Official';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.name = data.name || data.displayName || '';
            this.eqId = data.eqId || data.matchOfficialEqId || '';
            this.eqIdLinks = data.eqIdLinks || [];
            this.id = data.id || data.matchOfficialId || data.tournamentOfficialId || 0;
            this.matchEqId = data.matchEqId || '';
            this.matchId = data.matchId || 0;
            this.matchOfficialId = data.matchOfficialId || 0;
            this.matchOfficialEqId = data.matchOfficialEqId || '';
            this.personEqId = data.personEqId || '';
            this.personRoleEqId = data.personRoleEqId || '';
            this.personRoleId = data.personRoleId || data.roleId || 0;
            this.position = ((typeof data.position === 'object' && data.position !== null) ? PositionModel.from(data.position) : '') || data.position || {};
            this.positionEqId = data.positionEqId || '';
            this.positionId = data.positionId || 0;
            this.positionName = data.positionName || '';
            this.positionOrder = data.positionOrder || 0;
            this.personId = data.personId || 0;
            this.person = PersonModel.from(data.person) || {};
            this.roleId = data.roleId || 0;
            this.roleName = data.roleName || '';
            this.union = UnionModel.from(data.union);
            this.unionEqId = data.unionEqId || '';

            if (data.unionName && !(typeof data.union === 'object')) {
                this.union = new UnionModel();
                this.union.name = data.unionName;
            }

            if (this.person.id) {
                this.personId = this.person.id;
            }
        }
    }
}
