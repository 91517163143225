<script>
import itemList from '@/components/itemList.vue';
import rankingRow from '@/components/rows/ranking.vue';
import RankingDataModel from '@/models/rankingData.js';
import RankingRunMatchModel from '@/models/rankingRunMatch.js';
import RankingRunModel from '@/models/rankingRun.js';

/**
 * complex ranking list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'rankingList',
    extends: itemList,
    components: {
        rankingRow,
    },
    props: {
        sport: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            //overwrites
            rowElement: rankingRow,
            rowItemPropName: 'rank',
            selectItemProp: 'rank',
            //sortKey: 'name',
            //sortAsc: true,
            years: [],
            currentRunEffectiveDate: '',
            isLive: '',
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showRankIcon: true,
                showPoints: true,
                showPreviousPoints: true,
                showPlusMinus: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'year',
                    type: 'vselect',
                    options: this.years,
                    placeholder: this.$t('selectYear'),
                },
                {
                    id: 'rankingRun',
                    type: 'vselectRankingRun',
                    placeholder: this.$t('selectRankingRun'),
                    valueProperty: 'effectiveDate',
                    data: {
                        sport: this.sport,
                    },
                    dataDependency: {
                        year: true,
                    },
                },
                {
                    id: 'runEffectiveDate',
                    type: 'text',
                    placeholder: this.currentRunEffectiveDate + this.isLive,
                    label: false,
                    disabled: true,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            let request;
            let showCurrentRunEffectiveDate = false;

            this.$emit('loadingData');

            if (filters.rankingRun) {
                request = this.$api.call.rankings.rankingRunGetBySportAndDate(this.sport, filters.rankingRun);
            }
            else if (filters.year) {
                return {
                    items: [],
                    total: 0,
                };
            }
            else {
                showCurrentRunEffectiveDate = true;
                request = this.$api.call.rankings.rankingGetLatest(this.sport);
            }

            return request.then(result => {
                const rankingRun = new RankingRunModel(result);
                this.currentRunEffectiveDate = showCurrentRunEffectiveDate ? rankingRun.label(this) : '';

                switch (rankingRun.isLive) {
                    case true:
                        this.isLive = '        isLive = true';
                        break;
                    case false:
                        this.isLive = '        isLive = false';
                        break;
                    default:
                        this.isLive = '';
                        break;
                }

                const items = ((result || {}).runData || []).map(data => new RankingDataModel(data));

                // get the years
                return this.$api.call.rankings.rankingGetYears(this.sport).then(years => {
                    this.years = years;

                    //load matches data
                    return this.$api.call.rankings.rankingRunGetMatchesBySportAndDate(this.sport, rankingRun.fromDate(rankingRun.effectiveDate)).then(results => {
                        const runMatchTeamMap = {};

                        const runMatches = (results || []).map(data => {
                            const runMatch = new RankingRunMatchModel(data);

                            ['team1EqId', 'team2EqId'].forEach(key => {
                                if(!runMatch[key]){
                                    return;
                                }

                                if(!runMatchTeamMap[runMatch[key]]){
                                    runMatchTeamMap[runMatch[key]] = [];
                                }

                                runMatchTeamMap[runMatch[key]].push(runMatch);
                            });

                            return runMatch;
                        });

                        items.forEach(item => {
                            if(!item.teamEqId || !runMatchTeamMap[item.teamEqId]){
                                return;
                            }

                            item.runMatches = runMatchTeamMap[item.teamEqId];
                        });

                        this.$emit('runLoaded', rankingRun);
                        this.$emit('runMatchesLoaded', runMatches);

                        return {
                            items: this.sortItems(
                                items,
                                sortKey,
                                sortAsc,
                                pageIndex,
                                pageSize,
                            ),
                            total: items.length,
                        };
                    });
                });
            });
        },
    },
    watch: {
        sport() {
            this.resetItems();
        },
    },
};
</script>
