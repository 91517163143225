<script>
import formComponent from '@/components/form.vue';
import constants from '@/constants';

import PersonModel from '@/models/person.js';

/**
 * component
 */
export default {
    name: 'personPassportForm',
    extends: formComponent,
    props: {
        model: {
            type: PersonModel,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'passportFirstName',
                        type: 'text',
                        label: this.$t('firstName'),
                        placeholder: this.$t('firstName'),
                        plaintext: this.readonly,
                        disabled: true,
                        required: true,
                    },
                    {
                        id: 'passportLastName',
                        type: 'text',
                        label: this.$t('lastName'),
                        placeholder: this.$t('lastName'),
                        plaintext: this.readonly,
                        disabled: true,
                        required: true,
                    },
                    {
                        id: 'passportNo',
                        type: 'text',
                        label: this.$t('passportId'),
                        placeholder: this.$t('passportId'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'nationalityCountryIsoCode',
                        type: 'vselectCountry',
                        label: this.$t('nationality'),
                        placeholder: this.$t('selectCountry'),
                        disabled: this.readonly,
                        valueProperty: 'isoCode',
                        required: true,
                    },
                    {
                        id: 'passportExpiryDate',
                        type: 'date',
                        label: this.$t('expiryDate'),
                        placeholder: this.$t('expiryDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'passportIssueDate',
                        type: 'date',
                        label: this.$t('issueDate'),
                        placeholder: this.$t('issueDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'passport',
                        type: 'file',
                        label: this.readonly ? this.$t('image') : this.$t('file'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        readonly: this.readonly,
                        required: this.isFileRequired,
                        previewLarge: this.readonly,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'buttons',
                        type: 'buttons',
                        buttons: [
                            {
                                id: 'download',
                                type: 'button',
                                target: '_blank',
                                href: this.filePreviwUrl,
                                text: this.$t('view'),
                                variant: 'outline-secondary',
                            },
                        ],
                        hidden: (!this.model.passportStorage || (this.model.passport instanceof File)),
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                passportUrl: 'passport',
            };
        },
        isFileRequired(){
            return (this.model?.passportStorage === null || !this.model?.passportStorage?.length);
        },
        filePreviwUrl(){
            if ((!this.model.passportStorage || (this.model.passport instanceof File))){
                return '';
            }

            return this.model?.passport || '';
        },
    },
};
</script>
