<template>
    <div class="pageContent">
        <reviewSeriesList
            v-bind:title="$t('seriesDeclarations')"
            mainTitle
            v-bind:rowProps="rowProps"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import reviewSeriesList from '@/components/lists/review/series.vue';

export default {
    name: 'reviewSeriesHub',
    extends: base,
    components: {
        reviewSeriesList,
    },
    data(){
        return {
            rowProps: {
                showSport: true,
                showDeadline: true,
                showWorkflowStatus: true,
                showWorkflowDeadline: true,
            },
        };
    },
};
</script>
