<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-bind:scrollable="false"
    >
        <template v-slot="{ formId, submit }">
            <replacePlayerForm
                v-bind:dataId="formId"
                v-bind:errors="errorIds"
                v-bind:declaredPlayers="declaredPlayers"
                v-bind:selectedReplacementPlayerName="getSelectedPlayerName"
                v-on:openReplacementPlayerSelectModal="openReplacementPlayerSelectModal"
                v-on:change="updatePlayerToReplaced"
                v-on:submit="submit"
            />

            <playerPickModal
                id="replacementPlayerModal"
                v-bind:title="$t('addPlayer')"
                v-bind:listHideItems="declaredPlayers"
                v-bind:filterValues="{ role: personRole, isPersonActive: true, sport: getSport }"
                v-bind:selectHandler="replacementPlayerSelected"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import replacePlayerForm from '@/components/forms/replacePlayer.vue';
import playerPickModal from '@/components/modals/pick/player.vue';

import constants from '@/constants.js';

export default {
    name: 'replacePlayerModal',
    extends: base,
    components: {
        replacePlayerForm,
        playerPickModal,
    },
    props: {
        declaredPlayers: {
            type: Array,
            default(){
                return [];
            }
        },
        replacementPlayers: {
            type: Array,
            default(){
                return [];
            }
        },
        sport: {
            type: String,
            default(){
                return null;
            }
        },
    },
    data() {
        return {
            replacedPlayerData: null,
            personRole: constants.personRoleType.player,
            selectedReplacementPlayer: null,
            selectedReplacementPlayerName: null,
        };
    },
    computed: {
        payload(){
            return this.replacedPlayerData;
        },
        getSelectedPlayerName(){
            return this.selectedReplacementPlayerName;
        },
        getSport(){
            return this.sport || null;
        },
    },
    methods: {
        updatePlayerToReplaced(values){
            this.replacedPlayerData = {
                action: values.action,
                confirmDoctor: values.confirmDoctor,
                player: values.player,
                replacementPlayer: this.selectedReplacementPlayer,
            };
        },
        openReplacementPlayerSelectModal() {
            this.$bvModal.show('replacementPlayerModal');
        },
        replacementPlayerSelected(player){
            this.selectedReplacementPlayer = player;
            this.selectedReplacementPlayerName = player.displayName;

            this.updatePlayerToReplaced(this.replacedPlayerData || {});
        },
    },
};
</script>
