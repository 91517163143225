<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createCalculations')"
            v-on:submit="createCalculation"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toCalculationList"
                    v-bind:loadingWhile="apiData.calculationCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.calculationCreation"
                >
                    {{ $t('create') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createEditRankingCalculationForm
                    v-bind:model="calculation"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';
import wrapper from '@/components/wrapper.vue';
import validations from '@/components/validations.vue';
import actionButton from '@/components/actionButton.vue';
import createEditRankingCalculationForm from '@/components/forms/createEditRankingCalculation.vue';

import CalculationModel from '@/models/calculations.js';

export default {
    name: 'administrationCreateRankingCalculation',
    extends: rankingBase,
    components: {
        wrapper,
        validations,
        actionButton,
        createEditRankingCalculationForm,
    },
    data(){
        return {
            apiData: {
                calculationCreation: null,
            },
            calculation: CalculationModel.from({}),
            error: null,
        };
    },
    computed: {
        toCalculationList(){
            return { name: 'administrationRankingCalculationsIndex' };
        },
    },
    methods: {
        createCalculation() {
            this.calculation.sport = this.sportId;

            this.apiData.calculationCreation = this.$api.call.rankings.calculationCreate(this.calculation.toJSON()).then(result => {
                this.error = null;
                this.$router.push(this.toCalculationList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('calculation creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
