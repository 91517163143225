import Model from '@/models/model.js';

/**
 * standing team model class
 */
export default class StandingTeamModel extends Model {
    /**
     * team id
     *
     * @type {number}
     */
    teamId = 0;

    /**
     * forced order
     *
     * @type {number}
     */
    forceOrder = 0;

    /**
     * points to be deducted
     *
     * @type {number}
     */
    deductPoints = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.deductPoints = data.deductPoints || 0;
            this.forceOrder = data.forceOrder || 0;
            this.isDeleted = data.isDeleted || false;
            this.teamId = data.teamId || 0;
            this.teamName = data.teamName || '';
            this.tournamentTeamId = data.tournamentTeamId || 0;
        }
    }

    toJSON(){
        return {
            tournamentTeamId: this.tournamentTeamId,
            teamName: this.teamName,
            forceOrder: this.forceOrder,
            deductPoints: this.deductPoints,
            isDeleted: this.isDeleted,
        };
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return '';
    }
}
