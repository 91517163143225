<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editPlace')"
            v-bind:waitFor="apiData.location"
            v-bind:waitForRetry="reloadLocation"
            v-on:submit="updateLocation"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toLocationList"
                    v-bind:loadingWhile="apiData.locationUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.locationUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <editLocationForm
                    v-bind:headline="$t('placeInformation')"
                    v-bind:model="location"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import editLocationForm from '@/components/forms/editLocation.vue';

import LocationModel from '@/models/location.js';

export default {
    name: 'administrationLocationEdit',
    extends: base,
    components: {
        validations,
        editLocationForm,
    },
    props: {
        dataLocationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                location: this.loadLocation(),
                locationUpdate: null,
            },
            location: null,
            error: null,
        };
    },
    computed: {
        toLocationList(){
            return { name: 'administrationLocationIndex' };
        },
    },
    methods: {
        loadLocation(){
            return this.$api.call.location.locationGetById(this.dataLocationId).then(data => {
                this.location = LocationModel.from(data);
            });
        },
        reloadLocation(){
            this.apiData.location = this.loadLocation();
        },
        updateLocation(){
            this.apiData.locationUpdate = this.$api.call.location.locationUpdateById(this.dataLocationId, this.location.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toLocationList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('location update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
