<script>
import formComponent from '@/components/form.vue';
import DeclarationTOPModel from '@/models/declarationTOP.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'matchDeclarationForm',
    extends: formComponent,
    props: {
        model: {
            type: DeclarationTOPModel,
        },
        sport: {
            type: String,
            default: null,
        },
        isFixed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'enabled',
                        type: 'checkbox',
                        text: this.$t('applyTermsOfParticipationRegulations'),
                        disabled: this.isFixed || this.readonly,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'workflowId',
                        type: 'vselectWorkflow',
                        label: this.$t('declarationType'),
                        disabled: this.isFixed || this.readonly,
                        required: true,
                        data: {
                            type: 'Match',
                            sport: this.sport,
                        },
                    },
                ], DeclarationTOPModel.formDependencies.enabled),
                this.createFormRow([
                    {
                        id: 'openingDate',
                        type: 'date',
                        label: this.$t('openingOfDeclaration'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'openingTime',
                        type: 'time',
                        label: this.$t('openingOfDeclarationTime'),
                        placeholder: constants.timeFormat,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'openingGmtOffset',
                        type: 'select',
                        label: this.$t('gmtOffset'),
                        options: constants.gmtOffsets,
                        placeholder: this.$t('select'),
                        disabled: this.readonly,
                        required: true,
                    },
                ], DeclarationTOPModel.formDependencies.workflowId),
                this.createFormRow([
                    {
                        id: 'deadlineDate',
                        type: 'date',
                        label: this.$t('deadlineOfDeclaration'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'deadlineTime',
                        type: 'time',
                        label: this.$t('deadlineOfDeclarationTime'),
                        placeholder: constants.timeFormat,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'deadlineGmtOffset',
                        type: 'select',
                        label: this.$t('gmtOffset'),
                        options: constants.gmtOffsets,
                        placeholder: this.$t('select'),
                        disabled: this.readonly,
                        required: true,
                    },
                ], DeclarationTOPModel.formDependencies.workflowId),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
                this.createFormRow([
                    {
                        id: 'publishOffset',
                        type: 'number',
                        label: this.$t('enterMatchPublishOffset') + ' (' + this.$t('inMinutes') + ')',
                        placeholder: this.$t('enterMatchPublishOffset'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'teamsheetsPublishOffset',
                        type: 'number',
                        label: this.$t('matchTeamsheetsPublishOffset') + ' (' + this.$t('inMinutes') + ')',
                        placeholder: this.$t('enterMatchTeamsheetsPublishOffset'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.workflowId),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
                {
                    type: 'headline',
                    text: this.$t('matchLineUp'),
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
                this.createFormRow([
                    {
                        id: 'squadEnabled',
                        type: 'checkbox',
                        text: this.$t('enableModule'),
                        disabled: true,
                    },
                ], DeclarationTOPModel.formDependencies.workflowId),
                this.createFormRow([
                    {
                        id: 'squadDeadline',
                        type: 'date',
                        label: this.$t('deadlineToSubmit'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'squadDeadlineTime',
                        type: 'time',
                        label: this.$t('deadlineToSubmitTime'),
                        placeholder: constants.timeFormat,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'squadDeadlineGmtOffset',
                        type: 'select',
                        label: this.$t('gmtOffset'),
                        options: constants.gmtOffsets,
                        placeholder: this.$t('select'),
                        disabled: this.readonly,
                        required: true,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.squadEnabled,
                },
                {
                    type: 'headline',
                    text: this.$t('replacements'),
                    dependency: DeclarationTOPModel.formDependencies.squadEnabled,
                },
                this.createFormRow([
                    {
                        id: 'replacementsEnabled',
                        type: 'checkbox',
                        text: this.$t('enableModule'),
                        disabled: true,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled),
                this.createFormRow([
                    {
                        id: 'replacementsMinimumNumberOfPlayers',
                        type: 'number',
                        label: this.$t('minimumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'replacementsMaximumNumberOfPlayers',
                        type: 'number',
                        label: this.$t('maximumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.replacementsEnabled),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.squadEnabled,
                    hidden: !this.isSevens,
                },
                {
                    type: 'headline',
                    text: this.$t('nonPlayerReserve'),
                    dependency: DeclarationTOPModel.formDependencies.squadEnabled,
                    hidden: !this.isSevens,
                },
                this.createFormRow([
                    {
                        id: 'replacementsNonPlayersEnabled',
                        type: 'checkbox',
                        text: this.$t('enableModule'),
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled, !this.isSevens),
                this.createFormRow([
                    {
                        id: 'replacementsMinimumNumberOfNonPlayers',
                        type: 'number',
                        label: this.$t('minimumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'replacementsMaximumNumberOfNonPlayers',
                        type: 'number',
                        label: this.$t('maximumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.replacementsNonPlayersEnabled, !this.isSevens),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        isSevens(){
            return Object.values(constants.sevenSport).includes(this.sport);
        },
    },
};
</script>
