import PersonModel from '@/models/person.js';
import constants from '@/constants.js';

/**
 * medic model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class MedicModel extends PersonModel {
    /**
     * basic role type
     *
     * @type {string}
     */
    roleType = constants.group.medical;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);
        if(data){
            this.personId = data.matchTeamPersonId || data.personId || 0;
            this.eqId = data.eqId || data.personEqId || '';
            this.tournamentTeamPersonId = data.tournamentTeamPersonId || 0;
        }
    }
}
