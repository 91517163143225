<template>
    <b-row
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-col cols="12" lg="6">
            <b-row class="py-2">
                <b-col cols="4" lg="3" class="px-0 label">{{ $t('status') }}</b-col>
                <b-col cols="8" lg="9" class="px-0"><statusIndicatorIcon v-bind:status="upload.status" /> {{ upload.status }}</b-col>
            </b-row>
            <b-row class="py-2">
                <b-col cols="4" lg="3" class="px-0 label">{{ $t('deadline') }}</b-col>
                <b-col cols="8" lg="9" class="px-0">{{ upload.deadline ? $fd(upload.deadline, 'date') : ''}}</b-col>
            </b-row>
            <b-row class="py-2" v-if="canShowRemaining">
                <b-col cols="4" lg="3" class="px-0 label">{{ $t('remaining') }}</b-col>
                <b-col cols="8" lg="9" class="px-0"><countdown v-bind:until="upload.deadline"/></b-col>
            </b-row>
            <b-row class="py-2 d-none d-lg-flex" v-if="!readonly">
                <b-col cols="4" lg="3" class="px-0 label">{{ $t('fileFormat') }}</b-col>
                <b-col cols="8" lg="9" class="px-0">{{ upload.getFormats().join(', ') }}</b-col>
            </b-row>
        </b-col>
        <b-col cols="12" lg="6">
            <b-row class="py-2">
                <b-col cols="4" lg="3" class="px-0 label">{{ upload.title }}</b-col>
                <b-col cols="8" lg="9" class="px-0" v-if="(typeAudio || typeImage) && upload.filePath">
                    <audio controls v-if="typeAudio">
                        <source v-bind:src="upload.filePath" v-bind:type="upload.fileType">
                    </audio>
                    <picture v-if="typeImage">
                        <source v-bind:src="upload.filePath" v-bind:type="upload.fileType">
                        <img v-bind:src="upload.filePath" v-bind:alt="upload.title" />
                    </picture>
                </b-col>
            </b-row>
            <b-row class="py-2" v-if="upload.fileName">
                <b-col cols="4" lg="3" class="px-0 label">{{ $t('fileName') }}</b-col>
                <b-col cols="8" lg="9" class="px-0">{{ upload.fileName }}</b-col>
            </b-row>
            <b-row class="py-2 d-lg-none">
                <b-col cols="4" lg="3" class="px-0 label">{{ $t('fileFormat') }}</b-col>
                <b-col cols="8" lg="9" class="px-0">{{ upload.getFormats().join(', ') }}</b-col>
            </b-row>
            <b-row align-h="end" class="py-2">
                <b-col cols="8" lg="9" class="px-0">
                    <actionButton variant="secondary" v-bind:href="upload.template" target="_blank" class="mr-1 mb-1" v-if="!isReview && upload.template">{{ $t('downloadTemplate') }}</actionButton>
                    <actionButton variant="secondary" v-bind:href="upload.filePath" target="_blank" class="mb-1" v-if="upload.filePath">{{ $t('downloadFile') }}</actionButton>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" v-if="showParticipationAgreementsInfoBox">
            <b-row class="py-2">
                <b-col class="px-0">
                    <validations
                        v-bind:notices="showParticipationAgreementsInfoBox ? [$t('submittingParticipationAgreementsInfo')] : undefined"
                        v-bind:warnings="(showParticipationAgreementsInfoBox && !isParticipationAgreementSubmitted) ? [$t('submitParticipationAgreement')] : undefined"
                        noMargin
                    />
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" v-if="showTeamLogoSubmitMessages">
            <b-row class="py-2">
                <b-col class="px-0">
                    <validations
                        v-bind:successes="(showTeamLogoSubmitMessages && isTeamLogoSubmitted) ? [$t('submittedTeamLogo')] : undefined"
                        v-bind:warnings="(showTeamLogoSubmitMessages && !isTeamLogoSubmitted) ? [$t('submitTeamLogo')] : undefined"
                        v-bind:type="type"
                        v-bind:items="items"
                        noMargin
                    />
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" v-if="showNationalAnthemSubmitMessages">
            <b-row class="py-2">
                <b-col class="px-0">
                    <validations
                        v-bind:successes="(showNationalAnthemSubmitMessages && isNationalAnthemSubmitted) ? [$t('submittedNationalAnthem')] : undefined"
                        v-bind:items="items"
                        noMargin
                    />
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" v-if="showTeamKitsInfoBox">
            <b-row class="py-2">
                <b-col class="px-0">
                    <validations
                        v-bind:notices="showTeamKitsInfoBox ? [$t('submittingParticipationAgreementsInfo')] : undefined"
                        noMargin
                    />
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" v-if="showTeamPhoneticGuideSubmitMessages">
            <b-row class="py-2">
                <b-col class="px-0">
                    <validations
                        v-bind:successes="(showTeamPhoneticGuideSubmitMessages && isTeamPhoneticGuideSubmitted) ? [$t('submittedTeamPhoneticGuide')] : undefined"
                        v-bind:items="items"
                        noMargin
                    />
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" class="px-0" v-if="!readonly">
            <b-row>
                <b-col align-self="stretch">
                    <fileElement
                        v-bind:dataConfig="{
                            id: 'upload_' + this.upload.fileId,
                            previewOnTop: true,
                            accept,
                            readonly,
                        }"
                        v-bind:dataValue="upload.filePath"
                        v-on:change="updateFile"
                    />
                </b-col>
                <b-col cols="1" class="align-text-bottom" align-self="end" v-if="showLoadingThrobber && loading">
                    <throbber small class="align-middle mb-4" />
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import base from './base.vue';
import statusIndicatorIcon from '../statusIndicatorIcon.vue';
import fileElement from '@/components/elements/file.vue';
import validations from '@/components/validations.vue';
import countdown from '@/components/countdown.vue';
import throbber from '@/components/throbber.vue';

import UploadModel from '@/models/upload.js';
import AudioUploadModel from '@/models/upload/audio.js';
import DocumentUploadModel from '@/models/upload/document.js';
import ImageUploadModel from '@/models/upload/image.js';
import DocumentAndImageUploadModel from '@/models/upload/documentAndImage.js';

import constants from '@/constants.js';

/**
 * upload row component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'uploadRow',
    extends: base,
    components: {
        statusIndicatorIcon,
        fileElement,
        validations,
        countdown,
        throbber,
    },
    data(){
        return {
            loading: false,
            waitingFor: null,
        };
    },
    props: {
        /**
         * upload object
         */
        upload: {
            type: UploadModel,
            required: true,
            default(){
                return new UploadModel();
            }
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        accept: {
            type: String,
            required: false,
            default: constants.fileAcceptance.images,
        },
        showRemaining: {
            type: Boolean,
            required: false,
            default: false,
        },
        showParticipationAgreementsInfoBox: {
            type: Boolean,
            required: false,
            default: false,
        },
        isParticipationAgreementSubmitted: {
            type: Boolean,
            required: false,
            default: false,
        },
        showTeamLogoSubmitMessages: {
            type: Boolean,
            required: false,
            default: false,
        },
        isTeamLogoSubmitted: {
            type: Boolean,
            required: false,
            default: false,
        },
        isReview: {
            type: Boolean,
            required: false,
            default: false,
        },
        showNationalAnthemSubmitMessages: {
            type: Boolean,
            required: false,
            default: false,
        },
        isNationalAnthemSubmitted: {
            type: Boolean,
            required: false,
            default: false,
        },
        showTeamPhoneticGuideSubmitMessages: {
            type: Boolean,
            required: false,
            default: false,
        },
        isTeamPhoneticGuideSubmitted: {
            type: Boolean,
            required: false,
            default: false,
        },
        showTeamKitsInfoBox: {
            type: Boolean,
            required: false,
            default: false,
        },
        type: {
            type: String,
            required: false,
            default: '',
        },
        items: {
            type: Array,
            required: false,
            default(){
                return [];
            }
        },
        /**
         * promise during which upload method queue is processing
         */
        loadingWhile: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * show throbber during loading state
         */
        loadingThrobber: {
            type: Boolean,
            required: false,
            default: null,
        },
    },
    computed: {
        emitEventData(){
            return {
                upload: this.upload || null,
            };
        },
        typeAudio(){
            return (this.upload instanceof AudioUploadModel);
        },
        typeDocument(){
            if (this.upload instanceof DocumentAndImageUploadModel && this.upload.filePath) {
                return this.upload.filePath.indexOf('.pdf') !== -1 || this.upload.filePath.indexOf('.txt') !== -1;
            }
            return (this.upload instanceof DocumentUploadModel);
        },
        typeImage(){
            if (this.upload instanceof DocumentAndImageUploadModel && this.upload.filePath) {
                return this.upload.filePath.indexOf('.png') !== -1 || this.upload.filePath.indexOf('.jpg') !== -1 || this.upload.filePath.indexOf('.jpeg') !== -1;
            }
            return (this.upload instanceof ImageUploadModel);
        },
        canShowRemaining(){
            return (this.showRemaining && this.upload.deadline);
        },
        showLoadingThrobber(){
            return (this.loadingThrobber);
        },
    },
    methods: {
        updateFile(file){
            this.upload.setFile(file);
            this.emitEvent('change');
        },
        loadingWhilePromise(promise){
            //if no promise given, set as not loading
            if(promise === null){
                this.loading = false;
                this.waitingFor = null;

                return;
            }

            //if promise given, wait for it
            this.loading = true;
            this.waitingFor = promise;

            //waiting...
            promise.then(result => result, error => error).then(() => {
                //make sure the promise did not change in the meantime
                if(this.waitingFor !== promise){
                    return;
                }

                this.loading = false;
                this.waitingFor = null;
            });
        },
    },
    watch: {
        loadingWhile(promise){
            this.loadingWhilePromise(promise);
        },
    },
    created(){
        this.loadingWhilePromise(this.loadingWhile);
    },
    destroyed(){
        this.loadingWhilePromise(null);
    },
};
</script>
