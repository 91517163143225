<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('schoolName', 1)" v-bind:title-tag="titleTag" v-if="schoolName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('schoolName')" v-if="isHeadline">{{ getHeadline('schoolName', $t('schoolName')) }}</label>
            <span v-else>{{ school.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('country', 12, 6, 2)" v-if="country">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('country')">{{ getHeadline('country', $t('country')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ school.country }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDeleteSchool', school.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDeleteSchool', school.id)"
            v-bind:text="$t('areYouSureToDelete', { type: school.name })"
            v-bind:data="school"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
            v-if="showRemove"
        />
    </b-card>
</template>

<script>
import base from './base.vue';

import SchoolModel from '@/models/school.js';

/**
 * team block component
 */
export default {
    name: 'schoolRow',
    extends: base,
    props: {
        school: {
            type: SchoolModel,
            default(){
                return new SchoolModel();
            }
        },

        schoolName: {
            type: Boolean,
            default: true,
        },
        country: {
            type: Boolean,
            default: false,
        },

        showDetail: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || '',
                params: Object.assign({
                    dataSchoolId: this.school.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                school: this.school || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showRemove);
        },
    },
};
</script>
