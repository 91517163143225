<template>
    <div class="pageContent">
        <declarationTournamentList
            v-bind:title="$t('tournamentDeclarations')"
            mainTitle
            v-bind:rowProps="rowProps"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import declarationTournamentList from '@/components/lists/declaration/tournament.vue';

export default {
    name: 'declarationTournamentIndex',
    extends: base,
    components: {
        declarationTournamentList,
    },
    data(){
        return {
            rowProps: {
                showWorkflowStatus: true,
                showWorkflowDeadline: true,
            },
        };
    },
};
</script>
