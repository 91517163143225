<script>
import itemList from '@/components/itemList.vue';
import PersonRow from '@/components/rows/person.vue';
import PersonModel from '@/models/person.js';

/**
 * complex team list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'personList',
    extends: itemList,
    components: {
        PersonRow,
    },
    data(){
        return {
            //overwrites
            rowElement: PersonRow,
            rowItemPropName: 'person',
            selectItemProp: 'person',
            //sortKey: 'name',
            //sortAsc: true,
            ItemModel: PersonModel,
            personRole: null,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByPersonName'),
                },
                {
                    id: 'role',
                    type: 'vselectPersonRole',
                    placeholder: this.$t('filterByRole'),
                    valueProperty: 'name',
                    //hidden: !this.combinedRowProps.showRole,
                },
                {
                    id: 'lastAppearance',
                    type: 'vselect',
                    placeholder: this.$t('lastAppearance'),
                    options: [
                        {
                            id: 'all',
                            label: this.$t('all'),
                        },
                        {
                            id: 'last5years',
                            label: this.$t('last5years'),
                        },
                        {
                            id: 'last10years',
                            label: this.$t('last10years'),
                        },
                        {
                            id: 'last15years',
                            label: this.$t('last15years'),
                        },
                    ],
                },
                {
                    id: 'sport',
                    type: 'vselectSport',
                    placeholder: this.$t('filterBySport'),
                    disabled: this.combinedRowProps.disableSportFilter,
                    //hidden: !this.combinedRowProps.showSport,
                },
                {
                    id: 'dateFrom',
                    type: 'date',
                    placeholder: this.$t('dateFrom'),
                    //hidden: !this.combinedRowProps.showDateFrom,
                },
                {
                    id: 'dateUntil',
                    type: 'date',
                    placeholder: this.$t('dateUntil'),
                    //hidden: !this.combinedRowProps.showDateUntil,
                },
                {
                    id: 'team',
                    type: 'pickTeam',
                    placeholder: this.$t('filterByTeam'),
                    //hidden: !this.combinedRowProps.showTeam,
                },
                {
                    id: 'isPersonActive',
                    type: 'checkbox',
                    text: this.$t('hasAppearanceInLast15years'),
                    class: 'py-1',
                },
            ]);
        },
        filterDisplayDefault(){
            return {
                lastAppearance: false,
            };
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load persons
            return this.$api.call.person.personSearch({
                displayName: filters.name || null,
                roleType: filters.role || this.personRole || null,
                sport: filters.sport || null,
                dateFrom: filters.dateFrom || null,
                dateUntil: filters.dateUntil || null,
                teamEqId: filters.team ? filters.team.eqId : null,
                isPersonActive: filters.isPersonActive || null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.persons || result.items || []).map(data => new this.ItemModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
    },
};
</script>
