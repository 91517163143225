<script>
import formComponent from '@/components/form.vue';

import TeamModel from '@/models/team.js';

/**
 * component
 */
export default {
    name: 'changeTeamStatusForm',
    extends: formComponent,
    props: {
        model: {
            type: TeamModel,
        },
        tournamentTeamStatuses: {
            type: Array,
            required: true,
            default(){
                return [];
            }
        },
    },
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: false,
                        placeholder: '',
                        class: 'mb-0',
                        text: '7',
                        value: '6',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        readonly: true,
                    },
                    {
                        id: 'tournamentTeamStatus',
                        type: 'vselect',
                        options: this.tournamentTeamStatuses,
                        label: null,
                        required: true,
                    },
                ], null, null, 2, 2, 2),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
