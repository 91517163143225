import Model from '@/models/model.js';
import { formatDateSimple } from '@/services/formatDate.js';

/**
 * ranking run model class
 */
export default class RankingRunModel extends Model {
    /**
     * id
     *
     * @type {int}
     */
    id = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.rankingRunId || 0;
            this.eqId = data.eqId || '';
            this.effectiveDate = this.toDate(data.effectiveDate);
            this.runTimestamp = this.toDate(data.runTimestamp) || this.toDate(data.timestamp);
            this.hasChanged = data.hasChanged || false;
            this.isLive = data.isLive || null;
            this.sport = data.sport || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        if(vue){
            return vue.$fd(this.effectiveDate);
        }

        return formatDateSimple(this.effectiveDate);
    }
}
