<template>
    <div v-bind:class="elementClasses">
        <label>{{ labelText }}</label>
        <div>
            <button v-on:click="addItem" v-bind:disabled="this.config.max > 0 && this.values.length >= this.config.max">+</button>
        </div>
        <ul>
            <li v-for="(item, index) in values" v-bind:key="index">
                <div>
                    <button v-on:click="removeItem(index)" v-bind:disabled="config.min > 0 && values.length <= config.min">-</button>
                    <button v-on:click="moveUpItem(index)" v-bind:disabled="index <= 0">up</button>
                    <button v-on:click="moveDownItem(index)" v-bind:disabled="index >= values.length -1">down</button>
                </div>
                <component
                    v-bind:is="element.type + 'Element'"
                    v-for="element in variables"
                    v-show="!element.hidden && checkDependency(element.dependency, index)"
                    v-bind:key="itemKeys[index] + '_' + element.id"
                    v-bind:data-config="element" v-bind:data-value="element.id ? values[index][element.id] : undefined"
                    v-bind:data-index="index"
                    v-on:change="onElementChange(index, element.id, ...arguments)"
                    v-on:set="onElementSet(index, ...arguments)"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import base from './base.vue';
import elementComponents from './all.js';

/**
 * list element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'listElement',
    extends: base,
    components: elementComponents,
    props: {
        dataValue: {
            type: [Array],
            required: false,
            default: null,
        },
    },
    data(){
        return {
            values: this.dataValue || [],
            itemKeys: [],
            nextItemKey: 0,
            doDependencyChecks: false
        };
    },
    computed: {
        variables(){
            return this.config.variables || [];
        },
    },
    methods: {
        onElementChange(index, id, value){
            this.$set(this.values[index], id, value);
        },
        onElementSet(index, id, value){
            this.$set(this.values, id, value);
        },
        checkDependency(dependency, index){
            if(!dependency || !this.doDependencyChecks){
                return true;
            }

            let setValues = '';
            this.getParentValues().forEach((parentValues) => {
                for(const key in parentValues){
                    if(Object.prototype.hasOwnProperty.call(parentValues, key)){
                        setValues += 'let ' + key + ' = ' + JSON.stringify(parentValues[key]) + '; ';
                    }
                }
            });
            for(const key in this.values[index]){
                if(Object.prototype.hasOwnProperty.call(this.values[index], key)){
                    setValues += 'let ' + key + ' = ' + JSON.stringify(this.values[index][key]) + '; ';
                }
            }

            try {
                return Boolean(eval(setValues + dependency));
            }
            catch(e){
                return false;
            }
        },
        getParentValues(){
            const values = [];
            let instance = this.$parent;

            while(instance){
                if(instance.values){
                    values.unshift(instance.values);
                }

                //stop going deeper after finding an elements block
                if(instance.$options.name === 'elementsBlock'){
                    break;
                }

                instance = instance.$parent
            }

            return values;
        },
        addItem(){
            if(this.config.max > 0 && this.values.length >= this.config.max){
                return;
            }

            this.values.push({});
            this.itemKeys.push(this.nextItemKey);
            this.nextItemKey++;
        },
        removeItem(index){
            if(this.config.min > 0 && this.values.length <= this.config.min){
                return;
            }

            this.values.splice(index, 1);
            this.itemKeys.splice(index, 1);
        },
        moveUpItem(index){
            if(index <= 0){
                return;
            }

            this.values.splice(index - 1, 0, this.values.splice(index, 1).shift());
            this.itemKeys.splice(index - 1, 0, this.itemKeys.splice(index, 1).shift());
        },
        moveDownItem(index){
            if(index >= this.values.length - 1){
                return;
            }

            this.values.splice(index + 1, 0, this.values.splice(index, 1).shift());
            this.itemKeys.splice(index + 1, 0, this.itemKeys.splice(index, 1).shift());
        },
    },
    watch: {
        values: {
            deep: true,
            handler(){
                this.$emit('change', this.values);
            }
        }
    },
    created(){
        //if initialized with data, enable dependency checks from the start
        if(this.values.length > 0){
            this.doDependencyChecks = true;
        }

        if(this.config.min > 0){
            for(let i = this.values.length; i < this.config.min; i++){
                this.addItem();
            }
        }

        while(this.values.length > this.itemKeys.length){
            this.itemKeys.push(this.nextItemKey);
            this.nextItemKey++;
        }
    },
    updated(){
        //enable dependancy checks after initial rendering is done
        if(!this.doDependencyChecks){
            this.$nextTick(() => {
                this.doDependencyChecks = true;
            });
        }
    },
};
</script>
