import Model from '@/models/model.js';
import DeclarationModel from '@/models/declaration.js';
import TeamModel from '@/models/team.js';
import PersonModel from '@/models/person.js';
import constants from '@/constants.js';

/**
 * team declaration model class
 */
export default class DeclarationTeamModel extends DeclarationModel {
    /**
     * participation agreement to upload
     *
     * @type {File}
     */
    participationAgreementUpload = null;

    /**
     * team logo to upload
     *
     * @type {File}
     */
    teamLogoUpload = null;

    /**
     * national anthem to upload
     *
     * @type {File}
     */
    nationalAnthemUpload = null;

    /**
     * medical insurance cover to upload
     *
     * @type {File}
     */
    medicalInsuranceCoverUpload = null;

    /**
     * team disciplinary certificate to upload
     *
     * @type {File}
     */
    teamDisciplinaryCertificateUpload = null;

    /**
     * team member acceptance form certificate to upload
     *
     * @type {File}
     */
    teamMemberAcceptanceFormUpload = null;

    /**
     * team phonetic guide to upload
     *
     * @type {File}
     */
    teamPhoneticGuideUpload = null;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.configuration = data.steps ? { steps: data.steps } : null;
            this.id = data.id || null;
            this.kits = data.kits || [];
            this.persons = data.persons || [];
            this.status = data.status || null;
            this.stepStatus = this.getCurrentStepStatus();
            this.team = TeamModel.from(data.team);
            this.showTeamLogo = data.showTeamLogo || false;
        }
    }

    getCurrentStepStatus(){
        let stepStatus = null;
        if (this.configuration) {
            stepStatus = this.configuration.steps.find(step => step.name === this.step)?.status || this.status || null;
        }

        return stepStatus;
    }

    addPerson(person){
        person.useDeclarationStorage();
        this.persons.push(person);
    }

    addPersons(persons){
        this.persons.push(...persons.filter(person => person instanceof PersonModel).map(person => {
            person.useDeclarationStorage();
            return person;
        }));
    }

    removePerson(person){
        this.persons = this.persons.filter(currentPerson => currentPerson.id !== person.id);
    }

    setPersons(persons = []){
        this.persons = persons.filter(person => person instanceof PersonModel).map(person => {
            const isHeadshotModule = (this.existsInConfig(this.configTargets.squadPersonHeadshots) || this.existsInConfig(this.configTargets.preliminarySquadPersonHeadshots));
            person.useDeclarationStorage(true, isHeadshotModule);

            return person;
        });
    }

    getPersons(){
        return this.persons || [];
    }

    getStartingPlayers(){
        return this.getPersons().filter(player => player && player.group === constants.group.player);
    }

    getReplacementPlayers(){
        return this.getPersons().filter(player => player && player.group === constants.group.replacementPlayer);
    }

    getNonPlayingReserve(){
        return this.getPersons().filter(player => player && player.group === constants.group.nonPlayingReserve);
    }

    findPerson(id){
        return this.persons.find(person => person.id === id) || null;
    }

    addKit(kit, index = 0){
        kit.useDeclarationStorage();
        this.kits.splice(index, 0, kit);
    }

    getKits(){
        return this.kits || [];
    }

    getParticipationAgreement(){
        const storagePath = this.findInConfigurationFields(this.configTargets.participationAgreement, 'file');
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    setParticipationAgreement(filePath){
        return this.setConfigurationField(this.configTargets.participationAgreement, 'file', filePath);
    }

    getTeamLogo(){
        const storagePath = this.findInConfigurationFields(this.configTargets.teamLogo, 'file');
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    setTeamLogo(filePath){
        return this.setConfigurationField(this.configTargets.teamLogo, 'template-url', filePath);
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPathTournament(){
        if (this.getTeamLogo()) {
            return this.getTeamLogo();
        }
        if(this.showTeamLogo && this.team){
            return this.team.getImgPath();
        }
        return require('@/assets/img/series.svg');
    }

    /**
     * get union team image path
     *
     * @returns {string}
     */
    getUnionTeamLogo(){
        if (this.team && this.team.logo) {
            return this.team.logo;
        }
        return require('@/assets/img/series.svg');
    }

    getNationalAnthem(){
        const storagePath = this.findInConfigurationFields(this.configTargets.nationalAnthem, 'file');
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    setNationalAnthem(filePath){
        return this.setConfigurationField(this.configTargets.nationalAnthem, 'file', filePath);
    }

    getMedicalInsuranceCover(){
        const storagePath = this.findInConfigurationFields(this.configTargets.medicalInsuranceCover, 'file');
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    setMedicalInsuranceCover(filePath){
        return this.setConfigurationField(this.configTargets.medicalInsuranceCover, 'file', filePath);
    }

    getTeamDisciplinaryCertificate(){
        const storagePath = this.findInConfigurationFields(this.configTargets.teamDisciplinaryCertificate, 'file');
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    setTeamDisciplinaryCertificate(filePath){
        return this.setConfigurationField(this.configTargets.teamDisciplinaryCertificate, 'file', filePath);
    }

    getTeamMemberAcceptanceForm(){
        const storagePath = this.findInConfigurationFields(this.configTargets.teamMemberAcceptanceForm, 'file');
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    setTeamMemberAcceptanceForm(filePath){
        return this.setConfigurationField(this.configTargets.teamMemberAcceptanceForm, 'file', filePath);
    }

    getTeamPhoneticGuide(){
        const storagePath = this.findInConfigurationFields(this.configTargets.teamPhoneticGuide, 'file');
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    setTeamPhoneticGuide(filePath){
        return this.setConfigurationField(this.configTargets.teamPhoneticGuide, 'file', filePath);
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return '';
    }
}
