<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToMatchDetailsLink"
        >
            <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
        </actionButton>

        <matchReportsHeader
            v-bind:waitFor="apiData.timelineReport"
            v-bind:waitForRetry="reloadTimelineReport"
            v-bind:title="$t('matchHighlights')"
            v-bind:data="timelineReport"
            v-bind:teamOneHeadCoach="teamOneHeadCoach"
            v-bind:teamTwoHeadCoach="teamTwoHeadCoach"
        />

        <list
            v-bind:items="filteredTimeline"
            v-bind:waitFor="apiData.timelineReport"
            v-bind:waitForRetry="reloadTimelineReport"
            v-if="filteredTimeline"
        >
            <template v-slot="{ item: timeline }">
                <matchHighlightRow
                    showTeamOneIcon showTeamOneMinutes showTeamOnePlayerNameAndAction showScore showTeamTwoIcon showTeamTwoMinutes showTeamTwoPlayerNameAndAction
                    v-bind:timeline="timeline"
                    v-bind:teamOneName="teamOneName"
                />
            </template>
        </list>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import constants from '@/constants.js';

import matchReportsHeader from '@/components/matchReportsHeader.vue';
import matchHighlightRow from '@/components/rows/matchHighlight.vue';
import MatchTimelineReportModel from '@/models/matchTimelineReport.js';

export default {
    name: 'commonMatchHighlights',
    extends: base,
    components: {
        icon,
        matchReportsHeader,
        matchHighlightRow,
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                timelineReport: this.loadTimelineReport(),
            },
            timelineReport: null,
            filteredTimeline: [],
            teamOneName: '',
            teamOneHeadCoach: '',
            teamTwoHeadCoach: '',

        };
    },
    computed: {
        backToMatchDetailsLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
    },
    methods: {
        loadTimelineReport(){
            return this.$api.call.match.matchReportsTimelineGetByMatchId(this.dataMatchId).then(result => {
                this.timelineReport = new MatchTimelineReportModel(result);

                this.filteredTimeline = this.timelineReport.timeline.filter(timeline =>
                    timeline.actionTypeGroup === 'Score' ||
                    timeline.actionTypeGroup === 'Card' ||
                    timeline.actionTypeGroup === 'Replacement');

                const teamOne = this.timelineReport.teams.find(team => team.team.isTeam1 === true);
                if (teamOne){
                    this.teamOneName = teamOne.team.name;
                }

                return Promise.all((result.match.teams || []).map(team => {
                    return this.$api.call.match.matchTeamManagmentGetByRole(team.matchTeamId, constants.personRoleType.management).then(resultManagement => {
                        const headCoach = resultManagement.matchTeamPersons.find(person => person.position.positionName === 'Head Coach');
                        if (team.isTeam1){
                            this.teamOneHeadCoach = headCoach?.personRole?.displayName || '';
                        }
                        else
                        {
                            this.teamTwoHeadCoach = headCoach?.personRole?.displayName || '';
                        }
                    });
                }));
            });
        },
        reloadTimelineReport(){
            this.apiData.timelineReport = this.loadTimelineReport();
        },
    },
};
</script>
