import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationRankingCalculationsRankingUnionCreate from '@/views/administration/rankings/calculations/rankingUnion/create.vue';
import administrationRankingCalculationsRankingUnionEdit from '@/views/administration/rankings/calculations/rankingUnion/edit.vue';

export default {
    path: 'ranking-union/',
    component: pageFrame,
    children: [
        {
            name: 'administrationRankingCalculationsRankingUnionCreate',
            path: constants.routePaths.create,
            component: administrationRankingCalculationsRankingUnionCreate,
            props: true,
            meta: {
                permission: permissions.rankings.teams.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.rankingUnionId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationRankingCalculationsRankingUnionEdit',
                    path: constants.routePaths.edit,
                    component: administrationRankingCalculationsRankingUnionEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.rankingUnionId]),
                    meta: {
                        permission: permissions.rankings.teams.update,
                    },
                },
            ],
        },
    ],
};
