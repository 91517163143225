var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageContent"},[_c('wrapper',{attrs:{"title":_vm.$t('seriesTeamEdit'),"mainTitle":"","isForm":"","doubleButtons":"","noShadow":"","noLine":"","noPadding":""},on:{"submit":_vm.updateSeriesTeam},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('actionButton',{attrs:{"to":_vm.toSeriesTeamDetail,"loadingWhile":_vm.apiData.seriesTeamUpdate}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('actionButton',{attrs:{"type":"submit","loadingWhile":_vm.apiData.seriesTeamUpdate}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])]},proxy:true},{key:"default",fn:function(){return [_c('wrapper',{attrs:{"waitFor":_vm.apiData.seriesTeam,"waitForRetry":_vm.reloadSeriesTeam},scopedSlots:_vm._u([(_vm.error)?{key:"header",fn:function(){return [(_vm.error)?_c('validations',{attrs:{"errors":_vm.error.getErrorMessages(_vm.$t('requestError'))}}):_vm._e()]},proxy:true}:null,{key:"default",fn:function(){return [_c('editSeriesTeamForm',{attrs:{"values":_vm.seriesTeamFormValues,"model":_vm.seriesTeam}})]},proxy:true}],null,true)}),_c('subNavigation',{attrs:{"data-config":_vm.subnavigation}}),_c('list',{attrs:{"title":_vm.$t('seriesTeamPlayers'),"items":_vm.seriesTeamPlayers,"waitFor":_vm.apiData.seriesTeam,"waitForRetry":_vm.reloadSeriesTeam,"draggable":"","hide":_vm.hideElement(1)},on:{"change":_vm.updatePlayerOrder},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('playerRow',{attrs:{"showDetail":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('playerRow',{attrs:{"showDetail":"","showRemove":"","person":person,"role":role,"removeHandler":_vm.removePlayer}})]}},{key:"empty",fn:function(){return [_c('div')]},proxy:true},{key:"footer",fn:function(){return [_c('simpleRow',{directives:[{name:"b-modal",rawName:"v-b-modal.playerPick",modifiers:{"playerPick":true}}],attrs:{"text":_vm.$t('clickToAdd', { type: _vm.$tc('player') }),"showAdd":""}})]},proxy:true}])}),_c('list',{attrs:{"title":_vm.$t('seriesTeamManagement'),"items":_vm.seriesTeamManagement,"waitFor":_vm.apiData.seriesTeam,"waitForRetry":_vm.reloadSeriesTeam,"draggable":"","hide":_vm.hideElement(2)},on:{"change":_vm.updateManagerOrder},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('managerRow',{attrs:{"showDetail":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('managerRow',{attrs:{"showDetail":"","showRemove":"","person":person,"role":role,"removeHandler":_vm.removeManager}})]}},{key:"empty",fn:function(){return [_c('div')]},proxy:true},{key:"footer",fn:function(){return [_c('simpleRow',{directives:[{name:"b-modal",rawName:"v-b-modal.managerPick",modifiers:{"managerPick":true}}],attrs:{"text":_vm.$t('clickToAdd', { type: _vm.$tc('manager') }),"showAdd":""}})]},proxy:true}])}),_c('list',{attrs:{"title":_vm.$t('seriesTeamMedics'),"items":_vm.seriesTeamMedic,"waitFor":_vm.apiData.seriesTeam,"waitForRetry":_vm.reloadSeriesTeam,"draggable":"","hide":_vm.hideElement(3)},on:{"change":_vm.updateMedicOrder},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('medicRow',{attrs:{"showDetail":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('medicRow',{attrs:{"showDetail":"","showRemove":"","person":person,"role":role,"removeHandler":_vm.removeMedic}})]}},{key:"empty",fn:function(){return [_c('div')]},proxy:true},{key:"footer",fn:function(){return [_c('simpleRow',{directives:[{name:"b-modal",rawName:"v-b-modal.medicPick",modifiers:{"medicPick":true}}],attrs:{"text":_vm.$t('clickToAdd', { type: _vm.$tc('medic') }),"showAdd":""}})]},proxy:true}])})]},proxy:true}])}),_c('playerPickModal',{attrs:{"id":"playerPick","multiselect":"","title":_vm.$t('addPlayer'),"filterDisplay":_vm.pickerFilterDisplay,"filterValues":{ role: _vm.constants.personRoleType.player, sport: _vm.sport, team: _vm.getTeam },"rowProps":_vm.pickerRowProps,"listHideItems":_vm.selectedSeriesTeamPlayers,"selectHandler":_vm.addPlayers}}),_c('managerPickModal',{attrs:{"id":"managerPick","multiselect":"","title":_vm.$t('addManager'),"filterDisplay":_vm.pickerFilterDisplay,"filterValues":{ role: _vm.constants.personRoleType.management, sport: _vm.sport, team: _vm.getTeam },"rowProps":_vm.pickerRowProps,"listHideItems":_vm.selectedSeriesTeamManagementAndMedics,"selectHandler":_vm.addManagers}}),_c('medicPickModal',{attrs:{"id":"medicPick","multiselect":"","title":_vm.$t('addMedic'),"filterDisplay":_vm.pickerFilterDisplay,"filterValues":{ role: _vm.constants.personRoleType.management, sport: _vm.sport, team: _vm.getTeam },"rowProps":_vm.pickerRowProps,"listHideItems":_vm.selectedSeriesTeamManagementAndMedics,"selectHandler":_vm.addMedics}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }