/*jshint sub:true*/
import constants from '@/constants.js';

import MappingProviderModel from '@/models/mappingProvider.js';

import MatchModel from '@/models/match.js';
import PersonModel from '@/models/person.js';
import PersonRoleModel from '@/models/personRole.js';
import SeriesModel from '@/models/series.js';
import TeamModel from '@/models/team.js';
import TournamentModel from '@/models/tournament.js';
import VenueModel from '@/models/venue.js';

export const entityMappingCreateMixins = {
    data(){
        return {
            apiData: {
                mappingProviders: (this.loadMappingProviders ? this.loadMappingProviders() : null),
            },
        };
    },
    methods: {
        loadEntityMappingProviders(entity){
            return this.$api.call.mapping.mappingGetProviders().then(data => {
                // TODO: this mixin is not used for personRole. But we need to guards elsewhere to ensure PersonRole mappings is derived from Person
                return (data || []).map(mappingProvidersData => MappingProviderModel.from(mappingProvidersData));
            }).then(mappingProviderModels => {
                entity.mappingProviders = mappingProviderModels;

                //dynamically init properties for form component
                mappingProviderModels.forEach(mappingProvider => {
                    this.$set(entity, mappingProvider.name.toLowerCase(), '');
                });

                this.setEntity(entity);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('get mapping providers failed', error);
                }

                throw error;
            });
        },
        createMappings(entity){
            const payloads = entity.buildMappingPayloads().filter(payload => payload.id && payload.id.length);

            return Promise.allSettled(payloads.map(mappingPayload => {
                return this.$api.call.mapping.mappingCreate(mappingPayload).catch(error => {
                    if(this.$log){
                        this.$log.warn(mappingPayload.provider + ' create mapping failed', error);
                    }

                    throw error;
                });
            })).then(results => {
                //get failed promise results
                const failedResults = results.filter(result => result.status === 'rejected');

                if (!failedResults || (failedResults && !failedResults.length)){
                    this.error = null;
                    this.mappingWarning = null;

                    return results;
                }

                let errors = {};

                //build errors object with lowercase keys
                failedResults.forEach(result => {
                    Object.keys(result.reason.errors).forEach(key => {
                        errors[key.toLowerCase()] = result.reason.errors[key]
                    });
                });

                //get request error object from first promise result
                let requestError = failedResults.shift().reason;

                //assign errors object to request error object
                requestError.errors = errors;

                this.mappingWarning = this.$t(this.modelNameToConstant(entity) + 'MappingCreationWarning');

                throw requestError;
            });
        },
        createMappingsUsingQueue(entity, queue){
            if (!entity.eqId){
                return queue;
            }

            const payloads = entity.buildMappingPayloads().filter(payload => payload.id && payload.id.length);
            if (!payloads.length){
                this.error = null;
                this.mappingWarning = null;

                return queue;
            }

            payloads.forEach(mappingPayload => {
                if (this.error?.errors[mappingPayload.provider.toLowerCase()] || this.error?.errors[mappingPayload.provider]){
                    queue = queue.then(() => this.$api.call.mapping.mappingCreate(mappingPayload).then(() => {
                        if (this.error?.errors[mappingPayload.provider.toLowerCase()]){
                            delete this.error?.errors[mappingPayload.provider.toLowerCase()];
                        }
                        if (this.error?.errors[mappingPayload.provider]){
                            delete this.error?.errors[mappingPayload.provider];
                        }

                        if (Object.keys(this.error?.errors || {}).length === 0){
                            this.error = null;
                            this.mappingWarning = null;
                        }
                    }).catch(error => {
                        if(this.$log){
                            this.$log.warn(mappingPayload.provider + ' create mapping failed', error);
                        }

                        this.mappingWarning = this.$t(this.modelNameToConstant(entity) + 'MappingCreationWarning');
                        error.errorIdParser = this.entityMappingErrorIdParser.bind(entity);

                        throw error;
                    }));
                }
            });

            return queue;
        },
    },
};

export const entityMappingEditMixins = {
    methods: {
        loadEntityMappingProviders(entity){
            return this.$api.call.mapping.mappingGetProviders().then(mappingProvidersData => {
                entity.mappingProviders = (mappingProvidersData || []).map(mappingProviderData => MappingProviderModel.from(mappingProviderData));

                let entityRef = this.modelNameToConstant(entity);
                let entityId = entity.eqId;

                // PersonRole does not have mappings, must be derived from Person
                if (entityRef === constants.mappingEntityNames.personRole) {
                    if (!entity.person) {
                        if (this.$log) this.$log.warn('PersonRole expects a Person to get mapping details');
                        return;
                    }

                    entity.mappingProviders.forEach(mappingProvider => {
                        this.$set(entity, mappingProvider.name.toLowerCase(), entity.person[mappingProvider.name.toLowerCase()]);
                    });
                    return;
                }

                // get mapping values for entity and dynamically assign mapping provider names for form and assign value
                return this.$api.call.mapping.mappingGetByEntityAndEqId(entityRef, entityId).then(mappingData => {
                    entity.mappingProviders.forEach(mappingProvider => {
                        const mappingProviderName = mappingProvider.name.toLowerCase();
                        let mappingProviderValue = '';

                        if (mappingData && mappingData.length) {
                            const mapping = mappingData.find(mapping => mapping.provider.toLowerCase() === mappingProviderName);
                            mappingProviderValue = mapping ? mapping.id : '';
                        }

                        this.$set(entity, mappingProviderName, mappingProviderValue);

                        // trigger reactive state
                        this.setEntity(entity);
                    });
                });
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('get mapping providers failed', error);
                }

                throw error;
            });
        },
        manageMappings(entity, queue){
            // update mappings
            let payloads = entity.buildMappingPayloads();

            if (!payloads.length){
                return queue;
            }

            const entityName = payloads[0].entity;
            const entityEqId = payloads[0].eqId;

            payloads = payloads.filter(payload => payload.id && payload.id.length).map(({ provider, id }) => ({ provider, id }));

            queue = queue.then(() => this.$api.call.mapping.mappingUpdate(entityName, entityEqId, payloads)).then(() => {
                this.error = null;
                this.mappingWarning = null;
            }).catch(error => {
                if(this.$log){
                    this.$log.warn(entityName + ' update mapping failed', error);
                }

                this.mappingWarning = this.$t(this.modelNameToConstant(entity) + 'MappingUpdateWarning');
                error.errorIdParser = this.entityMappingErrorIdParser.bind(entity);

                throw error;
            });

            return queue;
        },
    },
};

export const entityMappingHelperMixins = {
    methods: {
        reloadMappingProviders(){
            this.apiData.mappingProviders = this.loadMappingProviders;
        },
        setEntity(entity){
            //cause reactive property state change
            switch (true) {
                case entity instanceof MatchModel:
                    this.match = entity;
                    break;
                case entity instanceof PersonModel:
                    this.person = entity;
                    break;
                case entity instanceof TournamentModel:
                    this.tournament = entity;
                    break;
                case entity instanceof SeriesModel:
                    this.series = entity;
                    break;
                case entity instanceof TeamModel:
                    this.team = entity;
                    break;
                case entity instanceof VenueModel:
                    this.venue = entity;
                    break;
                case entity instanceof PersonRoleModel:
                    break;
            }
        },
        modelNameToConstant(entity){
            let entityRef = constants.mappingEntityNames.match;

            switch (true) {
                case entity instanceof PersonModel:
                    entityRef = constants.mappingEntityNames.person;
                    break;
                case entity instanceof PersonRoleModel:
                    entityRef = constants.mappingEntityNames.personRole;
                    break;
                case entity instanceof SeriesModel:
                    entityRef = constants.mappingEntityNames.series;
                    break;
                case entity instanceof TeamModel:
                    entityRef = constants.mappingEntityNames.team;
                    break;
                case entity instanceof TournamentModel:
                    entityRef = constants.mappingEntityNames.tournament;
                    break;
                case entity instanceof VenueModel:
                    entityRef = constants.mappingEntityNames.venue;
                    break;
            }

            return entityRef;
        },
        entityMappingErrorIdParser(errorIds){
            return errorIds.map(id => {
                //remove dollar bs from id
                id = id.replace(/^\$\./g, '');

                return id.toLowerCase();
            });
        }
    },
};
