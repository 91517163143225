<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 1, null, null, true)" class="card-img-wrapper" v-if="showImg">
            <b-card-img
                v-bind="getColumnProps('image')"
                v-bind:alt="seriesType.name"
                v-bind:width="imgWidth"
                v-bind:height="imgHeight"
            />
        </div>

        <b-card-title v-bind="getColumnProps('seriesTypeName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('seriesTypeName')" v-if="isHeadline">{{ getHeadline('seriesTypeName', $t('seriesTypeName')) }}</label>
            <span class="text-uppercase" v-else>{{ seriesType.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('description', 12, 6, 2)" v-if="showDescription">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('description')">{{ getHeadline('description', $t('description')) }}</label>
            <span v-if="!isHeadline">{{ seriesType.description }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-on:click="emitEvent('remove', $event)"
                v-b-modal="modalName('confirmDelete', seriesType.id)"
                v-if="showRemove"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-bind:to="editLink"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', seriesType.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: seriesType.name })"
            v-bind:data="seriesType"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />
    </b-card>
</template>

<script>
import base from './base.vue';

import SeriesTypeModel from '@/models/seriesType.js';

/**
 * match block component
 */
export default {
    name: 'seriesTypeRow',
    extends: base,
    props: {
        seriesType: {
            type: SeriesTypeModel,
            default(){
                return new SeriesTypeModel();
            }
        },

        showImg: {
            type: Boolean,
            default: false,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showDescription: {
            type: Boolean,
            default: false,
        },
        showWorkflowStatus: {
            type: Boolean,
            default: false,
        },
        showWorkflowDeadline: {
            type: Boolean,
            default: false,
        },

        showRemove: {
            type: Boolean,
            default: false,
        },
        showEdit: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        editLink(){
            return {
                name: 'administrationSeriesTypesEdit',
                params: {
                    dataSeriesTypeId: this.seriesType.id,
                },
            };
        },
        emitEventData(){
            return {
                seriesType: this.seriesType || null,
            };
        },
        hasVisibleCircles(){
            return (this.showRemove || this.showEdit);
        },
    },
};
</script>
