import Model from '@/models/model.js';

/**
 * sport model class
 */
export default class SportModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * description
     *
     * @type {string}
     */
    description = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        //use name as id if no id given
        if(data){
            data.id = data.id || data.name;
        }

        super(data);

        if(data){
            this.id = data.id || null;
            this.name = data.name || '';
            this.description = data.description || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.description || this.name;
    }
}
