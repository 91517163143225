<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <actionButton
            v-bind:type="config.action"
            v-bind:name="config.name"
            v-bind:value="config.value"
            v-bind:variant="config.variant"
            v-bind:disabled="config.disabled"
            v-bind:class="config.buttonClass"
            v-bind:href="config.href"
            v-bind:to="config.to"
            v-bind:target="config.target"
            v-bind:loadingWhile="config.loadingWhile"
            v-bind:loadingThrobber="config.loadingThrobber"
            v-on:click="onClick"
        >
            <iconElement v-if="config.icon" v-bind:data-config="{ icon: config.icon }"/>
            {{ config.text }}
        </actionButton>
    </b-form-group>
</template>

<script>
import base from './base.vue';
import iconElement from './icon.vue';
import actionButton from '@/components/actionButton.vue';

/**
 * button element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'buttonElement',
    extends: base,
    components: {
        iconElement,
        actionButton,
    },
    data(){
        return {};
    },
    computed: {
    },
    methods: {
        onClick(e){
            if(this.config.disabled){
                return;
            }

            if(this.config.onClick instanceof Function){
                this.config.onClick.apply(this.config.onClick, [e, this.config.value, this.config.name]);
            }

            if(!this.config.name || this.config.value === undefined){
                return;
            }

            this.$emit('set', this.config.name, this.config.value);
        },
    },
};
</script>
