<template>
    <div class="pageContent">
        <reviewMatchList
            v-bind:title="(tournament ? tournament.name:  '') + ' | ' + $t('matchDeclarations')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:dataTournamentDeclarationId="dataTournamentDeclarationId"
            v-bind:dataTournamentEqId="tournament.eqId"
            v-if="tournament"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import reviewMatchList from '@/components/lists/review/match.vue';

import TournamentModel from '@/models/tournament.js';

export default {
    name: 'reviewMatchIndex',
    extends: base,
    components: {
        reviewMatchList,
    },
    props: {
        dataTournamentDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            rowProps: {
                showWorkflowStatus: false,
                showWorkflowDeadline: false,
            },
            tournament: null,
        };
    },
    created() {
        // get the tournament by Tournament Declaration Id
        this.$api.call.termsOfParticipation.tournamentDeclarationById(this.dataTournamentDeclarationId).then(data => {
            this.tournament = TournamentModel.from(data.tournament);
        });
    },
};
</script>
