<script>
import formComponent from '@/components/form.vue';
import SeriesTeamModel from '@/models/seriesTeam.js';

/**
 * component
 */
export default {
    name: 'createSeriesTeamForm',
    extends: formComponent,
    props: {
        model: {
            type: SeriesTeamModel,
        },
        sport: {
            type: String,
            default: null,
        },
    },
    methods: {
        showModal() {
            this.$root.$emit('bv::show::modal', 'addSeriesTeam');
        },
        onChange(data){
            this.formValues = data;
            this.$emit('select', this.formValues);
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'team',
                        type: 'pickTeam',
                        label: this.$t('seriesTeam'),
                        filterValues: {
                            sport: this.sport,
                        },
                    },
                    {
                        id: 'isTourMember',
                        type: 'checkbox',
                        label: this.$t('teamIsCoreMemberOfSeries'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                teamAliasEqId: 'team',
            };
        },
    },
};
</script>
