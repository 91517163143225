import Model from '@/models/model.js';
import UserAccessesModel from '@/models/userAccesses.js';

/**
 * user model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class UserModel extends Model {
    static types = {
        worldRugby: 'WorldRugby',
        unionAffiliated: 'UnionAffiliated',
    };

    /**
     * user name
     *
     * @type {string}
     */
    name = '';

    /**
     * user email
     *
     * @type {string}
     */
    email = '';

    /**
     * user enabled features list
     *
     * @type {string[]}
     */
    features = [];

    /**
     * user id
     *
     * @type {int}
     */
    id = 0;

    /**
     * user first name
     *
     * @type {string}
     */
    firstName = '';

    /**
     * user last name
     *
     * @type {string}
     */
    lastName = '';

    /**
     * user status
     *
     * @type {string}
     */
    userStatus = '';

    /**
     * internal or external
     *
     * @type {string}
     */
    isInternal = false;

    /**
     * user account type
     *
     * @type {string}
     */
    accountType = '';

    /**
     * user union id
     *
     * @type {number|null}
     */
    union = null;

    /**
     * unionSourceEqId
     *
     * @type {string}
     */
    unionSourceEqId = '';

    /**
     * user permission group
     *
     * @type {string}
     */
    permissionGroup = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.name = data.name || '';
            this.email = data.email || '';
            this.features = data.features || [];

            this.id = data.id || 0;
            this.eqId = data.eqId || '';
            this.externalId = data.ExternalId || data.userExternalId || '';
            this.firstName = data.firstName || '';
            this.lastName = data.lastName || data.surname || '';
            this.isInternal = data.isInternal || false;
            this.union = data.union || null;
            this.type = data.userType || data.type || null;
            this.status = data.status || data.userStatus || '';
            this.unionSourceEqId = data.unionSourceEqId || '';

            this.accountType = this.isInternal ? 'internal' : 'external';
            this.permissionGroup = data.permissionGroup || '';

            this.userStatus = data.userStatus || '';
            this.accesses = (data.accesses || data.userAccesses || []).map(data => UserAccessesModel.from(data));

            if (this.permissionGroup.length < 1 && this.accesses) {
                this.permissionGroup = this.accesses.map(a => a.name).join(', ');
            }
        }
    }

    /**
     * update user access roles
     *
     * @param {object[]} userRoles
     */
    updateAccessRoles(userRoles = []){
        if(!userRoles.length || !this.accesses.length){
            return;
        }

        //create role map by id
        const map = {};
        userRoles.forEach(data => {
            map[data.id] = data;
        });

        //loop accesses and update role by id from the map
        this.accesses.forEach(access => {
            if(access.role && access.role.id && map[access.role.id]){
                access.updateRole(map[access.role.id]);
            }
        });
    }

    /**
     * check if feature is allowed for user
     *
     * @param {string} feature
     * @param {string[]} [actions]
     * @param {string|null} [userType]
     *
     * @returns {boolean}
     */
    isAllowed(feature, actions = [], userType = null){
        if(!feature || !actions.length){
            return true;
        }

        if(userType && userType !== this.type){
            return false;
        }

        //check accesses one by one and look for one that allows it
        for(const i in this.accesses){
            if(Object.prototype.hasOwnProperty.call(this.accesses, i) && this.accesses[i].isAllowed(feature, actions)){
                return true;
            }
        }
        return false;
    }

    isWorldRugbyUser(){
        return (this.type !== null && this.type === UserModel.types.worldRugby);
    }

    isUnionAffiliatedUser(){
        return (this.type !== null && this.type === UserModel.types.unionAffiliated);
    }

    /**
     * check if permissions are allowed for user
     *
     * @param {PermissionModel[]} permissions
     *
     * @returns {boolean}
     */
    areAllowed(permissions){
        return permissions
            .map(permission => this.isAllowed(permission.getFeature(), permission.getActions(), permission.getUserType()))
            .reduce((result, current) => result && current, true);
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            id: this.id,
            userType: this.type,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            userExternalId: this.externalId,
            unionSourceEqId: this.type?.toLowerCase() === UserModel.types.worldRugby.toLowerCase() ? null : this.unionSourceEqId,
            eqId: this.eqId,
            status: this.status?.split(' ').join(''),
        };
    }
};
