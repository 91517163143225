<script>
import itemList from '@/components/itemList.vue';
import tournamentTypeGroupRow from '@/components/rows/administration/tournamentTypeGroup.vue';
import TournamentTypeGroupModel from '@/models/tournamentTypeGroup.js';

/**
 * complex tournamentTypeGroup list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'tournamentTypeGroupList',
    extends: itemList,
    components: {
        tournamentTypeGroupRow,
    },
    data(){
        return {
            //overwrites
            rowElement: tournamentTypeGroupRow,
            rowItemPropName: 'tournamentTypeGroup',
            selectItemProp: 'tournamentTypeGroup',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showSelect: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName'),
                    label: false,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load Tournament Type Groups
            return this.$api.call.tournament.tournamentTypeGroupSearch({
                name: filters.name || null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.tournamentTypeGroups || result.items || result.data || []).map(data => new TournamentTypeGroupModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.tournament.tournamentTypeGroupDeleteById(item.id);
        },
    },
};
</script>
