import Model from '@/models/model.js';
import ApiResourceMethodModel from '@/models/apiResourceMethod.js';

/**
 * Api resource model class
 */
export default class ApiResourceModel extends Model {
    /**
     * resource name
     *
     * @type {string}
     */
    name = '';

    /**
     * api resource methods
     *
     * @type {ApiResourceMethodModel[]}
     */
    methods = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     * @param {boolean} [isAuthorised = false]
     */
    constructor(data = {}, isAuthorised = false){
        super(data);

        if(data){
            this.id = data.apiResourceId || data.id || 0;
            this.apiResourceId = data.apiResourceId || 0;
            this.name = data.resourceName || data.name || '';

            this.isExternal = data.isExternal || false;
            this.service = data.service || null;

            this.methods = (data.apis || data.apiResourceMethods || [])
                .map(data => ApiResourceMethodModel.from(data, false, isAuthorised).setResource(this));

            //make sure methods are sorted
            this.sortMethods();
        }
    }

    setService(service){
        this.service = service;
        this.setMethodId(this.service.id);

        return this;
    }

    setMethodId(serviceId = ''){
        this.methods.map(method => method.setMethodId(this.apiResourceId, serviceId));
    }

    setMethods(methods = []){
        this.methods.splice(0).push.apply(this.methods, methods.map(data => ApiResourceMethodModel.from(data).setResource(this)));
    }

    getMethods(filter = null){
        if(filter){
            return this.methods.filter(filter);
        }
        return this.methods;
    }

    addMethod(apiResourceMethod){
        if(!apiResourceMethod){
            return false;
        }

        //check if method already exists
        if(this.methods.filter(method => method.apiResourceMethodId === apiResourceMethod.apiResourceMethodId).length){
            return true;
        }

        //add method to array
        const method = apiResourceMethod.clone();
        method.setResource(this);
        this.methods.push(method);

        //make sure methods are sorted
        this.sortMethods();

        return true;
    }

    removeMethod(apiResourceMethod){
        if(!apiResourceMethod){
            return false;
        }

        //remove method
        const methods = this.methods.filter(method => method.apiResourceMethodId !== apiResourceMethod.apiResourceMethodId);

        //check if change happened
        if(methods.length === this.methods.length){
            return false;
        }

        //update methods
        this.methods = methods;

        return true;
    }

    sortMethods(){
        //sort method array
        this.methods.sort((a, b) => a.id - b.id);
    }

    /**
     * get api compatible object json
     *
     * @returns {object}
     */
    toJSON(){
        return {
            apiResourceId: this.id,
            name: this.name,
            apiResourceMethods: this.methods.map(entry => entry.toJSON()).filter(data => data),
            isExternal: this.isExternal,
        };
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
