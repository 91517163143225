<template>
    <div class="pageContent">
        <declarationSeriesList
            v-bind:title="$t('seriesDeclarations')"
            mainTitle
            v-bind:rowProps="rowProps"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import declarationSeriesList from '@/components/lists/declaration/series.vue';

export default {
    name: 'declarationSeriesIndex',
    extends: base,
    components: {
        declarationSeriesList,
    },
    data(){
        return {
            rowProps: {
                showTeam: true,
                showDeadline: true,
                showDetail: true,
                showWorkflowStatus: true,
                showWorkflowDeadline: true,
                showSport: true,
            },
        };
    },
};
</script>
