import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationPermissionGroupIndex from '@/views/administration/permissiongroup/index.vue';
import administrationPermissionGroupDetail from '@/views/administration/permissiongroup/detail.vue';
import administrationPermissionGroupCreate from '@/views/administration/permissiongroup/create.vue';

export default {
    path: 'permission-group/',
    component: pageFrame,
    children: [
        {
            name: 'administrationPermissionGroupIndex',
            path: constants.routePaths.index,
            component: administrationPermissionGroupIndex,
            meta: {
                permission: permissions.permissionGroups.list,
            },
        },
        {
            name: 'administrationPermissionGroupCreate',
            path: constants.routePaths.create,
            component: administrationPermissionGroupCreate,
            props: routerTools.setProps.bind(null, { createMode: true }),
            meta: {
                permission: permissions.permissionGroups.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.permissionGroupId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationPermissionGroupDetail',
                    path: constants.routePaths.edit,
                    component: administrationPermissionGroupDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.permissionGroupId]),
                    meta: {
                        permission: permissions.permissionGroups.update,
                    },
                },
            ],
        },
    ],
};
