import pageFrame from '@/views/frame.vue';

import commonUserProfile from '@/views/common/user/profile.vue';

export default {
    path: '/user/',
    component: pageFrame,
    children: [
        {
            path: '/',
            redirect: '/home',
        },
        {
            name: 'commonUserProfile',
            path: 'profile/',
            component: commonUserProfile,
        },
    ],
};
