<template>
    <div v-bind:class="elementClasses"></div>
</template>

<script>
import base from './base.vue';

/**
 * static element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'staticElement',
    extends: base,
    data(){
        return {
            value: null
        };
    },
};
</script>
