<template>
    <span v-bind:class="iconClasses"></span>
</template>

<script>
const icons = {
    arrowLeft: 'arrow-left',
    arrowRight: 'arrow-right',
    ball: 'ball',
    bell: 'bell',
    bin: 'bin',
    binCircle: 'bin-circle',
    binCircleFull: 'bin-circle-full',
    calendar: 'calendar',
    cancel: 'cancel',
    cancelCircleFull: 'cancel-circle-full',
    card: 'card',
    cards: 'cards',
    chat: 'chat',
    citing: 'citing',
    clock: 'clock',
    confirmCircleFull: 'confirm-circle-full',
    cup: 'cup',
    down: 'down',
    downCircle: 'down-circle',
    downCircleFull: 'down-circle-full',
    edit: 'edit',
    editCircle: 'edit-circle',
    editCircleFull: 'edit-circle-full',
    eye: 'eye',
    eyeCircle: 'eye-circle',
    eyeCircleFull: 'eye-circle-full',
    jersey: 'jersey',
    left: 'left',
    leftCircle: 'left-circle',
    leftCircleFull: 'left-circle-full',
    match: 'match',
    menu: 'menu',
    minus: 'minus',
    minusCircle: 'minus-circle',
    minusCircleFull: 'minus-circle-full',
    player: 'player',
    plus: 'plus',
    plusCircle: 'plus-circle',
    plusCircleFull: 'plus-circle-full',
    right: 'right',
    rightCircle: 'right-circle',
    rightCircleFull: 'right-circle-full',
    search: 'search',
    series: 'series',
    union: 'union',
    up: 'up',
    upCircle: 'up-circle',
    upCircleFull: 'up-circle-full',
    warningCircleFull: 'warning-circle-full',
};

const aliases = {
    correct: icons.confirmCircleFull,
    confirm: icons.confirmCircleFull,
    success: icons.confirmCircleFull,

    warning: icons.warningCircleFull,

    incorrect: icons.cancelCircleFull,
    error: icons.cancelCircleFull,
    danger: icons.cancelCircleFull,

    add: icons.plusCircleFull,
    remove: icons.minusCircleFull,
    view: icons.eyeCircleFull,

    'card-red': icons.card,
    'card-yellow': icons.card,
    'card-none': icons.card,
};

const colors = {
    danger: 'danger',
    secondary: 'secondary',
    success: 'success',
    transparent: 'transparent',
    warning: 'warning',
    light: 'light',
    primary: 'primary',
}

export default {
    name: 'icon',
    props: {
        /**
         * icon type/id
         */
        type: {
            type: String,
            required: true,
            default: '',
        },
        /**
         * icon color
         */
        color: {
            type: String,
            default: null,
        },
        /**
         * large icon size toggle
         */
        large: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        types(){
            return Object.values(icons);
        },
        iconClasses(){
            const classes = {
                icon: true,
            };
            let color = null;

            //map alias
            let type = this.type;
            type = aliases[type] || type;

            //check if icon of that type exists
            if(!this.types.includes(type)){
                return null;
            }

            //get default color
            switch(type){
                //default color: success
                case icons.confirmCircleFull:
                case icons.plusCircleFull:
                    color = colors.success;
                    break;

                //default color: warning
                case icons.warningCircleFull:
                    color = colors.warning;
                    break;

                //default color: danger
                case icons.cancelCircleFull:
                    color = colors.danger;
                    break;

                //default color: secondary
                case icons.binCircleFull:
                case icons.upCircleFull:
                case icons.leftCircleFull:
                case icons.rightCircleFull:
                case icons.downCircleFull:
                case icons.editCircleFull:
                case icons.eyeCircleFull:
                case icons.minusCircleFull:
                    color = colors.secondary;
            }

            //get default color for aliases
            switch(this.type){
                //default color: warning
                case 'card-yellow':
                    color = colors.warning;
                    break;

                //default color: danger
                case 'card-red':
                    color = colors.danger;
                    break;

                case 'card-none':
                    color = colors.transparent;
                    break;
            }

            classes['icon-' + type] = true;

            if(this.color || color){
                classes['text-' + (this.color || color)] = true;
            }

            if(this.large){
                classes['icon-large'] = true;
            }

            return classes;
        },
    },
}
</script>
