import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import declarationMatchIndex from '@/views/declaration/match/index.vue';
import declarationMatchDetail from '@/views/declaration/match/detail.vue';

export default {
    path: 'match/',
    component: pageFrame,
    children: [
        {
            name: 'declarationMatchIndex',
            path: constants.routePaths.index,
            component: declarationMatchIndex,
            meta: {
                permission: permissions.declarations.matches.list,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.matchDeclarationId),
            component: pageFrame,
            children: [
                {
                    path: routerTools.propRoute(constants.routeProps.teamDeclarationId),
                    component: pageFrame,
                    children: [
                        {
                            name: 'declarationMatchDetail',
                            path: constants.routePaths.index,
                            component: declarationMatchDetail,
                            props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.matchDeclarationId, constants.routeProps.teamDeclarationId]),
                            meta: {
                                permission: permissions.declarations.matches.detail,
                            },
                        },
                        routerTools.createFallbackRedirect(),
                    ],
                },
                routerTools.createIndexRedirect(),
            ],
        },
    ],
};
