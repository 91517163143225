<script>
import personRow from './person.vue';

import ManagerModel from '@/models/person/manager.js';
import constants from '@/constants.js';

export default {
    name: 'managerRow',
    extends: personRow,
    props: {
        person: {
            type: ManagerModel,
            default(){
                return new ManagerModel();
            }
        },
    },
    data(){
        return {
            roleType: constants.personRoleType.management,
        };
    },
}
</script>
