<template>
    <div v-bind:class="elementClasses">
        <actionButton
            v-for="(button, index) in buttons"
            v-bind:key="index"
            v-bind:type="button.action"
            v-bind:name="button.name"
            v-bind:value="button.value"
            v-bind:variant="button.variant || getDefaultVariant(button.action)"
            v-bind:disabled="button.disabled"
            v-bind:class="button.buttonClass"
            v-bind:href="button.href"
            v-bind:to="button.to"
            v-bind:target="button.target"
            v-bind:loadingWhile="button.loadingWhile"
            v-bind:loadingThrobber="button.loadingThrobber"
            v-on:click="onClick(button)"
        >
            <iconElement v-if="button.icon" v-bind:data-config="{ icon: button.icon }"/>
            {{ button.text }}
        </actionButton>
    </div>
</template>

<script>
import base from './base.vue';
import iconElement from './icon.vue';
import actionButton from '@/components/actionButton.vue';

/**
 * button list element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'buttonsElement',
    extends: base,
    components: {
        iconElement,
        actionButton,
    },
    data(){
        return {};
    },
    computed: {
        buttons(){
            return this.config.buttons || [];
        },
    },
    methods: {
        onClick(button){
            if(button.disabled || !button.name || button.value === undefined){
                return;
            }

            this.$emit('set', button.name, button.value);
        },
        getDefaultVariant(type){
            switch(type){
                case 'submit':
                    return 'secondary';

                case 'reset':
                    return 'warning';

                case 'cancel':
                    return 'danger';
            }

            return null;
        },
    },
};
</script>
