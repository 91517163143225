<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createTournamentTypeGroup')"
            v-on:submit="createTournamentTypeGroup"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentTypeGroupList"
                    v-bind:loadingWhile="apiData.tournamentTypeGroupCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentTypeGroupCreation"
                >
                    {{ $t('createGroup') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createTournamentTypeGroupForm
                    v-bind:headline="$t('tournamentTypeGroupInformation')"
                    v-bind:model="tournamentTypeGroup"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import createTournamentTypeGroupForm from '@/components/forms/createTournamentTypeGroup.vue';

import TournamentTypeGroupModel from '@/models/tournamentTypeGroup.js';

export default {
    name: 'administrationTournamentTypeGroupCreate',
    extends: base,
    components: {
        validations,
        createTournamentTypeGroupForm,
    },
    data(){
        return {
            apiData: {
                tournamentTypeGroupCreation: null,
            },
            tournamentTypeGroup: TournamentTypeGroupModel.from({}),
            error: null,
        };
    },
    computed: {
        toTournamentTypeGroupList(){
            return { name: 'administrationTournamentTypeGroupIndex' };
        },
    },
    methods: {
        createTournamentTypeGroup(){
            this.apiData.tournamentTypeGroupCreation = this.$api.call.tournament.tournamentTypeGroupCreate(this.tournamentTypeGroup.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toTournamentTypeGroupList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentTypeGroup creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
