/**
 * Log service class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class Log {
    /**
     * log level what messages should be shown
     *
     * 0: no logging
     * 1: errors
     * 2: errors, warnings
     * 3: errors, warnings, info
     * 4: errors, warnings, info, debug
     * 5: errors, warnings, info, debug, log
     *
     * @type {number}
     */
    logLevel = 0;

    /**
     * class constructor
     *
     * @param {number} [logLevel = 0]
     */
    constructor(logLevel = 0){
        this.logLevel = logLevel;
    }

    /**
     * log error message
     *
     * @param {...*} message
     */
    error(message){
        if(this.logLevel < 1){
            return;
        }

        console.error.apply(console, arguments);
    }

    /**
     * log warning message
     *
     * @param {...*} message
     */
    warn(message){
        if(this.logLevel < 2){
            return;
        }
        console.warn.apply(console, arguments);
    }

    /**
     * log info message
     *
     * @param {...*} message
     */
    info(message){
        if(this.logLevel < 3){
            return;
        }

        console.info.apply(console, arguments);
    }

    /**
     * log debug message
     *
     * @param {...*} message
     */
    debug(message){
        if(this.logLevel < 4){
            return;
        }

        console.debug.apply(console, arguments);
    }

    /**
     * log message
     *
     * @param {...*} message
     */
    log(message){
        if(this.logLevel < 5){
            return;
        }

        console.log.apply(console, arguments);
    }

    /**
     * Koan
     * Log message irrespective of any configured log level
     *
     * @param {...*} message
     */
    always(message) {
        console.log.apply(console, arguments);
    }
};
