<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-text v-bind="getColumnProps('boostValue', 12, 6, 2)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('boostValue')">{{ getHeadline('boostValue', $t('boostValue')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ calculation.homeTeamPointsBoost }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('threshouldWinValue', 12, 6, 2)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('threshouldWinValue')">{{ getHeadline('threshouldWinValue', $t('thresholdWinValue')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ calculation.winByMoreThreshold }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('threshouldMultiplier', 12, 6, 2)">
            <label
                v-bind:class="headlineClasses"
                v-on:click="handleHeadlineSort('threshouldMultiplier')"
            >
                    {{ getHeadline('threshouldMultiplier', $t('thresholdMultiplier')) }}
            </label>
            <span class="text-uppercase" v-if="!isHeadline">{{ calculation.winByMoreMultiplier }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('startDate', 12, 6, 2)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('startDate')">{{ getHeadline('startDate', $t('startDate')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ $d(calculation.startDate, 'date') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('endDate', 12, 6, 2)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('endDate')">{{ getHeadline('endDate', $t('endDate')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ $d(calculation.endDate, 'date') }}</span>
        </b-card-text>

        <b-card-title v-bind="getColumnProps('name', 1)" v-bind:title-tag="titleTag">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{ getHeadline('name', '') }}</label>
            <span v-else></span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="editLink"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-on:click="emitEvent('remove', $event)"
                v-b-modal="modalName('confirmDelete', calculation.id)"
                v-if="showRemove"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', calculation.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: calculation.name })"
            v-bind:data="calculation"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />
    </b-card>
</template>

<script>
import base from '../base.vue';

import CalculationModel from '@/models/calculations.js';

/**
 * match block component
 */
export default {
    name: 'calculationsRow',
    extends: base,
    props: {
        calculation: {
            type: CalculationModel,
            default(){
                return new CalculationModel();
            }
        },

        showRemove: {
            type: Boolean,
            default: false,
        },
        showEdit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        editLink(){
            return {
                name: this.editLinkRoute || 'administrationRankingCalculationsCalculationRatingEdit',
                params: Object.assign({
                    dataCalculationId: this.calculation.id,
                }, this.editLinkParams),
            };
        },
        emitEventData(){
            return {
                calculation: this.calculation || null,
            };
        },
        hasVisibleCircles(){
            return (this.showRemove || this.showEdit);
        },
    },
};
</script>
