<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToMatchDetailsLink"
        >
            <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
        </actionButton>

        <matchReportsHeader
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStats"
            v-bind:title="$t('matchStats')"
            v-bind:data="stats"
        />

        <wrapper
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStats"
            noPadding noShadow noLine
            v-if="statisticsTeam1 && statisticsTeam2"
        >
            <bar
                v-for="(statisticsEntry, i) in statisticsEntries"
                v-bind:key="i"
                v-bind:title="$t(statisticsEntry)"
                v-bind:number1="statisticsTeam1[statisticsEntry]"
                v-bind:number3="statisticsTeam2[statisticsEntry]"
                v-bind:zeroNumber="formatPercent.includes(statisticsEntry) ? '0%' : 0"
                pinOuter
            >
                <template v-slot:number="{ value }">
                    {{ formatBarNumber(value, statisticsEntry) }}
                </template>
            </bar>
        </wrapper>

        <wrapper
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStats"
            noPadding noShadow noLine
            v-if="extendedDataTeam1 && extendedDataTeam2"
        >
            <bar
                v-for="(extendedDataEntry, i) in extendedDataEntries"
                v-bind:key="i"
                v-bind:title="$t(extendedDataEntry)"
                v-bind:number1="extendedDataTeam1[extendedDataEntry]"
                v-bind:number3="extendedDataTeam2[extendedDataEntry]"
                v-bind:zeroNumber="formatPercent.includes(extendedDataEntry) ? '0%' : 0"
                pinOuter
            >
                <template v-slot:number="{ value }">
                    {{ formatBarNumber(value, extendedDataEntry) }}
                </template>
            </bar>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import matchReportsHeader from '@/components/matchReportsHeader.vue';
import bar from '@/components/bar.vue';

import MatchStatsModel from '@/models/matchStats.js';

export default {
    name: 'commonMatchStats',
    extends: base,
    components: {
        icon,
        matchReportsHeader,
        bar,
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                stats: this.loadStats(),
            },
            matchTournaments: [],
            stats: null,
            statisticsTeam1: null,
            statisticsTeam2: null,
            extendedDataTeam1: null,
            extendedDataTeam2: null,
            statisticsEntries: [],
            extendedDataEntries: [],
            formatPercent: [
                'ballPossessionLast10Mins',
                'kickPercentSuccess',
                'lineoutSuccess',
                'pcKickPercent',
                'pcPossessionFirst',
                'pcPossessionSecond',
                'pcTerritoryFirst',
                'pcTerritorySecond',
                'possession',
                'restartsSuccess',
                'ruckSuccess',
                'scrumsSuccess',
                'territory',
                'territoryLast10Mins',
                'tackleSuccess',
            ],
        };
    },
    computed: {
        backToMatchDetailsLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
    },
    methods: {
        loadStats(){
            return this.$api.call.match.matchReportsStatsGetByMatchId(this.dataMatchId).then(result => {
                this.stats = new MatchStatsModel(result);

                const teamOne = this.stats.teams[0] || {};
                const teamTwo = this.stats.teams[1] || {};

                this.statisticsTeam1 = teamOne.statistics || {};
                this.statisticsTeam2 = teamTwo.statistics || {};

                this.extendedDataTeam1 = teamOne.extendedData || {};
                this.extendedDataTeam2 = teamTwo.extendedData || {};

                this.statisticsEntries = Object.keys(this.statisticsTeam1);
                this.extendedDataEntries = Object.keys(this.extendedDataTeam1);
            });
        },
        reloadStats(){
            this.apiData.stats = this.loadStats();
        },
        formatBarNumber(nr, key){
            const formatPercent = this.formatPercent.includes(key);

            if(formatPercent){
                nr *= 100;
            }

            return this.$n(nr) + (formatPercent ? '%' : '');
        },
    },
};
</script>
