<script>
import itemList from '@/components/itemList.vue';
import locationRow from '@/components/rows/location.vue';
import LocationModel from '@/models/location.js';

/**
 * complex location list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'locationList',
    extends: itemList,
    components: {
        locationRow,
    },
    data(){
        return {
            //overwrites
            rowElement: locationRow,
            rowItemPropName: 'location',
            selectItemProp: 'location',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showSelect: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName'),
                    label: false,
                },
                {
                    id: 'countryIsoCode',
                    type: 'vselectCountry',
                    valueProperty: 'isoCode',
                    placeholder: this.$t('filterByCountry'),
                    label: false,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load locations
            return this.$api.call.location.locationSearch({
                name: filters.name ? '%' + filters.name + '%' : null,
                countryIsoCode: filters.countryIsoCode ? filters.countryIsoCode : null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.locations || result.items || []).map(data => new LocationModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.location.locationDeleteById(item.id);
        },
    },
};
</script>
