import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class MappingService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/mapping-service/api/';

    /**
     * base path parts
     *
     * @type {object}
     */
    _paths = {
        unmapped: 'unmapped',
        match: 'match',
        dataIngestion: 'data-ingestion',
    };

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // Mapping

    /**
     *
     */
    mappingCreate(data){
        return this.post('mapping', data);
    }

    /**
     *
     */
    mappingUpdate(entityName, eqId, data){
        return this.put(['mapping', entityName, eqId], data);
    }

    /**
     *
     */
    mappingGetByEntityAndEqId(entityName, eqId){
        return this.get(['mapping', entityName, eqId]);
    }

    /**
     *
     */
    mappingDelete(providerName, entityName, entityId){
        return this.delete(['mapping', providerName, entityName, entityId]);
    }

    /**
     *
     */
    mappingGetProviders(){
        return this.get(['mapping', 'providers']);
    }

    // MappingMatch

    /**
     *
     */
    mappingMatchCreate(data){
        return this.post('mapping-match', data);
    }

    /**
     *
     */
    mappingMatchGetByProviderId(id){
        return this.get(['mapping-match', 'provider', id]);
    }

    /**
     *
     */
    mappingMatchGetByMatchId(id){
        return this.get(['mapping-match', 'match', id]);
    }

    /**
     *
     */
    mappingMatchUpdateProviderId(id){
        return this.patch(['mapping-match', id]);
    }

    /**
     *
     */
    mappingMatchDeleteById(id){
        return this.delete(['mapping-match', id]);
    }

    // MappingPersonnel

    /**
     *
     */
    mappingPersonnelCreate(data){
        return this.post('mapping-personnel', data);
    }

    /**
     *
     */
    mappingPersonnelGetByProviderId(id){
        return this.get(['mapping-personnel', 'provider', id]);
    }

    /**
     *
     */
    mappingPersonnelGetByMatchId(id){
        return this.get(['mapping-personnel', 'match', id]);
    }

    /**
     *
     */
    mappingPersonnelUpdateProviderId(id){
        return this.patch(['mapping-personnel', id]);
    }

    /**
     *
     */
    mappingPersonnelDeleteById(id){
        return this.delete(['mapping-personnel', id]);
    }

    // MappingPersonRole

    /**
     *
     */
    mappingPersonRoleCreate(data){
        return this.post('mapping-person-role', data);
    }

    /**
     *
     */
    mappingPersonRoleGetByProviderId(id){
        return this.get(['mapping-person-role', 'provider', id]);
    }

    /**
     *
     */
    mappingPersonRoleGetByMatchId(id){
        return this.get(['mapping-person-role', 'match', id]);
    }

    /**
     *
     */
    mappingPersonRoleUpdateProviderId(id){
        return this.patch(['mapping-person-role', id]);
    }

    /**
     *
     */
    mappingPersonRoleDeleteById(id){
        return this.delete(['mapping-person-role', id]);
    }

    // MappingRegion

    /**
     *
     */
    mappingRegionCreate(data){
        return this.post('mapping-region', data);
    }

    /**
     *
     */
    mappingRegionGetByProviderId(id){
        return this.get(['mapping-region', 'provider', id]);
    }

    /**
     *
     */
    mappingRegionGetByMatchId(id){
        return this.get(['mapping-region', 'match', id]);
    }

    /**
     *
     */
    mappingRegionUpdateProviderId(id){
        return this.patch(['mapping-region', id]);
    }

    /**
     *
     */
    mappingRegionDeleteById(id){
        return this.delete(['mapping-region', id]);
    }

    // MappingSeries

    /**
     *
     */
    mappingSeriesCreate(data){
        return this.post('mapping-series', data);
    }

    /**
     *
     */
    mappingSeriesGetByProviderId(id){
        return this.get(['mapping-series', 'provider', id]);
    }

    /**
     *
     */
    mappingSeriesGetByMatchId(id){
        return this.get(['mapping-series', 'match', id]);
    }

    /**
     *
     */
    mappingSeriesUpdateProviderId(id){
        return this.patch(['mapping-series', id]);
    }

    /**
     *
     */
    mappingSeriesDeleteById(id){
        return this.delete(['mapping-series', id]);
    }

    // MappingTeam

    /**
     *
     */
    mappingTeamCreate(data){
        return this.post('mapping-team', data);
    }

    /**
     *
     */
    mappingTeamGetByProviderId(id){
        return this.get(['mapping-team', 'provider', id]);
    }

    /**
     *
     */
    mappingTeamGetByMatchId(id){
        return this.get(['mapping-team', 'match', id]);
    }

    /**
     *
     */
    mappingTeamUpdateProviderId(id){
        return this.patch(['mapping-team', id]);
    }

    /**
     *
     */
    mappingTeamDeleteById(id){
        return this.delete(['mapping-team', id]);
    }

    // MappingTournament

    /**
     *
     */
    mappingTournamentCreate(data){
        return this.post('mapping-tournament', data);
    }

    /**
     *
     */
    mappingTournamentGetByProviderId(id){
        return this.get(['mapping-tournament', 'provider', id]);
    }

    /**
     *
     */
    mappingTournamentGetByMatchId(id){
        return this.get(['mapping-tournament', 'match', id]);
    }

    /**
     *
     */
    mappingTournamentUpdateProviderId(id){
        return this.patch(['mapping-tournament', id]);
    }

    /**
     *
     */
    mappingTournamentDeleteById(id){
        return this.delete(['mapping-tournament', id]);
    }

    // MappingUnion

    /**
     *
     */
    mappingUnionCreate(data){
        return this.post('mapping-union', data);
    }

    /**
     *
     */
    mappingUnionGetByProviderId(id){
        return this.get(['mapping-union', 'provider', id]);
    }

    /**
     *
     */
    mappingUnionGetByMatchId(id){
        return this.get(['mapping-union', 'match', id]);
    }

    /**
     *
     */
    mappingUnionUpdateProviderId(id){
        return this.patch(['mapping-union', id]);
    }

    /**
     *
     */
    mappingUnionDeleteById(id){
        return this.delete(['mapping-union', id]);
    }

    // DataPartner

    /**
     *
     */
    dataPartnerGetById(id){
        return this.get(['data-partner', id]);
    }

    /**
     *
     */
    dataPartnerGetAll(){
        return this.get(['data-partner']);
    }

    // match

    matchMappingGetByEqId(eqId){
        return this.get([this._paths.match, eqId, 'mapping']);
    }

    // Unmapped

    unmappedSearch(query){
        return this.post([this._paths.unmapped, 'search'], query);
    }

    /**
     *
     */
    unmappedDelete(providerName, entityName, entityId){
        return this.delete([this._paths.unmapped, providerName, entityName, entityId]);
    }

    // DataIngestion

    dataIngestionGetByPath(path){
        return this.resolvePath([this._paths.dataIngestion, path]);
    }
}
