<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('userName', 1)" v-bind:title-tag="titleTag" v-if="showName && !showNameSeparated">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('userName')" v-if="isHeadline">{{ getHeadline('userName', $t('name')) }}</label>
            <span v-else>{{ user.firstName }} {{ user.lastName }}</span>
        </b-card-title>

        <b-card-title v-bind="getColumnProps('userName', 1)" v-bind:title-tag="titleTag" class="d-flex flex-row align-items-center p-0" v-if="showName && showNameSeparated">
            <b-card-text textTag="span" v-bind="getColumnProps('firstName')" class="flex-lg-grow-1 flex-lg-basis-0 first-name-mobile">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('firstName')" v-if="isHeadline">{{ getHeadline('firstName', $t('firstName')) }}</label>
                <span v-else>{{ user.firstName }}</span>
            </b-card-text>
            <b-card-text textTag="span" v-bind="getColumnProps('lastName')" class="flex-lg-grow-1 flex-lg-basis-0">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('lastName')" v-if="isHeadline">{{ getHeadline('lastName', $t('lastName')) }}</label>
                <span v-else>{{ user.lastName }}</span>
            </b-card-text>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('permissionGroup', 12, 6, 2)" v-if="showPermissionGroup">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('permissionGroup')">{{ getHeadline('permissionGroup', $t('permissionGroup')) }}</label>
            <span v-if="!isHeadline">{{ user.permissionGroup }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('accountType', 12, 6, 2)" v-if="showAccountType">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('accountType')">{{ getHeadline('accountType', $t('userAccount')) }}</label>
            <span v-if="!isHeadline">{{ user.accountType }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('status', 12, 6, 2)" v-if="showStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('status')">{{ getHeadline('status', $t('status')) }}</label>
            <statusIndicator v-bind:type="user.status" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmRemoveUser', user.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmRemoveUser', user.id)"
            v-bind:text="$t('areYouSureToDelete', { type: user.lastName })"
            v-bind:data="user"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
            v-if="showRemove"
        />
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicator from '@/components/status-indicator.vue';

import UserModel from '@/models/user.js';

/**
 * union block component
 */
export default {
    name: 'userRow',
    extends: base,
    components: {
        statusIndicator
    },
    props: {
        user: {
            type: UserModel,
            default(){
                return new UserModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },
        showNameSeparated: {
            type: Boolean,
            default: false,
        },

        showAccountType: {
            type: Boolean,
            default: false,
        },
        showPermissionGroup: {
            type: Boolean,
            default: false,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },

        showDetail: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'administrationUserDetail',
                params: Object.assign({
                    dataUserId: this.user.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                user: this.user || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showRemove);
        },
    },
};
</script>
