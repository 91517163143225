<script>
import formComponent from '@/components/form.vue';
import PermissionGroupModel from '@/models/permissiongroup.js';

/**
 * component
 */
export default {
    name: 'createEditPermissionGroupForm',
    extends: formComponent,
    props: {
        model: {
            type: PermissionGroupModel,
        },
    },
    methods: {
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        index: this.model.type,
                        type: 'text',
                        label: this.$t('permissionGroupName'),
                        placeholder: this.$t('enterName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'publishingAccessLevel',
                        index: this.model.type,
                        type: 'vselectPublishingAccessLevel',
                        label: this.$t('publishingAccessLevel'),
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                accessLevel: 'publishingAccessLevel',
            };
        },
    },
};
</script>
