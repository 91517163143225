<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('eventType', 12)" v-bind:title-tag="titleTag">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('eventType')">{{ getHeadline('eventType', $t('eventType')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ weighting.tournamentName }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('startDate', 12, 6, 2)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('startDate')">{{ getHeadline('startDate', $t('startDate')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ $d(weighting.startDate, 'date') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('endDate', 12, 6, 2)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('endDate')">{{ getHeadline('endDate', $t('endDate')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ $d(weighting.endDate, 'date') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('weighting', 12, 6, 2)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('weighting')">{{ getHeadline('weighting', $t('weighting')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ weighting.weighting }}</span>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from '../base.vue';

import WeightingModel from '@/models/weighting.js';

/**
 * weighting block component
 */
export default {
    name: 'weightingRow',
    extends: base,
    props: {
        weighting: {
            type: WeightingModel,
            default(){
                return new WeightingModel();
            }
        },
    },
};
</script>
