<script>
import pickElement from '../pick.vue';
import teamPickModal from '@/components/modals/pick/team.vue';

/**
 * pick team input element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'pickTeamElement',
    extends: pickElement,
    components: {
        teamPickModal,
    },
    data(){
        return {
            modalComponent: teamPickModal,
        };
    },
};
</script>
