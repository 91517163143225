<script>
import personPickModal from './person.vue';
import playerList from '@/components/lists/player.vue';

/**
 * player pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'playerPickModal',
    extends: personPickModal,
    components: {
        playerList,
    },
    data(){
        return {
            listElement: playerList,
        };
    },
};
</script>
