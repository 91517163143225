<script>
import formComponent from '@/components/form.vue';
import UserModel from '@/models/user.js';

/**
 * component
 */
export default {
    name: 'userEditForm',
    extends: formComponent,
    props: {
        model: {
            type: UserModel,
        },
        profileView: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementsConfig(){
            return [
                {
                    id: 'type',
                    type: 'radioselect',
                    options: [
                        {
                            value: UserModel.types.worldRugby,
                            text: this.$t('genericUser'),
                        },
                        {
                            value: UserModel.types.unionAffiliated,
                            text: this.$t('unionAffiliatedUser'),
                        },
                    ],
                    label: null,
                    disabled: this.readonly,
                    hidden: this.profileView,
                },
                this.createFormRow([
                    {
                        id: 'firstName',
                        type: 'text',
                        label: this.$t('firstName'),
                        disabled: true,
                        plaintext: true,
                    },
                    {
                        id: 'lastName',
                        type: 'text',
                        label: this.$t('lastName'),
                        disabled: true,
                        plaintext: true,
                    },
                    {
                        id: 'email',
                        type: 'email',
                        label: this.$t('emailAddress'),
                        disabled: true,
                        plaintext: true,
                    },
                    {
                        id: 'unionSourceEqId',
                        type: 'vselectUnion',
                        label: this.$t('unionAssociation'),
                        valueProperty: 'eqId',
                        disabled: this.readonly,
                        dependency: 'type === "UnionAffiliated"',
                    },
                    {
                        id: 'status',
                        type: 'vselectUserStatus',
                        valueProperty: 'description',
                        label: this.$t('status'),
                        disabled: this.readonly,
                        hidden: this.profileView,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
