<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
    >
        <template v-slot="{ formId, submit }">
            <assignMatchToTournamentForm
                v-bind:dataId="formId"
                v-bind:errors="errorIds"
                v-bind:model="matchTournamentToEdit"
                v-on:change="addMatchToTournament"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import assignMatchToTournamentForm from '@/components/forms/addTournamentToMatch.vue';

import MatchTournamentModel from '@/models/matchTournament.js';

/**
 *
 */
export default {
    name: 'assignMatchToTournamentModal',
    extends: base,
    components: {
        assignMatchToTournamentForm,
    },
    props: {
        matchTournamentToEdit: {
            type: MatchTournamentModel,
            default(){
                return new MatchTournamentModel();
            }
        },
    },
    data() {
        return {
            matchTournament: null,
        };
    },
    computed: {
        payload(){
            return this.matchTournament;
        },
    },
    methods: {
        addMatchToTournament(values){
            const matchTournament = new MatchTournamentModel(values);

            if (values.tournament) {
                matchTournament.tournamentEqId = values.tournament.eqId;
            }

            this.matchTournament = matchTournament;
        },
    },
};
</script>
