import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class NotificationService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/notification-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // notification

    /**
     *
     */
    getAllNotifications(){
        return this.get(['notifications', 'all']);
    }

    /**
     *
     */
    getUnreadNotifications(){
        return this.get(['notifications', 'unread']);
    }

    /**
     *
     */
    createNotification(data){
        return this.post(['notifications'], data);
    }

    /**
     *
     */
    updateNotification(notificationId, data){
        return this.patch(['notifications', notificationId], data);
    }

    /**
     *
     */
    deleteAllNotifications(){
        return this.delete(['notifications', 'all']);
    }
}
