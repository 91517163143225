var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',_vm._b({class:_vm.elementClasses,attrs:{"label":_vm.labelText,"label-for":_vm.elementId,"label-class":_vm.labelClasses,"state":_vm.state}},"b-form-group",_vm._d({},[_vm.labelPropMobile,_vm.labelColsMobile,_vm.labelPropDesktop,_vm.labelColsDesktop])),[_c('v-select',{ref:"vselect",class:_vm.selectClasses,attrs:{"id":_vm.elementId,"multiple":_vm.config.multiple,"options":_vm.optionsList,"reduce":function (data) { return _vm.getOptionKey(data); },"placeholder":_vm.config.placeholder,"disabled":_vm.config.disabled,"closeOnSelect":_vm.config.closeOnSelect,"selectable":function (option) { return !_vm.config.disabled; },"label":_vm.label,"getOptionLabel":_vm.getOptionLabel,"filterable":!_vm.config.requestOptions},on:{"input":_vm.onChange,"search":_vm.onSearch,"search:focus":_vm.onFocus,"open":_vm.onOpen,"close":_vm.onClose},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.value && (_vm.config.required || false)}},'input',attributes,false),events))]}},{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [(!_vm.isLoading)?_c('span',_vm._b({staticClass:"icon-down"},'span',attributes,false)):_c('span')]}},{key:"spinner",fn:function(ref){
var loading = ref.loading;
return [(_vm.isLoading = loading)?_c('throbber',{staticClass:"mx-1",attrs:{"small":""}}):_vm._e()]}},(_vm.optionsLoadInit && _vm.optionsList.length && _vm.config.requestOptions)?{key:"list-footer",fn:function(){return [_c('li',[_c('infinite-loading',{attrs:{"distance":0},on:{"infinite":_vm.loadMore}})],1)]},proxy:true}:null],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }