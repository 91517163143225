<template>
    <div>
        <b-card
            v-bind="rowAttributes"
            v-on:click="emitEvent('click', $event)"
        >
            <b-card-title v-bind="getColumnProps('name', 1)" v-bind:title-tag="titleTag" v-if="showName">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{ getHeadline('name', $t('name')) }}</label>
                <span class="text-uppercase" v-else>{{ apiResource.name }}</span>
            </b-card-title>

            <b-card-text v-bind="getColumnProps('sport', 12, 12, 4)">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $t('activeMethods')) }}</label>
                <div class="d-flex flex-wrap" v-if="!isHeadline">
                    <b-form-checkbox
                        v-for="(apiResourceMethod, index) in apiResource.getMethods()"
                        v-bind:key="index"
                        class="mr-2"
                        v-bind:checked="methodsChecked[index]"
                        v-bind:title="apiResourceMethod.name"
                        v-bind:aria-label="apiResourceMethod.name"
                        disabled
                    />
                </div>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
                <actionButton
                    variant="icon"
                    v-bind="buttonAttributes"
                    v-b-toggle="'accordion-' + apiResource.id"
                >
                    <icon type="down-circle-full" large class="m-0"/>
                </actionButton>
            </b-card-text>

            <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
        </b-card>
        <b-collapse
            :id="'accordion-' + apiResource.id"
            accordion="categories"
            role="tabpanel"
            v-if="!isHeadline"
        >
            <b-row
                cols="1"
                cols-md="2"
                cols-lg="3"
                class="mb-2"
            >
                <b-col
                    v-for="(apiResourceMethod, index) in apiResource.getMethods()"
                    v-bind:key="index"
                    class="my-2"
                >
                    <b-form-checkbox
                        v-bind:checked="methodsChecked[index]"
                        v-bind:aria-label="apiResourceMethod.name"
                        v-on:input="changeMethodStatus(apiResourceMethod, $event)"
                    >
                        {{ apiResourceMethod.displayName || apiResourceMethod.name }}
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </b-collapse>
    </div>
</template>

<script>
import base from '../base.vue';

import ApiResourceModel from '@/models/apiResource.js';

/**
 * api permissions block component
 */
export default {
    name: 'apiPermissionsRow',
    extends: base,
    props: {
        apiResource: {
            type: ApiResourceModel,
            default(){
                return new ApiResourceModel();
            }
        },
        // index: {
        //     type: Number,
        //     required: true
        // },

        showName: {
            type: Boolean,
            default: true,
        },
    },
    data(){
        return {
            methodsChecked: [],
        };
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || '',
                params: Object.assign({
                    dataApiId: this.apiResource.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                apiResource: this.apiResource || null,
            };
        },
        hasVisibleCircles(){
            return true;
        },
    },
    methods: {
        updateMethodsChecked(){
            //for some reason it does not work to directly use v-model on the checkboxes so an array of check statuses it is
            this.methodsChecked = this.apiResource.getMethods().map(apiResourceMethod => apiResourceMethod.isAuthorised);
        },
        changeMethodStatus(apiResourceMethod, status){
            apiResourceMethod.isAuthorised = status;
            this.updateMethodsChecked();

            this.emitEvent('update');
        },
    },
    watch: {
        apiResource(){
            this.updateMethodsChecked();
        },
    },
    created(){
        this.updateMethodsChecked();
    },
};
</script>
