<script>
import pickElement from '../pick.vue';
import tournamentPickModal from '@/components/modals/pick/tournament.vue';

/**
 * pick tournament input element component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'pickTournamentElement',
    extends: pickElement,
    components: {
        tournamentPickModal,
    },
    data(){
        return {
            modalComponent: tournamentPickModal,
        };
    },
};
</script>
