<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="config.id"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
    >
        <b-form-textarea
            v-model="value"
            v-bind:id="config.id"
            v-bind:type="config.type"
            v-bind:required="config.required || false"
            v-bind:placeholder="config.placeholder"
            v-bind:plaintext="config.plaintext"
            v-bind:readonly="config.readonly || false"
            v-bind:disabled="config.disabled || false"
            v-bind:state="state"
        />
    </b-form-group>
</template>

<script>
import base from './base.vue';

/**
 * textarea input element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'textareaElement',
    extends: base,
    data(){
        return {
            value: null
        };
    },
};
</script>
