<script>
import vselectElement from '../vselect.vue';
import PersonRoleModel from '@/models/personRole.js';

/**
 * vselect element for PersonRole items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectPersonRoleElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                //filtering done in frontend due to api not providing the functionality
                return this.$api.call.person.personRoleSearch()
                    .then(result => (result || result.items || []).map(data => new PersonRoleModel(data)))
                    .then(items => items.filter(role => role.name.toLowerCase().includes(text.toLowerCase())));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'name':
                        return Promise.resolve(initValues);
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.person.personRoleGetById(id)))
                            .then(results => results.map(data => new PersonRoleModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
