<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('seriesName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('seriesName')" v-if="isHeadline">{{ getHeadline('seriesName', $t('seriesName')) }}</label>
            <span class="text-uppercase" v-else>{{ (seriesDeclaration.series || {}).name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 3)" v-if="showSport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $tc('sport', 1)) }}</label>
            <span v-if="!isHeadline">{{ seriesDeclaration.sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('status', 12, 6, 2)" v-if="showStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('status')">{{ getHeadline('status', $t('status')) }}</label>
            <statusIndicatorIcon v-bind:status="seriesDeclaration.status" v-bind:showText="true" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('deadline', 12, 6, 2)" v-if="showDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('deadline')">{{ getHeadline('deadline', $t('deadline')) }}</label>
            <span v-if="!isHeadline">{{ $fd(seriesDeclaration.getStepDeadlineDate()) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicatorIcon from '../statusIndicatorIcon.vue';

import SeriesDeclarationModel from '@/models/declaration/series.js';

/**
 * series review block component
 */
export default {
    name: 'seriesReviewRow',
    extends: base,
    components: {
        statusIndicatorIcon,
    },
    props: {
        seriesDeclaration: {
            type: SeriesDeclarationModel,
            default(){
                return new SeriesDeclarationModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },
        showSport: {
            type: Boolean,
            default: true,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        showDeadline: {
            type: Boolean,
            default: false,
        },

        showDetail: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'reviewSeriesIndex',
                params: Object.assign({
                    dataSeriesDeclarationId: this.seriesDeclaration.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                seriesDeclaration: this.seriesDeclaration || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail);
        },
    },
};
</script>
