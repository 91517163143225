<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editTournament')"
            v-bind:waitFor="apiData.tournament"
            v-bind:waitForRetry="reloadTournament"
            v-on:submit="updateTournament"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentDetail"
                    v-bind:loadingWhile="apiData.tournamentUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || declarationWarning || mappingWarning">
                <validations
                    v-bind:warnings="((declarationWarning || mappingWarning) ? [...(declarationWarning ? [declarationWarning] : []), ...(mappingWarning ? [mappingWarning] : [])] : undefined)"
                    v-bind:errors="error ? error.getErrorMessages($t('requestError')) : undefined"
                    v-if="error || declarationWarning || mappingWarning"
                />
            </template>
            <template v-slot>
                <editTournamentForm
                    v-bind:headline="$t('tournamentInformation')"
                    v-bind:model="tournament"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:isDeclarationFixed="Boolean(declaration && declaration.id)"
                    v-on:change="updateDeclarationBasics"
                />
                <loading
                    v-bind:waitFor="apiData.declarationTemplate"
                    v-bind:waitForRetry="loadDeclarationTemplate"
                    v-bind:throbberProps="{ class: 'mb-3' }"
                    v-if="tournament && tournament.declarationEnabled && tournament.declarationWorkflowId"
                >
                    <preliminarySquadDeclarationForm
                        dataId="preliminary"
                        v-bind:headline="$t('preliminarySquadDeclaration')"
                        v-bind:model="declarationTOPPreliminary"
                        v-bind:errors="error ? error.getErrorIds() : undefined"
                        v-if="hasPreliminaryDeclaration"
                    />
                    <tournamentSquadDeclarationForm
                        dataId="main"
                        v-bind:headline="$t('tournamentSquadDeclaration')"
                        v-bind:model="declarationTOPMain"
                        v-bind:declaration="declaration"
                        v-bind:errors="error ? error.getErrorIds() : undefined"
                    />
                </loading>
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingEditMixins } from '@/mixins/entityMapping.js';
import loading from '@/components/loading.vue';
import validations from '@/components/validations.vue';
import editTournamentForm from '@/components/forms/editTournament.vue';
import tournamentSquadDeclarationForm from '@/components/forms/tournamentSquadDeclaration.vue';
import preliminarySquadDeclarationForm from '@/components/forms/preliminarySquadDeclaration.vue';

import TournamentModel from '@/models/tournament.js';
import TournamentDeclarationModel from '@/models/declaration/tournament.js';
import WorkflowModel from '@/models/workflow.js';

export default {
    name: 'commonTournamentEdit',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingEditMixins,
    ],
    components: {
        loading,
        validations,
        editTournamentForm,
        tournamentSquadDeclarationForm,
        preliminarySquadDeclarationForm,
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                tournament: this.loadTournament(),
                tournamentUpdate: null,
                declarationTemplate: null,
            },
            tournament: null,
            declaration: null,
            declarationTOPPreliminary: null,
            declarationTOPMain: null,
            declarationWarning: null,
            mappingWarning: null,
            error: null,
        };
    },
    computed: {
        toTournamentDetail(){
            return { name: 'commonTournamentDetail', params: { dataTournamentId: this.dataTournamentId } };
        },
        hasPreliminaryDeclaration(){
            return this.declaration && this.declaration.template && this.declaration.getTemplateSteps().includes(TournamentDeclarationModel.steps.preliminarySquad);
        },
    },
    methods: {
        loadTournament(){
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId).then(data => {
                this.tournament = new TournamentModel(data);

                //get mapping providers
                this.loadEntityMappingProviders(this.tournament);

                return this.$api.call.termsOfParticipation.tournamentDeclarationByTournamentEqId(this.tournament.eqId).catch(error => {
                    //if none found just use empty data
                    if(error.status === 404){
                        return {};
                    }
                    throw error;
                }).then(data => {
                    this.declaration = new TournamentDeclarationModel(data);

                    this.declarationTOPPreliminary = this.declaration.getConfigurationFormModel(TournamentDeclarationModel.steps.preliminarySquad);
                    if (this.declaration.steps.find(s => s.name === TournamentDeclarationModel.steps.squad)){
                        this.declarationTOPMain = this.declaration.getConfigurationFormModel(TournamentDeclarationModel.steps.squad);
                    }
                    else
                    {
                        this.declarationTOPMain = this.declaration.getConfigurationFormModel(TournamentDeclarationModel.steps.main);
                    }

                    //set base data
                    this.$set(this.tournament, 'declarationEnabled', this.declarationTOPMain.enabled);
                    this.$set(this.tournament, 'declarationWorkflowId', this.declarationTOPMain.workflowId);
                    this.$set(this.tournament, 'declarationOpeningDate', this.declarationTOPMain.openingDate);
                    this.$set(this.tournament, 'declarationDeadlineDate', this.declarationTOPMain.deadlineDate);

                    //load template
                    this.loadDeclarationTemplate(true);
                    return this.apiData.declarationTemplate;
                });
            });
        },
        reloadTournament(){
            this.apiData.tournament = this.loadTournament();
        },
        updateDeclarationBasics(tournament){
            //update declarationTOP base data
            [this.declarationTOPPreliminary, this.declarationTOPMain].forEach(declarationTOP => {
                this.$set(declarationTOP, 'enabled', tournament.declarationEnabled);
                this.$set(declarationTOP, 'workflowId', tournament.declarationWorkflowId);
                this.$set(declarationTOP, 'openingDate', tournament.declarationOpeningDate);
                this.$set(declarationTOP, 'deadlineDate', tournament.declarationDeadlineDate);
            });

            this.loadDeclarationTemplate();
        },
        loadDeclarationTemplate(force){
            if(!this.tournament.declarationEnabled || !this.tournament.declarationWorkflowId){
                this.apiData.declarationTemplate = null;
                this.declaration.template = null;

                return;
            }

            //method gets called on every form change, but we only need to request if workflowId changed
            if(this.declaration.workflowId === this.tournament.declarationWorkflowId && !force){
                return;
            }

            this.apiData.declarationTemplate = this.$api.call.termsOfParticipation.declarationWorkflowsById(this.tournament.declarationWorkflowId).then(data => {
                const workflow = new WorkflowModel(data);

                return this.$api.call.termsOfParticipation.declarationTemplateById(workflow.templateId).then(template => {
                    this.declaration.workflowId = this.tournament.declarationWorkflowId;
                    this.declaration.template = template;

                    //if there is a "squad" step then change the main declarationTOP step to it, otherwise use "main" step
                    if(this.declaration.getTemplateSteps().includes(TournamentDeclarationModel.steps.squad)){
                        this.$set(this.declarationTOPMain, 'step', TournamentDeclarationModel.steps.squad);
                    }
                    else {
                        this.$set(this.declarationTOPMain, 'step', TournamentDeclarationModel.steps.main);
                    }
                });
            });
        },
        updateTournament(){
            let queue = Promise.resolve();

            //if logo upload file has been set, upload to storage and update tournament logo storage information
            if(this.tournament.logo instanceof File){
                queue = queue.then(() => this.$api.call.tournament.storeFiles(this.tournament.logo)).then(storageFile => {
                    this.tournament.setLogoStorage(storageFile);
                }, error => {
                    //set custom file upload validation error
                    error.errors.logo = this.$t('fileUploadError');

                    throw error;
                });
            }

            //if preliminary declaration enabled and its templates have been set, upload to storage and update declaration information
            if(this.declarationTOPMain.enabled && this.hasPreliminaryDeclaration){
                const fileUploads = this.declarationTOPPreliminary.getFileUploads();
                const fileUploadFiles = Object.values(fileUploads);

                //upload files to storage and update preliminary declaration file storage information
                if(fileUploadFiles.length){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles(fileUploadFiles)).then(storageFiles => {
                        this.declarationTOPPreliminary.setFileStorages(fileUploads, storageFiles);
                    }, error => {
                        throw this.declarationTOPPreliminary.setFileErrors(fileUploads, error);
                    });
                }
            }

            //if main declaration enabled and its templates have been set, upload to storage and update declaration information
            if(this.declarationTOPMain.enabled){
                const fileUploads = this.declarationTOPMain.getFileUploads();
                const fileUploadFiles = Object.values(fileUploads);

                //upload files to storage and update declaration file storage information
                if(fileUploadFiles.length){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles(fileUploadFiles)).then(storageFiles => {
                        this.declarationTOPMain.setFileStorages(fileUploads, storageFiles);
                    }, error => {
                        throw this.declarationTOPMain.setFileErrors(fileUploads, error);
                    });
                }
            }

            //generate declaration config if enabled
            if(this.declarationTOPMain.enabled){
                //make sure tournament is set to declaration
                this.declaration.setTournament(this.tournament);

                //generate config from form data
                queue = queue.then(() => {
                    //update declarationTOP base data
                    [this.declarationTOPPreliminary, this.declarationTOPMain].forEach(declarationTOP => {
                        this.$set(declarationTOP, 'enabled', this.tournament.declarationEnabled);
                        this.$set(declarationTOP, 'workflowId', this.tournament.declarationWorkflowId);
                        this.$set(declarationTOP, 'openingDate', this.tournament.declarationOpeningDate);
                        this.$set(declarationTOP, 'deadlineDate', this.tournament.declarationDeadlineDate);
                    });

                    this.declaration.generateConfiguration(this.hasPreliminaryDeclaration ? [this.declarationTOPPreliminary, this.declarationTOPMain] : this.declarationTOPMain);
                });
            }

            //update tournament
            queue = queue.then(() => this.$api.call.tournament.tournamentUpdateById(this.dataTournamentId, this.tournament.toJSON()));

            //update mappings
            queue = this.manageMappings(this.tournament, queue);

            //submit declaration if enabled
            if(this.declarationTOPMain.enabled){
                const declarationErrorHandler = error => {
                    this.declarationWarning = this.$t('tournamentDeclarationUpdateWarning');
                    error.errorIdParser = this.declaration.errorIdParser.bind(this.declaration);
                    throw error;
                };

                //update or create declaration
                if(this.declaration.id){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.tournamentDeclarationUpdateById(this.declaration.id, this.declaration.toJSON()).catch(declarationErrorHandler));
                }
                else {
                    queue = queue.then(() => this.$api.call.termsOfParticipation.tournamentDeclarationCreate(this.declaration.toJSON()).catch(declarationErrorHandler));
                }
            }

            //handle request outcome
            queue = queue.then(() => {
                this.error = null;
                this.$router.push(this.toTournamentDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournament update failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.tournamentUpdate = queue;
        },
    },
}
</script>
