<script>
import formComponent from '@/components/form.vue';
import MatchTournamentModel from '@/models/matchTournament.js';

/**
 * component
 */
export default {
    name: 'assignMatchToTournamentForm',
    extends: formComponent,
    props: {
        model: {
            type: MatchTournamentModel,
        },
    },
    methods: {
        showModal() {
            this.$root.$emit('bv::show::modal', 'searchTournamentModal');
        },
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
    },
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'tournament',
                        type: 'pickTournament',
                        label: this.$tc('tournament', 1),
                        plaintext: this.readonly,
                        disabled: this.model.tournamentEqId ? true : this.readonly,
                        readonly: true,
                        required: !this.model.tournamentEqId,
                        hidden: this.model.tournamentEqId,
                    },
                    {
                        id: 'tournamentStageEqId',
                        type: 'vselectTournamentStage',
                        valueProperty: 'eqId',
                        label: this.$tc('tournament', 1) + ' ' + this.$t('stage'),
                        placeholder: this.$t('select') + ' ' + this.$tc('tournament', 1) + ' ' + this.$t('stage'),
                    },
                    {
                        id: 'groupName',
                        type: 'text',
                        label: this.$t('group'),
                        placeholder: this.$t('enterGroup'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'matchNumber',
                        type: 'number',
                        min: 1000,
                        max: 9999,
                        label: this.$t('matchNumber'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                tournamentEqId: 'tournament',
            };
        },
    },
};
</script>
