<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-bind:scrollable="false"
    >
        <template v-slot="{ formId, submit }">
            <addOfficialForm
                v-bind:dataId="formId"
                v-bind:errors="errorIds"
                v-on:change="updateOfficial"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import addOfficialForm from '@/components/forms/addOfficial.vue';

export default {
    name: 'addOfficialModal',
    extends: base,
    components: {
        addOfficialForm,
    },
    data() {
        return {
            officialData: null,
        };
    },
    computed: {
        payload(){
            return this.officialData;
        },
    },
    methods: {
        updateOfficial(values){
            this.officialData = {
                personEqId: values.official,
                positionEqId: values.position
            };
        },
    },
};
</script>
