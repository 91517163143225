<script>
import vselectElement from '../vselect.vue';

/**
 * vselect element for workflowStatus items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectWorkflowStatus',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            const workflowStatuses = {
                open: {
                    id: 'open',
                    label: this.$t('open'),
                },
                inProgress: {
                    id: 'inProgress',
                    label: this.$t('inProgress'),
                },
                submitted: {
                    id: 'submitted',
                    label: this.$t('submitted'),
                },
                approved: {
                    id: 'approved',
                    label: this.$t('approved'),
                },
                rejected: {
                    id: 'rejected',
                    label: this.$t('rejected'),
                },
            };

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return Object.values(workflowStatuses).filter(item => !text || item.label.toLowerCase().includes(text.toLowerCase()));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => Promise.resolve(workflowStatuses[id])));
                }
            };

            return config;
        },
    },
};
</script>
