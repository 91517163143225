<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:waitFor="apiData.venue"
            v-bind:waitForRetry="reloadVenue"
            v-bind:title="$t('venueDetails')"
            v-bind:name="venue ? venue.name : ''"
            v-bind:details="detailData"
            v-bind:moreDetails="moreDetailData"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="secondary"
                    v-bind:to="editVenueLink(dataVenueId)"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <list
            v-bind:items="venueAliases"
            v-bind:title="$t('venueAliases')"
            v-bind:waitFor="apiData.venueAliases"
            v-bind:waitForRetry="reloadVenueAliases"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.venues.aliases.update"
                    v-b-modal.modal-aliases
                >
                    {{ $t('aliasDateChanges') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:permission="permissions.venues.aliases.create"
                    v-b-modal.modal-createVenueAlias
                >
                    {{ $t('addNewAlias') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <venueAliasRow
                    showDateFrom showDateUntil showEdit
                    isHeadline
                />
            </template>
            <template v-slot="{ item: venueAlias, role }">
                <venueAliasRow
                    showDateFrom showDateUntil showEdit
                    v-bind:venueAlias="venueAlias"
                    v-bind:role="role"
                    v-bind:updateHandler="changeVenueAlias"
                    v-on:updated="changeVenueAliasComplete"
                />
            </template>
        </list>

        <changeDateVenueAliasModal
            id="modal-aliases"
            v-bind:title="$t('venueAliasDateChange')"
            v-bind:venueAliases="venueAliases"
            v-bind:dataVenueId="dataVenueId"
            v-bind:confirmHandler="changeVenueAliasDate"
            v-on:confirmed="changeVenueAliasDateComplete"
            v-if="venueAliases"
        />

        <createVenueAliasModal
            id="modal-createVenueAlias"
            v-bind:currentlyActiveAlias="getCurrentlyActiveAlias"
            v-bind:title="$t('addNewVenueAlias')"
            v-bind:confirmHandler="createVenueAlias"
            v-on:confirmed="createVenueAliasComplete"
        />

        <list
            v-bind:items="venueMatches"
            v-bind:title="$t('latestMatches')"
            v-bind:waitFor="apiData.venueMatches"
            v-bind:waitForRetry="reloadVenueMatches"
            v-bind:hide="hideElement(2)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'commonMatchIndex', query: { venue: venue ? venue.eqId : 0 } }"
                >
                    {{ $t('viewAllMatches') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <matchRow
                    showScore showKickOffTime showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: match, role }">
                <matchRow
                    showScore showKickOffTime showDetail
                    v-bind:match="match"
                    v-bind:role="role"
                />
            </template>
        </list>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import subNavigation from '@/components/subnavigation.vue';
import detailsHeader from '@/components/detailsHeader.vue';
import changeDateVenueAliasModal from '@/components/modals/venue/changeDateVenueAlias.vue';
import createVenueAliasModal from '@/components/modals/venue/createVenueAlias.vue';
import venueAliasRow from '@/components/rows/venueAlias.vue';
import matchRow from '@/components/rows/match.vue';
import VenueModel from '@/models/venue.js';
import VenueAliasModel from '@/models/venueAlias.js';
import MatchModel from '@/models/match.js';

export default {
    name: 'commonVenueDetail',
    extends: base,
    components: {
        subNavigation,
        detailsHeader,
        changeDateVenueAliasModal,
        createVenueAliasModal,
        venueAliasRow,
        matchRow,
    },
    props: {
        dataVenueId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            venue: this.loadVenue(),
        };

        return {
            apiData: {
                venue: this.apiData.venue,
                venueAliases: this.loadVenueAliases(),
                venueMatches: this.loadVenueMatches(),
            },
            venue: null,
            venueAliases: [],
            venueMatches: [],
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$t('venueAliases'),
                    },
                    2: {
                        label: this.$t('latestMatches'),
                    },
                }
            },
        };
    },
    methods: {
        loadVenue(){
            return this.$api.call.location.venueGetById(this.dataVenueId, false).then(data => {
                if (data !== undefined) {
                    this.venue = new VenueModel(data);

                    this.reloadVenueAliases();
                    this.reloadVenueMatches();
                }
            });
        },
        reloadVenue(){
            this.apiData.venue = this.loadVenue();
        },
        loadVenueAliases(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.venue){
                return new Promise((resolve, reject) => this.apiData.venue.catch(reject));
            }

            return this.$api.call.location.venueAliasesGetByVenueId(this.dataVenueId, false).then(result => {
                if (result !== undefined) {
                    this.venueAliases = (result.venueAliases || []).map(data => new VenueAliasModel(data));
                }
            });
        },
        reloadVenueAliases(){
            this.apiData.venueAliases = this.loadVenueAliases();
        },
        createVenueAlias(data){
            const venueAlias = VenueAliasModel.from(data);

            return this.$api.call.location.venueAliasCreate(this.dataVenueId, venueAlias.toJSON());
        },
        createVenueAliasComplete(){
            this.venueAliases = [];
            this.reloadVenueAliases();
        },
        changeVenueAliasDate(data){
            const dataToSave = {};
            for (const [key, value] of Object.entries(data)) {
                // get the ID
                const id = key.split('_')[1];

                if (key === 'newValidFrom_' + id) {
                    if (typeof dataToSave[id] === 'undefined') {
                        dataToSave[id] = {};
                    }
                    dataToSave[id].id = id;
                    dataToSave[id].dateFrom = this.convertLocalDateToUTCIgnoringTimezone(new Date(value));
                }
            }

            const aliases = [];
            Object.entries(dataToSave).forEach((data, key) => {
                aliases.push(data[1]);
            });

            return this.$api.call.location.changeDateVenueAliasByVenueId(this.dataVenueId, { aliases });
        },
        changeVenueAliasDateComplete(){
            this.venueAliases = [];
            this.reloadVenueAliases();
        },
        convertLocalDateToUTCIgnoringTimezone(date) {
            const utcTimeStamp = Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds(),
            );
            return new Date(utcTimeStamp);
        },
        changeVenueAlias(venueAlias){
            return this.$api.call.location.venueAliasNameUpdate(this.dataVenueId, venueAlias.toJSON());
        },
        changeVenueAliasComplete(){
            this.venueAliases = [];
            this.reloadVenueAliases();
        },
        loadVenueMatches(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.venue){
                return new Promise((resolve, reject) => this.apiData.venue.catch(reject));
            }

            return this.$api.call.match.matchSearch({
                isResult: true,
                venueEqId: this.venue.eqId,
                kickoffDateDirection: 'Desc',
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(result => {
                this.venueMatches = (result ? (result.data || []) : []).map(data => new MatchModel(data));
            });
        },
        reloadVenueMatches(){
            this.apiData.venueMatches = this.loadVenueMatches();
        },
        editVenueLink(id){
            return { name: 'commonVenueEdit', params: { dataVenueId: id } };
        },
        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },
    },
    computed: {
        detailData(){
            if(!this.venue){
                return [];
            }

            return [
                {
                    label: this.$t('town'),
                    content: (this.venue.location ? this.venue.location.name : ''),
                },
                {
                    label: this.$t('country'),
                    content: (this.venue.location ? this.venue.location.countryName : ''),
                },
            ];
        },
        moreDetailData(){
            if(!this.venue){
                return [];
            }

            return [
                {
                    headline: this.$t('additionalVenueInformation'),
                    items: [
                        {
                            label: this.$t('capacity'),
                            content: this.venue.capacity,
                            type: 'number',
                        },
                        {
                            label: this.$t('seats'),
                            content: this.venue.seats,
                            type: 'number',
                        },
                        {
                            label: this.$t('recordAttendance'),
                            content: this.venue.recordAttendance,
                            type: 'number',
                        },
                        {
                            label: this.$t('recordDetails'),
                            content: this.venue.recordDetail,
                        },
                        {
                            label: this.$t('recordGame'),
                            content: this.venue.recordGame,
                            type: 'number',
                        },
                        {
                            label: this.$t('displayScreens'),
                            content: this.venue.displayScreens > 0,
                            type: 'boolean',
                        },
                        {
                            label: this.$t('numberOfScreens'),
                            content: this.venue.displayScreenCount,
                            type: 'number',
                        },
                        {
                            label: this.$t('lights'),
                            content: this.venue.hasLights,
                            type: 'boolean',
                        },
                        {
                            label: this.$t('roof'),
                            content: this.venue.hasRoof,
                            type: 'boolean',
                        },
                        {
                            label: this.$t('venueBuilt'),
                            content: this.venue.built,
                            type: 'date',
                        },
                        {
                            label: this.$t('lastDeveloped'),
                            content: this.venue.lastDeveloped,
                            type: 'date',
                        },
                        {
                            label: this.$t('constructionCosts'),
                            content: this.venue.cost,
                            type: 'number',
                        },
                        {
                            label: this.$t('reconstructionCosts'),
                            content: this.venue.redevelopmentCost,
                            type: 'number',
                        },
                        {
                            label: this.$t('corporateBoxes'),
                            content: this.venue.corporateBoxes > 0,
                            type: 'boolean',
                        },
                        {
                            label: this.$t('numberOfBoxes'),
                            content: this.venue.corporateBoxesCount,
                            type: 'number',
                        },
                        {
                            label: this.$t('venueWebsite'),
                            content: this.venue.stadiumWebsiteUrl
                        },
                        {
                            label: this.$t('latitude'),
                            content: this.venue.latitude,
                        },
                        {
                            label: this.$t('longitude'),
                            content: this.venue.longitude,
                        },
                    ],
                },
            ];
        },
        getCurrentlyActiveAlias(){
            if(!this.venue || !this.venue.alias){
                return '';
            }

            return this.venue.alias.name;
        },
    },
};
</script>
