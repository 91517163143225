<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="config.label ? labelColsMobile : null"
        v-bind:[labelPropDesktop]="config.label ? labelColsDesktop : null"
        v-bind:state="state"
    >
        <b-form-radio-group
            v-bind:options="config.options"
            v-bind:id="elementId"
            v-model="value"
            v-bind:name="config.name"
            v-bind:required="config.required || false"
            v-bind:stacked="config.stacked || false"
            v-bind:plain="config.plain || false"
            v-bind:size="config.size || 'medium'"
            v-bind:disabled="config.disabled"
            v-bind:state="state"
        ></b-form-radio-group>
    </b-form-group>
</template>

<script>
import base from './base.vue';

/**
 * radio select element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'radioselectElement',
    extends: base,
    props: {
        dataValue: {
            type: [String, Number, Boolean],
        },
    },
    data(){
        return {
            value: null
        };
    },
};
</script>
