<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:waitFor="apiData.tournamentStandingType"
            v-bind:waitForRetry="reloadTournamentStandingType"
            v-bind:title="$t('tournamentStandingTypeDetails')"
            v-bind:name="tournamentStandingType ? tournamentStandingType.name : ''"
            v-bind:nameSub="tournamentStandingType ? tournamentStandingType.sport : ''"
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="editTournamentStandingTypeLink(dataTournamentStandingTypeId)"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <list
            v-bind:items="tournamentStandingType ? tournamentStandingType.pointParameters : []"
            v-bind:title="$t('pointsParameters')"
            v-bind:waitFor="apiData.tournamentStandingType"
            v-bind:waitForRetry="reloadTournamentStandingType"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.tournamentStandingTypes.pointParameters.create"
                    v-b-modal.modal-addPointParameterModal
                >
                    {{ $t('addNewPointsParameter') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <tournamentStandingTypeParameterRow
                    showValue showDiscriminator showId showEdit showDelete
                    isHeadline
                />
            </template>
            <template v-slot="{ item: parameters, role }">
                <tournamentStandingTypeParameterRow
                    showValue showDiscriminator showId showEdit showDelete
                    v-bind:parameters="parameters"
                    v-bind:standingTypeId="dataTournamentStandingTypeId"
                    v-bind:standingTypeName="tournamentStandingType.name"
                    v-bind:role="role"
                    v-bind:removePermission="permissions.tournamentStandingTypes.pointParameters.delete"
                    v-bind:parameterType="'point'"
                    v-bind:removeHandler="removeParameter"
                    v-on:removed="reloadTournamentStandingType"
                    v-on:changed="reloadTournamentStandingType"
                />
            </template>
        </list>

        <list
            v-bind:items="tournamentStandingType ? tournamentStandingType.orderParameters : []"
            v-bind:title="$t('orderingParameters')"
            v-bind:waitFor="apiData.tournamentStandingType"
            v-bind:waitForRetry="reloadTournamentStandingType"
            v-bind:hide="hideElement(2)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="arrangeOrderLink"
                >
                    {{ $t('arrangeOrder') }}
                </actionButton>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.tournamentStandingTypes.orderParameters.create"
                    v-b-modal.modal-addOrderingParameterModal
                >
                    {{ $t('addNewOrderingParameter') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <tournamentStandingTypeParameterRow
                    showOrder showId showDelete
                    isHeadline
                />
            </template>
            <template v-slot="{ item: parameters, role }">
                <tournamentStandingTypeParameterRow
                    showOrder showId showDelete
                    v-bind:parameters="parameters"
                    v-bind:standingTypeId="dataTournamentStandingTypeId"
                    v-bind:standingTypeName="tournamentStandingType.name"
                    v-bind:role="role"
                    v-bind:removePermission="permissions.tournamentStandingTypes.orderParameters.delete"
                    v-bind:parameterType="'order'"
                    v-bind:removeHandler="removeParameter"
                    v-on:removed="reloadTournamentStandingType"
                />
            </template>
        </list>

        <addEditTournamentStandingTypeParameterModal
            id="modal-addPointParameterModal"
            v-bind:title="$t('createNewPointParameter')"
            v-bind:standingTypeId="dataTournamentStandingTypeId"
            parameterType="point"
            v-bind:create="true"
            v-on:confirmed="reloadTournamentStandingType"
        />

        <addEditTournamentStandingTypeParameterModal
            id="modal-addOrderingParameterModal"
            v-bind:title="$t('createNewOrderingParameter')"
            v-on:add="addOrderingParameter"
            v-bind:standingTypeId="dataTournamentStandingTypeId"
            parameterType="order"
            v-bind:hideValue="true"
            v-bind:hideDiscriminator="true"
            v-bind:create="true"
            v-on:confirmed="reloadTournamentStandingType"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import subNavigation from '@/components/subnavigation.vue';
import detailsHeader from '@/components/detailsHeader.vue';
import addEditTournamentStandingTypeParameterModal from '@/components/modals/addEditTournamentStandingTypeParameter.vue';
import tournamentStandingTypeParameterRow from '@/components/rows/administration/tournamentStandingTypeParameter.vue';

import TournamentStandingTypeModel from '@/models/tournamentStandingType.js';

export default {
    name: 'administrationTournamentStandingTypeDetail',
    extends: base,
    components: {
        subNavigation,
        detailsHeader,
        tournamentStandingTypeParameterRow,
        addEditTournamentStandingTypeParameterModal,
    },
    props: {
        dataTournamentStandingTypeId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataTournamentStandingTypeName: {
            type: String,
            mandatory: false,
            default: null,
        },
    },
    data(){
        return {
            tournamentStandingType: null,
            apiData: {
                tournamentStandingType: this.loadTournamentStandingType(),
            },
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$t('pointsParameters'),
                    },
                    2: {
                        label: this.$t('orderingParameters'),
                    },
                }
            },
        };
    },
    methods: {
        editTournamentStandingTypeLink(id){
            return { name: 'administrationTournamentStandingTypeEdit', params: { dataTournamentStandingTypeId: id } };
        },
        loadTournamentStandingType(){
            return this.$api.call.tournament.tournamentStandingTypeGetById(this.dataTournamentStandingTypeId).then(data => {
                if (data !== undefined) {
                    this.tournamentStandingType = new TournamentStandingTypeModel(data);
                }
            });
        },
        reloadTournamentStandingType(){
            this.apiData.tournamentStandingType = this.loadTournamentStandingType();
        },
        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },
        addOrderingParameter(orderParameter) {
            this.$api.call.tournament.tournamentStandingTypeParameterCreate('order', orderParameter);
        },
        removeParameter(data) {
            this.$api.call.tournament.standingTypeParameterDeleteByParameterId(this.dataTournamentStandingTypeId, data.id)
                .then(this.reloadTournamentStandingType());
        },
    },
    computed: {
        arrangeOrderLink(){
            return {
                name: 'administrationTournamentStandingTypeOrderingParameter',
                params: Object.assign({
                    dataTournamentStandingTypeId: this.dataTournamentStandingTypeId,
                }, this.detailLinkParams),
            };
        },
    },
};
</script>
