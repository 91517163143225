import constants from '@/constants.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationTournamentTeamStatusTypesIndex from '@/views/administration/tournamentTeamStatusTypes/index.vue';

export default {
    path: 'tournament-team-status-type/',
    component: pageFrame,
    children: [
        {
            name: 'administrationTournamentTeamStatusTypesIndex',
            path: constants.routePaths.index,
            component: administrationTournamentTeamStatusTypesIndex,
            meta: {
                permission: permissions.tournamentTeamStatusTypes.list,
            },
        },
    ],
};
