<script>
import formComponent from '@/components/form.vue';
import PersonModel from '@/models/person.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'createPersonForm',
    extends: formComponent,
    props: {
        model: {
            type: PersonModel,
        },
        hideMapping: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dependencies: {
                isPlayer: 'newPersonRoleTypeId === "Player"',
                isManagement: 'newPersonRoleTypeId === "Management"',
                isOfficial: 'newPersonRoleTypeId === "Official"',
                isNotOfficial: 'newPersonRoleTypeId !== "Official"',
            },
        };
    },
    computed: {
        elementsConfig() {
            return [
                {
                    type: 'headline',
                    text: this.$t('personInformation'),
                },
                this.createFormRow([
                    {
                        id: 'gender',
                        type: 'radioselect',
                        selected: 'gender',
                        options: [
                            {
                                value: constants.genders.male,
                                text: this.$t('male'),
                            },
                            {
                                value: constants.genders.female,
                                text: this.$t('female'),
                            },
                        ],
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        label: this.$t('gender'),
                        required: true,
                    },
                    {
                        id: 'displayName',
                        type: 'text',
                        label: this.$t('displayName'),
                        placeholder: this.$t('enterDisplayName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'lastName',
                        type: 'text',
                        label: this.$t('surnameAsPerPassport'),
                        placeholder: this.$t('enterNameAsPerPassport'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'knownAsLastname',
                        type: 'text',
                        label: this.$t('knownAsSurname'),
                        placeholder: this.$t('enterKnownAsSurname'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'firstName',
                        type: 'text',
                        label: this.$t('forenameAsPerPassport'),
                        placeholder: this.$t('enterNameAsPerPassport'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'knownAsFirstname',
                        type: 'text',
                        label: this.$t('knownAsForename'),
                        placeholder: this.$t('enterKnownAsForename'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'initials',
                        type: 'text',
                        label: this.$t('initials'),
                        placeholder: this.$t('enterInitials'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'maidenName',
                        type: 'text',
                        label: this.$t('maidenName'),
                        placeholder: this.$t('enterMaidenName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'nativeKnownAsLastname',
                        type: 'text',
                        label: this.$t('nativeKnownAsSurname'),
                        placeholder: this.$t('enterNativeKnownAsSurname'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'nativeKnownAsFirstname',
                        type: 'text',
                        label: this.$t('nativeKnownAs'),
                        placeholder: this.$t('enterKnownAsForename'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'dateOfBirth',
                        type: 'date',
                        label: this.$t('dateOfBirthAsPerPassport'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                    },
                    {
                        id: 'dateOfDeath',
                        type: 'date',
                        label: this.$t('dateOfDeath'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                    },
                    {
                        id: 'lionsNumber',
                        type: 'text',
                        label: this.$t('lionsNumber'),
                        placeholder: this.$t('enterLionsNumber'),
                    },
                    {
                        id: 'prefix',
                        type: 'text',
                        label: this.$t('prefix'),
                        placeholder: this.$t('enterPrefix'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'suffix',
                        type: 'text',
                        label: this.$t('suffix'),
                        placeholder: this.$t('enterSuffix'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'emailAddress',
                        type: 'text',
                        label: this.$t('email'),
                        placeholder: this.$t('enterEmailAddress'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
                {
                    type: 'hline',
                },
                {
                    type: 'headline',
                    text: this.$t('passportInformation'),
                },
                this.createFormRow([
                    {
                        id: 'nationalityCountryIsoCode',
                        type: 'vselectCountry',
                        modalTitle: this.$t('selectPlace'),
                        label: this.$t('nationalityAsPerPassport'),
                        valueProperty: 'isoCode',
                        disabled: this.readonly,
                    },
                    {
                        id: 'placeOfBirth',
                        type: 'pickLocation',
                        modalTitle: this.$t('selectPlace'),
                        label: this.$t('placeOfBirthAsPerPassport'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'passport',
                        type: 'file',
                        label: this.$t('passportScan'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        readonly: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'passportNo',
                        type: 'text',
                        label: this.$t('passportId'),
                        placeholder: this.$t('enterPassportId'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'passportExpiryDate',
                        type: 'date',
                        label: this.$t('expiryDate'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'passportIssueDate',
                        type: 'date',
                        label: this.$t('issueDate'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
                {
                    type: 'hline',
                },
                {
                    type: 'headline',
                    text: this.$t('personRole'),
                },
                this.createFormRow([
                    {
                        id: 'newPersonRoleTypeId',
                        type: 'vselectPersonRole',
                        label: this.$tc('role', 2),
                        placeholder: this.$t('selectPersonRole'),
                        valueProperty: 'name',
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'newPersonDateFrom',
                        type: 'date',
                        label: this.$t('validFrom'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'newPersonDateUntil',
                        type: 'date',
                        label: this.$t('validUntil'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'newPersonTeam',
                        type: 'pickTeam',
                        label: this.$t('teamAffiliation'),
                        text: this.$t('selectTeam'),
                        disabled: this.readonly,
                        required: true,
                        dependency: this.dependencies.isNotOfficial,
                    },
                    {
                        id: 'newPersonUnionEqId',
                        type: 'vselectUnion',
                        valueProperty: 'eqId',
                        label: this.$t('unionAffiliation'),
                        placeholder: this.$t('selectUnion'),
                        disabled: this.readonly,
                        required: true,
                        dependency: this.dependencies.isOfficial,
                    },
                ]),
                {
                    type: 'hline',
                },
                {
                    type: 'headline',
                    text: this.$t('personHeadshot'),
                },
                this.createFormRow([
                    {
                        id: 'headshot',
                        type: 'file',
                        label: this.$t('file'),
                        accept: constants.fileAcceptance.images,
                        readonly: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
                {
                    type: 'hline',
                    dependency: this.dependencies.isPlayer,
                },
                {
                    type: 'headline',
                    text: this.$t('playerRelevantInformation'),
                    dependency: this.dependencies.isPlayer,
                },
                this.createFormRow([
                    {
                        id: 'newPersonHeight',
                        type: 'number',
                        label: this.$t('height'),
                        placeholder: this.$t('height'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'newPersonWeight',
                        type: 'number',
                        label: this.$t('weight'),
                        placeholder: this.$t('weight'),
                        disabled: this.readonly,
                    },
                ], this.dependencies.isPlayer),
                this.createFormRow([
                    {
                        id: 'newPersonDominantHand',
                        type: 'vselect',
                        label: this.$t('dominantHand'),
                        placeholder: this.$t('dominantHand'),
                        options: [
                            {
                                id: constants.dominantSides.left,
                                label: this.$t('left'),
                            },
                            {
                                id: constants.dominantSides.right,
                                label: this.$t('right'),
                            },
                            {
                                id: constants.dominantSides.both,
                                label: this.$t('ambidextrous'),
                            },
                        ],
                        disabled: this.readonly,
                    },
                    {
                        id: 'newPersonDominantFoot',
                        type: 'vselect',
                        label: this.$t('dominantFoot'),
                        placeholder: this.$t('dominantFoot'),
                        options: [
                            {
                                id: constants.dominantSides.left,
                                label: this.$t('left'),
                            },
                            {
                                id: constants.dominantSides.right,
                                label: this.$t('right'),
                            },
                            {
                                id: constants.dominantSides.both,
                                label: this.$t('twoFooted'),
                            },
                        ],
                        disabled: this.readonly,
                    },
                ], this.dependencies.isPlayer),
                {
                    type: 'hline',
                },
                {
                    type: 'headline',
                    text: this.$t('assignSchool'),
                },
                this.createFormRow([
                    {
                        id: 'newPersonSchoolEqId',
                        type: 'vselectSchool',
                        label: this.$tc('school', 1),
                        placeholder: this.$t('selectSchool'),
                        valueProperty: 'eqId',
                        disabled: this.readonly,
                    },
                ]),

                {
                    type: 'hline',
                    hidden: this.hideMapping,
                },
                {
                    id: 'mapping',
                    type: 'headline',
                    text: this.$t('mapping'),
                    hidden: this.hideMapping,
                },
                this.createFormRow(this.generateMappingProviderControls(), null, this.hideMapping),
            ];
        },
        confirmButtonLabel() {
            return null;
        },
        abortButtonLabel() {
            return null;
        },
        errorIdMap() {
            return {
                dateFrom: 'newPersonDateFrom',
                dominantFoot: 'newPersonDominantFoot',
                dominantHand: 'newPersonDominantHand',
                forenames: 'firstName',
                knownAsForenames: 'knownAsFirstname',
                knownAsSurname: 'knownAsLastname',
                passportId: 'passportNo',
                passportScanUrl: 'passport',
                personRole: 'newPersonTeam',
                placeOfBirthLocationEqId: 'placeOfBirth',
                roleType: 'newPersonRoleTypeId',
                schoolEqId: 'newPersonSchoolEqId',
                surname: 'lastName',
            };
        },
    },
};
</script>
