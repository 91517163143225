<template>
    <div>
        <b-card
            v-bind="rowAttributes"
            v-on:click="emitEvent('click', $event)"
        >
            <div class="match-timeline-icon-score">
                <b-card-text v-bind="getColumnProps('icon', 1, null, null, true)" v-if="showCard">
                    <icon v-bind:type="iconType()" v-bind:color="replacementColor()" v-if="!score()" large/>
                    <span v-if="score()">{{ score() }}</span>
                </b-card-text>
            </div>

            <b-card-text v-bind="getColumnProps('half', 12, 6, 2)" v-if="showHalf">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('half')">{{ getHeadline('half', $t('half')) }}</label>
                <span class="text-uppercase" v-if="!isHeadline">{{ timeline.matchStatus }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('time', 12, 6, 1)" v-if="showTime">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('time')">{{ getHeadline('time', $t('time')) }}</label>
                <span class="text-uppercase" v-if="!isHeadline">{{ timeline.time }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('timestamp', 12, 6, 1)" v-if="showTimestamp">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('timestamp')">{{ getHeadline('timestamp', $t('timestamp')) }}</label>
                <span class="text-uppercase" v-if="!isHeadline">{{ $fd(timeline.timestamp, 'timeWithSeconds') }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('action', 12, 6, 2)" v-if="showAction">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('action')">{{ getHeadline('action', $t('action')) }}</label>
                <span class="text-uppercase" v-if="!isHeadline">{{ timeline.actionTypeName }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('team', 12, 6, 2)" v-if="showTeam">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('team')">{{ getHeadline('team', $tc('team', 1)) }}</label>
                <span class="text-uppercase" v-if="!isHeadline">{{ timeline.teamName }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('player', 12, 6, 2)" v-if="showPlayer">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('player')">{{ getHeadline('player', $tc('player', 1)) }}</label>
                <span class="text-uppercase" v-if="!isHeadline">{{ timeline.personName }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('circles', 3, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
                <actionButton
                    variant="icon"
                    v-bind="buttonAttributes"
                    v-b-toggle="'accordion-' + timeline.matchTimelineEqId"
                    v-if="showAccordionToggle"
                >
                    <icon type="down-circle-full" large/>
                </actionButton>
            </b-card-text>
        </b-card>
        <b-collapse :id="'accordion-' + timeline.matchTimelineEqId" accordion="qualifierTypes" role="tabpanel" v-if="!isHeadline">
            <b-table head-variant="light" hover :items="accordionData(0)" :fields="accordionFields(0)"></b-table>
        </b-collapse>
    </div>
</template>

<script>
import base from './base.vue';
import icon from '../icon.vue';

import MatchTimelineModel from '@/models/matchTimeline.js';

/**
 * match timeline component
 */
export default {
    name: 'matchTimelineRow',
    extends: base,
    components: {
        icon,
    },
    props: {
        timeline: {
            type: MatchTimelineModel,
            default(){
                return new MatchTimelineModel();
            }
        },

        showCard: {
            type: Boolean,
            default: true,
        },
        showHalf: {
            type: Boolean,
            default: false,
        },
        showTime: {
            type: Boolean,
            default: false,
        },

        showTimestamp: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: false,
        },
        showTeam: {
            type: Boolean,
            default: false,
        },
        showPlayer: {
            type: Boolean,
            default: false,
        },
        /**
         * show role toggle button
         * clicking it will trigger "roleToggle" event
         */
        showAccordionToggle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            scoreClass: 'match-timeline-score',
        }
    },
    computed: {
        hasVisibleCircles(){
            if (this.timeline.qualifierTypes.length > 0) {
                return (this.showAccordionToggle);
            }
            return false;
        },
    },
    methods: {
        iconType() {
            const cards = {
                'Yellow Card': 'card-yellow',
                '2nd Yellow Card': 'card-yellow',
                'Red Card': 'card-red',
                CCW: 'card',
                Citing: 'citing',
            };

            if (typeof cards[this.timeline.actionTypeName] !== 'undefined') {
                return cards[this.timeline.actionTypeName];
            }

            if (this.timeline.actionTypeName.includes('Replacement On')) {
                return 'arrow-right';
            }

            if (this.timeline.actionTypeName.includes('Replacement Off')) {
                return 'arrow-left';
            }

            return 'card-none';
        },

        replacementColor(){
            if (this.timeline.actionTypeName.includes('Replacement On')) {
                return 'success';
            } else if (this.timeline.actionTypeName.includes('Replacement Off')) {
                return 'danger';
            } else {
                return '';
            }
        },

        score() {
            if (this.timeline.actionTypeGroup === 'Score' && this.timeline.actionTypeValue) {
                return this.timeline.scoring[0] + ':' + this.timeline.scoring[1];
            }
            return '';
        },

        accordionFields(row){
            const fields = [
                ['qualifierTypes'],
            ];
            return fields[row];
        },
        accordionData(row){
            if (!this.timeline) {
                return [];
            }

            const data = [
                {
                    qualifierTypes: this.timeline.qualifierTypes.join(', '),
                },
            ];
            return [data[row]];
        },
    },
};
</script>
