<script>
import personRow from '@/components/rows/person.vue';

import OfficialModel from '@/models/person/official.js';

export default {
    name: 'matchOfficialRow',
    extends: personRow,
    props: {
        person: {
            type: OfficialModel,
            default(){
                return new OfficialModel();
            }
        },
    },
    data(){
        return {
            roleType: 'Official',
        };
    },
}
</script>
