<script>
import formComponent from '@/components/form.vue';
import LocationModel from '@/models/location.js';

/**
 * component
 */
export default {
    name: 'createLocationForm',
    extends: formComponent,
    props: {
        model: {
            type: LocationModel,
        },
    },
    methods: {
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('name'),
                        placeholder: this.$t('enterName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'countryIsoCode',
                        name: 'countryIsoCode',
                        type: 'vselectCountry',
                        label: this.$t('country'),
                        placeholder: this.$t('selectCountry'),
                        disabled: this.readonly,
                        valueProperty: 'isoCode',
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                isoCode: 'countryIsoCode',
            };
        },
    },
};
</script>
