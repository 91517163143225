<script>
import vselectElement from '../vselect.vue';
import MatchModel from '@/models/match.js';
import MatchTeamModel from '@/models/matchTeam.js';

/**
 * vselect element for MatchTeam items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectMatchTeamElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            // add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                //force reloading options when match changes
                this.addOptionReloadListener('dataAllValues.matchId', true);

                if(this.dataAllValues.matchId){
                    return this.$api.call.match.matchGetById(this.dataAllValues.matchId)
                        .then(data => MatchModel.from(data))
                        .then(match => match.teams)
                        .then(teams => teams.filter(team => team.name.toLowerCase().includes(text.toLowerCase())));
                }
                else {
                    return Promise.resolve([]);
                }
            };

            // add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    case 'matchTeamId':
                    default:
                        if(this.dataAllValues.matchId){
                            return this.$api.call.match.matchGetById(this.dataAllValues.matchId)
                                .then(data => MatchModel.from(data))
                                .then(match => match.teams)
                                .then(teams => teams.filter(team => initValues.includes(team.id)));
                        }
                        else {
                            return Promise.all(initValues.map(id => this.$api.call.match.teamGetByMatchTeamId(id)))
                                .then(results => results.map(data => new MatchTeamModel(data)));
                        }
                }
            };

            return config;
        },
    },
};
</script>
