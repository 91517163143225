<template>
    <wrapper
        v-bind:title="title"
        v-bind:waitFor="waitFor"
        v-bind:waitForRetry="waitForRetry"
        class="detailHeader"
        largeTitle
    >
        <template v-slot:buttons>
            <actionButton
                variant="outline-primary"
                class="btn-flat"
                v-on:click="printSegment()"
            >
                {{ $t('print') }}
            </actionButton>
        </template>
        <template v-slot:default>
            <div id="match-report-header" class="col-12 px-3 px-lg-5 py-4 py-lg-6" v-if="data">
                <div class="top col-12">
                    <div class="col-12 px-0 text-center">
                        <ul>
                            <li class="col-12" v-if="data.match.tournaments[0]">
                                <span>
                                    <b-img v-bind:src="data.match.tournaments[0].getImgPath()" v-bind:width="32" v-bind:height="32"></b-img>
                                </span>
                                <span class="m-0">{{ data.match.tournaments[0].name }} - {{ $tc('match', 1) }} {{ data.match.tournaments[0].matchNumber }}</span>
                            </li>
                            <li class="col-12">
                                <span class="m-0">{{ $fd(data.match.kickOff, 'dateTime') }}</span>
                            </li>
                            <li class="col-12">
                                <span>{{ data.match.venueName }}</span>
                                <span>{{ data.match.locationName }}</span>
                            </li>
                            <li class="col-12">
                                <span class="m-0">{{ $t('attendance') }}: {{ data.match.attendance }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 px-0 clearfix">
                        <div class="float-left col-5">
                            <span class="float-right">
                                <b-img v-bind:src="data.match.getImgPathTeamOne()" v-bind:widht="48" v-bind:height="48"></b-img>
                            </span>
                            <div class="float-right">
                                <h2 class="text-uppercase text-right m-0 pr-md-2 pt-1">{{ data.teams[0].team.name }}</h2>
                                <span class="text-right pr-md-2 h6">Head Coach: {{ displayTeamOneHeadCoach }}</span>
                            </div>
                        </div>
                        <div class="float-left col-2 text-center">
                            <span>{{ data.teams[0].team.score }} : {{ data.teams[1].team.score }}</span><br>
                            <span>({{ data.teams[0].team.firstHalfScore }} : {{ data.teams[1].team.firstHalfScore }})</span>
                        </div>
                        <div class="float-left col-5">
                            <span class="float-left">
                                <b-img v-bind:src="data.match.getImgPathTeamTwo()" v-bind:widht="48" v-bind:height="48"></b-img>
                            </span>
                            <div class="float-left">
                                <h2 class="text-uppercase m-0 pl-md-2 pt-1">{{ data.teams[1].team.name }}</h2>
                                <span class="pl-md-2 h6">Head Coach: {{ displayTeamTwoHeadCoach }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4" v-if="matchOfficials && matchOfficials.length">
                        <ul id="match-report-header-management">
                            <li
                                v-for="(official, i) in matchOfficials"
                                v-bind:key="i"
                                class="row py-0"
                            >
                                <span class="col-6 text-right mr-0">{{ $t(official.position) }}</span>
                                <span class="col-6">{{ official.name }} ({{ official.union }})</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </wrapper>
</template>

<script>
import base from '@/views/base.vue';
import wrapper from '@/components/wrapper.vue';
import actionButton from '@/components/actionButton.vue';

export default {
    name: 'detailsHeader',
    extends: base,
    components: {
        wrapper,
        actionButton,
    },
    props: {
        /**
         * wrapper title
         */
        title: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * item name
         */
        data: {
            type: Object,
            default: null,
        },
        teamOneHeadCoach: {
            type: String,
            required: false,
            default: null,
        },
        teamTwoHeadCoach: {
            type: String,
            required: false,
            default: null,
        },
        matchOfficials: {
            type: Array,
            default: null,
        },
        /**
         * item name sub text
         */
        nameSub: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * item name sub text 2
         */
        nameSub2: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * image path (src attribute)
         */
        imagePath: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * image title attribute
         */
        imageTitle: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * image alt attribute
         */
        imageAlt: {
            type: String,
            required: false,
            default: null,
        },
        status: {
            type: String,
            required: false,
            default: null,
        },
        statusType: {
            type: String,
            required: false,
            default: '',
        },
        toBeDeletedOn: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * main detail data
         * array items must be objects with "label" (string) and "content" (string)
         */
        details: {
            type: Array,
            required: false,
            default(){
                return [];
            },
        },
        /**
         * more detail data
         * array items must be objects with "headline" (string) and "items" (array), which must contain objects with "label" (string) and "content" (string)
         */
        moreDetails: {
            type: Array,
            required: false,
            default(){
                return [];
            },
        },
        /**
         * promise to wait for before showing top, default, and footer slot content
         */
        waitFor: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * method to bind to click action on error msg retry button
         * if not defined retry button is not shown
         */
        waitForRetry: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data(){
        return {
            emptyString: '-----',
        };
    },
    computed: {
        itemClasses(){
            return 'mb-5 mx-3 mx-lg-0';
        },
        displayTeamOneHeadCoach(){
            if (this.data.teams[0]?.players) {
                const headCoach = this.data.teams[0]?.players.find(p => p.player?.roleName === 'Management' && p.player?.positionName === 'Head Coach');

                return headCoach?.player?.displayName;
            }

            return this.teamOneHeadCoach ? this.teamOneHeadCoach : this.emptyString;
        },
        displayTeamTwoHeadCoach(){
            if (this.data.teams[1]?.players) {
                const headCoach = this.data.teams[1]?.players.find(p => p.player?.roleName === 'Management' && p.player?.positionName === 'Head Coach');

                return headCoach?.player?.displayName;
            }

            return this.teamTwoHeadCoach ? this.teamTwoHeadCoach : this.emptyString;
        },
    },
}
</script>
