<script>
import itemList from '@/components/itemList.vue';
import tournamentRow from '@/components/rows/tournament.vue';
import TournamentModel from '@/models/tournament.js';
import constants from '@/constants';

/**
 * complex tournament list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'tournamentList',
    extends: itemList,
    components: {
        tournamentRow,
    },
    props: {
        teamEqId: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            //overwrites
            rowElement: tournamentRow,
            rowItemPropName: 'tournament',
            selectItemProp: 'tournament',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
                showPublishStatus: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByTournamentName'),
                    label: false,
                },
                {
                    id: 'sport',
                    type: 'vselectSport',
                    placeholder: this.$t('filterBySport'),
                    label: false,
                    //hidden: !this.combinedRowProps.showSport,
                },
                {
                    id: 'eventType',
                    type: 'vselectEventType',
                    placeholder: this.$t('filterByEventType'),
                    label: false,
                    //hidden: !this.combinedRowProps.showEventType,
                },
                {
                    id: 'year',
                    type: 'number',
                    min: constants.inputMainYear.min,
                    max: constants.inputMainYear.max,
                    default: !this.teamEqId ? constants.inputMainYear.default : null,
                    placeholder: this.$t('mainYear'),
                    label: false,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load tournaments
            return this.$api.call.tournament.tournamentSearch({
                title: filters.name ? filters.name : null,
                year: filters.year || null, // or mainSeason: filters.year || null,
                sport: filters.sport || null,
                tournamentTypeId: filters.eventType || null,
                teamEqIds: this.teamEqId ? [this.teamEqId] : null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.data || result.items || []).map(data => new TournamentModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.tournament.tournamentDeleteById(item.id);
        },
    },
};
</script>
