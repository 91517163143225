<template>
    <modal
        v-bind="modalProps"
        v-bind:scrollable="false"
        v-on="modalEvents"
        v-on:show="onOpen"
        size="xl"
    >
        <template v-slot="{ formId, submit }">
            <teamEventSeriesPointsForm
                v-bind:dataId="formId"
                v-bind:values="values"
                v-bind:errors="errorIds"
                v-on:change="setFormData"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import teamEventSeriesPointsForm from '@/components/forms/teamEventSeriesPoints.vue';

import TeamModel from '@/models/team.js';

export default {
    name: 'editTeamEventSeriesPointsModal',
    extends: base,
    components: {
        teamEventSeriesPointsForm,
    },
    data() {
        return {
            values: {
                tournamentTeamId: this.team.tournamentTeamId,
                points: this.team.points
            },
        };
    },
    props: {
        team: {
            type: TeamModel,
            default(){
                return new TeamModel();
            }
        },
    },
    computed: {
        payload(){
            return this.values;
        },
    },
    methods: {
        setFormData(values) {
            this.values = values;
        },
        onOpen(){
            this.values = {
                tournamentTeamId: this.team.tournamentTeamId,
                points: this.team.points
            }
        },
    },
};
</script>
