<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('myAccount')"
            largeTitle
        >
            <template v-slot:buttons>
                <actionButton
                    variant="danger"
                >
                    {{ $t('requestToDeleteAccount') }}
                </actionButton>
            </template>
            <template v-slot>
                <userEditForm
                    v-bind:model="user"
                    profileView
                />
            </template>
        </wrapper>

        <list
            v-bind:title="$t('myRimsAccess')"
            v-bind:items="userWithMatches ? userWithMatches.accesses : []"
            v-bind:waitFor="apiData.userWithMatches"
            v-bind:waitForRetry="reloadEnabledMatches"
            largeTitle
        >
            <template v-slot="{ item: userAccesses, role }">
                <userAccessRow
                    v-bind:userAccesses="userAccesses"
                    v-bind:role="role"
                    v-bind:dataUserId="dataUserId"
                    v-bind:showButtons="false"
                    v-bind:showMatchRemove="false"
                    v-bind:matches="true"
                    v-bind:isProfileDisplay="true"
                    readonly
                />
            </template>
        </list>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import userEditForm from '@/components/forms/userEdit.vue';
import userAccessRow from '@/components/rows/administration/userAccess.vue';

import MatchModel from '@/models/match.js';

export default {
    name: 'administrationUserDetail',
    extends: base,
    components: {
        userEditForm,
        userAccessRow,
    },
    props: {
        dataUserId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    data(){
        return {
            user: this.$auth.getUser(),
            userWithMatches: null,
            matches: [],
            rimsMatches: [],
            apiData: {
                user: this.loadUser(),
                userWithMatches: this.loadEnabledMatches(),
            },
        };
    },
    methods: {
        loadUser(){
            this.user = this.$auth.getUser();
        },
        reloadUser(){
            this.apiData.user = this.loadUser();
        },
        loadEnabledMatches(){
            if(!this.user){
                return new Promise((resolve, reject) => this.apiData ? this.apiData.user.catch(reject) : '');
            }

            //also load match details for any user access that contains selected matches
            return Promise.all(this.user.accesses.map(access => {
                if(!access.allowAccessToAllMatches && access.authorisedMatchEqIds){
                    return this.$api.call.match.matchSearch({
                        matchEqIds: access.authorisedMatchEqIds,
                        paging: { pageIndex: 0, pageSize: 100 },
                    }).then(matchSearchData => {
                        access.enabledMatches = (matchSearchData.data || []).map(matchData => {
                            return new MatchModel(matchData);
                        });
                        return access;
                    });
                }
                return access;
            }))
                .then(accessWithMatches => {
                    this.user.accesses = accessWithMatches;
                    this.userWithMatches = this.user;
                });
        },
        reloadEnabledMatches(){
            this.apiData.userWithMatches = this.loadEnabledMatches();
        },
        modalName(type, id){
            return 'modal-' + type + '-' + id;
        },
    },
};
</script>
