<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editTournamentType')"
            v-bind:waitFor="apiData.tournamentType"
            v-bind:waitForRetry="reloadTournamentType"
            v-on:submit="updateTournamentType"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentTypeDetail"
                    v-bind:loadingWhile="apiData.tournamentTypeUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentTypeUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <editTournamentTypeForm
                    v-bind:headline="$t('tournamentTypeInformation')"
                    v-bind:model="tournamentType"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import editTournamentTypeForm from '@/components/forms/editTournamentType.vue';
import TournamentTypeModel from '@/models/tournamentType.js';

export default {
    name: 'administrationTournamentTypeEdit',
    extends: base,
    components: {
        validations,
        editTournamentTypeForm,
    },
    props: {
        dataTournamentTypeId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                tournamentType: this.loadTournamentType(),
                tournamentTypeUpdate: null,
            },
            tournamentType: null,
            error: null,
        };
    },
    computed: {
        toTournamentTypeDetail(){
            return { name: 'administrationTournamentTypeDetail', params: { dataTournamentTypeId: this.dataTournamentTypeId } };
        },
    },
    methods: {
        loadTournamentType(){
            return this.$api.call.tournament.tournamentTypeGetById(this.dataTournamentTypeId).then(data => {
                this.tournamentType = TournamentTypeModel.from(data);
            });
        },
        reloadTournamentType(){
            this.apiData.tournamentType = this.loadTournamentType();
        },
        updateTournamentType(){
            this.apiData.tournamentTypeUpdate = this.$api.call.tournament.tournamentTypeUpdateById(this.dataTournamentTypeId, this.tournamentType.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toTournamentTypeDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentType update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
