<script>
import vselectElement from '../vselect.vue';
import OfficialModel from '@/models/person/official.js';

/**
 * vselect element for OfficialModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectMatchOfficialElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            // add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                //force reloading options when match changes
                this.addOptionReloadListener('dataAllValues.matchId', true);

                if(this.dataAllValues.matchId){
                    return this.$api.call.match.matchOfficialsGetByMatchId(this.dataAllValues.matchId)
                        .then(result => (result || []).map(data => new OfficialModel(data)))
                        .then(items => items.filter(item => !config.officialType || item.positionName === config.officialType))
                        .then(items => items.filter(item => !text || item.label().toLowerCase().includes(text.toLowerCase())));
                }
                else {
                    return Promise.resolve([]);
                }
            };

            return config;
        },
    },
};
</script>
