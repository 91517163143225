/**
 * format date/datetime for display
 *
 * @param {Date} date
 * @param {string} [dateFormat]
 *
 * @returns {string}
 */
export default function(date, dateFormat = 'date'){
    if(!(date instanceof Date)){
        if(this.$log){
            this.$log.warn('unable to format date', date);
        }
        return '';
    }

    if(this.$i18n && this.$i18n.locale.substr(0, 2) !== 'en'){
        return this.$d(date, dateFormat);
    }

    let day = date.getDate();
    const weekDay = date.getDay();
    const month = date.getMonth();
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    if (day < 10) {
        day = '0' + day;
    }
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }

    const weekdays = [
        this.$t('sunday'),
        this.$t('monday'),
        this.$t('tuesday'),
        this.$t('wednesday'),
        this.$t('thursday'),
        this.$t('friday'),
        this.$t('saturday')
    ];

    const monthsShort = [
        this.$t('jan'),
        this.$t('feb'),
        this.$t('mar'),
        this.$t('apr'),
        this.$t('may'),
        this.$t('jun'),
        this.$t('jul'),
        this.$t('aug'),
        this.$t('sep'),
        this.$t('oct'),
        this.$t('nov'),
        this.$t('dec')
    ];

    const monthsLong = [
        this.$t('january'),
        this.$t('february'),
        this.$t('march'),
        this.$t('april'),
        this.$t('may'),
        this.$t('june'),
        this.$t('july'),
        this.$t('august'),
        this.$t('september'),
        this.$t('october'),
        this.$t('november'),
        this.$t('december')
    ];

    switch (dateFormat) {
        // DD/MMM/YYYY | HH:MM
        case 'dateTime':
            return day + '/' + monthsShort[month].toUpperCase() + '/' + year + ' | ' + hours + ':' + minutes;
        case 'dateTimeFull':
            return day + '/' + monthsShort[month].toUpperCase() + '/' + year + ' | ' + hours + ':' + minutes + ':' + seconds;
        case 'dateFor2Rows':
            return day + '/' + monthsShort[month].toUpperCase() + '/' + year;
        case 'timeFor2Rows':
            return hours + ':' + minutes;
        // WEEKDAY, DD  MONTH YYYY
        case 'dateLong':
            return weekdays[weekDay] + ', ' + day + ' ' + monthsLong[month] + ' ' + year;
        // DD/MMM/YYYY
        case 'year':
            return '' + year;
        //YYYY
        case 'date':
            return day + '/' + monthsShort[month].toUpperCase() + '/' + year;
        // HH:MM
        case 'time':
            return hours + ':' + minutes;
        // HH:MM:SS
        case 'timeWithSeconds':
            return hours + ':' + minutes + ':' + seconds;
    }
};

export const formatDateSimple = function(date){
    if(!(date instanceof Date)){
        return '';
    }

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
}
