<template>
    <b-col
        v-bind:class="rowClasses"
        v-bind:role="role || (hasVisibleAssigns ? 'button' : null)"
        v-on:click="emitEvent('click', $event)"
    >
        <userRimsDisplayForm
            v-bind:model="userAccesses"
            v-bind:readonly="readonly"
            v-bind:headline="userAccesses.name"
            v-bind:matches="matches"
            v-if="!isProfileDisplay"
            class="mt-5"
        >
            <template v-slot:buttons v-if="showButtons">
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    v-on:click="$emit('edit-access', $event, userAccesses.id)"
                >
                    {{ $t('viewOrEdit', { type: '' }) }}
                </actionButton>
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    v-on:click="$emit('delete-access', $event, userAccesses.id)"
                >
                    {{ $t('delete') }}
                </actionButton>
            </template>
            <template v-slot:buttonsMobile v-if="showButtons">
                <actionButton
                    class="mr-3 mb-3"
                    variant="outline-primary"
                    v-on:click="$emit('edit-access', $event, userAccesses.id)"
                >
                    {{ $t('viewOrEdit', { type: '' }) }}
                </actionButton>
                <actionButton
                    class="mr-3 mb-3"
                    variant="outline-primary"
                    v-on:click="$emit('delete-access', $event, userAccesses.id)"
                >
                    {{ $t('delete') }}
                </actionButton>
            </template>
            <template v-slot:matches>
                <list v-bind:items="userAccesses.enabledMatches">
                    <template v-slot="{ item: match, role }">
                        <matchRow
                            showScore showVenue showKickOffTime showRemove
                            v-bind:match="match"
                            v-bind:role="role"
                        />
                    </template>
                    <template v-slot:empty>
                        <div>Empty</div>
                    </template>
                </list>
            </template>
        </userRimsDisplayForm>

        <userRimsProfileDisplayForm
            v-bind:model="userAccesses"
            v-bind:readonly="readonly"
            v-bind:headline="userAccesses.name"
            v-bind:matches="matches"
            v-if="isProfileDisplay"
            class="mt-5"
        >
            <template v-slot:matches>
                <list v-bind:items="userAccesses.enabledMatches">
                    <template v-slot="{ item: match, role }">
                        <matchRow
                            showScore showVenue showKickOffTime
                            v-bind:match="match"
                            v-bind:role="role"
                            v-bind:showRemove="showMatchRemove"
                        />
                    </template>
                    <template v-slot:empty>
                        <div>Empty</div>
                    </template>
                </list>
            </template>
        </userRimsProfileDisplayForm>
    </b-col>
</template>

<script>
import base from '../base.vue';
import userRimsDisplayForm from '@/components/forms/userRimsDisplay.vue';
import userRimsProfileDisplayForm from '@/components/forms/userRimsProfileDisplay.vue';
import list from '@/components/list.vue';
import matchRow from '@/components/rows/match.vue';

import UserAccessesModel from '@/models/userAccesses.js';

export default {
    name: 'userAccessRow',
    extends: base,
    components: {
        userRimsDisplayForm,
        userRimsProfileDisplayForm,
        list,
        matchRow,
    },
    props: {
        userAccesses: {
            type: UserAccessesModel,
            required: true,
            default(){
                return new UserAccessesModel();
            }
        },
        dataUserId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        showButtons: {
            type: Boolean,
            mandatory: false,
            default: true,
        },
        showMatchRemove: {
            type: Boolean,
            mandatory: false,
            default: true,
        },
        matches: {
            type: Boolean,
            mandatory: false,
            default: false,
        },
        isProfileDisplay: {
            type: Boolean,
            mandatory: false,
            default: false,
        },
    },
    computed: {
        emitEventData(){
            return {
                userAccesses: this.userAccesses || null,
            };
        },
        hasVisibleAssigns(){
            return true;
        },
    },
}
</script>
