<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 1, null, null, true, { hasImg: showImg, noName: !showName, })" class="card-img-wrapper" v-if="showImg">
            <b-card-img
                v-bind="getColumnProps('image')"
                v-bind:src="declarationTeam.getImgPathTournament()"
                v-bind:alt="(declarationTeam.team || {}).name"
                v-bind:width="imgWidth"
                v-bind:height="imgHeight"
            />
        </div>

        <b-card-title v-bind="getColumnProps('teamName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamName')" v-if="isHeadline">{{ getHeadline('teamName', $t('teamName')) }}</label>
            <span class="text-uppercase" v-else>{{ (declarationTeam.team || {}).name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('status', 12, 6, 2)" v-if="showStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('status')">{{ getHeadline('status', $t('status')) }}</label>
            <statusIndicatorIcon v-bind:status="declarationTeam.stepStatus" v-bind:showText="true" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('deadline', 12, 6, 2)" v-if="showDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('deadline')">{{ getHeadline('deadline', $t('deadline')) }}</label>
            <span v-if="!isHeadline">{{ $fd(deadlineDate) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicatorIcon from '../statusIndicatorIcon.vue';

import DeclarationTeamModel from '@/models/declaration/team.js';

/**
 * series review block component
 */
export default {
    name: 'teamReviewRow',
    extends: base,
    components: {
        statusIndicatorIcon
    },
    props: {
        declarationTeam: {
            type: DeclarationTeamModel,
            default(){
                return new DeclarationTeamModel();
            }
        },
        seriesDeclarationId: {
            type: Number,
            default: 0,
        },
        tournamentDeclarationId: {
            type: Number,
            default: 0,
        },
        deadlineDate: {
            type: Date,
        },
        showImg: {
            type: Boolean,
            default: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        showDeadline: {
            type: Boolean,
            default: false,
        },

        showDetail: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },
        isSeries: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        detailLink(){
            if (this.isSeries) {
                return {
                    name: this.detailLinkRoute || 'reviewSeriesDetail',
                    params: Object.assign({
                        seriesDeclarationId: this.seriesDeclarationId,
                        dataTeamDeclarationId: this.declarationTeam.id,
                    }, this.detailLinkParams),
                };
            }

            return {
                name: this.detailLinkRoute || 'reviewTournamentDetail',
                params: Object.assign({
                    tournamentDeclarationId: this.tournamentDeclarationId,
                    dataTeamDeclarationId: this.declarationTeam.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                teamDeclaration: this.teamDeclaration || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail);
        },
    },
};
</script>
