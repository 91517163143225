<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'replacePlayerForm',
    extends: formComponent,
    props: {
        declaredPlayers: {
            type: Array,
            default(){
                return [];
            }
        },
        selectedReplacementPlayerName: {
            type: String,
            default(){
                return '';
            }
        },
    },
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'action',
                        type: 'radioselect',
                        options: {
                            replacement: this.$t('replacement'),
                            hasLeftSquad: this.$t('hasLeftSquad'),
                        },
                        label: this.$t('action'),
                        default: 'replacement',
                        required: true,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'player',
                        type: 'vselect',
                        label: this.$tc('person', 1),
                        placeholder: this.$t('selectPersonFromSquad'),
                        options: this.declaredPlayers,
                        returnFullOptions: true,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'selectReplacement',
                        type: 'button',
                        label: this.$t('replacement'),
                        name: 'selectReplacement',
                        value: null,
                        text: this.getSelectedPlayerName,
                        variant: 'secondary',
                        onClick: this.openReplacementPlayerSelectModal,
                        dependency: 'action === "replacement"',
                        required: true,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'confirmDoctor',
                        type: 'checkbox',
                        label: this.$t('signedConfirmedByDoctor'),
                        disabled: this.readonly,
                        required: true,
                    },
                ], 'action === "replacement"'),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        getSelectedPlayerName(){
            return this.selectedReplacementPlayerName || this.$t('searchPlayers');
        },
    },
    methods: {
        openReplacementPlayerSelectModal() {
            this.$emit('openReplacementPlayerSelectModal');
        }
    },
};
</script>
