import Model from '@/models/model.js';
import MatchModel from '@/models/match.js';
import MatchTimelineTeamModel from '@/models/matchTimelineTeam.js';
import MatchTimelineModel from '@/models/matchTimeline.js';

/**
 * match timeline report model class
 */
export default class MatchTimelineReportModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.match = data.match ? MatchModel.from(data.match) : {};

            this.teams = (data.match ? data.match.teams : []).map(data => MatchTimelineTeamModel.from(data)).sort((a, b) => {
                const isTeam1A = a?.team?.isTeam1;
                const isTeam1B = b?.team?.isTeam1;

                if(isTeam1A && isTeam1B){
                    return 0;
                }

                return isTeam1A ? -1 : 1;
            });

            this.timeline = (data.timeline || []).map(data => MatchTimelineModel.from(data));
        }
    }
}
