<template>
    <div v-bind:class="blockClasses" v-show="message">
        <div>{{ message }}</div>
    </div>
</template>

<script>
import base from './base.vue';

/**
 * message block component
 * @deprecated
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'messageBlock',
    extends: base,
    props: [
        'dataType',
        'dataMessage',
    ],
    data(){
        return {};
    },
    methods: {

    },
    computed: {
        message(){
            return this.dataMessage;
        },
        type(){
            return this.dataType;
        }
    },
};
</script>
