import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import reviewMatchHub from '@/views/review/match/hub.vue';
import reviewMatchIndex from '@/views/review/match/index.vue';
import reviewMatchDetail from '@/views/review/match/detail.vue';

export default {
    path: 'match/',
    component: pageFrame,
    children: [
        {
            name: 'reviewMatchHub',
            path: constants.routePaths.index,
            component: reviewMatchHub,
            meta: {
                permission: permissions.reviews.matches.list,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.tournamentDeclarationId),
            component: pageFrame,
            children: [
                {
                    name: 'reviewMatchIndex',
                    path: constants.routePaths.index,
                    component: reviewMatchIndex,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentDeclarationId]),
                    meta: {
                        permission: permissions.reviews.matches.list,
                    },
                },
                {
                    path: routerTools.propRoute(constants.routeProps.matchDeclarationId),
                    component: pageFrame,
                    children: [
                        {
                            name: 'reviewMatchDetail',
                            path: constants.routePaths.index,
                            component: reviewMatchDetail,
                            props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentDeclarationId, constants.routeProps.matchDeclarationId]),
                            meta: {
                                permission: permissions.reviews.matches.detail,
                            },
                        },
                        routerTools.createFallbackRedirect(),
                    ],
                },
            ],
        },
    ],
};
