<template>
    <div>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-text v-bind="getColumnProps('icon', 1, null, null, true)" v-if="showCard">
            <icon v-bind:type="disciplinaryIcon()" large/>
        </b-card-text>

        <b-card-title v-bind="getColumnProps('matchTeamName', 2)" v-bind:title-tag="titleTag" v-if="showTeamName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('matchTeamName')" v-if="isHeadline">{{ getHeadline('matchTeamName', $tc('team', 1)) }}</label>
            <span v-else>{{ disciplinary.matchTeamName }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('playerName', 12, 6, 3)" class="align-self-center" v-if="showPlayerName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('playerName')">{{ getHeadline('playerName', $t('playerName')) }}</label>
            <span v-if="!isHeadline">{{ disciplinary.playerName }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('law', 12, 6, 2)" v-if="showLaw">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('law')">{{ getHeadline('law', $t('law')) }}</label>
            <span v-if="!isHeadline">{{ disciplinary.law }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 3, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-b-modal="modalName('editDisciplinary', disciplinary.id)"
                v-on:click="emitEvent('editDisciplinary', $event)"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-on:click="emitEvent('remove', $event)"
                v-b-modal="modalName('confirmRemoveDisciplinary', disciplinary.id)"
                v-if="showRemove"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-b-toggle="'accordion-' + disciplinary.id"
                v-if="showAccordionToggle"
            >
                <icon type="down-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmRemoveDisciplinary', disciplinary.id)"
            v-bind:text="$t('areYouSureToDelete', { type: disciplinary.id })"
            v-bind:data="disciplinary"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
            v-if="showRemove"
        />

        <addEditDisciplinaryModal
            v-bind:id="modalName('editDisciplinary', disciplinary.id)"
            v-bind:title="this.$t('editDisciplinary')"
            v-bind:disciplinary="disciplinary.clone()"
            v-bind:matchReadonly="matchReadonly"
            v-bind:teamReadonly="teamReadonly"
            v-bind:citingCommissionerReadonly="citingCommissionerReadonly"
            v-bind:confirmHandler="updateHandler"
            v-on:confirmed="emitEvent('updated', $event)"
            v-if="showEdit"
        />
    </b-card>
    <b-collapse :id="'accordion-' + disciplinary.id" accordion="disciplinaryData" role="tabpanel" v-if="!isHeadline">
        <b-table head-variant="light" hover :items="accordionData(0)" :fields="accordionFields(0)"></b-table>
        <b-table head-variant="light" hover :items="accordionData(1)" :fields="accordionFields(1)"></b-table>
        <b-table head-variant="light" hover :items="accordionData(2)" :fields="accordionFields(2)"></b-table>
    </b-collapse>
    </div>
</template>

<script>
import base from './base.vue';
import icon from '../icon.vue';
import addEditDisciplinaryModal from '@/components/modals/addEditDisciplinary.vue';

import DisciplinaryModel from '@/models/disciplinary.js';

/**
 * disciplinary row component
 */
export default {
    name: 'disciplinaryRow',
    extends: base,
    components: {
        icon,
        addEditDisciplinaryModal,
    },
    props: {
        /**
         * timeline disciplinary object
         */
        disciplinary: {
            type: DisciplinaryModel,
            default(){
                return new DisciplinaryModel();
            },
        },
        officials: {
            type: Array,
            default(){
                return [];
            },
        },

        /**
         * show card
         */
        showCard: {
            type: Boolean,
            default: true,
        },
        /**
         * show team name
         */
        showTeamName: {
            type: Boolean,
            default: true,
        },
        /**
         * show person name
         */
        showPlayerName: {
            type: Boolean,
            default: true,
        },
        /**
         * show person name
         */
        showLaw: {
            type: Boolean,
            default: true,
        },

        showDetail: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showEdit: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with remove icon
         * clicking it will trigger "remove" event
         */
        showRemove: {
            type: Boolean,
            default: false,
        },
        /**
         * show role toggle button
         * clicking it will trigger "roleToggle" event
         */
        showAccordionToggle: {
            type: Boolean,
            default: false,
        },
        matchReadonly: {
            type: Boolean,
            default: false,
        },
        teamReadonly: {
            type: Boolean,
            default: false,
        },
        citingCommissionerReadonly: {
            type: Boolean,
            default: false,
        },

        /**
         * image width
         */
        imgWidth: {
            type: Number,
            default: 16,
        },
        /**
         * image height
         */
        imgHeight: {
            type: Number,
            default: 32,
        },
    },
    methods: {
        accordionFields(row){
            const fields = [
                ['matchStatus', 'time', 'referee'],
                ['hearingOutcome', 'internalComments', 'offence'],
                ['judicialOfficer', 'citingCommissioner', 'appealsOfficer']
            ];
            return fields[row];
        },
        accordionData(row){
            if (!this.disciplinary) {
                return [];
            }

            const data = [
                {
                    matchStatus: this.disciplinary.matchStatus,
                    time: this.disciplinary.timeInHalf,
                    referee: this.disciplinary.referee,
                },
                {
                    hearingOutcome: this.disciplinary.citing,
                    internalComments: this.disciplinary.internalComments,
                    offence: this.disciplinary.offence,
                },
                {
                    judicialOfficer: this.disciplinary.judicialOfficer,
                    citingCommissioner: this.disciplinary.citingCommissioner,
                    appealsOfficer: this.disciplinary.appealsOfficer,
                },
            ];
            return [data[row]];
        },
        disciplinaryIcon(){
            const icons = {
                'Yellow Card': 'card-yellow',
                '2nd Yellow Card': 'card-yellow',
                'Red Card': 'card-red',
                CCW: 'card',
                Citing: 'citing',
            };

            return icons[this.disciplinary.actionTypeName] || 'card-none';
        }
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.disciplinary.matchId ? this.disciplinary.matchId : 0,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                disciplinary: this.disciplinary || null,
            };
        },
        customElementClasses(){
            return {
                disciplinaryRow: true,
            };
        },
        hasVisibleCircles(){
            return (this.showEdit || this.showRemove || this.showDetail || this.showAccordionToggle);
        },
    },
};
</script>
