<script>
import vselectElement from '../vselect.vue';
import SeriesModel from '@/models/series.js';

/**
 * vselect element for SeriesModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectSeriesElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //filtering done in frontend due to api not providing the functionality
                return this.$api.call.tournament.seriesSearch({
                    names: text || null,
                    paging: this.usePagination(offset),
                })
                    .then(result => (result.series || result.items || []).map(data => new SeriesModel(data)))
                    .then(items => items.filter(series =>
                        series.name.toLowerCase().includes(text.toLowerCase())
                    ));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'eqId':
                        return Promise.all(initValues.map(eqId => this.$api.call.tournament.seriesGetByEqId(eqId)))
                            .then(results => results.map(data => new SeriesModel(data)));

                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.tournament.seriesGetById(id)))
                            .then(results => results.map(data => new SeriesModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
