import Model from '@/models/model.js';

/**
 * action type model class
 */
export default class ActionTypeModel extends Model {
    /**
     * id
     *
     * @type {int}
     */
    id = 0;

    /**
     * group
     *
     * @type {string}
     */
    group = '';

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * name short
     *
     * @type {string}
     */
    nameShort = '';

    /**
     * order
     *
     * @type {int}
     */
    order = 0;

    /**
     * sup group
     *
     * @type {string}
     */
    subGroup = '';

    /**
     * value
     *
     * @type {int}
     */
    value = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.eqId = data.eqId || '';
            this.group = data.group || '';
            this.name = data.name || '';
            this.nameShort = data.nameShort || '';
            this.order = data.order || 0;
            this.subGroup = data.subGroup || '';
            this.value = data.value || 0;
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
