<script>
import vselectElement from '../vselect.vue';
import UnionModel from '@/models/union.js';

/**
 * vselect element for UnionModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectUnionElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                return this.$api.call.union.unionSearch({
                    unionName: text || null,
                    paging: this.usePagination(offset),
                }).then(result => (result.data || []).map(data => new UnionModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'eqId':
                        return Promise.all(initValues.map(eqId => this.$api.call.union.unionGetByEqId(eqId)))
                            .then(results => results.map(data => new UnionModel(data)));
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.union.unionGetById(id)))
                            .then(results => results.map(data => new UnionModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
