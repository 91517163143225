<script>
import pickElement from '../pick.vue';
import venuePickModal from '@/components/modals/pick/venue.vue';

/**
 * pick venue input element component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'pickVenueElement',
    extends: pickElement,
    components: {
        venuePickModal,
    },
    data(){
        return {
            modalComponent: venuePickModal,
        };
    },
};
</script>
