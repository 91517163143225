import TeamModel from '@/models/team.js';
import PersonModel from '@/models/person.js';

/**
 * team model class
 */
export default class SeriesTeamModel extends TeamModel {
    /**
     * position
     *
     * @type {string}
     */
    position = '';

    /**
     * points
     *
     * @type {int}
     */
    points = 0;

    /**
     * sup group
     *
     * @type {bool}
     */
    isTourMember = false;

    /**
     * players
     *
     * @type {array}
     */
    players = [];

    /**
     * management
     *
     * @type {array}
     */
    management = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.eqId = data.eqId || '';
            this.id = data.id || data.seriesTeamId || 0;
            this.isTourMember = data.isTourMember || false;
            this.name = data.name || data.teamName || '';
            this.points = data.points || 0;
            this.position = data.position || '';
            this.seriesId = data.seriesId || 0;
            this.shortName = data.shortName || '';
            this.teamId = data.teamId || 0;
            this.teamName = data.teamName || '';
            this.teamSourceEqId = data.teamSourceEqId || '';

            (data.players || []).forEach(value =>
                this.players.push(new PersonModel(value))
            );

            (data.management || []).forEach(value =>
                this.management.push(new PersonModel(value))
            );

            if(!this.team){
                this.team = TeamModel.from(data);

                if(data.eqId && data.teamEqId){
                    this.team.eqId = data.teamEqId;
                }
            }

            this.logoStorage = data.logoUrl || '';
            this.logo = TeamModel._api.call.union.resolveStoragePath(this.logoStorage) || data.logo || null;
        }
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPath(){
        return this.logo || require('@/assets/img/union.svg');
    }

    toJSON() {
        return {
            isTourMember: this.isTourMember,
            teamName: (this.team ? this.team.name : this.name) || null,
            teamAliasEqId: (this.team ? this.team.teamAliasEqId : this.teamSourceEqId) || null,
        };
    }
}
