<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('name', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('seriesName')" v-if="isHeadline">{{ getHeadline('seriesName', $t('name')) }}</label>
            <span class="text-uppercase" v-else>{{ teamAlias.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('nameShort', 12, 6, 2)" v-if="showNameShort">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('nameShort')">{{ getHeadline('nameShort', $t('nameShort')) }}</label>
            <span v-if="!isHeadline" class="text-uppercase">{{ teamAlias.nameShort }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('teamCode', 12, 6, 2)" v-if="showTeamCode">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamCode')">{{ getHeadline('teamCode', $t('code')) }}</label>
            <span v-if="!isHeadline" class="text-uppercase">{{ teamAlias.teamCode }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('dateFrom', 12, 6, 2)" v-if="showDateFrom">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateFrom')">{{ getHeadline('dateFrom', $t('validFrom')) }}</label>
            <span v-if="!isHeadline" class="text-uppercase">{{ teamAlias.dateFrom ? $fd(teamAlias.dateFrom, 'date') : '-' }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('dateUntil', 12, 6, 2)" v-if="showDateUntil">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateUntil')">{{ getHeadline('dateUntil', $t('validUntil')) }}</label>
            <span v-if="!isHeadline" class="text-uppercase">{{ teamAlias.dateUntil ? $fd(teamAlias.dateUntil, 'date') : '-' }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-b-modal="modalName('editTeamAlias', teamAlias.id)"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <editCreateTeamAliasModal
            v-bind:id="modalName('editTeamAlias', teamAlias.id)"
            v-bind:title="$t('teamAlias')"
            v-bind:teamAlias="teamAlias.clone()"
            v-bind:create="false"
            v-bind:confirmHandler="updateHandler"
            v-on:confirmed="emitEvent('updated', $event)"
        />
    </b-card>
</template>

<script>
import base from './base.vue';
import editCreateTeamAliasModal from '@/components/modals/editCreateTeamAlias.vue';

import TeamAliasModel from '@/models/teamAlias.js';

/**
 * team block component
 */
export default {
    name: 'teamAliasRow',
    extends: base,
    components: {
        editCreateTeamAliasModal,
    },
    props: {
        teamAlias: {
            type: TeamAliasModel,
            default(){
                return new TeamAliasModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },

        showNameShort: {
            type: Boolean,
            default: false,
        },
        showTeamCode: {
            type: Boolean,
            default: false,
        },
        showDateFrom: {
            type: Boolean,
            default: false,
        },
        showDateUntil: {
            type: Boolean,
            default: false,
        },

        showEdit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        emitEventData(){
            return {
                teamAlias: this.teamAlias || null,
            };
        },
        hasVisibleCircles(){
            return (this.showEdit);
        },
    },
};
</script>
