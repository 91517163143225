import Model from '@/models/model.js';

export default class WeightingModel extends Model {
    startDate = null;

    endDate = null;

    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.tournamentId = data.tournamentId || 0;
            this.tournamentName = data.tournamentName || '';
            this.tournamentGroup = data.tournamentGroup || '';
            this.startDate = this.toDate(data.startDate) || null;
            this.endDate = this.toDate(data.endDate) || null;
            this.weighting = data.weighting || 0;
        }
    }
}
