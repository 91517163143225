import Model from '@/models/model.js';
import ApiResourceModel from '@/models/apiResource.js';

/**
 * Api service model class
 */
export default class ApiServiceModel extends Model {
    /**
     * id
     *
     * @type {string}
     */
    id = '';

    /**
     * service name
     *
     * @type {string}
     */
    name = '';

    /**
     * service
     *
     * @type {string}
     */
    service = '';

    /**
     * api resources
     *
     * @type {ApiResourceModel[]}
     */
    resources = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     * @param {boolean} [isAuthorised = false]
     */
    constructor(data = {}, isAuthorised = false){
        super(data);

        if(data){
            this.id = data.serviceUserRoleId || data.id || null;
            this.service = data.service || '';
            this.name = data.name || data.serviceName || '';

            this.resources = (data.resources || data.apiResources || [])
                .map(data => ApiResourceModel.from(data, false, isAuthorised).setService(this));
        }
    }

    setId(id){
        this.id = id;

        return this;
    }

    setResources(resources = []){
        this.resources.splice(0).push.apply(this.resources, resources.map(data => ApiResourceModel.from(data).setService(this)));
    }

    getResources(){
        return this.resources;
    }

    getResourceMethods(filter = null){
        return this.resources.map(resource => resource.getMethods(filter)).reduce((arr, methods) => arr.concat(methods), []);
    }

    addResourceMethod(apiResourceMethod){
        if(!apiResourceMethod || !apiResourceMethod.resource){
            return false;
        }

        //find resource
        let resource = this.resources.filter(resource => resource.id === apiResourceMethod.resource.id).shift();

        //add if not found
        if(!resource){
            resource = apiResourceMethod.resource.clone();
            resource.setService(this);
            resource.setMethods([]);
            this.resources.push(resource);
        }

        //tell resource to add
        return resource.addMethod(apiResourceMethod);
    }

    removeResourceMethod(apiResourceMethod){
        if(!apiResourceMethod || !apiResourceMethod.resource){
            return false;
        }

        //find resource
        const resource = this.resources.filter(resource => resource.id === apiResourceMethod.resource.id).shift();

        //if not found, all is well
        if(!resource){
            return true;
        }

        //tell resource to remove
        if(!resource.removeMethod(apiResourceMethod)){
            return false;
        }

        //if removed and resource now empty, remove it
        if(resource.getMethods().length === 0){
            this.removeResource(apiResourceMethod.resource);
        }

        return true;
    }

    removeResource(resourceItem){
        //find and remove item
        const index = this.resources.map(resource => resource.id).indexOf(resourceItem.id);
        if(index !== -1){
            this.resources.splice(index, 1);
        }
    }

    /**
     * get api compatible object json
     *
     * @returns {object}
     */
    toJSON(){
        return {
            serviceUserRoleId: this.id,
            service: this.service,
            serviceName: this.name,
            resources: this.resources.map(entry => entry.toJSON()).filter(data => data && (data.apiResourceMethods || []).length),
        };
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.id;
    }
}
