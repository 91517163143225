<template>
    <div id="app">
        <header>
            <b-navbar toggleable="lg" type="dark" variant="secondary" class="p-0" id="nav-bar">
                <b-navbar-brand class="col-2 bg-white text-primary p-4 px-5 mw-100" id="logo">
                    <b-link v-bind:to="{name: 'home'}">
                        <img v-bind:height="26" src="@/assets/img/logo.svg" alt="logo"/>
                    </b-link>
                </b-navbar-brand>

                <div id="project-title" class="text-uppercase d-none d-lg-block">RUGBY INFORMATION MANAGEMENT SYSTEM 3.0</div>

                <!-- Right aligned nav items -->
                <div class="navbar-wrapper h-100" v-if="$store.state.user">
                    <b-navbar-nav class="ml-auto pr-lg-6 h-100">
                        <b-nav-form class="d-none d-lg-flex">
                            <div class="bg-primary nav-search py-1 px-2">
                                <b-form-input
                                    size="m"
                                    class="mr-sm-2 bg-primary border-0"
                                    placeholder="Search by keyword"
                                    v-model="searchText"
                                    v-bind:debounce="searchDebounce"
                                />
                                <b-button size="sm" class="my-2 my-sm-0 bg-primary border-0 shadow-none">
                                    <loading v-bind:waitFor="apiData.search" v-bind:throbberProps="{small: true, variant: 'light'}" waitSilentError>
                                        <icon type="search" color="white" class="m-0" v-if="!searchText"/>
                                        <icon type="cancel" color="white" class="m-0" v-on:click.native="emptySearch" v-else/>
                                    </loading>
                                </b-button>
                            </div>
                        </b-nav-form>

                        <b-nav-item right class="d-lg-none nav-button h-100" @click="collapse('searchMobile')">
                            <b-button class="p-0 h-100">
                                <icon type="search" class="m-0"/>
                            </b-button>
                        </b-nav-item>

                        <b-nav-item right class="nav-button h-100" @click="collapse('notification')">
                            <b-button class="p-0 h-100">
                                <icon type="bell" color="white" class="m-0" v-if="!notifications.length"/>
                                <icon type="bell" color="white" class="m-0 notification-new" v-if="notifications.length"/>
                            </b-button>
                        </b-nav-item>

                        <b-nav-item-dropdown class="d-none d-lg-block h-100" right>
                            <template slot="button-content" class="h-100">
                                <span class="bg-secondary h-100 align-middle">
                                    <img v-bind:width="32" class="align-middle mr-2 rounded-circle" src="@/assets/img/player.svg"/>
                                    <icon type="dropdown" color="white" class="m-0"/>
                                </span>
                            </template>
                            <b-dropdown-item
                                v-bind:to="{ name: 'commonUserProfile' }"
                                class="bg-primary text-uppercase"
                            >
                                Profile
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-bind:to="{ name: 'logout' }"
                                class="bg-primary text-uppercase"
                            >
                                {{ $t('logout') }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <b-nav-item right class="nav-button h-100 d-lg-none" @click="collapse('navbar')">
                            <b-button class="p-0 h-100">
                                <icon type="menu" color="white"/>
                            </b-button>
                        </b-nav-item>
                    </b-navbar-nav>
                </div>
            </b-navbar>
        </header>

        <div v-if="$store.state.user">
            <b-collapse
                id="notification-list"
                v-model="collapseState.notification"
            >
                <list
                    v-bind:items="notifications"
                    v-bind:waitFor="apiData.notifications"
                >
                    <template v-slot="{ item: notification, role }">
                        <notificationRow
                            v-bind:notification="notification"
                            v-bind:role="role"
                            v-on:click="readNotification"
                        />
                    </template>
                    <template v-slot:empty>
                        <div class="bg-primary px-4 py-3">
                            {{ $t('noUnreadNotifications') }}
                        </div>
                    </template>
                </list>
                <b-button class="d-block bg-primary px-4 py-3 text-center border-0 text-uppercase" v-bind:to="homeRoute" v-on:click="collapse('notification', false)">
                    {{ $t('aeeAllIncomingActivity') }}
                </b-button>
            </b-collapse>

            <b-collapse
                id="search-mobile"
                class="bg-primary p-4 d-lg-none"
                v-model="collapseState.searchMobile"
            >
                <div class="nav-search py-1 px-2">
                    <b-form-input
                        size="m"
                        class="mr-sm-2 pr-6 bg-primary border-0"
                        v-bind:debounce="searchDebounce"
                        placeholder="Search by keyword"
                        v-model="searchText"
                    />
                    <b-button size="sm" class="bg-primary border-0 pe-none" type="submit">
                        <loading v-bind:waitFor="apiData.search" v-bind:throbberProps="{small: true, variant: 'light'}" waitSilentError>
                            <icon type="search" color="white" class="m-0" v-if="!searchText"/>
                            <icon type="cancel" color="white" class="m-0" v-on:click.native="emptySearch" v-else/>
                        </loading>
                    </b-button>
                </div>
            </b-collapse>

            <b-collapse
                id="search-wrapper"
                v-model="collapseState.search"
            >
                <div class="bg-primary px-4 py-3">
                    <div v-if="searchResultCount === 0 && !searchError">
                        {{ $t('noFilterResult') }}
                    </div>
                    <div v-if="searchError">
                        {{ $t('requestError') }}
                    </div>
                    <b-list-group>
                        <b-list-group-item v-for="person in searchResults.persons" v-bind:key="person.id">
                            <router-link
                                v-on:click.native="emptySearch"
                                v-bind:to="{ name: 'commonPersonDetail', params: { dataPersonId: person.id }}"
                                v-bind:class="searchResultClass"
                            >
                                {{ person.label() }} - Person
                            </router-link>
                        </b-list-group-item>
                    </b-list-group>
                    <b-list-group>
                        <b-list-group-item v-for="team in searchResults.teams" v-bind:key="team.id">
                            <router-link
                                v-on:click.native="emptySearch"
                                v-bind:to="{ name: 'commonTeamDetail', params: { dataTeamId: team.id }}"
                                v-bind:class="searchResultClass"
                            >
                                {{ team.label() }} - Team
                            </router-link>
                        </b-list-group-item>
                    </b-list-group>
                    <b-list-group>
                        <b-list-group-item v-for="tournament in searchResults.tournaments" v-bind:key="tournament.id">
                            <router-link
                                v-on:click.native="emptySearch"
                                v-bind:to="{ name: 'commonTournamentDetail', params: { dataTournamentId: tournament.id }}"
                                v-bind:class="searchResultClass"
                            >
                                {{ tournament.label() }} - Tournament
                            </router-link>
                        </b-list-group-item>
                    </b-list-group>
                    <b-list-group>
                        <b-list-group-item v-for="series in searchResults.series" v-bind:key="series.id">
                            <router-link
                                v-on:click.native="emptySearch"
                                v-bind:to="{ name: 'commonSeriesDetail', params: { dataSeriesId: series.id }}"
                                v-bind:class="searchResultClass"
                            >
                                {{ series.label() }} - Series
                            </router-link>
                        </b-list-group-item>
                    </b-list-group>
                    <b-list-group>
                        <b-list-group-item v-for="union in searchResults.unions" v-bind:key="union.id">
                            <router-link
                                v-on:click.native="emptySearch"
                                v-bind:to="{ name: 'commonUnionDetail', params: { dataUnionId: union.id }}"
                                v-bind:class="searchResultClass"
                            >
                                {{ union.label() }} - Union
                            </router-link>
                        </b-list-group-item>
                    </b-list-group>
                    <b-list-group>
                        <b-list-group-item v-for="venue in searchResults.venues" v-bind:key="venue.id">
                            <router-link
                                v-on:click.native="emptySearch"
                                v-bind:to="{ name: 'commonVenueDetail', params: { dataVenueId: venue.id }}"
                                v-bind:class="searchResultClass"
                            >
                                {{ venue.label() }} - Venue
                            </router-link>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-collapse>
        </div>

        <b-row no-gutters class="wrapper">
            <b-col cols="12" lg="10" order="1" order-lg="2" tag="main" id="main">
                <b-container fluid>
                    <router-view v-on:clearNotifications="clearNotificationsHandler"/>
                </b-container>
            </b-col>

            <b-col cols="12" lg="2" order="2" order-lg="1" tag="aside" id="nav" class="bg-primary">
                <b-collapse
                    id="nav-collapse"
                    class="px-0 py-4 navbar-expand-lg"
                    v-model="collapseState.navbar"
                    is-nav
                >
                    <appNavigation/>
                </b-collapse>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import constants from './constants.js';

import appNavigation from './components/navigation.vue';
import icon from './components/icon.vue';
import loading from './components/loading.vue';
import list from '@/components/list.vue';
import notificationRow from '@/components/rows/notification.vue';

import PersonModel from '@/models/person.js';
import NotificationModel from '@/models/notification.js';
import TeamModel from '@/models/team.js';
import TournamentModel from '@/models/tournament.js';
import SeriesModel from '@/models/series.js';
import UnionModel from '@/models/union.js';
import VenueModel from '@/models/venue.js';

export default {
    name: 'app',
    components: {
        appNavigation,
        icon,
        loading,
        list,
        notificationRow,
    },
    data(){
        return {
            searchText: '',
            collapseState: {
                search: false,
                searchMobile: false,
                notification: false,
                navbar: false
            },
            apiData: {
                search: null,
                notifications: this.loadNotifications(),
            },
            searchResults: {
                persons: [],
                teams: [],
                tournaments: [],
                series: [],
                unions: [],
                venues: [],
            },
            searchError: false,
            searchDebounce: 2000,
            searchResultClass: 'd-block text-white py-3',
            reloadNotificationsInterval: null,
            notifications: [],
        };
    },
    computed: {
        searchResultCount() {
            return Object.entries(this.searchResults).map(([key, data]) => data.length).reduce((previous, current) => previous + current, 0);
        },
        homeRoute(){
            return { name: constants.routeNames.home };
        },
    },
    methods: {
        collapse(currentCollapse, state = null) {
            for(const key in this.collapseState) {
                if(currentCollapse === key) {
                    this.collapseState[key] = (state !== null ? Boolean(state) : !this.collapseState[key])
                } else {
                    this.collapseState[key] = false
                }
            }
        },
        search(searchText) {
            this.collapseState.search = false;
            this.searchError = false;

            if (!searchText && this.collapseState.searchMobile === true) {
                this.collapseState.searchMobile = false;
            }

            if (searchText) {
                this.apiData.search = Promise.all([
                    this.searchPerson(searchText),
                    this.searchTeam(searchText),
                    this.searchTournament(searchText),
                    this.searchSeries(searchText),
                    this.searchUnion(searchText),
                    this.searchVenue(searchText),
                ]).then(results => {
                    this.$set(this.searchResults, 'persons', results.shift());
                    this.$set(this.searchResults, 'teams', results.shift());
                    this.$set(this.searchResults, 'tournaments', results.shift());
                    this.$set(this.searchResults, 'series', results.shift());
                    this.$set(this.searchResults, 'unions', results.shift());
                    this.$set(this.searchResults, 'venues', results.shift());

                    this.collapseState.search = true;
                    this.collapseState.searchMobile = true;
                }, error => {
                    if (this.$log) {
                        this.$log.warn(error);
                    }
                    this.searchError = true;

                    this.collapseState.search = true;
                    this.collapseState.searchMobile = true;
                });
            }
        },
        emptySearch(){
            this.searchText = '';
        },
        searchPerson(searchString){
            return this.$api.call.person.personSearch({
                surname: searchString,
                forenames: searchString,
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(result => (result.persons || []).map(data => PersonModel.from(data)));
        },
        searchTeam(searchString){
            return this.$api.call.union.teamSearch({
                teamName: searchString,
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(result => (result.data || []).map(data => TeamModel.from(data)));
        },
        searchTournament(searchString){
            return this.$api.call.tournament.tournamentSearch({
                title: searchString,
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(result => (result.data || []).map(data => TournamentModel.from(data)));
        },
        searchSeries(searchString){
            return this.$api.call.tournament.seriesSearch({
                name: searchString,
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(result => (result.series || []).map(data => SeriesModel.from(data)));
        },
        searchUnion(searchString){
            return this.$api.call.union.unionSearch({
                unionName: searchString,
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(result => (result.data || []).map(data => UnionModel.from(data)));
        },
        searchVenue(searchString){
            return this.$api.call.location.venueSearch({
                venueName: searchString,
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(result => (result.data || []).map(data => VenueModel.from(data)));
        },
        loadNotifications(){
            return this.$api.call.notification.getUnreadNotifications().then(data => {
                this.notifications = (data || []).map(data => new NotificationModel(data)).sort((b, a) => a.timestamp - b.timestamp).slice(0, 3);
            });
        },
        readNotification(notificationData){
            if (!notificationData.notification.isRead){
                return this.$api.call.notification.updateNotification(notificationData.notification.id, {
                    isRead: true
                }).then(() => {
                    this.loadNotifications();
                    this.collapse('notification', false);

                    this.$router.push(notificationData.notification.toRouterParamsJSON());
                });
            }
            else
            {
                this.$router.push(notificationData.notification.toRouterParamsJSON());
            }
        },
        clearNotificationsHandler(){
            this.notifications = [];
        },
    },
    watch: {
        searchText(searchText){
            this.search(searchText);
        }
    },
    created(){
        this.reloadNotificationsInterval = setInterval(() => { this.loadNotifications() }, 60000);
    },
    destroyed(){
        clearInterval(this.reloadNotificationsInterval);
    }
}
</script>

<style lang="scss">
    @import 'scss/init.scss';

    .collapsing {
        transition: none !important;
    }
</style>
