<template>
    <div class="pageContent">
        <venueList
            v-bind:title="$tc('venue', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.venues.delete"
            v-bind:filterValues="filterValues"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'commonVenueCreate' }"
                >
                    {{ $t('createVenue') }}
                </actionButton>
            </template>
        </venueList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import venueList from '@/components/lists/venue.vue';

export default {
    name: 'commonVenueIndex',
    extends: base,
    components: {
        venueList,
    },
    data(){
        return {
            rowProps: {
                showCountry: true,
                showPlace: true,
                showRemove: true,
            },
        };
    },
    computed: {
        filterValues(){
            return {
                location: this.$route.query.location || null,
            }
        }
    },
};
</script>
