import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class LocationService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/location-service/api/';

    /**
     * base path parts
     *
     * @type {object}
     */
    _paths = {
        venueDetail: 'venue-detail',
    };

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // country

    /**
     *
     */
    countryCreate(data){
        return this.post('country', data);
    }

    /**
     *
     */
    countryGetById(id){
        return this.get(['country', id]);
    }

    /**
     *
     */
    countryUpdateById(id, data){
        return this.put(['country', id], data);
    }

    /**
     *
     */
    countryDeleteById(id){
        return this.delete(['country', id]);
    }

    /**
     *
     */
    countrySearch(query){
        return this.post(['country', 'search'], query);
    }

    // location

    /**
     *
     */
    locationCreate(data){
        return this.post('location', data);
    }

    /**
     *
     */
    locationGetAllByCountryIsoCode(isoCode){
        return this.get(['place', isoCode]);
    }

    /**
     *
     */
    locationUpdateById(id, data){
        return this.put(['location', id], data);
    }

    /**
     *
     */
    locationDeleteById(id){
        return this.delete(['location', id]);
    }

    /**
     *
     */
    locationGetById(id){
        return this.get(['location', id]);
    }

    /**
     *
     */
    locationSearch(query){
        return this.post(['location', 'search'], query);
    }

    // venue

    /**
     *
     */
    venueCreate(data){
        return this.post('venue', data);
    }

    /**
     *
     */
    venueGetById(id){
        return this.get(['venue', id]);
    }

    /**
     *
     */
    venueUpdateById(id, data){
        return this.put(['venue', id], data);
    }

    /**
     *
     */
    venueDeleteById(id){
        return this.delete(['venue', id]);
    }

    /**
     *
     */
    venueAliasesGetByVenueId(id){
        return this.get(['venue', id, 'aliases']);
    }

    /**
     *
     */
    venueAliasCreate(venueId, data){
        return this.post(['venue', venueId, 'alias'], data);
    }

    /**
     *
     */
    venueAliasNameUpdate(venueId, data){
        return this.put(['venue', venueId, 'alias'], data);
    }

    /**
     *
     */
    changeDateVenueAliasByVenueId(id, data){
        return this.put(['venue', id, 'aliases'], data);
    }

    /**
     *
     */
    venueSearch(query){
        return this.post(['venue', 'search'], query);
    }

    // venue detail

    /**
     *
     */
    venueDetailCreate(data){
        return this.post(this._paths.venueDetail, data);
    }

    /**
     *
     */
    venueDetailGetByVenueNames(venueNames){
        return this.get([this._paths.venueDetail, venueNames]);
    }

    /**
     *
     */
    venueDetailUpdateByVenueName(venueName, data){
        return this.put([this._paths.venueDetail, venueName], data);
    }

    /**
     *
     */
    venueDetailDeleteByVenueName(venueName){
        return this.delete([this._paths.venueDetail, venueName]);
    }

    // school

    /**
     *
     */
    schoolCreate(data){
        return this.post('school', data);
    }

    /**
     *
     */
    schoolGetById(id){
        return this.get(['school', id]);
    }

    /**
     * (params TBC)
     */
    schoolGetAll(){
        return this.get(['school']);
    }

    /**
     *
     */
    schoolUpdateById(id, data){
        return this.put(['school', id], data);
    }

    /**
     *
     */
    schoolDeleteById(id){
        return this.delete(['school', id]);
    }
}
