import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class KitService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/kit-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // playingKit

    /**
     *
     */
    playingKitCreate(data){
        return this.post('playing-kit', data);
    }

    /**
     *
     */
    playingKitUpdateByTournamentNameAndTeamName(tournamentName, teamName, data){
        return this.put(['playing-kit', tournamentName, teamName], data);
    }

    /**
     *
     */
    playingKitGetByTournamentNameAndTeamName(tournamentName, teamName){
        return this.get(['playing-kit', tournamentName, teamName]);
    }

    /**
     *
     */
    playingKitDeleteByTournamentNameAndTeamName(tournamentName, teamName){
        return this.delete(['playing-kit', tournamentName, teamName]);
    }
}
