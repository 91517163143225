import Model from '@/models/model.js';

/**
 * series team evolution model class
 */
export default class SeriesTeamEvolutionModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.averageAwardRanking = data.averageAwardRanking || null; // AAR
            this.currentSeriesRanking = data.currentSeriesRanking || null; // CSR
            this.evolutionInSeries = data.evolutionInSeries || null; // EIS
            this.pointsBreakdown = data.pointsBreakdown || [];
            this.position = data.position || null;
            this.previousSeasonSeriesRanking = data.previousSeasonSeriesRanking || null; // PSSR
            this.seasonMomentumTracker = data.seasonMomentumTracker || null; // SMT
            this.teamEqId = data.teamEqId || '';
            this.teamName = data.teamName || '';
            this.totalAwardRanking = data.totalAwardRanking || null; // TAR
        }
    }
}
