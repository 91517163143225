<script>
import base from '@/views/base.vue';
import constants from '@/constants.js';

export default {
    name: 'administrationRankingBase',
    extends: base,
    props: {
        dataRankingType: {
            type: String,
            default: '',
        },
    },
    computed: {
        sportId() {
            return constants.rankingTypes[this.dataRankingType];
        },
    },
    beforeRouteEnter (to, from, next) {
        if (to.params.dataRankingType && constants.rankingTypes[to.params.dataRankingType]) {
            next();
        }
        else {
            next({ name: 'home' });
        }
    },
}
</script>
