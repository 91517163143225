import Model from '@/models/model.js';

/**
 * user role model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class UserRoleModel extends Model {
    /**
     * role name
     *
     * @type {string}
     */
    name = '';

    /**
     * role eqId
     *
     * @type {string}
     */
    eqId = '';

    /**
     * parsed permissions
     *
     * @type {Object}
     */
    permissions = null;

    /**
     * raw permissions
     *
     * @type {Object}
     */
    permissionsRaw = null;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.eqId = data.eqId || '';
            this.id = data.id || 0;
            this.name = data.name || '';
            this.permissionsRaw = data.permissions || {};
            this.permissions = this.parsePermissions(data.permissions || {});
        }
    }

    /**
     * parse permissions
     *
     * @param {Object} permissions
     *
     * @returns {Object}
     */
    parsePermissions(permissions = {}){
        const parsed = {};

        Object.keys(permissions).forEach(key => {
            const item = {};

            (permissions[key].resources || []).forEach(resource => {
                item[resource.name] = (resource.apiResourceMethods || []).map(method => method.methodName);
            });

            parsed[key] = item;
        });

        return parsed;
    }

    /**
     * check if role allows feature
     *
     * @param {string} feature
     * @param {string[]} [actions]
     *
     * @returns {boolean}
     */
    allows(feature, actions = []){
        if(!feature){
            return true;
        }

        //split feature into service and resource and check if they exist in the permissions
        let [service, ...resource] = feature.split('/');
        resource = resource.join('/');
        if(!service || !resource || !this.permissions[service] || !this.permissions[service][resource]){
            return false;
        }

        //get and check allowed actions
        const allowedActions = this.permissions[service][resource];
        return actions.every(action => allowedActions.includes(action));
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name || '';
    }
};
