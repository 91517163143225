<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 3, showScore ? 5 : 6)" class="d-flex flex-column flex-lg-row align-items-center flex-grow-1 flex-basis-0 p-0" v-if="showTeamOneLogo || showTeamOneName">
            <div v-bind="getColumnProps(null, null, null, null,true)" class="card-img-wrapper mobile-center" v-if="showTeamOneLogo">
                <b-card-img
                    v-bind="getColumnProps('image')"
                    data-type="image"
                    v-bind:src="matchDeclaration.getImgPathTeamOne()"
                    v-bind:alt="matchDeclaration.getTeamOneName()"
                    v-bind:width="imgWidth"
                    v-bind:height="imgHeight"
                    v-if="showTeamOneLogo"
                />
            </div>

            <b-card-title v-bind="getColumnProps('team1')" v-bind:title-tag="titleTag" v-if="showTeamOneName">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('team1')" v-if="isHeadline">{{ getHeadline('team1', $tc('team', 1) + ' 1') }}</label>
                <span class="text-uppercase" v-else>{{ matchDeclaration.getTeamOneName() }}</span>
            </b-card-title>
        </div>

        <b-card-text v-bind="getColumnProps('score', 4, 2, 1)" class="align-self-center" v-if="showScore">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('score')" v-if="isHeadline">{{ getHeadline('score', $t('score')) }}</label>
            <span class="d-block text-uppercase" v-else-if="matchDeclaration.match.teamOne !== null && matchDeclaration.match.teamTwo !== null">{{ matchDeclaration.match.teamOneScore }} : {{ matchDeclaration.match.teamTwoScore }}</span>
            <span class="d-block text-center text-uppercase" v-else>{{ $t('vs') }}</span>
        </b-card-text>

        <div v-bind="getColumnProps(null, 5, showScore ? 5 : 6)" class="d-flex flex-column flex-lg-row align-items-center flex-grow-1 flex-basis-0 p-0" v-if="showTeamTwoLogo || showTeamTwoName">
            <div v-bind="getColumnProps(null, null,  null, null, true)" class="card-img-wrapper mobile-center" v-if="showTeamTwoLogo">
                <b-card-img
                    v-bind="getColumnProps('image')"
                    v-bind:src="matchDeclaration.getImgPathTeamTwo()"
                    v-bind:alt="matchDeclaration.getTeamTwoName()"
                    v-bind:width="imgWidth"
                    v-bind:height="imgHeight"
                    v-if="showTeamTwoLogo"
                />
            </div>

            <b-card-title v-bind="getColumnProps('team2')" v-bind:title-tag="titleTag" v-if="showTeamTwoName">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('team2')" v-if="isHeadline">{{ getHeadline('team2', $tc('team', 1) + ' 2') }}</label>
                <span class="text-uppercase" v-else>{{ matchDeclaration.getTeamTwoName() }}</span>
            </b-card-title>
        </div>

        <b-card-text v-bind="getColumnProps('matchNumber', 12, 6, 1)" v-if="showMatchNumber">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('matchNumber')">{{ getHeadline('matchNumber', $t('matchNumber')) }}</label>
            <span v-if="!isHeadline">{{ matchDeclaration.match.matchNumber }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('tournament', 12, 6, 1)" v-if="showTournament">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('tournament')">{{ getHeadline('tournament', $tc('tournament', 2)) }}</label>
            <span v-if="!isHeadline">{{ matchDeclaration.getTournamentNames() }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('status', 12, 6, 2)" v-if="showStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('status')">{{ getHeadline('status', $t('status')) }}</label>
            <statusIndicatorIcon v-bind:status="matchDeclaration.status" v-bind:showText="true" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('showKickOff', 12, 6, 2)" v-if="showKickOff">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('showKickOff')">{{ getHeadline('showKickOff', $t('kickOff')) }}</label>
            <span v-if="!isHeadline">{{ $fd(matchDeclaration.match.kickOff, 'dateTime') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('showWhichTeamDetailLink', 12, 6, 1)" v-if="showWhichTeamDetailLink">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('showWhichTeamDetailLink')">{{ getHeadline('showWhichTeamDetailLink', $t('team detail link')) }}</label>
            <span v-if="!isHeadline">{{ matchDeclaration.getTeamOneName() }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 4, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-on:click="emitEvent('view', $event)"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 6)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicatorIcon from '../statusIndicatorIcon.vue';

import MatchDeclarationModel from '@/models/declaration/match.js';

/**
 * match review block component
 */
export default {
    name: 'matchReviewRow',
    extends: base,
    components: {
        statusIndicatorIcon
    },
    props: {
        matchDeclaration: {
            type: MatchDeclarationModel,
            required: false,
            default(){
                return new MatchDeclarationModel();
            }
        },

        showTeamOneLogo: {
            type: Boolean,
            default: true,
        },
        showTeamTwoLogo: {
            type: Boolean,
            default: true,
        },
        showTeamOneName: {
            type: Boolean,
            default: true,
        },
        showTeamTwoName: {
            type: Boolean,
            default: true,
        },
        showScore: {
            type: Boolean,
            default: true,
        },
        showMatchNumber: {
            type: Boolean,
            default: false,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        showKickOff: {
            type: Boolean,
            default: false,
        },
        showTournament: {
            type: Boolean,
            default: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },
        showWhichTeamDetailLink: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'reviewMatchDetail',
                params: Object.assign({
                    dataTournamentDeclarationId: this.matchDeclaration.linkedTournamentDeclarationId,
                    dataMatchDeclarationId: this.matchDeclaration.id,
                }, this.detailLinkParams),
            };
        },
        customElementClasses(){
            return {
                matchDeclarationRow: true,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail);
        },
    },
};
</script>
