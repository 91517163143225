<script>
import formComponent from '@/components/form.vue';
import RankingUnionModel from '@/models/rankingUnion';

export default {
    name: 'createCalculationUnionForm',
    extends: formComponent,
    props: {
        model: {
            type: RankingUnionModel,
        },
        subscriptionTypes: {
            type: Array,
            default(){
                return [];
            },
        },
        sport: {
            type: String,
            default(){
                return 'MRU';
            },
        },
    },
    methods: {},
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'union',
                        type: 'pickTeam',
                        label: this.$tc('team', 1),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        filterValues: {
                            sport: this.sport || this.model.sport || null,
                        },
                    },
                    {
                        id: 'startDate',
                        type: 'date',
                        label: this.$t('startDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'endDate',
                        type: 'date',
                        label: this.$t('endDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'startingPoints',
                        type: 'number',
                        label: this.$t('startingPoints'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'startingMatchesPlayed',
                        type: 'number',
                        label: this.$t('startingMatchesPlayed'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'startingPosition',
                        type: 'number',
                        label: this.$t('startingPosition'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'comment',
                        type: 'textarea',
                        label: this.$t('comment'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
            ];
        },
        abortButtonLabel(){
            return null;
        },
        confirmButtonLabel(){
            return null;
        },
    },
};
</script>
