<template>
    <div class="pageContent">
        <tournamentTeamStatusTypesList
            v-bind:title="$t('tournamentTeamStatusTypes')"
            mainTitle
            v-bind:rowProps="rowProps"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import tournamentTeamStatusTypesList from '@/components/lists/administration/tournamentTeamStatusTypes.vue';

export default {
    name: 'administrationTournamentTeamStatusTypesIndex',
    extends: base,
    components: {
        tournamentTeamStatusTypesList,
    },
    data(){
        return {
            rowProps: {
                showTournamentGroup: true,
                showParticipants: true,
                showDetail: true,
                showDelete: true,
            },
        };
    },
};
</script>
