<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:waitFor="apiData.tournamentType"
            v-bind:waitForRetry="reloadTournamentType"
            v-bind:title="$t('tournamentTypeDetails')"
            v-bind:name="tournamentType ? tournamentType.name : ''"
            v-bind:nameSub="tournamentType ? tournamentType.shortName : ''"
            v-bind:details="detailData"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="secondary"
                    v-bind:to="editTournamentTypeLink(dataTournamentTypeId)"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <list
            v-bind:items="tournaments"
            v-bind:title="$t('tournamentsApplyingThisType')"
            v-bind:waitFor="apiData.tournaments"
            v-bind:waitForRetry="reloadTournaments"
        >
            <template v-slot:header>
                <tournamentRow
                    showDateRange showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: tournament, role }">
                <tournamentRow
                    showDateRange showDetail
                    v-bind:tournament="tournament"
                    v-bind:role="role"
                />
            </template>
        </list>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import tournamentRow from '@/components/rows/tournament.vue';
import detailsHeader from '@/components/detailsHeader.vue';

import TournamentTypeModel from '@/models/tournamentType.js';
import TournamentModel from '@/models/tournament.js';

export default {
    name: 'administrationTournamentTypeDetail',
    extends: base,
    components: {
        tournamentRow,
        detailsHeader,
    },
    props: {
        dataTournamentTypeId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                tournamentType: this.loadTournamentType(),
                tournaments: this.loadTournaments(),
            },
            tournamentType: null,
            tournaments: [],
        };
    },
    methods: {
        editTournamentTypeLink(id){
            return { name: 'administrationTournamentTypeEdit', params: { dataTournamentTypeId: id } };
        },

        loadTournamentType(){
            return this.$api.call.tournament.tournamentTypeGetById(this.dataTournamentTypeId).then(data => {
                this.tournamentType = new TournamentTypeModel(data);

                this.reloadTournaments();
            });
        },
        reloadTournamentType(){
            this.apiData.tournamentType = this.loadTournamentType();
        },

        loadTournaments(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.tournamentType){
                return new Promise((resolve, reject) => this.apiData.tournamentType.catch(reject));
            }

            return this.$api.call.tournament.tournamentGetByTournamentTypeEqId(this.tournamentType.eqId).then(res => {
                Object.values(res).forEach(entry => {
                    this.tournaments.push(new TournamentModel(entry));
                });
            });
        },
        reloadTournaments(){
            this.apiData.tournaments = this.loadTournaments();
        },
    },
    computed: {
        detailData(){
            if(!this.tournamentType){
                return [];
            }

            return [
                {
                    label: this.$t('group'),
                    content: (this.tournamentType.tournamentTypeGroup ? this.tournamentType.tournamentTypeGroup.name : ''),
                },
                {
                    label: this.$tc('sport', 1),
                    content: (this.tournamentType.sport ? this.tournamentType.sport : ''),
                },
                {
                    label: this.$t('numberOfParticipants'),
                    content: (this.tournamentType.numberOfParticipants ? this.tournamentType.numberOfParticipants : ''),
                },
                {
                    label: this.$t('notes'),
                    content: (this.tournamentType.notes ? this.tournamentType.notes : ''),
                },
                {
                    label: this.$t('radarThreeSixtyName'),
                    content: (this.tournamentType.radarThreeSixtyName ? this.tournamentType.radarThreeSixtyName : ''),
                },
            ];
        },
    },
    // created(){
    //     this.apiData.tournamentType.then(data => {
    //         this.tournamentType = new TournamentTypeModel(data);

    //         if (data) {
    //             this.$api.call.tournament.tournamentGetByTournamentTypeEqId(data.eqId).then(res => {
    //                 Object.values(res).forEach(entry => {
    //                     this.tournaments.push(new TournamentModel(entry));
    //                 });
    //             });
    //         }
    //     });
    // },
    // destroyed(){
    // },
};
</script>
