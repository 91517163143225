<script>
import vselectElement from '../vselect.vue';
import StandingTypeModel from '@/models/standingType.js';

/**
 * vselect element for StandingType items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectStandingTypeElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                return this.$api.call.tournament.tournamentStandingTypeSearch({
                    name: text || null,
                    sport: config.data.sport || null,
                    paging: this.usePagination(offset),
                }).then(result => (result.standingTypes || result.items || []).map(data => new StandingTypeModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.tournament.tournamentStandingTypeGetById(id)))
                            .then(results => results.map(data => new StandingTypeModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
