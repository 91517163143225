import Model from '@/models/model.js';
import DeclarationModel from '@/models/declaration.js';
import MatchModel from '@/models/match.js';
import TournamentModel from '@/models/tournament.js';
import TeamDeclarationModel from '@/models/declaration/team.js';

/**
 * match declaration model class
 */
export default class MatchDeclarationModel extends DeclarationModel {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.declarationWorkflowId = data.declarationWorkflowId || 0;
            this.eqId = data.eqId || '';
            this.firstTournamentMatchDate = this.toDate(data.firstTournamentMatchDate);
            this.id = data.id || 0;

            /**
             * get the teams logos
             * we need all this code because BE response contains
             * "isTeam1" in "data.match.teams" and
             * "logoUrl" in "data.teams"
             */
            this.teamOne = {};
            this.teamTwo = {};
            if (data.match && data.match.teams) {
                data.match.teams.forEach(team => {
                    if (team.isTeam1) {
                        this.teamOne.teamEqId = team.teamEqId || '';
                        this.teamOne.name = team.name || '';
                        this.teamOne.logoUrl = Model._api.call.union.resolveStoragePath(team.logoUrl || '') || null;
                    }
                    if (!team.isTeam1) {
                        this.teamTwo.teamEqId = team.teamEqId || '';
                        this.teamTwo.name = team.name || '';
                        this.teamTwo.logoUrl = Model._api.call.union.resolveStoragePath(team.logoUrl || '') || null;
                    }
                });
            }
            if (data.teams) {
                data.teams.forEach(team => {
                    if (team.team.teamEqId === this.teamOne.teamEqId) {
                        this.teamOne.logoUrl = Model._api.call.union.resolveStoragePath(team.team.logoUrl || '') || null;
                        if (this.teamOne.name === '') {
                            this.teamOne.name = team.team.name;
                        }
                    }
                    if (team.team.teamEqId === this.teamTwo.teamEqId) {
                        this.teamTwo.logoUrl = Model._api.call.union.resolveStoragePath(team.team.logoUrl || '') || null;
                        if (this.teamTwo.name === '') {
                            this.teamTwo.name = team.team.name;
                        }
                    }
                });
            }

            this.match = MatchModel.from(data.match) || {};
            this.openingDate = this.toDate(data.openingDate);
            this.sport = data.sport || '';
            this.status = data.status || '';
            this.steps = data.steps || [];
            this.tournamentDateFrom = data.match ? (data.match.tournamentDateFrom ? this.toDate(data.match.tournamentDateFrom) : '') : '';
            this.tournamentDateUntil = data.match ? (data.match.tournamentDateUntil ? this.toDate(data.match.tournamentDateUntil) : '') : '';
            this.tournamentName = data.match ? (data.match.tournamentName ? data.match.tournamentName : null) : null;
            this.tournamentEqId = data.match ? data.match.tournamentEqId : '';
            this.tournaments = ((data.match ? data.match.tournaments : []) || []).map(tournament => new TournamentModel(tournament));
            this.type = data.type || '';
            this.teams = (data.teams || []).map(teamDeclaration => new TeamDeclarationModel(teamDeclaration));
            this.isSevens = this.checkIsSevens();

            if(!this.tournamentName && this.tournaments.length){
                this.tournamentName = this.tournaments[0]?.name || '';
            }
        }
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPathTeamOne(){
        return this.teamOne.logoUrl || require('@/assets/img/union.svg');
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPathTeamTwo(){
        return this.teamTwo.logoUrl || require('@/assets/img/union.svg');
    }

    /**
     * get team one name
     *
     * @returns {string}
     */
    getTeamOneName() {
        if (this.teamOne && this.teamOne.name) {
            return this.teamOne.name;
        }
        return '';
    }

    /**
     * get team two name
     *
     * @returns {string}
     */
    getTeamTwoName(){
        if (this.teamTwo && this.teamTwo.name) {
            return this.teamTwo.name;
        }
        return '';
    }

    /**
     * get tournament image path
     *
     * @returns {string}
     */
    getImgPathTournament(){
        if (this.match?.tournament?.logo) {
            return this.match.tournament.logo;
        }
        return require('@/assets/img/union.svg');
    }

    getMatch(){
        return this.match || new MatchModel();
    }

    setMatch(match){
        this.match = match || new MatchModel();
    }

    getTeamDeclaration(){
        return this.teamDeclaration || new TeamDeclarationModel();
    }

    getTeamDeclarationTwo(){
        return this.teamDeclarationTwo || new TeamDeclarationModel();
    }

    setOpponentAndUnion(match, teamEqId) {
        match.teams.forEach(matchTeam => {
            if (matchTeam.team.eqId === teamEqId) {
                this.union = matchTeam.team.unionName;
            }
            if (matchTeam.team.eqId !== teamEqId) {
                this.opponent = matchTeam.name;
            }
        });
    }

    /**
     * name of first tournament in array
     *
     * @returns {string}
     */
    getTournamentName(){
        return (this.tournaments && this.tournaments.length ? this.tournaments.find(tournament => tournament.name.length).name : '') || '';
    }

    /**
     * tournament names comma seperated
     *
     * @returns {string}
     */
    getTournamentNames(){
        return this.tournaments?.map(tournament => tournament.name).join(', ');
    }

    toJSON(){
        const data = super.toJSON();

        data.matchEqId = this.getMatch().eqId || null;
        data.matchPublishOffset = this.publishOffset || null;
        data.matchTeamsheetsPublishOffset = this.teamsheetsPublishOffset || null;

        return data;
    }
}
