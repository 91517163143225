/**
 * permission model, internal use only
 */
export default class PermissionModel {
    /**
     * feature
     *
     * @type {string|null}
     */
    feature = null;

    /**
     * actions
     *
     * @type {string[]}
     */
    actions = [];

    /**
     * children
     *
     * @type {PermissionModel[]}
     */
    children = [];

    /**
     * user type
     *
     * @type {string|null}
     */
    userType = null;

    /**
     * class constructor
     *
     * @param {string} feature
     * @param {string|string[]} actions
     * @param {PermissionModel[]} [children]
     * @param {string|null} [userType]
     */
    constructor(feature, actions, children = [], userType = null){
        this.feature = feature || null;

        if(actions instanceof Array){
            this.actions = actions;
        }
        else {
            this.actions = [actions];
        }

        this.children = children || [];
        this.userType = userType || null;
    }

    /**
     * get features
     *
     * @returns {string|null}
     */
    getFeature(){
        return this.feature;
    }

    /**
     * get actions
     *
     * @returns {string[]}
     */
    getActions(){
        return this.actions;
    }

    /**
     * get children
     *
     * @returns {PermissionModel[]}
     */
    getChildren(){
        return this.children;
    }

    /**
     * get user type
     *
     * @returns {string|null}
     */
    getUserType(){
        return this.userType;
    }

    /**
     * get full list of this permission with all child permissions
     *
     * @returns {PermissionModel[]}
     */
    getWithChildren(){
        return [
            this,
            ...this.children.map(permission => permission.getWithChildren()).reduce((arr, current) => current.concat(arr), []),
        ];
    }

    /**
     * json conversion
     */
    toJSON(){
        return {
            feature: this.feature,
            actions: this.actions,
            children: this.children.map(permission => permission.toJSON()),
        };
    }
}
