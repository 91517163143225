<script>
import vselectElement from '../vselect.vue';
import PlayerModel from '@/models/person/player.js';

/**
 * vselect element for PlayerModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectPlayerElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            config.requestOptions = (text = '', offset = 0) => {
                if(config.dataDependency && config.dataDependency.matchTeamId){
                    //request loads all entries, there is no pagination here
                    if(offset > 0){
                        return Promise.resolve(null);
                    }

                    //force reloading options when match changes
                    this.addOptionReloadListener('dataAllValues.matchTeamId', true);

                    if(this.dataAllValues.matchTeamId){
                        return this.$api.call.match.matchTeamPlayerGetByMatchTeamId(this.dataAllValues.matchTeamId)
                            .then(result => (result.matchTeamPersons || []).map(data => PlayerModel.from(data)));
                    }

                    return Promise.resolve([]);
                }
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'matchTeamPlayerId':
                        return this.$api.call.match.matchTeamPlayerGetByMatchTeamId(this.dataAllValues.matchTeamId)
                            .then(result => (result.matchTeamPersons || result.items || []).map(data => new PlayerModel(data)))
                            .then(items => items.filter(player => initValues.includes(this.getOptionKey(player.matchTeamPlayerId))));
                    case 'id':
                    default:
                        return this.$api.call.match.matchTeamPlayerGetByMatchTeamId(this.dataAllValues.matchTeamId)
                            .then(result => (result.matchTeamPersons || result.items || []).map(data => new PlayerModel(data)))
                            .then(items => items.filter(player => initValues.includes(this.getOptionKey(player.id))));
                }
            };

            return config;
        },
    },
};
</script>
