import Model from '@/models/model.js';
import TeamModel from '@/models/team.js';
import PlayerModel from '@/models/person/player.js';

/**
 * team model class
 */
export default class MatchTeamModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.disciplinary = data.disciplinary || null;
            this.displayOverride = data.displayOverride || '';
            this.eqId = data.eqId || data.matchTeamEqId || '';
            this.firstHalfScore = data.firstHalfScore || 0;
            this.teamEqId = data.teamEqId || '';
            this.id = data.id || data.matchTeamId || 0;
            this.isKitApproved = data.isKitApproved || false;
            this.isPlayingAtHome = data.isPlayingAtHome || data.isHome || false;
            this.isTeam1 = data.isTeam1 || false;
            this.isTest = data.isTest || false;
            this.matchId = data.matchId || 0;
            this.result = data.result || '';
            this.score = data.score || 0;
            this.scoring = data.scoring || null;
            this.sport = data.sport || '';
            this.team = data.team ? TeamModel.from(data.team) : {};
            this.teamSheets = (data.teamSheet || []).map(data => new PlayerModel(data));
            this.teamSheetPublishStatus = data.teamsheetPublishStatus || '';
            this.name = data.name || data.teamName || data.matchTeamName || '';
            this.displayOverride = data.displayOverride || '';
            if (data.team) {
                this.name = data.team.teamName || '';
            }
            this.teamAliasEqId = data.teamAliasEqId || (data.team ? data.team.teamAliasSourceEqId : '') || (data.team ? data.team.teamAliasEqId : '') || '';
            this.teamLogoUrl = data.teamLogoUrl || '';

            if(!data.team && data.teamEqId){
                this.team = new TeamModel(data);
            }
        }
    }

    setLogoStorage(logoStorage){
        this.logoStorage = logoStorage;
        this.logo = Model._api.call.union.resolveStoragePath(this.logoStorage);
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
