<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('tournamentTeamEdit')"
            v-on:submit="updateTournamentTeam"
            mainTitle
            isForm
            doubleButtons
            noShadow
            noLine
            noPadding
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentTeamDetail"
                    v-bind:loadingWhile="apiData.tournamentTeamUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentTeamUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot>
                <wrapper
                    v-bind:waitFor="apiData.tournamentTeam"
                    v-bind:waitForRetry="reloadTournamentTeam"
                    mainTitle
                >
                    <template v-slot:header v-if="error">
                        <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
                    </template>
                    <template v-slot>
                        <editTournamentTeamForm
                            v-bind:model="tournamentTeam"
                            v-bind:errors="error ? error.getErrorIds() : undefined"
                        />
                    </template>
                </wrapper>

                <subNavigation v-bind:data-config="subnavigation" />

                <list
                    v-bind:title="$t('tournamentTeamPlayers')"
                    v-bind:items="tournamentTeam ? tournamentTeam.players : []"
                    v-bind:waitFor="apiData.tournamentTeam"
                    v-bind:waitForRetry="reloadTournamentTeam"
                    draggable
                    v-on:change="updatePlayerOrder"
                    v-bind:hide="hideElement(1)"
                >
                    <template v-slot:header>
                        <playerRow
                            showJerseyNumber showPosition showRepresenting showActionsPositionSelect showDetailInputs showIsCaptainCheckbox
                            showActionsReplacementCheckbox showActionsLeftSquadCheckbox
                            showDetail showRemove isRemoveGeneric
                            isHeadline
                        />
                    </template>
                    <template v-slot="{ item: person, role }">
                        <playerRow
                            showJerseyNumber showPosition showRepresenting showEditTeamsRepresenting showActionsPositionSelect showDetailInputs showIsCaptainCheckbox
                            showActionsReplacementCheckbox showActionsLeftSquadCheckbox
                            showDetail showRemove isRemoveGeneric
                            v-bind:person="person"
                            v-bind:role="role"
                            v-bind:actionsPositionSelectBySport="tournament ? tournament.sport : null"
                            v-bind:removeHandler="removePlayer"
                            v-on:heightChange="setPlayerHeight"
                            v-on:weightChange="setPlayerWeight"
                            v-on:isCaptainToggle="setPlayerIsCaptain"
                            v-on:leftSquadToggle="setPlayerLeftSquad"
                            v-on:replacementToggle="setPlayerReplacement"
                            v-on:positionSelect="setPersonPosition"
                            v-on:jerseyNumberToggle="updatePlayerSquadNumber"
                        />
                    </template>
                    <template v-slot:empty>
                        <div/>
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            v-bind:text="$t('clickToAdd', { type: $tc('player') })"
                            showAdd
                            v-b-modal.playerPick
                        />
                    </template>
                </list>

                <list
                    v-bind:title="$t('tournamentTeamManagement')"
                    v-bind:items="tournamentTeam ? tournamentTeam.managers : []"
                    v-bind:waitFor="apiData.tournamentTeam"
                    v-bind:waitForRetry="reloadTournamentTeam"
                    draggable
                    v-on:change="updateManagerOrder"
                    v-bind:hide="hideElement(2)"
                >
                    <template v-slot:header>
                        <managerRow
                            showPosition showActionsPositionSelect showDetail showRemove
                            isHeadline
                        />
                    </template>
                    <template v-slot="{ item: person, role }">
                        <managerRow
                            showPosition showActionsPositionSelect showDetail showRemove
                            v-bind:person="person"
                            v-bind:role="role"
                            v-bind:removeHandler="removeManager"
                            v-on:positionSelect="setPersonPosition"

                        />
                    </template>
                    <template v-slot:empty>
                        <div/>
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            v-bind:text="$t('clickToAdd', { type: $tc('manager') })"
                            showAdd
                            v-b-modal.managerPick
                        />
                    </template>
                </list>

                <list
                    v-bind:title="$t('tournamentTeamMedics')"
                    v-bind:items="tournamentTeam ? tournamentTeam.medics : []"
                    v-bind:waitFor="apiData.tournamentTeam"
                    v-bind:waitForRetry="reloadTournamentTeam"
                    draggable
                    v-on:change="updateMedicOrder"
                    v-bind:hide="hideElement(3)"
                >
                    <template v-slot:header>
                        <medicRow
                            showPosition showActionsPositionSelect showDetail showRemove
                            isHeadline
                        />
                    </template>
                    <template v-slot="{ item: person, role }">
                        <medicRow
                            showPosition showActionsPositionSelect showDetail showRemove
                            v-bind:person="person"
                            v-bind:role="role"
                            v-bind:removeHandler="removeMedic"
                            v-on:positionSelect="setPersonPosition"
                        />
                    </template>
                    <template v-slot:empty>
                        <div/>
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            v-bind:text="$t('clickToAdd', { type: $tc('medic') })"
                            showAdd
                            v-b-modal.medicPick
                        />
                    </template>
                </list>

                <list
                    v-bind:title="$t('tournamentTeamKits')"
                    v-bind:items="tournamentTeamKits"
                    v-bind:waitFor="apiData.tournamentTeamKits"
                    v-bind:waitForRetry="reloadTournamentTeamKits"
                    isRow
                    v-bind:hide="hideElement(4)"
                >
                    <template v-slot="{ item: teamKit, role }">
                        <teamKitRow
                            v-bind:teamKit="teamKit"
                            v-bind:role="role"
                        />
                    </template>
                </list>
            </template>
        </wrapper>

        <playerPickModal
            id="playerPick"
            multiselect
            v-bind:title="$t('addPlayer')"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:filterValues="{ unionEqId: getUnionEqId, role: constants.personRoleType.player, sport, team: getTeam }"
            v-bind:rowProps="pickerRowProps"
            v-bind:listHideItems="selectedTournamentTeamPlayers"
            v-bind:selectHandler="addPlayers"
        />
        <managerPickModal
            id="managerPick"
            multiselect
            v-bind:title="$t('addManager')"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:filterValues="{ unionEqId: getUnionEqId, role: constants.personRoleType.management, sport, team: getTeam }"
            v-bind:rowProps="pickerRowProps"
            v-bind:listHideItems="selectedTournamentTeamManagementAndMedics"
            v-bind:selectHandler="addManagers"
        />
        <medicPickModal
            id="medicPick"
            multiselect
            v-bind:title="$t('addMedic')"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:filterValues="{ unionEqId: getUnionEqId, role: constants.personRoleType.management, sport, team: getTeam}"
            v-bind:rowProps="pickerRowProps"
            v-bind:listHideItems="selectedTournamentTeamManagementAndMedics"
            v-bind:selectHandler="addMedics"
        />
    </div>
</template>

<script>
import constants from '@/constants.js';
import commonTournamentTeamDetail from './detail.vue';
import editTournamentTeamForm from '@/components/forms/editTournamentTeam.vue';
import validations from '@/components/validations.vue';

import simpleRow from '@/components/rows/simple.vue';

import playerPickModal from '@/components/modals/pick/player.vue';
import managerPickModal from '@/components/modals/pick/manager.vue';
import medicPickModal from '@/components/modals/pick/medic.vue';

import TournamentModel from '@/models/tournament.js';
import PlayerModel from '@/models/person/player.js';
import ManagerModel from '@/models/person/manager.js';
import MedicModel from '@/models/person/medic.js';
import TeamKitModel from '@/models/person/teamkit.js';

export default {
    name: 'commonTournamentTeamEdit',
    extends: commonTournamentTeamDetail,
    components: {
        editTournamentTeamForm,
        validations,
        simpleRow,
        playerPickModal,
        managerPickModal,
        medicPickModal,
    },
    data(){
        return {
            apiData: {
                tournamentTeamUpdate: null,
            },
            tournamentTeam: null,
            error: null,
            pickerFilterDisplay: {
                lastAppearance: false,
                isPersonActive: false,
                sport: 'MRU',
            },
            pickerRowProps: {
                showDetails: true,
            },
            newKitPrimary: null,
            newKitSecondary: null,
            unknownPosition: null,
        };
    },
    computed: {
        toTournamentTeamDetail(){
            return {
                name: 'commonTournamentTeamDetail',
                params: {
                    tournamentId: this.dataTournamentId,
                    tournamentTeamId: this.dataTournamentTeamId,
                },
            };
        },
        getUnionEqId(){
            if(!this.tournamentTeam || !this.tournamentTeam.team){
                return null;
            }

            return this.tournamentTeam.team.unionEqId || null;
        },
        getTeam(){
            if(!this.tournamentTeam || !this.tournamentTeam.team){
                return null;
            }

            return this.tournamentTeam.team || null;
        },
        sport(){
            if(!this.tournament || !this.tournament.sport){
                return null;
            }

            return this.tournament.sport;
        },
        selectedTournamentTeamPlayers(){
            return (this.tournamentTeam ? this.tournamentTeam.players : []).map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },
        selectedTournamentTeamManagement(){
            return (this.tournamentTeam ? this.tournamentTeam.managers : []).map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },
        selectedTournamentTeamMedics(){
            return (this.tournamentTeam ? this.tournamentTeam.medics : []).map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },
        selectedTournamentTeamManagementAndMedics(){
            const management = this.selectedTournamentTeamManagement;

            return management.concat(this.selectedTournamentTeamMedics);
        },
    },
    methods: {
        loadTournament(){
            // override get tournament to ensure we have a tournament to obtain the sport
            if(!this.tournamentTeam){
                return new Promise((resolve, reject) => this.apiData ? this.apiData.tournamentTeam.catch(reject) : '');
            }

            return this.$api.call.tournament.tournamentGetById(this.tournamentTeam.tournamentId).then(data => {
                this.tournament = new TournamentModel(data);

                // to create an initial tournament team person a position is required
                this.$api.call.person.positionSearch({
                    name: 'Unknown',
                }).then(positionsData => {
                    if (positionsData && positionsData.positions && positionsData.positions.length) {
                        this.unknownPosition = positionsData.positions.find(obj => {
                            return obj.sport === this.tournament.sport });
                    }
                });
            });
        },
        prepareTournamentTeamKits(){
            //if secondary is the only one we get, add empty primary before
            if(this.tournamentTeamKits.length === 1 && this.tournamentTeamKits[0].type === TeamKitModel.types.secondary){
                if(!this.newKitPrimary){
                    this.newKitPrimary = TeamKitModel.from({ type: TeamKitModel.types.primary, tournamentTeamId: this.dataTournamentTeamId })
                }
                this.tournamentTeamKits.unshift(this.newKitPrimary);
            }

            //if none received, add empty primary
            if(this.tournamentTeamKits.length < 1){
                if(!this.newKitPrimary){
                    this.newKitPrimary = TeamKitModel.from({ type: TeamKitModel.types.primary, tournamentTeamId: this.dataTournamentTeamId })
                }
                this.tournamentTeamKits.push(this.newKitPrimary);
            }
            //if one received, add empty secondary
            if(this.tournamentTeamKits.length < 2){
                if(!this.newKitSecondary){
                    this.newKitSecondary = TeamKitModel.from({ type: TeamKitModel.types.secondary, tournamentTeamId: this.dataTournamentTeamId })
                }
                this.tournamentTeamKits.push(this.newKitSecondary);
            }

            //primary kit should display first
            if (this.tournamentTeamKits.length && this.tournamentTeamKits.length > 1) {
                var secondaryKitIndex = this.tournamentTeamKits.findIndex(kit => {
                    return kit.type === 'Secondary';
                });
                if (secondaryKitIndex === 0) {
                    this.tournamentTeamKits.push(this.tournamentTeamKits.shift());
                }
            }
        },
        updateTournamentTeam() {
            let queue = Promise.resolve();

            //check if the user wants to change the kit images and upload them
            this.tournamentTeamKits.forEach(teamKit => {
                if(teamKit.image instanceof File){
                    queue = queue.then(() => this.$api.call.tournament.storeFiles(teamKit.image)).then(storageFile => {
                        teamKit.setImageStorage(storageFile);
                    }, error => {
                        //set custom file upload validation error
                        error.errors.image = this.$t('fileUploadError');
                    });
                }
            });

            //save kits
            queue = queue.then(() => Promise.all(this.tournamentTeamKits.map(teamKit => {
                if(teamKit.id){
                    //update existing kit
                    return this.$api.call.tournament.tournamentTeamKitUpdateById(teamKit.id, teamKit.toJSON());
                }
                else if(Object.keys(teamKit._changedProperties).length) {
                    //add kit if it has any content
                    return this.$api.call.tournament.tournamentTeamAddKit(teamKit.toJSON());
                }
            }))).then(() => {
                this.reloadTournamentTeamKits();
            }, err => {
                this.reloadTournamentTeamKits();
                throw err;
            });

            //save players/managers/medics
            const persons = [].concat(this.tournamentTeam.players, this.tournamentTeam.managers, this.tournamentTeam.medics);
            queue = queue.then(() => Promise.all(persons.map(person => {
                const playerAttributes = person.toJSON().playerAttributes;
                const dataToSave = {
                    role: person.roleType === constants.group.medical ? constants.personRoleType.management : person.roleType,
                    positionEqId: (person.positionEqId ? person.positionEqId : '') || (person.position ? person.position.eqId : ''),
                };

                if (playerAttributes) {
                    dataToSave.playerAttributes = {
                        height: playerAttributes.height,
                        weight: playerAttributes.weight,
                        hasPlayerLeftSquad: playerAttributes.hasPlayerLeftSquad,
                        squadNumber: playerAttributes.squadNumber,
                        clubApproved: playerAttributes.clubApproved,
                        isUsedReplacement: playerAttributes.isUsedReplacement,
                        isCaptain: playerAttributes.isCaptain,
                    };
                }

                if (person.representing){
                    const limitedRepresenting = person.representing.map(({ teamName, teamAliasEqId }) => ({ teamName, teamAliasEqId }));
                    dataToSave.representing = limitedRepresenting;
                }

                switch (true) {
                    case person instanceof MedicModel:
                        dataToSave.group = constants.group.medical;
                        break;
                    case person instanceof ManagerModel:
                        dataToSave.group = constants.group.management;
                        break;
                    default:
                        dataToSave.group = constants.group.player;
                        break;
                }

                return this.$api.call.tournament.tournamentTeamPersonUpdateById(person.tournamentTeamPersonId, dataToSave);
            })));

            queue = queue.then(() => {
                this.error = null;
                this.$router.push(this.toTournamentTeamDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('update tournament team failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.tournamentTeamUpdate = queue;
        },
        addPerson(person){
            const personRole = person.getCurrentRole();
            if(!personRole){
                return Promise.reject(Error('player has no role'));
            }

            let dataToSave = {
                personEqId: person.eqId,
                role: person.roleType === constants.group.medical ? constants.personRoleType.management : person.roleType,
                personRoleEqId: personRole.eqId,
                tournamentTeamId: this.dataTournamentTeamId,
                positionEqId: this.unknownPosition.eqId,
            }

            if(personRole.roleType === 'Player'){
                dataToSave.playerAttributes = {
                    height: person.height,
                    weight: person.weight,
                    hasPlayerLeftSquad: person.hasPlayerLeftSquad || false,
                    squadNumber: person.squadNumber || null,
                    clubApproved: 0,
                    isUsedReplacement: person.isUsedReplacement,
                    isCaptain: person.isCaptain,
                    isLoosehead: person.isLoosehead,
                    isHooker: person.isHooker,
                    isTighthead: person.isTighthead,
                };
            }

            if(person.representing && Object.keys(person.representing).length > 0){
                dataToSave.representing = person.representing;
            }

            switch (true) {
                case person instanceof MedicModel:
                    dataToSave.group = constants.group.medical;
                    break;
                case person instanceof ManagerModel:
                    dataToSave.group = constants.group.management;
                    break;
                default:
                    dataToSave.group = constants.group.player;
                    break;
            }

            // save tournament team person
            return this.$api.call.tournament.tournamentTeamAddPersonByPersonEqId(dataToSave)
                .catch(err => {
                    if(this.$log){
                        this.$log.error(err);
                    }
                    return Promise.reject(Error(this.$t('requestError')));
                });
        },
        removePerson(person){
            return this.$api.call.tournament.tournamentTeamDeletePersonByTeamPersonId(person.tournamentTeamPersonId).catch(err => {
                if(this.$log){
                    this.$log.error(err);
                }
                return Promise.reject(Error(this.$t('requestError')));
            });
        },
        addPlayers(players){
            return Promise.all(players.map(player => {
                return this.addPerson(player).then(data => {
                    this.tournamentTeam.players.push(new PlayerModel(data));
                });
            }));
        },
        removePlayer(selectedPlayer) {
            return this.removePerson(selectedPlayer).then(() => {
                this.tournamentTeam.players = this.tournamentTeam.players.filter(player => player.tournamentTeamPersonId !== selectedPlayer.tournamentTeamPersonId);
            });
        },
        addManagers(managers){
            return Promise.all(managers.map(manager => {
                return this.addPerson(manager).then(data => {
                    this.tournamentTeam.managers.push(new ManagerModel(data));
                });
            }));
        },
        removeManager(selectedManager) {
            return this.removePerson(selectedManager).then(() => {
                this.tournamentTeam.managers = this.tournamentTeam.managers.filter(manager => manager.tournamentTeamPersonId !== selectedManager.tournamentTeamPersonId);
            });
        },
        addMedics(medics){
            return Promise.all(medics.map(medic => {
                return this.addPerson(medic).then(data => {
                    this.tournamentTeam.medics.push(new MedicModel(data));
                });
            }));
        },
        removeMedic(selectedMedic) {
            return this.removePerson(selectedMedic).then(() => {
                this.tournamentTeam.medics = this.tournamentTeam.medics.filter(medic => medic.tournamentTeamPersonId !== selectedMedic.tournamentTeamPersonId);
            });
        },
        updatePlayerNumbers(){
            this.tournamentTeam.players.forEach((player, i) => {
                player.number = i + 1;
            });
        },
        updatePlayerOrder(players){
            this.tournamentTeam.players = players;
            this.updatePlayerNumbers();
        },
        updateManagerOrder(managers){
            this.tournamentTeam.managers = managers;
        },
        updateMedicOrder(medics){
            this.tournamentTeam.medics = medics;
        },
        setPlayerHeight(data){
            if(!data || !data.person){
                return;
            }

            data.person.setHeight(data.value);
        },
        setPlayerWeight(data){
            if(!data || !data.person){
                return;
            }

            data.person.setWeight(data.value);
        },
        setPlayerIsCaptain(data){
            if(!data || !data.person){
                return;
            }

            data.person.setIsCaptain(data.value);
        },
        setPlayerLeftSquad(data){
            if(!data || !data.person){
                return;
            }

            data.person.setLeftSquad(data.value);
        },
        setPlayerReplacement(data){
            if(!data || !data.person){
                return;
            }

            data.person.setReplacement(data.value);
        },
        setPersonPosition(data){
            //ignore if nothing changed
            if(!data || !data.person || data.person.position === data.value){
                return;
            }

            data.person.setPosition(data.value);
            if (data && data.value && data.value.eqId) {
                data.person.positionEqId = data.value.eqId;
            }
        },
        updatePlayerSquadNumber(data) {
            if(data?.person?.playerAttributes) {
                data.person.playerAttributes.squadNumber = data.value.srcElement.value;
            }
        },
    },
};
</script>
