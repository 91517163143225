<script>
import declarationPersonPickModal from './person.vue';
import declarationManagerList from '@/components/lists/declaration/manager.vue';

/**
 * declaration manager pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'declarationManagerPickModal',
    extends: declarationPersonPickModal,
    components: {
        declarationManagerList,
    },
    data(){
        return {
            listElement: declarationManagerList,
        };
    },
};
</script>
