import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationTournamentStandingTypeIndex from '@/views/administration/tournamentStandingType/index.vue';
import administrationTournamentStandingTypeCreate from '@/views/administration/tournamentStandingType/create.vue';
import administrationTournamentStandingTypeDetail from '@/views/administration/tournamentStandingType/detail.vue';
import administrationTournamentStandingTypeEdit from '@/views/administration/tournamentStandingType/edit.vue';
import administrationTournamentStandingTypeOrderingParameter from '@/views/administration/tournamentStandingType/arrangeOrderingParameters.vue';

export default {
    path: 'tournament-standing-type/',
    component: pageFrame,
    children: [
        {
            name: 'administrationTournamentStandingTypeIndex',
            path: constants.routePaths.index,
            component: administrationTournamentStandingTypeIndex,
            meta: {
                permission: permissions.tournamentStandingTypes.list,
            },
        },
        {
            name: 'administrationTournamentStandingTypeCreate',
            path: constants.routePaths.create,
            component: administrationTournamentStandingTypeCreate,
            meta: {
                permission: permissions.tournamentStandingTypes.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.tournamentStandingTypeId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationTournamentStandingTypeDetail',
                    path: constants.routePaths.index,
                    component: administrationTournamentStandingTypeDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentStandingTypeId]),
                    meta: {
                        permission: permissions.tournamentStandingTypes.detail,
                    },
                },
                {
                    name: 'administrationTournamentStandingTypeEdit',
                    path: constants.routePaths.edit,
                    component: administrationTournamentStandingTypeEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentStandingTypeId]),
                    meta: {
                        permission: permissions.tournamentStandingTypes.update,
                    },
                },
                {
                    name: 'administrationTournamentStandingTypeOrderingParameter',
                    path: 'arrange-ordering-parameter/',
                    component: administrationTournamentStandingTypeOrderingParameter,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentStandingTypeId]),
                    meta: {
                        permission: permissions.tournamentStandingTypes.orderParameters.update,
                    },
                },
            ],
        },
    ],
};
