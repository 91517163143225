import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class RankingsService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/ranking-service/api/';

    /**
     * base path parts
     *
     * @type {object}
     */
    _paths = {
        rankingAlteration: 'ranking-alteration',
    };

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // run

    /**
     *
     */
    runGetLatest(){
        return this.get(['run']);
    }

    runSearch(query){
        return this.post(['run', 'search', query]);
    }

    /**
     *
     */
    runGetBySportAndYear(sport, year){
        return this.get(['run', 'list', sport, year]);
    }

    /**
     *
     */
    runDeleteById(id){
        return this.delete(['run', id]);
    }

    /**
     *
     */
    runPredictBySport(sport, data){
        return this.post(['run', sport, 'predict'], data);
    }

    /**
     *
     */
    runGetCalculatedView(id){
        return this.get(['run', id]);
    }

    /**
     *
     */
    runGenerate(data){
        return this.post(['run'], data);
    }

    /**
     *
     */
    runGenerateCalculate(data){
        return this.post(['run', 'calculate'], data);
    }

    /**
     *
     */
    rankingRunGetBySportAndDate(sport, date){
        return this.get(['run', sport, date]);
    }

    /**
     *
     */
    rankingRunGetMatchesBySportAndDate(sport, date){
        return this.get(['run', sport, date, 'matches']);
    }

    // ranking
    /**
     *
     */
    rankingGetLatest(sport){
        return this.get(['ranking', 'latest', sport]);
    }

    /**
     *
     */
    rankingGetYears(sport){
        return this.get(['ranking', sport, 'years']);
    }

    // Alteration

    /**
     *
     */
    alterationGetById(id){
        return this.get([this._paths.rankingAlteration, id]);
    }

    /**
     *
     */
    alterationGetBySport(sport){
        return this.get([this._paths.rankingAlteration, 'list', sport]);
    }

    /**
     *
     */
    alterationCreate(data){
        return this.post(this._paths.rankingAlteration, data);
    }

    /**
     *
     */
    alterationDeleteById(id){
        return this.delete([this._paths.rankingAlteration, id]);
    }

    /**
     *
     */
    alterationUpdateById(id, data){
        return this.put([this._paths.rankingAlteration, id], data);
    }

    // Calculation

    /**
     *
     */
    calculationGetBySport(sport){
        return this.get(['calculation', 'list', sport]);
    }

    /**
     *
     */
    calculationGetById(id){
        return this.get(['calculation', id]);
    }

    /**
     *
     */
    calculationCreate(data){
        return this.post('calculation', data);
    }

    /**
     *
     */
    calculationDeleteById(id){
        return this.delete(['calculation', id]);
    }

    /**
     *
     */
    calculationUpdateById(id, data){
        return this.put(['calculation', id], data);
    }

    // RatingChange

    /**
     *
     */
    ratingChangeGetById(id){
        return this.get(['rating-change', id]);
    }

    /**
     *
     */
    ratingChangeCreate(data){
        return this.post('rating-change', data);
    }

    /**
     *
     */
    ratingChangeDeleteById(id){
        return this.delete(['rating-change', id]);
    }

    /**
     *
     */
    ratingChangeUpdateById(id, data){
        return this.put(['rating-change', id], data);
    }

    // weighting

    /**
     *
     */
    weightingsGetBySport(sport){
        return this.get(['weighting', 'list', sport]);
    }

    // TournamentWeighting

    /**
     *
     */
    tournamentWeightingGetById(id){
        return this.get(['tournament-weighting', id]);
    }

    /**
     *
     */
    tournamentWeightingCreate(data){
        return this.post('tournament-weighting', data);
    }

    /**
     *
     */
    tournamentWeightingDeleteById(id){
        return this.delete(['tournament-weighting', id]);
    }

    /**
     *
     */
    tournamentWeightingUpdateById(id, data){
        return this.put(['tournament-weighting', id], data);
    }

    // Team

    /**
     *
     */
    calculationRankingUnionCreate(data){
        return this.post('team', data);
    }

    /**
     *
     */
    rankingUnionGetBySport(sport){
        return this.get(['team', 'list', sport]);
    }

    /**
     *
     */
    rankingUnionGetByTeamId(teamId){
        return this.get(['team', teamId]);
    }

    /**
     *
     */
    rankingUnionUpdateById(id, data){
        return this.put(['team', id], data);
    }

    teamInactivity(sport){
        return this.get(['team', sport, 'inactivity']);
    }

    // ActiveUnion

    /**
     *
     */
    activeUnionGetById(id){
        return this.get(['active-union', id]);
    }

    /**
     *
     */
    activeUnionCreate(data){
        return this.post('active-union', data);
    }

    /**
     *
     */
    activeUnionDeleteById(id){
        return this.delete(['active-union', id]);
    }

    /**
     *
     */
    activeUnionUpdateById(id, data){
        return this.put(['active-union', id], data);
    }
}
