<template>
    <b-col
        v-bind:class="rowClasses"
        v-bind:role="role || (hasVisibleAssigns ? 'button' : null)"
        v-on:click="emitEvent('click', $event)"
    >
        <h2
            class="pl-3 pt-3"
        >
            {{ teamKit.teamName }}
        </h2>
        <teamKitSelectionForm
            class="col-md-6 mt-5 mb-n7"
            v-bind:matchTeamId="teamKit.matchTeamId"
            v-bind:values="{ kitSelection: teamKit.id ? teamKit : null }"
            v-on:change="changeKit"
            v-if="!hideSelectTeamKit"
        />
        <div class="col-md-6" v-if="teamKit.type === 'Primary'">
            <loading v-bind:waitFor="teamKitPrimaryUpdate"/>
        </div>
        <div class="col-md-6" v-if="teamKit.type === 'Secondary'">
            <loading v-bind:waitFor="teamKitSecondaryUpdate"/>
        </div>
        <teamKitForm
            v-bind:model="teamKit"
            v-bind:readonly="readonly"
            v-bind:matchTeamId="teamKit.matchTeamId"
            v-on:change="updateKit"
            v-if="!teamKit.isUndefined() || (!teamKit.isUndefinedFromTop() && readonly) || (teamKit.isUndefined() && !readonly)"
            class="col-md-6 mt-1"
        />
        <div
            class="col-md-6 mt-7"
            v-if="(teamKit.isUndefined() || teamKit.isUndefinedFromTop()) && readonly && !hideSelectTeamKit"
        >
            {{ $t('noKitSelected') }}
        </div>
    </b-col>
</template>

<script>
import base from './base.vue';
import teamKitForm from '@/components/forms/teamKit.vue';
import teamKitSelectionForm from '@/components/forms/teamKitSelection.vue';
import loading from '@/components/loading.vue';

import TeamKitModel from '@/models/person/teamkit.js';

export default {
    name: 'teamKitRow',
    extends: base,
    components: {
        teamKitForm,
        teamKitSelectionForm,
        loading,
    },
    data(){
        return {
            kitSelection: null,
            teamKitInit: false,
        };
    },
    props: {
        teamKit: {
            type: TeamKitModel,
            required: true,
            default(){
                return new TeamKitModel();
            }
        },
        showValidationBiography: {
            default: false,
        },
        showValidationPassport: {
            default: false,
        },
        showValidationMedicalCertificate: {
            default: false,
        },
        hideSelectTeamKit: {
            type: Boolean,
            default() {
                return true;
            }
        },
        /**
         * enable image edit modal
         */
        editImg: {
            type: Boolean,
            default: true,
        },
        /**
         * image width
         */
        imgWidth: {
            type: Number,
            default: 100,
        },
        /**
         * image height
         */
        imgHeight: {
            type: Number,
            default: 100,
        },
        /**
         * show teamkit image
         */
        showImg: {
            type: Boolean,
            default: true,
        },
        /**
         * show download button
         */
        showUploadButton: {
            type: Boolean,
            default: true,
        },
        /**
         * show download button
         */
        showDownloadButton: {
            type: Boolean,
            default: false,
        },
        /**
         * show teamkit name
         */
        showName: {
            type: Boolean,
            default: true,
        },
        /**
         * show teamkit name
         */
        readonly: {
            type: Boolean,
            default: false,
        },
        /**
         * primary team kit promise for loading
         */
        teamKitPrimaryUpdate: {
            type: Promise,
            default: null,
        },
        /**
         * secondary team kit promise for loading
         */
        teamKitSecondaryUpdate: {
            type: Promise,
            default: null,
        },
    },
    computed: {
        hasVisibleAssigns(){
            return true;
        },
        hasVisibleValidations(){
            return (this.showValidationBiography || this.showValidationPassport || this.showValidationMedicalCertificate);
        },
    },
    methods: {
        changeKit(data) {
            if (data.kitSelection && this.kitSelection !== data.kitSelection) {
                this.kitSelection = data.kitSelection;
                this.$emit('kitSelection', this.kitSelection);
            }
        },
        updateKit(teamKit) {
            if (teamKit && !this.teamKitInit){
                this.teamKitInit = true;
            }
            else
            {
                if(Object.keys(this.teamKit._changedProperties).length){
                    this.$emit('kitUpdate', teamKit);
                }
            }
        },
    },
}
</script>
