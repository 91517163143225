<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'addSchoolForm',
    extends: formComponent,
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'school',
                        type: 'vselectSchool',
                        label: this.$tc('school', 1),
                        placeholder: this.$t('select') + ' ' + this.$tc('school', 1),
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
