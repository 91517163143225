<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-on:show="onOpen"
    >
        <template v-slot="{ formId, submit }">
            <list
                v-bind:items="kits"
                isRow
            >
                <template v-slot="{ item: teamKit, role, index }">
                    <teamKitRow
                        v-bind:dataId="formId"
                        v-bind:teamKit="teamKit"
                        v-bind:role="role"
                        v-bind:readonly="readonly"
                        v-bind:hideSelectTeamKit="hideSelectTeamKit"
                        v-bind:errors="errorIds"
                        v-on:kitSelection="setKit(index, $event)"
                        v-on:submit="submit"
                    />
                </template>
            </list>
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import list from '@/components/list.vue';
import teamKitRow from '@/components/rows/teamKit.vue';

/**
 *
 */
export default {
    name: 'selectKitsModal',
    extends: base,
    components: {
        list,
        teamKitRow,
    },
    props: {
        matchKits: {
            type: Array,
            required: false,
            default(){
                return {};
            },
        },
        dataMatchId: {
            type: Number,
            default(){
                return 0;
            },
        },
        hideSelectTeamKit: {
            type: Boolean,
            default() {
                return true;
            }
        },
        showTeamName: {
            type: Boolean,
            required: false,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            kits: [],
        };
    },
    computed: {
        payload(){
            return this.kits;
        },
    },
    methods: {
        setKit(index, kit){
            kit.teamName = (this.kits[index] || {}).teamName || '';
            kit.matchTeamId = (this.kits[index] || {}).matchTeamId || '';
            this.kits.splice(index, 1, kit);
        },
        onOpen(){
            this.kits = [...this.matchKits];
        },
    },
};
</script>
