<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <input
            type="color"
            v-bind:id="elementId"
            v-model="value"
            v-bind:disabled="config.disabled || false"
            v-bind:class="inputClasses"
        />
    </b-form-group>
</template>

<script>
import base from './base.vue';

/**
 * color select element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'colorElement',
    extends: base,
    data(){
        return {
            value: null
        };
    },
    computed: {
        inputClasses(){
            return {
                'is-invalid': (this.state === false),
            };
        }
    },
};
</script>
