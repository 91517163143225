import Model from '@/models/model.js';
import PositionModel from '@/models/position.js';
import PersonRoleModel from '@/models/personRole.js';
import PersonSchoolModel from '@/models/school.js';
import TeamModel from '@/models/team.js';
import LocationModel from '@/models/location.js';
import constants from '../constants';

/**
 * person model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class PersonModel extends Model {
    /**
     * id
     *
     * @type {int}
     */
    id = 0;

    /**
     * person forename
     *
     * @type {string}
     */
    forename = '';

    /**
     * person surname
     *
     * @type {string}
     */
    surname = '';

    /**
     * person nickname
     *
     * @type {string|null}
     */
    nickname = null;

    /**
     * person age
     *
     * @type {number|null}
     */
    age = null;

    /**
     * person height
     *
     * @type {number|null}
     */
    height = null;

    /**
     * person height unit
     *
     * @type {number|null}
     */
    heightUnit = null;

    /**
     * person weight
     *
     * @type {number|null}
     */
    weight = null;

    /**
     * person weight unit
     *
     * @type {number|null}
     */
    weightUnit = null;

    /**
     * dominant hand
     *
     * @type {string|null}
     */
    hand = null;

    /**
     * dominant foot
     *
     * @type {string|null}
     */
    foot = null;

    /**
     * person id
     *
     * @type {number|null}
     */
    personId = null;

    /**
     * first match kick off used to highlight age restrictions
     *
     * @type {date|null}
     */
    firstMatchKickOff = null;

    /**
     * last match kick off used to highlight age restrictions
     *
     * @type {date|null}
     */
    lastMatchKickOff = null;

    /**
     * mapping providers
     *
     * @type {array}
     */
    mappingProviders = [];

    /**
     * email address
     *
     * @type {string|null}
     */
    emailAddress = null;

    /**
     * British & Irish Lions number
     *
     * @type {number|null}
     */
    lionsNumber = null;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}) {
        super(data);

        if (data) {
            this.roles = (data.roles || []).map((data) => PersonRoleModel.from(Object.assign(data, { person: this })));

            let personRole = null;
            if (data.personRole && typeof data.personRole === 'object') {
                personRole = PersonRoleModel.from(data.personRole);
                this.roles.push(personRole);
            }

            this.activeTeams = (data.activeTeams || []).map((data) =>
                TeamModel.from(Object.assign(data, { person: this }))
            );
            this.age = data.age || null;
            this.currentRole = this.getCurrentRole();
            this.dateFrom = data.dateFrom || '';
            this.dateUntil = data.dateUntil || '';
            this.dateOfBirth = this.toDate(data.dateOfBirth);
            this.dateOfDeath = this.toDate(data.dateOfDeath);
            this.deletionDate = (data.deletionDate ? this.toDate(data.deletionDate) : null) || null;
            this.displayName = data.displayName || data.personName || '';
            this.eqId = data.eqId || data.personEqId || '';
            this.firstName = data.firstName || data.firstname || data.forenames || '';
            this.foot = data.foot || null;
            this.gender = data.gender || '';
            this.group = data.group || '';
            this.hallOfFameInducted = (data.hallOfFameInducted ? this.toDate(data.hallOfFameInducted) : null) || null;
            this.hand = data.hand || null;
            this.hasBeenImpactPlayer = data.hasBeenImpactPlayer || false;
            this.hasBeenPlayerOfFinal = data.hasBeenPlayerOfFinal || false;
            this.height = data.height || data.playingHeight || null;
            this.heightUnit = data.heightUnit || null;
            this.id = data.id || 0;
            this.initials = data.initials || '';
            this.isDeleted = data.isDeleted || false;
            this.isRetired = data.isRetired || false;
            this.knownAsFirstname = data.knownAsFirstname || data.knownAs || '';
            this.knownAsLastname = data.knownAsLastname || data.knownAsSurname || '';
            this.lastName = data.lastName || data.lastname || data.surname || '';
            this.level = data.level || 0;
            this.locationPlaceOfBirthEqId = data.locationPlaceOfBirthEqId || '';
            this.maidenName = data.maidenName || '';
            this.matches = data.matches || null;
            this.nativeKnownAsFirstname = data.nativeKnownAsFirstname || data.nativeKnownAs || '';
            this.nativeKnownAsLastname = data.nativeKnownAsLastname || data.nativeKnownAsSurname || '';
            this.nickname = data.nickname || null;
            this.order = data.order || 0;
            this.placeOfBirth = LocationModel.from(data.placeOfBirth);
            this.position = typeof data.position === 'object' ? new PositionModel(data.position) : data.position;
            this.prefix = data.prefix || '';
            this.representing = (data.representing || []).map((team) => TeamModel.from(team));
            this.roleId = data.roleId || 0;
            this.schools = (data.schools || []).map((data) =>
                PersonSchoolModel.from(Object.assign(data, { person: this }))
            );
            this.sport = data.sport || '';
            this.sportId = data.sportId || 0;
            this.suffix = data.suffix || '';
            this.team = data.team || data.teamName || '';
            this.teamCode = data.teamCode || '';
            this.tournaments = data.tournaments || null;
            this.union = data.union || null;
            this.weight = data.weight || data.playingWeight || null;
            this.weightUnit = data.weightUnit || null;
            this.teamDeclarationPersonId = data.teamDeclarationPersonId || 0;
            this.teamNameShort = data.teamNameShort || '';
            this.tackles = data.tackles || 0;
            this.breaks = data.breaks || 0;
            this.offloads = data.offloads || 0;
            this.carries = data.carries || 0;
            this.total = data.total || 0;
            this.emailAddress = data.emailAddress || '';
            this.lionsNumber = data.lionsNumber || null;

            if (this.position) {
                this.positionEqId = this.position.eqId;
            }

            if (personRole && personRole.name && (!this.firstName || !this.lastName)) {
                const splitPersonRoleName = personRole.name.split(' ');
                this.firstName = splitPersonRoleName[0];
                this.lastName = splitPersonRoleName[splitPersonRoleName.length - 1];
            }

            //IdentifiableInfo
            this.passportScanUrl = data.passportScanUrl || null;
            this.passportNo = data.passportNo || null;
            this.passportExpiryDate = this.toDate(data.passportExpiryDate);
            this.passportIssueDate = this.toDate(data.passportIssueDate);

            if (!data.headshot) {
                data.headshot = {};
            }

            //information used only for creating new persons
            this.newPersonDominantHand = null;
            this.newPersonDominantFoot = null;
            this.newPersonRoleTypeId = null;
            this.newPersonDateFrom = null;
            this.newPersonDateUntil = null;
            this.newPersonTeam = null;
            this.newPersonHeight = null;
            this.newPersonWeight = null;
            this.newPersonSchoolEqId = null;
            this.newPersonUnionEqId = null;

            //player relevant information
            const currentPlayerRole = this.getCurrentPlayerRole();
            if (currentPlayerRole !== undefined) {
                this.height = currentPlayerRole.height || currentPlayerRole.playingHeight || null;
                this.weight = currentPlayerRole.weight || currentPlayerRole.playingWeight || null;
                this.hand = currentPlayerRole.dominantHand || null;
                this.foot = currentPlayerRole.dominantFoot || null;
            }

            //biography from top service
            if (!data.bio) {
                data.bio = {};
            }
            this.bestAdviceGiven = data.bio.bestPeaceOfAdvice || data.bestAdviceGiven || null;
            this.favouriteAthletes = data.bio.inspirationAthelets || data.favouriteAthletes || null;
            this.favouriteFood = data.bio.favoriteFood || data.favouriteFood || null;
            this.favouriteMovie = data.bio.favoriteMovie || data.favouriteMovie || null;
            this.favouriteSeriesLocation = data.bio.favoriteSeriesLocation || data.favouriteSeriesLocation || null;
            this.highlightsCareer = data.bio.highlightOfCarrer || data.highlightsCareer || null;
            this.namePronunciation = data.bio.nameOfPrononciation || data.namePronunciation || null;
            this.nickname = data.bio.nickname || data.nickname || null;
            this.rugbyFirstSteps = data.bio.worldSeriesDebut || data.rugbyFirstSteps || null;
            this.rugbyPlayingFamily = data.bio.otherPlayingFamilyMembers || data.rugbyPlayingFamily || null;
            this.startPlaying = data.bio.startsPlayerRugbyAt || data.startPlaying || null;
            this.tournamentsPlayedPoints =
                data.bio.numberOfTournamentsPlayerdPoints || data.tournamentsPlayedPoints || null;
            this.biographyStatus = data.bio.status || null;

            this.height = this.height || data.bio.height || null;
            this.weight = this.weight || data.bio.weight || null;
            this.hand = this.hand || data.bio.handDexterity || null;
            this.foot = this.foot || data.bio.footDexterity || null;

            //personally identifiable information from top service
            if (!data.pii) {
                data.pii = {};
            }
            this.passportNo = data.pii.passportNo || null;
            this.passportExpiryDate = this.toDate(data.pii.passportExpiryDate);
            this.passportIssueDate = this.toDate(data.pii.passportIssueDate);
            this.passportUploadDate = this.toDate(data.pii.passportUploadDate);
            this.passportStatus = data.pii.status || null;
            this.nationalityCountry = data.pii.nationalityCountry || null;
            this.nationalityCountryIsoCode =
                data.pii.nationalityCountryIsoCode || data.pii.nationalityCountryIso || null;
            this.passportFirstName = data.pii.firstName || null;
            this.passportLastName = data.pii.lastName || null;
            if (!this.firstName && data.pii.firstName) {
                this.firstName = data.pii.firstName;
            }
            if (!this.lastName && data.pii.lastName) {
                this.lastName = data.pii.lastName;
            }

            // medical certificate
            if (!data.medicalCertificate) {
                data.medicalCertificate = {};
            }
            this.medicalCertificateUrl = data.medicalCertificate.certificateUrl || null;
            this.medicalCertificateUploadDate = this.toDate(data.medicalCertificate.certificateUploadDate);
            this.medicalCertificateStatus = data.medicalCertificate.status || null;

            this.unavailable = data.unavailable || false;

            //declaration information
            this.status = data.status || null;
            this.headshotStatus = data.headshot.status || null;
            this.usingDeclarationStorage = data.usingDeclarationStorage || false;

            //files
            this.setHeadshotStorage(
                data.headshot.headshotUrl || data.headshotImageUrl || data.headshotUrl || data.imageUrl || ''
            );
            this.setPassportStorage(data.pii.passportUrl || data.pii.passportScanUrl || data.passportScanUrl || '');
            this.setMedicalCertificateStorage(
                data.medicalCertificate.certificateUrl || data.medicalCertificateUrl || ''
            );
        }
    }

    getAge(comparisonDate = new Date()) {
        if (this.dateOfBirth) {
            const diff = new Date(comparisonDate.getTime() - this.dateOfBirth.getTime());
            return Math.abs(diff.getUTCFullYear() - 1970);
        }

        if (this.age) {
            return parseInt(this.age, 10);
        }

        return 0;
    }

    getDateOfBirthAndAge(vue = null) {
        const age = this.getAge();
        const dateOfBirth = this.dateOfBirth;
        return { age, dateOfBirth };
    }

    isMinor(comparisonDate = new Date()) {
        if (!this.position || this.position.grouping !== 'Front Row') {
            return false;
        }

        return this.isUnderage(comparisonDate);
    }

    /**
     * This method is used to calculate whether a player is under 19.
     * Since the position data lives with the Match, the above function isMinor() cannot be used outside of matches.
     */
    isUnderage(comparisonDate = new Date()) {
        const age = this.getAge(comparisonDate);
        return age && age < 19;
    }

    setHeadshotStorage(headshotStorage) {
        this.headshotStorage = headshotStorage || null;

        if (this.usingDeclarationStorage) {
            this.headshot = Model._api.call.termsOfParticipation.resolveStoragePath(this.headshotStorage);
        } else {
            this.headshot = Model._api.call.person.resolveStoragePath(this.headshotStorage);
        }
    }

    setPassportStorage(passportStorage) {
        this.passportStorage = passportStorage || null;

        if (this.usingDeclarationStorage) {
            this.passport = Model._api.call.termsOfParticipation.resolveStoragePath(this.passportStorage);
        } else {
            this.passport = Model._api.call.person.resolveStoragePath(this.passportStorage);
        }
    }

    setMedicalCertificateStorage(medicalCertificateStorage) {
        this.medicalCertificateStorage = medicalCertificateStorage || null;

        if (this.usingDeclarationStorage) {
            this.medicalCertificate = Model._api.call.termsOfParticipation.resolveStoragePath(
                this.medicalCertificateStorage
            );
        } else {
            this.medicalCertificate = Model._api.call.person.resolveStoragePath(this.medicalCertificateStorage);
        }
    }

    /**
     * get person name.
     * returns array of name segments if segmented is enabled.
     *
     * @param {boolean} [segmented]
     *
     * @returns {string|string[]}
     */
    getName(segmented) {
        if (this.displayName) {
            if (segmented) {
                return [this.displayName];
            }

            return this.displayName;
        }

        if (segmented) {
            const arr = [];
            if (this.firstName) {
                arr.push(this.firstName);
            }
            if (this.lastName) {
                arr.push(this.lastName);
            }
            return arr;
        }

        return this.firstName + (this.firstName ? ' ' : '') + this.lastName;
    }

    getCurrentRole() {
        if (!this.roles || (this.roles && !this.roles.length)) {
            return null;
        }

        this.roles = this.roles.sort((a, b) => {
            if (!a.dateUntil) {
                return -1;
            }
            return new Date(b.dateUntil).getFullYear() - new Date(a.dateUntil).getFullYear();
        });

        this.roles = this.roles.sort((a, b) => {
            if (new Date(a.dateUntil).getFullYear() === new Date(b.dateUntil).getFullYear()) {
                if (a.teamType && a.teamType === 'Country') {
                    return -1;
                }
            }
            return 0;
        });

        return this.roles[0];
    }

    getCurrentRoleByType(roleType, unionEqId = null, isByUnionOnly = false) {
        if (!this.roles) {
            return null;
        }

        const orderedByDateUntil = this.roles.sort((a, b) => {
            if (!a.dateUntil) {
                return -1;
            }
            return new Date(b.dateUntil).getFullYear() - new Date(a.dateUntil).getFullYear();
        });

        const roleFound = orderedByDateUntil.find(
            (element) => element.roleType === roleType && (!unionEqId || element.unionEqId === unionEqId)
        );

        if (!roleFound && !isByUnionOnly) {
            return orderedByDateUntil.find((element) => element.roleType === roleType);
        }

        return roleFound;
    }

    getRoleByDate(roleType, targetDate) {
        if (!this.roles) {
            return null;
        }

        const orderedByDateUntil = this.roles.sort((a, b) => {
            if (!a.dateUntil) {
                return -1;
            }
            return new Date(b.dateUntil).getFullYear() - new Date(a.dateUntil).getFullYear();
        });

        return orderedByDateUntil.find(
            (role) =>
                role.roleType === roleType &&
                role.dateFrom <= targetDate &&
                (role.dateUntil === null || role.dateUntil >= targetDate)
        );
    }

    getCurrentPlayerRole() {
        const playerRoles = this.roles.filter((role) => role.roleType === 'Player');
        return playerRoles[playerRoles.length - 1];
    }

    getActiveRoles() {
        return this.roles.filter((role) => !role.isRetired);
    }

    getActiveTeams() {
        const today = new Date();

        return this.getActiveRoles()
            .map((role) => (role.dateFrom <= today && role.dateUntil >= today ? role.teamName : ''))
            .filter((teamName) => teamName)
            .join(', ');
    }

    getActiveUnions() {
        const today = new Date();

        return this.getActiveRoles()
            .map((role) => (role.dateFrom <= today && role.dateUntil >= today ? role.unionName : ''))
            .filter((unionName) => unionName)
            .join(', ');
    }

    /**
     * get person image path
     *
     * @returns {string}
     */
    getImgPath() {
        return this.headshot || require('@/assets/img/player.svg');
    }

    setPosition(position) {
        this.position = PositionModel.from(position) || {};
    }

    getPositionName() {
        if (this.position && (this.position.id || this.position.eqId)) {
            return this.position.getPositionName();
        }

        return this.positionName || '-';
    }

    setJerseyNumber(jerseyNumber) {
        if (!this.playerAttributes) {
            this.playerAttributes = {};
        }
        this.playerAttributes.shirtNumber = jerseyNumber;
    }

    /**
     * get person id
     *
     * models that extend person model the id is not the person id which is used for detail links
     * if a child model has a role obtain the person id
     *
     * @returns {number}
     */
    getPersonId() {
        if (this.personId) {
            return this.personId;
        }

        if (this.roles.length) {
            const currentRole = this.getCurrentRole();
            if (currentRole && currentRole.personId) {
                return currentRole.personId;
            }
        }

        return this.id;
    }

    useDeclarationStorage(status = true, isHeadshotModule = true) {
        if (!isHeadshotModule) {
            const roleWithheadshotUrl = this.roles?.find((role) => role.imageUrl) || null;
            if (roleWithheadshotUrl) {
                this.usingDeclarationStorage = false;
                this.setHeadshotStorage(roleWithheadshotUrl.imageUrl);
            }
        }

        this.usingDeclarationStorage = Boolean(status);

        if (isHeadshotModule) {
            this.setHeadshotStorage(this.headshotStorage);
        }
        this.setPassportStorage(this.passportStorage);
        this.setMedicalCertificateStorage(this.medicalCertificateStorage);
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null) {
        return this.getName();
    }

    toJSON() {
        const toSave = {};

        toSave.person = {
            gender: this.gender || null,
            displayName: this.displayName || null,
            surname: this.lastName || null,
            knownAsSurname: this.knownAsLastname || null,
            forenames: this.firstName || null,
            knownAsForenames: this.knownAsFirstname || null,
            initials: this.initials || null,
            maidenName: this.maidenName || null,
            nativeKnownAsSurname: this.nativeKnownAsLastname || null,
            nativeKnownAs: this.nativeKnownAsFirstname || null,
            dateOfBirth: this.fromDate(this.dateOfBirth),
            dateOfDeath: this.fromDate(this.dateOfDeath),
            placeOfBirthLocationEqId:
                (this.placeOfBirth ? this.placeOfBirth.eqId : null) || this.locationPlaceOfBirthEqId || null,
            isPlayerOfFinal: this.hasBeenPlayerOfFinal || false,
            isImpactPlayer: this.hasBeenImpactPlayer || false,
            isRetired: this.isRetired || false,
            hallOfFameInducted: this.hallOfFameInducted ? this.fromDate(this.hallOfFameInducted) : null || null,
            order: this.order || 0,
            prefix: this.prefix || null,
            suffix: this.suffix || null,
            emailAddress: this.emailAddress || null,
            lionsNumber: this.lionsNumber || null
        };

        toSave.passport = {
            passportScanUrl: this.passportStorage || null,
            passportId: this.passportNo || null,
            passportExpiryDate: this.fromDate(this.passportExpiryDate),
            passportIssueDate: this.fromDate(this.passportIssueDate)
        };

        toSave.personHeadshot = {
            personHeadshotScanUrl: this.headshotStorage || null
        };

        toSave.personRole = {
            roleType: this.newPersonRoleTypeId || null,
            dateFrom: this.fromDate(this.newPersonDateFrom),
            dateUntil: this.fromDate(this.newPersonDateUntil),
            teamEqId:
                (this.newPersonRoleTypeId !== 'Official' && this.newPersonTeam ? this.newPersonTeam.eqId : null) ||
                null,
            unionEqId:
                (this.newPersonRoleTypeId === 'Official' && this.newPersonUnionEqId ? this.newPersonUnionEqId : null) ||
                null
        };

        toSave.personSchool = {
            schoolEqId: this.newPersonSchoolEqId || null
        };

        toSave.bio = {
            footDexterity: this.foot || null,
            handDexterity: this.hand || null,
            weight: this.weight || null,
            height: this.height || null,
            numberOfTournamentsPlayerdPoints: Number(this.tournamentsPlayedPoints) || null,
            nameOfPrononciation: this.namePronunciation || null,
            favoriteMovie: this.favouriteMovie || null,
            favoriteFood: this.favouriteFood || null,
            bestPeaceOfAdvice: this.bestAdviceGiven || null,
            otherPlayingFamilyMembers: this.rugbyPlayingFamily || null,
            inspirationAthelets: this.favouriteAthletes || null,
            favoriteSeriesLocation: this.favouriteSeriesLocation || null,
            highlightOfCarrer: this.highlightsCareer || null,
            worldSeriesDebut: this.rugbyFirstSteps || null,
            startsPlayerRugbyAt: this.startPlaying || null,
            nickname: this.nickname || null
        };

        if (this.newPersonRoleTypeId === 'Player') {
            toSave.playerAttributes = {
                playingHeight: this.newPersonHeight || null,
                playingWeight: this.newPersonWeight || null,
                dominantHand: this.newPersonDominantHand || null,
                dominantFoot: this.newPersonDominantFoot || null
            };
        }

        if (this.representing) {
            toSave.representing = this.representing.map((item) => {
                return {
                    teamName: item.teamName,
                    teamAliasEqId: item.teamAliasEqId
                };
            });
        }

        if (this.nationalityCountryIsoCode) {
            toSave.country = {
                countryCode: this.nationalityCountryIsoCode
            };
        }

        return this.removeObjectsWherePropertiesAreNull(toSave);
    }

    toTopPersonUpdateJSON() {
        return {
            headshotUrl: this.headshotStorage || null,
            isCaptain: this.playerAttributes?.isCaptain || null,
            unavailable: this.unavailable || false,
            jerseyNumber: this.playerAttributes?.shirtNumber || null,
            replacedById: this.replacedById || null,
            positionEqId: this.position?.eqId || null,
            order: this.order || null,
            hasLeftSquad: this.hasLeftSquad || false,
            isLoosehead: this.isLoosehead || false,
            isHooker: this.isHooker || false,
            isTighthead: this.isTighthead || false
        };
    }

    buildMappingPayloads() {
        if (!this.mappingProviders || !this.mappingProviders.length) {
            return [];
        }

        return this.mappingProviders.map((mappingProvider) => ({
            provider: mappingProvider.name,
            entity: constants.mappingEntityNames.person,
            id: this[mappingProvider.name.toLowerCase()],
            eqId: this.eqId
        }));
    }
}
