import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonVenueIndex from '@/views/common/venue/index.vue';
import commonVenueCreate from '@/views/common/venue/create.vue';
import commonVenueDetail from '@/views/common/venue/detail.vue';
import commonVenueEdit from '@/views/common/venue/edit.vue';

export default {
    path: '/venue/',
    component: pageFrame,
    children: [
        {
            name: 'commonVenueIndex',
            path: constants.routePaths.index,
            component: commonVenueIndex,
            meta: {
                permission: permissions.venues.list,
            },
        },
        {
            name: 'commonVenueCreate',
            path: constants.routePaths.create,
            component: commonVenueCreate,
            meta: {
                permission: permissions.venues.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.venueId),
            component: pageFrame,
            children: [
                {
                    name: 'commonVenueDetail',
                    path: constants.routePaths.index,
                    component: commonVenueDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.venueId]),
                    meta: {
                        permission: permissions.venues.detail,
                    },
                },
                {
                    name: 'commonVenueEdit',
                    path: constants.routePaths.edit,
                    component: commonVenueEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.venueId]),
                    meta: {
                        permission: permissions.venues.update,
                    },
                },
            ],
        },
    ],
};
