<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editVenue')"
            v-bind:waitFor="apiData.venue"
            v-bind:waitForRetry="reloadVenue"
            v-on:submit="updateVenue"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toVenueDetail"
                    v-bind:loadingWhile="apiData.venueUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.venueUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || mappingWarning">
                <validations
                    v-bind:warnings="mappingWarning ? [mappingWarning] : undefined"
                    v-bind:errors="error.getErrorMessages($t('requestError'))"
                    v-if="error || mappingWarning"/>
            </template>
            <template v-slot>
                <editVenueForm
                    v-bind:headline="$t('venueInformation')"
                    v-bind:model="venue"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingEditMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import editVenueForm from '@/components/forms/editVenue.vue';

import VenueModel from '@/models/venue.js';

export default {
    name: 'commonVenueEdit',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingEditMixins,
    ],
    components: {
        validations,
        editVenueForm,
    },
    props: {
        dataVenueId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                venue: this.loadVenue(),
                venueUpdate: null,
            },
            venue: null,
            error: null,
            mappingWarning: null,
        };
    },
    computed: {
        toVenueDetail(){
            return { name: 'commonVenueDetail', params: { dataVenueId: this.dataVenueId } };
        },
    },
    methods: {
        loadVenue(){
            return this.$api.call.location.venueGetById(this.dataVenueId, false).then(data => {
                this.venue = new VenueModel(data);

                //get mapping providers
                return this.loadEntityMappingProviders(this.venue);
            });
        },
        reloadVenue(){
            this.apiData.venue = this.loadVenue();
        },
        updateVenue(){
            let queue = Promise.resolve();

            //update team
            queue = queue.then(() => this.$api.call.location.venueUpdateById(this.dataVenueId, this.venue.toJSON()));

            //update mappings
            queue = this.manageMappings(this.venue, queue);

            queue = queue.then(() => {
                this.error = null;
                this.$router.push(this.toVenueDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('venue update failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.venueUpdate = queue;
        },
    },
}
</script>
