import Model from '@/models/model.js';
import DeclarationTOPModel from '@/models/declarationTOP.js';
import UploadModel from '@/models/upload.js';
import WorkflowSummaryModel from '@/models/workflowsummary.js';

import constants from '@/constants.js';

/**
 * declaration model class
 * @abstract
 */
export default class DeclarationModel extends Model {
    static steps = {
        main: 'main',
        preliminarySquad: 'preliminary-squad',
        squad: 'squad',
    };

    /**
     * declaration configuration
     *
     * @type {object}
     */
    configuration = null;

    /**
     * configuration template
     *
     * @type {object}
     */
    template = null;

    /**
     * declaration step type
     *
     * @type {string}
     */
    step = 'main';

    /**
     * declaration step count
     *
     * @type {int}
     */
    stepCount = 0;

    /**
     * declaration step total count
     *
     * @type {int}
     */
     stepTotalCount = 0;

    /**
     * step config target paths
     *
     * @type {object}
     */
    configTargets = {
        squad: 'squad',
        squadPersonHeadshots: 'squad.person-headshots',
        squadPassportInformation: 'squad.passport-information',
        squadMedicalCertificates: 'squad.medical-certificates',
        squadBio: 'squad.bio',
        squadPersonPosition: 'squad.person-position',
        preliminarySquad: 'preliminary-squad',
        preliminarySquadPersonHeadshots: 'preliminary-squad.person-headshots',
        preliminarySquadPassportInformation: 'preliminary-squad.passport-information',
        preliminarySquadMedicalCertificates: 'preliminary-squad.medical-certificates',
        preliminarySquadBio: 'preliminary-squad.bio',
        preliminarySquadPersonPosition: 'preliminary-squad.person-position',
        participationAgreement: 'participation-agreement',
        teamLogo: 'team-logo',
        nationalAnthem: 'national-anthem',
        medicalInsuranceCover: 'medical-insurance-cover',
        teamDisciplinaryCertificate: 'team-disciplinary-certificate',
        teamMemberAcceptanceForm: 'team-member-acceptance-form',
        teamPhoneticGuide: 'phonetic-guide',
        kit: 'kit',
    };

    configAttributes = {
        deadlineDate: 'deadline-date',
        templateUrl: 'template-url',
    };

    configProperties = {
        id: '.id',
        name: '.name',
        status: '.status',
        required: '.required',
        file: '.file',
    };

    configurationAttributeMap = [
        {
            conditionProperty: 'squadEnabled',
            target: this.configTargets.squad,
            attributes: {
                'deadline-date': 'squadDeadline',
                'minimum-number-of-players': 'squadMinimumNumberOfPlayers',
                'maximum-number-of-players': 'squadMaximumNumberOfPlayers',
                'minimum-number-of-managers': 'squadMinimumNumberOfManagers',
                'maximum-number-of-managers': 'squadMaximumNumberOfManagers',
                'minimum-number-of-medics': 'squadMinimumNumberOfMedics',
                'maximum-number-of-medics': 'squadMaximumNumberOfMedics',
                'minimum-total-number-of-management': 'squadMinimumTotalNumberOfManagement',
                'maximum-total-number-of-management': 'squadMaximumTotalNumberOfManagement',
            },
            attributeExtras: {
                'deadline-date': ['squadDeadlineTime', 'squadDeadlineGmtOffset'],
            },
        },
        {
            conditionProperty: 'squadPersonHeadshotsEnabled',
            target: this.configTargets.squadPersonHeadshots,
            attributes: {
                'deadline-date': 'squadPersonHeadshotsDeadline',
            },
        },
        {
            conditionProperty: 'squadPassportInformationEnabled',
            target: this.configTargets.squadPassportInformation,
            attributes: {
                'deadline-date': 'squadPassportInformationDeadline',
            },
        },
        {
            conditionProperty: 'squadMedicalCertificatesEnabled',
            target: this.configTargets.squadMedicalCertificates,
            attributes: {
                'deadline-date': 'squadMedicalCertificatesDeadline',
            },
        },
        {
            conditionProperty: 'squadBioEnabled',
            target: this.configTargets.squadBio,
            attributes: {
                'deadline-date': 'squadBioDeadline',
            },
        },
        {
            conditionProperty: 'squadPersonPositionEnabled',
            target: this.configTargets.squadPersonPosition,
            attributes: {
                'deadline-date': 'squadPersonPositionDeadline',
            },
        },
        {
            conditionProperty: 'preliminarySquadEnabled',
            target: this.configTargets.preliminarySquad,
            attributes: {
                'deadline-date': 'preliminarySquadDeadline',
                'minimum-number-of-players': 'preliminarySquadMinimumNumberOfPlayers',
                'maximum-number-of-players': 'preliminarySquadMaximumNumberOfPlayers',
                'minimum-number-of-managers': 'preliminarySquadMinimumNumberOfManagers',
                'maximum-number-of-managers': 'preliminarySquadMaximumNumberOfManagers',
                'minimum-number-of-medics': 'preliminarySquadMinimumNumberOfMedics',
                'maximum-number-of-medics': 'preliminarySquadMaximumNumberOfMedics',
                'minimum-total-number-of-management': 'preliminarySquadMinimumTotalNumberOfManagement',
                'maximum-total-number-of-management': 'preliminarySquadMaximumTotalNumberOfManagement',
            },
        },
        {
            conditionProperty: 'preliminarySquadPersonHeadshotsEnabled',
            target: this.configTargets.preliminarySquadPersonHeadshots,
            attributes: {
                'deadline-date': 'preliminarySquadPersonHeadshotsDeadline',
            },
        },
        {
            conditionProperty: 'preliminarySquadPassportInformationEnabled',
            target: this.configTargets.preliminarySquadPassportInformation,
            attributes: {
                'deadline-date': 'preliminarySquadPassportInformationDeadline',
            },
        },
        {
            conditionProperty: 'preliminarySquadMedicalCertificatesEnabled',
            target: this.configTargets.preliminarySquadMedicalCertificates,
            attributes: {
                'deadline-date': 'preliminarySquadMedicalCertificatesDeadline',
            },
        },
        {
            conditionProperty: 'preliminarySquadBioEnabled',
            target: this.configTargets.preliminarySquadBio,
            attributes: {
                'deadline-date': 'preliminarySquadBioDeadline',
            },
        },
        {
            conditionProperty: 'preliminarySquadPersonPositionEnabled',
            target: this.configTargets.preliminarySquadPersonPosition,
            attributes: {
                'deadline-date': 'preliminarySquadPersonPositionDeadline',
            },
        },
        {
            //conditionProperty: 'replacementsEnabled',
            target: this.configTargets.squad,
            attributes: {
                'minimum-number-of-replacement-players': 'replacementsMinimumNumberOfPlayers',
                'maximum-number-of-replacement-players': 'replacementsMaximumNumberOfPlayers',
                'minimum-number-of-replacement-non-players': 'replacementsMinimumNumberOfNonPlayers',
                'maximum-number-of-replacement-non-players': 'replacementsMaximumNumberOfNonPlayers',
            },
            fields: {
                'enable-non-playing-reserve': 'replacementsNonPlayersEnabled',
            },
        },
        {
            conditionProperty: 'participationAgreementEnabled',
            target: this.configTargets.participationAgreement,
            attributes: {
                'deadline-date': 'participationAgreementDeadline',
                'template-url': 'participationAgreementTemplateStorage',
            },
        },
        {
            conditionProperty: 'teamLogoEnabled',
            target: this.configTargets.teamLogo,
            attributes: {
                'deadline-date': 'teamLogoDeadline',
                'template-url': 'teamLogoTemplateStorage',
            },
        },
        {
            conditionProperty: 'nationalAnthemEnabled',
            target: this.configTargets.nationalAnthem,
            attributes: {
                'deadline-date': 'nationalAnthemDeadline',
                'template-url': 'nationalAnthemTemplateStorage',
            },
        },
        {
            conditionProperty: 'medicalInsuranceCoverEnabled',
            target: this.configTargets.medicalInsuranceCover,
            attributes: {
                'deadline-date': 'medicalInsuranceCoverDeadline',
                'template-url': 'medicalInsuranceCoverTemplateStorage',
            },
        },
        {
            conditionProperty: 'teamDisciplinaryCertificateEnabled',
            target: this.configTargets.teamDisciplinaryCertificate,
            attributes: {
                'deadline-date': 'teamDisciplinaryCertificateDeadline',
                'template-url': 'teamDisciplinaryCertificateTemplateStorage',
            },
        },
        {
            conditionProperty: 'teamMemberAcceptanceFormEnabled',
            target: this.configTargets.teamMemberAcceptanceForm,
            attributes: {
                'deadline-date': 'teamMemberAcceptanceFormDeadline',
                'template-url': 'teamMemberAcceptanceFormTemplateStorage',
            },
        },
        {
            conditionProperty: 'teamPhoneticGuideEnabled',
            target: this.configTargets.teamPhoneticGuide,
            attributes: {
                'deadline-date': 'teamPhoneticGuideDeadline',
                'template-url': 'teamPhoneticGuideTemplateStorage',
            },
        },
        {
            conditionProperty: 'kitEnabled',
            target: this.configTargets.kit,
            attributes: {
                'deadline-date': 'kitDeadline',
            },
        },
    ];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.eqId = data.eqId || '';
            this.type = data.type || '';
            this.sport = data.sport || '';
            this.status = data.status || '';
            this.workflowId = data.workflowId || data.declarationWorkflowId || null;
            this.openingDate = this.toDate(data.openingDate);
            this.openingTime = data.openingTime || this.toDateTime(data.openingDate) || '';
            this.openingGmtOffset = data.openingGmtOffset || this.toDateOffset(data.openingDate) || 0;
            this.deadlineDate = this.toDate(data.deadlineDate);
            this.deadlineTime = data.deadlineTime || this.toDateTime(data.deadlineDate) || '';
            this.deadlineGmtOffset = data.deadlineGmtOffset || this.toDateOffset(data.deadlineDate) || 0;
            this.publishOffset = data.publishOffset || data.matchPublishOffset || (data.match || {}).publishOffset || null;
            this.teamsheetsPublishOffset = data.teamsheetsPublishOffset || data.matchTeamsheetsPublishOffset || (data.match || {}).teamsheetsPublishOffset || null;
            this.locked = data.locked || false;

            this.step = data.step || this.step;
            this.steps = data.steps || [];
            this.stepStatus = data.stepStatus || null;
            this.configuration = data.configuration || {};

            this.template = null;
            this.teamDeclaration = data.teamDeclaration || null;
            this.teamDeclarationTwo = data.teamDeclarationTwo || null;
            this.participationAgreement = new UploadModel();
            this.logo = new UploadModel();
            this.nationalAnthem = new UploadModel();
            this.medicalInsuranceCover = new UploadModel();
            this.teamPhoneticGuide = new UploadModel();

            this.players = [];
            this.managers = [];
            this.medics = [];
            this.teamKits = [];
        }
    }

    setTeamDeclaration(teamDeclaration){
        this.teamDeclaration = teamDeclaration;
    }

    getTeamDeclaration(){
        return this.teamDeclaration || {};
    }

    setTeamDeclarationTwo(teamDeclaration){
        this.teamDeclarationTwo = teamDeclaration;
    }

    getTeamDeclarationTwo(){
        return this.teamDeclarationTwo || {};
    }

    getTeam(){
        if(!this.getTeamDeclaration() || !this.getTeamDeclaration().team){
            return null;
        }

        return this.getTeamDeclaration().team;
    }

    checkIsSevens(){
        return Object.values(constants.sevenSport).includes(this.sport);
    }

    getConfigurationSteps(asObject = false, configuration = this.configuration){
        return ((configuration || {}).steps || []).map(data => !asObject ? data.name : {
            id: data.id || null,
            name: data.name || '',
            status: data.status || '',
            deadlineDate: this.toDate(data.deadlineDate),
        });
    }

    getTemplateSteps(asObject = false, template = this.template){
        return this.getConfigurationSteps(asObject, template);
    }

    getSteps(asObject = false){
        return this.getConfigurationSteps(asObject, this);
    }

    getCurrentStep(configuration = this.configuration){
        return this.getConfigurationSteps(true, configuration).filter(data => data.status !== constants.declarationStatus.approved).map(data => data.name).shift();
    }

    getStepStatus(){
        return this.getSteps(true).find(step => step.name === this.step)?.status || null;
    }

    getStepDeadlineDate(noFallback = false){
        return this.getSteps(true).find(step => step.name === this.step)?.deadlineDate || (!noFallback ? this.deadlineDate : null) || null;
    }

    findModuleInConfiguration(target, configuration = this.configuration){
        const targetSteps = target ? target.split('.') : [];

        const steps = (configuration || {}).steps || [];
        const step = steps.filter(data => data.name === this.step).shift() || {};

        //search for module in config tree
        let currentType;
        let currentItem = step;
        while(targetSteps.length){
            currentType = targetSteps.shift();
            currentItem = (currentItem.modules || []).filter(data => data.name === currentType).shift();
            if(!currentItem){
                return null;
            }
        }

        return currentItem;
    }

    findInConfiguration(target, configuration = this.configuration){
        const targetSteps = target ? target.split('.') : [];
        const key = targetSteps.pop();
        if(!key){
            return null;
        }

        //find module
        const module = this.findModuleInConfiguration(targetSteps.join('.'), configuration);
        if(!module){
            return null;
        }

        //if looking for id, status or fields, directly return them from the module data
        if(['id', 'name', 'status', 'required', 'attributes', 'fields'].includes(key)){
            return module[key];
        }

        //search in module attributes
        const item = (module.attributes || []).filter(data => data.name === key).shift();
        if(!item){
            return null;
        }
        return item.value;
    }

    findInConfigurationAttributes(target, attributeName, configuration = this.configuration){
        const attributes = this.findInConfiguration(target + '.attributes') || [];
        const attribute = attributes.filter(data => data.name === attributeName).shift();

        if(attribute){
            return attribute.value;
        }
        return undefined;
    }

    findInConfigurationFields(target, fieldName, configuration = this.configuration){
        const fields = this.findInConfiguration(target + '.fields') || [];
        const field = fields.filter(data => data.name === fieldName).shift();

        if(field){
            if(field.value === 'false'){
                return false;
            }

            return field.value;
        }
        return undefined;
    }

    existsInConfig(target, step = null, configuration = this.configuration){
        //get for step if defined
        const currentStep = this.step;
        if(step){
            this.step = step;
        }

        const result = Boolean(this.findModuleInConfiguration(target, configuration));

        if(step){
            this.step = currentStep;
        }

        return result;
    }

    existsInTemplate(target, step = null, template = this.template){
        return this.existsInConfig(target, step, template);
    }

    setConfigurationAttribute(target, attributeName, value = null, extraValues = null, configuration = this.configuration){
        //find module attributes
        const attributes = this.findInConfiguration(target + '.attributes', configuration);
        if(!attributes){
            return false;
        }

        //find attribute item
        const attribute = attributes.filter(data => data.name === attributeName).shift();

        //set if found
        if(attribute){
            //convert dates for api
            if(value instanceof Date){
                if(extraValues && extraValues.length >= 2){
                    value = this.fromDateTime(value, extraValues[0], extraValues[1]);
                }
                else {
                    value = this.fromDate(value);
                }
            }

            //api needs numbers and boolean as strings
            if(Number.isInteger(value) || typeof value === 'boolean'){
                value = JSON.stringify(value);
            }

            attribute.value = value;
        }
        else {
            return false;
        }

        return true;
    }

    setConfigurationField(target, fieldName, value, configuration = this.configuration){
        //find module fields
        const fields = this.findInConfiguration(target + '.fields', configuration);
        if(!fields){
            return false;
        }

        //find field item
        const field = fields.filter(data => data.name === fieldName).shift();

        //api needs numbers and boolean as strings
        if(Number.isInteger(value) || typeof value === 'boolean'){
            value = JSON.stringify(value);
        }

        //set if found, add if not
        if(field){
            field.value = value;
        }
        else {
            fields.push({
                name: fieldName,
                value,
            });
        }

        return true;
    }

    removeFromConfiguration(target, configuration = this.configuration){
        const targetSteps = target.split('.');
        const key = targetSteps.pop();

        //find parent module
        const parent = this.findModuleInConfiguration(targetSteps.join('.'), configuration);
        if(!parent || !parent.modules){
            return false;
        }

        //remove item from parent module children
        const count = parent.modules.length;
        parent.modules = parent.modules.filter(module => module.name !== key);

        //return true if something has been removed
        return (count > parent.modules.length);
    }

    /**
     *
     * @param {DeclarationTOPModel|DeclarationTOPModel[]} declarationTOPs
     * @param {boolean} [returnResult]
     * @param {object} [template]
     *
     * @returns {boolean|object}
     */
    generateConfiguration(declarationTOPs, returnResult = false, template = this.template){
        if(!template){
            return false;
        }

        if(!(declarationTOPs instanceof Array)){
            declarationTOPs = [declarationTOPs];
        }

        const configuration = JSON.parse(JSON.stringify(template));

        declarationTOPs.forEach(declarationTOP => {
            //import for corresponding step
            const currentStep = this.step;
            if(declarationTOP.step){
                this.step = declarationTOP.step;
            }

            //import form data into new config
            this.configurationAttributeMap.forEach(data => {
                if(!data.conditionProperty || declarationTOP[data.conditionProperty]){
                    Object.entries(data.attributes || {}).forEach(([attribute, property]) => {
                        const extraValues = ((data.attributeExtras || {})[attribute] || []).map(prop => declarationTOP[prop]);
                        return this.setConfigurationAttribute(data.target, attribute, declarationTOP[property], extraValues, configuration);
                    });
                    Object.entries(data.fields || {}).forEach(([field, property]) => {
                        return this.setConfigurationField(data.target, field, declarationTOP[property], configuration);
                    });
                }
                else {
                    this.removeFromConfiguration(data.target, configuration);
                }
            });

            //import base form data
            this.openingDate = declarationTOP.openingDate || null;
            this.openingTime = declarationTOP.openingTime || null;
            this.openingGmtOffset = declarationTOP.openingGmtOffset || null;
            this.deadlineDate = declarationTOP.deadlineDate || null;
            this.deadlineTime = declarationTOP.deadlineTime || null;
            this.deadlineGmtOffset = declarationTOP.deadlineGmtOffset || null;
            this.workflowId = declarationTOP.workflowId || null;
            this.publishOffset = declarationTOP.publishOffset || null;
            this.teamsheetsPublishOffset = declarationTOP.teamsheetsPublishOffset || null;

            //revert step
            if(declarationTOP.step){
                this.step = currentStep;
            }
        });

        //return new config instead of saving it if needed
        if(returnResult){
            return configuration;
        }

        this.configuration = configuration;
        return true;
    }

    /**
     * create DeclarationTOPModel for form usage from declaration data
     *
     * @param {string|null} [step]
     * @param {object}      [configuration]
     *
     * @returns {DeclarationTOPModel}
     */
    getConfigurationFormModel(step = null, configuration = this.configuration){
        const formData = {};

        //export for corresponding step
        const currentStep = this.step;
        if(step){
            this.step = step;
        }

        //export data from config
        this.configurationAttributeMap.forEach(data => {
            if(data.conditionProperty){
                formData[data.conditionProperty] = this.existsInConfig(data.target, null, configuration);
            }
            Object.entries(data.attributes || {}).forEach(([attribute, property]) => {
                formData[property] = this.findInConfigurationAttributes(data.target, attribute, configuration);
            });
            Object.entries(data.fields || {}).forEach(([field, property]) => {
                formData[property] = this.findInConfigurationFields(data.target, field, configuration);
            });
        });

        //base data
        formData.enabled = Boolean(this.id);
        formData.openingDate = this.openingDate;
        formData.openingTime = this.openingTime;
        formData.openingGmtOffset = this.openingGmtOffset;
        formData.deadlineDate = this.deadlineDate;
        formData.deadlineTime = this.deadlineTime;
        formData.deadlineGmtOffset = this.deadlineGmtOffset;
        formData.workflowId = this.workflowId;
        formData.publishOffset = this.publishOffset;
        formData.teamsheetsPublishOffset = this.teamsheetsPublishOffset;
        formData.step = this.step;

        //revert step
        if(step){
            this.step = currentStep;
        }

        return DeclarationTOPModel.from(formData);
    }

    /**
     * adjust targets for step differences
     *
     * @param {string} target
     *
     * @returns {string}
     */
    getStepTarget(target, step = this.step){
        return target.split('.').map(segment => {
            if(step === DeclarationModel.steps.preliminarySquad){
                switch(segment){
                    case this.configTargets.squad:
                        return this.configTargets.preliminarySquad;

                    case this.configTargets.squadPersonHeadshots:
                        return this.configTargets.preliminarySquadPersonHeadshots;

                    case this.configTargets.squadPassportInformation:
                        return this.configTargets.preliminarySquadPassportInformation;

                    case this.configTargets.squadMedicalCertificates:
                        return this.configTargets.preliminarySquadMedicalCertificates;

                    case this.configTargets.squadBio:
                        return this.configTargets.preliminarySquadBio;

                    case this.configTargets.squadPersonPosition:
                        return this.configTargets.preliminarySquadPersonPosition;
                }
            }

            return segment;
        }).join('.');
    }

    errorIdParser(errorIds){
        //for all ids
        return errorIds.map(id => {
            //remove dollar bs from id
            id = id.replace(/^\$\./g, '');

            //if its configuration related, do some mapping
            const searchFor = 'configuration.steps';
            if(id.substring(0, searchFor.length) === searchFor){
                //split parts and remove the first and last due to them being irrelevant here
                const parts = id.split('.');
                parts.shift();
                parts.pop();

                //loop id parts
                let part;
                let currentItem = this.configuration;
                const targetParts = [];
                while(parts.length){
                    //parse part
                    part = parts.shift();
                    const [key, i] = part.replace(']', '').split('[').map((value, i) => i > 0 ? Number(value) : value);
                    if(!currentItem || !currentItem[key] || !(currentItem[key] instanceof Array) || isNaN(i) || !currentItem[key][i]){
                        return '';
                    }

                    //add together target information
                    if(key !== 'steps' && currentItem.name !== this.step){
                        targetParts.push(currentItem.name);
                    }

                    currentItem = currentItem[key][i];
                }

                //map target using configTargets
                const configTarget = targetParts.join('.');
                const target = (Object.entries(this.configTargets).filter(([key, target]) => target === configTarget).shift() || []).shift();
                if(!target){
                    return '';
                }

                //map target using configurationAttributeMap
                const targetMap = this.configurationAttributeMap.filter(data => data.target === target).shift();
                if(!targetMap){
                    return '';
                }

                //find attribute map information in configurationAttributeMap data
                return targetMap.attributes[currentItem.name] || '';
            }

            //do the usual
            id = id.split('.').pop();
            return id.charAt(0).toLowerCase() + id.slice(1);
        });
    }

    getParticipationAgreementTemplate(){
        const storagePath = this.findInConfiguration(`${this.configTargets.participationAgreement}.${this.configAttributes.templateUrl}`);
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    getTeamLogoTemplate(){
        const storagePath = this.findInConfiguration(`${this.configTargets.teamLogo}.${this.configAttributes.templateUrl}`);
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    getNationalAnthemTemplate(){
        const storagePath = this.findInConfiguration(`${this.configTargets.nationalAnthem}.${this.configAttributes.templateUrl}`);
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    getMedicalInsuranceCoverTemplate(){
        const storagePath = this.findInConfiguration(`${this.configTargets.medicalInsuranceCover}.${this.configAttributes.templateUrl}`);
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    getTeamDisciplinaryCertificateTemplate(){
        const storagePath = this.findInConfiguration(`${this.configTargets.teamDisciplinaryCertificate}.${this.configAttributes.templateUrl}`);
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    getTeamMemberAcceptanceFormTemplate(){
        const storagePath = this.findInConfiguration(`${this.configTargets.teamMemberAcceptanceForm}.${this.configAttributes.templateUrl}`);
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    getTeamPhoneticGuideTemplate(){
        const storagePath = this.findInConfiguration(`${this.configTargets.teamPhoneticGuide}.${this.configAttributes.templateUrl}`);
        return Model._api.call.termsOfParticipation.resolveStoragePath(storagePath) || null;
    }

    generateWorkflowSummaries(config){
        const workflowSummaries = [];

        config.forEach(data => {
            if(!this.existsInConfig(data.target)){
                return;
            }

            workflowSummaries.push(new WorkflowSummaryModel({
                name: data.name,
                status: this.getTeamDeclaration().findInConfiguration(data.target + this.configProperties.status),
                deadline: this.findInConfigurationAttributes(data.target, this.configAttributes.deadlineDate),
                main: true,
            }));

            (data.children || []).forEach(childData => {
                if(!this.existsInConfig(childData.target)){
                    return;
                }

                workflowSummaries.push(new WorkflowSummaryModel({
                    name: childData.name,
                    status: this.getTeamDeclaration().findInConfiguration(childData.target + this.configProperties.status),
                    deadline: this.findInConfigurationAttributes(childData.target, this.configAttributes.deadlineDate),
                }));
            });
        });

        return workflowSummaries;
    }

    isApproved(){
        return (this.findInConfiguration(this.configProperties.status, this) === 'Approved');
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return '';
    }

    toJSON(){
        return {
            declarationWorkflowId: this.workflowId,
            openingDate: this.fromDateTime(this.openingDate, this.openingTime, this.openingGmtOffset),
            deadlineDate: this.fromDateTime(this.deadlineDate, this.deadlineTime, this.deadlineGmtOffset),
            configuration: this.configuration,
        };
    }
}
