<template>
    <div class="pageContent">
        <tournamentList
            v-bind:title="$tc('tournament', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.tournaments.delete"
            v-bind:teamEqId="teamEqId"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'commonTournamentCreate' }"
                >
                    {{ $t('createTournament') }}
                </actionButton>
            </template>
        </tournamentList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import tournamentList from '@/components/lists/tournament.vue';

export default {
    name: 'commonTournamentIndex',
    extends: base,
    components: {
        tournamentList,
    },
    props: {
        teamEqId: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            rowProps: {
                showDateRange: true,
                showRemove: true,
            },
        };
    },
};
</script>
