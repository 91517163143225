<script>
import pickModal from '@/components/modals/pick.vue';
import matchList from '@/components/lists/match.vue';

/**
 * match pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'matchPickModal',
    extends: pickModal,
    components: {
        matchList,
    },
    props: {
        showCancel: {
            type: Boolean,
            required: false,
            default: true,
        },
        showClearSelection: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data(){
        return {
            listElement: matchList,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showScore: true,
                showDetail: true,
                showPublishStatus: true,
                showRemove: false,
                showSelect: true,
            };
        },
    },
};
</script>
