<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createPerson')"
            v-bind:waitFor="apiData.mappingProviders"
            v-bind:waitForRetry="reloadMappingProviders"
            v-on:submit="createPerson"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons="{ bottomPosition }">
                <div class="buttonContent" v-if="bottomPosition">
                    <b-form-checkbox
                        v-model="createAnother"
                        class="float-left"
                    >
                        {{ $t('createAnother', { type: $tc('person', 1) }) }}
                    </b-form-checkbox>
                </div>

                <actionButton
                    v-bind:to="toPersonList"
                    v-bind:loadingWhile="apiData.personCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.personCreation"
                >
                    {{ $t('createPerson') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || mappingWarning">
                <validations
                    v-bind:warnings="mappingWarning ? [mappingWarning] : undefined"
                    v-bind:errors="error ? error.getErrorMessages($t('requestError')) : []"
                    v-if="error || mappingWarning"/>
            </template>
            <template v-slot>
                <createPersonForm
                    v-bind:headline="$t('generalInformation')"
                    v-bind:model="person"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>

        <confirmModal
            id="confirmForce"
            v-bind:text="confirmForceMessage"
            v-bind:confirmHandler="forceCreatePerson"
            v-if="$areAllowed(permissions.persons.forceCreate.getWithChildren())"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingCreateMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import createPersonForm from '@/components/forms/createPerson.vue';
import confirmModal from '@/components/modals/confirm.vue';

import PersonModel from '@/models/person.js';

export default {
    name: 'commonPersonCreate',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingCreateMixins,
    ],
    components: {
        validations,
        createPersonForm,
        confirmModal,
    },
    data(){
        return {
            apiData: {
                personCreation: null,
            },
            person: PersonModel.from({}),
            error: null,
            createAnother: false,
            mappingWarning: null,
        };
    },
    computed: {
        toPersonList(){
            return { name: 'commonPersonIndex' };
        },
        confirmForceMessage(){
            if(!this.error){
                return '';
            }

            return this.$t('createPersonForce', { errorMsg: this.error.message });
        },
    },
    methods: {
        loadMappingProviders(){
            this.person = PersonModel.from({});

            return this.loadEntityMappingProviders(this.person);
        },
        createPerson(event, force = false){
            let queue = Promise.resolve();

            //check which files chanced and are now File objects, so they can be uploaded
            const fileUploads = {};
            if(this.person._changedProperties.headshot !== undefined && this.person.headshot instanceof File){
                fileUploads.headshot = this.person.headshot;
            }
            if(this.person._changedProperties.passport !== undefined && this.person.passport instanceof File){
                fileUploads.passport = this.person.passport;
            }
            const fileUploadFiles = Object.values(fileUploads);
            //if files have changed, upload to storage and update series file storage information
            if(fileUploadFiles.length){
                queue = queue.then(() => this.$api.call.person.storeFiles(fileUploadFiles)).then(storageFiles => {
                    Object.keys(fileUploads).forEach((key, i) => {
                        const storageFile = storageFiles[i];
                        if(!storageFile){
                            return;
                        }

                        switch(key){
                            case 'headshot':
                                this.person.setHeadshotStorage(storageFile);
                                break;

                            case 'passport':
                                this.person.setPassportStorage(storageFile);
                                break;
                        }
                    });
                }, error => {
                    //set custom file upload validation error
                    if(fileUploads.headshot){
                        error.errors.headshot = this.$t('fileUploadError');
                    }
                    if(fileUploads.passport){
                        error.errors.passport = this.$t('fileUploadError');
                    }

                    throw error;
                });
            }
            //submit person
            if(!this.person.id){
                if(force){
                    queue = queue.then(() => this.$api.call.person.forcePersonCreate(this.person.toJSON()));
                }
                else {
                    queue = queue.then(() => this.$api.call.person.personCreate(this.person.toJSON()));
                }

                queue = queue.then(data => {
                    this.person.id = data.id;
                    this.person.eqId = data.eqId;

                    //create mappings
                    return this.createMappings(this.person);
                });
            }

            //map person to mapping providers
            if(this.person.eqId){
                queue = this.createMappingsUsingQueue(this.person, queue);
            }

            //handle request outcome
            queue = queue.then(() => {
                this.error = null;

                if(this.createAnother){
                    this.loadMappingProviders();
                    this.apiData.personCreation = null;
                }
                else {
                    this.goToPersonDetail(this.person.id);
                }
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('person creation failed', error);
                }

                if(force){
                    throw error;
                }

                //show validation errors
                this.error = error;

                //show force update confirm message on 409 error
                if(error.status === 409 && this.$areAllowed(this.permissions.persons.forceCreate.getWithChildren())){
                    this.$bvModal.show('confirmForce');
                }
            });

            if(force){
                return queue;
            }

            this.apiData.personCreation = queue;
        },
        forceCreatePerson(){
            return this.createPerson(null, true);
        },
        goToPersonDetail(id){
            this.$router.push({ name: 'commonPersonDetail', params: { dataPersonId: id } });
        },
    },
}
</script>
