import constants from '@/constants.js';

import PlayerModel from '@/models/person/player.js';
import ManagerModel from '@/models/person/manager.js';
import MedicModel from '@/models/person/medic.js';
import DocumentAndAudioUploadModel from '@/models/upload/documentAndAudio.js';
import DocumentAndImageUploadModel from '@/models/upload/documentAndImage.js';
import DocumentUploadModel from '@/models/upload/document.js';
import ImageUploadModel from '@/models/upload/image.js';
import AudioUploadModel from '@/models/upload/audio.js';
import SeriesDeclarationModel from '@/models/declaration/series.js';
import TournamentDeclarationModel from '@/models/declaration/tournament.js';
import SeriesModel from '@/models/series.js';
import TournamentModel from '@/models/tournament.js';
import DeclarationTeamModel from '@/models/declaration/team.js';
import TeamKitModel from '@/models/person/teamkit.js';
import MatchModel from '@/models/match.js';
import DeclarationStatusModel from '@/models/declaration/status.js';

export const declarationDetailDisplayMixins = {
    data(){
        return {
            apiData: {
                workflowSummaryUpdate: null,
            },

            declaration: null,
            teamKitPrimaryUpdating: null,
            teamKitSecondaryUpdating: null,
            teamKit: null,
        };
    },
    computed: {
        workflowSummaryConfig(){
            return [
                {
                    target: this.declaration.getStepTarget(this.declaration.configTargets.squad),
                    name: this.$t('squadAnnouncement'),
                    children: [
                        {
                            target: this.declaration.getStepTarget(this.declaration.configTargets.squadPersonHeadshots),
                            name: this.$t('personHeadshots'),
                        },
                        {
                            target: this.declaration.getStepTarget(this.declaration.configTargets.squadPassportInformation),
                            name: this.$t('passportInformation'),
                        },
                        {
                            target: this.declaration.getStepTarget(this.declaration.configTargets.squadMedicalCertificates),
                            name: this.$t('medicalCertificate'),
                        },
                        {
                            target: this.declaration.getStepTarget(this.declaration.configTargets.squadBio),
                            name: this.$t('biographyAndPhoneticGuide'),
                        },
                    ],
                },
            ];
        },
        workflowSummaries(){
            if(!this.declaration){
                return [];
            }

            return this.declaration.generateWorkflowSummaries(this.workflowSummaryConfig);
        },

        players(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            //order players so that the replacement player directly follows the player to replace
            let orderedPlayers = [];
            let players = this.declaration.getTeamDeclaration().getPersons().filter(person => person instanceof PlayerModel);

            (players || []).forEach(player => {
                player.declarationSport = this.declaration.sport;
                this.processPlayerForReplacements(players, player, orderedPlayers);
            });

            return orderedPlayers;
        },
        managers(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return this.declaration.getTeamDeclaration().getPersons().filter(person => person instanceof ManagerModel);
        },
        medics(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return this.declaration.getTeamDeclaration().getPersons().filter(person => person instanceof MedicModel);
        },
        totalManagement(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return 0;
            }

            let total = this.managers?.length || 0;
            total += this.medics?.length || 0;

            return total;
        },

        selectedPlayers(){
            return this.players.map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },
        selectedManagers(){
            return this.managers.map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },
        selectedMedics(){
            return this.medics.map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },

        participationAgreements(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return [
                new DocumentUploadModel({
                    fileId: 'participationAgreement',
                    filePath: this.declaration.getTeamDeclaration().getParticipationAgreement(),
                    status: this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.configTargets.participationAgreement + this.declaration.configProperties.status),
                    deadline: this.declaration.findInConfigurationAttributes(this.declaration.configTargets.participationAgreement, this.declaration.configAttributes.deadlineDate),
                    template: this.declaration.getParticipationAgreementTemplate(),
                }),
            ];
        },
        teamLogos(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }
            return [
                new ImageUploadModel({
                    fileId: 'teamLogo',
                    filePath: this.declaration.getTeamDeclaration().getTeamLogo(),
                    status: this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.configTargets.teamLogo + this.declaration.configProperties.status),
                    deadline: this.declaration.findInConfigurationAttributes(this.declaration.configTargets.teamLogo, this.declaration.configAttributes.deadlineDate),
                    template: this.declaration.getTeamLogoTemplate(),
                }),
            ];
        },
        nationalAnthems(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return [
                new AudioUploadModel({
                    fileId: 'nationalAnthem',
                    filePath: this.declaration.getTeamDeclaration().getNationalAnthem(),
                    status: this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.configTargets.nationalAnthem + this.declaration.configProperties.status),
                    deadline: this.declaration.findInConfigurationAttributes(this.declaration.configTargets.nationalAnthem, this.declaration.configAttributes.deadlineDate),
                    template: this.declaration.getNationalAnthemTemplate(),
                }),
            ];
        },
        medicalInsuranceCovers(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return [
                new DocumentAndImageUploadModel({
                    fileId: 'medicalInsuranceCover',
                    filePath: this.declaration.getTeamDeclaration().getMedicalInsuranceCover(),
                    status: this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.configTargets.medicalInsuranceCover + this.declaration.configProperties.status),
                    deadline: this.declaration.findInConfigurationAttributes(this.declaration.configTargets.medicalInsuranceCover, this.declaration.configAttributes.deadlineDate),
                    template: this.declaration.getMedicalInsuranceCoverTemplate(),
                }),
            ];
        },
        teamMemberAcceptanceForms(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return [
                new DocumentUploadModel({
                    fileId: 'teamMemberAcceptanceForm',
                    filePath: this.declaration.getTeamDeclaration().getTeamMemberAcceptanceForm(),
                    status: this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.configTargets.teamMemberAcceptanceForm + this.declaration.configProperties.status),
                    deadline: this.declaration.findInConfigurationAttributes(this.declaration.configTargets.teamMemberAcceptanceForm, this.declaration.configAttributes.deadlineDate),
                    template: this.declaration.getTeamMemberAcceptanceFormTemplate(),
                }),
            ];
        },
        teamPhoneticGuides(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return [
                new DocumentAndAudioUploadModel({
                    fileId: 'teamPhoneticGuide',
                    filePath: this.declaration.getTeamDeclaration().getTeamPhoneticGuide(),
                    status: this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.configTargets.teamPhoneticGuide + this.declaration.configProperties.status),
                    deadline: this.declaration.findInConfigurationAttributes(this.declaration.configTargets.teamPhoneticGuide, this.declaration.configAttributes.deadlineDate),
                    template: this.declaration.getTeamPhoneticGuideTemplate(),
                }),
            ];
        },
        teamDisciplinaryCertificates(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return [
                new DocumentAndImageUploadModel({
                    fileId: 'teamDisciplinaryCertificate',
                    filePath: this.declaration.getTeamDeclaration().getTeamDisciplinaryCertificate(),
                    status: this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.configTargets.teamDisciplinaryCertificate + this.declaration.configProperties.status),
                    deadline: this.declaration.findInConfigurationAttributes(this.declaration.configTargets.teamDisciplinaryCertificate, this.declaration.configAttributes.deadlineDate),
                    template: this.declaration.getTeamDisciplinaryCertificateTemplate(),
                }),
            ];
        },

        participationAgreementSubmitted(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return false;
            }

            return Boolean(this.declaration.getTeamDeclaration().getParticipationAgreement());
        },
        teamLogoSubmitted(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return false;
            }

            return Boolean(this.declaration.getTeamDeclaration().getTeamLogo());
        },
        medicalInsuranceCoverSubmitted() {
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return false;
            }

            return Boolean(this.declaration.getTeamDeclaration().getMedicalInsuranceCover());
        },
        teamDisciplinaryCertificateSubmitted(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return false;
            }

            return Boolean(this.declaration.getTeamDeclaration().getTeamDisciplinaryCertificate());
        },

        kits(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return this.declaration.getTeamDeclaration().getKits();
        },
        kitsStatus(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return new DeclarationStatusModel({
                status: this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.configTargets.kit + this.declaration.configProperties.status),
                deadline: this.declaration.findInConfigurationAttributes(this.declaration.configTargets.kit, this.declaration.configAttributes.deadlineDate),
            });
        },

        unionEqId() {
            if (!this.declaration || !this.declaration.getTeamDeclaration()) {
                return null;
            }

            return (this.declaration.getTeamDeclaration().team || {}).unionEqId || null;
        },

        unionName() {
            if (!this.declaration || !this.declaration.getTeamDeclaration()) {
                return null;
            }

            return (this.declaration.getTeamDeclaration().team || {}).unionName || null;
        },

        tournamentName(){
            if(!this.declaration || !this.declaration.getTournament()){
                return '';
            }

            return this.declaration.getTournament().name;
        },

        sport() {
            if (!this.declaration) {
                return null;
            }

            return this.declaration.sport || null;
        },

        isAllowedToSubmitDeclaration(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return false;
            }

            // a page that uses this mixin must have a validation component with a set ref for example:
            // <validations ref="playerValidations"
            if(this.$refs.playerValidations && !this.$refs.playerValidations.isSubmitAllowed()) return false;
            if(this.$refs.managerValidations && !this.$refs.managerValidations.isSubmitAllowed()) return false;
            if(this.$refs.medicValidations && !this.$refs.medicValidations.isSubmitAllowed()) return false;
            if(this.$refs.kitValidations && !this.$refs.kitValidations.isSubmitAllowed()) return false;
            if(this.$refs.participationAgreementValidations && (this.$refs.participationAgreementValidations.warnings.length || this.$refs.participationAgreementValidations.errors.length)) return false;
            if(this.$refs.teamLogoValidations && (this.$refs.teamLogoValidations.warnings.length || this.$refs.teamLogoValidations.errors.length)) return false;
            if(this.$refs.nationalAnthemValidations && (this.$refs.nationalAnthemValidations.warnings.length || this.$refs.nationalAnthemValidations.errors.length)) return false;
            if(this.$refs.medicalInsuranceCoverValidations && (this.$refs.medicalInsuranceCoverValidations.warnings.length || this.$refs.medicalInsuranceCoverValidations.errors.length)) return false;
            if(this.$refs.teamDisciplinaryCertificates && (this.$refs.teamDisciplinaryCertificates.warnings.length || this.$refs.teamDisciplinaryCertificates.errors.length)) return false;
            if(this.$refs.teamMemberAcceptanceFormValidations && (this.$refs.teamMemberAcceptanceFormValidations.warnings.length || this.$refs.teamMemberAcceptanceFormValidations.errors.length)) return false;
            if(this.$refs.teamPhoneticGuideValidations && (this.$refs.teamPhoneticGuideValidations.warnings.length || this.$refs.teamPhoneticGuideValidations.errors.length)) return false;

            return true;
        },
    },
    methods: {
        updateWorkflowSummaries(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return;
            }

            this.apiData.workflowSummaryUpdate = this.$api.call.termsOfParticipation.declarationTeamDeclarationById(this.declaration.getTeamDeclaration().id).then(teamDeclarationConfiguration => {
                if(teamDeclarationConfiguration.declarationId !== this.declaration.id){
                    throw new Error('something went wrong while requesting declaration team information');
                }

                //parse updated declaration team into tmp object
                const tmpDeclarationTeam = new DeclarationTeamModel(teamDeclarationConfiguration);

                const updateStatus = data => {
                    if(!this.declaration.existsInConfig(data.target)){
                        return;
                    }

                    //find updated status in tmp declaration team and store update accordingly in actual declaration team
                    const newStatus = tmpDeclarationTeam.findInConfiguration(data.target + this.declaration.configProperties.status);
                    if(newStatus){
                        this.$set(this.declaration.getTeamDeclaration().findModuleInConfiguration(data.target) || {}, 'status', newStatus);
                    }

                    (data.children || []).forEach(childData => updateStatus(childData));
                };

                this.workflowSummaryConfig.forEach(data => updateStatus(data));
            });
        },
        processPlayerForReplacements(players, player, processedPlayers){
            if(processedPlayers.findIndex((p) => p.id === player.id) !== -1){
                return;
            }

            const replacementPlayerIds = players.filter(player => player.replacedById).map(player => player.replacedById);
            const replacementPlayers = players.filter(player => replacementPlayerIds.find(replacementPlayerId => replacementPlayerId === player.id) !== undefined);

            if(players.findIndex((p) => p.replacedById === player.id) !== -1){
                player.isReplacement = true;
            }

            if(player.isDeclarationReplacement && player.replacedById){
                let replacement = replacementPlayers.find(replacementPlayer => replacementPlayer.id === player.replacedById);
                if(replacement){
                    player.replacementOverlayMessage = player.displayName + ' ' + this.$t('replacedBy') + ' ' + replacement.displayName;
                    player.showReplacementOverlay = true;
                    processedPlayers.push(player);

                    this.processPlayerForReplacements(players, replacement, processedPlayers);
                }
                else
                {
                    processedPlayers.push(player);
                }
            }
            else if(player.hasLeftSquad){
                player.replacementOverlayMessage = this.$t('playerLeftSquad', { name: player.displayName });
                player.showReplacementOverlay = true;

                if(this.playerLeavesSquad && this.playerReplaceOrLeaveComplete){
                    player.replacementOverlayUndo = (player => {
                        return () => {
                            player.replacementOverlayUndoPromise = this.playerLeavesSquad({ player: player }, false).then(() => this.playerReplaceOrLeaveComplete());
                        };
                    })(player);
                }

                processedPlayers.push(player);
            }
            else
            {
                processedPlayers.push(player);
            }
        },
    },
};

export const declarationDetailEditMixins = {
    data(){
        return {
            declaration: null,
            apiData: {
                participationAgreementUpdating: null,
                teamLogoUpdating: null,
                nationalAnthemUpdating: null,
                medicalInsuranceCoverUpdating: null,
                teamPhoneticGuideUpdating: null,
                teamDisciplinaryCertificateUpdating: null,
                teamKitsUpdate: null,
                passportUpdate: null,
            },
            errors: {
                participationAgreement: null,
                teamLogo: null,
                nationalAnthem: null,
                medicalInsuranceCover: null,
                teamPhoneticGuide: null,
                teamDisciplinaryCertificate: null,
                passport: null,
            },
        };
    },
    methods: {
        addPlayers(players, params = [], modelParams = {}){
            params = params.concat(Array(Math.max(0, players.length - params.length)).fill({}));
            return this.addPersons(players, params.map(x => ({
                ...x,
                group: constants.group.player
            })), modelParams);
        },
        addManagers(managers, params = []){
            params = params.concat(Array(Math.max(0, managers.length - params.length)).fill({}));
            return this.addPersons(managers, params.map(x => ({
                ...x,
                group: constants.group.management
            })))
        },
        addMedics(medics, params = []){
            params = params.concat(Array(Math.max(0, medics.length - params.length)).fill({}));
            return this.addPersons(medics, params.map(x => ({
                ...x,
                group: constants.group.medical
            })));
        },
        addReplacementPlayers(players, params = [], modelParams = {}){
            params = params.concat(Array(Math.max(0, players.length - params.length)).fill({}));
            return this.addPersons(players, params.map(x => ({
                ...x,
                group: constants.group.replacementPlayer
            })), modelParams);
        },
        addNonPlayingReserves(players, params = []){
            params = params.concat(Array(Math.max(0, players.length - params.length)).fill({}));
            return this.addPersons(players, params.map(x => ({
                ...x,
                group: constants.group.nonPlayingReserve
            })));
        },
        addPersons(persons, params = [], modelParams = {}){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            const request = persons.map((person, index) => Object.assign({}, params[index], {
                personEqId: person.eqId,
            }));

            return this.$api.call.termsOfParticipation.declarationTeamAddPersons(this.declaration.getTeamDeclaration().id, this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.getStepTarget(this.declaration.configTargets.squad) + this.declaration.configProperties.id), request).then(teamDeclarationPersonDataList => {
                return (teamDeclarationPersonDataList || []).map((teamDeclarationPersonData, i) => {
                    const person = persons[i];

                    teamDeclarationPersonData = Object.assign({}, person.toJSON(), person.toJSON().person, teamDeclarationPersonData, modelParams, {
                        personId: person.id,
                        teamDeclarationPersonId: teamDeclarationPersonData.id
                    });

                    switch(teamDeclarationPersonData.roleType){
                        case constants.personRoleType.player:
                            return new PlayerModel(teamDeclarationPersonData);

                        case constants.personRoleType.management:
                            if (teamDeclarationPersonData.group === constants.group.medical){
                                return new MedicModel(teamDeclarationPersonData);
                            }
                            return new ManagerModel(teamDeclarationPersonData);
                    }
                    return null;
                });
            }).then(persons => {
                this.declaration.getTeamDeclaration().addPersons(persons);
                this.updateWorkflowSummaries();
            });
        },
        removePerson(teamDeclarationPerson){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            return this.$api.call.termsOfParticipation.declarationTeamDeletePerson(this.declaration.getTeamDeclaration().id, this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.getStepTarget(this.declaration.configTargets.squad) + this.declaration.configProperties.id), teamDeclarationPerson.id).then(() => {
                this.declaration.getTeamDeclaration().removePerson(teamDeclarationPerson);
            }).then(() => {
                this.updateWorkflowSummaries();
            }).catch(err => {
                if(this.$log){
                    this.$log.error(err);
                }
                return Promise.reject(Error(this.$t('requestError')));
            });
        },

        updateParticipationAgreement(data){
            this.apiData.participationAgreementUpdating = this.updateFile(data, 'participationAgreement');
        },
        updateTeamLogo(data){
            this.apiData.teamLogoUpdating = this.updateFile(data, 'teamLogo');
        },
        updateNationalAnthem(data){
            this.apiData.nationalAnthemUpdating = this.updateFile(data, 'nationalAnthem');
        },
        updateMedicalInsuranceCover(data){
            this.apiData.medicalInsuranceCoverUpdating = this.updateFile(data, 'medicalInsuranceCover');
        },
        updateTeamDisciplinaryCertificate(data){
            this.apiData.teamDisciplinaryCertificateUpdating = this.updateFile(data, 'teamDisciplinaryCertificate');
        },
        updateTeamMemberAcceptanceForm(data){
            this.apiData.teamDisciplinaryCertificateUpdating = this.updateFile(data, 'teamMemberAcceptanceForm');
        },
        updateTeamPhoneticGuide(data){
            this.apiData.teamPhoneticGuideUpdating = this.updateFile(data, 'teamPhoneticGuide');
        },
        updateFile(data, fileId){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            if(!(data.upload.file instanceof File)){
                return Promise.resolve(null);
            }

            return this.$api.call.termsOfParticipation.storeFile(data.upload.file).then(file => {
                if(!file){
                    throw Error(this.$t('unexpectedError'));
                }

                switch(fileId){
                    case 'participationAgreement':
                        this.declaration.getTeamDeclaration().setParticipationAgreement(file);
                        break;

                    case 'teamLogo':
                        this.declaration.getTeamDeclaration().setTeamLogo(file);
                        break;

                    case 'nationalAnthem':
                        this.declaration.getTeamDeclaration().setNationalAnthem(file);
                        break;

                    case 'medicalInsuranceCover':
                        this.declaration.getTeamDeclaration().setMedicalInsuranceCover(file);
                        break;

                    case 'teamDisciplinaryCertificate':
                        this.declaration.getTeamDeclaration().setTeamDisciplinaryCertificate(file);
                        break;

                    case 'teamMemberAcceptanceForm':
                        this.declaration.getTeamDeclaration().setTeamMemberAcceptanceForm(file);
                        break;

                    case 'teamPhoneticGuide':
                        this.declaration.getTeamDeclaration().setTeamPhoneticGuide(file);
                        break;

                    default:
                        throw Error(this.$t('unexpectedError'));
                }

                const target = this.declaration.configTargets[fileId];
                const teamDeclarationId = this.declaration.getTeamDeclaration().id;
                const teamDeclarationModuleId = this.declaration.getTeamDeclaration().findInConfiguration(target + this.declaration.configProperties.id);
                const fieldName = this.declaration.configProperties.file.replace('.', '');
                const fieldValue = {
                    value: file,
                };

                return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdateField(teamDeclarationId, teamDeclarationModuleId, fieldName, fieldValue).then(() => {
                    // reload the series declaration with the new status
                    if (this.declaration.type === 'Series') {
                        this.loadSeriesDeclaration();
                    }

                    // reload the tournament declaration with the new status
                    if (this.declaration.type === 'Tournament') {
                        this.loadTournamentDeclaration();
                    }

                    this.declaration.getTeamDeclaration().setConfigurationField(target, fieldName, file);
                    this.errors[fileId] = null;
                });
            }, error => {
                this.errors[fileId] = error;

                throw error;
            });
        },

        setPersonPosition(data){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return;
            }

            //ignore if nothing changed
            if(!data || !data.person || data.person.position === data.value){
                return;
            }

            if (data && data.value && data.value.eqId) {
                let payload = data.person.toTopPersonUpdateJSON();
                payload.positionEqId = data.value.eqId;

                return this.$api.call.termsOfParticipation.declarationTeamUpdatePerson(
                    this.declaration.getTeamDeclaration().id,
                    this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.getStepTarget(this.declaration.configTargets.squad) + this.declaration.configProperties.id),
                    data.person.id,
                    payload
                ).then(() => {
                    data.person.setPosition(data.value);
                    data.person.positionEqId = data.value.eqId;
                }).catch(err => {
                    if(this.$log){
                        this.$log.error(err);
                    }

                    return Promise.reject(Error(this.$t('requestError')));
                });
            }
        },

        setJerseyNumber(data){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return;
            }

            //ignore if nothing changed
            if(data?.person?.playerAttributes?.shirtNumber === data.value.srcElement.value){
                return;
            }

            if (data && data.value) {
                let payload = data.person.toTopPersonUpdateJSON();
                payload.jerseyNumber = data.value.srcElement.value;

                return this.$api.call.termsOfParticipation.declarationTeamUpdatePerson(
                    this.declaration.getTeamDeclaration().id,
                    this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.getStepTarget(this.declaration.configTargets.squad) + this.declaration.configProperties.id),
                    data.person.id,
                    payload
                ).then(() => {
                    data.person.setJerseyNumber(data.value.srcElement.value);
                }).catch(err => {
                    if(this.$log){
                        this.$log.error(err);
                    }

                    return Promise.reject(Error(this.$t('requestError')));
                });
            }
        },

        setPlayerEligibility(data){
            if(!this.declaration || !this.declaration.getTeamDeclaration() || !data || !data.person || !data.event){
                return;
            }

            let payload = data.person.toTopPersonUpdateJSON();

            switch (data.event) {
                case 'changeIsHooker':
                    payload.isHooker = data.value;
                    break;
                case 'changeIsLoosehead':
                    payload.isLoosehead = data.value;
                    break;
                case 'changeIsTighthead':
                    payload.isTighthead = data.value;
                    break;
            }

            return this.$api.call.termsOfParticipation.declarationTeamUpdatePerson(
                this.declaration.getTeamDeclaration().id,
                this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.getStepTarget(this.declaration.configTargets.squad) + this.declaration.configProperties.id),
                data.person.id,
                payload
            ).then(() => {
                switch (data.event) {
                    case 'changeIsHooker':
                        data.person.setIsHooker(data.value);
                        break;
                    case 'changeIsLoosehead':
                        data.person.setIsLoosehead(data.value);
                        break;
                    case 'changeIsTighthead':
                        data.person.setIsTighthead(data.value);
                        break;
                }
            }).catch(err => {
                if(this.$log){
                    this.$log.error(err);
                }

                return Promise.reject(Error(this.$t('requestError')));
            });
        },

        updateTeamKit(teamKit) {
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            // team kit should update when any form control is changed
            let queue = Promise.resolve();

            //check if the user wants to change the kit image and upload
            if(teamKit.image instanceof File){
                queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles(teamKit.image)).then(storageFile => {
                    teamKit.setImageStorage(storageFile);
                }, error => {
                    //set custom file upload validation error
                    this.errors.teamKit = this.$t('fileUploadError');

                    throw error;
                });
            }

            //save kit
            queue = queue.then(() => this.$api.call.termsOfParticipation.declarationTeamDeclarationKitUpdate(this.declaration.getTeamDeclaration().id, this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.configTargets.kit + this.declaration.configProperties.id), teamKit.type, teamKit.toJSON())).then(() => {
                return this.$api.call.termsOfParticipation.declarationTeamDeclarationById(this.declaration.getTeamDeclaration().id).then(teamDeclarationConfiguration => {
                    if(teamDeclarationConfiguration.declarationId !== this.declaration.id){
                        throw new Error('something went wrong while requesting declaration team information');
                    }

                    //parse updated declaration team into tmp object
                    const tmpDeclarationTeam = new DeclarationTeamModel(teamDeclarationConfiguration);
                    tmpDeclarationTeam.step = this.declaration.getTeamDeclaration().step;

                    //update kit status
                    let kitStatus = tmpDeclarationTeam.findInConfiguration(this.declaration.configTargets.kit + this.declaration.configProperties.status);
                    this.$set(this.declaration.getTeamDeclaration().findModuleInConfiguration(this.declaration.configTargets.kit) || {}, 'status', kitStatus);
                    this.declaration.getTeamDeclaration().getKits().forEach(kit => {
                        kit.status = kitStatus;
                    });

                    //force kit ui update
                    this.$set(this.declaration.getTeamDeclaration().getKits(), this.declaration.getTeamDeclaration().getKits().indexOf(teamKit), teamKit);
                });
            });

            if (teamKit.type === 'Primary'){
                this.apiData.teamKitPrimaryUpdating = queue;
            }
            else{
                this.apiData.teamKitSecondaryUpdating = queue;
            }
        },

        updatePersonBiography(person){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return;
            }

            const teamDeclarationId = this.declaration.getTeamDeclaration().id;
            const teamDeclarationModuleId = this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.getStepTarget(this.declaration.configTargets.squad) + this.declaration.configProperties.id);
            const teamDeclarationPersonId = person.id;
            const data = person.toJSON().bio;

            return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonBio(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data).then(bioData => {
                person.biographyStatus = bioData.status;
            });
        },
        /**
         * transform date object to date data
         *
         * @param {Date}    date
         * @param {boolean} [nullable = true]
         *
         * @returns {String}
         */
        fromDate(date, nullable = true){
            //detect empty input
            if(nullable && !date){
                return null;
            }

            //needs to be date
            if(!(date instanceof Date)){
                return null;
            }

            //convert to UTC datetime string
            return (new Date(date.getTime() - date.getTimezoneOffset() * 60000)).toJSON();
        },
        updatePassport(data) {
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            if(!(data.passport instanceof File) && !data.passportStorage){
                return Promise.reject(Error(this.$t('fileIsRequired')));
            }

            let queue = Promise.resolve();

            const target = this.declaration.getStepTarget(this.declaration.configTargets.squad);
            const teamDeclarationId = this.declaration.getTeamDeclaration().id;
            const teamDeclarationModuleId = this.declaration.getTeamDeclaration().findInConfiguration(target + this.declaration.configProperties.id);
            const teamDeclarationPersonId = data.id;
            const payload = {
                passportUploadDate: new Date(),
                passportUrl: data.passportStorage,
                firstName: data.firstName,
                lastName: data.lastName,
                passportNo: data.passportNo,
                passportIssueDate: this.fromDate(data.passportIssueDate),
                passportExpiryDate: this.fromDate(data.passportExpiryDate),
                nationalityCountryIsoCode: data.nationalityCountryIsoCode,
            };

            if(data.passport instanceof File){
                queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles([data.passport]).then(storageFile => {
                    payload.passportUrl = storageFile[0];

                    const file = storageFile.shift();
                    if(!file){
                        throw Error(this.$t('unexpectedError'));
                    }
                }));
            }

            queue = queue.then(() => this.$api.call.termsOfParticipation.declarationTeamDeclarationPassportUpdate(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, payload).then(passportData => {
                if(data.passport instanceof File){
                    data.setPassportStorage(payload.passportUrl);
                }
                data.passportStatus = passportData.status;
            }).catch(error => {
                // target the form field id to display the message
                error.errors.passport = this.$t('fileUploadError');

                throw error;
            }));

            return queue;
        },
        updateHeadshot(data) {
            if (data.headshotStorage && !(data.headshot instanceof File)) {
                return Promise.resolve(null);
            }

            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            if(!(data.headshot instanceof File)){
                return Promise.reject(Error(this.$t('fileIsRequired')));
            }

            let queue = Promise.resolve();

            queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles([data.headshot]).then(storageFile => {
                const headshotUrl = storageFile[0];

                const file = storageFile.shift();
                if(!file){
                    throw Error(this.$t('unexpectedError'));
                }

                const target = this.declaration.getStepTarget(this.declaration.configTargets.squad);
                const teamDeclarationId = this.declaration.getTeamDeclaration().id;
                const teamDeclarationModuleId = this.declaration.getTeamDeclaration().findInConfiguration(target + this.declaration.configProperties.id);
                const teamDeclarationPersonId = data.id;

                const payload = {
                    headshotUrl: headshotUrl,
                };
                return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonHeadshot(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, payload).then(headshotData => {
                    data.setHeadshotStorage(headshotUrl);
                    data.headshotStatus = headshotData.status;
                });
            }, error => {
                // target the form field id to display the message
                error.errors.headshot = this.$t('fileUploadError');

                throw error;
            }));

            return queue;
        },

        updateMedicalCertificate(data) {
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            if(!(data.medicalCertificate instanceof File) && !data.medicalCertificateStorage){
                return Promise.reject(Error(this.$t('fileIsRequired')));
            }

            let queue = Promise.resolve();

            const target = this.declaration.getStepTarget(this.declaration.configTargets.squad);
            const teamDeclarationId = this.declaration.getTeamDeclaration().id;
            const teamDeclarationModuleId = this.declaration.getTeamDeclaration().findInConfiguration(target + this.declaration.configProperties.id);
            const teamDeclarationPersonId = data.id;
            const payload = {
                certificateUploadDate: new Date(),
                certificateUrl: data.medicalCertificateStorage,
            };

            if(data.medicalCertificate instanceof File){
                queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles([data.medicalCertificate]).then(storageFile => {
                    payload.certificateUrl = storageFile[0];

                    const file = storageFile.shift();
                    if(!file){
                        throw Error(this.$t('unexpectedError'));
                    }
                }));
            }

            queue = queue.then(() => this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonMedicalCertificate(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, payload).then(medicalCertificateData => {
                if(data.medicalCertificate instanceof File){
                    data.setMedicalCertificateStorage(payload.certificateUrl);
                }
                data.medicalCertificateStatus = medicalCertificateData.status;
                data.medicalCertificateUploadDate = medicalCertificateData.certificateUploadDate;
                data.medicalCertificateUrl = medicalCertificateData.certificateUrl;
            }).catch(error => {
                // target the form field id to display the message
                error.errors.medicalCertificate = this.$t('fileUploadError');

                throw error;
            }));

            return queue;
        },

        submitTeamDeclaration(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return;
            }

            const teamDeclaration = this.declaration.getTeamDeclaration();

            return Promise.all(teamDeclaration.getSteps(true).map(step => {
                return this.$api.call.termsOfParticipation.declarationTeamDeclarationStepSubmit(teamDeclaration.id, step.id).catch(error => {
                    this.declarationWarning = this.$t('tournamentDeclarationSubmitWarning');
                    error.errorIdParser = this.declaration.errorIdParser.bind(this.declaration);

                    throw error;
                });
            })).then(() => {
                // redirect to overview page
                if (this.declaration.type) {
                    switch (this.declaration.type) {
                        case constants.declarationTypes.match:
                            this.$router.push({ name: 'declarationMatchIndex' });
                            break;

                        case constants.declarationTypes.series:
                            this.$router.push({ name: 'declarationSeriesIndex' });
                            break;

                        case constants.declarationTypes.tournament:
                            this.$router.push({ name: 'declarationTournamentIndex' });
                            break;
                    }
                }
            });
        },
    },
};

export const declarationDetailReviewMixins = {
    data(){
        return {
            declaration: null,
            statusChangeModalText: '',
            statusChangeModalHandler: null,
            statusChangeModalClosedHandler: () => {},

            acceptType: null,
            acceptHandler: null,
            rejectType: null,
            rejectHandler: null,
        };
    },
    methods: {
        showStatusChangeModal(type, isAccept = false, name = '', teamDeclarationPersonId = null, rejectOnCancel = false){
            let statusChangeModalHandler;

            switch(type){
                case 'participationAgreement':
                    statusChangeModalHandler = isAccept ? this.acceptParticipationAgreement : this.rejectParticipationAgreement;
                    break;

                case 'teamLogo':
                    statusChangeModalHandler = isAccept ? this.acceptTeamLogo : this.rejectTeamLogo;
                    break;

                case 'nationalAnthem':
                    statusChangeModalHandler = isAccept ? this.acceptNationalAnthem : this.rejectNationalAnthem;
                    break;

                case 'medicalInsuranceCover':
                    statusChangeModalHandler = isAccept ? this.acceptMedicalInsuranceCover : this.rejectMedicalInsuranceCover;
                    break;

                case 'teamDisciplinaryCertificate':
                    statusChangeModalHandler = isAccept ? this.acceptTeamDisciplinaryCertificate : this.rejectTeamDisciplinaryCertificate;
                    break;

                case 'teamMemberAcceptanceForm':
                    statusChangeModalHandler = isAccept ? this.acceptTeamMemberAcceptanceForm : this.rejectTeamMemberAcceptanceForm;
                    break;

                case 'teamPhoneticGuide':
                    statusChangeModalHandler = isAccept ? this.acceptTeamPhoneticGuide : this.rejectTeamPhoneticGuide;
                    break;

                case 'kit':
                    statusChangeModalHandler = isAccept ? this.acceptKit : this.rejectKit;
                    break;

                case 'squad':
                    statusChangeModalHandler = isAccept ? this.acceptSquad : this.rejectSquad;
                    break;

                case 'squadTwo':
                    statusChangeModalHandler = isAccept ? this.acceptSquadTwo : this.rejectSquadTwo;
                    break;

                case 'person':
                    statusChangeModalHandler = isAccept ? this.acceptPerson.bind(this, teamDeclarationPersonId) : this.rejectPerson.bind(this, teamDeclarationPersonId);
                    break;

                case 'personHeadshot':
                    statusChangeModalHandler = isAccept ? this.acceptPersonHeadshot.bind(this, teamDeclarationPersonId) : this.rejectPersonHeadshot.bind(this, teamDeclarationPersonId);
                    break;

                case 'PersonBio':
                    statusChangeModalHandler = isAccept ? this.acceptPersonBio.bind(this, teamDeclarationPersonId) : this.rejectPersonBio.bind(this, teamDeclarationPersonId);
                    break;

                case 'PersonPassport':
                    statusChangeModalHandler = isAccept ? this.acceptPersonPassport.bind(this, teamDeclarationPersonId) : this.rejectPersonPassport.bind(this, teamDeclarationPersonId);
                    break;

                case 'PersonMedicalCertificate':
                    statusChangeModalHandler = isAccept ? this.acceptPersonMedicalCertificate.bind(this, teamDeclarationPersonId) : this.rejectPersonMedicalCertificate.bind(this, teamDeclarationPersonId);
                    break;

                default:
                    return Promise.reject(Error('unexpectedError'));
            }

            return new Promise((resolve, reject) => {
                const textProps = { type: name || this.$t(type) };
                this.statusChangeModalText = isAccept ? this.$t('confirmAccept', textProps) : this.$t('confirmReject', textProps);

                this.statusChangeModalHandler = (...args) => {
                    return statusChangeModalHandler(...args).then(result => {
                        resolve();
                        return result;
                    });
                };

                this.statusChangeModalClosedHandler = () => {
                    if(rejectOnCancel){
                        reject(Error());
                    }
                    else {
                        resolve();
                    }
                };

                this.$bvModal.show('statusChangeModal');
            });
        },
        acceptParticipationAgreement(){
            return this.updateModuleStatus(this.declaration.configTargets.participationAgreement, this.constants.declarationStatus.approved);
        },
        rejectParticipationAgreement(){
            return this.updateModuleStatus(this.declaration.configTargets.participationAgreement, this.constants.declarationStatus.rejected);
        },
        acceptTeamLogo(){
            return this.updateModuleStatus(this.declaration.configTargets.teamLogo, this.constants.declarationStatus.approved);
        },
        rejectTeamLogo(){
            return this.updateModuleStatus(this.declaration.configTargets.teamLogo, this.constants.declarationStatus.rejected);
        },
        acceptNationalAnthem(){
            return this.updateModuleStatus(this.declaration.configTargets.nationalAnthem, this.constants.declarationStatus.approved);
        },
        rejectNationalAnthem(){
            return this.updateModuleStatus(this.declaration.configTargets.nationalAnthem, this.constants.declarationStatus.rejected);
        },
        acceptMedicalInsuranceCover(){
            return this.updateModuleStatus(this.declaration.configTargets.medicalInsuranceCover, this.constants.declarationStatus.approved);
        },
        rejectMedicalInsuranceCover(){
            return this.updateModuleStatus(this.declaration.configTargets.medicalInsuranceCover, this.constants.declarationStatus.rejected);
        },
        acceptTeamDisciplinaryCertificate(){
            return this.updateModuleStatus(this.declaration.configTargets.teamDisciplinaryCertificate, this.constants.declarationStatus.approved);
        },
        rejectTeamDisciplinaryCertificate(){
            return this.updateModuleStatus(this.declaration.configTargets.teamDisciplinaryCertificate, this.constants.declarationStatus.rejected);
        },
        acceptTeamMemberAcceptanceForm(){
            return this.updateModuleStatus(this.declaration.configTargets.teamMemberAcceptanceForm, this.constants.declarationStatus.approved);
        },
        rejectTeamMemberAcceptanceForm(){
            return this.updateModuleStatus(this.declaration.configTargets.teamMemberAcceptanceForm, this.constants.declarationStatus.rejected);
        },
        acceptTeamPhoneticGuide(){
            return this.updateModuleStatus(this.declaration.configTargets.teamPhoneticGuide, this.constants.declarationStatus.approved);
        },
        rejectTeamPhoneticGuide(){
            return this.updateModuleStatus(this.declaration.configTargets.teamPhoneticGuide, this.constants.declarationStatus.rejected);
        },
        acceptKit(){
            return this.updateModuleStatus(this.declaration.configTargets.kit, this.constants.declarationStatus.approved);
        },
        rejectKit(){
            return this.updateModuleStatus(this.declaration.configTargets.kit, this.constants.declarationStatus.rejected);
        },
        acceptSquad(){
            return this.updateModuleStatus(this.declaration.configTargets.squad, this.constants.declarationStatus.approved);
        },
        rejectSquad(){
            return this.updateModuleStatus(this.declaration.configTargets.squad, this.constants.declarationStatus.rejected);
        },
        acceptSquadTwo(){
            return this.updateModuleStatus(this.declaration.configTargets.squad, this.constants.declarationStatus.approved, '', false, true);
        },
        rejectSquadTwo(){
            return this.updateModuleStatus(this.declaration.configTargets.squad, this.constants.declarationStatus.rejected, '', false, true);
        },
        acceptPerson(teamDeclarationPersonId){
            return this.updatePersonStatus(teamDeclarationPersonId, this.constants.declarationStatus.approved);
        },
        rejectPerson(teamDeclarationPersonId){
            return this.updatePersonStatus(teamDeclarationPersonId, this.constants.declarationStatus.rejected);
        },
        acceptPersonHeadshot(teamDeclarationPersonId){
            return this.updatePersonHeadshotStatus(teamDeclarationPersonId, this.constants.declarationStatus.approved);
        },
        rejectPersonHeadshot(teamDeclarationPersonId){
            return this.updatePersonHeadshotStatus(teamDeclarationPersonId, this.constants.declarationStatus.rejected);
        },
        acceptPersonBio(teamDeclarationPersonId){
            return this.updatePersonBioStatus(teamDeclarationPersonId, this.constants.declarationStatus.approved);
        },
        rejectPersonBio(teamDeclarationPersonId){
            return this.updatePersonBioStatus(teamDeclarationPersonId, this.constants.declarationStatus.rejected);
        },
        acceptPersonPassport(teamDeclarationPersonId){
            return this.updatePersonPassportStatus(teamDeclarationPersonId, this.constants.declarationStatus.approved);
        },
        rejectPersonPassport(teamDeclarationPersonId){
            return this.updatePersonPassportStatus(teamDeclarationPersonId, this.constants.declarationStatus.rejected);
        },
        acceptPersonMedicalCertificate(teamDeclarationPersonId){
            return this.updatePersonMedicalCertificateStatus(teamDeclarationPersonId, this.constants.declarationStatus.approved);
        },
        rejectPersonMedicalCertificate(teamDeclarationPersonId){
            return this.updatePersonMedicalCertificateStatus(teamDeclarationPersonId, this.constants.declarationStatus.rejected);
        },
        updateModuleStatus(target, status, comment = '', internalComment = false, isTeamTwo = false){
            if(!this.declaration || !target){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            const teamDeclaration = isTeamTwo ? this.declaration.getTeamDeclarationTwo() : this.declaration.getTeamDeclaration();
            if(!teamDeclaration){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            const teamDeclarationId = teamDeclaration.id;
            const teamDeclarationModuleId = teamDeclaration.findInConfiguration(target + this.declaration.configProperties.id);
            const data = this.generateStatusChangePayload(status, comment, internalComment);

            if(!teamDeclarationId || !teamDeclarationModuleId){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdateStatus(teamDeclarationId, teamDeclarationModuleId, data).then(result => {
                //update status in config
                if(result && result.status){
                    this.$set(teamDeclaration.findModuleInConfiguration(target) || {}, 'status', result.status);

                    // reload the match declaration with the new status
                    if (this.declaration.type === 'Match') {
                        this.loadMatchDeclaration();
                    }
                }

                return result;
            });
        },
        updatePersonStatus(teamDeclarationPersonId, status, isPreliminary = false, comment = '', internalComment = false){
            return this.updatePersonDataStatus(teamDeclarationPersonId, (isPreliminary ? this.declaration.configTargets.preliminarySquad : this.declaration.configTargets.squad), status, comment, internalComment);
        },
        updatePersonHeadshotStatus(teamDeclarationPersonId, status, isPreliminary = false, comment = '', internalComment = false){
            return this.updatePersonDataStatus(teamDeclarationPersonId, (isPreliminary ? this.declaration.configTargets.preliminarySquadPersonHeadshots : this.declaration.configTargets.squadPersonHeadshots), status, comment, internalComment);
        },
        updatePersonBioStatus(teamDeclarationPersonId, status, isPreliminary = false, comment = '', internalComment = false){
            return this.updatePersonDataStatus(teamDeclarationPersonId, (isPreliminary ? this.declaration.configTargets.preliminarySquadBio : this.declaration.configTargets.squadBio), status, comment, internalComment);
        },
        updatePersonPassportStatus(teamDeclarationPersonId, status, isPreliminary = false, comment = '', internalComment = false){
            return this.updatePersonDataStatus(teamDeclarationPersonId, (isPreliminary ? this.declaration.configTargets.preliminarySquadPassportInformation : this.declaration.configTargets.squadPassportInformation), status, comment, internalComment);
        },
        updatePersonMedicalCertificateStatus(teamDeclarationPersonId, status, isPreliminary = false, comment = '', internalComment = false){
            return this.updatePersonDataStatus(teamDeclarationPersonId, (isPreliminary ? this.declaration.configTargets.preliminarySquadMedicalCertificates : this.declaration.configTargets.squadMedicalCertificates), status, comment, internalComment);
        },
        updatePersonDataStatus(teamDeclarationPersonId, target, status, comment = '', internalComment = false){
            if(!this.declaration || !this.declaration.getTeamDeclaration() || !teamDeclarationPersonId){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            const teamDeclarationId = this.declaration.getTeamDeclaration().id;
            const teamDeclarationModuleId = this.declaration.getTeamDeclaration().findInConfiguration(this.declaration.getStepTarget(this.declaration.configTargets.squad) + this.declaration.configProperties.id);
            const data = this.generateStatusChangePayload(status, comment, internalComment);

            if(!teamDeclarationId || !teamDeclarationModuleId){
                return Promise.reject(Error(this.$t('unexpectedError')));
            }

            let request;
            let statusPropertyName;
            switch(target){
                case this.declaration.configTargets.squad:
                    request = this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data);
                    statusPropertyName = 'status';
                    break;

                case this.declaration.configTargets.squadPersonHeadshots:
                    request = this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonHeadshotStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data);
                    statusPropertyName = 'headshotStatus';
                    break;

                case this.declaration.configTargets.squadBio:
                    request = this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonBioStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data);
                    statusPropertyName = 'biographyStatus';
                    break;

                case this.declaration.configTargets.squadPassportInformation:
                    request = this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonPassportStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data);
                    statusPropertyName = 'passportStatus';
                    break;

                case this.declaration.configTargets.squadMedicalCertificates:
                    request = this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonMedicalCertificateStatus(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, data);
                    statusPropertyName = 'medicalCertificateStatus';
                    break;

                default:
                    return Promise.reject(Error(this.$t('unexpectedError')));
            }

            return request.then(result => {
                //update status in config
                if(result && result.status){
                    const person = this.declaration.getTeamDeclaration().findPerson(teamDeclarationPersonId) || {};
                    person[statusPropertyName] = result.status;
                }
                return result;
            });
        },
        generateStatusChangePayload(status, comment = '', internalComment = false){
            const data = {
                status: status,
                comment: comment || null,
            };
            if(comment){
                data.isInternal = internalComment;
            }

            return data;
        },
    },
};

export const declarationDetailSeriesMixins = {
    props: {
        dataSeriesDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataTeamDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            declaration: null,
            apiData: {
                seriesDeclaration: this.loadSeriesDeclaration(),
            },
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$t('squadAnnouncement'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.squad),
                    },
                    2: {
                        label: this.$t('participationAgreement'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.participationAgreement),
                    },
                    3: {
                        label: this.$t('teamLogo'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.teamLogo),
                    },
                    4: {
                        label: this.$t('nationalAnthem'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.nationalAnthem),
                    },
                    5: {
                        label: this.$t('medicalInsuranceCover'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.medicalInsuranceCover),
                    },
                    6: {
                        label: this.$t('teamDisciplinaryCertificates'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.teamDisciplinaryCertificate),
                    },
                    7: {
                        label: this.$t('kitDeclaration'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.kit),
                    },
                    8: {
                        label: this.$t('teamMemberAcceptanceForm'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.teamMemberAcceptanceForm),
                    },
                    9: {
                        label: this.$t('teamPhoneticGuide'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.teamPhoneticGuide),
                    },
                },
            },
        };
    },
    computed: {
        detailData(){
            if(!this.declaration || !this.declaration.getTeamDeclaration()){
                return [];
            }

            return [
                {
                    label: this.$tc('team'),
                    content: this.declaration.getTeamDeclaration().team.name || '',
                },
                {
                    label: this.$tc('union'),
                    content: this.declaration.getTeamDeclaration().team.union || '',
                },
                {
                    label: this.$tc('sport'),
                    content: this.declaration.sport || '',
                },
            ];
        },
    },
    methods: {
        loadSeriesDeclaration(){
            //load declaration
            return this.$api.call.termsOfParticipation.seriesDeclarationById(this.dataSeriesDeclarationId).then(data => {
                const declaration = new SeriesDeclarationModel(data);

                //load series
                return this.$api.call.tournament.seriesGetByEqId(declaration.getSeries().eqId).then(data => {
                    declaration.setSeries(SeriesModel.from(data));

                    //load team declaration
                    if (this.dataTeamDeclarationId){
                        return this.$api.call.termsOfParticipation.declarationTeamDeclarationById(this.dataTeamDeclarationId).then(teamDeclarationConfiguration => {
                            if(teamDeclarationConfiguration.declarationId !== declaration.id){
                                throw new Error('something went wrong while requesting declaration team information');
                            }

                            declaration.setTeamDeclaration(new DeclarationTeamModel(teamDeclarationConfiguration));

                            const teamDeclarationModuleId = declaration.getTeamDeclaration().findInConfiguration(declaration.getStepTarget(declaration.configTargets.squad + declaration.configProperties.id));
                            if(!teamDeclarationModuleId){
                                throw new Error('team declaration has no squad id');
                            }

                            //load team persons
                            return this.$api.call.termsOfParticipation.declarationTeamDeclarationPersons(declaration.getTeamDeclaration().id, teamDeclarationModuleId).then(result => {
                                declaration.getTeamDeclaration().setPersons((result.persons || []).map(data => {
                                    if (data.roleType === 'Player'){
                                        return new PlayerModel(data);
                                    }
                                    else if(data.roleType === constants.personRoleType.management) {
                                        if ((data.position && data.position.isMedical) || (data.group && data.group === constants.group.medical)){
                                            return new MedicModel(data);
                                        }
                                        else{
                                            return new ManagerModel(data);
                                        }
                                    }

                                    return null;
                                }));

                                //load team kits
                                if (declaration.getTeamDeclaration().findInConfiguration(declaration.configTargets.kit + declaration.configProperties.id)) {
                                    const kitTypes = Object.values(TeamKitModel.types);
                                    let kitStatus = declaration.getTeamDeclaration().findInConfiguration(declaration.configTargets.kit + declaration.configProperties.status)
                                    kitTypes.map((type, index) => {
                                        this.$api.call.termsOfParticipation.declarationTeamDeclarationKit(declaration.getTeamDeclaration().id, declaration.getTeamDeclaration().findInConfiguration('kit.id'), type).then(data => {
                                            let kit = TeamKitModel.from(data);
                                            kit.status = kitStatus;
                                            declaration.getTeamDeclaration().addKit(kit, index);
                                        }, error => {
                                            //if 404 not found set as null
                                            if(error.status === 404){
                                                let newKit = TeamKitModel.from({});
                                                newKit.type = type;
                                                newKit.status = kitStatus;
                                                declaration.getTeamDeclaration().addKit(newKit, index);
                                                return;
                                            }
                                            throw error;
                                        });
                                    });
                                }

                                //all loaded
                                this.declaration = declaration;

                                //update subnavigation visibility
                                this.$set(this.subnavigation.elements[1], 'hidden', !this.declaration.existsInConfig(this.declaration.getStepTarget(this.declaration.configTargets.squad)));
                                this.$set(this.subnavigation.elements[2], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.participationAgreement));
                                this.$set(this.subnavigation.elements[3], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.teamLogo));
                                this.$set(this.subnavigation.elements[4], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.nationalAnthem));
                                this.$set(this.subnavigation.elements[5], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.medicalInsuranceCover));
                                this.$set(this.subnavigation.elements[6], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.teamDisciplinaryCertificate));
                                this.$set(this.subnavigation.elements[7], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.kit));
                                this.$set(this.subnavigation.elements[8], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.teamMemberAcceptanceForm));
                                this.$set(this.subnavigation.elements[9], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.teamPhoneticGuide));
                            });
                        });
                    }
                });
            });
        },
        reloadSeriesDeclaration(){
            this.apiData.seriesDeclaration = this.loadSeriesDeclaration();
        },
    },
    watch: {
        dataSeriesDeclarationId(){
            this.reloadSeriesDeclaration();
        },
        dataTeamDeclarationId(){
            this.reloadSeriesDeclaration();
        },
    },
};

export const declarationDetailTournamentMixins = {
    props: {
        dataTournamentDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataTeamDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataDeclarationStep: {
            type: String,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            declaration: null,
            apiData: {
                tournamentDeclaration: this.loadTournamentDeclaration(),
                replacementPlayers: this.loadReplacementPlayers(),
            },
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$t('tournamentSquad'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.getStepTarget(this.declaration.configTargets.squad)),
                    },
                    2: {
                        label: this.$t('participationAgreement'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.participationAgreement),
                    },
                    3: {
                        label: this.$t('teamLogo'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.teamLogo),
                    },
                    4: {
                        label: this.$t('nationalAnthem'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.nationalAnthem),
                    },
                    5: {
                        label: this.$t('medicalInsuranceCover'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.medicalInsuranceCover),
                    },
                    6: {
                        label: this.$t('kitDeclaration'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.kit),
                    },
                    7: {
                        label: this.$t('teamMemberAcceptanceForm'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.teamMemberAcceptanceForm),
                    },
                    8: {
                        label: this.$t('teamDisciplinaryCertificate'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.teamDisciplinaryCertificate),
                    },
                    9: {
                        label: this.$t('teamPhoneticGuide'),
                        hidden: !this.declaration || !this.declaration.existsInConfig(this.declaration.configTargets.teamPhoneticGuide),
                    },
                }
            },
            replacementPlayers: [],
        };
    },
    methods: {
        loadTournamentDeclaration(){
            //load declaration
            return this.$api.call.termsOfParticipation.tournamentDeclarationById(this.dataTournamentDeclarationId).then(data => {
                const declaration = new TournamentDeclarationModel(data);
                declaration.step = this.dataDeclarationStep;

                if(!declaration.step){
                    declaration.step = declaration.getCurrentStep();
                }

                //load tournament
                return this.$api.call.tournament.tournamentGetByEqId(declaration.getTournament().eqId).then(data => {
                    declaration.setTournament(TournamentModel.from(data));

                    //load team declaration
                    return this.$api.call.termsOfParticipation.declarationTeamDeclarationById(this.dataTeamDeclarationId).then(teamDeclarationConfiguration => {
                        if(teamDeclarationConfiguration.declarationId !== declaration.id){
                            throw new Error('something went wrong while requesting declaration team information');
                        }

                        declaration.setTeamDeclaration(new DeclarationTeamModel(teamDeclarationConfiguration));
                        declaration.getTeamDeclaration().step = declaration.step;

                        let stepName = declaration.getTeamDeclaration().step;
                        if(!stepName || stepName === 'main'){
                            stepName = declaration.configTargets.squad;
                        }
                        const teamDeclarationModuleId = declaration.getTeamDeclaration().findInConfiguration(stepName + declaration.configProperties.id);
                        if(!teamDeclarationModuleId){
                            throw new Error('team declaration has no squad id');
                        }

                        //load team persons
                        return this.$api.call.termsOfParticipation.declarationTeamDeclarationPersons(declaration.getTeamDeclaration().id, teamDeclarationModuleId).then(result => {
                            declaration.getTeamDeclaration().setPersons((result.persons || []).map(data => {
                                switch(data.roleType){
                                    case constants.personRoleType.player:
                                        return new PlayerModel(data);

                                    case constants.personRoleType.management:
                                        if (data.group && data.group === constants.group.medical){
                                            return new MedicModel(data);
                                        }
                                        return new ManagerModel(data);
                                }
                                return null;
                            }));

                            // find first tournament match date
                            return this.$api.call.match.matchSearch({
                                tournamentEqId: declaration.getTournament().eqId,
                                paging: {
                                    pageIndex: 0,
                                    pageSize: 100
                                }
                            }).then(result => {
                                return (result ? (result.data || []) : [])
                                    .map(data => new MatchModel(data))
                                    .map(match => match.kickOff)
                                    .reduce((v, c) => v < c ? v : c, undefined);
                            }).then(dates => {
                                if (Array.isArray(dates)) {
                                    declaration.firstTournamentMatchDate = dates.reduce((v, c) => v < c ? v : c, undefined) || null;
                                }
                                else {
                                    declaration.firstTournamentMatchDate = dates;
                                }

                                //load team kits
                                if (declaration.getTeamDeclaration().findInConfiguration('kit.id')) {
                                    const kitTypes = Object.values(TeamKitModel.types);
                                    kitTypes.map((type, index) => {
                                        this.$api.call.termsOfParticipation.declarationTeamDeclarationKit(declaration.getTeamDeclaration().id, declaration.getTeamDeclaration().findInConfiguration('kit.id'), type).then(data => {
                                            declaration.getTeamDeclaration().addKit(TeamKitModel.from(data), index);
                                        }, error => {
                                            //if 404 not found set as null
                                            if(error.status === 404){
                                                let newKit = TeamKitModel.from({});
                                                newKit.type = type;
                                                declaration.getTeamDeclaration().addKit(newKit, index);
                                                return;
                                            }
                                            throw error;
                                        });
                                    });
                                }

                                this.declaration = declaration;

                                //update subnavigation visibility
                                this.$set(this.subnavigation.elements[1], 'hidden', !this.declaration.existsInConfig(this.declaration.getStepTarget(this.declaration.configTargets.squad)));
                                this.$set(this.subnavigation.elements[2], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.participationAgreement));
                                this.$set(this.subnavigation.elements[3], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.teamLogo));
                                this.$set(this.subnavigation.elements[4], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.nationalAnthem));
                                this.$set(this.subnavigation.elements[5], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.medicalInsuranceCover));
                                this.$set(this.subnavigation.elements[6], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.kit));
                                this.$set(this.subnavigation.elements[7], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.teamDisciplinaryCertificate));
                                this.$set(this.subnavigation.elements[8], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.teamMemberAcceptanceForm));
                                this.$set(this.subnavigation.elements[9], 'hidden', !this.declaration.existsInConfig(this.declaration.configTargets.teamPhoneticGuide));

                                this.reloadReplacementPlayers();
                            });
                        });
                    });
                });
            });
        },
        reloadTournamentDeclaration(){
            this.apiData.tournamentDeclaration = this.loadTournamentDeclaration();
        },

        loadReplacementPlayers() {
            if(!this.declaration){
                return new Promise((resolve, reject) => this.apiData ? this.apiData.tournamentDeclaration.catch(reject) : '');
            }

            return this.$api.call.person.personSearch({
                roleType: 'Player',
                sport: this.declaration.sport,
                teamEqId: this.declaration.getTeamDeclaration().team.eqId,
                unionEqId: this.declaration.getTeamDeclaration().team.unionEqId,
                isPersonActive: true,
                paging: {
                    pageIndex: 0,
                    pageSize: 100
                },
            }).then(result => {
                this.replacementPlayers = (result.persons || []).map(data => new PlayerModel(data)).filter(person => (this.players || []).map(curPerson => curPerson.personId).find(curPersonId => curPersonId === person.id) === undefined);
            });
        },
        reloadReplacementPlayers(){
            this.apiData.replacementPlayers = this.loadReplacementPlayers();
        },

        playerReplaceOrLeave(data){
            switch(data.action){
                case 'hasLeftSquad':
                    return this.playerLeavesSquad(data);

                case 'replacement':
                default:
                    return this.replacePlayer(data);
            }
        },
        playerReplaceOrLeaveComplete(data) {
            this.reloadTournamentDeclaration();
        },

        replacePlayer(data) {
            const payload = {
                personEqId: data.replacementPlayer?.eqId,
                group: constants.group.player,
                signedByDoctor: data.confirmDoctor
            };

            const target = this.declaration.getStepTarget(this.declaration.configTargets.squad);
            const teamDeclarationId = this.declaration.getTeamDeclaration().id;
            const teamDeclarationModuleId = this.declaration.getTeamDeclaration().findInConfiguration(target + this.declaration.configProperties.id);
            const teamDeclarationPersonId = data.player?.id || 0;

            return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonReplace(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, payload);
        },
        playerLeavesSquad(data, leaveState = true){
            let payload = data.player?.toTopPersonUpdateJSON() || {};
            payload.hasLeftSquad = leaveState;

            const target = this.declaration.getStepTarget(this.declaration.configTargets.squad);
            const teamDeclarationId = this.declaration.getTeamDeclaration().id;
            const teamDeclarationModuleId = this.declaration.getTeamDeclaration().findInConfiguration(target + this.declaration.configProperties.id);
            const teamDeclarationPersonId = data.player?.id || 0;

            return this.$api.call.termsOfParticipation.declarationTeamUpdatePerson(teamDeclarationId, teamDeclarationModuleId, teamDeclarationPersonId, payload);
        },
    },
    watch: {
        dataTournamentDeclarationId(){
            this.reloadTournamentDeclaration();
        },
        dataTeamDeclarationId(){
            this.reloadTournamentDeclaration();
        },
        dataDeclarationStep(){
            this.reloadTournamentDeclaration();
        },
    },
};

export const declarationDetailMatchDisplayMixins = {
    computed: {
        startingLineupSize(){
            if(!this.declaration){
                return [];
            }

            return (this.declaration.isSevens ? 7 : 15);
        },
        replacementLineupSize(){
            if(!this.declaration){
                return [];
            }

            const maximumNumberOfReplacementPlayers = this.declaration.findInConfiguration(this.declaration.getStepTarget(this.declaration.configTargets.squad) + '.maximum-number-of-replacement-players') || 0;

            return (this.declaration.isSevens ? 5 : maximumNumberOfReplacementPlayers);
        },

        startingPlayers(){
            return this.teamOneStartingPlayers;
        },
        startingLineup(){
            return this.teamOneStartingLineup;
        },

        replacementPlayers(){
            return this.teamOneReplacementPlayers;
        },
        replacementLineup(){
            return this.teamOneReplacementLineup;
        },

        teamOneStartingPlayers(){
            if(!this.declaration){
                return [];
            }

            return this.filterPlayers(this.declaration.getTeamDeclaration().getStartingPlayers());
        },
        teamOneStartingLineup(){
            if(!this.declaration){
                return [];
            }

            if (this.declaration.isSevens){
                return this.createLineupByJerseyNumber(this.startingPlayers);
            }

            return this.createLineup(this.startingPlayers, this.startingLineupSize);
        },

        teamOneReplacementPlayers(){
            if(!this.declaration){
                return [];
            }

            return this.filterPlayers(this.declaration.getTeamDeclaration().getReplacementPlayers());
        },
        teamOneReplacementLineup(){
            if(!this.declaration){
                return [];
            }

            if (this.declaration.isSevens){
                return this.createLineupByJerseyNumber(this.replacementPlayers);
            }

            return this.createLineup(this.replacementPlayers, this.replacementLineupSize, this.startingLineupSize);
        },

        teamTwoStartingPlayers(){
            if(!this.declaration){
                return [];
            }

            return this.filterPlayers(this.declaration.getTeamDeclarationTwo().getStartingPlayers());
        },
        teamTwoStartingLineup(){
            if(!this.declaration){
                return [];
            }

            if (this.declaration.isSevens){
                return this.createLineupByJerseyNumber(this.teamTwoStartingPlayers);
            }

            return this.createLineup(this.teamTwoStartingPlayers, this.startingLineupSize);
        },

        teamTwoReplacementPlayers(){
            if(!this.declaration){
                return [];
            }

            return this.filterPlayers(this.declaration.getTeamDeclarationTwo().getReplacementPlayers());
        },
        teamTwoReplacementLineup(){
            if(!this.declaration){
                return [];
            }

            if (this.declaration.isSevens){
                return this.createLineupByJerseyNumber(this.teamTwoReplacementPlayers);
            }

            return this.createLineup(this.teamTwoReplacementPlayers, this.replacementLineupSize, this.startingLineupSize);
        },

        nonPlayingReserve(){
            return this.teamOneNonPlayingReserve;
        },
        teamOneNonPlayingReserve(){
            if(!this.declaration){
                return [];
            }

            return this.filterPlayers(this.declaration.getTeamDeclaration().getNonPlayingReserve());
        },
        teamTwoNonPlayingReserve(){
            if(!this.declaration){
                return [];
            }

            return this.filterPlayers(this.declaration.getTeamDeclarationTwo().getNonPlayingReserve());
        },
    },
    methods: {
        filterPlayers(persons){
            return persons.filter(person => person instanceof PlayerModel);
        },
        createLineup(players, size, offset = 0){
            const lineup = Array.from({ length: size }, () => null);

            players.forEach(player => {
                if(player && player.order){
                    lineup[player.order - 1 - offset] = player;
                }
                else if(player){
                    //fallback in case order is missing
                    lineup.push(player);
                }
            });

            return lineup;
        },
        createLineupByJerseyNumber(players){
            var sortBy = require('lodash.sortby');
            return sortBy(players, 'playerAttributes.shirtNumber');
        },
    },
};

export const declarationDetailMatchMixins = {

};
