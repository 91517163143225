import Model from '@/models/model.js';
import PersonMappingModel from '@/models/mapping/person.js';

/**
 * team mapping model class
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default class TeamMappingModel extends Model {
    /**
     * score
     *
     * @type {number|null}
     */
    score = null;

    /**
     * is team one
     *
     * @type {boolean}
     */
    isTeam1 = false;

    /**
     * mapping
     *
     * @type {array}
     */
    mapping = [];

    /**
     * players
     *
     * @type {array}
     */
    players = [];

    /**
     * staff
     *
     * @type {array}
     */
    staff = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.name = data.teamName || '';
            this.eqId = data.teamEqId || '';
            this.teamAliasEqId = data.teamAliasEqId || '';
            this.result = data.result || '';
            this.score = data.score || null;
            this.isTeam1 = data.isTeam1 || false;
            this.mapping = data.mapping || [];
            this.players = (data.players || []).map(playerData => new PersonMappingModel(playerData));
            this.staff = (data.staff || []).map(staffData => new PersonMappingModel(staffData));
        }
    }
}
