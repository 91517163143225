<template>
    <vue-countdown
        v-bind:time="time"
        v-slot="{ days, hours, minutes, seconds }"
    >
        <span class="mr-2">{{ days }}{{ $t('countdownDays') }}</span>
        <span class="mr-2">{{ hours }}{{ $t('countdownHours') }}</span>
        <span class="mr-2">{{ minutes }}{{ $t('countdownMinutes') }}</span>
        <span>{{ seconds }}{{ $t('countdownSeconds') }}</span>
    </vue-countdown>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
    name: 'countdown',
    components: {
        VueCountdown,
    },
    props: {
        until: {
            type: Date,
            mandatory: true,
            default(){
                return new Date();
            },
        },
        offset: {
            type: [Number, String],
            mandatory: false,
            default: null,
        },
    },
    computed: {
        time(){
            if(!this.until){
                return null;
            }

            let timeRemaining = this.until - new Date();

            if(this.offset !== null){
                const offset = parseInt(this.offset, 10) || 0;

                timeRemaining -= this.until.getTimezoneOffset() * 60000;
                timeRemaining -= offset * 60000;
            }

            return Math.max(timeRemaining, 0);
        },
    },
};
</script>
