<script>
import formComponent from '@/components/form.vue';
import SeriesModel from '@/models/series.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'createSeriesForm',
    extends: formComponent,
    props: {
        model: {
            type: SeriesModel,
        },
        hideMapping: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('seriesName'),
                        placeholder: this.$t('enterSeriesName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'mainSeason',
                        type: 'number',
                        label: this.$t('mainYear'),
                        placeholder: this.$t('enterMainYear'),
                        min: constants.inputMainYear.min,
                        max: constants.inputMainYear.max,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'sport',
                        type: 'vselectSport',
                        label: this.$tc('sport', 1),
                        placeholder: this.$t('selectSport'),
                        filter: Object.values(constants.sevenSport),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'seriesTypeId',
                        type: 'vselectSeriesType',
                        label: this.$t('seriesType'),
                        placeholder: this.$t('filterBySeriesType'),
                        disabled: this.readonly,
                        required: true,
                    },
                ]),

                {
                    type: 'hline',
                },
                {
                    id: 'seriesLogo',
                    type: 'headline',
                    text: this.$t('seriesLogo'),
                },
                this.createFormRow([
                    {
                        id: 'logo',
                        type: 'file',
                        label: this.$t('file'),
                        accept: constants.fileAcceptance.images,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),

                {
                    type: 'hline',
                    hidden: this.hideMapping,
                },
                {
                    id: 'mapping',
                    type: 'headline',
                    text: this.$t('mapping'),
                    hidden: this.hideMapping,
                },
                this.createFormRow(this.generateMappingProviderControls(), null, this.hideMapping),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                seriesTypeId: 'seriesType',
            };
        },
    },
};
</script>
