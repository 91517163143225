import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationLocationIndex from '@/views/administration/location/index.vue';
import administrationLocationCreate from '@/views/administration/location/create.vue';
import administrationLocationEdit from '@/views/administration/location/edit.vue';

export default {
    path: 'location/',
    component: pageFrame,
    children: [
        {
            name: 'administrationLocationIndex',
            path: constants.routePaths.index,
            component: administrationLocationIndex,
            meta: {
                permission: permissions.locations.list,
            },
        },
        {
            name: 'administrationLocationCreate',
            path: constants.routePaths.create,
            component: administrationLocationCreate,
            meta: {
                permission: permissions.locations.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.locationId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationLocationEdit',
                    path: constants.routePaths.edit,
                    component: administrationLocationEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.locationId]),
                    meta: {
                        permission: permissions.locations.update,
                    },
                },
            ],
        },
    ],
};
