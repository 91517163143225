<template>
    <modal
         v-bind="modalProps"
         v-on="modalEvents"
         v-bind:confirmHandler="handleParameter"
         v-bind:scrollable="false"
    >
        <template v-slot="{ formId, submit }">
            <addEditTournamentStandingTypeParameterForm
                v-bind:dataId="formId"
                v-bind:values="values"
                v-bind:hideValue="hideValue"
                v-bind:hideDiscriminator="hideDiscriminator"
                v-bind:parameterType="parameterType"
                v-bind:errors="errorIds"
                v-on:change="updateParameters"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import addEditTournamentStandingTypeParameterForm from '@/components/forms/addEditTournamentStandingTypeParameter.vue';

import TournamentStandingTypeParameterModel from '@/models/tournamentStandingTypeParameter.js';

/**
 * team alias edit modal component
 */
export default {
    name: 'addEditTournamentStandingTypeParameterModal',
    extends: base,
    components: {
        addEditTournamentStandingTypeParameterForm,
    },
    props: {
        parameters: {
            type: TournamentStandingTypeParameterModel,
            default(){
                return new TournamentStandingTypeParameterModel();
            }
        },
        create: {
            type: Boolean,
            default: false,
        },
        standingTypeId: {
            type: Number,
            default: 0,
        },
        parameterType: {
            type: String,
            default: '',
        },
        hideValue: {
            type: Boolean,
            default: false,
        },
        hideDiscriminator: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            values: {
                discriminator: this.parameters.discriminator,
                group: this.parameters.group,
                id: this.parameters.id,
                typeId: this.parameters.typeId,
                typeName: this.parameters.typeName,
                value: this.parameters.value,
            },
        };
    },
    computed: {
        payload(){
            return this.parameters;
        },
    },
    methods: {
        updateParameters(values){
            this.parameters.discriminator = values.discriminator;
            this.parameters.typeId = values.typeId;
            this.parameters.value = values.value;
        },
        handleParameter(){
            let paramToSave = null;
            if (this.parameterType === 'point') {
                paramToSave = {
                    discriminator: this.parameters.discriminator,
                    parameterTypeId: this.parameters.typeId,
                    standingTypeId: this.standingTypeId,
                    value: this.parameters.value,
                }
            }
            if (this.parameterType === 'order') {
                paramToSave = {
                    parameterTypeId: this.parameters.typeId,
                    standingTypeId: this.standingTypeId,
                }
            }

            // create
            if (this.create) {
                return this.$api.call.tournament.tournamentStandingTypeParameterCreate(this.parameterType, paramToSave);
            }
            // edit
            else {
                return this.$api.call.tournament.tournamentStandingTypeParameterPointUpdateById(this.parameters.id, paramToSave);
            }
        }
    },
};
</script>
