<script>
import formComponent from '@/components/form.vue';
import DisciplinaryModel from '@/models/disciplinary.js';

/**
 * component
 */
export default {
    name: 'editDisciplinaryForm',
    extends: formComponent,
    props: {
        model: {
            type: DisciplinaryModel,
        },
        matchReadonly: {
            type: Boolean,
            default(){
                return false;
            },
        },
        teamReadonly: {
            type: Boolean,
            default(){
                return false;
            },
        },
        citingCommissionerReadonly: {
            type: Boolean,
            default: false,
        },
        tournamentEqId: {
            type: String,
            default: '',
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'matchId',
                        type: 'vselectMatch',
                        label: this.$tc('match', 1),
                        placeholder: this.$t('selectMatch'),
                        disabled: this.readonly || this.matchReadonly,
                        tournamentEqId: this.tournamentEqId,
                    },
                    {
                        id: 'matchTeamId',
                        type: 'vselectMatchTeam',
                        label: this.$tc('team', 1),
                        placeholder: this.$t('selectTeam'),
                        disabled: this.readonly || this.teamReadonly,
                        required: true,
                    },
                    {
                        id: 'playerId',
                        type: 'vselectPlayer',
                        label: this.$tc('player', 1),
                        placeholder: this.$t('selectTournamentPlayer'),
                        valueProperty: 'matchTeamPlayerId',
                        disabled: this.readonly,
                        dataDependency: {
                            matchTeamId: true,
                        },
                        required: true,
                    },
                    {
                        id: 'actionTypeId',
                        type: 'vselectActionType',
                        label: this.$t('disciplinaryAction'),
                        placeholder: this.$t('selectDisciplinaryAction'),
                        disabled: this.readonly,
                        actionTypeGroup: 'Card',
                        required: true,
                    },
                    {
                        id: 'matchStatusId',
                        type: 'vselectMatchStatus',
                        label: this.$t('matchStatus'),
                        placeholder: this.$t('selectMatchStatus'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'timeInHalf',
                        type: 'text',
                        label: this.$t('timeInHalf'),
                        placeholder: 'HH:mm',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'lawId',
                        type: 'vselectLaw',
                        label: this.$t('law'),
                        placeholder: this.$t('selectLawReference'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'judicialOfficer',
                        type: 'vselectMatchOfficial',
                        label: this.$t('judicialOfficer'),
                        placeholder: this.$t('selectJudicialOfficer'),
                        disabled: this.readonly,
                        valueProperty: 'personRoleEqId',
                        officialType: 'Judicial Officer',
                    },
                    {
                        id: 'citingCommissioner',
                        type: 'vselectMatchOfficial',
                        label: this.$t('citingCommissioner'),
                        placeholder: (!this.readonly && !this.citingCommissionerReadonly) ? this.$t('selectCitingCommissioner') : '',
                        disabled: this.readonly || this.citingCommissionerReadonly,
                        valueProperty: 'personRoleEqId',
                        officialType: 'Citing Commissioner',
                    },
                    {
                        id: 'appealsOfficer',
                        type: 'vselectMatchOfficial',
                        label: this.$t('appealsOfficer'),
                        placeholder: this.$t('selectAppealsOfficer'),
                        disabled: this.readonly,
                        valueProperty: 'personRoleEqId',
                        officialType: 'Appeal Officer',
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'citing',
                        type: 'textarea',
                        label: this.$t('hearingOutcome'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'internalComments',
                        type: 'textarea',
                        label: this.$t('internalComments'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'offence',
                        type: 'textarea',
                        label: this.$t('offence'),
                        disabled: this.readonly,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                time: 'timeInHalf',
            };
        },
    },
};
</script>
