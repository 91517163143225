<template>
    <wrapper
        v-bind:title="title"
        v-bind:waitFor="waitFor"
        v-bind:waitForRetry="waitForRetry"
        noLine
        noShadow
        noPadding
    >
        <b-table
            v-bind:fields="tableFields"
            v-bind:items="tableItems"
            head-variant="list"
            responsive
            no-border-collapse
            class="mb-0"
        >
            <template #head()="data">
                <div v-bind:class="{ 'pl-4': data.column === 'name' }">{{ data.label.toUpperCase() }}</div>
            </template>
            <template #cell(name)="data">
                <div v-bind:class="{ 'text-nowrap': true, 'font-weight-bold': data.item.main, 'pl-4': data.item.main, 'pl-5': !data.item.main }">
                    <div v-bind:class="{ 'pl-2': !data.item.main }">{{ data.value }}</div>
                </div>
            </template>
            <template #cell(status)="data">
                <loading
                    v-bind:waitFor="statusUpdate"
                    v-bind:waitForRetry="statusUpdateRetry"
                    v-bind:throbberProps="{ small: true }"
                    class="align-items-start"
                >
                    <statusIndicatorIcon v-bind:status="data.value" v-bind:showText="true"/>
                </loading>
            </template>
            <template #cell(remaining)="data">
                <countdown v-bind:until="data.value"/>
            </template>
        </b-table>
    </wrapper>
</template>

<script>
import wrapper from '@/components/wrapper.vue';
import statusIndicatorIcon from '@/components/statusIndicatorIcon.vue';
import countdown from '@/components/countdown.vue';
import loading from '@/components/loading.vue';

export default {
    name: 'declarationWorkflowSummaries',
    components: {
        wrapper,
        statusIndicatorIcon,
        countdown,
        loading,
    },
    props: {
        /**
         * change default collapse state to hidden
         */
        workflowSummaries: {
            type: Array,
            required: true,
            default(){
                return [];
            },
        },
        /**
         * wrapper title
         */
        title: {
            type: String,
            default: null,
        },
        /**
         * promise to wait for before showing wrapper top, default, and footer slot content
         */
        waitFor: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * method to bind to click action on error msg retry button
         * if not defined retry button is not shown
         */
        waitForRetry: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * status update loading promise
         */
        statusUpdate: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * method to bind to click action on status update error msg retry button
         * if not defined retry button is not shown
         */
        statusUpdateRetry: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data(){
        return {
            tableFields: [
                {
                    key: 'name',
                    label: this.$tc('declaration', 2),
                    class: 'py-4',
                },
                {
                    key: 'status',
                    label: this.$t('status'),
                    class: 'py-4 col-2',
                },
                {
                    key: 'deadline',
                    label: this.$t('deadline'),
                    class: 'py-4 col-2',
                },
                {
                    key: 'remaining',
                    label: this.$t('remaining'),
                    class: 'py-4 col-2',
                },
            ],
        };
    },
    computed: {
        tableItems(){
            if(!this.workflowSummaries){
                return [];
            }

            return this.workflowSummaries.map(row => ({
                name: row.name,
                status: row.status,
                deadline: this.$fd(row.deadline, 'date'),
                remaining: row.deadline,
                main: row.main,
            }));
        },
    },
};
</script>
