import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationTournamentTypeIndex from '@/views/administration/tournamentType/index.vue';
import administrationTournamentTypeCreate from '@/views/administration/tournamentType/create.vue';
import administrationTournamentTypeDetail from '@/views/administration/tournamentType/detail.vue';
import administrationTournamentTypeEdit from '@/views/administration/tournamentType/edit.vue';

export default {
    path: 'tournament-type/',
    component: pageFrame,
    children: [
        {
            name: 'administrationTournamentTypeIndex',
            path: constants.routePaths.index,
            component: administrationTournamentTypeIndex,
            meta: {
                permission: permissions.tournamentTypes.list,
            },
        },
        {
            name: 'administrationTournamentTypeCreate',
            path: constants.routePaths.create,
            component: administrationTournamentTypeCreate,
            meta: {
                permission: permissions.tournamentTypes.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.tournamentTypeId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationTournamentTypeDetail',
                    path: constants.routePaths.index,
                    component: administrationTournamentTypeDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentTypeId]),
                    meta: {
                        permission: permissions.tournamentTypes.detail,
                    },
                },
                {
                    name: 'administrationTournamentTypeEdit',
                    path: constants.routePaths.edit,
                    component: administrationTournamentTypeEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentTypeId]),
                    meta: {
                        permission: permissions.tournamentTypes.update,
                    },
                },
            ],
        },
    ],
};
