import Model from '@/models/model.js';

const formDependencyEnabled = 'Boolean(enabled)';
const formDependencyWorkflowId = `${formDependencyEnabled} && Boolean(workflowId)`;
const formDependencySquadEnabled = `${formDependencyWorkflowId} && Boolean(squadEnabled)`;
const formDependencyPreliminarySquadEnabled = `${formDependencyWorkflowId} && Boolean(preliminarySquadEnabled)`;

/**
 * country model class
 */
export default class DeclarationTOPModel extends Model {
    static formDependencies = {
        enabled: formDependencyEnabled,
        workflowId: formDependencyWorkflowId,

        squadEnabled: formDependencySquadEnabled,
        squadPersonHeadshotsEnabled: `${formDependencySquadEnabled} && Boolean(squadPersonHeadshotsEnabled)`,
        squadPassportInformationEnabled: `${formDependencySquadEnabled} && Boolean(squadPassportInformationEnabled)`,
        squadMedicalCertificatesEnabled: `${formDependencySquadEnabled} && Boolean(squadMedicalCertificatesEnabled)`,
        squadBioEnabled: `${formDependencySquadEnabled} && Boolean(squadBioEnabled)`,
        squadPersonPositionEnabled: `${formDependencySquadEnabled} && Boolean(squadPersonPositionEnabled)`,

        preliminarySquadEnabled: formDependencyPreliminarySquadEnabled,
        preliminarySquadPersonHeadshotsEnabled: `${formDependencyPreliminarySquadEnabled} && Boolean(preliminarySquadPersonHeadshotsEnabled)`,
        preliminarySquadPassportInformationEnabled: `${formDependencyPreliminarySquadEnabled} && Boolean(preliminarySquadPassportInformationEnabled)`,
        preliminarySquadMedicalCertificatesEnabled: `${formDependencyPreliminarySquadEnabled} && Boolean(preliminarySquadMedicalCertificatesEnabled)`,
        preliminarySquadBioEnabled: `${formDependencyPreliminarySquadEnabled} && Boolean(preliminarySquadBioEnabled)`,
        preliminarySquadPersonPositionEnabled: `${formDependencyPreliminarySquadEnabled} && Boolean(preliminarySquadPersonPositionEnabled)`,

        replacementsEnabled: `${formDependencySquadEnabled} && Boolean(replacementsEnabled)`,
        replacementsNonPlayersEnabled: `${formDependencySquadEnabled} && Boolean(replacementsNonPlayersEnabled)`,

        participationAgreementEnabled: `${formDependencyWorkflowId} && Boolean(participationAgreementEnabled)`,
        teamLogoEnabled: `${formDependencyWorkflowId} && Boolean(teamLogoEnabled)`,
        nationalAnthemEnabled: `${formDependencyWorkflowId} && Boolean(nationalAnthemEnabled)`,
        teamPhoneticGuideEnabled: `${formDependencyWorkflowId} && Boolean(teamPhoneticGuideEnabled)`,
        medicalInsuranceCoverEnabled: `${formDependencyWorkflowId} && Boolean(medicalInsuranceCoverEnabled)`,
        teamDisciplinaryCertificateEnabled: `${formDependencyWorkflowId} && Boolean(teamDisciplinaryCertificateEnabled)`,
        teamMemberAcceptanceFormEnabled: `${formDependencyWorkflowId} && Boolean(teamMemberAcceptanceFormEnabled)`,

        kitEnabled: `${formDependencyWorkflowId} && Boolean(kitEnabled)`,
    };

    static joinFormDependencies(dependencies = [], useAnd = false){
        return '(' + dependencies.join(`) ${useAnd ? '&&' : '||'} (`) + ')';
    };

    /**
     * declaration enabled state
     *
     * @type {boolean}
     */
    enabled = false;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            //base
            this.enabled = Boolean(data.enabled);
            this.openingDate = this.toDate(data.openingDate);
            this.openingTime = data.openingTime || '';
            this.openingGmtOffset = data.openingGmtOffset || 0;
            this.deadlineDate = this.toDate(data.deadlineDate);
            this.deadlineTime = data.deadlineTime || '';
            this.deadlineGmtOffset = data.deadlineGmtOffset || 0;
            this.publishOffset = data.publishOffset || null;
            this.teamsheetsPublishOffset = data.teamsheetsPublishOffset || null;
            this.workflowId = data.workflowId || null;
            this.step = data.step || null;

            //squad
            this.squadEnabled = true;
            this.squadDeadline = this.toDate(data.squadDeadline);
            this.squadDeadlineTime = data.squadDeadlineTime || this.toDateTime(data.squadDeadline) || '';
            this.squadDeadlineGmtOffset = data.squadDeadlineGmtOffset || this.toDateOffset(data.squadDeadline) || 0;
            this.squadMinimumNumberOfPlayers = Number(data.squadMinimumNumberOfPlayers) || 0;
            this.squadMaximumNumberOfPlayers = Number(data.squadMaximumNumberOfPlayers) || 0;
            this.squadMinimumNumberOfManagers = Number(data.squadMinimumNumberOfManagers) || 0;
            this.squadMaximumNumberOfManagers = Number(data.squadMaximumNumberOfManagers) || 0;
            this.squadMinimumNumberOfMedics = Number(data.squadMinimumNumberOfMedics) || 0;
            this.squadMaximumNumberOfMedics = Number(data.squadMaximumNumberOfMedics) || 0;
            this.squadMinimumTotalNumberOfManagement = Number(data.squadMinimumTotalNumberOfManagement) || 0;
            this.squadMaximumTotalNumberOfManagement = Number(data.squadMaximumTotalNumberOfManagement) || 0;

            this.squadPersonHeadshotsEnabled = Boolean(data.squadPersonHeadshotsEnabled);
            this.squadPersonHeadshotsDeadline = this.toDate(data.squadPersonHeadshotsDeadline);

            this.squadPassportInformationEnabled = Boolean(data.squadPassportInformationEnabled);
            this.squadPassportInformationDeadline = this.toDate(data.squadPassportInformationDeadline);

            this.squadMedicalCertificatesEnabled = Boolean(data.squadMedicalCertificatesEnabled);
            this.squadMedicalCertificatesDeadline = this.toDate(data.squadMedicalCertificatesDeadline);

            this.squadBioEnabled = Boolean(data.squadBioEnabled);
            this.squadBioDeadline = this.toDate(data.squadBioDeadline);

            this.squadPersonPositionEnabled = Boolean(data.squadPersonPositionEnabled);
            this.squadPersonPositionDeadline = this.toDate(data.squadPersonPositionDeadline);

            //preliminary squad
            this.preliminarySquadEnabled = true;
            this.preliminarySquadDeadline = this.toDate(data.preliminarySquadDeadline);
            this.preliminarySquadMinimumNumberOfPlayers = Number(data.preliminarySquadMinimumNumberOfPlayers) || 0;
            this.preliminarySquadMaximumNumberOfPlayers = Number(data.preliminarySquadMaximumNumberOfPlayers) || 0;
            this.preliminarySquadMinimumNumberOfManagers = Number(data.preliminarySquadMinimumNumberOfManagers) || 0;
            this.preliminarySquadMaximumNumberOfManagers = Number(data.preliminarySquadMaximumNumberOfManagers) || 0;
            this.preliminarySquadMinimumNumberOfMedics = Number(data.preliminarySquadMinimumNumberOfMedics) || 0;
            this.preliminarySquadMaximumNumberOfMedics = Number(data.preliminarySquadMaximumNumberOfMedics) || 0;
            this.preliminarySquadMinimumTotalNumberOfManagement = Number(data.preliminarySquadMinimumTotalNumberOfManagement) || 0;
            this.preliminarySquadMaximumTotalNumberOfManagement = Number(data.preliminarySquadMaximumTotalNumberOfManagement) || 0;

            this.preliminarySquadPersonHeadshotsEnabled = Boolean(data.preliminarySquadPersonHeadshotsEnabled);
            this.preliminarySquadPersonHeadshotsDeadline = this.toDate(data.preliminarySquadPersonHeadshotsDeadline);

            this.preliminarySquadPassportInformationEnabled = Boolean(data.preliminarySquadPassportInformationEnabled);
            this.preliminarySquadPassportInformationDeadline = this.toDate(data.preliminarySquadPassportInformationDeadline);

            this.preliminarySquadMedicalCertificatesEnabled = Boolean(data.preliminarySquadMedicalCertificatesEnabled);
            this.preliminarySquadMedicalCertificatesDeadline = this.toDate(data.preliminarySquadMedicalCertificatesDeadline);

            this.preliminarySquadBioEnabled = Boolean(data.preliminarySquadBioEnabled);
            this.preliminarySquadBioDeadline = this.toDate(data.preliminarySquadBioDeadline);

            this.preliminarySquadPersonPositionEnabled = Boolean(data.preliminarySquadPersonPositionEnabled);
            this.preliminarySquadPersonPositionDeadline = this.toDate(data.preliminarySquadPersonPositionDeadline);

            //replacements
            this.replacementsEnabled = true;
            this.replacementsMinimumNumberOfPlayers = Number(data.replacementsMinimumNumberOfPlayers) || 0;
            this.replacementsMaximumNumberOfPlayers = Number(data.replacementsMaximumNumberOfPlayers) || 0;
            this.replacementsNonPlayersEnabled = Boolean(data.replacementsNonPlayersEnabled === 'false' ? false : data.replacementsNonPlayersEnabled);
            this.replacementsMinimumNumberOfNonPlayers = Number(data.replacementsMinimumNumberOfNonPlayers) || 0;
            this.replacementsMaximumNumberOfNonPlayers = Number(data.replacementsMaximumNumberOfNonPlayers) || 0;

            //uploads
            this.participationAgreementEnabled = Boolean(data.participationAgreementEnabled);
            this.participationAgreementDeadline = this.toDate(data.participationAgreementDeadline);

            this.teamLogoEnabled = Boolean(data.teamLogoEnabled);
            this.teamLogoDeadline = this.toDate(data.teamLogoDeadline);

            this.nationalAnthemEnabled = Boolean(data.nationalAnthemEnabled);
            this.nationalAnthemDeadline = this.toDate(data.nationalAnthemDeadline);

            this.medicalInsuranceCoverEnabled = Boolean(data.medicalInsuranceCoverEnabled);
            this.medicalInsuranceCoverDeadline = this.toDate(data.medicalInsuranceCoverDeadline);

            this.teamDisciplinaryCertificateEnabled = Boolean(data.teamDisciplinaryCertificateEnabled);
            this.teamDisciplinaryCertificateDeadline = this.toDate(data.teamDisciplinaryCertificateDeadline);

            this.teamMemberAcceptanceFormEnabled = Boolean(data.teamMemberAcceptanceFormEnabled);
            this.teamMemberAcceptanceFormDeadline = this.toDate(data.teamMemberAcceptanceFormDeadline);

            this.teamPhoneticGuideEnabled = Boolean(data.teamPhoneticGuideEnabled);
            this.teamPhoneticGuideDeadline = this.toDate(data.teamPhoneticGuideDeadline);

            //kit
            this.kitEnabled = Boolean(data.kitEnabled);
            this.kitDeadline = this.toDate(data.kitDeadline);

            //templates
            this.participationAgreementTemplateStorage = data.participationAgreementTemplateStorage || data.participationAgreementTemplate || '';
            this.participationAgreementTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.participationAgreementTemplateStorage) || null;

            this.teamLogoTemplateStorage = data.teamLogoTemplateStorage || data.teamLogoTemplate || '';
            this.teamLogoTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.teamLogoTemplateStorage) || null;

            this.nationalAnthemTemplateStorage = data.nationalAnthemTemplateStorage || data.nationalAnthemTemplate || '';
            this.nationalAnthemTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.nationalAnthemTemplateStorage) || null;

            this.medicalInsuranceCoverTemplateStorage = data.medicalInsuranceCoverTemplateStorage || data.medicalInsuranceCoverTemplate || '';
            this.medicalInsuranceCoverTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.medicalInsuranceCoverTemplateStorage) || null;

            this.teamDisciplinaryCertificateTemplateStorage = data.teamDisciplinaryCertificateTemplateStorage || data.teamDisciplinaryCertificateTemplate || '';
            this.teamDisciplinaryCertificateTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.teamDisciplinaryCertificateTemplateStorage) || null;

            this.teamMemberAcceptanceFormTemplateStorage = data.teamMemberAcceptanceFormTemplateStorage || data.teamMemberAcceptanceFormTemplate || '';
            this.teamMemberAcceptanceFormTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.teamMemberAcceptanceFormTemplateStorage) || null;

            this.teamPhoneticGuideTemplateStorage = data.teamPhoneticGuideTemplateStorage || data.teamPhoneticGuideTemplate || '';
            this.teamPhoneticGuideTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.teamPhoneticGuideTemplateStorage) || null;
        }
    }

    setParticipationAgreementTemplateStorage(participationAgreementTemplateStorage){
        this.participationAgreementTemplateStorage = participationAgreementTemplateStorage;
        this.participationAgreementTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.participationAgreementTemplateStorage);
    }

    setTeamLogoTemplateStorage(teamLogoTemplateStorage){
        this.teamLogoTemplateStorage = teamLogoTemplateStorage;
        this.teamLogoTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.teamLogoTemplateStorage);
    }

    setNationalAnthemTemplateStorage(nationalAnthemTemplateStorage){
        this.nationalAnthemTemplateStorage = nationalAnthemTemplateStorage;
        this.nationalAnthemTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.nationalAnthemTemplateStorage);
    }

    setMedicalInsuranceCoverTemplateStorage(medicalInsuranceCoverTemplateStorage){
        this.medicalInsuranceCoverTemplateStorage = medicalInsuranceCoverTemplateStorage;
        this.medicalInsuranceCoverTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.medicalInsuranceCoverTemplateStorage);
    }

    setTeamDisciplinaryCertificateTemplateStorage(teamDisciplinaryCertificateTemplateStorage){
        this.teamDisciplinaryCertificateTemplateStorage = teamDisciplinaryCertificateTemplateStorage;
        this.teamDisciplinaryCertificateTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.teamDisciplinaryCertificateTemplateStorage);
    }

    setTeamMemberAcceptanceFormTemplateStorage(teamMemberAcceptanceFormTemplateStorage){
        this.teamMemberAcceptanceFormTemplateStorage = teamMemberAcceptanceFormTemplateStorage;
        this.teamMemberAcceptanceFormTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.teamMemberAcceptanceFormTemplateStorage);
    }

    setTeamPhoneticGuideTemplateStorage(teamPhoneticGuideTemplateStorage){
        this.teamPhoneticGuideTemplateStorage = teamPhoneticGuideTemplateStorage;
        this.teamPhoneticGuideTemplate = Model._api.call.termsOfParticipation.resolveStoragePath(this.teamPhoneticGuideTemplateStorage);
    }

    getFileUploads(){
        const fileUploads = {};

        if(this.participationAgreementTemplate instanceof File && this.participationAgreementEnabled){
            fileUploads.participationAgreementTemplate = this.participationAgreementTemplate;
        }
        if(this.teamLogoTemplate instanceof File && this.teamLogoEnabled){
            fileUploads.teamLogoTemplate = this.teamLogoTemplate;
        }
        if(this.nationalAnthemTemplate instanceof File && this.nationalAnthemEnabled){
            fileUploads.nationalAnthemTemplate = this.nationalAnthemTemplate;
        }
        if(this.medicalInsuranceCoverTemplate instanceof File && this.medicalInsuranceCoverEnabled){
            fileUploads.medicalInsuranceCoverTemplate = this.medicalInsuranceCoverTemplate;
        }
        if(this.teamDisciplinaryCertificateTemplate instanceof File && this.teamDisciplinaryCertificateEnabled){
            fileUploads.teamDisciplinaryCertificateTemplate = this.teamDisciplinaryCertificateTemplate;
        }
        if(this.teamMemberAcceptanceFormTemplate instanceof File && this.teamMemberAcceptanceFormEnabled){
            fileUploads.teamMemberAcceptanceFormTemplate = this.teamMemberAcceptanceFormTemplate;
        }
        if(this.teamPhoneticGuideTemplate instanceof File && this.teamPhoneticGuideEnabled){
            fileUploads.teamPhoneticGuideTemplate = this.teamPhoneticGuideTemplate;
        }

        return fileUploads;
    }

    setFileStorages(fileUploads, storageFiles){
        Object.keys(fileUploads).forEach((key, i) => {
            const storageFile = storageFiles[i];
            if(!storageFile){
                return;
            }

            switch(key){
                case 'participationAgreementTemplate':
                    this.setParticipationAgreementTemplateStorage(storageFile);
                    break;

                case 'teamLogoTemplate':
                    this.setTeamLogoTemplateStorage(storageFile);
                    break;

                case 'nationalAnthemTemplate':
                    this.setNationalAnthemTemplateStorage(storageFile);
                    break;

                case 'medicalInsuranceCoverTemplate':
                    this.setMedicalInsuranceCoverTemplateStorage(storageFile);
                    break;

                case 'teamDisciplinaryCertificateTemplate':
                    this.setTeamDisciplinaryCertificateTemplateStorage(storageFile);
                    break;

                case 'teamMemberAcceptanceFormTemplate':
                    this.setTeamMemberAcceptanceFormTemplateStorage(storageFile);
                    break;

                case 'teamPhoneticGuideTemplate':
                    this.setTeamPhoneticGuideTemplateStorage(storageFile);
                    break;
            }
        });
    }

    setFileErrors(fileUploads, error){
        //set custom file upload validation error
        if(fileUploads.participationAgreementTemplate){
            error.errors.participationAgreementTemplate = this.$t('fileUploadError');
        }
        if(fileUploads.teamLogoTemplate){
            error.errors.teamLogoTemplate = this.$t('fileUploadError');
        }
        if(fileUploads.nationalAnthemTemplate){
            error.errors.nationalAnthemTemplate = this.$t('fileUploadError');
        }
        if(fileUploads.medicalInsuranceCoverTemplate){
            error.errors.medicalInsuranceCoverTemplate = this.$t('fileUploadError');
        }
        if(fileUploads.teamDisciplinaryCertificateTemplate){
            error.errors.teamDisciplinaryCertificateTemplate = this.$t('fileUploadError');
        }
        if(fileUploads.teamMemberAcceptanceFormTemplate){
            error.errors.teamMemberAcceptanceFormTemplate = this.$t('fileUploadError');
        }
        if(fileUploads.teamPhoneticGuideTemplate){
            error.errors.teamPhoneticGuideTemplate = this.$t('fileUploadError');
        }

        return error;
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return '';
    }
}
