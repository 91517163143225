<script>
import itemList from '@/components/itemList.vue';
import penaltiesRow from '@/components/rows/administration/penalties.vue';
import PenaltyModel from '@/models/penalty.js';

/**
 * complex penalty list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'penaltiesList',
    extends: itemList,
    components: {
        penaltiesRow,
    },
    props: {
        sport: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            //overwrites
            rowElement: penaltiesRow,
            rowItemPropName: 'penalty',
            selectItemProp: 'penalty',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showView: true,
                showRemove: true,
            };
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.rankings.alterationGetBySport(this.sport).then(result => {
                return {
                    items: this.sortItems(
                        (result || []).map(data => new PenaltyModel(data)),
                        sortKey,
                        sortAsc,
                        pageIndex,
                        pageSize,
                    ),
                    total: (result || []).length,
                };
            });
        },

        requestItemRemove(item){
            return this.$api.call.rankings.alterationDeleteById(item.id);
        },
    },
};
</script>
