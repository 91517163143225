import Model from '@/models/model.js';

/**
 * law model class
 */
export default class LawModel extends Model {
    /**
     * description
     *
     * @type {string}
     */
    description = '';

    /**
     * reference
     *
     * @type {string}
     */
    reference = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.description = data.description || '';
            this.eqId = data.eqId || '';
            this.id = data.id || 0;
            this.reference = data.reference || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.description;
    }

    toJSON() {
        return {
            description: this.description,
            reference: this.reference,
        };
    }
}
