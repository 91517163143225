<template>
    <div class="px-0 px-lg-5 py-0">
        <b-card v-bind:class="rowClasses">
            <b-card-text
                v-for="(item, index) in elements"
                v-bind:key="index"
                v-bind:class="itemClasses(item)"
                v-on:click="handleSort(item)"
            >{{ item.label }}</b-card-text>
        </b-card>
    </div>
</template>

<script>

/**
 * row header component
 */
export default {
    name: 'listHeader',
    props: {
        /**
         * header row elements
         */
        elements: {
            type: Array,
            mandatory: true,
            default(){
                return [];
            }
        },
        /**
         * sort key
         */
        sortKey: {
            type: String,
            default: null,
        },
        /**
         * sort direction (true = ASC, false = DESC)
         */
        sortAsc: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        itemClasses(item){
            const classes = {};

            //include config classes
            if(item.cssclass){
                let configClasses = item.cssclass;

                //split into array if string
                if(typeof configClasses === 'string'){
                    configClasses = configClasses.split(' ');
                }

                //convert to object if array
                if(configClasses instanceof Array){
                    configClasses = configClasses.reduce((result, current) => {
                        result[current] = true;
                        return result;
                    }, {});
                }

                //combine if object
                if(configClasses instanceof Object){
                    Object.assign(classes, configClasses);
                }
            }

            //basic classes
            classes['text-uppercase'] = true;

            //sorting classes
            classes.sortable = !!item.sortKey;
            classes.sorted = (this.sortKey === item.sortKey);
            classes.sortedAsc = (classes.sorted && this.sortAsc);
            classes.sortedDesc = (classes.sorted && !this.sortAsc);

            return classes;
        },
        handleSort(item){
            if(!item.sortKey){
                return;
            }

            let sortAsc = true;
            if(this.sortKey && this.sortKey === item.sortKey){
                sortAsc = !this.sortAsc;
            }

            /**
             * List sorting has been requested
             *
             * @param {string} sortKey Key to sort for
             * @param {boolean} sortAsc Sort in ascending direction
             */
            this.$emit('sort', item.sortKey, sortAsc);
        },
    },
    computed: {
        rowClasses(){
            const classes = {
                entryRow: true,
                listHeader: true,
                'flex-row': true,
            };

            //if component has a name, add class based on it
            if(this.$options.name){
                classes[this.$options.name] = true;
            }

            return classes;
        },
    },
};
</script>
