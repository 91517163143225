<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('seriesTeamCreate')"
            v-bind:waitFor="apiData.series"
            v-bind:waitForRetry="reloadSeries"
            v-on:submit="createTeamSeries"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toSeriesDetail"
                    v-bind:loadingWhile="apiData.seriesTeamCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.seriesTeamCreation"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createSeriesTeamForm
                    v-bind:values="seriesTeamFormValues"
                    v-on:select="setTeam"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:sport="series ? series.sport : null"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import createSeriesTeamForm from '@/components/forms/createSeriesTeam.vue';

import SeriesModel from '@/models/series.js';
import SeriesTeamModel from '@/models/seriesTeam.js';

export default {
    name: 'commonSeriesTeamDetail',
    extends: base,
    components: {
        validations,
        createSeriesTeamForm,
    },
    props: {
        dataSeriesId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                series: this.loadSeries(),
                seriesTeamCreation: null,
            },
            series: null,
            seriesTeam: SeriesTeamModel.from({}),
            error: null,

            seriesTeamFormValues: null,
        };
    },
    computed: {
        toSeriesDetail(){
            return { name: 'commonSeriesDetail', params: { dataSeriesId: this.dataSeriesId } };
        },
    },
    methods: {
        loadSeries(){
            return this.$api.call.tournament.seriesGetById(this.dataSeriesId).then(data => {
                this.series = new SeriesModel(data);
            });
        },
        reloadSeries(){
            this.apiData.series = this.loadSeries();
        },

        cancelLink(){
            return { name: 'commonSeriesDetail' };
        },
        createTeamSeries(){
            this.apiData.seriesTeamCreation = this.$api.call.tournament.seriesTeamCreate(this.dataSeriesId, this.seriesTeam.toJSON()).then(response => {
                this.error = null;
                this.$router.push({ name: 'commonSeriesDetail', params: { dataSeriesId: this.dataSeriesId } });
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('seriesTeam creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
        setTeam(data){
            this.seriesTeam = new SeriesTeamModel(data);
            this.seriesTeamFormValues = this.getSeriesTeamFormValues();
        },
        getSeriesTeamFormValues(){
            if(!this.seriesTeam.teamName){
                return null;
            }

            return {
                team: this.seriesTeam,
                isTourMember: this.seriesTeam.isTourMember,
            };
        },
    },
    created(){
        this.seriesTeamFormValues = this.getSeriesTeamFormValues();
    },
};
</script>
