<template>
    <div class="pageContent">
        <reviewTournamentList
            v-bind:title="$t('matchDeclarations')"
            mainTitle
            v-bind:isMatchReview="true"
            v-bind:rowProps="rowProps"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import reviewTournamentList from '@/components/lists/review/tournament.vue';

export default {
    name: 'reviewMatchHub',
    extends: base,
    components: {
        reviewTournamentList,
    },
    data(){
        return {
            rowProps: {
                showDeclaration: false,
                showStatus: false,
                showDeadline: false,
            },
        };
    },
};
</script>
