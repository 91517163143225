import BasicService from './basic.js';

/**
 * Api request service class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class TeamService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/team-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // team

    /**
     *
     */
    teamGetById(id){
        return this.get(['team', id]);
    }

    /**
     *
     */
    teamCreate(data){
        return this.post('team', data);
    }

    /**
     *
     */
    teamApproveCreateById(id){
        return this.patch(['team', id]);
    }

    /**
     *
     */
    teamUpdateById(id, data){
        return this.put(['team', id], data);
    }

    /**
     *
     */
    teamDeleteById(id){
        return this.delete(['team', id]);
    }

    /**
     *
     */
    teamSearch(query){
        return this.post(['team', 'search', query]);
    }

    // team alias

    /**
     *
     */
    teamAliasCreate(data){
        return this.post('team-alias', data);
    }

    /**
     *
     */
    teamAliasUpdateById(id, data){
        return this.put(['team-alias', id], data);
    }

    /**
     *
     */
    teamAliasDeleteById(id){
        return this.delete(['team-alias', id]);
    }
}
