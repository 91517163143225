<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-text v-bind="getColumnProps('order', 12, 6, 3)" v-if="showOrder">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('order')">{{ getHeadline('order', $t('order')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ parameters.value }}</span>
        </b-card-text>

        <b-card-title v-bind="getColumnProps('tournamentStandingTypeParameterName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('tournamentStandingTypeParameterName')" v-if="isHeadline">
                {{ getHeadline('tournamentStandingTypeParameterName', $t('name')) }}
            </label>
            <span v-else>{{ standingTypeName }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('parameterTypeName', 12, 6, 3)" v-if="showParameterTypeName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('parameterTypeName')">{{ getHeadline('parameterTypeName', $t('parameterTypeName')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ parameters.name }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('value', 12, 6, 2)" v-if="showValue">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('value')">{{ getHeadline('value', $t('value')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ parameters.value }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('discriminator', 12, 6, 2)" v-if="showDiscriminator">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('discriminator')">{{ getHeadline('discriminator', $t('discriminator')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ parameters.discriminator }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('id', 12, 6, 2)" v-if="showId">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('id')">{{ getHeadline('id', $t('id')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ parameters.id }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-b-modal="modalName('editPointParameter', parameters.id)"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', parameters.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showDelete"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>

        <addEditTournamentStandingTypeParameterModal
            v-bind:id="modalName('editPointParameter', parameters.id)"
            v-bind:title="$t('editPointParameter')"
            v-bind:parameters="parameters"
            v-bind:standingTypeId="standingTypeId"
            v-bind:parameterType="parameterType"
            v-on:confirmed="emitEvent('changed')"
        />

        <confirmModal
            v-bind:id="modalName('confirmDelete', parameters.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: parameters.name })"
            v-bind:data="parameters"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />

    </b-card>
</template>

<script>
import base from '../base.vue';
import addEditTournamentStandingTypeParameterModal from '@/components/modals/addEditTournamentStandingTypeParameter.vue';

import TournamentStandingTypeParameterModel from '@/models/tournamentStandingTypeParameter.js';

/**
 * tournamentStandingTypePointParameters block component
 */
export default {
    name: 'tournamentStandingTypeParameterRow',
    extends: base,
    components: {
        addEditTournamentStandingTypeParameterModal,
    },
    props: {
        parameters: {
            type: TournamentStandingTypeParameterModel,
            required: false,
            default(){
                return new TournamentStandingTypeParameterModel();
            }
        },
        parameterType: {
            type: String,
            default: '',
        },
        standingTypeId: {
            type: Number,
            default: 0,
        },
        standingTypeName: {
            type: String,
            default: '',
        },

        showName: {
            type: Boolean,
            default: true,
        },
        showOrder: {
            type: Boolean,
            default: false,
        },
        showParameterTypeName: {
            type: Boolean,
            default: true,
        },
        showValue: {
            type: Boolean,
            default: false,
        },
        showDiscriminator: {
            type: Boolean,
            default: false,
        },
        showId: {
            type: Boolean,
            default: false,
        },
        showEdit: {
            type: Boolean,
            default: false,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        emitEventData(){
            return {
                parameters: this.parameters || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showDelete);
        },
    },
};
</script>
