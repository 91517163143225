import UploadModel from '@/models/upload.js';

/**
 * document and image upload model class
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default class DocumentAndImageUploadModel extends UploadModel {
    /**
     * get allowed format list
     *
     * @returns {string[]}
     */
    getFormats(){
        return ['.pdf', '.png', '.jpg'];
    }
}
