import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import declarationSeriesIndex from '@/views/declaration/series/index.vue';
import declarationSeriesDetail from '@/views/declaration/series/detail.vue';

export default {
    path: 'series/',
    component: pageFrame,
    children: [
        {
            name: 'declarationSeriesIndex',
            path: constants.routePaths.index,
            component: declarationSeriesIndex,
            meta: {
                permission: permissions.declarations.series.list,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.seriesDeclarationId),
            component: pageFrame,
            children: [
                {
                    path: routerTools.propRoute(constants.routeProps.teamDeclarationId),
                    component: pageFrame,
                    children: [
                        {
                            name: 'declarationSeriesDetail',
                            path: constants.routePaths.index,
                            component: declarationSeriesDetail,
                            props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.seriesDeclarationId, constants.routeProps.teamDeclarationId]),
                            meta: {
                                permission: permissions.declarations.series.detail,
                            },
                        },
                        routerTools.createFallbackRedirect(),
                    ],
                },
                routerTools.createIndexRedirect(),
            ],
        },
    ],
};
