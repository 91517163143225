<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="$t('matchNumber') + ' ' + (declaration ? declaration.match.matchNumber : '-') + ' | ' + $t('matchDeclaration')"
            v-bind:name="(this.declaration ? this.declaration.getTournamentName() : '-')"
            v-bind:imagePath="declaration ? declaration.getMatch().getTournamentImgPath() : null"
            v-bind:details="detailData" v-bind:waitFor="apiData.matchDeclaration"
            v-bind:waitForRetry="reloadMatchDeclaration">
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <loading tag="h2" v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            class="h1" waitHidden waitSilentError>
            {{ (declaration ? declaration.tournamentName : '-') }}
        </loading>

        <loading tag="h2" v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            class="h1" waitHidden waitSilentError>
            {{ teamDisplayOne }}
        </loading>

        <list v-bind:items="teamOneStartingLineup" v-bind:title="$t('startingPlayers')"
            v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            v-bind:hide="hideElement(1)">
            <template v-slot:buttons>
                <actionButton variant="outline-primary" class="btn-flat" v-bind:to="toTeamEdit(teamOneDeclarationId)"
                    v-if="declaration">
                    {{ $t('edit') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations ref="matchValidationsTeamOneStartingPlayers" v-bind:declaration="declaration"
                    v-bind:items="teamOneStartingPlayers" type="player" v-bind:isMatch="true" v-bind:isReview="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-players')) : 0" />
                <playerRow showNr showDetails showPosition showDetail showDateOfBirthAndAge
                    v-bind:showEligibility="declaration ? !declaration.isSevens : false" isHeadline />
            </template>
            <template v-slot="{ item: person, role, index }">
                <playerRow showNr showDetails showPosition showDetail showDateOfBirthAndAge hideDetailsAge
                    v-bind:showEligibility="declaration ? !declaration.isSevens : false" v-bind:person="person"
                    v-bind:role="role" v-if="person" />
                <simpleAddPlayerToMatchRow v-bind:text="$t('noPlayerDeclared')" v-bind:number="index + 1"
                    v-if="!person" />
            </template>
        </list>

        <list v-bind:items="teamOneReplacementLineup" v-bind:title="$t('replacementPlayers')"
            v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            v-bind:hide="hideElement(1)">
            <template v-slot:header>
                <validations ref="matchValidationsTeamOneReplacementPlayers" v-bind:declaration="declaration"
                    v-bind:items="teamOneReplacementPlayers" v-bind:isMatch="true" v-bind:isReview="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-replacement-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-replacement-players')) : 0" />
                <playerRow showNr showMessageUnavailableForMatch showPosition showDetails showDetail
                    showDateOfBirthAndAge v-bind:showEligibility="declaration ? !declaration.isSevens : false"
                    isHeadline />
            </template>
            <template v-slot="{ item: person, role, index }">
                <playerRow showNr showMessageUnavailableForMatch showPosition showDetails showDetail
                    showDateOfBirthAndAge hideDetailsAge
                    v-bind:showEligibility="declaration ? !declaration.isSevens : false" v-bind:person="person"
                    v-bind:role="role" v-if="person" />
                <simpleAddPlayerToMatchRow v-bind:text="$t('noPlayerDeclared')"
                    v-bind:number="index + 1 + startingLineupSize" v-if="!person" />
            </template>
        </list>

        <list v-bind:items="teamOneNonPlayingReserve" v-bind:title="$t('nonPlayerReserve')"
            v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            v-bind:hide="hideElement(1)"
            v-if="declaration && Boolean(declaration.findInConfigurationFields('squad', 'enable-non-playing-reserve'))">
            <template v-slot:header>
                <validations ref="matchValidationsTeamOneNonPlayingReserves" type="player" v-bind:isMatch="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-replacement-non-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-replacement-non-players')) : 0"
                    v-bind:declaration="declaration" v-bind:items="nonPlayingReserve" />
                <playerRow showDetails showDetail showDateOfBirthAndAge
                    v-bind:showJerseyNumber="declaration ? !declaration.isSevens : false" isHeadline />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow showDetails showDetail showDateOfBirthAndAge hideDetailsAge
                    v-bind:showJerseyNumber="declaration ? !declaration.isSevens : false" v-bind:person="person"
                    v-bind:role="role" />
            </template>
        </list>

        <wrapper v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            v-bind:hide="hideElement(1)">
            <template v-slot:buttons>
                <actionButton variant="danger" v-on:click="showStatusChangeModal('squad', false, teamDisplayOne)">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success" v-on:click="showStatusChangeModal('squad', true, teamDisplayOne)">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template v-slot>
                <declarationComments type="squad" v-bind:declaration="declaration" class="mt-3" noShadow noLine
                    noPadding allowInternalComments collapseEnabled collapseHidden v-if="declaration" />
            </template>
        </wrapper>

        <loading tag="h2" v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            class="h1" waitHidden waitSilentError>
            {{ teamDisplayTwo }}
        </loading>

        <list v-bind:items="teamTwoStartingLineup" v-bind:title="$t('startingPlayers')"
            v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            v-bind:hide="hideElement(2)">
            <template v-slot:buttons>
                <actionButton variant="outline-primary" class="btn-flat" v-bind:to="toTeamEdit(teamTwoDeclarationId)"
                    v-if="declaration">
                    {{ $t('edit') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations ref="matchValidationsTeamTwoStartingPlayers" v-bind:declaration="declaration"
                    v-bind:items="teamTwoStartingPlayers" type="player" v-bind:isMatch="true" v-bind:isReview="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-players')) : 0" />
                <playerRow showNr showPosition showDetails showDetail showDateOfBirthAndAge
                    v-bind:showEligibility="declaration ? !declaration.isSevens : false" isHeadline />
            </template>
            <template v-slot="{ item: person, role, index }">
                <playerRow showNr showPosition showDetails showDetail hideDetailsAge showDateOfBirthAndAge
                    v-bind:showEligibility="declaration ? !declaration.isSevens : false" v-bind:person="person"
                    v-bind:role="role" v-if="person" />
                <simpleAddPlayerToMatchRow v-bind:text="$t('noPlayerDeclared')" v-bind:number="index + 1"
                    v-if="!person" />
            </template>
        </list>

        <list v-bind:items="teamTwoReplacementLineup" v-bind:title="$t('replacementPlayers')"
            v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            v-bind:hide="hideElement(2)">
            <template v-slot:header>
                <validations ref="matchValidationsTeamTwoReplacementPlayers" v-bind:declaration="declaration"
                    v-bind:items="teamTwoReplacementPlayers" v-bind:isMatch="true" v-bind:isReview="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-replacement-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-replacement-players')) : 0" />
                <playerRow showNr showMessageUnavailableForMatch showPosition showDetails showDetail
                    showDateOfBirthAndAge v-bind:showEligibility="declaration ? !declaration.isSevens : false"
                    isHeadline />
            </template>
            <template v-slot="{ item: person, role, index }">
                <playerRow showNr showMessageUnavailableForMatch showPosition showDetails showDetail
                    showDateOfBirthAndAge hideDetailsAge
                    v-bind:showEligibility="declaration ? !declaration.isSevens : false" v-bind:person="person"
                    v-bind:role="role" v-if="person" />
                <simpleAddPlayerToMatchRow v-bind:text="$t('noPlayerDeclared')"
                    v-bind:number="index + 1 + startingLineupSize" v-if="!person" />
            </template>
        </list>


        <list v-bind:items="teamTwoNonPlayingReserve" v-bind:title="$t('nonPlayerReserve')"
            v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            v-bind:hide="hideElement(2)"
            v-if="declaration && Boolean(declaration.findInConfigurationFields('squad', 'enable-non-playing-reserve'))">
            <template v-slot:header>
                <validations ref="matchValidationsTeamOneNonPlayingReserves" type="player" v-bind:isMatch="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-replacement-non-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-replacement-non-players')) : 0"
                    v-bind:declaration="declaration" v-bind:items="nonPlayingReserve" />
                <playerRow showDetails showDetail showDateOfBirthAndAge
                    v-bind:showJerseyNumber="declaration ? !declaration.isSevens : false" isHeadline />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow showDetails showDetail hideDetailsAge showDateOfBirthAndAge
                    v-bind:showJerseyNumber="declaration ? !declaration.isSevens : false" v-bind:person="person"
                    v-bind:role="role" />
            </template>
        </list>

        <wrapper v-bind:waitFor="apiData.matchDeclaration" v-bind:waitForRetry="reloadMatchDeclaration"
            v-bind:hide="hideElement(2)">
            <template v-slot:buttons>
                <actionButton variant="danger" v-on:click="showStatusChangeModal('squadTwo', false, teamDisplayTwo)">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success" v-on:click="showStatusChangeModal('squadTwo', true, teamDisplayTwo)">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template>
                <declarationComments type="squad" v-bind:declaration="declaration" class="mt-3" noShadow noLine
                    noPadding isTeamTwo allowInternalComments collapseEnabled collapseHidden v-if="declaration" />
            </template>
        </wrapper>

        <confirmModal id="statusChangeModal" v-bind:text="statusChangeModalText"
            v-bind:confirmHandler="statusChangeModalHandler" v-on:hidden="statusChangeModalClosedHandler" />
    </div>
</template>

<script>

import base from '@/views/base.vue';
import { declarationDetailMatchDisplayMixins, declarationDetailReviewMixins } from '@/mixins/declarationDetail.js';
import detailsHeader from '@/components/detailsHeader.vue';
import subNavigation from '@/components/subnavigation.vue';
import loading from '@/components/loading.vue';
import declarationComments from '@/components/declarationComments.vue';
import validations from '@/components/validations.vue';

import playerRow from '@/components/rows/player.vue';
import simpleAddPlayerToMatchRow from '@/components/rows/simpleAddPlayerToMatch.vue';

import confirmModal from '@/components/modals/confirm.vue';

import MatchDeclarationModel from '@/models/declaration/match.js';
import MatchModel from '@/models/match.js';
import TeamDeclarationModel from '@/models/declaration/team.js';
import PlayerModel from '@/models/person/player.js';
import WorkflowSummaryModel from '@/models/workflowsummary.js';

export default {
    name: 'reviewMatchDetail',
    extends: base,
    mixins: [
        declarationDetailMatchDisplayMixins,
        declarationDetailReviewMixins,
    ],
    components: {
        detailsHeader,
        subNavigation,
        loading,
        declarationComments,
        validations,
        playerRow,
        simpleAddPlayerToMatchRow,
        confirmModal,
        // WorkflowStatusRow
    },
    props: {
        dataTournamentDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataMatchDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data() {
        return {
            declaration: null,
            apiData: {
                matchDeclaration: this.loadMatchDeclaration(),
            },
            headlineConfig: {
                text: 'Match Declaration'
            },
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: 'All',
                    },
                    1: {
                        label: '',
                        hidden: true,
                    },
                    2: {
                        label: '',
                        hidden: true,
                    },
                }
            },
            teamOneAction: null,
            teamTwoAction: null,
            workflowStatus: [],
            teamOneDeclarationId: 0,
            teamTwoDeclarationId: 0,
        };
    },
    computed: {
        detailData() {
            if (!this.declaration) {
                return [];
            }

            return [
                {
                    label: this.$t('submissionDate'),
                    content: this.declaration.getStepDeadlineDate(),
                    offset: this.declaration.deadlineGmtOffset,
                    type: 'dateTime',
                },
                {
                    label: this.$t('status'),
                    content: this.declaration.status,
                },
                {
                    label: this.$t('kickOff'),
                    content: this.$fd(this.declaration.match.kickOff, 'dateTime'),
                },
                {
                    label: this.$tc('match', 1),
                    content: this.declaration.match.matchNumber,
                },
                {
                    label: this.$tc('opponent', 2),
                    content: (this.declaration.match ? this.declaration.match.teamOneDisplayName : '-') + ' - ' + (this.declaration.match ? this.declaration.match.teamTwoDisplayName : '-'),
                },
                {
                    label: this.$t('status') + ' ' + (this.declaration.teamDeclaration ? this.declaration.teamDeclaration.team.name : this.$t('teamOne')),
                    content: (this.declaration.teamDeclaration ? this.declaration.teamDeclaration.findModuleInConfiguration(this.declaration.configTargets.squad).status : this.$t('notAvailable')),
                },
                {
                    label: this.$t('status') + ' ' + (this.declaration.teamDeclarationTwo ? this.declaration.teamDeclarationTwo.team.name : this.$t('teamTwo')),
                    content: (this.declaration.teamDeclarationTwo ? this.declaration.teamDeclarationTwo.findModuleInConfiguration(this.declaration.configTargets.squad).status : this.$t('notAvailable')),
                },
            ];
        },
        workflowSummaries() {
            if (!this.declaration) {
                return [];
            }

            const workflows = [];

            if (this.declaration.existsInConfig('squad')) {
                workflows.push(new WorkflowSummaryModel({
                    name: this.$t('squadAnnouncement'),
                    status: this.declaration.findInConfiguration('squad.status'),
                    deadline: this.declaration.findInConfiguration('squad.deadline-date'),
                    main: true,
                }));
            }

            return workflows;
        },
        teamDisplayOne() {
            return ((this.declaration && this.declaration.match.teamOne) ? (this.declaration.match.teamOne.team.unionName || '') : '-') + ' | ' + ((this.declaration && this.declaration.match.teamOne) ? this.declaration.match.teamOne.name : '-');
        },
        teamDisplayTwo() {
            return ((this.declaration && this.declaration.match.teamTwo) ? (this.declaration.match.teamTwo.team.unionName || '') : '-') + ' | ' + ((this.declaration && this.declaration.match.teamTwo) ? this.declaration.match.teamTwo.name : '-');
        },
    },
    methods: {
        hideElement(index) {
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },

        toTeamEdit(teamDeclarationId) {
            return { name: 'declarationMatchDetail', params: { dataMatchDeclarationId: this.declaration.id, dataTeamDeclarationId: teamDeclarationId } };
        },

        loadMatchDeclaration() {
            //load declaration
            return this.$api.call.termsOfParticipation.matchDeclarationGetById(this.dataMatchDeclarationId).then(matchDeclarationData => {
                const declaration = new MatchDeclarationModel(matchDeclarationData);

                //load match
                return this.$api.call.match.matchGetByEqId(declaration.getMatch().eqId).then(matchData => {
                    matchData.tournaments = matchData.tournaments || matchDeclarationData.match?.tournaments || [];
                    declaration.setMatch(MatchModel.from(matchData));

                    this.subnavigation.elements[1].label = declaration.getMatch().teamOne ? declaration.getMatch().teamOne.name : this.$t('teamOne');
                    this.subnavigation.elements[1].hidden = false;
                    this.subnavigation.elements[2].label = declaration.getMatch().teamTwo ? declaration.getMatch().teamTwo.name : this.$t('teamTwo');
                    this.subnavigation.elements[2].hidden = false;

                    return Promise.all((declaration.teams || []).map(teamDeclaration => {
                        return this.$api.call.termsOfParticipation.declarationTeamDeclarationById(teamDeclaration.id).then(teamDeclarationConfiguration => {
                            if (teamDeclarationConfiguration.declarationId !== declaration.id) {
                                throw new Error('something went wrong while requesting declaration team information');
                            }

                            const teamDeclaration = new TeamDeclarationModel(teamDeclarationConfiguration);
                            const findMatchTeam = declaration.match.teams.find((matchTeam) => (teamDeclaration.team.eqId === matchTeam.team.eqId));
                            let isTeamOne = findMatchTeam ? findMatchTeam.isTeam1 : declaration.teams.map(team => team.id).indexOf(teamDeclaration.id) === 0;

                            if (isTeamOne) {
                                this.teamOneDeclarationId = teamDeclaration.id;
                                declaration.setTeamDeclaration(teamDeclaration);
                                declaration.setOpponentAndUnion(declaration.match, declaration.teamDeclaration.team.eqId);
                            }
                            else {
                                this.teamTwoDeclarationId = teamDeclaration.id;
                                declaration.setTeamDeclarationTwo(teamDeclaration);
                            }

                            let teamDeclarationModuleId = teamDeclaration.findInConfiguration(declaration.configTargets.squad + declaration.configProperties.id);
                            if (!teamDeclarationModuleId) {
                                teamDeclarationModuleId = teamDeclaration.findInConfiguration('main' + declaration.configProperties.id);
                                if (!teamDeclarationModuleId) throw new Error('team declaration has no squad/main id');
                            }

                            //load team persons
                            return this.$api.call.termsOfParticipation.declarationTeamDeclarationPersons(teamDeclaration.id, teamDeclarationModuleId).then(result => {
                                if (isTeamOne) {
                                    declaration.getTeamDeclaration().setPersons((result.persons || []).map(data => {
                                        switch (data.roleType) {
                                            case 'Player':
                                                return new PlayerModel(data);
                                        }
                                        return null;
                                    }));
                                }
                                else {
                                    declaration.getTeamDeclarationTwo().setPersons((result.persons || []).map(data => {
                                        switch (data.roleType) {
                                            case 'Player':
                                                return new PlayerModel(data);
                                        }
                                        return null;
                                    }));
                                }
                            });
                        });
                    })).then(() => {
                        this.declaration = declaration;
                    });
                });
            });
        },
        reloadMatchDeclaration() {
            this.apiData.matchDeclaration = this.loadMatchDeclaration();
        },
    },
};
</script>
