<template>
    <b-row>
        <b-col cols="12" lg="6" class="uploadRow">
            <b-row class="py-2">
                <b-col cols="4" lg="3" class="px-0 label">{{ $t('status') }}</b-col>
                <b-col cols="8" lg="9" class="px-0"><statusIndicatorIcon v-bind:status="declarationStatus.status" v-bind:showText="true" /></b-col>
            </b-row>
            <b-row class="py-2">
                <b-col cols="4" lg="3" class="px-0 label">{{ $t('deadline') }}</b-col>
                <b-col cols="8" lg="9" class="px-0">{{ declarationStatus.deadline ? $fd(declarationStatus.deadline, 'date') : ''}}</b-col>
            </b-row>
            <b-row class="py-2" v-if="canShowRemaining">
                <b-col cols="4" lg="3" class="px-0 label">{{ $t('remaining') }}</b-col>
                <b-col cols="8" lg="9" class="px-0"><countdown v-bind:until="declarationStatus.deadline"/></b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import base from './base.vue';
import statusIndicatorIcon from '@/components/statusIndicatorIcon.vue';
import countdown from '@/components/countdown.vue';

import DeclarationStatusModel from '@/models/declaration/status.js';

/**
 * declaration status component
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default {
    name: 'declarationStatusBlock',
    extends: base,
    components: {
        statusIndicatorIcon,
        countdown,
    },
    props: {
        /**
         * declaration status object
         */
        declarationStatus: {
            type: DeclarationStatusModel,
            required: true,
            default: new DeclarationStatusModel(),
        },
        showRemaining: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        canShowRemaining(){
            return (this.showRemaining && this.declarationStatus.deadline);
        },
    },
};
</script>
