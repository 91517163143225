<script>
import formComponent from '@/components/form.vue';
import PersonRoleModel from '@/models/personRole.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'editPersonRoleForm',
    extends: formComponent,
    props: {
        model: {
            type: PersonRoleModel,
        },
        hideMapping: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            dependencies: {
                isPlayer: 'roleType === "Player"',
                isManagement: 'roleType === "Management"',
                isOfficial: 'roleType === "Official"',
                isNotOfficial: 'roleType !== "Official"',
            },
        };
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'roleType',
                        type: 'vselectPersonRole',
                        label: this.$tc('role', 1),
                        placeholder: this.$t('selectPersonRole'),
                        disabled: this.readonly,
                        required: true,
                        valueProperty: 'name',
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'team',
                        type: 'pickTeam',
                        label: this.$t('teamAffiliation'),
                        text: this.$t('selectTeam'),
                        action: null,
                        disabled: this.readonly,
                        required: true,
                        dependency: this.dependencies.isNotOfficial,
                    },
                    {
                        id: 'unionEqId',
                        type: 'vselectUnion',
                        valueProperty: 'eqId',
                        label: this.$t('unionAffiliation'),
                        placeholder: this.$t('selectUnion'),
                        disabled: this.readonly,
                        dependency: this.dependencies.isOfficial,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'dateFrom',
                        type: 'date',
                        label: this.$t('validFrom'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'dateUntil',
                        type: 'date',
                        label: this.$t('validUntil'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),

                {
                    type: 'hline',
                    dependency: this.dependencies.isPlayer,
                },
                {
                    id: 'playerRelevantInformation',
                    type: 'headline',
                    text: this.$t('playerRelevantInformation'),
                    dependency: this.dependencies.isPlayer,
                },
                this.createFormRow([
                    {
                        id: 'height',
                        type: 'number',
                        label: this.$t('heightIn', { unit: 'CM' }),
                        placeholder: this.$t('height'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'weight',
                        type: 'number',
                        label: this.$t('weightIn', { unit: 'KG' }),
                        placeholder: this.$t('weight'),
                        disabled: this.readonly,
                    },
                ], this.dependencies.isPlayer),
                this.createFormRow([
                    {
                        id: 'dominantHand',
                        type: 'select',
                        label: this.$t('dominantHand'),
                        options: {
                            Left: this.$t('left'),
                            Right: this.$t('right'),
                            Ambidextrous: this.$t('ambidextrous'),
                        },
                        disabled: this.readonly,
                    },
                    {
                        id: 'dominantFoot',
                        type: 'select',
                        label: this.$t('dominantFoot'),
                        options: {
                            Left: this.$t('left'),
                            Right: this.$t('right'),
                            Ambidextrous: this.$t('twoFooted'),
                        },
                        disabled: this.readonly,
                    },
                ], this.dependencies.isPlayer),

                {
                    type: 'hline',
                    hidden: this.hideMapping,
                },
                {
                    id: 'mapping',
                    type: 'headline',
                    text: this.$t('mapping'),
                    hidden: this.hideMapping,
                },
                this.createFormRow(this.generateMappingProviderControls(), null, this.hideMapping),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                teamEqId: 'team',
            };
        },
    },
};
</script>
