<script>
import itemList from '@/components/itemList.vue';
import tournamentReviewRow from '@/components/rows/tournamentReview.vue';
import TournamentDeclarationModel from '@/models/declaration/tournament.js';

/**
 * complex tournament review list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'reviewTournamentList',
    extends: itemList,
    components: {
        tournamentReviewRow,
    },
    props: {
        isMatchReview: {
            type: Boolean,
            default: false,
        },
        statuses: {
            type: Array,
            default: null,
        },
        deadlineDateOrder: {
            type: String,
            default: null,
        },
    },
    data(){
        return {
            //overwrites
            rowElement: tournamentReviewRow,
            rowItemPropName: 'tournamentDeclaration',
            selectItemProp: 'tournamentDeclaration',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDateRange: true,
                showDeclaration: true,
                showStatus: true,
                showSport: true,
                showDeadline: true,
                showDetail: true,
                isMatchReview: this.isMatchReview,
            };
        },
        listFilterConfig(){
            if (this.isMatchReview) {
                return [
                    this.createFilterRow([
                        {
                            id: 'name',
                            type: 'text',
                            placeholder: this.$t('searchByTournamentName'),
                        },
                        {
                            id: 'sport',
                            type: 'vselectSport',
                            placeholder: this.$t('filterBySport'),
                        },
                    ]),
                ];
            }

            return [
                this.createFilterRow([
                    {
                        id: 'name',
                        type: 'text',
                        placeholder: this.$t('searchByTournamentName'),
                    },
                    {
                        id: 'sport',
                        type: 'vselectSport',
                        placeholder: this.$t('filterBySport'),
                    },
                    {
                        id: 'status',
                        type: 'vselectWorkflowStatus',
                        placeholder: this.$t('filterByStatus'),
                    },
                ]),
                this.createFilterRow([
                    {
                        id: 'tournament',
                        type: 'pickTournament',
                        placeholder: this.$t('filterByTournament'),
                    },
                    {
                        id: 'declaration',
                        type: 'vselectTournamentDeclaration',
                        placeholder: this.$t('filterByDeclaration'),
                    },
                ]),
            ];
        },
        listFilterConfigRaw(){
            return true;
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load series declarations
            return this.$api.call.termsOfParticipation.tournamentDeclarationSearch({
                tournamentName: filters.name ? filters.name : null,
                tournamentEqId: filters.tournament ? filters.tournament.eqId : null,
                statuses: this.statuses || (filters.status ? [filters.status] : null),
                sport: filters.sport || null,
                declarationStepName: filters.declaration || null,
                deadlineDateOrder: this.deadlineDateOrder || undefined,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => {
                const declarations = (result.declarations || []);

                return Promise.resolve(declarations.map(declarationData => {
                    const tmpDeclaration = new TournamentDeclarationModel(declarationData);

                    if (this.isMatchReview){
                        tmpDeclaration.stepStatus = tmpDeclaration.status;

                        return [tmpDeclaration];
                    }

                    const result = [];
                    const steps = tmpDeclaration.getSteps(true);
                    let stepCount = 1;

                    steps.forEach(step => {
                        const declaration = new TournamentDeclarationModel(declarationData);

                        declaration.step = step.name;
                        declaration.stepStatus = step.status;
                        declaration.stepCount = stepCount;
                        declaration.stepTotalCount = steps.length;

                        result.push(declaration);
                        stepCount += 1;
                    });

                    return result;
                })).then(results => {
                    result.declarations = results.reduce((arr, data) => arr.concat(data.filter(content => content)), []);
                    return result;
                });
            }).then(result => ({
                items: this.sortItems(
                    result.declarations || [],
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
    },
};
</script>
