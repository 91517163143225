<template>
    <div
        v-bind:class="elementClasses"
        class="form-group"
    >
        <h3 v-bind:class="headlineClasses">{{ config.text }}</h3>
    </div>
</template>

<script>
import base from './base.vue';

/**
 * headline element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'headlineElement',
    extends: base,
    computed: {
        headlineClasses(){
            const classes = {};

            classes['h' + (this.config.level || 2)] = true;

            return classes;
        },
    },
};
</script>
