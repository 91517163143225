<template>
    <div class="pageContent">
        <unmappedList
            v-bind:title="$t('failedDataIngestion')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.unmapped.delete"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import unmappedList from '@/components/lists/administration/unmapped.vue';

export default {
    name: 'administrationUnmappedIndex',
    extends: base,
    components: {
        unmappedList,
    },
    data(){
        return {
            rowProps: {
                showDelete: true,
            },
        };
    },
};
</script>
