<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-text v-bind="getColumnProps('action', 12, 6, 1)" class="text-center" v-if="isTeamOne && showTeamOneIcon">
            <span class="text-uppercase" v-if="!isHeadline"><icon v-bind:type="actionIcon" v-bind:color="actionIconColor" large/></span>
        </b-card-text>
        <b-card-text v-bind="getColumnProps('action', 12, 6, 1)" class="text-center" v-else/>

        <b-card-text v-bind="getColumnProps('time', 12, 6, 1)" class="text-center" v-if="isTeamOne && showTeamOneMinutes">
            <span class="text-uppercase" v-if="!isHeadline">{{ cumulativeTimeDisplay }}</span>
        </b-card-text>
        <b-card-text v-bind="getColumnProps('time', 12, 6, 1)" class="text-center" v-else/>

        <b-card-text v-bind="getColumnProps('player', 12, 6, 3)" v-if="isTeamOne && showTeamOnePlayerNameAndAction">
            <span class="text-uppercase" v-if="!isHeadline">{{ timeline.personName }} - {{ timeline.actionTypeName }}</span>
        </b-card-text>
        <b-card-text v-bind="getColumnProps('player', 12, 6, 3)" class="text-center" v-else/>

        <b-card-text v-bind="getColumnProps('score', 12, 6, 2)" class="text-center" v-if="showScore">
            <span class="font-heavy" v-if="!isHeadline">{{ timeline.scoring.join(' : ') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('player', 12, 6, 3)" class="text-right" v-if="!isTeamOne && showTeamTwoPlayerNameAndAction">
            <span class="text-uppercase" v-if="!isHeadline">{{ timeline.personName }} - {{ timeline.actionTypeName }}</span>
        </b-card-text>
        <b-card-text v-bind="getColumnProps('player', 12, 6, 3)" class="text-center" v-else/>

        <b-card-text v-bind="getColumnProps('time', 12, 6, 1)" class="text-center" v-if="!isTeamOne && showTeamTwoMinutes">
            <span class="text-uppercase" v-if="!isHeadline">{{ cumulativeTimeDisplay }}</span>
        </b-card-text>
        <b-card-text v-bind="getColumnProps('time', 12, 6, 1)" class="text-center" v-else/>

        <b-card-text v-bind="getColumnProps('action', 12, 6, 1)" class="text-center" v-if="!isTeamOne && showTeamTwoIcon">
            <span class="text-uppercase" v-if="!isHeadline"><icon v-bind:type="actionIcon" v-bind:color="actionIconColor" large/></span>
        </b-card-text>
        <b-card-text v-bind="getColumnProps('action', 12, 6, 1)" class="text-center" v-else/>
    </b-card>
</template>

<script>
import base from './base.vue';

import MatchTimelineModel from '@/models/matchTimeline.js';

/**
 * match highlight component
 */
export default {
    name: 'matchHighlightRow',
    extends: base,
    props: {
        timeline: {
            type: MatchTimelineModel,
            default(){
                return new MatchTimelineModel();
            }
        },
        teamOneName: {
            type: String,
            default: '',
        },

        showTeamOneIcon: {
            type: Boolean,
            default: false,
        },
        showTeamOneMinutes: {
            type: Boolean,
            default: false,
        },
        showTeamOnePlayerNameAndAction: {
            type: Boolean,
            default: false,
        },
        showScore: {
            type: Boolean,
            default: false,
        },
        showTeamTwoIcon: {
            type: Boolean,
            default: false,
        },
        showTeamTwoMinutes: {
            type: Boolean,
            default: false,
        },
        showTeamTwoPlayerNameAndAction: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isTeamOne() {
            return this.timeline?.teamName === this.teamOneName;
        },
        actionIcon(){
            if (this.timeline.actionTypeGroup === 'Score'){
                return 'ball';
            }

            if (this.timeline.actionTypeGroup === 'Card'){
                if (this.timeline.actionTypeName.includes('red')){
                    return 'card-red';
                }
                else
                {
                    return 'card-yellow';
                }
            }

            if (this.timeline.actionTypeGroup === 'Replacement'){
                if (this.timeline.actionTypeName.includes('Off')){
                    return 'arrow-left';
                }
                else
                {
                    return 'arrow-right';
                }
            }

            return '';
        },
        actionIconColor(){
            if (this.timeline.actionTypeGroup === 'Replacement'){
                if (this.timeline.actionTypeName.includes('Off')){
                    return 'danger';
                }
                else
                {
                    return 'success';
                }
            }

            return null;
        },
        cumulativeTimeDisplay(){
            let cumulativeTime = this.timeline?.cumulativeTime;

            if(cumulativeTime){
                cumulativeTime += '\'';
            }

            return cumulativeTime;
        },
    },
};
</script>
