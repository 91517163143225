<script>
import vselectElement from '../vselect.vue';
import LawModel from '@/models/law.js';

/**
 * vselect element for LawModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectLawElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return this.$api.call.match.lawGetAll()
                    .then(result => (result.laws || []).map(data => new LawModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.match.lawGetById(id)))
                            .then(results => results.map(data => new LawModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
