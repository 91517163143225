import Model from '@/models/model.js';
import MatchModel from '@/models/match.js';

/**
 * ranking data model class
 */
export default class RankingRunMatchModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.rankingTeamEqId = data.rankingTeamEqId || '';

            this.match = data.match || new MatchModel({
                matchId: data.matchId || data.id,
                eqId: data.eqId,
                sport: data.sport,
                kickOff: data.kickOff,
                tournaments: data.tournaments,
            });

            this.winMultiplier = data.winMultiplier || 0;
            this.matchMultiplier = data.matchMultiplier || 0;

            this.team1Code = data.team1Code || '';
            this.team1Points = data.team1Points || 0;
            this.team1PointsBefore = data.team1PointsBefore || 0;
            this.team1CountryCode = data.team1CountryCode || '';
            this.team1EqId = data.team1EqId || this.match.teamOne?.teamEqId || '';
            this.team1Score = data.team1Score || this.match.teamOneScore || 0;
            this.team1IsAtHome = data.team1IsAtHome || this.match.teamOne?.isPlayingAtHome || false;
            this.team1Result = data.team1Result || '';

            this.team2Code = data.team2Code || '';
            this.team2Points = data.team2Points || 0;
            this.team2PointsBefore = data.team2PointsBefore || 0;
            this.team2CountryCode = data.team2CountryCode || '';
            this.team2EqId = data.team2EqId || this.match.teamTwo?.teamEqId || '';
            this.team2Score = data.team2Score || this.match.teamTwoScore || 0;
            this.team2IsAtHome = data.team2IsAtHome || this.match.teamTwo?.isPlayingAtHome || false;
            this.team2Result = data.team2Result || '';
        }
    }

    toJSON(){
        return {
            id: this.match.id,
            eqId: this.match.eqId,
            kickOff: this.fromDate(this.match.kickOff),
            team1EqId: this.team1EqId,
            team1Score: parseInt(this.team1Score, 10) || 0,
            team1IsAtHome: this.team1IsAtHome,
            team2EqId: this.team2EqId,
            team2Score: parseInt(this.team2Score, 10) || 0,
            team2IsAtHome: this.team2IsAtHome,
            tournamentGroups: this.match.tournaments.map(tournament => tournament.groupName),
        };
    }
}
