import UploadModel from '@/models/upload.js';

/**
 * document and audio upload model class
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default class DocumentAndAudioUploadModel extends UploadModel {
    /**
     * get allowed format list
     *
     * @returns {string[]}
     */
    getFormats(){
        return ['.mp3', '.wav', '.pdf'];
    }
}
