var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageContent"},[_c('wrapper',{attrs:{"title":_vm.$t('tournamentTeamEdit'),"mainTitle":"","isForm":"","doubleButtons":"","noShadow":"","noLine":"","noPadding":""},on:{"submit":_vm.updateTournamentTeam},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('actionButton',{attrs:{"to":_vm.toTournamentTeamDetail,"loadingWhile":_vm.apiData.tournamentTeamUpdate}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('actionButton',{attrs:{"type":"submit","loadingWhile":_vm.apiData.tournamentTeamUpdate}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])]},proxy:true},{key:"default",fn:function(){return [_c('wrapper',{attrs:{"waitFor":_vm.apiData.tournamentTeam,"waitForRetry":_vm.reloadTournamentTeam,"mainTitle":""},scopedSlots:_vm._u([(_vm.error)?{key:"header",fn:function(){return [(_vm.error)?_c('validations',{attrs:{"errors":_vm.error.getErrorMessages(_vm.$t('requestError'))}}):_vm._e()]},proxy:true}:null,{key:"default",fn:function(){return [_c('editTournamentTeamForm',{attrs:{"model":_vm.tournamentTeam,"errors":_vm.error ? _vm.error.getErrorIds() : undefined}})]},proxy:true}],null,true)}),_c('subNavigation',{attrs:{"data-config":_vm.subnavigation}}),_c('list',{attrs:{"title":_vm.$t('tournamentTeamPlayers'),"items":_vm.tournamentTeam ? _vm.tournamentTeam.players : [],"waitFor":_vm.apiData.tournamentTeam,"waitForRetry":_vm.reloadTournamentTeam,"draggable":"","hide":_vm.hideElement(1)},on:{"change":_vm.updatePlayerOrder},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('playerRow',{attrs:{"showJerseyNumber":"","showPosition":"","showRepresenting":"","showActionsPositionSelect":"","showDetailInputs":"","showIsCaptainCheckbox":"","showActionsReplacementCheckbox":"","showActionsLeftSquadCheckbox":"","showDetail":"","showRemove":"","isRemoveGeneric":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('playerRow',{attrs:{"showJerseyNumber":"","showPosition":"","showRepresenting":"","showEditTeamsRepresenting":"","showActionsPositionSelect":"","showDetailInputs":"","showIsCaptainCheckbox":"","showActionsReplacementCheckbox":"","showActionsLeftSquadCheckbox":"","showDetail":"","showRemove":"","isRemoveGeneric":"","person":person,"role":role,"actionsPositionSelectBySport":_vm.tournament ? _vm.tournament.sport : null,"removeHandler":_vm.removePlayer},on:{"heightChange":_vm.setPlayerHeight,"weightChange":_vm.setPlayerWeight,"isCaptainToggle":_vm.setPlayerIsCaptain,"leftSquadToggle":_vm.setPlayerLeftSquad,"replacementToggle":_vm.setPlayerReplacement,"positionSelect":_vm.setPersonPosition,"jerseyNumberToggle":_vm.updatePlayerSquadNumber}})]}},{key:"empty",fn:function(){return [_c('div')]},proxy:true},{key:"footer",fn:function(){return [_c('simpleRow',{directives:[{name:"b-modal",rawName:"v-b-modal.playerPick",modifiers:{"playerPick":true}}],attrs:{"text":_vm.$t('clickToAdd', { type: _vm.$tc('player') }),"showAdd":""}})]},proxy:true}])}),_c('list',{attrs:{"title":_vm.$t('tournamentTeamManagement'),"items":_vm.tournamentTeam ? _vm.tournamentTeam.managers : [],"waitFor":_vm.apiData.tournamentTeam,"waitForRetry":_vm.reloadTournamentTeam,"draggable":"","hide":_vm.hideElement(2)},on:{"change":_vm.updateManagerOrder},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('managerRow',{attrs:{"showPosition":"","showActionsPositionSelect":"","showDetail":"","showRemove":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('managerRow',{attrs:{"showPosition":"","showActionsPositionSelect":"","showDetail":"","showRemove":"","person":person,"role":role,"removeHandler":_vm.removeManager},on:{"positionSelect":_vm.setPersonPosition}})]}},{key:"empty",fn:function(){return [_c('div')]},proxy:true},{key:"footer",fn:function(){return [_c('simpleRow',{directives:[{name:"b-modal",rawName:"v-b-modal.managerPick",modifiers:{"managerPick":true}}],attrs:{"text":_vm.$t('clickToAdd', { type: _vm.$tc('manager') }),"showAdd":""}})]},proxy:true}])}),_c('list',{attrs:{"title":_vm.$t('tournamentTeamMedics'),"items":_vm.tournamentTeam ? _vm.tournamentTeam.medics : [],"waitFor":_vm.apiData.tournamentTeam,"waitForRetry":_vm.reloadTournamentTeam,"draggable":"","hide":_vm.hideElement(3)},on:{"change":_vm.updateMedicOrder},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('medicRow',{attrs:{"showPosition":"","showActionsPositionSelect":"","showDetail":"","showRemove":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('medicRow',{attrs:{"showPosition":"","showActionsPositionSelect":"","showDetail":"","showRemove":"","person":person,"role":role,"removeHandler":_vm.removeMedic},on:{"positionSelect":_vm.setPersonPosition}})]}},{key:"empty",fn:function(){return [_c('div')]},proxy:true},{key:"footer",fn:function(){return [_c('simpleRow',{directives:[{name:"b-modal",rawName:"v-b-modal.medicPick",modifiers:{"medicPick":true}}],attrs:{"text":_vm.$t('clickToAdd', { type: _vm.$tc('medic') }),"showAdd":""}})]},proxy:true}])}),_c('list',{attrs:{"title":_vm.$t('tournamentTeamKits'),"items":_vm.tournamentTeamKits,"waitFor":_vm.apiData.tournamentTeamKits,"waitForRetry":_vm.reloadTournamentTeamKits,"isRow":"","hide":_vm.hideElement(4)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var teamKit = ref.item;
var role = ref.role;
return [_c('teamKitRow',{attrs:{"teamKit":teamKit,"role":role}})]}}])})]},proxy:true}])}),_c('playerPickModal',{attrs:{"id":"playerPick","multiselect":"","title":_vm.$t('addPlayer'),"filterDisplay":_vm.pickerFilterDisplay,"filterValues":{ unionEqId: _vm.getUnionEqId, role: _vm.constants.personRoleType.player, sport: _vm.sport, team: _vm.getTeam },"rowProps":_vm.pickerRowProps,"listHideItems":_vm.selectedTournamentTeamPlayers,"selectHandler":_vm.addPlayers}}),_c('managerPickModal',{attrs:{"id":"managerPick","multiselect":"","title":_vm.$t('addManager'),"filterDisplay":_vm.pickerFilterDisplay,"filterValues":{ unionEqId: _vm.getUnionEqId, role: _vm.constants.personRoleType.management, sport: _vm.sport, team: _vm.getTeam },"rowProps":_vm.pickerRowProps,"listHideItems":_vm.selectedTournamentTeamManagementAndMedics,"selectHandler":_vm.addManagers}}),_c('medicPickModal',{attrs:{"id":"medicPick","multiselect":"","title":_vm.$t('addMedic'),"filterDisplay":_vm.pickerFilterDisplay,"filterValues":{ unionEqId: _vm.getUnionEqId, role: _vm.constants.personRoleType.management, sport: _vm.sport, team: _vm.getTeam},"rowProps":_vm.pickerRowProps,"listHideItems":_vm.selectedTournamentTeamManagementAndMedics,"selectHandler":_vm.addMedics}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }