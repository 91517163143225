<script>
import formComponent from '@/components/form.vue';
import DeclarationTOPModel from '@/models/declarationTOP.js';
import TournamentDeclarationModel from '@/models/declaration/tournament.js';
import constants from '@/constants';

const uploadDependencies = DeclarationTOPModel.joinFormDependencies([
    DeclarationTOPModel.formDependencies.participationAgreementEnabled,
    DeclarationTOPModel.formDependencies.teamLogoEnabled,
    DeclarationTOPModel.formDependencies.nationalAnthemEnabled,
    DeclarationTOPModel.formDependencies.teamDisciplinaryCertificateEnabled,
    DeclarationTOPModel.formDependencies.teamMemberAcceptanceFormEnabled,
    DeclarationTOPModel.formDependencies.teamPhoneticGuideEnabled,
]);

/**
 * component
 */
export default {
    name: 'tournamentSquadDeclarationForm',
    extends: formComponent,
    props: {
        model: {
            type: DeclarationTOPModel,
        },
        declarationType: {
            type: Number,
            default(){
                return 1;
            },
        },
        declaration: {
            type: TournamentDeclarationModel,
            default(){
                return new TournamentDeclarationModel();
            },
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            id: 'squadEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            disabled: true,
                        },
                        {
                            id: 'squadDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.workflowId, this.squadHidden),
                this.createFormRow([
                    {
                        id: 'squadMinimumNumberOfPlayers',
                        type: 'number',
                        label: this.$t('minimumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'squadMaximumNumberOfPlayers',
                        type: 'number',
                        label: this.$t('maximumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled, this.squadHidden),
                this.createFormRow([
                    {
                        id: 'squadMinimumNumberOfManagers',
                        type: 'number',
                        label: this.$t('minimumNumberOfManagers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'squadMaximumNumberOfManagers',
                        type: 'number',
                        label: this.$t('maximumNumberOfManagers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled, this.squadHidden),
                this.createFormRow([
                    {
                        id: 'squadMinimumNumberOfMedics',
                        type: 'number',
                        label: this.$t('minimumNumberOfMedics'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'squadMaximumNumberOfMedics',
                        type: 'number',
                        label: this.$t('maximumNumberOfMedics'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled, this.squadHidden),
                this.createFormRow([
                    {
                        id: 'squadMinimumTotalNumberOfManagement',
                        type: 'number',
                        label: this.$t('minimumTotalNumberOfManagement'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'squadMaximumTotalNumberOfManagement',
                        type: 'number',
                        label: this.$t('maximumTotalNumberOfManagement'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled, this.squadHidden),
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('personHeadshots'),
                        },
                        {
                            id: 'squadPersonHeadshotsEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadPersonHeadshotsDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadPersonHeadshotsEnabled,
                        },
                    ], null, this.squadPersonHeadshotsHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('passportInformation'),
                        },
                        {
                            id: 'squadPassportInformationEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadPassportInformationDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadPassportInformationEnabled,
                        },
                    ], null, this.squadPassportInformationHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('medicalCertificates'),
                        },
                        {
                            id: 'squadMedicalCertificatesEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadMedicalCertificatesDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadMedicalCertificatesEnabled,
                        },
                    ], null, this.squadMedicalCertificatesHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('biographyAndPhoneticGuide'),
                        },
                        {
                            id: 'squadBioEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadBioDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadBioEnabled,
                        },
                    ], null, this.squadBioHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('personPositions'),
                        },
                        {
                            id: 'squadPersonPositionEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadPersonPositionDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadPersonPositionEnabled,
                        },
                    ], null, this.squadPersonPositionHidden),
                ], DeclarationTOPModel.formDependencies.squadEnabled, this.squadDetailsHidden),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                    hidden: this.filesHidden,
                },
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('participationAgreement'),
                        },
                        {
                            id: 'participationAgreementEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'participationAgreementDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.participationAgreementEnabled,
                        },
                    ], null, this.participationAgreementHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamLogo'),
                        },
                        {
                            id: 'teamLogoEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamLogoDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamLogoEnabled,
                        },
                    ], null, this.teamLogoHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('nationalAnthem'),
                        },
                        {
                            id: 'nationalAnthemEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'nationalAnthemDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.nationalAnthemEnabled,
                        },
                    ], null, this.nationalAnthemHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('medicalInsuranceCover'),
                        },
                        {
                            id: 'medicalInsuranceCoverEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'medicalInsuranceCoverDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.medicalInsuranceCoverEnabled,
                        },
                    ], null, this.medicalInsuranceCoverHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamDisciplinaryCertificate'),
                        },
                        {
                            id: 'teamDisciplinaryCertificateEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamDisciplinaryCertificateDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamDisciplinaryCertificateEnabled,
                        },
                    ], null, this.teamDisciplinaryCertificateHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamMemberAcceptanceForm'),
                        },
                        {
                            id: 'teamMemberAcceptanceFormEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamMemberAcceptanceFormDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamMemberAcceptanceFormEnabled,
                        },
                    ], null, this.teamMemberAcceptanceFormHidden),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamPhoneticGuide'),
                        },
                        {
                            id: 'teamPhoneticGuideEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamPhoneticGuideDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamPhoneticGuideEnabled,
                        },
                    ], null, this.teamPhoneticGuideHidden),
                ], DeclarationTOPModel.formDependencies.workflowId, this.filesHidden),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                    hidden: this.kitHidden,
                },
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('kitDeclaration'),
                        },
                        {
                            id: 'kitEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'kitDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.kitEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.workflowId, this.kitHidden),

                {
                    type: 'hline',
                    dependency: uploadDependencies,
                    hidden: this.filesHidden,
                },
                {
                    type: 'headline',
                    text: this.$t('uploadForTOPTemplates'),
                    dependency: uploadDependencies,
                    hidden: this.filesHidden,
                },
                this.createFormRow([
                    {
                        id: 'participationAgreementTemplate',
                        type: 'file',
                        label: this.$t('participationAgreement'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.participationAgreementEnabled,
                        hidden: this.participationAgreementHidden,
                    },
                    {
                        id: 'teamLogoTemplate',
                        type: 'file',
                        label: this.$t('teamLogo'),
                        accept: constants.fileAcceptance.images,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamLogoEnabled,
                        hidden: this.teamLogoHidden,
                    },
                    {
                        id: 'nationalAnthemTemplate',
                        type: 'file',
                        label: this.$t('nationalAnthem'),
                        accept: constants.fileAcceptance.documentAndAudio,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.nationalAnthemEnabled,
                        hidden: this.nationalAnthemHidden,
                    },
                    {
                        id: 'teamDisciplinaryCertificateTemplate',
                        type: 'file',
                        label: this.$t('disciplinaryCertificate'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamDisciplinaryCertificateEnabled,
                        hidden: this.medicalInsuranceCoverHidden,
                    },
                    {
                        id: 'teamMemberAcceptanceFormTemplate',
                        type: 'file',
                        label: this.$t('teamMemberAcceptanceForm'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamMemberAcceptanceFormEnabled,
                        hidden: this.teamMemberAcceptanceFormHidden,
                    },
                    {
                        id: 'teamPhoneticGuideTemplate',
                        type: 'file',
                        label: this.$t('teamPhoneticGuide'),
                        accept: constants.fileAcceptance.documentAndAudio,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamPhoneticGuideEnabled,
                        hidden: this.teamPhoneticGuideHidden,
                    },
                ], uploadDependencies, this.filesHidden),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },

        squadHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.squad, this.model.step);
        },
        squadDetailsHidden(){
            return this.squadHidden || (
                this.squadPersonHeadshotsHidden &&
                this.squadPassportInformationHidden &&
                this.squadMedicalCertificatesHidden &&
                this.squadBioHidden &&
                this.squadPersonPositionHidden
            );
        },
        squadPersonHeadshotsHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.squadPersonHeadshots, this.model.step);
        },
        squadPassportInformationHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.squadPassportInformation, this.model.step);
        },
        squadMedicalCertificatesHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.squadMedicalCertificates, this.model.step);
        },
        squadBioHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.squadBio, this.model.step);
        },
        squadPersonPositionHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.squadPersonPosition, this.model.step);
        },

        filesHidden(){
            return (
                this.participationAgreementHidden &&
                this.teamLogoHidden &&
                this.nationalAnthemHidden &&
                this.medicalInsuranceCoverHidden &&
                this.teamDisciplinaryCertificateHidden &&
                this.teamMemberAcceptanceFormHidden &&
                this.teamPhoneticGuideHidden
            );
        },
        participationAgreementHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.participationAgreement, this.model.step);
        },
        teamLogoHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.teamLogo, this.model.step);
        },
        nationalAnthemHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.nationalAnthem, this.model.step);
        },
        medicalInsuranceCoverHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.medicalInsuranceCover, this.model.step);
        },
        teamDisciplinaryCertificateHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.teamDisciplinaryCertificate, this.model.step);
        },
        teamMemberAcceptanceFormHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.teamMemberAcceptanceForm, this.model.step);
        },
        teamPhoneticGuideHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.teamPhoneticGuide, this.model.step);
        },

        kitHidden(){
            return !this.declaration.existsInTemplate(this.declaration.configTargets.kit, this.model.step);
        },
        errorIdMap(){
            return {
                squad: 'squadDeadline',
            };
        },
    },
};
</script>
