<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="$t('matchNumber') + ' ' + (this.declaration ? this.declaration.match.matchNumber : '-') + ' | ' + $t('matchDeclaration')"
            v-bind:name="(this.declaration ? this.declaration.getTournamentName() : '-')"
            v-bind:nameSub="(this.declaration ? unionName : '-') + ' | ' + (this.declaration ? this.declaration.teamDeclaration.team.name : '-')"
            v-bind:imagePath="this.declaration ? this.declaration.teamDeclaration.team.logo : null"
            v-bind:details="detailData"
            v-bind:waitFor="apiData.matchDeclaration"
            v-bind:waitForRetry="reloadMatchDeclaration"
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:disabled="!isAllowedToSubmitDeclaration"
                    loadingThrobber
                    v-b-modal.confirmSubmit
                >
                    {{ $t('submitMatchDeclaration') }}
                </actionButton>
            </template>
        </detailsHeader>

        <list
            v-bind:items="startingLineup"
            v-bind:title="$t('startingPlayers')"
            v-bind:waitFor="apiData.matchDeclaration"
            v-bind:waitForRetry="reloadMatchDeclaration"
        >
            <template v-slot:buttons>
                <actionButton
                    v-if="isSevens"
                    variant="icon"
                    v-bind:disabled="isMaxPlayersSelected"
                    v-b-modal.addPlayersModal
                    v-on:click="setAddPlayerType(constants.group.player, null)"
                    class="d-inline-flex align-items-center"
                >
                <span class="pr-3">{{ $t('addPlayers') }}</span> <icon type="add" large/>
                </actionButton>
            </template>
            <template v-slot:header>
                <validations
                    ref="matchStartingPlayersValidations"
                    type="player"
                    v-bind:isMatch="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-players')) : 0"
                    v-bind:declaration="declaration"
                    v-bind:items="startingPlayers"
                />
                <playerRow
                    showNr showDetails showIsCaptainCheckbox showPosition showActionsPositionSelect showDetail showRemove
                    isHeadline
                    v-bind:showJerseyNumber="true"
                    v-bind:showEligibilityCheckbox="declaration ? !declaration.isSevens : false"
                     v-if="startingPlayers.length > 0"
                />
            </template>
            <template v-slot="{ item: person, role, index }">
                <playerRow
                    showNr showDetails showIsCaptainCheckbox showPosition showActionsPositionSelect showDetail showRemove showRemoveIcon highlightUnderaged isDeclaration
                    v-bind:isRemoveGeneric="!isSevens"
                    v-bind:showJerseyNumber="true"
                    v-bind:showEligibilityCheckbox="declaration ? !declaration.isSevens : false"
                    v-bind:person="person"
                    v-bind:role="role"
                    v-bind:actionsPositionSelectBySport="sport"
                    v-bind:removeHandler="removePerson"
                    v-on:positionSelect="updatePlayer"
                    v-on:isCaptainToggle="updatePlayer"
                    v-on:jerseyNumberToggle="updatePlayer"
                    v-bind:firstTournamentMatchDate="declaration ? declaration.firstTournamentMatchDate : null"
                    v-on:isLooseheadToggle="setPlayerEligibility"
                    v-on:isHookerToggle="setPlayerEligibility"
                    v-on:isTightheadToggle="setPlayerEligibility"
                    v-if="person"
                />
                <simpleAddPlayerToMatchRow
                    v-bind:text="$tc('addPlayer')"
                    v-bind:number="index + 1"
                    v-on:click="setAddPlayerType(constants.group.player, index + 1)"
                    showAdd
                    v-b-modal.addPlayerModal
                    v-if="!person"
                />
            </template>
            <template v-slot:empty>
                <div/>
            </template>
        </list>

        <list
            v-bind:items="replacementLineup"
            v-bind:title="$t('replacementPlayers')"
            v-bind:waitFor="apiData.matchDeclaration"
            v-bind:waitForRetry="reloadMatchDeclaration"
        >
            <template v-slot:buttons>
                <actionButton
                    v-if="isSevens"
                    variant="icon"
                    v-bind:disabled="isMaxReplacementPlayersSelected"
                    v-b-modal.addPlayersModal
                    v-on:click="setAddPlayerType(constants.group.replacementPlayer, null)"
                    class="d-inline-flex align-items-center"
                >
                <span class="pr-3">{{ $t('addReplacementPlayers') }}</span> <icon type="add" large/>
                </actionButton>
            </template>
            <template v-slot:header>
                <validations
                    ref="matchReplacementPlayersValidations"
                    type="replacementPlayers"
                    v-bind:isMatch="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-replacement-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-replacement-players')) : 0"
                    v-bind:declaration="declaration"
                    v-bind:items="replacementPlayers"
                />
                <playerRow
                    showNr showDetails showIsCaptainCheckbox showActionsUnavailable showActionsAvailable showPosition showActionsPositionSelect showDetail showRemove
                    v-bind:showJerseyNumber="declaration ? !declaration.isSevens : false"
                    v-bind:showEligibilityCheckbox="declaration ? !declaration.isSevens : false"
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role, index }">
                <playerRow
                    showNr showDetails showIsCaptainCheckbox showActionsUnavailable showActionsAvailable showPosition showActionsPositionSelect showDetail showRemove showRemoveIcon isDeclaration
                    v-bind:showJerseyNumber="declaration ? !declaration.isSevens : false"
                    v-bind:showEligibilityCheckbox="declaration ? !declaration.isSevens : false"
                    v-bind:person="person"
                    v-bind:role="role"
                    v-bind:actionsPositionSelectBySport="sport"
                    v-bind:removeHandler="removePerson"
                    v-on:positionSelect="updatePlayer"
                    v-on:isCaptainToggle="updatePlayer"
                    v-on:jerseyNumberToggle="updatePlayer"
                    v-bind:markPlayerAvailableLoadingWhile="apiData.markPlayerAvailableUpdate"
                    v-bind:markPlayerUnavailableLoadingWhile="apiData.markPlayerUnavailableUpdate"
                    v-on:markAvailable="markPlayerAvailable"
                    v-on:markUnavailable="markPlayerUnavailable"
                    v-on:isLooseheadToggle="setPlayerEligibility"
                    v-on:isHookerToggle="setPlayerEligibility"
                    v-on:isTightheadToggle="setPlayerEligibility"
                    v-if="person"
                />
                <simpleAddPlayerToMatchRow
                    v-bind:text="$tc('addPlayer')"
                    v-bind:number="index + 1 + startingLineupSize"
                    v-on:click="setAddPlayerType(constants.group.replacementPlayer, index + 1 + startingLineupSize)"
                    showAdd
                    v-b-modal.addPlayerModal
                    v-if="!person"
                />
            </template>
            <template v-slot:empty>
                <div/>
            </template>
        </list>

        <list
            v-bind:items="nonPlayingReserve"
            v-bind:title="$t('nonPlayerReserve')"
            v-bind:waitFor="apiData.matchDeclaration"
            v-bind:waitForRetry="reloadMatchDeclaration"
            v-if="declaration && Boolean(declaration.findInConfigurationFields('squad', 'enable-non-playing-reserve'))"
        >
            <template v-slot:header>
                <validations
                    ref="matchNonPlayingReservePlayersValidations"
                    type="player"
                    v-bind:isMatch="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-replacement-non-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-replacement-non-players')) : 0"
                    v-bind:declaration="declaration"
                    v-bind:items="nonPlayingReserve"
                />
                <playerRow
                    showDetails showDetail showRemove showRemoveIcon
                    v-bind:showJerseyNumber="declaration ? !declaration.isSevens : false"
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow
                    showDetails showDetail showRemove showRemoveIcon
                    v-bind:showJerseyNumber="declaration ? !declaration.isSevens : false"
                    v-bind:person="person"
                    v-bind:role="role"
                    v-bind:removeHandler="removePerson"
                />
            </template>
            <template v-slot:footer>
                <simpleRow
                    v-bind:text="$tc('addPlayer')"
                    v-on:click="setAddPlayerType(constants.group.nonPlayingReserve, null)"
                    showAdd
                    v-b-modal.addPlayerModal
                />
            </template>
            <template v-slot:empty>
                <div/>
            </template>
        </list>

        <declarationPlayerPickModal
            id="addPlayerModal"
            v-bind:title="$t('addPlayer')"
            v-bind:listHideItems="selectedPlayers"
            v-bind:filterValues="{ teamDeclarationId: declaration.getTeamDeclaration().id }"
            v-bind:selectHandler="addPlayer"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.squad)"
        />
        <confirmModal
            v-bind:id="'confirmSubmit'"
            v-bind:text="$t('areYouSureYouWantTo', { action: $t('submitMatchDeclaration') }) + '?'"
            v-bind:confirmHandler="submitTeamDeclaration"
        />
        <declarationPlayerPickModal
            id="addPlayersModal"
            multiselect
            v-bind:title="$t('addPlayers')"
            v-bind:listHideItems="selectedPlayers"
            v-bind:filterValues="{ teamDeclarationId: declaration.getTeamDeclaration().id }"
            v-bind:selectHandler="addMultiplePlayers"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.squad)"
        />
    </div>
</template>

<script>

import base from '@/views/base.vue';
import { declarationDetailDisplayMixins, declarationDetailEditMixins, declarationDetailMatchDisplayMixins } from '@/mixins/declarationDetail.js';
import detailsHeader from '@/components/detailsHeader.vue';
import validations from '@/components/validations.vue';

import playerRow from '@/components/rows/player.vue';
import simpleRow from '@/components/rows/simple.vue';
import simpleAddPlayerToMatchRow from '@/components/rows/simpleAddPlayerToMatch.vue';

import MatchDeclarationModel from '@/models/declaration/match.js';
import DeclarationTeamModel from '@/models/declaration/team.js';
import MatchModel from '@/models/match.js';
import PlayerModel from '@/models/person/player.js';
import PositionModel from '@/models/position.js';

import declarationPlayerPickModal from '@/components/modals/pick/declaration/player.vue';
import confirmModal from '@/components/modals/confirm.vue';
import icon from '@/components/icon.vue';

export default {
    name: 'reviewMatchDetail',
    extends: base,
    mixins: [
        declarationDetailDisplayMixins,
        declarationDetailEditMixins,
        declarationDetailMatchDisplayMixins,
    ],
    components: {
        detailsHeader,
        icon,
        playerRow,
        simpleRow,
        simpleAddPlayerToMatchRow,
        declarationPlayerPickModal,
        confirmModal,
        validations,
    },
    props: {
        dataMatchDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataTeamDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            matchDeclaration: this.loadMatchDeclaration(),
        };

        return {
            declaration: null,
            apiData: {
                matchDeclaration: this.apiData.matchDeclaration,
                markPlayerAvailableUpdate: null,
                markPlayerUnavailableUpdate: null,
                noneGenericPersonPositions: this.loadNoneGenericPersonPositions(),
            },
            headlineConfig: {
                text: 'Match Declaration'
            },
            addPlayerType: '',
            addPlayerNumber: 0,
            unknownPosition: null,
            noneGenericPersonPositions: [],
        };
    },
    computed: {
        detailData(){
            if(!this.declaration){
                return [];
            }

            return [
                {
                    label: this.$t('deadline'),
                    content: this.declaration.getStepDeadlineDate(),
                    offset: this.declaration.deadlineGmtOffset,
                    type: 'dateTime',
                },
                {
                    label: this.$t('timeRemaining'),
                    content: this.declaration.getStepDeadlineDate(),
                    offset: this.declaration.deadlineGmtOffset,
                    type: 'countdown',
                },
                {
                    label: this.$t('status'),
                    content: this.declaration.getTeamDeclaration().status,
                },
                {
                    label: this.$t('kickOff'),
                    content: this.$fd(this.declaration.match.kickOff, 'dateTime'),
                },
                {
                    label: this.$tc('match', 1),
                    content: this.declaration.match.matchNumber,
                },
                {
                    label: this.$tc('opponent', 1),
                    content: this.declaration.opponent,
                },
            ];
        },

        selectedPlayers(){
            return this.startingPlayers.concat(this.replacementPlayers).concat(this.nonPlayingReserve).map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },
        sport(){
            if(!this.declaration){
                return '';
            }

            return this.declaration.sport;
        },
        isSevens(){
            return this.declaration?.isSevens || false;
        },
        isMaxPlayersSelected(){
            return this.startingLineup.length >= this.startingLineupSize;
        },
        isMaxReplacementPlayersSelected(){
            return this.replacementLineup.length >= this.replacementLineupSize;
        },
    },
    methods: {
        loadMatchDeclaration(){
            //load declaration
            return this.$api.call.termsOfParticipation.matchDeclarationGetById(this.dataMatchDeclarationId).then(dataDeclarationData => {
                const declaration = new MatchDeclarationModel(dataDeclarationData);

                //load match
                return this.$api.call.match.matchGetByEqId(declaration.getMatch().eqId).then(matchData => {
                    matchData.tournaments = matchData.tournaments || dataDeclarationData.match?.tournaments || [];
                    declaration.setMatch(MatchModel.from(matchData));

                    //load team declaration
                    return this.$api.call.termsOfParticipation.declarationTeamDeclarationById(this.dataTeamDeclarationId).then(teamDeclarationConfiguration => {
                        if(teamDeclarationConfiguration.declarationId !== declaration.id){
                            throw new Error('something went wrong while requesting declaration team information');
                        }

                        declaration.setTeamDeclaration(new DeclarationTeamModel(teamDeclarationConfiguration));

                        declaration.setOpponentAndUnion(declaration.match, declaration.teamDeclaration.team.eqId);

                        const teamDeclarationModuleId = declaration.getTeamDeclaration().findInConfiguration('squad.id');
                        if(!teamDeclarationModuleId){
                            throw new Error('team declaration has no squad id');
                        }

                        // get unknown position for adding players
                        return this.$api.call.person.positionSearch({
                            name: 'Unknown',
                            roleType: 'Player',
                            sport: declaration.sport,
                        }).then(positionData => {
                            if (positionData && positionData.positions && positionData.positions.length){
                                this.unknownPosition = positionData.positions[0];
                            }

                            //load team persons
                            return this.$api.call.termsOfParticipation.declarationTeamDeclarationPersons(declaration.getTeamDeclaration().id, teamDeclarationModuleId).then(result => {
                                let playerNumber = 0;
                                declaration.getTeamDeclaration().setPersons((result.persons || []).map(data => {
                                    if(data.roleType === 'Player'){
                                        playerNumber += 1;

                                        if (!data.position && this.unknownPosition){
                                            data.position = this.unknownPosition;
                                        }

                                        let player = new PlayerModel(data);
                                        player.declarationSport = declaration.sport;
                                        player.number = playerNumber;
                                        player.teamDeclarationPersonId = data.id;

                                        return player;
                                    }
                                }));

                                // find first tournament match date
                                return Promise.all(declaration.getMatch().tournaments.map(tournament => this.$api.call.match.matchSearch({
                                    tournamentEqId: tournament.eqId,
                                    paging: {
                                        pageIndex: 0,
                                        pageSize: 100
                                    }
                                }).then(result => {
                                    return (result ? (result.data || []) : [])
                                        .map(data => new MatchModel(data))
                                        .map(match => match.kickOff)
                                        .reduce((v, c) => v < c ? v : c, undefined);
                                }))).then(dates => {
                                    if (Array.isArray(dates)) {
                                        declaration.firstTournamentMatchDate = dates.reduce((v, c) => v < c ? v : c, undefined) || null;
                                    }
                                    else {
                                        declaration.firstTournamentMatchDate = dates;
                                    }

                                    this.declaration = declaration;

                                    //reload stuff that require the declaration
                                    this.reloadNoneGenericPersonPositions();

                                    return this.declaration;
                                });
                            });
                        });
                    });
                });
            });
        },
        reloadMatchDeclaration(){
            this.apiData.matchDeclaration = this.loadMatchDeclaration();
        },

        loadNoneGenericPersonPositions(){
            //request requires the sport from the match declaration, so until the match declaration is loaded we use a promise that never resolves
            if(!this.declaration){
                return new Promise((resolve, reject) => this.apiData.matchDeclaration.catch(reject));
            }

            return this.$api.call.person.positionSearch({
                sport: this.declaration.sport,
            }).then(positionData => {
                this.noneGenericPersonPositions = (positionData?.positions || []).map(data => new PositionModel(data)).sort((a, b) => a.order - b.order);
            });
        },
        reloadNoneGenericPersonPositions(){
            this.apiData.noneGenericPersonPositions = this.loadNoneGenericPersonPositions();
        },

        addPlayer(player){
            const unknownPositionEqId = this.unknownPosition?.eqId || null;
            let preSelectedPositionEqId = null;
            let modelParams = {};

            // only pre-select a position if not a match related to the sport sevens
            if (!this.declaration.isSevens){
                if(this.addPlayerType === this.constants.group.player){
                    const positionFound = this.noneGenericPersonPositions.find((position) => position.number === this.addPlayerNumber.toString());
                    preSelectedPositionEqId = positionFound?.eqId || null;
                }

                if(this.addPlayerType === this.constants.group.replacementPlayer){
                    const replacementPositionFound = this.noneGenericPersonPositions.find((position) => position.number === this.addPlayerNumber.toString());
                    preSelectedPositionEqId = replacementPositionFound?.eqId || null;

                    // a match could have more replacement players than the amount of positions available for replacement players
                    // if this happens use the generic position for a replacement
                    if(!preSelectedPositionEqId){
                        const genericReplacementPositionFound = this.noneGenericPersonPositions.find((position) => position.number === 'Rep' && position.name === 'Replacement');
                        preSelectedPositionEqId = genericReplacementPositionFound?.eqId || null;
                    }
                }

                if(preSelectedPositionEqId){
                    // modelParams: this data will not be included in the payload for adding a player to the declaration
                    // modelParams: this data will be included within the player model after the player has been added to the declaration
                    // this data allows the jersey number text field to be editable as default when the row is added to the list
                    modelParams = {
                        declarationSport: this.declaration.sport,
                    };
                }
            }

            const params = {
                positionEqId: preSelectedPositionEqId || unknownPositionEqId || null,
                jerseyNumber: String(this.addPlayerNumber),
                order: this.addPlayerNumber,
            };

            switch(this.addPlayerType){
                case this.constants.group.replacementPlayer:
                    return this.addReplacementPlayers([player], [params], modelParams);

                case this.constants.group.nonPlayingReserve:
                    return this.addNonPlayingReserves([player], [params]);

                case this.constants.group.player:
                default:
                    return this.addPlayers([player], [params], modelParams);
            }
        },

        addMultiplePlayers(players){
            let playersParams = [];
            let modelParams = {};

            for (let order = 1; order <= players.length; order++) {
                playersParams.push({});
            }

            switch(this.addPlayerType){
                case this.constants.group.replacementPlayer:
                    return this.addReplacementPlayers(players, playersParams, modelParams);

                case this.constants.group.nonPlayingReserve:
                    return this.addNonPlayingReserves(players, playersParams);

                case this.constants.group.player:
                default:
                    return this.addPlayers(players, playersParams, modelParams);
            }
        },
        updatePlayer(data) {
            //ignore change position if position not changed
            if (data.event === 'changePosition' && data.value && data.value !== null && data.person.positionEqId === data.value.eqId){
                return;
            }

            let payload = data.person.toTopPersonUpdateJSON();

            switch (data.event) {
                case 'jerseyNumber':
                    payload.jerseyNumber = data.value.srcElement.value;
                    break;
                case 'isCaptain':
                    payload.isCaptain = data.value;
                    break;
                case 'changePosition':
                    payload.positionEqId = data.value ? data.value.eqId : null;
                    break;
            }

            const teamDeclarationModuleId = this.declaration.getTeamDeclaration().findInConfiguration('squad.id');

            this.$api.call.termsOfParticipation.declarationTeamUpdatePerson(
                this.declaration.getTeamDeclaration().id,
                teamDeclarationModuleId,
                data.person.teamDeclarationPersonId,
                payload,
            ).then(result => {
                //load team persons
                return this.$api.call.termsOfParticipation.declarationTeamDeclarationPersons(this.declaration.getTeamDeclaration().id, teamDeclarationModuleId).then(result => {
                    let playerNumber = 0;
                    this.declaration.getTeamDeclaration().setPersons((result.persons || []).map(data => {
                        if(data.roleType === 'Player'){
                            playerNumber += 1;

                            let player = new PlayerModel(data);
                            player.declarationSport = this.declaration.sport;
                            player.number = playerNumber;
                            player.teamDeclarationPersonId = data.id;

                            return player;
                        }
                    }));
                });
            });
        },
        markPlayerAvailable(player){
            let payload = player.person.toTopPersonUpdateJSON();
            payload.unavailable = false;

            this.apiData.markPlayerAvailableUpdate = this.$api.call.termsOfParticipation.declarationTeamUpdatePerson(
                this.declaration.getTeamDeclaration().id,
                this.declaration.getTeamDeclaration().findInConfiguration('squad.id'),
                player.person.teamDeclarationPersonId,
                payload
            ).then(() => {
                this.reloadMatchDeclaration();
            });
        },
        markPlayerUnavailable(player){
            let payload = player.person.toTopPersonUpdateJSON();
            payload.unavailable = true;

            this.apiData.markPlayerUnavailableUpdate = this.$api.call.termsOfParticipation.declarationTeamUpdatePerson(
                this.declaration.getTeamDeclaration().id,
                this.declaration.getTeamDeclaration().findInConfiguration('squad.id'),
                player.person.teamDeclarationPersonId,
                payload
            ).then(() => {
                this.reloadMatchDeclaration();
            });
        },
        setAddPlayerType(type, number){
            this.addPlayerType = type;
            this.addPlayerNumber = number;
        },
    },
    watch: {
        dataMatchDeclarationId(){
            this.reloadMatchDeclaration();
        },
        dataTeamDeclarationId(){
            this.reloadMatchDeclaration();
        },
    },
};
</script>
