<script>
import vselectElement from '../vselect.vue';
import UserTypeModel from '@/models/userType.js';

/**
 * vselect element for UserStatus items
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default {
    name: 'vselectUserTypeElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return this.$api.call.admin.userTypes().then(
                    result => (result || []).map(data => new UserTypeModel(data)));
            };

            return config;
        },
    },
};
</script>
