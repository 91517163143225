<template>
    <div v-bind:class="elementClasses">
        <div class="form-group" v-bind:class="config.labelClasses" v-if="config.label">
            <label class="d-block">{{ labelText }}</label>
        </div>
        <slot/>
    </div>
</template>

<script>
import base from './base.vue';

/**
 * slot element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'slotElement',
    extends: base,
    props: {
        dataValue: undefined,
    },
};
</script>
