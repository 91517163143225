<template>
    <b-card v-bind="rowAttributes">

        <b-card-text class="matchSummaryRow">
            <p><span class="font-weight-bold">{{ $t('teamOne') }}</span>: {{ matchSummary.teams[0] && matchSummary.teams[0].teamName ? matchSummary.teams[0].teamName : '-' }}</p>
            <p><span class="font-weight-bold">{{ $t('lineup') }}</span>: {{ matchSummary.teams[0] && matchSummary.teams[0].lineUp ? matchSummary.teams[0].lineUp : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('replacements') }}</span>: {{ matchSummary.getReplacementsTeam1() }}</p>
            <p><span class="font-weight-bold">{{ $t('tries') }}</span>: {{ matchSummary.teams[0] && matchSummary.teams[0].tries ? matchSummary.teams[0].tries : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('conversions') }}</span>: {{ matchSummary.teams[0] && matchSummary.teams[0].conversions ? matchSummary.teams[0].conversions : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('dropGoals') }}</span>: {{ matchSummary.teams[0] && matchSummary.teams[0].dropGoals ? matchSummary.teams[0].dropGoals : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('penalties') }}</span>: {{ matchSummary.teams[0] && matchSummary.teams[0].penalties ? matchSummary.teams[0].penalties : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('missedConversions') }}</span>: {{ matchSummary.teams[0] && matchSummary.teams[0].missedConversions ? matchSummary.teams[0].missedConversions : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('missedDropGoals') }}</span>: {{ matchSummary.teams[0] && matchSummary.teams[0].missedDropGoals ? matchSummary.teams[0].missedDropGoals : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('missedPenalties') }}</span>: {{ matchSummary.teams[0] && matchSummary.teams[0].missedPenalties ? matchSummary.teams[0].missedPenalties : '' }}</p>
            <br>
            <p><span class="font-weight-bold">{{ $t('teamTwo') }}</span>: {{ matchSummary.teams[1] && matchSummary.teams[1].teamName ? matchSummary.teams[1].teamName : '-' }}</p>
            <p><span class="font-weight-bold">{{ $t('lineup') }}</span>: {{ matchSummary.teams[1] && matchSummary.teams[1].lineUp ? matchSummary.teams[1].lineUp : ''  }}</p>
            <p><span class="font-weight-bold">{{ $t('replacements') }}</span>: {{ matchSummary.getReplacementsTeam2() }}</p>
            <p><span class="font-weight-bold">{{ $t('tries') }}</span>: {{ matchSummary.teams[1] && matchSummary.teams[1].tries ? matchSummary.teams[1].tries : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('conversions') }}</span>: {{ matchSummary.teams[1] && matchSummary.teams[1].conversions ? matchSummary.teams[1].conversions : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('dropGoals') }}</span>: {{ matchSummary.teams[1] && matchSummary.teams[1].dropGoals ? matchSummary.teams[1].dropGoals : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('penalties') }}</span>: {{ matchSummary.teams[1] && matchSummary.teams[1].penalties ? matchSummary.teams[1].penalties : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('missedConversions') }}</span>: {{ matchSummary.teams[1] && matchSummary.teams[1].missedConversions ? matchSummary.teams[1].missedConversions : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('missedDropGoals') }}</span>: {{ matchSummary.teams[1] && matchSummary.teams[1].missedDropGoals ? matchSummary.teams[1].missedDropGoals : '' }}</p>
            <p><span class="font-weight-bold">{{ $t('missedPenalties') }}</span>: {{ matchSummary.teams[1] && matchSummary.teams[1].missedPenalties ? matchSummary.teams[1].missedPenalties : '' }}</p>
            <br>
            <p><span class="font-weight-bold">{{ $t('referee') }}</span>: {{ matchSummary.referees }}</p>
            <p><span class="font-weight-bold">{{ $t('attendance') }}</span>: {{ matchSummary.attendance }}</p>
        </b-card-text>

    </b-card>
</template>

<script>
import base from './base.vue';

import MatchSummaryModel from '@/models/matchSummary.js';

/**
 * match summary component
 */
export default {
    name: 'matchSummaryRow',
    extends: base,
    props: {
        matchSummary: {
            type: MatchSummaryModel,
            default(){
                return new MatchSummaryModel();
            }
        },
    },
};
</script>
