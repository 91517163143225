<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-bind:scrollable="false"
    >
        <template v-slot="{ formId, submit }">
            <administrationApiResourceMethodForm
                v-bind:dataId="formId"
                v-bind:errors="errorIds"
                v-on:change="updateResourceMethod"
                v-on:submit="submit"
            >
                <template v-slot:headerText>
                    <div class="mb-6">{{ $tc('addApiService', 2) }}</div>
                </template>
            </administrationApiResourceMethodForm>
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import administrationApiResourceMethodForm from '@/components/forms/apiResourceMethod.vue';

/**
 * api resource method modal component
 */
export default {
    name: 'apiResourceMethodModal',
    extends: base,
    components: {
        administrationApiResourceMethodForm,
    },
    data() {
        return {
            formData: null,
        };
    },
    computed: {
        payload(){
            return (this.formData || {}).ApiResourceMethod || null;
        },
    },
    methods: {
        updateResourceMethod(formData){
            this.formData = formData;
        },
    },
};
</script>
