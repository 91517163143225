var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',_vm._g(_vm._b({on:{"show":_vm.onOpen},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formId = ref.formId;
var submit = ref.submit;
return [_c('list',{attrs:{"items":_vm.kits,"isRow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var teamKit = ref.item;
var role = ref.role;
var index = ref.index;
return [_c('teamKitRow',{attrs:{"dataId":formId,"teamKit":teamKit,"role":role,"readonly":_vm.readonly,"hideSelectTeamKit":_vm.hideSelectTeamKit,"errors":_vm.errorIds},on:{"kitSelection":function($event){return _vm.setKit(index, $event)},"submit":submit}})]}}],null,true)})]}}])},'modal',_vm.modalProps,false),_vm.modalEvents))}
var staticRenderFns = []

export { render, staticRenderFns }