<script>
import itemList from '@/components/itemList.vue';
import matchDeclarationRow from '@/components/rows/matchDeclaration.vue';
import MatchDeclarationModel from '@/models/declaration/match.js';
import TeamDeclarationModel from '@/models/declaration/team.js';

/**
 * complex match declaration list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'declarationMatchList',
    extends: itemList,
    components: {
        matchDeclarationRow,
    },
    props: {
        teamEqId: {
            type: String,
            default: '',
        },
        statuses: {
            type: Array,
            default: null,
        },
        kickoffDateDirection: {
            type: String,
            default: null,
        },
    },
    data(){
        return {
            //overwrites
            rowElement: matchDeclarationRow,
            rowItemPropName: 'matchDeclaration',
            selectItemProp: 'matchDeclaration',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showTournament: true,
                showStatus: true,
                showKickOff: true,
                showDetail: true,
            };
        },
        listFilterConfig(){
            return [
                this.createFilterRow([
                    {
                        id: 'name',
                        type: 'text',
                        placeholder: this.$t('searchByTeamName'),
                    },
                    {
                        id: 'kickoffDateOrder',
                        type: 'vselect',
                        placeholder: this.$t('filterByKickoffDate'),
                        options: [
                            {
                                id: 'asc',
                                label: this.$t('ascending'),
                            },
                            {
                                id: 'desc',
                                label: this.$t('descending'),
                            },
                        ],
                    },
                    {
                        id: 'status',
                        type: 'vselectWorkflowStatus',
                        placeholder: this.$t('filterByStatus'),
                    },

                ]),
                this.createFilterRow([
                    {
                        id: 'sport',
                        type: 'vselectSport',
                        placeholder: this.$t('filterBySport'),
                    },
                    {
                        id: 'team',
                        type: 'pickTeam',
                        placeholder: this.$t('filterByTeam'),
                        // label: this.$t('searchByTeam', 1),
                    },
                    {
                        id: 'tournament',
                        type: 'pickTournament',
                        placeholder: this.$t('filterByTournament'),
                    },
                ]),
            ];
        },
        listFilterConfigRaw(){
            return true;
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load matches declarations
            return this.$api.call.termsOfParticipation.matchDeclarationSearch({
                tournamentName: filters.tournament ? filters.tournament.name : null,
                teamName: filters.team ? filters.team.name : (filters.name ? filters.name : null) || null,
                teamEqId: filters.team ? filters.team.eqId : null,
                sport: filters.sport || null,
                statuses: this.statuses || (filters.status ? [filters.status] : null),
                kickoffDateDirection: this.kickoffDateDirection || filters.kickoffDateOrder || 'Desc',
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => {
                const declarations = (result.declarations || result.items || []);

                //grab teams for each declaration and create a MatchDeclarationModel for each team
                return Promise.resolve(declarations.map(declarationData => {
                    return (declarationData.teams || []).map(data => {
                        const declaration = new MatchDeclarationModel(declarationData);
                        declaration.setTeamDeclaration(new TeamDeclarationModel(data));
                        return declaration;
                    });
                })).then(results => {
                    result.declarations = results.reduce((arr, data) => arr.concat(data.filter(content => content)), []);
                    return result;
                });
            }).then(result => ({
                items: this.sortItems(
                    result.declarations || [],
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
    },
};
</script>
