import Model from '@/models/model.js';
import MatchModel from '@/models/match.js';
import MatchStatsTeamModel from '@/models/matchStatsTeam.js';

/**
 * match stats model class
 */
export default class MatchStatsModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.match = data.match ? MatchModel.from(data.match) : {};
            this.teams = (data.teams || []).map(data => MatchStatsTeamModel.from(data)).sort((a, b) => {
                const isTeam1A = a?.team?.isTeam1;
                const isTeam1B = b?.team?.isTeam1;

                if(isTeam1A && isTeam1B){
                    return 0;
                }

                return isTeam1A ? -1 : 1;
            });
        }
    }
}
