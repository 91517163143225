import Model from '@/models/model.js';

/**
 * match condition model class
 */
export default class MatchConditionModel extends Model {
    /**
     * id
     *
     * @type {int}
     */
    id = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.matchConditionId || 0;
            this.eqId = data.eqId || '';
            this.matchEqId = data.matchEqId || ''
            this.matchId = data.matchId || 0;
            this.minimumTemperature = data.minimumTemperature || 0;
            this.maximumTemperature = data.maximumTemperature || 0;
            this.pitchConditions = data.pitchConditions || '';
            this.windConditions = data.windConditions || '';
            this.weather = data.weather || '';
        }
    }
}
