<template>
    <component
        v-bind:is="isForm ? 'b-form' : 'div'"
        v-bind:class="outerClasses"
        v-bind:role="role"
        v-bind:novalidate="isForm ? !formValidationEnabled : undefined"
        v-on:submit="onSubmit"
    >
        <div class="headline-wrapper" v-bind:class="{ collapsed }" v-if="title || $scopedSlots.title || $scopedSlots.buttons || collapseEnabled">
            <!--
            @slot Replace default module headline element
            @binding {string} title Title prop value
            @binding {boolean} mainTitle Is main title
            @binding {number} itemsTotal Total item number
            @binding {boolean} collapseEnabled Is collapse enabled
            @binding {boolean} collapsed Wrapper collapsed state
            @binding {function} toggleCollapse Method to toggle collapse state if enabled
            -->
            <slot name="title" v-bind="{ title, mainTitle, itemsTotal, collapseEnabled, collapsed, toggleCollapse }">
                <component v-bind:is="title ? (mainTitle ? 'h1' : 'h2') : 'div'" v-bind:class="{ h1: (largeTitle === true), h2: (!largeTitle && !title), 'cursor-pointer': collapseEnabled }" v-on:click="toggleCollapse">
                    <span class="icon-down align-middle mr-2" v-if="collapseEnabled && collapseState"/>
                    <span class="icon-up align-middle mr-2" v-if="collapsed"/>
                    <span>{{ title }}</span>
                    <span class="items-total ml-2" v-if="itemsTotal">({{ $n(itemsTotal) }})</span>
                </component>
            </slot>
            <loading class="headline-buttons" v-bind="{ waitFor, waitForRetry }" waitSilent v-if="$scopedSlots.buttons && (!collapseEnabled || collapseState)">
                <!--
                @slot Module buttons
                @binding {boolean} bottomPosition For distinguishing button position when prop doubleButtons is enabled
                -->
                <slot name="buttons" v-bind:bottomPosition="false"/>
            </loading>
        </div>
        <component v-bind:is="collapseEnabled ? 'b-collapse' : 'div'" v-model="collapseState" class="wrapperOuter">
            <div class="wrapperHeader" v-bind:role="headerRole" v-if="$scopedSlots.header">
                <!--
                @slot Header content
                -->
                <slot name="header"/>
            </div>
            <loading v-bind:class="wrapperClasses" v-bind="{ waitFor, waitForRetry, waitSilent, waitSilentLoading, waitSilentError }">
                <div class="wrapperTop" v-bind:role="topRole" v-if="$scopedSlots.top">
                    <!--
                    @slot Top content
                    -->
                    <slot name="top"/>
                </div>
                <div class="wrapperMain" v-bind:role="mainRole" v-if="$scopedSlots.default">
                    <!--
                    @slot Main content
                    @binding {boolean} collapsed Wrapper collapsed state
                    -->
                    <slot v-bind:collapsed="collapsed"/>
                </div>
                <div class="wrapperFooter" v-bind:role="footerRole" v-if="$scopedSlots.footer">
                    <!--
                    @slot Footer content
                    -->
                    <slot name="footer"/>
                </div>
            </loading>
            <loading class="buttons-wrapper mt-4" v-bind="{ waitFor, waitForRetry }" waitSilent waitHidden v-if="$scopedSlots.buttons && doubleButtons">
                <!--
                @slot Module buttons
                @binding {boolean} bottomPosition For distinguishing button position when prop doubleButtons is enabled
                -->
                <slot name="buttons" v-bind:bottomPosition="true"/>
            </loading>
        </component>
    </component>
</template>

<script>
import loading from '@/components/loading.vue';
import constants from '@/constants.js';

/**
 * wrapper component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'wrapper',
    components: {
        loading,
    },
    props: {
        /**
         * wrapper title
         */
        title: {
            type: String,
            default: null,
        },
        /**
         * total number of results to add to title
         */
        itemsTotal: {
            type: Number,
            default: 0,
        },
        /**
         * wrapper title shown as large variant
         */
        largeTitle: {
            type: Boolean,
            default: false,
        },
        /**
         * wrapper title is page main title (h1)
         */
        mainTitle: {
            type: Boolean,
            default: false,
        },
        /**
         * wrapper role
         */
        role: {
            type: String,
            default: null,
        },
        /**
         * role of header slot wrapper
         */
        headerRole: {
            type: String,
            default: null,
        },
        /**
         * role of top slot wrapper
         */
        topRole: {
            type: String,
            default: null,
        },
        /**
         * role of main/default slot wrapper
         */
        mainRole: {
            type: String,
            default: null,
        },
        /**
         * role of footer slot wrapper
         */
        footerRole: {
            type: String,
            default: null,
        },
        /**
         * buttons slot content shows both in header and below the wrapper
         */
        doubleButtons: {
            type: Boolean,
            default: false,
        },
        /**
         * disable box shadow on desktop
         */
        noShadow: {
            type: Boolean,
            default: false,
        },
        /**
         * disable top line on desktop
         */
        noLine: {
            type: Boolean,
            default: false,
        },
        /**
         * show with border on mobile
         */
        mobileBorder: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce horizontal padding to minimum
         */
        noHorizontalPadding: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce vertical padding to minimum
         */
        noVerticalPadding: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce padding to minimum
         */
        noPadding: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce outer margin to minimum
         */
        noMargin: {
            type: Boolean,
            default: false,
        },
        /**
         * promise to wait for before showing top, default, and footer slot content
         */
        waitFor: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * method to bind to click action on error msg retry button
         * if not defined retry button is not shown
         */
        waitForRetry: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * stay silent while promise is loading or rejected (no loading icon/slot or error msg/slot)
         */
        waitSilent: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * stay silent while promise is loading (no loading icon/slot)
         */
        waitSilentLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * stay silent while promise is loading or rejected (no error msg/slot)
         */
        waitSilentError: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * use form tag for wrapper element
         */
        isForm: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * enable the option to collapse everything below the headline
         */
        collapseEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * change default collapse state to hidden
         */
        collapseHidden: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data(){
        return {
            collapseState: !this.collapseHidden,
        };
    },
    computed: {
        outerClasses(){
            return {
                wrapper: true,
                'mb-7': !this.noMargin,
                'mb-2': (this.noMargin && !this.noShadow),
            };
        },
        wrapperClasses(){
            return {
                wrapperInner: true,
                hasFooter: Boolean(this.$scopedSlots.footer),
                noShadow: this.noShadow,
                noLine: this.noLine,
                mobileBorder: this.mobileBorder,
                'px-0': true,
                'px-lg-5': !this.noPadding && !this.noHorizontalPadding,
                'pt-2': !this.noPadding && !this.noVerticalPadding,
                'pb-4': !this.noPadding && !this.noVerticalPadding,
            };
        },
        formValidationEnabled(){
            return (constants.options && constants.options.formValidationEnabled);
        },
        collapsed(){
            return (this.collapseEnabled && !this.collapseState);
        },
    },
    methods: {
        onSubmit(event){
            event.preventDefault();
            this.$emit('submit', event, event.target.checkValidity());
        },
        toggleCollapse(){
            if(!this.collapseEnabled){
                return;
            }

            this.collapseState = !this.collapseState;
        },
    },
};
</script>
