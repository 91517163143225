import Model from '@/models/model.js';

/**
 * unmapped model class
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default class UnmappedModel extends Model {
    /**
     * id
     *
     * @type {string}
     */
    id = '';

    /**
     * correlationId
     *
     * @type {string}
     */
    correlationId = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || '';
            this.cloudWatchFilter = data.cloudWatchFilter || '';
            this.correlationId = data.correlationId || '';
            this.creationDate = this.toDate(data.creationDate);
            this.description = data.description || '';
            this.entity = data.entity || '';
            this.fileUrl = data.fileUrl || '';
            this.provider = data.provider || '';
        }
    }
}
