<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('venueAlias', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('venueAliasName')" v-if="isHeadline">{{ getHeadline('venueAlias', $t('name')) }}</label>
            <span v-else>{{ venueAlias.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('dateFrom', 12, 6, 2)" v-if="showDateFrom">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateFrom')">{{ getHeadline('dateFrom', $t('validFrom')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ venueAlias.dateFrom ? $fd(venueAlias.dateFrom, 'date') : '-' }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('dateUntil', 12, 6, 2)" v-if="showDateUntil">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateUntil')">{{ getHeadline('dateUntil', $t('validUntil')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ venueAlias.dateUntil ? $fd(venueAlias.dateUntil, 'date') : $t('validToDate') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-if="showEdit"
                v-b-modal="modalName('editVenueAlias', venueAlias.id)"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <editVenueAliasModal
            v-bind:id="modalName('editVenueAlias', venueAlias.id)"
            v-bind:title="$t('editVenueAlias')"
            v-bind:venueAlias="venueAlias.clone()"
            v-bind:confirmHandler="updateHandler"
            v-on:confirmed="emitEvent('updated')"
        />
    </b-card>
</template>

<script>
import base from './base.vue';
import editVenueAliasModal from '@/components/modals/venue/editVenueAlias.vue';

import VenueAliasModel from '@/models/venueAlias.js';

/**
 * team block component
 */
export default {
    name: 'venueAliasRow',
    extends: base,
    components: {
        editVenueAliasModal,
    },
    props: {
        venueAlias: {
            type: VenueAliasModel,
            default(){
                return new VenueAliasModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },

        showDateFrom: {
            type: Boolean,
            default: false,
        },
        showDateUntil: {
            type: Boolean,
            default: false,
        },

        showEdit: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        modalName(type, id){
            return 'modal-' + type + '-' + id;
        },
        emitEvent(type, event, data){
            return this.$emit(type, Object.assign({
                venueAlias: this.venueAlias || null,
                event: event,
            }, data || {}));
        },
    },
    computed: {
        emitEventData(){
            return {
                venueAlias: this.venueAlias || null,
            };
        },
        hasVisibleCircles(){
            return (this.showEdit);
        },
    },
};
</script>
