<template>
    <b-modal
        v-bind="modalProps"
        v-on="modalEvents"
        ref="modal"
    >
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
            <!-- @slot All slots are passed through from v-modal component with their full scope, except for: modal-header-close, default, modal-footer -->
            <slot v-bind:name="name" v-bind="data"/>
        </template>
        <template v-slot:modal-header-close>
            <icon type="cancel" color="primary"/>
        </template>
        <template v-slot="data">
            <validations
                v-bind:errors="confirmErrors.length ? confirmErrors : cancelErrors"
                v-if="confirmErrors.length || cancelErrors.length"
            />
            <!--
            @slot Default modal content. All scope data is passed through from b-modal component default slot
            @binding {string} formId Form id, used to connect the form in default slot and the button in the modal-footer slot
            @binding {Function} ok Confirm modal (overwrites default ok function from b-modal component)
            @binding {Function} submit Handler when submitting modal form
            -->
            <slot
                v-bind="data"
                v-bind:formId="modalFormId"
                v-bind:ok="handleConfirm"
                v-bind:submit="processConfirmHandler"
            />
        </template>
        <template v-slot:modal-footer="data">
            <!--
            @slot Replace modal footer buttons. All scope data is passed through from b-modal component modal-footer slot
            @binding {string} formId Form id, used to connect the form in default slot and the button in the modal-footer slot
            @binding {Function} ok Confirm modal (overwrites default ok function from b-modal component)
            @binding {Function} cancel Cancel modal button action (overwrites default cancel function from b-modal component)
            @binding {Function} submit Handler when submitting modal form
            -->
            <slot
                name="modal-footer"
                v-bind="data"
                v-bind:formId="modalFormId"
                v-bind:ok="handleConfirm"
                v-bind:cancel="$event => handleCancel(data.cancel, $event)"
                v-bind:submit="processConfirmHandler"
            >
                <actionButton
                    v-bind:variant="cancelVariant"
                    v-bind:disabled="cancelDisabled"
                    v-bind:loadingWhile="loadingWhile || cancelPromise || confirmPromise"
                    v-bind:loadingThrobber="Boolean(cancelPromise)"
                    v-on:click="handleCancel(data.cancel, $event)"
                    v-if="!okOnly"
                >
                    {{ cancelTitle }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:form="modalFormId"
                    v-bind:variant="okVariant"
                    v-bind:disabled="okDisabled"
                    v-bind:loadingWhile="loadingWhile || cancelPromise || confirmPromise"
                    v-bind:loadingThrobber="!cancelPromise"
                    v-on:click="handleConfirm"
                >
                    {{ okTitle }}
                </actionButton>
            </slot>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import actionButton from '@/components/actionButton.vue';
import validations from '@/components/validations.vue';
import icon from '@/components/icon.vue';

import { RequestError } from '@/errors.js';

/**
 * base modal component
 * @abstract
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    id: 'modal',
    components: {
        actionButton,
        validations,
        icon,
    },
    props: Object.assign({}, BModal.options.props, {
        /**
         * handler to be executed when confirmation is completed.
         * if function return {Promise}, its result will be depending on the promise state.
         * other function return values will be ignored, except for {Boolean} false.
         */
        confirmHandler: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * handler to be executed when cancel button is clicked.
         * if function return {Promise}, its result will be depending on the promise state.
         * other function return values will be ignored, except for {Boolean} false.
         */
        cancelHandler: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * data to be used as parameter for confirmHandler function call.
         */
        confirmHandlerData: {
            default: null,
        },
        /**
         * data to be used as parameter for cancelHandler function call.
         */
        cancelHandlerData: {
            default: null,
        },
        /**
         * promise during which button is shown as loading.
         * takes priority over confirmHandler processing.
         */
        loadingWhile: {
            type: Promise,
            required: false,
            default: null,
        },
    }),
    data(){
        return {
            confirmPromise: null,
            confirmProcessing: false,
            confirmErrors: [],
            cancelPromise: null,
            cancelProcessing: false,
            cancelErrors: [],
        };
    },
    computed: {
        modalProps(){
            const props = Object.assign({}, this.$props);

            //remove custom props before handing to b-modal
            Object.keys(props).forEach(key => {
                if(!BModal.options.props[key]){
                    delete props[key];
                }
            });

            return props;
        },
        modalEvents(){
            return {
                cancel: event => this.$emit('cancel', event),
                change: event => this.$emit('change', event),
                close: event => this.$emit('close', event),
                hide: this.handleHide,
                hidden: event => this.$emit('hidden', event),
                ok: event => this.$emit('ok', event),
                show: this.handleShow,
                shown: event => this.$emit('shown', event),
            };
        },
        modalFormId(){
            return 'modalForm-' + this.id;
        },
    },
    methods: {
        show(){
            this.$refs.modal.show();
        },
        hide(){
            this.closeModal();
        },
        processConfirmHandler(){
            //if confirmHandler is set, execute it and wait for result promise
            if(this.confirmHandler){
                this.confirmProcessing = true;

                const handlerResult = this.confirmHandler(this.confirmHandlerData);

                if(handlerResult instanceof Promise){
                    this.confirmPromise = handlerResult.then(result => {
                        this.confirmProcessing = false;

                        //confirm process complete
                        this.handleOk(result);
                    }).catch(error => {
                        if(this.$log){
                            this.$log.warn(error);
                        }

                        if(error instanceof RequestError){
                            this.confirmErrors = error.getErrorMessages(this.$t('requestError'));

                            this.$emit('errorIds', error.getErrorIds());
                        }
                        else if(!error || (error instanceof Error && !error.message)){
                            this.confirmErrors = [];
                        }
                        else {
                            this.confirmErrors = [error instanceof Error ? error.message : error];
                        }

                        this.confirmProcessing = false;
                    });
                }
                else if(handlerResult !== false){
                    this.confirmProcessing = false;

                    //confirm process complete
                    this.handleOk(handlerResult);
                }
                else {
                    this.confirmProcessing = false;
                }
            }
            else{
                this.handleOk();
            }
        },
        processCancelHandler(cancel){
            if(!this.cancelHandler){
                cancel();
                return;
            }

            //if cancelHandler is set, execute it and wait for result promise
            this.cancelProcessing = true;

            const handlerResult = this.cancelHandler(this.cancelHandlerData);

            if(handlerResult instanceof Promise){
                this.cancelPromise = handlerResult.then(result => {
                    this.cancelProcessing = false;
                    this.cancelPromise = null;

                    //cancel process complete
                    cancel();
                }).catch(error => {
                    if(this.$log){
                        this.$log.warn(error);
                    }

                    if(error instanceof RequestError){
                        this.cancelErrors = error.getErrorMessages(this.$t('requestError'));

                        this.$emit('errorIds', error.getErrorIds());
                    }
                    else if(!error || (error instanceof Error && !error.message)){
                        this.cancelErrors = [];
                    }
                    else {
                        this.cancelErrors = [error instanceof Error ? error.message : error];
                    }

                    this.cancelProcessing = false;
                    this.cancelPromise = null;
                });
            }
            else if(handlerResult !== false){
                this.cancelProcessing = false;
                this.cancelPromise = null;

                //cancel process complete
                cancel();
            }
            else {
                this.cancelProcessing = false;
                this.cancelPromise = null;
            }
        },
        closeModal(){
            //while confirmProcessing or cancelProcessing, prevent closing
            if(this.confirmProcessing || this.cancelProcessing){
                return;
            }

            this.$refs.modal.hide();
        },
        handleHide(event){
            //while confirmProcessing, prevent closing
            if(this.confirmProcessing || this.cancelProcessing){
                event.preventDefault();
            }
            else {
                this.$emit('hide', event);
            }
        },
        handleShow(event){
            this.confirmPromise = null;
            this.confirmProcessing = false;
            this.confirmErrors = [];
            this.cancelPromise = null;
            this.cancelProcessing = false;
            this.cancelErrors = [];

            this.$emit('show', event);
        },
        handleOk(result = null){
            const showEvent = this.$refs.modal.buildEvent('ok');
            showEvent.result = result;
            this.$refs.modal.emitEvent(showEvent);

            this.closeModal();
        },
        handleConfirm(){
            //if modal contains form, only submit it. we bind the processConfirmHandler to the form submit event instead through the default slot scope
            if(this.$refs.modal && this.$refs.modal.$refs.body && this.$refs.modal.$refs.body.querySelector('form#' + this.modalFormId)){
                return;
            }

            this.processConfirmHandler();
        },
        handleCancel(cancel, event){
            this.processCancelHandler(cancel.bind(null, event));
        },
    },
};
</script>
