import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonPersonIndex from '@/views/common/person/index.vue';
import commonPersonCreate from '@/views/common/person/create.vue';
import commonPersonDetail from '@/views/common/person/detail.vue';
import commonPersonEdit from '@/views/common/person/edit.vue';

import personRoleRoutes from './person/role.js';

export default {
    path: '/person/',
    component: pageFrame,
    children: [
        {
            name: 'commonPersonIndex',
            path: constants.routePaths.index,
            component: commonPersonIndex,
            meta: {
                permission: permissions.persons.list,
            },
        },
        {
            name: 'commonPersonCreate',
            path: constants.routePaths.create,
            component: commonPersonCreate,
            meta: {
                permission: permissions.persons.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.personId),
            component: pageFrame,
            children: [
                {
                    name: 'commonPersonDetail',
                    path: constants.routePaths.index,
                    component: commonPersonDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.personId]),
                    meta: {
                        permission: permissions.persons.detail,
                    },
                },
                {
                    name: 'commonPersonEdit',
                    path: constants.routePaths.edit,
                    component: commonPersonEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.personId]),
                    meta: {
                        permission: permissions.persons.update,
                    },
                },
                personRoleRoutes,
            ],
        },
    ],
};
