<template>
    <div class="pageContent">
        <penaltiesList
            v-bind:title="$t('penalties')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:sport="sportId"
            v-bind:rowRemovePermission="permissions.rankings.penalties.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'administrationRankingPenaltiesCreate' }"
                >
                    {{ $t('createNew') }}
                </actionButton>
            </template>
        </penaltiesList>
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';
import penaltiesList from '@/components/lists/administration/penalties.vue';

export default {
    name: 'administrationRankingPenaltiesIndex',
    extends: rankingBase,
    components: {
        penaltiesList,
    },
    data() {
        return {
            rowProps: {},
        }
    },
}
</script>
