var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageContent"},[_c('detailsHeader',{attrs:{"title":_vm.$t('seriesTeamDetails'),"details":_vm.detailData,"waitFor":_vm.apiData.seriesTeam,"waitForRetry":_vm.reloadSeriesTeam},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.hasTeamDeclaration)?_c('actionButton',{directives:[{name:"b-modal",rawName:"v-b-modal.confirmDeleteDeclaration",modifiers:{"confirmDeleteDeclaration":true}}],attrs:{"variant":"danger","permission":_vm.permissions.declarations.team.delete}},[_vm._v(" "+_vm._s(_vm.$t('deleteDeclaration'))+" ")]):_vm._e(),_c('actionButton',{attrs:{"variant":"secondary","to":_vm.editSeriesTeamLink}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])]},proxy:true}])}),_c('subNavigation',{attrs:{"data-config":_vm.subnavigation}}),_c('list',{attrs:{"title":_vm.$t('seriesTeamPlayers'),"items":_vm.seriesTeamPlayers,"waitFor":_vm.apiData.seriesTeamPersonnel,"waitForRetry":_vm.reloadSeriesTeamPersonnel,"hide":_vm.hideElement(1)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('playerRow',{attrs:{"showDetail":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('playerRow',{attrs:{"showDetail":"","person":person,"role":role}})]}}])}),_c('list',{attrs:{"title":_vm.$t('seriesTeamManagement'),"items":_vm.seriesTeamManagement,"waitFor":_vm.apiData.seriesTeamPersonnel,"waitForRetry":_vm.reloadSeriesTeamPersonnel,"hide":_vm.hideElement(2)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('managerRow',{attrs:{"showDetail":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('managerRow',{attrs:{"showDetail":"","person":person,"role":role}})]}}])}),_c('list',{attrs:{"title":_vm.$t('seriesTeamMedics'),"items":_vm.seriesTeamMedic,"waitFor":_vm.apiData.seriesTeamPersonnel,"waitForRetry":_vm.reloadSeriesTeamPersonnel,"hide":_vm.hideElement(3)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('medicRow',{attrs:{"showDetail":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('medicRow',{attrs:{"showDetail":"","person":person,"role":role}})]}}])}),_c('confirmModal',{attrs:{"id":'confirmDeleteDeclaration',"text":_vm.$t('pleaseConfirmDeletion', { name: _vm.$t('teamDeclaration') }),"data":_vm.teamDeclaration,"confirmHandler":_vm.deleteTeamDeclarationHandler},on:{"confirmed":_vm.deleteTeamDeclarationComplete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }