<script>
import vselectElement from '../vselect.vue';
import ActionTypeModel from '@/models/actionType.js';

/**
 * vselect element for ActionType items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectActionTypeElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return this.$api.call.match.actionTypeGetAll()
                    .then(result => (result.actionTypes || []).map(data => new ActionTypeModel(data)))
                    .then(items => items.filter(item => !config.actionTypeGroup || item.group === config.actionTypeGroup))
                    .then(teams => teams.filter(item => !text || item.label().toLowerCase().includes(text.toLowerCase())));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.match.actionTypeGetById(id)))
                            .then(results => results.map(data => new ActionTypeModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
