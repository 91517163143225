import pageFrame from '@/views/frame.vue';

import reviewMatchRoutes from './review/match.js';
import reviewSeriesRoutes from './review/series.js';
import reviewTournamentRoutes from './review/tournament.js';

export default {
    path: '/review/',
    component: pageFrame,
    children: [
        reviewMatchRoutes,
        reviewSeriesRoutes,
        reviewTournamentRoutes,
    ],
};
