<script>
import pickElement from '../pick.vue';
import locationPickModal from '@/components/modals/pick/location.vue';

/**
 * pick location input element component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'pickLocationElement',
    extends: pickElement,
    components: {
        locationPickModal,
    },
    data(){
        return {
            modalComponent: locationPickModal,
        };
    },
};
</script>
