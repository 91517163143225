<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createTournamentStage')"
            v-on:submit="createTournamentStage"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentStageList"
                    v-bind:loadingWhile="apiData.tournamentStageCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentStageCreation"
                >
                    {{ $t('createStage') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createTournamentStageForm
                    v-bind:headline="$t('tournamentStageInformation')"
                    v-bind:model="tournamentStage"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import createTournamentStageForm from '@/components/forms/createTournamentStage.vue';

import TournamentStageModel from '@/models/tournamentStage.js';

export default {
    name: 'administrationTournamentStageCreate',
    extends: base,
    components: {
        validations,
        createTournamentStageForm,
    },
    data(){
        return {
            apiData: {
                tournamentStageCreation: null,
            },
            tournamentStage: TournamentStageModel.from({}),
            error: null,
        };
    },
    computed: {
        toTournamentStageList(){
            return { name: 'administrationTournamentStageIndex' };
        },
    },
    methods: {
        createTournamentStage(){
            this.apiData.tournamentStageCreation = this.$api.call.tournament.tournamentStageCreate(this.tournamentStage.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toTournamentStageList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentStage creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
