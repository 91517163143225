<script>
import constants from '../../../constants';
import vselectElement from '../vselect.vue';

/**
 * vselect element for Tournament Declaration items
 */
export default {
    name: 'vselectTournamentDeclaration',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            config.valueProperty = 'id';

            const tournamentDeclarationItems = Object.keys(constants.tournamentDeclarationTypes).map(key => ({
                id: key === 'oneStep' ? 'main' : 'preliminary-squad',
                label: this.$t(constants.tournamentDeclarationTypes[key]),
            }));

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return tournamentDeclarationItems;
            };

            return config;
        },
    },
};
</script>
