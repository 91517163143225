<script>
import formComponent from '@/components/form.vue';
import TournamentModel from '@/models/tournament.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'createTournamentForm',
    extends: formComponent,
    props: {
        model: {
            type: TournamentModel,
            clearEventTypeOptions: false,
        },
        hideMapping: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('tournamentName'),
                        placeholder: this.$t('enterTournamentName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'abbreviation',
                        type: 'text',
                        label: this.$t('abbreviation'),
                        placeholder: this.$t('enterAbbreviation'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'dateFrom',
                        type: 'date',
                        label: this.$t('dateFrom'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'dateUntil',
                        type: 'date',
                        label: this.$t('dateUntil'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'mainYear',
                        type: 'number',
                        min: 1000,
                        max: 9999,
                        label: this.$t('mainYear'),
                        placeholder: this.$t('enterMainYear'),
                        default: new Date().getFullYear(),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'sport',
                        type: 'vselectSport',
                        label: this.$tc('sport', 1),
                        placeholder: this.$t('selectSport'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'tournamentTypeId',
                        type: 'vselectEventType',
                        label: this.$t('eventType'),
                        placeholder: this.$t('selectEventType'),
                        disabled: this.readonly,
                        required: true,
                        dataDependency: {
                            sport: true,
                        },
                    },
                    {
                        id: 'startDateTimeOffset',
                        type: 'select',
                        label: this.$t('gmtOffsetStartDate'),
                        options: constants.gmtOffsets,
                        placeholder: this.$t('select'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'endDateTimeOffset',
                        type: 'select',
                        label: this.$t('gmtOffsetEndDate'),
                        options: constants.gmtOffsets,
                        placeholder: this.$t('select'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'tournamentStatusTypeId',
                        type: 'vselectTournamentStatus',
                        label: this.$t('statusType'),
                        placeholder: this.$t('selectStatusType'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'location',
                        type: 'text',
                        label: this.$t('location'),
                        placeholder: this.$t('enterLocation'),
                        disabled: this.readonly,
                    },
                ]),

                {
                    type: 'hline',
                },
                {
                    id: 'tournamentLogo',
                    type: 'headline',
                    text: this.$t('tournamentLogo'),
                },
                this.createFormRow([
                    {
                        id: 'logo',
                        type: 'file',
                        label: this.$t('file'),
                        accept: constants.fileAcceptance.images,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),

                {
                    type: 'hline',
                    hidden: this.hideMapping,
                },
                {
                    id: 'mapping',
                    type: 'headline',
                    text: this.$t('mapping'),
                    hidden: this.hideMapping,
                },
                this.createFormRow(this.generateMappingProviderControls(), null, this.hideMapping),

                this.createFormRow([
                    {
                        id: 'declarationEnabled',
                        type: 'checkbox',
                        text: this.$t('applyTermsOfParticipationRegulations'),
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'declarationWorkflowId',
                        type: 'vselectWorkflow',
                        label: this.$t('declarationType'),
                        required: true,
                        dataDependency: {
                            sport: true,
                        },
                        data: {
                            type: 'Tournament',
                        },
                    },
                ], 'Boolean(declarationEnabled)'),
                this.createFormRow([
                    {
                        id: 'declarationOpeningDate',
                        type: 'date',
                        label: this.$t('openingOfDeclaration'),
                        placeholder: constants.dateFormat,
                        required: true,
                    },
                    {
                        id: 'declarationDeadlineDate',
                        type: 'date',
                        label: this.$t('deadlineOfDeclaration'),
                        placeholder: constants.dateFormat,
                        required: true,
                    },
                ], 'Boolean(declarationEnabled) && declarationWorkflowId'),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                title: 'name',
                titleAbbreviation: 'abbreviation',
                openingDate: 'declarationOpeningDate',
                deadlineDate: 'declarationDeadlineDate',
            };
        },
        watchSport(){
            return this.model.sport;
        },
    },
    watch: {
        watchSport(){
            this.model.tournamentTypeId = null;
        },
    },
};
</script>
