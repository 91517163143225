<template>
    <modal
        v-bind="modalProps"
        v-bind:ok-title="confirmLabel"
        footer-class="justify-content-between align-items-end"
        size="xl"
        v-on:show="onOpen"
        v-on:close="onClose"
        ref="modal"
    >
        <template v-slot>
            <component
                v-bind:is="listElement"
                v-bind:listProps="combinedListProps"
                v-bind:rowProps="combinedRowProps"
                v-bind:rowReadonly="processing"
                v-bind:notices="listNotices"
                v-bind:successes="listSuccesses"
                v-bind:warnings="listWarnings"
                v-bind:errors="errors"
                v-bind:selected="selected"
                v-bind:content="content"
                v-bind:headlineConfig="listHeadlineConfig || defaultListHeadlineConfig"
                v-bind:noFilters="noFilters"
                v-bind:filterDisplay="filterDisplay"
                v-bind:filterValues="filterValues"
                v-bind:filterDisableElements="filterDisableElements"
                v-bind:hideItems="listHideItems"
                v-on:select="onSelect"
                v-on:itemCount="setItemCount"
                v-on:itemTotal="setItemTotal"
                ref="list"
            />
        </template>
        <template v-slot:modal-footer>
            <div v-if="total">
                {{ $t('itemsShown', { number: $n(count, 'nr'), total: $n(total, 'nr') }) }}
            </div>
            <div v-else></div>
            <div>
                <span v-if="!multiselect && processing">
                    <throbber small class="align-middle mr-2" v-if="processing"/>
                    {{ $t('processingRequest') }}
                </span>
                <actionButton
                    type="button"
                    variant="outline-secondary"
                    class="ml-2"
                    v-on:click="resetSelectedWithEmit"
                    v-bind:disabled="processing"
                    v-if="showClearSelection"
                >
                    {{ $t('clearSelection') }}
                </actionButton>
                <actionButton
                    type="button"
                    variant="secondary"
                    class="ml-2"
                    v-on:click="onCancel"
                    v-bind:disabled="processing"
                    v-if="showCancel"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="button"
                    variant="success"
                    class="ml-2"
                    v-on:click="onConfirm"
                    v-bind:disabled="processing || !selected.length"
                    v-if="multiselect"
                >
                    <throbber variant="white" small class="align-middle mr-2" v-if="processing"/>
                    {{ confirmLabel || $t('confirm') }}
                </actionButton>
            </div>
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import icon from '@/components/icon.vue';
import throbber from '@/components/throbber.vue';
import actionButton from '@/components/actionButton.vue';

/**
 * item pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'pickModal',
    extends: base,
    components: {
        icon,
        throbber,
        actionButton,
    },
    props: {
        /**
         * enable multiselect
         */
        multiselect: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * handler to be executed when selection is completed.
         * must return {Promise} or {Boolean}
         */
        selectHandler: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * confirm button label
         */
        confirmLabel: {
            type: String,
            required: false,
            default: undefined,
        },
        /**
         * use defined items for the list content
         */
        content: {
            type: Array,
            required: false,
            default: null,
        },
        /**
         * list component props
         */
        listProps: {
            type: Object,
            required: false,
            default(){
                return {};
            },
        },
        /**
         * row component props
         */
        rowProps: {
            type: Object,
            required: false,
            default(){
                return {};
            },
        },
        /**
         * list headline config
         */
        listHeadlineConfig: {
            type: Array,
            required: false,
            default: null,
        },
        /**
         * list of notice messages for list validations component
         */
        listNotices: {
            type: Array,
            required: false,
            default(){
                return [];
            }
        },
        /**
         * list of success messages for list validations component
         */
        listSuccesses: {
            type: Array,
            required: false,
            default(){
                return [];
            }
        },
        /**
         * list of warning messages for list validations component
         */
        listWarnings: {
            type: Array,
            required: false,
            default(){
                return [];
            }
        },
        /**
         * hide list filters
         */
        noFilters: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * toggle which list filters should be displayed or not
         */
        filterDisplay: {
            type: Object,
            required: false,
            default: null,
        },
        /**
         * initial list filter values
         */
        filterValues: {
            type: Object,
            required: false,
            default: null,
        },
        /**
         * list of filter element ids to disable
         */
        filterDisableElements: {
            type: Object,
            required: false,
            default: null,
        },
        /**
         * items to hide from list
         */
        listHideItems: {
            type: Array,
            required: false,
            default: null,
        },
        /**
         * show cancel button
         */
        showCancel: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * show clear selection button
         */
        showClearSelection: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data(){
        return {
            //base properties
            count: 0,
            total: 0,
            selected: [],
            errors: [],
            processing: false,

            //overwrite
            listElement: '',
        };
    },
    computed: {
        defaultListProps(){
            return {
                noMargin: true,
            };
        },
        combinedListProps(){
            return Object.assign({}, this.defaultListProps, this.listProps);
        },
        defaultRowProps(){
            return {
                showSelect: true,
                showDetail: false,
            };
        },
        combinedRowProps(){
            return Object.assign({}, this.defaultRowProps, this.rowProps);
        },
        defaultListHeadlineConfig(){
            return null;
        },
    },
    methods: {
        resetBasics(){
            this.errors = [];
            this.processing = false;

            this.listExecute(list => list.resetBasics());
        },
        resetItems(){
            this.listExecute(list => list.resetItems());
        },
        resetSelected(){
            this.selected = [];

            this.listExecute(list => list.resetSelected());
        },
        resetSelectedWithEmit(){
            this.resetSelected();

            this.emitData(this.selected);
        },
        listExecute(method){
            const execute = () => {
                //if list ref not found, wait for it
                if(!this.$refs.list){
                    this.$nextTick(execute);
                    return;
                }

                //execute
                method(this.$refs.list);
            };
            execute();
        },
        show(){
            this.$refs.modal.show();
        },
        hide(){
            this.closeModal();
        },
        closeModal(){
            //while processing, prevent closing
            if(this.processing){
                return;
            }

            this.$refs.modal.hide();
        },
        setItemCount(count){
            this.count = count;
        },
        setItemTotal(total){
            this.total = total;
        },
        onOpen(){
            this.resetBasics();
            this.resetItems();
            this.resetSelected();
        },
        onClose(e){
            //while processing, prevent closing
            if(this.processing){
                e.preventDefault();
                return;
            }

            this.resetBasics();
        },
        onCancel(e){
            this.closeModal();
        },
        onConfirm(e){
            /*
            if(!this.selected.length){
                e.preventDefault();
                return;
            }
            */

            this.emitData(this.selected, e);
        },
        onSelect(selected){
            this.selected = selected;

            //if no multiselect, directly emit
            if(!this.multiselect){
                this.emitData(selected.length ? selected[0] : null);
            }
        },
        emitData(data, e){
            //if select handler is set, execute it and wait for result promise
            if(this.selectHandler){
                if(e){
                    e.preventDefault();
                }

                this.processing = true;

                const handlerResult = this.selectHandler(data);

                if(handlerResult instanceof Promise){
                    handlerResult.then(() => {
                        this.processing = false;

                        /**
                         * Item has been selected/picked
                         *
                         * @param {Model[]} data Selected items
                         */
                        this.$emit('select', data);

                        this.closeModal();
                    }).catch(error => {
                        if(this.$log){
                            this.$log.warn(error);
                        }

                        this.errors = [error instanceof Error ? error.message : error];

                        this.processing = false;
                        if(!this.multiselect){
                            this.resetSelected();
                        }
                    });
                }
                else if(handlerResult !== false){
                    this.processing = false;

                    /**
                     * Item has been selected/picked
                     *
                     * @param {Model[]} data Selected items
                     */
                    this.$emit('select', data);

                    this.closeModal();
                }
            }
            else {
                /**
                 * Item has been selected/picked
                 *
                 * @param {Model[]} data Selected items
                 */
                this.$emit('select', data);

                this.closeModal();
            }
        },
    },
};
</script>
