<script>
import formComponent from '@/components/form.vue';
import TeamModel from '@/models/team.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'editTeamForm',
    extends: formComponent,
    props: {
        model: {
            type: TeamModel,
        },
        hideMapping: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'teamType',
                        type: 'vselectTeamType',
                        label: this.$t('teamType'),
                        placeholder: this.$t('selectTeamType'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'countryIsoCode',
                        type: 'vselectCountry',
                        label: this.$t('country'),
                        placeholder: this.$t('selectCountry'),
                        disabled: this.readonly,
                        valueProperty: 'isoCode',
                        required: true,
                    },
                    {
                        id: 'sport',
                        type: 'vselectSport',
                        label: this.$tc('sport', 1),
                        placeholder: this.$t('selectSport'),
                        disabled: this.readonly,
                        valueProperty: 'name',
                        required: true,
                    },
                    {
                        id: 'unionId',
                        type: 'vselectUnion',
                        label: this.$tc('union', 1),
                        placeholder: this.$t('selectUnion'),
                        disabled: this.readonly,
                        required: true,
                    },
                ]),

                {
                    type: 'headline',
                    text: this.$t('teamLogo'),
                },
                this.createFormRow([
                    {
                        id: 'logo',
                        type: 'file',
                        label: this.$t('file'),
                        accept: constants.fileAcceptance.images,
                        readonly: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),

                {
                    type: 'hline',
                    hidden: this.hideMapping,
                },
                {
                    id: 'mapping',
                    type: 'headline',
                    text: this.$t('mapping'),
                    hidden: this.hideMapping,
                },
                this.createFormRow(this.generateMappingProviderControls(), null, this.hideMapping),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
