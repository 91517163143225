var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageContent"},[_c('wrapper',{attrs:{"title":_vm.$t('apiAccess'),"mainTitle":"","isForm":"","doubleButtons":"","noShadow":"","noLine":"","noPadding":""},on:{"submit":_vm.updateApiConsumer},scopedSlots:_vm._u([(_vm.apiConsumer)?{key:"buttons",fn:function(){return [_c('actionButton',{attrs:{"to":_vm.toApiConsumerList,"loadingWhile":_vm.apiData.apiConsumerUpdate}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('actionButton',{attrs:{"type":"submit","loadingWhile":_vm.apiData.apiConsumerUpdate}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])]},proxy:true}:null,{key:"default",fn:function(){return [_c('wrapper',{attrs:{"title":_vm.$t('basicData'),"waitFor":_vm.apiData.apiConsumer,"waitForRetry":_vm.reloadApiConsumer},scopedSlots:_vm._u([(_vm.error)?{key:"header",fn:function(){return [(_vm.error)?_c('validations',{attrs:{"errors":_vm.error.getErrorMessages(_vm.$t('requestError'))}}):_vm._e()]},proxy:true}:null,{key:"default",fn:function(){return [_c('apiConsumerForm',{attrs:{"model":_vm.apiConsumer,"apiKeyLoadingWhile":_vm.apiData.apiKeyGeneration,"errors":_vm.error ? _vm.error.getErrorIds() : undefined},on:{"generateKey":_vm.generateKey,"deleteKey":_vm.deleteKey}})]},proxy:true}],null,true)}),_c('list',{attrs:{"title":_vm.$tc('ipWhitelist'),"items":_vm.apiConsumer ? _vm.apiConsumer.whitelistedIpAddresses : [],"waitFor":_vm.apiData.apiConsumer,"waitForRetry":_vm.reloadApiConsumer},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ipAddressRow',{attrs:{"showRemove":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var ipAddress = ref.item;
var role = ref.role;
return [_c('ipAddressRow',{attrs:{"showRemove":"","ipAddress":ipAddress,"role":role,"removeHandler":_vm.removeIpAddress}})]}},{key:"empty",fn:function(){return [_c('div')]},proxy:true},{key:"footer",fn:function(){return [(_vm.apiConsumer)?_c('simpleRow',{directives:[{name:"b-modal",rawName:"v-b-modal.ipWhitelistModal",modifiers:{"ipWhitelistModal":true}}],attrs:{"text":_vm.$tc('addIpWhitelist'),"showAdd":""}}):_vm._e()]},proxy:true}])}),_c('list',{attrs:{"title":_vm.$tc('tournament', 2),"items":_vm.apiConsumer ? _vm.apiConsumer.authorisedTournaments : [],"waitFor":_vm.apiData.apiConsumer,"waitForRetry":_vm.reloadApiConsumer},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tournamentRow',{attrs:{"showDateRange":"","showRemove":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var tournament = ref.item;
var role = ref.role;
return [_c('tournamentRow',{attrs:{"showDateRange":"","showRemove":"","tournament":tournament,"role":role,"removeHandler":_vm.removeTournament}})]}},{key:"empty",fn:function(){return [_c('div')]},proxy:true},{key:"footer",fn:function(){return [(_vm.apiConsumer)?_c('simpleRow',{directives:[{name:"b-modal",rawName:"v-b-modal.tournamentPick",modifiers:{"tournamentPick":true}}],attrs:{"text":_vm.$t('clickToAdd', { type: _vm.$tc('tournament') }),"showAdd":""}}):_vm._e()]},proxy:true}])}),_c('list',{attrs:{"title":_vm.$tc('apiService', 2),"items":_vm.apiResourceMethods,"waitFor":_vm.apiData.apiConsumer,"waitForRetry":_vm.reloadApiConsumer},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('apiResourceMethodRow',{attrs:{"showRemove":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var apiResourceMethod = ref.item;
var role = ref.role;
return [_c('apiResourceMethodRow',{attrs:{"showRemove":"","apiResourceMethod":apiResourceMethod,"role":role},on:{"removed":_vm.removeApiResourceMethod}})]}},{key:"empty",fn:function(){return [_c('div')]},proxy:true},{key:"footer",fn:function(){return [(_vm.apiConsumer)?_c('simpleRow',{directives:[{name:"b-modal",rawName:"v-b-modal.apiResourceMethodModal",modifiers:{"apiResourceMethodModal":true}}],attrs:{"showAdd":"","text":_vm.$tc('clickToAddApiService')}}):_vm._e()]},proxy:true}])})]},proxy:true}],null,true)}),_c('ipWhitelistModal',{attrs:{"id":"ipWhitelistModal","title":_vm.$tc('ipWhitelist', 2)},on:{"confirmed":function($event){return _vm.addIpAddress($event.payload)}}}),_c('tournamentPickModal',{attrs:{"id":"tournamentPick","multiselect":"","title":_vm.$t('searchSeriesTournaments'),"filterDisplay":_vm.pickerFilterDisplay,"rowProps":_vm.pickerRowProps,"listHideItems":_vm.apiConsumer ? _vm.apiConsumer.authorisedTournaments : []},on:{"select":_vm.addTournaments}}),_c('apiResourceMethodModal',{attrs:{"id":"apiResourceMethodModal","title":_vm.$tc('addApiService'),"confirmHandler":_vm.addApiResourceMethod}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }