<script>
import itemList from '@/components/itemList.vue';
import rankingUnionRow from '@/components/rows/administration/rankingUnion.vue';
import RankingUnionModel from '@/models/rankingUnion.js';

/**
 * complex Event Weightings list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'rankingUnionList',
    extends: itemList,
    components: {
        rankingUnionRow,
    },
    props: {
        /**
         * show card
         */
        sport: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            //overwrites
            rowElement: rankingUnionRow,
            rowItemPropName: 'rankingUnion',
            selectItemProp: 'rankingUnion',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showEdit: true,
                showRemove: true,
            };
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.rankings.rankingUnionGetBySport(this.sport).then(result => {
                return {
                    items: this.sortItems(
                        (result || []).map(data => new RankingUnionModel(data)),
                        sortKey,
                        sortAsc,
                        pageIndex,
                        pageSize,
                    ),
                    total: result.length,
                };
            });
        },
    },
};
</script>
