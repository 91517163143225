import Model from '@/models/model.js';
import MatchTeamModel from '@/models/matchTeam.js';

/**
 * match timeline team model class
 */
export default class MatchTimelineTeamModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            let team = {
                id: data.id || data.matchTeamId || 0,
                countryCode: data.countryCode || '',
                countryName: data.countryName || '',
                displayOverride: data.displayOverride || '',
                firstHalfScore: data.firstHalfScore || 0,
                isTeam1: data.isTeam1 || false,
                isPlayingAtHome: data.isPlayingAtHome || false,
                result: data.result || '',
                name: data.name || data.teamName || data.displayOverride || '',
                score: data.score || 0,
                teamAliasEqId: data.teamAliasEqId || '',
                teamCode: data.teamCode || '',
                teamEqId: data.teamEqId || '',
                teamStatuses: data.teamStatuses || [],
                teamType: data.teamType || '',
                unionName: data.unionName || '',
            };

            this.team = MatchTeamModel.from(team);
        }
    }
}
