import Model from '@/models/model.js';
import UnionModel from '@/models/union.js'

/**
 * player representing model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class PlayerRepresentingModel extends Model {
    /**
     * id
     *
     * @type {Number}
     */
    id = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.eqId = data.eqId || '';
            this.sport = data.sport || '';
            this.teamAliasEqId = data.teamAliasSourceEqId || data.teamAliasEqId || '';
            this.teamName = data.teamName || '';
            this.union = UnionModel.from(data.union) || {};
        }
    }
}
