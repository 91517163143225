import Model from '@/models/model.js';

export default class PenaltyModel extends Model {
    constructor(data = {}){
        super(data);

        if(data){
            this.amount = data.amount || 0;
            this.dateApplied = this.toDate(data.dateApplied);
            this.id = data.id || data.alterationId || 0;
            this.isEnabled = data.isEnabled || false;
            this.lastUpdated = this.toDate(data.lastUpdated);
            this.lastUpdatedBy = data.lastUpdatedBy || '';
            this.pointsAfter = data.pointsAfter || 0;
            this.pointsBefore = data.pointsBefore || 0;
            this.reason = data.reason || '';
            this.sport = data.sport || '';
            this.teamId = data.teamId || data.rankingTeamId || 0;
            this.teamName = data.teamName || '';
            this.union = data.union || '';
        }
    }

    toJSON(){
        return {
            alterationId: this.id,
            amount: this.amount,
            reason: this.reason,
            isEnabled: this.isEnabled,
            dateApplied: this.fromDate(this.dateApplied),
            sport: this.sport,
            rankingTeamId: this.teamId,
        };
    }
}
