<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <b-input-group>
            <template v-slot>
                <b-form-input
                    v-model="itemName"
                    v-bind:id="elementId"
                    type="text"
                    v-bind:required="config.required || false"
                    v-bind:placeholder="config.placeholder"
                    v-bind:plaintext="config.plaintext"
                    readonly
                    v-bind:disabled="config.disabled || false"
                    v-bind:state="state"
                />
            </template>
            <template v-slot:append v-if="!config.plaintext">
                <b-button
                    variant="outline-primary"
                    v-bind:disabled="config.disabled || false"
                    v-on:click="$refs.modal ? $refs.modal.show() : null"
                >{{ config.buttonLabel || $t('select') }}</b-button>
            </template>
        </b-input-group>

        <component
            v-bind:is="modalComponent"
            v-bind:id="modalId"
            v-bind:title="config.modalTitle || ''"
            v-bind:filterValues="config.filterValues || null"
            v-bind:filterDisableElements="config.filterDisableElements || null"
            v-on:select="handleSelect"
            ref="modal"
        />
    </b-form-group>
</template>

<script>
import base from './base.vue';
import Model from '@/models/model.js';

/**
 * pick input element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'pickElement',
    extends: base,
    props: {
        dataValue: {
            type: [Model, Object],
        },
    },
    data(){
        return {
            value: null,
            defaultValue: null,
            //overwrite me
            modalComponent: '',
        };
    },
    computed: {
        itemName(){
            if(!this.value){
                return '';
            }

            if(this.value.label instanceof Function){
                return this.value.label();
            }
            else if(this.value.label) {
                return this.value.label;
            }
            else {
                //if we have no label... display the id?
                return this.value.id;
            }
        },
        modalId(){
            return this.elementId + '-modal';
        },
        customElementClasses(){
            return {
                pickElement: true,
            };
        },
    },
    methods: {
        handleSelect(item){
            this.value = item;
        },
        onClearSelection(e){
            this.value = null;
        },
    },
};
</script>
