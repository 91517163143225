<script>
import vselectElement from '../vselect.vue';
import CountryModel from '@/models/country.js';

/**
 * vselect element for CountryModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectCountryElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                return this.$api.call.location.countrySearch({
                    name: text || null,
                    paging: this.usePagination(offset),
                }).then(result => (result.data || []).map(data => new CountryModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'isoCode':
                    default:
                        return Promise.all(initValues.map(isoCode => this.$api.call.location.countrySearch({
                            isoCode,
                            paging: this.usePagination(0, 1), // search by IsoCode will only return 1 record
                        }).then(result => (result.data || []).map(data => new CountryModel(data)).shift())));
                }
            };

            return config;
        },
    },
};
</script>
