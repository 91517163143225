<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('tournamentStandingTypeName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label
                v-bind:class="headlineClasses"
                v-on:click="handleHeadlineSort('tournamentStandingTypeName')"
                v-if="isHeadline"
            >
                    {{ getHeadline('tournamentStandingTypeName', $t('name')) }}
            </label>
            <span v-else>{{ tournamentStandingType.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 2)" v-if="showSport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $tc('sport', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ tournamentStandingType.sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', tournamentStandingType.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showDelete"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', tournamentStandingType.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: tournamentStandingType.name })"
            v-bind:data="tournamentStandingType"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />

    </b-card>
</template>

<script>
import base from './../base.vue';

import TournamentStandingTypeModel from '@/models/tournamentStandingType.js';

/**
 * tournamentType block component
 */
export default {
    name: 'tournamentStandingTypeRow',
    extends: base,
    props: {
        tournamentStandingType: {
            type: TournamentStandingTypeModel,
            required: false,
            default(){
                return new TournamentStandingTypeModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },
        showSport: {
            type: Boolean,
            default: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        detailLink(){
            return {
                name: 'administrationTournamentStandingTypeDetail',
                params: Object.assign({
                    dataTournamentStandingTypeId: this.tournamentStandingType.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                tournamentStandingType: this.tournamentStandingType || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showDelete);
        },
    },
};
</script>
