import Model from '@/models/model.js';

/**
 * TournamentStandingTypeParameter model class
 */
export default class TournamentStandingTypeParameterModel extends Model {
    /**
     * id
     *
     * @type {number}
     */
    id = 0;

    /**
     * group
     *
     * @type {string}
     */
    group = '';

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * value
     *
     * @type {number}
     */
    value = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.discriminator = data.discriminator || 0;
            this.group = data.group || '';
            this.id = data.id || 0;
            this.typeId = data.typeId || 0;
            this.name = data.typeName || data.name || data.parameterName || '';
            this.value = data.value || 0;
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            discriminator: this.discriminator,
            group: this.group,
        };
    }
}
