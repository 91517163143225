<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <b-form-checkbox
            v-model="value"
            v-bind:id="elementId"
            v-bind:name="config.name"
            v-bind:value="inputValue"
            v-bind:uncheckedValue="uncheckedInputValue"
            v-bind:class="elementClasses"
            v-bind:required="config.required || false"
            v-bind:disabled="config.disabled"
            v-bind:state="state"
        >
            {{ config.text }}
        </b-form-checkbox>
    </b-form-group>
</template>

<script>
import base from './base.vue';

/**
 * checkbox element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'checkboxElement',
    extends: base,
    props: {
        dataValue: {
            type: [String, Number, Boolean],
        },
    },
    data(){
        return {
            value: null
        };
    },
    computed: {
        inputValue(){
            return this.config.checkboxvalue || true;
        },
        uncheckedInputValue(){
            return this.config.uncheckedValue || false;
        },
        customLabelClasses(){
            return {
                'col-form-label': true,
                'pt-0': true,
            };
        },
    },
    methods: {
        convertValueIn(){
            return ((this.value === this.inputValue || this.value === true) ? this.inputValue : this.uncheckedInputValue);
        },
    },
};
</script>
