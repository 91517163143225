<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-text v-bind="getColumnProps('order', 12, 6, 2)" v-if="showOrder">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('order')">{{ getHeadline('order', $tc('order', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ position.order }}</span>
        </b-card-text>

        <b-card-title v-bind="getColumnProps('name', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{ getHeadline('name', $t('name')) }}</label>
            <span class="text-uppercase" v-else>{{ position.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from '../base.vue';

import PositionModel from '@/models/position.js';

/**
 * union block component
 */
export default {
    name: 'managementPositionRow',
    extends: base,
    props: {
        position: {
            type: PositionModel,
            default(){
                return new PositionModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },
        showOrder: {
            type: Boolean,
            default: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        emitEventData(){
            return {
                position: this.position || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail);
        },
    },
};
</script>
