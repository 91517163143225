import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonPersonRoleDetail from '@/views/common/person/role/detail.vue';

export default {
    path: 'role/',
    component: pageFrame,
    children: [
        {
            name: 'commonPersonRoleDetail',
            path: routerTools.propRoute(constants.routeProps.personRoleId),
            component: commonPersonRoleDetail,
            props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.personId, constants.routeProps.personRoleId]),
            meta: {
                permission: permissions.persons.roles.detail,
            },
        },
    ],
};
