<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <b-form-select
            v-model="value"
            v-bind:id="elementId"
            v-bind:required="config.required || false"
            v-bind:disabled="config.disabled || false"
            v-bind:state="state"
        >
            <b-form-select-option v-if="config.placeholder" :value="''" selected>{{ config.placeholder }}</b-form-select-option>
            <b-form-select-option v-for="(title, key) in config.options" v-bind:key="key" v-bind:value="key">{{ title }}</b-form-select-option>
        </b-form-select>
    </b-form-group>
</template>

<script>
import base from './base.vue';

/**
 * select element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'selectElement',
    extends: base,
    data(){
        return {
            value: null,
        };
    },
};
</script>
