<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <b-img thumbnail src="https://picsum.photos/250/250/?image=54" blank-color="#CCC" alt="Transparent image"></b-img>
    </b-form-group>
</template>

<script>
import base from './base.vue';

/**
 * image select element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'imageElement',
    extends: base,
};
</script>
