<script>
import formComponent from '@/components/form.vue';
import TeamModel from '@/models/team.js';

/**
 * event series points for team form component
 */
export default {
    name: 'teamEventSeriesPointsForm',
    extends: formComponent,
    props: {
        model: {
            type: TeamModel,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'points',
                        type: 'text',
                        label: this.$t('points'),
                        placeholder: this.$t('enterPoints'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
