<script>
import formComponent from '@/components/form.vue';
import SeriesTypeModel from '@/models/seriesType.js';

/**
 * component
 */
export default {
    name: 'createSeriesTypeForm',
    extends: formComponent,
    props: {
        model: {
            type: SeriesTypeModel,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('seriesTypeName'),
                        placeholder: this.$t('name'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'description',
                        type: 'textarea',
                        label: this.$t('seriesTypeDescription'),
                        placeholder: this.$t('enterDescription'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
