<script>
import vselectElement from '../vselect.vue';
import LocationModel from '@/models/location.js';

/**
 * vselect element for LocationModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectLocationElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                return this.$api.call.location.locationSearch({
                    name: text || null,
                    paging: this.usePagination(offset),
                }).then(result => (result.locations || result.items || []).map(data => new LocationModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'name':
                        return this.$api.call.location.locationSearch({
                            name: initValues,
                            paging: this.usePagination(0, 100),
                        }).then(result => (result.locations || result.items || []).map(data => new LocationModel(data)));

                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.location.locationGetById(id)))
                            .then(results => results.map(data => new LocationModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
