import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class ApiService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/api-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // Consumer

    /**
     *
     */
    consumerGetAll(){
        return this.get('consumer');
    }

    /**
     *
     */
    consumerGetById(id){
        return this.get(['consumer', id]);
    }

    /**
     *
     */
    consumerCreate(data){
        return this.post('consumer', data);
    }

    /**
     *
     */
    consumerUpdateById(id, data){
        return this.put(['consumer', id], data);
    }

    /**
     *
     */
    consumerDeleteById(id){
        return this.delete(['consumer', id]);
    }

    // APiKey

    /**
     *
     */
    apiKeyCreate(data){
        return this.post('api-key', data);
    }

    /**
     *
     */
    apiKeyGetById(id){
        return this.get(['api-key', id]);
    }

    /**
     *
     */
    apiKeyUpdateById(id, data){
        return this.put(['api-key', id], data);
    }

    /**
     *
     */
    apiKeyDeleteById(id){
        return this.delete(['api-key', id]);
    }

    // ConsumerTournamentAccess

    /**
     *
     */
    consumerTournamentAccessCreate(data){
        return this.post('consumer-tournament-access', data);
    }

    /**
     *
     */
    consumerTournamentAccessGetByAuthenticationUsername(authenticationUsername){
        return this.get(['consumer-tournament-access', authenticationUsername]);
    }

    /**
     *
     */
    consumerTournamentAccessUpdateByAuthenticationUsername(authenticationUsername, data){
        return this.put(['consumer-tournament-access', authenticationUsername], data);
    }

    /**
     *
     */
    consumerTournamentAccessDeleteByAuthenticationUsername(authenticationUsername){
        return this.delete(['consumer-tournament-access', authenticationUsername]);
    }

    // ConsumerSNSService

    /**
     *
     */
    consumerSNSServiceCreate(data){
        return this.post('consumer-sns-service', data);
    }

    /**
     *
     */
    consumerSNSServiceGetByAuthenticationUsername(authenticationUsername){
        return this.get(['consumer-sns-service', authenticationUsername]);
    }

    /**
     *
     */
    consumerSNSServiceUpdateByAuthenticationUsername(authenticationUsername, data){
        return this.put(['consumer-sns-service', authenticationUsername], data);
    }

    /**
     *
     */
    consumerSNSServiceDeleteByAuthenticationUsername(authenticationUsername){
        return this.delete(['consumer-sns-service', authenticationUsername]);
    }

    // ConsumerApiPermission

    /**
     *
     */
    consumerApiPermissionCreate(data){
        return this.post('consumer-api-permission', data);
    }

    /**
     *
     */
    consumerApiPermissionGetByAuthenticationUsername(authenticationUsername){
        return this.get(['consumer-api-permission', authenticationUsername]);
    }

    /**
     *
     */
    consumerApiPermissionUpdateByAuthenticationUsername(authenticationUsername, data){
        return this.put(['consumer-api-permission', authenticationUsername], data);
    }

    /**
     *
     */
    consumerApiPermissionDeleteByAuthenticationUsername(authenticationUsername){
        return this.delete(['consumer-api-permission', authenticationUsername]);
    }
}
