import Model from '@/models/model.js';

/**
 * team model class
 */
export default class VenueAliasModel extends Model {
    /**
     * venue alias id
     *
     * @type {int}
     */
    id = 0;

    /**
     * venue alias eq id
     *
     * @type {string}
     */
     eqId = '';

    /**
     * venue id
     *
     * @type {int}
     */
    venueId = 0;

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * valid from
     *
     * @type {Date}
     */
    dateFrom = null;

    /**
     * valid until
     *
     * @type {Date}
     */
    dateUntil = null;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);
        if(data){
            this.eqId = data.eqId || '';
            this.dateFrom = data.dateFrom ? this.toDate(data.dateFrom) : null;
            this.dateUntil = data.dateUntil ? this.toDate(data.dateUntil) : null;
            this.id = data.id || data.venueAliasId || 0;
            this.name = data.venueName || data.name || '';
            this.venueEqId = data.venueEqId || '';
            this.venueId = data.venueId || 0;
        }
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPath(){
        return require('@/assets/img/union.svg');
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            dateFrom: this.fromDate(this.dateFrom),
        };
    }
}
