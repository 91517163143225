<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'ipWhitelistForm',
    extends: formComponent,
    props: {
    },
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'ipType',
                        type: 'radioselect',
                        selected: 'ipSingle',
                        options: [
                            { value: 'ipSingle', text: this.$t('ipSingle') },
                            { value: 'ipRange', text: this.$t('ipRange') }
                        ],
                        // stacked: true,
                        plain: false,
                        label: this.$t('type'),
                        required: true,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'ipSingle',
                        type: 'text',
                        label: this.$t('ipAddress'),
                        placeholder: this.$t('ipAddress'),
                    },
                ], 'ipType === "ipSingle"'),
                this.createFormRow([
                    {
                        id: 'ipRangeStart',
                        type: 'text',
                        label: this.$t('ipRangeStart'),
                        placeholder: this.$t('ipRangeStart'),
                    },
                    {
                        id: 'ipRangeEnd',
                        type: 'text',
                        label: this.$t('ipRangeEnd'),
                        placeholder: this.$t('ipRangeEnd'),
                    },
                ], 'ipType === "ipRange"'),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
