import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class SeriesService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/series-service/api/';

    /**
     * base path parts
     *
     * @type {object}
     */
    _paths = {
        seriesTournament: 'series-tournament',
    };

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // Series

    /**
     * (params tbc)
     */
    seriesSearch(query){
        return this.post(['series', 'search'], query);
    }

    /**
     *
     */
    seriesGetById(id){
        return this.get(['series', id]);
    }

    /**
     *
     */
    seriesGetBySeriesTypeId(id){
        return this.get(['series', id]);
    }

    /**
     *
     */
    seriesCreate(data){
        return this.post('series', data);
    }

    /**
     *
     */
    seriesUpdateById(id, data){
        return this.patch(['series', id], data);
    }

    /**
     *
     */
    seriesDeleteById(id){
        return this.delete(['series', id]);
    }

    // SeriesType

    /**
     *
     */
    seriesTypeCreate(data){
        return this.post('series-type', data);
    }

    /**
     *
     */
    seriesTypeGetAll(){
        return this.get('series-type');
    }

    /**
     *
     */
    seriesTypeGetById(id){
        return this.get(['series-type', id]);
    }

    /**
     *
     */
    seriesTypeUpdateById(id, data){
        return this.patch(['series-type', id], data);
    }

    /**
     *
     */
    seriesTypeDeleteById(id){
        return this.delete(['series-type', id]);
    }

    // SeriesTeam

    /**
     *
     */
    seriesTeamCreate(data){
        return this.post('series-team', data);
    }

    /**
     *
     */
    seriesTeamGetById(id){
        return this.get(['series-team', id]);
    }

    /**
     *
     */
    seriesTeamUpdateById(id, data){
        return this.put(['series-team', id], data);
    }

    /**
     *
     */
    seriesTeamDeleteById(id){
        return this.delete(['series-team', id]);
    }

    /**
     *
     */
    seriesTeamSearch(query){
        return this.post(['series-team', 'search'], query);
    }

    // SeriesTeamManagement

    /**
     *
     */
    seriesTeamManagementCreate(data){
        return this.post('series-team-management', data);
    }

    /**
     *
     */
    seriesTeamManagementGetById(id){
        return this.get(['series-team-management', id]);
    }

    /**
     *
     */
    seriesTeamManagementUpdateById(id, data){
        return this.put(['series-team-management', id], data);
    }

    /**
     *
     */
    seriesTeamManagementDeleteById(id){
        return this.delete(['series-team-management', id]);
    }

    /**
     *
     */
    seriesTeamManagementGetAllBySeriesTeamId(teamId){
        return this.get(['series-team-management', teamId]);
    }

    // SeriesTeamPlayer

    /**
     *
     */
    seriesTeamPlayerCreate(data){
        return this.post('series-team-player', data);
    }

    /**
     *
     */
    seriesTeamPlayerGetById(id){
        return this.get(['series-team-player', id]);
    }

    /**
     *
     */
    seriesTeamPlayerUpdateById(id, data){
        return this.put(['series-team-player', id], data);
    }

    /**
     *
     */
    seriesTeamPlayerDeleteById(id){
        return this.delete(['series-team-player', id]);
    }

    /**
     *
     */
    seriesTeamPlayerGetAllBySeriesTeamId(teamId){
        return this.get(['series-team-player', teamId]);
    }

    // SeriesTournament

    /**
     *
     */
    seriesTournamentCreate(data){
        return this.post(this._paths.seriesTournament, data);
    }

    /**
     *
     */
    seriesTournamentsGetBySeriesId(id){
        return this.get([this._paths.seriesTournament, 'series-id', id]);
    }

    /**
     *
     */
    seriesTournamentGetByTournamentId(id){
        return this.get([this._paths.seriesTournament, id]);
    }

    /**
     *
     */
    seriesTournamentDeleteByTournamentId(id){
        return this.delete([this._paths.seriesTournament, id]);
    }
}
