<script>
import actionButton from '@/components/actionButton.vue';
import confirmModal from '@/components/modals/confirm.vue';
import icon from '@/components/icon.vue';
import PermissionModel from '@/models/permission.js';

/**
 * base row component
 * @abstract
 */
export default {
    name: 'baseRow',
    components: {
        actionButton,
        confirmModal,
        icon,
    },
    props: {
        /**
         * header is used as headline. columns will show description label instead of content.
         */
        isHeadline: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * headline configuration
         * example format:
         * {
         *     columnType: {
         *         label: 'alternate headline label',
         *         sortKey: 'keyToSortFor',
         *     },
         *     anotherColumnType: {
         *         sortKey: 'anotherKeyToSortFor',
         *     },
         *     yetAnotherColumnType: 'another alternate headline label',
         * }
         */
        headlineConfig: {
            type: Object,
            required: false,
            default(){
                return {};
            },
        },
        /**
         * headline sort key
         */
        headlineSortKey: {
            type: String,
            default: 'name',
        },
        /**
         * headline sort direction (true = ASC, false = DESC)
         */
        headlineSortAsc: {
            type: Boolean,
            default: true,
        },
        /**
         * row body class
         */
        bodyClass: {
            type: [String, Array, Object],
            default: null,
        },
        /**
         * disable all possible actions
         */
        readonly: {
            type: Boolean,
            default: false,
        },
        /**
         * ARIA role
         * should be "listitem" if inside list
         * if not set and any of the "assign" props is enabled, role "button" will be used
         */
        role: {
            type: String,
            default: null,
        },
        /**
         * detail link route name
         */
        detailLinkRoute: {
            type: String,
            default: null,
        },
        /**
         * arrow link rut name
         */
        arrowLinkRoute: {
            type: String,
            default: null,
        },
        /**
         * additional params for detail link
         */
        detailLinkParams: {
            type: Object,
            default(){
                return {};
            },
        },
        /**
         * method to be executed when potential update modal is confirmed.
         * if function return {Promise}, its result will be depending on the promise state.
         * other function return values will be ignored, except for {Boolean} false.
         */
        updateHandler: {
            type: Function,
            default: null,
        },
        /**
         * method to be executed when remove prompt is confirmed to process the removal.
         * if function return {Promise}, its result will be depending on the promise state.
         * other function return values will be ignored, except for {Boolean} false.
         */
        removeHandler: {
            type: Function,
            default: null,
        },
        /**
         * permission for edit button
         */
        editPermission: {
            type: PermissionModel,
            default: null,
            required: false,
        },
        /**
         * permission for remove button
         */
        removePermission: {
            type: PermissionModel,
            default: null,
            required: false,
        },
    },
    computed: {
        emitEventData(){
            //overwrite me
            return {};
        },
        customElementClasses(){
            //overwrite me
            return {};
        },
        rowClasses(){
            const classes = {
                entryRow: true,
                headlineRow: this.isHeadline,
                'd-none': this.isHeadline,
                'd-lg-block': this.isHeadline,
                'm-0': true,
                'p-0': true,
            };

            //if component has a name, add class based on it
            if(this.$options.name){
                classes[this.$options.name] = true;
            }

            //add custom classes if defined
            if(this.customElementClasses){
                Object.assign(classes, this.customElementClasses);
            }

            return classes;
        },
        rowBodyClasses(){
            const classes = {
                'm-0': true,
                'mb-5': true,
                'mb-lg-0': true,
                'px-3': true,
                'px-lg-0': !this.isHeadline,
                'px-lg-5': this.isHeadline,
                'py-3': true,
                'py-lg-4': !this.isHeadline,
            };

            //add bodyClass prob if given
            if(this.bodyClass){
                let moreClasses = this.bodyClass;

                //split into array if string
                if(typeof moreClasses === 'string'){
                    moreClasses = moreClasses.split(' ');
                }

                //convert to object if array
                if(moreClasses instanceof Array){
                    moreClasses = moreClasses.reduce((result, current) => {
                        result[current] = true;
                        return result;
                    }, {});
                }

                //combine if object
                if(moreClasses instanceof Object){
                    Object.assign(classes, moreClasses);
                }
            }

            return classes;
        },
        rowAttributes(){
            return {
                class: this.rowClasses,
                'body-class': this.rowBodyClasses,
                'img-left': true,
                role: this.rowRole,
            };
        },
        rowRole(){
            return this.role || ((this.rowButtonRole && !this.isHeadline) ? 'button' : null);
        },
        rowButtonRole(){
            return false;
        },
        headlineClasses(){
            return {
                rowHeadline: true,
                'd-block': true,
                'd-lg-none': !this.isHeadline,
                'm-0': true,
                'p-0': true,
                'text-uppercase': true,
                'font-heavy': !this.isHeadline,
            };
        },
        buttonClasses(){
            if(!this.isHeadline){
                return {
                    'ml-2': true,
                    //'btn-flat': true,
                };
            }

            return {
                'ml-2': true,
                'my-0': true,
                'py-0': true,
                'border-top-0': true,
                'border-bottom-0': true,
                'h-0': true,
                'pe-none': true,
                invisible: true,
            };
        },
        buttonAttributes(){
            return {
                disabled: this.readonly || this.isHeadline,
                class: this.buttonClasses,
            };
        },
        inputAttributes(){
            return this.buttonAttributes;
        },
        selectAttributes(){
            const attributes = this.buttonAttributes;

            if(!attributes.class){
                attributes.class = {};
            }

            attributes.class['text-primary'] = true;
            //attributes.class['custom-select-flat'] = true;

            return attributes;
        },
        titleTag(){
            if(this.isHeadline){
                return 'div';
            }
            return 'h3';
        },
    },
    methods: {
        getColumnProps(type, mobileOrder = 0, mobileCols = 0, desktopCols = 0, emptyHeadline = false, additionalClasses = {}){
            const classes = {};

            //header classes
            if(this.isHeadline){
                //sorting classes
                const sortKey = (typeof this.headlineConfig[type] === 'object' ? this.headlineConfig[type] : {}).sortKey;
                classes.sortable = !!sortKey;
                classes.sorted = (this.headlineSortKey === sortKey);
                classes.sortedAsc = (classes.sorted && this.headlineSortAsc);
                classes.sortedDesc = (classes.sorted && !this.headlineSortAsc);

                //hide empty headlines
                if(emptyHeadline){
                    classes['h-0'] = true;
                    classes.invisible = true;
                }
            }

            //flex order classes
            if(mobileOrder >= 0 && mobileOrder <= 12){
                classes['order-' + mobileOrder] = true;
                classes['order-lg-0'] = true;
            }

            //mobile size classes
            if(mobileCols > 0 && mobileCols <= 12){
                classes['col-' + mobileCols] = true;
            }
            else if(mobileCols === 'auto'){
                classes['col-auto'] = true;
            }

            //desktop size classes
            if(desktopCols > 0 && desktopCols <= 12){
                classes['col-lg-' + desktopCols] = true;
            }
            else if(mobileCols > 0 && mobileCols <= 12){
                classes['col-lg-auto'] = true;
            }

            //if additional classes are defined in computed prop, merge it in
            Object.assign(classes, additionalClasses);

            return {
                'data-type': type,
                class: classes,
            };
        },
        getHeadline(type, fallback = ''){
            if(this.headlineConfig[type]){
                if(typeof this.headlineConfig[type] === 'string'){
                    return this.headlineConfig[type] || fallback;
                }

                return this.headlineConfig[type].label || fallback;
            }
            return fallback;
        },
        handleHeadlineSort(type){
            const sortKey = (typeof this.headlineConfig[type] === 'object' ? this.headlineConfig[type] : {}).sortKey;

            if(!sortKey){
                return;
            }

            let sortAsc = true;
            if(this.headlineSortKey && this.headlineSortKey === sortKey){
                sortAsc = !this.headlineSortAsc;
            }

            /**
             * Headline sorting has been requested
             *
             * @param {string} sortKey Key to sort for
             * @param {boolean} sortAsc Sort in ascending direction
             */
            this.$emit('headlineSort', sortKey, sortAsc);
        },
        modalName(type, id){
            return ('modal-' + type + '-' + id).replace(/[^a-zA-Z0-9_-]/g, '');
        },
        emitEvent(type, event, data){
            /**
             * row action has been triggered
             *
             * @param {object} data Event data
             */
            return this.$emit(type, Object.assign({
                event,
            }, this.emitEventData, data || {}));
        },
    },
};
</script>
