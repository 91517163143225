<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createRankingPenalty')"
            v-on:submit="createPenalty"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toPenaltyList"
                    v-bind:loadingWhile="apiData.penaltyCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.penaltyCreation"
                >
                    {{ $t('create') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createRankingPenaltyForm
                    v-bind:model="penalty"
                    v-bind:sport="sportId"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';
import validations from '@/components/validations.vue';
import createRankingPenaltyForm from '@/components/forms/createRankingPenalty.vue';

import PenaltyModel from '@/models/penalty.js';

export default {
    name: 'administrationCreateRankingPenalties',
    extends: rankingBase,
    components: {
        validations,
        createRankingPenaltyForm
    },
    data(){
        return {
            apiData: {
                penaltyCreation: null,
            },
            penalty: PenaltyModel.from({}),
            error: null,
        };
    },
    computed: {
        toPenaltyList(){
            return { name: 'administrationRankingPenaltiesIndex' };
        },
    },
    methods: {
        createPenalty() {
            this.penalty.sport = this.sportId;

            this.apiData.penaltyCreation = this.$api.call.rankings.alterationCreate(this.penalty.toJSON()).then(result => {
                this.error = null;
                this.$router.push(this.toPenaltyList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('penalty creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
