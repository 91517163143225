<script>
import personPickModal from './person.vue';
import medicList from '@/components/lists/medic.vue';

/**
 * medic pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'medicPickModal',
    extends: personPickModal,
    components: {
        medicList,
    },
    data(){
        return {
            listElement: medicList,
        };
    },
};
</script>
