<template>
    <div v-bind:class="elementClasses">
        <hr/>
    </div>
</template>

<script>
import base from './base.vue';

/**
 * horizontal line element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'hlineElement',
    extends: base,
};
</script>
