<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'addOfficialForm',
    extends: formComponent,
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'official',
                        type: 'vselectOfficial',
                        label: this.$tc('official', 1),
                        placeholder: this.$t('selectOfficial'),
                        required: true,
                        valueProperty: 'eqId',
                    },
                    {
                        id: 'position',
                        type: 'vselectOfficialPosition',
                        plain: false,
                        label: this.$t('position'),
                        placeholder: this.$t('selectPosition'),
                        required: true,
                        valueProperty: 'eqId',
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                eqId: 'official',
                positionEqId: 'position',
                positionSourceEqId: 'position',
            };
        },
    },
};
</script>
