import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class StandingsService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/standings-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // tournament standing

    /**
     *
     */
    tournamentStandingCreate(data){
        return this.post('tournament-standing', data);
    }

    /**
     *
     */
    tournamentStandingGetByTournament(tournament){
        return this.get(['tournament-standing', tournament]);
    }

    /**
     *
     */
    tournamentStandingUpdateByTournament(tournament, data){
        return this.put(['tournament-standing', tournament], data);
    }

    /**
     *
     */
    tournamentStandingDeleteByTournament(tournament){
        return this.delete(['tournament-standing', tournament]);
    }

    // TournamentStandingTeam

    /**
     *
     */
    tournamentStandingTeamCreate(data){
        return this.post('tournament-standing-team', data);
    }

    /**
     *
     */
    tournamentStandingTeamGetByTournamentAndTeam(tournament, team){
        return this.get(['tournament-standing-team', tournament, team]);
    }

    /**
     *
     */
    tournamentStandingTeamUpdateByTournamentAndTeam(tournament, team, data){
        return this.put(['tournament-standing-team', tournament, team], data);
    }

    /**
     *
     */
    tournamentStandingTeamDeleteByTournamentAndTeam(tournament, team){
        return this.delete(['tournament-standing-team', tournament, team]);
    }

    // TournamentStandingType

    /**
     *
     */
    tournamentStandingTypeCreate(data){
        return this.post('tournament-standing-type', data);
    }

    /**
     *
     */
    tournamentStandingTypeGetById(id){
        return this.get(['tournament-standing-type', id]);
    }

    /**
     *
     */
    tournamentStandingTypeGetAll(){
        return this.get('tournament-standing-type');
    }

    /**
     *
     */
    tournamentStandingTypeUpdateById(id, data){
        return this.put(['tournament-standing-type', id], data);
    }

    /**
     *
     */
    tournamentStandingTypeDeleteById(id){
        return this.delete(['tournament-standing-type', id]);
    }
}
