<script>
import vselectElement from '../vselect.vue';
import PersonModel from '@/models/person.js';

/**
 * vselect element for PersonModel Officials items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectOfficialElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                return this.$api.call.person.personSearch({
                    roleType: 'Official',
                    displayName: text || null,
                    paging: this.usePagination(offset, 100),
                }).then(result => (result.persons || result.items || []).map(data => new PersonModel(data)));
            };

            return config;
        },
    },
};
</script>
