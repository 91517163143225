<script>
import declarationPersonPickModal from './person.vue';
import declarationMedicList from '@/components/lists/declaration/medic.vue';

/**
 * declaration medic pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'declarationMedicPickModal',
    extends: declarationPersonPickModal,
    components: {
        declarationMedicList,
    },
    data(){
        return {
            listElement: declarationMedicList,
        };
    },
};
</script>
