<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
    >
        <template v-slot="{ formId, submit }">
            <editTeamAliasForm
                v-bind:dataId="formId"
                v-bind:headline="$t('teamAliasInformation')"
                v-bind:values="values"
                v-bind:create="create"
                v-bind:errors="errorIds"
                v-on:change="updateAlias"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import editTeamAliasForm from '@/components/forms/editTeamAlias.vue';

import TeamAliasModel from '@/models/teamAlias.js';

/**
 * team alias edit modal component
 */
export default {
    name: 'editCreateTeamAliasModal',
    extends: base,
    components: {
        editTeamAliasForm,
    },
    props: {
        teamAlias: {
            type: TeamAliasModel,
            default(){
                return new TeamAliasModel();
            }
        },
        teamId: {
            type: Number,
            default(){
                return 0;
            }
        },
        create: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            values: {
                teamName: this.teamAlias.name,
                teamNameShort: this.teamAlias.nameShort,
                teamCode: this.teamAlias.teamCode,
                teamCodeAnnual: this.teamAlias.teamCodeAnnual,
                dateFrom: this.teamAlias.dateFrom,
            }
        };
    },
    computed: {
        payload(){
            return this.teamAlias;
        },
    },
    methods: {
        updateAlias(values){
            this.teamAlias.name = values.teamName;
            this.teamAlias.nameShort = values.teamNameShort;
            this.teamAlias.teamCode = values.teamCode;
            this.teamAlias.teamCodeAnnual = values.teamCodeAnnual;
            this.teamAlias.dateFrom = values.dateFrom;
        },
    },
};
</script>
