import Model from '@/models/model.js';
import RankingRunMatchModel from '@/models/rankingRunMatch.js';

/**
 * ranking data model class
 */
export default class RankingDataModel extends Model {
    /**
     * id
     *
     * @type {int}
     */
    id = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.points = data.points || null;
            this.position = data.position || null;
            this.previousPoints = data.previousPoints || null;
            this.previousPosition = data.previousPosition || null;
            this.teamCode = data.teamCode || '';
            this.teamEqId = data.teamEqId || '';
            this.teamName = data.teamName || '';
            this.positionDifference = data.previousPosition - data.position;
            this.sameRank = data.position === data.previousPosition;
            this.runMatches = (data.details || []).map(data => new RankingRunMatchModel(data));
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.effectiveDate;
    }
}
