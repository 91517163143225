<script>
import formComponent from '@/components/form.vue';
import TournamentStageModel from '@/models/tournamentStage.js';

/**
 * component
 */
export default {
    name: 'editTournamentStageForm',
    extends: formComponent,
    props: {
        model: {
            type: TournamentStageModel,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('name'),
                        placeholder: this.$t('enterName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'abbreviation',
                        type: 'text',
                        label: this.$t('abbreviation'),
                        placeholder: this.$t('enterAbbreviation'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'order',
                        type: 'number',
                        label: this.$t('order'),
                        placeholder: '0',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
