import Model from '@/models/model.js';

/**
 * workflowsummary model class
 */
export default class WorkflowSummaryModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * status
     *
     * @type {string}
     */
    status = '';

    /**
     * format
     *
     * @type {string}
     */
    format = '';

    /**
     * dealine
     *
     * @type {string}
     */
    deadline = null;

    /**
     * main summary
     *
     * @type {boolean}
     */
    main = false;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.main = data.main || false;
            this.name = data.name || '';
            this.status = data.status || '';
            this.format = data.format || '.PDF, .PNG, .JPG';
            this.deadline = this.toDate(data.deadline);
        }
    }
}
