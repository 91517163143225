<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'teamKitSelectionForm',
    extends: formComponent,
    props: {
        matchTeamId: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        elementsConfig(){
            return [
                {
                    id: 'kitSelection',
                    type: 'vselectTeamKit',
                    label: this.$t('matchKit'),
                    readonly: false,
                    disabled: false,
                    byMatchTeamId: this.matchTeamId,
                    returnFullOptions: true,
                },
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
