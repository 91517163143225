import Model from '@/models/model.js';
import ApiServiceModel from '@/models/apiService.js';

/**
 * PermissionGroup model class
 */
export default class PermissionGroupModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * publishing access level
     *
     * @type {string}
     */
    publishingAccessLevel = '';

    /**
     * services
     *
     * @type {array}
     */
    services = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     * @param {boolean} [isAuthorised = false]
     */
    constructor(data = {}, isAuthorised = false){
        super(data);

        if(data){
            this.eqId = data.eqId || '';
            this.id = data.permissionGroupId || data.id || 0;
            this.name = data.name || data.userRole || '';
            this.publishingAccessLevel = data.accessLevel || '';
            this.services = Object.entries(data.permissions || data.services || {}).map(([key, data]) => ApiServiceModel.from(data, false, isAuthorised).setId(key));
        }
    }

    /**
     * get api compatible object json
     *
     * @returns {object}
     */
    toJSON(){
        const permissions = {};
        this.services.forEach(service => {
            const data = service.toJSON();
            if(data && (data.resources).length){
                permissions[service.id] = data;
            }
        });

        return {
            id: this.id,
            eqId: this.eqId || null, // we have to send null by create
            name: this.name,
            accessLevel: this.publishingAccessLevel,
            permissions: permissions,
        };
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
