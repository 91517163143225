<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:waitFor="apiData.person"
            v-bind:waitForRetry="reloadPerson"
            v-bind:title="$t('personDetails')"
            v-bind:name="person ? person.getName() : ''"
            v-bind:imagePath="person ? person.getImgPath() : null"
            v-bind:status="status"
            v-bind:statusType="statusType"
            v-bind:toBeDeletedOn="toBeDeletedOn"
            v-bind:details="detailData"
            v-bind:moreDetails="moreDetailData"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="secondary"
                    v-bind:to="editPersonLink()"
                >
                    {{ $t('edit') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:permission="permissions.persons.delete"
                    v-bind:waitFor="apiData.person"
                    v-b-modal="modalName('confirmDelete', person.id)"
                    v-if="person && !person.isDeleted && !person.deletionDate"
                >
                    {{ $t('delete') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:permission="permissions.persons.undelete"
                    v-bind:waitFor="apiData.person"
                    v-b-modal="modalName('confirmUndoDeletion', person.id)"
                    v-if="person && !person.isDeleted && person.deletionDate"
                >
                    {{ $t('undoDeletion') }}
                </actionButton>
            </template>
        </detailsHeader>

        <confirmModal
            v-bind:id="modalName('confirmDelete', person.id)"
            v-bind:text="$t('pleaseConfirmDeletionOfPerson', { name: person.firstName + ' ' +  person.lastName})"
            v-bind:data="person"
            v-bind:confirmHandler="markAsDeleted"
            v-if="person && !person.isDeleted && !person.deletionDate"
        />

        <confirmModal
            v-bind:id="modalName('confirmUndoDeletion', person.id)"
            v-bind:text="$t('pleaseConfirmUndoDeletion', { name: person.firstName + ' ' +  person.lastName})"
            v-bind:data="person"
            v-bind:confirmHandler="undoDelete"
            v-if="person && !person.isDeleted && person.deletionDate"
        />

        <subNavigation v-bind:data-config="subnavigation" />

        <!-- person roles -->
        <list
            v-bind:items="personRoles"
            v-bind:title="$tc('role', 2)"
            v-bind:waitFor="apiData.personRoles"
            v-bind:waitForRetry="reloadPersonRoles"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.persons.roles.create"
                    v-b-modal.modal-createPersonRole
                >
                    {{ $t('createNewRole') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <roleRow
                    isHeadline
                />
            </template>
            <template v-slot="{ item: role }">
                <roleRow
                    v-bind:role="role"
                    v-bind:dataPersonId="dataPersonId"
                    v-bind:person="person || undefined"
                    v-bind:editPermission="permissions.persons.roles.update"
                    v-bind:updateHandler="updatePersonRole"
                    v-on:updated="reloadPersonRoles"
                />
            </template>
        </list>

        <editPersonRoleModal
            id="modal-createPersonRole"
            v-bind:title="$t('addPersonRole')"
            v-bind:personId="dataPersonId"
            v-bind:personRole="apiData.newPersonRoleModel"
            v-bind:create="true"
            v-bind:confirmHandler="createPersonRole"
            v-on:confirmed="reloadPersonRoles"
        />
        <!-- END person roles -->

        <!-- player summary -->
        <wrapper
            v-bind:title="$t('playerSummary')"
            v-bind:waitFor="Promise.all([this.apiData.playerTotalSummary, this.apiData.playerSummary])"
            v-bind:waitForRetry="reloadFullPlayerSummary"
            noShadow
            noLine
            noHorizontalPadding
            v-bind:hide="hideElement(2)"
            v-if="hasPlayerRole"
        >
            <template v-slot:buttons>
                <b-form-select
                    v-model="playerMatchesSelected"
                    v-bind:options="playerMatchesOptions"
                    v-on:change="matchTypeSelected"
                    size="lg"
                />
            </template>
            <template v-slot>
                <b-row
                    cols="1"
                    cols-lg="2"
                    align-v="center"
                    v-if="playerSummary.length > 0"
                >
                    <b-col>
                        <bar
                            v-bind:label1="$t('won')"
                            v-bind:label2="$tc('draw', 1)"
                            v-bind:label3="$t('lost')"
                            v-bind:number1="totalSummary.won"
                            v-bind:number2="totalSummary.drawn"
                            v-bind:number3="totalSummary.lost"
                        />
                    </b-col>
                    <b-col>
                        <tiles v-bind:tiles="playerTotalSummary"/>
                    </b-col>
                </b-row>
                <div v-else class="w-100 text-center">{{ $t('noData') }}</div>

                <b-table
                    class="mt-4"
                    head-variant="light"
                    responsive
                    hover
                    v-bind:items="playerSummary"
                    v-bind:fields="tableFieldsPlayerSummary"
                    v-if="playerSummary.length > 0"
                >
                    <template #head()="data">
                        <span v-if="data.column !== 'teamName' && data.column !== 'lastCap'" v-b-tooltip.hover.top="data.label">{{ data.column.toUpperCase() }}</span>
                    </template>
                </b-table>
            </template>
        </wrapper>
        <!-- END player summary -->

        <!-- player summary breakdown -->
        <wrapper
            v-bind:title="$t('playerSummaryBreakdown')"
            v-bind:waitFor="apiData.playerSummaryBreakdown"
            v-bind:waitForRetry="reloadPlayerSummaryBreakdown"
            noShadow
            noLine
            noHorizontalPadding
            v-bind:hide="hideElement(3)"
            v-if="hasPlayerRole"
        >
            <template v-slot>
                <b-table
                    head-variant="light"
                    responsive
                    hover
                    v-bind:items="playerSummaryBreakdown"
                    v-bind:fields="tableFieldsPlayerSummaryBreakdown"
                    v-if="playerSummaryBreakdown.length > 0"
                >
                    <template #head()="data">
                        <span v-if="data.column !== 'teamName' && data.column !== 'lastCap'" v-b-tooltip.hover.top="data.label">{{ data.column.toUpperCase() }}</span>
                    </template>
                </b-table>
                <div v-else class="w-100 text-center">{{ $t('noData') }}</div>
            </template>
        </wrapper>
        <!-- END player summary breakdown -->

        <!-- head coach summary -->
        <wrapper
            v-bind:title="$t('headCoachTestSummary')"
            v-bind:waitFor="apiData.headCoachSummary"
            v-bind:waitForRetry="reloadHeadCoachSummary"
            noShadow
            noLine
            noHorizontalPadding
            v-bind:hide="hideElement(4)"
            v-if="hasManagementOfficialRole"
        >
            <b-table
                head-variant="light"
                responsive
                hover
                v-bind:items="playerHeadCoachSummary"
                v-bind:fields="tableFieldsheadCoachSummary"
                v-if="playerHeadCoachSummary.length > 0"
            >
                <template #head()="data">
                    <span v-if="data.column !== 'teamName' && data.column !== 'lastCap'" v-b-tooltip.hover.top="data.label">{{ data.column.toUpperCase() }}</span>
                </template>
            </b-table>
            <div v-else class="w-100 text-center">{{ $t('noData') }}</div>
        </wrapper>
        <!-- END head coach summary -->

        <!-- schools -->
        <list
            v-bind:items="personSchools"
            v-bind:title="$tc('school', 2)"
            v-bind:waitFor="apiData.personSchools"
            v-bind:waitForRetry="reloadPersonSchools"
            v-bind:hide="hideElement(5)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:permission="permissions.persons.schools.create"
                    v-b-modal.modal-assignSchool
                >
                    {{ $t('assignSchool') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <schoolRow
                    showRemove
                    isHeadline
                />
            </template>
            <template v-slot="{ item: school, role }">
                <schoolRow
                    showRemove
                    v-bind:school="school"
                    v-bind:role="role"
                    v-bind:removeHandler="removePersonSchool"
                    v-on:removed="removePersonSchoolComplete"
                />
            </template>
        </list>
        <!-- END schools -->

        <addSchoolModal
            id="modal-assignSchool"
            v-bind:title="$t('assignSchoolToPerson')"
            v-bind:confirmHandler="addSchool"
            v-on:confirmed="reloadPersonSchools"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingEditMixins } from '@/mixins/entityMapping.js';
import detailsHeader from '@/components/detailsHeader.vue';
import bar from '@/components/bar.vue';
import tiles from '@/components/tiles.vue';
import subNavigation from '@/components/subnavigation.vue';
import roleRow from '@/components/rows/role.vue';
import confirmModal from '@/components/modals/confirm.vue';
import constants from '@/constants.js';

import schoolRow from '@/components/rows/school.vue';
import addSchoolModal from '@/components/modals/addSchool.vue';

import PersonModel from '@/models/person.js';
import editPersonRoleModal from '@/components/modals/editPersonRole.vue';
import PersonRoleModel from '@/models/personRole.js';
import PlayerSummaryModel from '@/models/playerSummary.js';
import PlayerTotalSummaryModel from '@/models/playerTotalSummary.js';
import PlayerSummaryBreakdownModel from '@/models/playerSummaryBreakdown.js';
import HeadCoachSummaryModel from '@/models/headCoachSummary.js';
import PersonSchoolModel from '@/models/school.js';

export default {
    name: 'commonPersonDetail',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingEditMixins,
    ],
    components: {
        bar,
        tiles,
        detailsHeader,
        subNavigation,
        roleRow,
        addSchoolModal,
        schoolRow,
        editPersonRoleModal,
        confirmModal,
    },
    props: {
        dataPersonId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        /**
         * image width
         */
        imgWidth: {
            type: Number,
            default: 100,
        },
        /**
         * image height
         */
        imgHeight: {
            type: Number,
            default: 100,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
        undoDeletion: {
            type: Function,
            default: null,
        },
    },
    data(){
        this.apiData = {
            person: this.loadPerson(),
            newPersonRoleModel: new PersonRoleModel(),
        };

        return {
            apiData: {
                person: this.apiData.person,
                newPersonRoleModel: this.apiData.newPersonRoleModel,
                personRoles: this.loadPersonRoles(),
                playerSummary: this.loadPlayerSummary(),
                playerTotalSummary: this.loadTotalPlayerSummary(),
                playerSummaryBreakdown: this.loadPlayerSummaryBreakdown(),
                headCoachSummary: this.loadHeadCoachSummary(),
                personSchools: this.loadPersonSchools(),
            },
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$tc('role', 2),
                    },
                    2: {
                        label: this.$t('playerSummary'),
                        hidden: !this.hasPlayerRole,
                    },
                    3: {
                        label: this.$t('playerSummaryBreakdown'),
                        hidden: !this.hasPlayerRole,
                    },
                    4: {
                        label: this.$t('headCoachSummary'),
                        hidden: !this.hasManagementOfficialRole,
                    },
                    5: {
                        label: this.$tc('school', 2),
                    },
                }
            },
            person: null,
            personRoles: [],
            hasPlayerRole: false,
            hasManagementOfficialRole: false,
            personSchools: [],
            status: '',
            statusType: '',
            toBeDeletedOn: '',
            summary: {},
            playerSummary: [],
            totalSummary: {},
            playerTotalSummary: [],
            summaryBreakdown: {},
            playerSummaryBreakdown: [],
            headCoachSummary: {},
            playerHeadCoachSummary: [],
            tableFieldsPlayerSummary: [
                { key: 'teamName', stickyColumn: true, label: this.$t('teamName') },
                { key: 'm', label: this.$t('matchesPlayed') },
                { key: 'c', label: this.$t('captain') },
                { key: 'rep', label: this.$t('usedAsReplacement') },
                { key: 'try', label: this.$t('tries') },
                { key: 'con', label: this.$t('conversions') },
                { key: 'pen', label: this.$t('penalties') },
                { key: 'dg', label: this.$t('dropGoals') },
                { key: 'pts', label: this.$tc('point', 2) },
                { key: 'w', label: this.$t('wins') },
                { key: 'd', label: this.$tc('draw', 2) },
                { key: 'l', label: this.$t('loses') },
                { key: 'debut', label: this.$t('debutYear') },
                { key: 'lastCap', label: this.$t('lastCap') },
            ],
            playerMatchesSelected: 'test',
            playerMatchesOptions: [
                { value: 'test', text: this.$t('testMatches') },
                { value: 'non', text: this.$t('nonTestMatches') },
                { value: 'all', text: this.$t('allMatches') },
            ],
            tableFieldsPlayerSummaryBreakdown: [
                { key: 'tf', stickyColumn: true, label: this.$t('teamFor') },
                { key: 'ta', stickyColumn: true, label: this.$t('teamAgainst') },
                { key: 'm', label: this.$t('matchesPlayed') },
                { key: 'w', label: this.$t('wins') },
                { key: 'd', label: this.$tc('draw', 2) },
                { key: 'l', label: this.$t('loses') },
                { key: 'pf', label: this.$t('pointsFor') },
                { key: 'pa', label: this.$t('pointsAgainst') },
                { key: 'try', label: this.$t('tries') },
                { key: 'con', label: this.$t('conversions') },
                { key: 'pen', label: this.$t('penalties') },
                { key: 'dg', label: this.$t('dropGoals') },
                { key: 'pts', label: this.$tc('point', 2) },
            ],
            tableFieldsheadCoachSummary: [
                { key: 'teamName', stickyColumn: true, label: this.$t('teamName') },
                { key: 'm', label: this.$t('matchesPlayed') },
                { key: 'w', label: this.$t('wins') },
                { key: 'd', label: this.$tc('draw', 2) },
                { key: 'l', label: this.$t('loses') },
                { key: 'pf', label: this.$t('pointsFor') },
                { key: 'pa', label: this.$t('pointsAgainst') },
                { key: 'tf', label: this.$t('triesFor') },
                { key: 'ta', label: this.$t('triesAgainst') },
                { key: 'firstMatch', label: this.$t('firstMatch') },
            ],
            Promise: Promise,
        };
    },
    methods: {
        emitEvent(type, event, data){
            return this.$emit(type, Object.assign({
                person: this.person || null,
                event: event,
            }, data || {}));
        },
        editPersonLink(){
            return { name: 'commonPersonEdit', params: { dataPersonId: this.dataPersonId } };
        },
        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },

        loadPerson(){
            return this.$api.call.person.personGetById(this.dataPersonId).then(data => {
                //also load pii (personally identifiable information) from person service for passport data
                return this.$api.call.person.personIdentifiableInfoGetById(this.dataPersonId).then(pii => {
                    return Object.assign({ pii }, data);
                }, error => {
                    //ignore 404 and 403 error
                    if(error.status !== 404 && error.status !== 403){
                        throw error;
                    }

                    if(this.$log){
                        this.$log.info('No PII from Person service found', error);
                    }

                    return Object.assign({}, data);
                });
            }).then(data => {
                //also load pii (personally identifiable information) from ToP (Terms of Participation) service for biography & phonetic guide data
                return this.$api.call.termsOfParticipation.personGetByPersonEqId(data.eqId).then(topPersonData => {
                    return Object.assign({}, topPersonData, data);
                }, error => {
                    //ignore 404 and 403 error
                    if(error.status !== 404 && error.status !== 403){
                        throw error;
                    }

                    if(this.$log){
                        this.$log.info('No PII from ToP service found', error);
                    }

                    return Object.assign({}, data);
                });
            }).then(data => {
                this.person = PersonModel.from(data);

                // reload stuff that require the tournament eqid so they can finally load
                this.reloadPersonRoles();
                this.reloadPlayerSummary();
                this.reloadTotalPlayerSummary();
                this.reloadPlayerSummaryBreakdown();
                this.reloadHeadCoachSummary();
                this.status = this.getPersonStatus();
            });
        },
        reloadPerson(){
            this.apiData.person = this.loadPerson();
        },
        loadPersonRoles(){
            // request requires the person eqid, so until the person is loaded we use a promise that never resolves unless person load fails
            if (!this.person) {
                return new Promise((resolve, reject) => (this.apiData && this.apiData.person) ? this.apiData.person.catch(reject) : '');
            }

            return this.loadEntityMappingProviders(this.person).then(() => {
                this.loadNewPersonRoleModel();

                return this.$api.call.person.personRolesByPersonId(this.dataPersonId).then(result => {
                    this.personRoles = ((result || {}) || []).map(data => {
                        let personRole = new PersonRoleModel(data);
                        personRole.person = this.person;
                        return personRole;
                    });

                    if (this.personRoles) {
                        this.personRoles.forEach(personRole => {
                            if (personRole.roleType && personRole.roleType === constants.personRoleType.player) {
                                this.hasPlayerRole = true;
                            }
                            if (personRole.roleType && (personRole.roleType === constants.personRoleType.management || personRole.roleType === constants.personRoleType.official)) {
                                this.hasManagementOfficialRole = true;
                            }
                            this.loadEntityMappingProviders(personRole);
                        });
                    }
                });
            });
        },
        reloadPersonRoles(){
            this.apiData.personRoles = this.loadPersonRoles();
        },
        loadNewPersonRoleModel() {
            // NOTE: this should not be called by itself, defer to loadPersonRoles to ensure all data is loaded

            let newPersonRole = new PersonRoleModel();
            newPersonRole.person = this.person;
            newPersonRole.mappingProviders = this.person.mappingProviders;

            // populate the modal mapping inputs with the person's mappings
            newPersonRole.mappingProviders.forEach(mappingProvider => {
                this.$set(newPersonRole, mappingProvider.name.toLowerCase(), this.person[mappingProvider.name.toLowerCase()] || '');
            });

            this.apiData.newPersonRoleModel = newPersonRole;
        },
        createPersonRole(personRole) {
            let queue = Promise.resolve();

            personRole.personId = this.dataPersonId;

            if (!personRole.id) {
                queue = queue.then(() => this.$api.call.person.personRoleCreate(personRole.toJSON()));
            }

            // personRole mapping data are actually Person mappings, so this will update Person mappings
            queue = this.manageMappings(personRole, queue);

            queue = queue.then(() => {
                this.error = null;
            }).catch(error => {
                if (this.$log) {
                    this.$log.warn('create person role failed', error);
                }

                throw error;
            });

            return queue;
        },
        updatePersonRole(personRole) {
            let queue = Promise.resolve();

            queue = queue.then(() => this.$api.call.person.personRoleUpdate(personRole.id, personRole.toJSON()));

            // personRole mapping data are actually Person mappings, so this will update Person mappings
            queue = this.manageMappings(personRole, queue);

            queue = queue.then(() => {
                this.error = null;
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('person role update failed', error);
                }

                throw error;
            });

            return queue;
        },
        matchTypeSelected(){
            this.reloadTotalPlayerSummary();
            this.reloadPlayerSummary();
            this.reloadPlayerSummaryBreakdown();
        },

        loadTotalPlayerSummary(){
            //request requires the person eqid, so until the tournament is loaded we use a promise that never resolves unless person load fails
            if(!this.person){
                return new Promise((resolve, reject) => (this.apiData && this.apiData.person) ? this.apiData.person.catch(reject) : '');
            }

            return this.$api.call.match.playerTotalSummaryByPersonEqId(this.person.eqId, this.playerMatchesSelected).then(result => {
                if (result) {
                    this.totalSummary = PlayerTotalSummaryModel.from(result);

                    this.playerTotalSummary = [
                        { name: 'Conv', value: this.totalSummary.conversions },
                        { name: 'Captain', value: this.totalSummary.captain },
                        { name: 'Tries', value: this.totalSummary.tries },
                        { name: 'Drop Goals', value: this.totalSummary.dropGoals },
                        { name: 'Rep', value: this.totalSummary.yellowCards },
                        { name: 'Matches', value: this.totalSummary.matches },
                        { name: 'Points', value: this.totalSummary.pointsScored },
                        { name: 'Penalties', value: this.totalSummary.penalties },
                    ]
                }
            });
        },
        reloadTotalPlayerSummary(){
            this.apiData.playerTotalSummary = this.loadTotalPlayerSummary();
        },

        loadPlayerSummary(){
            //request requires the person eqid, so until the tournament is loaded we use a promise that never resolves unless person load fails
            if(!this.person){
                return new Promise((resolve, reject) => (this.apiData && this.apiData.person) ? this.apiData.person.catch(reject) : '');
            }

            return this.$api.call.match.playerSummaryByPersonEqId(this.person.eqId, this.playerMatchesSelected).then(result => {
                if (result) {
                    this.summary = (result || []).map(data => PlayerSummaryModel.from(data));

                    this.playerSummary = this.summary.map(element => ({
                        teamName: element.teamName,
                        m: element.matches,
                        c: element.captain,
                        rep: element.usedReplacement,
                        try: element.tries,
                        con: element.conversions,
                        pen: element.penalties,
                        dg: element.dropGoals,
                        pts: element.pointsScored,
                        w: element.won,
                        d: element.drawn,
                        l: element.lost,
                        debut: this.$fd(element.firstMatch, 'date'),
                        lastCap: this.$fd(element.lastMatch, 'date'),
                    }));
                }
            });
        },
        reloadPlayerSummary(){
            this.apiData.playerSummary = this.loadPlayerSummary();
        },

        reloadFullPlayerSummary(){
            this.reloadTotalPlayerSummary();
            this.reloadPlayerSummary();
        },

        loadPlayerSummaryBreakdown(){
            //request requires the person eqid, so until the tournament is loaded we use a promise that never resolves unless person load fails
            if(!this.person){
                return new Promise((resolve, reject) => (this.apiData && this.apiData.person) ? this.apiData.person.catch(reject) : '');
            }

            return this.$api.call.match.playerSummaryBreakdownByPersonEqId(this.person.eqId, this.playerMatchesSelected).then(result => {
                if (result) {
                    this.summaryBreakdown = (result || []).map(data => PlayerSummaryBreakdownModel.from(data));

                    this.playerSummaryBreakdown = this.summaryBreakdown.map(element => ({
                        tf: element.teamName,
                        ta: element.oppositionTeamName,
                        m: element.matches,
                        w: element.won,
                        d: element.drawn,
                        l: element.lost,
                        pf: element.pointsFor,
                        pa: element.pointsAgainst,
                        try: element.tries,
                        con: element.conversions,
                        pen: element.penalties,
                        dg: element.dropGoals,
                        pts: element.pointsScored
                    }));
                }
            });
        },
        reloadPlayerSummaryBreakdown(){
            this.apiData.playerSummaryBreakdown = this.loadPlayerSummaryBreakdown();
        },

        loadHeadCoachSummary(){
            //request requires the person eqid, so until the tournament is loaded we use a promise that never resolves unless person load fails
            if(!this.person){
                return new Promise((resolve, reject) => (this.apiData && this.apiData.person) ? this.apiData.person.catch(reject) : '');
            }

            return this.$api.call.match.headCoachSummaryByPersonEqId(this.person.eqId).then(result => {
                if (result) {
                    this.headCoachSummary = (result || []).map(data => HeadCoachSummaryModel.from(data));

                    this.playerHeadCoachSummary = this.headCoachSummary.map(element => ({
                        teamName: element.teamName,
                        m: element.matches,
                        w: element.won,
                        d: element.drawn,
                        l: element.lost,
                        pf: element.pointsFor,
                        pa: element.pointsAgainst,
                        tf: element.tries,
                        ta: element.triesAgainst,
                        firstMatch: this.$d(element.firstMatch, 'date')
                    }));
                }
            });
        },
        reloadHeadCoachSummary(){
            this.apiData.headCoachSummary = this.loadHeadCoachSummary();
        },

        loadPersonSchools(){
            return this.$api.call.person.personSchoolsByPersonId(this.dataPersonId).then(result => {
                this.personSchools = ((result || {}).schools || []).map(data => new PersonSchoolModel(data));
            });
        },
        reloadPersonSchools(){
            this.apiData.personSchools = this.loadPersonSchools();
        },
        addSchool(schoolId) {
            return this.$api.call.person.personSchoolCreate(this.dataPersonId, schoolId);
        },
        removePersonSchool(school) {
            return this.$api.call.person.personSchoolDelete(this.dataPersonId, school.id);
        },
        removePersonSchoolComplete(){
            this.reloadPersonSchools();
        },
        modalName(type, id){
            return 'modal-person-' + type + '-' + id;
        },
        getPersonStatus(){
            if (this.person.isRetired) {
                this.statusType = 'warning';
                return this.$t('retired');
            }
            if (this.person.deletionDate) {
                this.statusType = 'danger';
                this.toBeDeletedOn = this.$t('personallyIdentifiableInformation') + ' ' +
                    this.$t('willBeDeletedOn') + ' ' +
                    this.$fd(this.person.deletionDate, 'date');
                return this.$t('toBeDeleted');
            }
            if (this.person.isDeleted) {
                this.statusType = 'danger';
                this.toBeDeletedOn = this.$t('personallyIdentifiableInformation') + ' ' +
                    this.$t('hasBeenDeleted');
                return this.$t('deleted');
            }
            this.statusType = '';
            this.toBeDeletedOn = '';
            return '';
        },
        markAsDeleted() {
            return this.$api.call.person.personDeleteById(this.dataPersonId).then(result => {
                //this.$router.push({ name: 'commonPersonDetail', params: { dataPersonId: this.dataPersonId } });
                this.reloadPerson();
            });
        },
        undoDelete() {
            return this.$api.call.person.personUndoDeleteById(this.dataPersonId).then(result => {
                //this.$router.push({ name: 'commonPersonDetail', params: { dataPersonId: this.dataPersonId } });
                this.reloadPerson();
            });
        },
    },
    computed: {
        detailData(){
            if(!this.person){
                return [];
            }

            return [
                {
                    label: this.$t('dateOfBirth'),
                    content: (this.person.dateOfBirth ? this.person.dateOfBirth : null),
                    type: 'date',
                },
                {
                    label: this.$t('placeOfBirth'),
                    content: (this.person.placeOfBirth ? this.person.placeOfBirth.label() : null),
                },
                {
                    label: this.$t('currentUnion'),
                    content: this.person.getActiveUnions(),
                },
                {
                    label: this.$t('activeTeams'),
                    content: this.person.getActiveTeams(),
                },
            ];
        },
        moreDetailData(){
            if(!this.person){
                return [];
            }

            return [
                {
                    headline: this.$t('generalInformation'),
                    items: [
                        {
                            label: this.$t('gender'),
                            content: this.person.gender,
                        },
                        {
                            label: this.$t('displayName'),
                            content: this.person.displayName,
                        },
                        {
                            label: this.$t('lastName'),
                            content: this.person.lastName,
                        },
                        {
                            label: this.$t('knownAsLastname'),
                            content: this.person.knownAsLastname,
                        },
                        {
                            label: this.$t('firstnames'),
                            content: this.person.firstName,
                        },
                        {
                            label: this.$t('knownAsFirstnames'),
                            content: this.person.knownAsFirstname,
                        },
                        {
                            label: this.$t('initials'),
                            content: this.person.initials,
                        },
                        {
                            label: this.$t('maidenName'),
                            content: this.person.maidenName,
                        },
                        {
                            label: this.$t('nativeKnownAsLastname'),
                            content: this.person.nativeKnownAsLastname,
                        },
                        {
                            label: this.$t('nativeKnownAs'),
                            content: this.person.nativeKnownAsFirstname,
                        },
                        {
                            label: this.$t('dateOfBirth'),
                            content: this.person.dateOfBirth,
                            type: 'date',
                        },
                        {
                            label: this.$t('dateOfDeath'),
                            content: this.person.dateOfDeath,
                            type: 'date',
                        },
                        {
                            label: this.$t('placeOfBirth'),
                            content: (this.person.placeOfBirth ? this.person.placeOfBirth.label() : null),
                        },
                        {
                            label: this.$t('nationality'),
                            content: this.person.nationalityCountry,
                        },
                        {
                            label: this.$t('hallOfFameInducted'),
                            content: this.person.hallOfFameInducted,
                            type: 'date',
                        },
                        {
                            label: this.$t('lionsNumber'),
                            content: this.person.lionsNumber,
                        },
                        {
                            label: this.$t('prefix'),
                            content: this.person.prefix,
                        },
                        {
                            label: this.$t('suffix'),
                            content: this.person.suffix,
                        },
                        {
                            label: this.$t('emailAddress'),
                            content: this.person.emailAddress,
                        }
                    ],
                },
                {
                    headline: this.$t('passportInformation'),
                    items: [
                        {
                            label: this.$t('passportId'),
                            content: this.person.passportNo,
                        },
                        {
                            label: this.$t('expiryDate'),
                            content: this.person.passportExpiryDate,
                            type: 'date',
                        },
                        {
                            label: this.$t('issueDate'),
                            content: this.person.passportIssueDate,
                            type: 'date',
                        },
                        {
                            label: this.$t('passportScan'),
                            content: this.person.passport,
                            type: 'image',
                        },
                    ],
                },
                {
                    headline: this.$t('biographyAndPhoneticGuide'),
                    items: [
                        {
                            label: this.$t('nickname'),
                            content: this.person.nickname,
                        },
                        {
                            label: this.$t('position'),
                            content: (
                                (this.person.position && this.person.position.name ? this.person.position.name : '') ||
                                (this.person.position ? this.person.position : '') ||
                                ''
                            ),
                        },
                        {
                            label: this.$t('startPlaying'),
                            content: this.person.startPlaying,
                        },
                        {
                            label: this.$t('rugbyFirstSteps'),
                            content: this.person.rugbyFirstSteps,
                        },
                        {
                            label: this.$t('highligthsCareer'),
                            content: this.person.highlightsCareer,
                        },
                        {
                            label: this.$t('favouriteSeriesLocation'),
                            content: this.person.favouriteSeriesLocation,
                        },
                        {
                            label: this.$t('favouriteAthletes'),
                            content: this.person.favouriteAthletes,
                        },
                        {
                            label: this.$t('rugbyPlayingFamily'),
                            content: this.person.rugbyPlayingFamily,
                        },
                        {
                            label: this.$t('bestAdviceGiven'),
                            content: this.person.bestAdviceGiven,
                        },
                        {
                            label: this.$t('favouriteFood'),
                            content: this.person.favouriteFood,
                        },
                        {
                            label: this.$t('favouriteMovie'),
                            content: this.person.favouriteMovie,
                        },
                        {
                            label: this.$t('tournamentsPlayedPoints'),
                            content: this.person.tournamentsPlayedPoints,
                        },
                        {
                            label: this.$t('namePronunciation'),
                            content: this.person.namePronunciation,
                        },
                    ],
                },
                {
                    headline: this.$t('playerRelevantInformation'),
                    items: [
                        {
                            label: this.$t('height'),
                            content: this.person.height,
                        },
                        {
                            label: this.$t('weight'),
                            content: this.person.weight,
                        },
                        {
                            label: this.$t('dominantHand'),
                            content: this.person.hand,
                        },
                        {
                            label: this.$t('dominantFoot'),
                            content: this.person.foot,
                        },
                    ],
                },
            ];
        },
        playerTotalSummaryDisplay() {
            const result = [];
            this.playerSummary.forEach((playerTotalSummary, key) => {
                playerTotalSummary.total.forEach((total, i) => {
                    result.push(total);
                });
            });

            return result;
        },
        playerTotalSummaryBreakdownDisplay() {
            const result = [];
            this.playerSummaryBreakdown.forEach((playerTotalSummaryBreakdown, key) => {
                playerTotalSummaryBreakdown.total.forEach((total, i) => {
                    result.push(total);
                });
            });

            return result;
        },
    },
};
</script>
