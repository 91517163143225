import Model from '@/models/model.js';

/**
 * match timeline model class
 */
export default class MatchTimelineModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.actionTypeGroup = data.actionTypeGroup || '';
            this.actionTypeName = data.actionTypeName || '';
            this.actionTypeSubGroup = data.actionTypeSubGroup || '';
            this.actionTypeValue = data.actionTypeValue || 0;
            this.cumulativeTime = data.cumulativeTime || '';
            this.direction = data.direction || 0;
            this.endXCoordinate = data.endXCoordinate || 0;
            this.endYCoordinate = data.endYCoordinate || 0;
            this.isOutcome = data.isOutcome || false;
            this.matchClock = data.matchClock || '';
            this.matchStatus = data.matchStatus || '';
            this.matchTimelineEqId = data.matchTimelineEqId || '';
            this.metersTravelled = data.metersTravelled || 0;
            this.personEqId = data.personEqId || '';
            this.personName = data.personName || '';
            this.possession = data.possession || 0;
            this.qualifierTypes = data.qualifierTypes || [];
            this.scoring = data.scoring || [];
            this.startXCoordinate = data.startXCoordinate || 0;
            this.startYCoordinate = data.startYCoordinate || 0;
            this.teamEqId = data.teamEqId || '';
            this.teamName = data.teamName || '';
            this.time = this.formatEventTime(data.time) || '';
            this.timestamp = this.toDate(data.timestamp);
        }
    }

    formatEventTime(time){
        if (!time) {
            return '';
        }

        return String(Math.floor(time / 60)).padStart(2, '0') + ':' + String(time % 60).padStart(2, '0');
    }
}
