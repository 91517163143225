<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <b-input-group>
            <template v-slot>
                <b-form-input
                    v-model="value"
                    v-bind:id="elementId"
                    v-bind:type="config.type"
                    v-bind:required="config.required || false"
                    v-bind:placeholder="config.placeholder"
                    v-bind:plaintext="config.plaintext"
                    v-bind:readonly="config.readonly || false"
                    v-bind:disabled="config.disabled || false"
                    v-bind:maxlength="config.maxlength"
                    v-bind:state="state"
                />
            </template>
            <template v-slot:prepend v-if="config.prepend">
                <component
                    v-bind:is="config.prepend"
                    v-bind="config.prependProps"
                    v-if="config.prepend.render"
                />
                <b-input-group-text v-else>{{ config.prepend }}</b-input-group-text>
            </template>
            <template v-slot:append v-if="config.append">
                <component
                    v-bind:is="config.append"
                    v-bind="config.appendProps"
                    v-if="config.append.render"
                />
                <b-input-group-text v-else>{{ config.append }}</b-input-group-text>
            </template>
        </b-input-group>
    </b-form-group>
</template>

<script>
import base from './base.vue';

/**
 * text input element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 * @example @examples/elements/text.md
 */
export default {
    name: 'textElement',
    extends: base,
    data(){
        return {
            value: null,
        };
    },
};
</script>
