<template>
    <div v-bind:class="{ listFilter: !elementsRaw, 'mb-3': true }" v-bind:data-search="hasSearch">
        <!--<messageBlock v-bind:data-message="errorMsg" data-type="error"/>-->
        <formBlock
            v-bind:data-config="formConfig"
            v-bind:data-values="values"
            v-bind:data-model="model"
            v-bind:data-disabled="isProcessing"
            v-bind:class="formClasses"
            v-on:change="onChange"
            v-on:init="onInit"
            v-on:submit="onSubmit"
            v-on:abort="onAbort"
            data-id="dataId"
        >
            <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
                <slot v-bind:name="name" v-bind="data"/>
            </template>
        </formBlock>
    </div>
</template>

<script>
//import messageBlock from '@/components/blocks/message.vue';
import Model from '@/models/model.js';

export default {
    name: 'listFilterForm',
    components: {
        //messageBlock,
        formBlock: () => import('@/components/blocks/form.vue'),
    },
    props: {
        /**
         * form elements
         */
        elements: {
            type: Array,
            mandatory: true,
            default(){
                return [];
            }
        },
        /**
         * use element config raw instead of translating it into grid
         */
        elementsRaw: {
            type: Boolean,
            default: false,
        },
        /**
         * list of element ids to disable
         */
        disableElements: {
            type: Object,
            required: false,
            default: null,
        },
        values: {
            type: Object,
            default: null,
        },
        model: {
            type: Model,
            default: null,
        },
        dataId: {
            type: String,
            mandatory: false,
            default: '',
        },
        large: {
            type: Boolean,
            mandatory: false,
            default: false,
        },
        hasSearch: {
            type: Boolean,
            mandatory: false,
            default: false,
        }
    },
    data(){
        return {
            formValues: {},
            errorMsg: null,
            isProcessing: false,
        };
    },
    methods: {
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
        onInit(data){
            this.$emit('init', data);
        },
        onSubmit(data){
            this.$emit('submit', data);
        },
        onAbort(){
            this.$emit('abort');
        },
        disableConfigElements(elements){
            //check if there are any elements to disable
            if(!this.disableElements || !Object.keys(this.disableElements).length){
                return elements;
            }

            return elements.map(element => {
                //for rows, use recursion on the column elements
                if(element.type === 'row'){
                    return Object.assign({}, element, {
                        cols: (element.cols || []).map(col => {
                            col.elements = this.disableConfigElements(col.elements || []);
                            return col;
                        }),
                    });
                }

                //check if element id is in disable list; if so do it
                if(element.id && this.disableElements[element.id]){
                    element = Object.assign({}, element, {
                        disabled: true,
                    });
                }

                return element;
            });
        },
    },
    computed: {
        formConfig(){
            let elements = this.elements;

            //unless raw output is required, wrap elements into row
            if(!this.elementsRaw){
                elements = [
                    {
                        type: 'row',
                        props: {
                            cols: 2,
                            'cols-md': 2,
                            'cols-lg': 3,
                        },
                        cols: elements.map(element => ({
                            elements: [element],
                            dependency: element.dependency,
                            hidden: element.hidden,
                        })),
                    }
                ];
            }

            return {
                elements: this.disableConfigElements(elements),
                confirmButton: undefined,
            };
        },
        formClasses(){
            return {
                large: this.large,
            };
        },
    },
};
</script>
