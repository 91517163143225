<script>
import itemList from '@/components/itemList.vue';
import tournamentStageRow from '@/components/rows/administration/tournamentStage.vue';
import TournamentStageModel from '@/models/tournamentStage.js';

/**
 * complex tournamentStage list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'tournamentStageList',
    extends: itemList,
    components: {
        tournamentStageRow,
    },
    data(){
        return {
            //overwrites
            rowElement: tournamentStageRow,
            rowItemPropName: 'tournamentStage',
            selectItemProp: 'tournamentStage',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showEdit: true,
                showDelete: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName'),
                    label: false,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.tournament.tournamentStageSearch({
                name: filters.name || null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.data || result.items || []).map(data => new TournamentStageModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.tournament.tournamentStageDeleteById(item.id);
        },
    },
};
</script>
