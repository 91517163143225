<script>
import vselectElement from '../vselect.vue';
import SchoolModel from '@/models/country.js';

/**
 * vselect element for SchoolModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectSchoolElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                return this.$api.call.person.schoolSearch({
                    name: text,
                    paging: this.usePagination(offset),
                }).then(result => (result.schools || result.items || []).map(data => new SchoolModel(data)));
            };

            return config;
        },
    },
};
</script>
