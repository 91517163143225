import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationApiConsumerIndex from '@/views/administration/apiconsumer/index.vue';
import administrationApiConsumerCreate from '@/views/administration/apiconsumer/create.vue';
import administrationApiConsumerDetail from '@/views/administration/apiconsumer/detail.vue';

export default {
    path: 'api-consumer/',
    component: pageFrame,
    children: [
        {
            name: 'administrationApiConsumerIndex',
            path: constants.routePaths.index,
            component: administrationApiConsumerIndex,
            meta: {
                permission: permissions.apiConsumer.list,
            },
        },
        {
            name: 'administrationApiConsumerCreate',
            path: constants.routePaths.create,
            component: administrationApiConsumerCreate,
            meta: {
                permission: permissions.apiConsumer.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.apiConsumerId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationApiConsumerDetail',
                    path: constants.routePaths.index,
                    component: administrationApiConsumerDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.apiConsumerId]),
                    meta: {
                        permission: permissions.apiConsumer.update,
                    },
                },
            ],
        },
    ],
};
