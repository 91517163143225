import Model from '@/models/model.js';
import TeamModel from '@/models/team.js';
import TournamentModel from '@/models/tournament.js';
import UnionModel from '@/models/union.js';
import constants from '../constants';

/**
 * person role model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class PersonRoleModel extends Model {
    /**
     * person object
     *
     * @type {PersonModel}
     */
    person = null;

    /**
     * team object
     *
     * @type {TeamModel}
     */
    team = null;

    /**
     * roleType object
     *
     * @type {RoleModel}
     */
    roleType = null;

    /**
     * active from date
     *
     * @type {Date}
     */
    dateFrom = null;

    /**
     * active until date
     *
     * @type {Date|null}
     */
    dateUntil = null;

    /**
     * mapping providers
     *
     * @type {array}
     */
    mappingProviders = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.eqId = data.eqId || data.personRoleEqId || data.personEqId || '';
            this.id = data.id || data.personRoleId || data.roleId || 0;
            this.roleTypeId = data.id || 0;
            this.dateFrom = this.toDate(data.dateFrom);
            this.dateUntil = this.toDate(data.dateUntil);
            this.dominantFoot = data.footDexterity || data.dominantFoot || null;
            this.dominantHand = data.handDexterity || data.dominantHand || null;
            this.isRetired = data.isRetired || false;

            // this.matches = (data.matches || []).map(data => new MatchModel(data.match));
            // this.position = data.matches.position || [];
            // this.playerStatistics = data.playerStatistics || [];
            // this.citingReceived = data.citingReceived || false;
            // this.warningReceived = data.warningReceived || false;

            this.tournaments = (data.tournaments || []).map(tournament => new TournamentModel(tournament));
            this.name = data.name || data.teamName || data.team?.name || data.displayName || '';
            this.teamType = data.teamType || '';
            this.sport = data.sport || '';
            this.personId = data.personId || 0;
            this.personEqId = data.personEqId || '';
            this.height = data.playingHeight || null;
            this.weight = data.playingWeight || null;
            this.position = data.position || '';
            this.retirementDate = this.toDate(data.retirementDate);
            this.roleType = data.roleType || '';
            this.union = UnionModel.from(data.union);
            this.knownAs = data.knownAs || '';
            this.lastName = data.surname || '';
            this.dateOfBirth = this.toDate(data.dateOfBirth);
            this.teamEqId = data.teamEqId || '';
            this.teamName = data.teamName || '';
            this.countryCode = data.countryCode || '';

            this.team = TeamModel.from(data.team);
            this.teamId = data.teamId || '';
            if (!this.team && this.teamId && this.teamEqId && this.teamName) {
                this.team = new TeamModel({
                    id: this.teamId,
                    eqId: this.teamEqId,
                    name: this.teamName,
                    teamType: this.teamType || '',
                    sport: this.sport || '',
                });
            }

            this.unionName = data.unionName || '';
            this.unionEqId = data.unionEqId || '';
            if (this.union) {
                this.unionName = this.union.name || '';
                this.unionEqId = this.union.eqId || '';
            }

            //supplied via team declaration module persons
            this.imageUrl = data.imageUrl || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            dateFrom: this.fromDate(this.dateFrom),
            dateUntil: this.fromDate(this.dateUntil),
            footDexterity: this.roleType === 'Player' ? this.dominantFoot : null,
            handDexterity: this.roleType === 'Player' ? this.dominantHand : null,
            isRetired: this.isRetired,
            name: this.name,
            personId: this.personId,
            playingHeight: this.roleType === 'Player' ? this.height : 0,
            playingWeight: this.roleType === 'Player' ? this.weight : 0,
            position: this.position,
            retirementDate: this.fromDate(this.retirementDate),
            roleType: this.roleType,
            unionEqId: (this.roleType === 'Official' && this.unionEqId ? this.unionEqId : null) || null,
            teamEqId: (this.roleType !== 'Official' && this.team && this.team.eqId ? this.team.eqId : null) || null,
        };
    }

    buildMappingPayloads() {
        if (!this.mappingProviders || !this.mappingProviders.length){
            return [];
        }

        // personRole mapping data are actually Person mappings, so this will update Person mappings
        if (!this.person) {
            if (this.$log) this.$log.warn('PersonRole expects a Person to get mapping details');
            return [];
        }

        return this.mappingProviders
            .map(mappingProvider => ({
                provider: mappingProvider.name,
                entity: constants.mappingEntityNames.person,
                id: this[mappingProvider.name.toLowerCase()],
                eqId: this.person.eqId,
            }));
    }
}
