import DeclarationModel from '@/models/declaration.js';
import TeamDeclarationModel from '@/models/declaration/team.js';
import SeriesModel from '@/models/series.js';

/**
 * series declaration model class
 */
export default class SeriesDeclarationModel extends DeclarationModel {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.deadlineDate = this.toDate(data.deadlineDate);
            this.declarationWorkflowId = data.declarationWorkflowId || 0;
            this.eqId = data.eqId || '';
            this.id = data.id || 0;
            this.openingDate = this.toDate(data.openingDate);
            this.series = SeriesModel.from(data.series) || {};
            this.sport = data.sport || '';
            this.status = data.status || '';
            this.steps = data.steps || [];
            this.teams = (data.teams || []).map(data => TeamDeclarationModel.from(data));
            this.type = data.type || '';
        }
    }

    setSeries(series){
        this.series = series;
    }

    getSeries(){
        return this.series || new SeriesModel();
    }

    getTeamDeclaration(){
        return this.teamDeclaration || new TeamDeclarationModel();
    }

    toJSON(){
        const data = super.toJSON();

        data.seriesEqId = this.getSeries().eqId || null;

        return data;
    }
}
