<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="(declaration ? declaration.series.name : '-') + ' | ' + $t('seriesDeclaration')"
            v-bind:imagePath="declaration ? declaration.getSeries().logo : null"
            v-bind:details="detailData"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
        >
        </detailsHeader>

        <list
            v-bind:items="declaration ? declaration.teams : []"
            v-bind:seriesDeclarationId="dataSeriesDeclarationId"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
        >
            <template v-slot:header>
                <teamReviewRow
                    showStatus showDeadline showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: declarationTeam, role }">
                <teamReviewRow
                    showStatus showDeadline showDetail isSeries
                    v-bind:declarationTeam="declarationTeam"
                    v-bind:deadlineDate="declaration.getStepDeadlineDate()"
                    v-bind:role="role"
                />
            </template>
        </list>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import detailsHeader from '@/components/detailsHeader.vue';
import teamReviewRow from '@/components/rows/teamReview.vue';
import constants from '@/constants';

import SeriesDeclarationModel from '@/models/declaration/series.js';

export default {
    name: 'reviewMatchIndex',
    extends: base,
    components: {
        detailsHeader,
        teamReviewRow,
    },
    props: {
        dataSeriesDeclarationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                seriesDeclaration: this.loadSeriesDeclaration(),
            },
            rowProps: {
                showWorkflowStatus: false,
                showWorkflowDeadline: false,
            },
            declaration: null,
            statusOpen: 0,
            statusApproved: 0,
            statusInProgress: 0,
        };
    },
    computed: {
        detailData(){
            if(!this.declaration){
                return [];
            }

            return [
                {
                    label: this.$t('deadLine'),
                    content: this.$fd(this.declaration.getStepDeadlineDate(), 'dateTime'),
                },
                {
                    label: this.$t('timeRemaining'),
                    content: this.declaration.getStepDeadlineDate(),
                    type: 'countdown',
                },
                {
                    label: this.$t('approvedSubmissions'),
                    content: this.statusApproved,
                },
                {
                    label: this.$t('submissionsInProgress'),
                    content: this.statusInProgress,
                },
                {
                    label: this.$t('openSubmissions'),
                    content: this.statusOpen,
                },
            ];
        },
    },
    methods: {
        loadSeriesDeclaration(){
            //load declaration
            return this.$api.call.termsOfParticipation.seriesDeclarationById(this.dataSeriesDeclarationId).then(result => {
                this.declaration = new SeriesDeclarationModel(result);

                // get the number of submissions
                if (this.declaration.teams) {
                    this.declaration.teams.map(team => {
                        team.showTeamLogo = true;

                        switch (team.status) {
                            case constants.declarationStatus.open:
                                this.statusOpen++;
                                break;
                            case constants.declarationStatus.approved:
                                this.statusApproved++;
                                break;
                            case constants.declarationStatus.inProgress:
                                this.statusInProgress++;
                                break;
                        }
                    });
                }
            });
        },
        reloadSeriesDeclaration(){
            this.apiData.seriesDeclaration = this.loadSeriesDeclaration();
        },
    },
};
</script>
