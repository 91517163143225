<script>
import textElement from './text.vue';
import icon from '@/components/icon.vue';

/**
 * search input element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'searchElement',
    extends: textElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            if(!config.append){
                config.append = icon;
                config.appendProps = Object.assign({ type: 'search', color: 'primary' }, config.appendProps);
            }

            return config;
        },
    }
};
</script>
