<script>
import vselectElement from '../vselect.vue';
import PositionModel from '@/models/position.js';
import constants from '@/constants.js';

/**
 * vselect element for PositionModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectPositionElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                // players payload includes sport and isGeneric
                if(config.bySport){
                    let payload = {
                        sport: config.bySport,
                        isGeneric: config.isGeneric || false,
                    };
                    if (config.isRemoveGeneric){
                        delete payload.isGeneric;
                    }
                    return this.$api.call.person.positionSearch(payload).then(result => (result.positions || result.items || []).map(data => new PositionModel(data)))
                        .then(items => {
                            if (config.isDeclaration && config.bySport.charAt(config.bySport.length - 1) === 'S'){
                                return items.filter(position => [235, 236, 67, 237, 240, 68].includes(position.id)).sort((a, b) => a.order - b.order);
                            }
                            return items.filter(position => position.name.toLowerCase().includes(text.toLowerCase())).sort((a, b) => a.order - b.order);
                        });
                }

                // none players payload includes roleType and isMedical
                if(config.roleType && config.roleType !== constants.group.player){
                    return this.$api.call.person.positionSearch({
                        roleType: constants.personRoleType.management,
                        isMedical: config.roleType === constants.group.medical,
                    }).then(result => (result.positions || result.items || []).map(data => new PositionModel(data)))
                        .then(items => items.filter(position => position.name.toLowerCase().includes(text.toLowerCase())).sort((a, b) => a.order - b.order));
                }

                //filtering done in frontend due to api not providing the functionality
                return this.$api.call.person.positionSearch({
                    roleType: config.roleType || constants.personRoleType.management,
                    isGeneric: (config.isRemoveGeneric ? undefined : (config.isGeneric || false)),
                }).then(result => (result.positions || result.items || []).map(data => new PositionModel(data)))
                    .then(items => items.filter(position => position.name.toLowerCase().includes(text.toLowerCase())).sort((a, b) => a.order - b.order));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'name':
                        return Promise.all(initValues.map(name => this.$api.call.person.positionSearch({ name }).then(result => result?.positions?.shift())))
                            .then(results => results.map(data => new PositionModel(data)));

                    case 'eqId':
                        return Promise.all(initValues.map(eqId => this.$api.call.person.positionGetByEqId(eqId)))
                            .then(results => results.map(data => new PositionModel(data)));

                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.person.positionGetById(id)))
                            .then(results => results.map(data => new PositionModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
