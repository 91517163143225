<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="$t('tournamentTeamDetails')"
            v-bind:name="(tournamentTeam && tournamentTeam.team) ? tournamentTeam.team.name : ''"
            v-bind:nameSub="(tournamentTeam && tournamentTeam.team) ? tournamentTeam.team.sport + (tournamentTeam.team.countryName ? ', ' + tournamentTeam.team.countryName : '') : ''"
            v-bind:waitFor="apiData.tournamentTeam"
            v-bind:waitForRetry="reloadTournamentTeam"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="danger"
                    v-bind:permission="permissions.declarations.team.delete"
                    v-b-modal.confirmDeleteDeclaration
                    v-if="hasTeamDeclaration"
                >
                    {{ $t('deleteDeclaration') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:to="editTournamentTeamLink()"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <list
            v-bind:title="$t('tournamentTeamPlayers')"
            v-bind:items="this.tournamentTeam ? this.tournamentTeam.players : []"
            v-bind:waitFor="apiData.tournamentTeam"
            v-bind:waitForRetry="reloadTournamentTeam"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:header>
                <playerRow
                    showNr showDetails hideDetailsAge showPosition showRepresenting showActionsReplacementCheckbox showActionsLeftSquadCheckbox showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow
                    showNr showDetails hideDetailsAge showPosition showRepresenting showActionsReplacementCheckbox showActionsLeftSquadCheckbox showDetail
                    v-bind:person="person"
                    v-bind:role="role"
                    readonly
                />
            </template>
        </list>

        <list
            v-bind:title="$t('tournamentTeamManagement')"
            v-bind:items="this.tournamentTeam ? this.tournamentTeam.managers : []"
            v-bind:waitFor="apiData.tournamentTeam"
            v-bind:waitForRetry="reloadTournamentTeam"
            v-bind:hide="hideElement(2)"
        >
            <template v-slot:header>
                <managerRow
                    showPosition showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <managerRow
                    showPosition showDetail
                    v-bind:person="person"
                    v-bind:role="role"
                    readonly
                />
            </template>
        </list>

        <list
            v-bind:title="$t('tournamentTeamMedics')"
            v-bind:items="this.tournamentTeam ? this.tournamentTeam.medics : []"
            v-bind:waitFor="apiData.tournamentTeam"
            v-bind:waitForRetry="reloadTournamentTeam"
            v-bind:hide="hideElement(3)"
        >
            <template v-slot:header>
                <medicRow
                    showPosition showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <medicRow
                    showPosition showDetail
                    v-bind:person="person"
                    v-bind:role="role"
                    readonly
                />
            </template>
        </list>

        <list
            v-bind:title="$t('tournamentTeamKits')"
            v-bind:items="tournamentTeamKits"
            v-bind:waitFor="apiData.tournamentTeamKits"
            v-bind:waitForRetry="reloadTournamentTeamKits"
            isRow
        >
            <template v-slot="{ item: teamKit, role }">
                <teamKitRow
                    v-bind:teamKit="teamKit"
                    v-bind:role="role"
                    readonly
                />
            </template>
        </list>

        <confirmModal
            v-bind:id="'confirmDeleteDeclaration'"
            v-bind:text="$t('pleaseConfirmDeletion', { name: $t('teamDeclaration') })"
            v-bind:data="teamDeclaration"
            v-bind:confirmHandler="deleteTeamDeclarationHandler"
            v-on:confirmed="deleteTeamDeclarationComplete"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import subNavigation from '@/components/subnavigation.vue';
import detailsHeader from '@/components/detailsHeader.vue';

import playerRow from '@/components/rows/player.vue';
import managerRow from '@/components/rows/manager.vue';
import medicRow from '@/components/rows/medic.vue';
import teamKitRow from '@/components/rows/teamKit.vue';

import TournamentModel from '@/models/tournament.js';
import TournamentTeamModel from '@/models/tournamentTeam.js';
import TeamKitModel from '@/models/person/teamkit.js';
import TournamentDeclarationModel from '@/models/declaration/tournament.js';

import confirmModal from '@/components/modals/confirm.vue';

export default {
    name: 'commonTournamentTeamDetail',
    extends: base,
    components: {
        subNavigation,
        detailsHeader,
        playerRow,
        managerRow,
        medicRow,
        teamKitRow,
        confirmModal,
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataTournamentTeamId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            tournamentTeam: this.loadTournamentTeam(),
        };

        return {
            tournamentTeam: null,
            tournamentTeamKits: [],
            tournament: null,
            tournamentDeclaration: null,
            teamDeclaration: null,
            apiData: {
                tournamentTeam: this.apiData.tournamentTeam,
                tournamentTeamKits: this.loadTournamentTeamKits(),
                tournament: this.loadTournament(),
            },
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$t('tournamentTeamPlayers'),
                    },
                    2: {
                        label: this.$t('tournamentTeamManagement'),
                    },
                    3: {
                        label: this.$t('tournamentTeamMedics'),
                    },
                    4: {
                        label: this.$t('tournamentTeamKits'),
                    },
                }
            },
        };
    },
    methods: {
        loadTournamentTeam(){
            return this.$api.call.tournament.tournamentTeamGetById(this.dataTournamentTeamId).then(data => {
                if (data !== undefined) {
                    this.tournamentTeam = new TournamentTeamModel(data);

                    //make sure tournament id matches, otherwise redirect to tournament page
                    if(this.tournamentTeam.tournamentId !== this.dataTournamentId){
                        this.$router.push({ name: 'commonTournamentDetail', params: { dataTournamentId: this.dataTournamentId } });
                        return Promise.reject(Error(''));
                    }

                    //reload kits and tournament after load complete
                    this.reloadTournamentTeamKits();
                    return this.reloadTournament();
                }
            });
        },
        reloadTournamentTeam(){
            this.apiData.tournamentTeam = this.loadTournamentTeam();
        },
        loadTournamentTeamKits(){
            if(!this.tournamentTeam){
                return new Promise((resolve, reject) => this.apiData ? this.apiData.tournamentTeam.catch(reject) : '');
            }

            return this.$api.call.tournament.tournamentTeamKitsGetByTournamentTeamEqId(this.tournamentTeam.eqId).then(result => {
                this.tournamentTeamKits = (result || []).map(data => TeamKitModel.from(data));

                this.prepareTournamentTeamKits();
            });
        },
        prepareTournamentTeamKits(){
            if (this.tournamentTeamKits.length && this.tournamentTeamKits.length > 1) {
                var secondaryKitIndex = this.tournamentTeamKits.findIndex(kit => {
                    return kit.type === 'Secondary';
                });
                if (secondaryKitIndex === 0) {
                    this.tournamentTeamKits.push(this.tournamentTeamKits.shift());
                }
            }
        },
        reloadTournamentTeamKits(){
            this.apiData.tournamentTeamKits = this.loadTournamentTeamKits();
        },
        loadTournament(){
            if(!this.tournamentTeam){
                return new Promise((resolve, reject) => this.apiData ? this.apiData.tournamentTeam.catch(reject) : '');
            }

            return this.$api.call.tournament.tournamentGetById(this.tournamentTeam.tournamentId).then(data => {
                this.tournament = new TournamentModel(data);

                return this.$api.call.termsOfParticipation.tournamentDeclarationByTournamentEqId(this.tournament.eqId).catch(error => {
                    //if none found just use empty data
                    if(error.status === 404){
                        return {};
                    }
                    throw error;
                }).then(data => {
                    this.tournamentDeclaration = new TournamentDeclarationModel(data);
                    this.teamDeclaration = this.tournamentDeclaration?.teams?.find(tournamentTeam => tournamentTeam?.team?.eqId === this.tournamentTeam?.team?.eqId);

                    return this.tournament;
                });
            });
        },
        reloadTournament(){
            this.apiData.tournament = this.loadTournament();
        },
        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },
        editTournamentTeamLink(){
            return {
                name: 'commonTournamentTeamEdit',
                params: {
                    dataTournamentId: this.dataTournamentId,
                    dataTournamentTeamId: this.dataTournamentTeamId,
                }
            };
        },
        deleteTeamDeclarationHandler(data){
            return this.$api.call.termsOfParticipation.declarationTeamDeclarationDeleteById(data?.id);
        },
        deleteTeamDeclarationComplete(){
            this.teamDeclaration = null;

            this.reloadTournamentTeam();
        },
    },
    computed: {
        hasTeamDeclaration(){
            return this.teamDeclaration;
        },
    },
};
</script>
