<script>
import personRow from './person.vue';

import PersonModel from '@/models/person.js';

export default {
    name: 'playerRow',
    extends: personRow,
    props: {
        person: {
            type: PersonModel,
            default(){
                return new PersonModel();
            }
        },
    },
    data(){
        return {
            roleType: 'Player',
        };
    },
}
</script>
