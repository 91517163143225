<template>
    <div class="subNavigation mb-5 mb-md-6">
        <vuescroll v-bind:ops="ops">
            <b-tabs>
                <template v-slot:tabs-end>
                    <b-nav-item
                        v-for="(item, index) in elements"
                        v-bind:key="index"
                        v-bind:active="config.active == index"
                        v-on:click="onClick(index)"
                        role="presentation"
                    >
                        {{item.label}}
                    </b-nav-item>
                </template>
            </b-tabs>
        </vuescroll>
    </div>
</template>

<script>
import vuescroll from 'vuescroll';

/**
 * subNavigation component
 */
export default {
    name: 'subNavigation',
    components: {
        vuescroll,
    },
    props: {
        /**
         * sub-navigation configuration
         */
        dataConfig: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            ops: {
                vuescroll: {
                    mode: 'slide',
                    zooming: false,
                },
                bar: {
                    disable: true
                },
                scrollPanel: {
                    scrollingY: false
                }
            }
        };
    },
    methods: {
        onClick(index){
            this.config.active = parseInt(index);
            //this.$emit('set', value.name, value.value);
        },
    },
    computed: {
        config(){
            return this.dataConfig || {};
        },
        elements(){
            return Object.fromEntries(Object.entries(this.config.elements || {}).filter(([key, value]) => value.hidden !== true));
        },
    },
};
</script>
