import Model from '@/models/model.js';

/**
 * TournamentTypeGroup model class
 */
export default class TournamentTypeGroupModel extends Model {
    /**
     * id
     *
     * @type {number}
     */
    id = 0;

    /**
     * eqId
     *
     * @type {string}
     */
    eqId = '';

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * sport
     *
     * @type {string}
     */
    sport = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.eqId = data.eqId || data.tournamentTypeGroupEqId || '';
            this.id = data.id || data.tournamentTypeGroupId || 0;
            this.name = data.name || '';
            this.sport = data.sport || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            name: this.name,
            sport: this.sport,
        };
    }
}
