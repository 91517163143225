<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >

        <b-card-title v-bind="getColumnProps('name', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{ getHeadline('name', $t('name')) }}</label>
            <span class="text-uppercase" v-else>{{ tournamentTeamStatusTypes.name }}</span>
        </b-card-title>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from '../base.vue';

import TournamentTeamStatusTypeModel from '@/models/tournamentTeamStatusType.js';

/**
 * tournamentTeamStatusType block component
 */
export default {
    name: 'tournamentTeamStatusTypesRow',
    extends: base,
    components: {
    },
    props: {
        tournamentTeamStatusTypes: {
            type: TournamentTeamStatusTypeModel,
            default(){
                return new TournamentTeamStatusTypeModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
    },
};
</script>
