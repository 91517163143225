import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import declarationTournamentIndex from '@/views/declaration/tournament/index.vue';
import declarationTournamentDetail from '@/views/declaration/tournament/detail.vue';

export default {
    path: 'tournament/',
    component: pageFrame,
    children: [
        {
            name: 'declarationTournamentIndex',
            path: constants.routePaths.index,
            component: declarationTournamentIndex,
            meta: {
                permission: permissions.declarations.tournaments.list,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.tournamentDeclarationId),
            component: pageFrame,
            children: [
                {
                    path: routerTools.propRoute(constants.routeProps.teamDeclarationId),
                    component: pageFrame,
                    children: [
                        {
                            path: routerTools.propRoute(constants.routeProps.declarationStep),
                            component: pageFrame,
                            children: [
                                {
                                    name: 'declarationTournamentDetail',
                                    path: constants.routePaths.index,
                                    component: declarationTournamentDetail,
                                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentDeclarationId, constants.routeProps.teamDeclarationId]),
                                    meta: {
                                        permission: permissions.declarations.tournaments.detail,
                                    },
                                },
                                routerTools.createFallbackRedirect(),
                            ],
                        },
                        routerTools.createIndexRedirect(),
                    ],
                },
                routerTools.createIndexRedirect(),
            ],
        },
    ],
};
