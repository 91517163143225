<script>
import itemList from '@/components/itemList.vue';
import managementPositionRow from '@/components/rows/administration/managementPosition.vue';
import PositionModel from '@/models/position.js';
import constants from '@/constants.js';

/**
 * complex management position list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'managementPositionList',
    extends: itemList,
    components: {
        managementPositionRow,
    },
    data(){
        return {
            //overwrites
            rowElement: managementPositionRow,
            rowItemPropName: 'position',
            selectItemProp: 'position',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    name: 'name',
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName')
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load management position
            return this.$api.call.person.positionSearch({
                name: filters.name ? '%' + filters.name + '%' : null,
                roleType: constants.personRoleType.management,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.positions || result.items || []).map(data => new PositionModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.positions || result.items || []).length,
            }));
        },
    },
};
</script>
