import Model from '@/models/model.js';

/**
 * team declaration model class
 */
export default class DeclarationCommentModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.date = this.toDate(data.date);
            this.text = data.text || '';
            this.isInternal = Boolean(data.isInternal) || false;
            this.userId = data.userId || 0;
            this.userName = data.userName || data.username || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return '';
    }
}
