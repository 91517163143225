var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageContent"},[_c('detailsHeader',{attrs:{"waitFor":_vm.apiData.team,"waitForRetry":_vm.reloadTeam,"title":_vm.$t('teamDetails'),"name":_vm.team ? _vm.team.getName() : '',"imagePath":_vm.team ? _vm.team.getImgPath() : null,"details":_vm.detailData},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('actionButton',{attrs:{"variant":"secondary","to":_vm.editTeamLink(_vm.dataTeamId)}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])]},proxy:true}])}),_c('subNavigation',{attrs:{"data-config":_vm.subnavigation}}),_c('list',{attrs:{"items":_vm.teamTournaments,"title":_vm.$t('latestTournaments'),"waitFor":_vm.apiData.teamTournaments,"waitForRetry":_vm.reloadTeamTournaments,"hide":_vm.hideElement(1)},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.team)?_c('actionButton',{staticClass:"d-none d-lg-block",attrs:{"variant":"outline-primary","to":_vm.viewAllTournamentsByTeamEqId(_vm.team.eqId)}},[_vm._v(" "+_vm._s(_vm.$t('viewAllTournaments'))+" ")]):_vm._e()]},proxy:true},{key:"header",fn:function(){return [_c('tournamentRow',{attrs:{"showDetail":"","showDateRange":"","showSport":"","showEventType":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var tournament = ref.item;
var role = ref.role;
return [_c('tournamentRow',{attrs:{"showDetail":"","showDateRange":"","showSport":"","showEventType":"","tournament":tournament,"role":role}})]}}])}),_c('list',{attrs:{"items":_vm.teamMatches,"title":_vm.$t('latestMatches'),"waitFor":_vm.apiData.teamMatches,"waitForRetry":_vm.reloadTeamMatches,"hide":_vm.hideElement(2)},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.team)?_c('actionButton',{staticClass:"d-none d-lg-block",attrs:{"variant":"outline-primary","to":_vm.viewAllMatchesByTeamEqId(_vm.team.eqId)}},[_vm._v(" "+_vm._s(_vm.$t('viewAllMatches'))+" ")]):_vm._e()]},proxy:true},{key:"header",fn:function(){return [_c('matchRow',{attrs:{"showScore":"","showVenue":"","showKickOffTime":"","showDetail":"","showTeamOneLogo":false,"showTeamTwoLogo":false,"isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var match = ref.item;
var role = ref.role;
return [_c('matchRow',{attrs:{"showScore":"","showVenue":"","showKickOffTime":"","showDetail":"","showTeamOneLogo":false,"showTeamTwoLogo":false,"match":match,"role":role}})]}}])}),_c('list',{attrs:{"items":_vm.teamNameHistory,"title":_vm.$t('teamAlias'),"waitFor":_vm.apiData.teamNameHistory,"waitForRetry":_vm.reloadTeamNameHistory,"hide":_vm.hideElement(3)},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('actionButton',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-aliases-date-change",modifiers:{"modal-aliases-date-change":true}}],staticClass:"btn-flat",attrs:{"variant":"outline-primary","permission":_vm.permissions.teams.aliases.update}},[_vm._v(" "+_vm._s(_vm.$t('aliasDateChanges'))+" ")]),_c('actionButton',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-createEditTeamAlias",modifiers:{"modal-createEditTeamAlias":true}}],attrs:{"variant":"secondary","permission":_vm.permissions.teams.aliases.create}},[_vm._v(" "+_vm._s(_vm.$t('addNewAlias'))+" ")])]},proxy:true},{key:"header",fn:function(){return [_c('teamAliasRow',{attrs:{"showNameShort":"","showTeamCode":"","showDateFrom":"","showEdit":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var teamAlias = ref.item;
var role = ref.role;
return [_c('teamAliasRow',{attrs:{"showNameShort":"","showTeamCode":"","showDateFrom":"","showEdit":"","teamAlias":teamAlias,"role":role,"editPermission":_vm.permissions.teams.aliases.update,"updateHandler":_vm.updateTeamNameHistory},on:{"updated":_vm.teamNameHistoryChanged}})]}}])}),(_vm.team)?_c('teamAliasesDateChangeModal',{attrs:{"id":"modal-aliases-date-change","title":_vm.$t('teamAliasDateChange'),"team":_vm.team,"teamId":_vm.dataTeamId,"teamNameHistory":_vm.teamNameHistory,"confirmHandler":_vm.changeDateTeamNameHistory},on:{"confirmed":_vm.changeDateTeamNameHistoryComplete}}):_vm._e(),(_vm.team)?_c('editCreateTeamAliasModal',{attrs:{"id":"modal-createEditTeamAlias","title":_vm.$t('createTeamAlias'),"teamId":_vm.dataTeamId,"create":true,"confirmHandler":_vm.createTeamNameHistory},on:{"confirmed":_vm.teamNameHistoryChanged}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }