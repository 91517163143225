<script>
import formComponent from '@/components/form.vue';

const levels = {
    categories: 'SeriesAndTournament',
    matches: 'Matches',
};

/**
 * component
 */
export default {
    name: 'userRimsDisplayForm',
    extends: formComponent,
    props: {
        matches: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'accessType',
                        type: 'vselect',
                        options: [
                            {
                                id: levels.categories,
                                label: this.$t('seriesAndTournaments'),
                            },
                            {
                                id: levels.matches,
                                label: this.$tc('match'),
                            },
                        ],
                        label: this.$t('accessLevel'),
                        disabled: true,
                    },
                    {
                        id: 'allowAccessToAllSportsDisplay',
                        type: 'text',
                        label: this.$tc('sport', 2),
                        disabled: this.readonly,
                    },
                    {
                        id: 'accessToAllSeriesDisplay',
                        type: 'text',
                        label: this.$tc('series', 1),
                        disabled: this.readonly,
                    },
                    {
                        id: 'accessToAllTournamentsDisplay',
                        type: 'text',
                        label: this.$tc('tournament', 2),
                        disabled: this.readonly,
                    },
                    {
                        id: 'allowAccessToAllTournamentTypesDisplay',
                        type: 'text',
                        label: this.$tc('tournamentTypes'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'allowAccessToAllMatchesDisplay',
                        type: 'text',
                        label: this.$tc('match', 2),
                        disabled: this.readonly,
                        //dependency: 'accessLevel == 1',
                    },
                ]),
                {
                    id: 'buttonsMobile',
                    type: 'slot',
                    class: 'd-flex d-lg-none flex-wrap',
                },
                {
                    id: 'matches',
                    type: 'slot',
                    label: this.$tc('match', 2),
                    dependency: 'accessLevel == 2',
                },
            ];
        },
        initValues(){
            return Object.assign({}, this.values, {
                accessLevel: (this.matches ? levels.matches : levels.categories),
            });
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
