<script>
import vselectElement from '../vselect.vue';
import SeriesType from '@/models/seriesType.js';

/**
 * vselect element for SeriesType items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectSeriesTypeElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                //filtering done in frontend due to api not providing the functionality
                return this.$api.call.tournament.seriesTypeGetAll()
                    .then(result => (result || []).map(data => new SeriesType(data)))
                    .then(items => items.filter(seriesType => seriesType.name));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.tournament.seriesTypeGetById(id)))
                            .then(results => results.map(data => new SeriesType(data)));
                }
            };

            return config;
        },
    },
};
</script>
