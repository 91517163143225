<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('permissionGroups')"
            v-on:submit="updatePermissionGroup"
            mainTitle
            isForm
            doubleButtons
            noShadow
            noLine
            noPadding
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toPermissionGroupList"
                    v-bind:loadingWhile="apiData.permissionGroupUpdate"
                    v-if="permissionGroup"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.permissionGroupUpdate"
                    v-if="permissionGroup"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <wrapper
                    v-bind:waitFor="apiData.permissionGroup"
                    v-bind:waitForRetry="reloadPermissionGroup"
                >
                    <createEditPermissionGroupForm
                        v-bind:model="permissionGroup"
                        v-bind:errors="error ? error.getErrorIds() : undefined"
                    />
                </wrapper>
                <div
                    class="permissionSummary py-3"
                    v-if="permissionGroup"
                >
                    <ul class="validations d-flex flex-column flex-lg-row flex-wrap list-unstyled m-0 p-0 mb-3">
                        <li v-for="(item, key) in summary" v-bind:key="key">
                            <span v-if="item.permission">
                                <icon type="success"/>
                                {{ $t('permissionGrantedTo', { name: item.service }) }}
                            </span>
                            <span v-else>
                                <icon type="danger"/>
                                {{ $t('noPermissionGrantedTo', { name: item.service }) }}
                            </span>
                        </li>
                    </ul>
                </div>
                <wrapper
                    v-bind:waitFor="apiData.permissionGroup"
                    v-bind:waitForRetry="reloadPermissionGroup"
                    v-if="permissionGroup"
                >
                    <list
                        v-for="(apiService, key) in permissionGroup.services"
                        v-bind:key="key"
                        v-bind:items="apiService.resources"
                        v-bind:title="apiService.id"
                    >
                        <template v-slot:header>
                            <apiPermissionsRow
                                isHeadline
                            />
                        </template>
                        <template v-slot="{ item: apiResource, role }">
                            <apiPermissionsRow
                                v-bind:apiResource="apiResource"
                                v-bind:role="role"
                                v-on:update="updateSummary"
                            />
                        </template>
                    </list>
                </wrapper>
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import icon from '@/components/icon.vue';
import apiPermissionsRow from '@/components/rows/administration/apipermissions.vue';
import createEditPermissionGroupForm from '@/components/forms/createEditPermissionGroup.vue';

import PermissionGroupModel from '@/models/permissiongroup.js';
import ApiServiceModel from '@/models/apiService.js';
import ApiConsumerModel from '@/models/apiConsumer.js';

export default {
    name: 'administrationPermissionGroupDetail',
    extends: base,
    components: {
        validations,
        icon,
        apiPermissionsRow,
        createEditPermissionGroupForm,
    },
    props: {
        dataPermissionGroupId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                permissionGroup: this.loadPermissionGroup(),
                permissionGroupUpdate: null,
            },
            permissionGroup: null,
            error: null,
            summary: {},
        };
    },
    computed: {
        toPermissionGroupList(){
            return { name: 'administrationPermissionGroupIndex' };
        },
    },
    methods: {
        loadPermissionGroup(){
            return this.$api.call.admin.userRoleById(this.dataPermissionGroupId).then(result => {
                if (result !== undefined) {
                    const permissionGroup = new PermissionGroupModel(result, true);

                    return this.loadServices(permissionGroup);
                }
            });
        },
        reloadPermissionGroup(){
            this.apiData.permissionGroup = this.loadPermissionGroup();
        },
        loadServices(permissionGroup){
            return this.$api.call.admin.services(false)
                .then(result => Object.entries(result.services || result.items || {}).map(([key, data]) => ApiServiceModel.from(data).setId(key)))
                .then(services => {
                    //lets quickly use the methods in consumer model to merge a full list of all services, resources and methods into the active ones so nothing is missing
                    const tmp = new ApiConsumerModel({
                        services: permissionGroup.toJSON().permissions,
                    });
                    services.forEach(service => service.getResourceMethods().forEach(resourceMethod => tmp.addResourceMethod(resourceMethod)));

                    //update permission group list with updated service data
                    permissionGroup.services = tmp.services;

                    //data is ready
                    this.permissionGroup = permissionGroup;
                    this.updateSummary();
                });
        },
        updateSummary(){
            this.permissionGroup.services.forEach((apiService, index) => {
                const item = {
                    service: apiService.id,
                    permission: false
                };

                apiService.getResourceMethods().forEach(apiResourceMethod => {
                    if (apiResourceMethod.isAuthorised) {
                        item.permission = true;
                    }
                });

                this.$set(this.summary, apiService.id, item);
            });
        },
        updatePermissionGroup(){
            this.apiData.permissionGroupUpdate = this.$api.call.admin.userRoleUpdateById(this.dataPermissionGroupId, this.permissionGroup.toJSON()).then(result => {
                this.error = null;
                this.$router.push(this.toPermissionGroupList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('permissionGroup update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
};
</script>
