<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createTournamentStandingType')"
            v-on:submit="createTournamentStandingType"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentStandingTypeList"
                    v-bind:loadingWhile="apiData.tournamentStandingTypeCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentStandingTypeCreation"
                >
                    {{ $t('createTournamentStandingType') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createTournamentStandingTypeForm
                    v-bind:headline="$t('tournamentStandingTypeInformation')"
                    v-bind:model="tournamentStandingType"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import createTournamentStandingTypeForm from '@/components/forms/createTournamentStandingType.vue';

import TournamentStandingTypeModel from '@/models/tournamentStandingType.js';

export default {
    name: 'administrationTournamentStandingTypeCreate',
    extends: base,
    components: {
        validations,
        createTournamentStandingTypeForm,
    },
    data(){
        return {
            apiData: {
                tournamentStandingTypeCreation: null,
            },
            tournamentStandingType: TournamentStandingTypeModel.from({}),
            error: null,
        };
    },
    computed: {
        toTournamentStandingTypeList(){
            return { name: 'administrationTournamentStandingTypeIndex' };
        },
    },
    methods: {
        updateTournamentStandingType(values){
            this.tournamentStandingType = new TournamentStandingTypeModel(values);
        },
        createTournamentStandingType(){
            this.apiData.tournamentStandingTypeCreation = this.$api.call.tournament.tournamentStandingTypeCreate(this.tournamentStandingType.toJSON()).then(response => {
                this.error = null;
                this.goToTournamentStandingTypeDetail(response.standingTypeId);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentStandingType creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
        goToTournamentStandingTypeDetail(id){
            this.$router.push({ name: 'administrationTournamentStandingTypeDetail', params: { dataTournamentStandingTypeId: id } });
        },
    },
}
</script>
