<script>
import personList from '../person.vue';

/**
 * complex declaration player list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'declarationPersonList',
    extends: personList,
    data(){
        return {
            personIsMedic: null,
        };
    },
    computed: {
        listFilterConfig(){
            return [
                {
                    id: 'teamDeclarationId',
                    type: 'hidden',
                },
            ];
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.termsOfParticipation.declarationTeamDeclarationGetEligiblePersons(this.filters.teamDeclarationId, this.personRole, this.personIsMedic).then(result => ({
                items: this.sortItems(
                    (result || []).map(data => new this.ItemModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result || []).length,
            }));
        },
    },
};
</script>
