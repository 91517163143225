<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('tournamentStageOrder', 1)" v-bind:title-tag="titleTag" v-if="showOrder">
            <label
                v-bind:class="headlineClasses"
                v-on:click="handleHeadlineSort('tournamentStageOrder')"
                v-if="isHeadline"
            >
                    {{ getHeadline('tournamentStageOrder', $t('order')) }}
            </label>
            <span v-else>{{ tournamentStage.order }}</span>
        </b-card-title>

        <b-card-title v-bind="getColumnProps('tournamentStageName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label
                v-bind:class="headlineClasses"
                v-on:click="handleHeadlineSort('tournamentStageName')"
                v-if="isHeadline"
            >
                    {{ getHeadline('tournamentStageName', $t('name')) }}
            </label>
            <span v-else>{{ tournamentStage.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('abbreviation', 12, 6, 2)" v-if="showAbbreviation">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('abbreviation')">{{ getHeadline('abbreviation', $t('abbreviation')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ tournamentStage.abbreviation }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-bind:to="editlLink"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', tournamentStage.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', tournamentStage.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showDelete"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', tournamentStage.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: tournamentStage.name })"
            v-bind:data="tournamentStage"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />

    </b-card>
</template>

<script>
import base from './../base.vue';

import TournamentStageModel from '@/models/tournamentStage.js';

/**
 * tournamentStage block component
 */
export default {
    name: 'tournamentStageRow',
    extends: base,
    props: {
        tournamentStage: {
            type: TournamentStageModel,
            required: false,
            default(){
                return new TournamentStageModel();
            }
        },

        showOrder: {
            type: Boolean,
            default: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showAbbreviation: {
            type: Boolean,
            default: true,
        },
        showEdit: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        editlLink(){
            return {
                name: 'administrationTournamentStageEdit',
                params: Object.assign({
                    dataTournamentStageId: this.tournamentStage.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                tournamentStage: this.tournamentStage || null,
            };
        },
        hasVisibleCircles(){
            return (this.showEdit || this.showRemove || this.showDelete);
        },
    },
};
</script>
