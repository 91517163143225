<template>
    <div v-bind:class="blockClasses">
        <div
            v-for="element in elements"
            v-bind:key="element.id"
            v-show="!element.hidden && checkDependency(element.dependency)"
        >
            <component
                v-if="!isRow(element.type)"
                v-bind:is="element.type + 'Element'"
                v-bind:data-config="element"
                v-bind:data-index="element.index"
                v-bind:data-value="element.id ? values[element.id] : undefined"
                v-bind:data-all-values="values"
                v-bind:data-error="hasError(element.id)"
                v-on:change="onElementChange(element.id, ...arguments)"
                v-on:set="onElementSet(...arguments)"
            >
                <template v-if="element.id && $scopedSlots[element.id]" v-slot:default="data">
                    <!--
                    @slot All slots are passed through with all their data
                    @binding {boolean} enabled Element enable/visible state
                    -->
                    <slot v-bind:name="element.id" v-bind="data" v-bind:enabled="!element.hidden && checkDependency(element.dependency) && data.enabled !== false"/>
                </template>
            </component>

            <b-row
                v-if="isRow(element.type)"
                v-bind="element.props"
                class="form-row"
                v-show="!element.hidden && checkDependency(element.dependency)"
            >
                <b-col
                    v-for="(col, index) in (element.cols || [])"
                    v-bind="col.props"
                    v-bind:key="index"
                    v-show="!col.hidden && checkDependency(col.dependency)"
                >
                    <elementsBlock
                        v-bind:data-config="col.elements || []"
                        v-bind:data-errors="dataErrors"
                        v-bind:data-namespace="dataNamespace"
                        data-parent-values
                    >
                        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
                            <!--
                            @slot All slots are passed through with all their data
                            @binding {boolean} enabled Element enable/visible state
                            -->
                            <slot v-bind:name="name" v-bind="data" v-bind:enabled="!element.hidden && checkDependency(element.dependency) && data.enabled !== false"/>
                        </template>
                    </elementsBlock>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import base from './base.vue';
import elementComponents from '../elements/all.js';

/**
 * elements block component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'elementsBlock',
    extends: base,
    components: elementComponents,
    props: {
        /**
         * elements config
         */
        dataConfig: {
            type: Array,
            default: () => {
                return [];
            },
        },
        /**
         * initial values
         */
        dataValues: {
            type: Object,
            default: () => {
                return {};
            },
        },
        /**
         * array of element ids that will be forced into error state
         */
        dataErrors: {
            type: Array,
            default(){
                return [];
            }
        },
        /**
         * element id namespace
         */
        dataNamespace: {
            type: String,
            default: '',
        },
        /**
         * use parent component values instead of handling own values
         */
        dataParentValues: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            values: (this.dataParentValues ? this.$parent.values : Object.assign({}, this.dataValues)),
            initialized: false,
            doDependencyChecks: false,
            updatePromise: null,
        };
    },
    methods: {
        onElementChange(id, value){
            this.$set(this.values, id, value);
        },
        onElementSet(id, value){
            this.$set(this.values, id, value);
        },
        checkDependency(dependency){
            if(!dependency || !this.doDependencyChecks){
                return true;
            }

            let setValues = '';
            for(const key in this.values){
                if(Object.prototype.hasOwnProperty.call(this.values, key)){
                    setValues += 'let ' + key + ' = ' + JSON.stringify(this.values[key]) + '; ';
                }
            }

            try {
                return Boolean(eval(setValues + dependency));
            }
            catch(e){
                return false;
            }
        },
        isRow(type){
            return (type === 'row');
        },
        hasError(id){
            return (id && this.dataErrors.includes(id));
        },
    },
    computed: {
        elementId(){
            return (this.dataNamespace ? this.dataNamespace + '_' : '') + 'element';
        },
        elements(){
            return this.dataConfig || [];
        },
    },
    watch: {
        values: {
            deep: true,
            handler(){
                //wait for next tick to emit change event
                const promise = this.$nextTick();
                this.updatePromise = promise;

                promise.then(() => {
                    //but if several changes happened in quick succession, only emit change event once, on the last change
                    if(this.updatePromise === promise){
                        //if not initialized yet, emit that event first
                        if(!this.initialized){
                            /**
                             * Elements have been initialized
                             *
                             * @param {object} values Initial values
                             */
                            this.$emit('init', Object.assign({}, this.values));
                            this.initialized = true;
                        }

                        /**
                         * values changed
                         *
                         * @param {object} values New values
                         */
                        this.$emit('change', Object.assign({}, this.values));
                    }
                });
            },
        },
        dataValues: {
            deep: true,
            handler(values){
                if(this.dataParentValues){
                    return;
                }

                Object.assign(this.values, values || {});
            },
        },
    },
    created(){
        //if initialized with data, enable dependency checks from the start
        if(Object.keys(this.values).length > 0){
            this.doDependencyChecks = true;
        }

        //execute init event
        this.$nextTick(() => {
            if(!this.initialized){
                /**
                 * Elements have been initialized
                 *
                 * @param {object} values Initial values
                 */
                this.$emit('init', Object.assign({}, this.values));
                this.initialized = true;
            }
        });
    },
    updated(){
        //enable dependancy checks after initial rendering is done
        if(!this.doDependencyChecks){
            this.$nextTick(() => {
                this.doDependencyChecks = true;
            });
        }
    },
};
</script>
