<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createVenue')"
            v-bind:waitFor="apiData.mappingProviders"
            v-bind:waitForRetry="reloadMappingProviders"
            v-on:submit="createVenue"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toVenueList"
                    v-bind:loadingWhile="apiData.venueCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.venueCreation"
                >
                    {{ $t('createVenue') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || mappingWarning">
                <validations
                    v-bind:warnings="mappingWarning ? [mappingWarning] : undefined"
                    v-bind:errors="error.getErrorMessages($t('requestError'))"
                    v-if="error || mappingWarning"/>
            </template>
            <template v-slot>
                <createVenueForm
                    v-bind:headline="$t('venueInformation')"
                    v-bind:model="venue"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingCreateMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import createVenueForm from '@/components/forms/createVenue.vue';
import VenueModel from '@/models/venue.js';

export default {
    name: 'commonVenueCreate',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingCreateMixins,
    ],
    components: {
        validations,
        createVenueForm,
    },
    data(){
        return {
            apiData: {
                venueCreation: null,
            },
            venue: VenueModel.from({}),
            error: null,
            mappingWarning: null,
        };
    },
    computed: {
        toVenueList(){
            return { name: 'commonVenueIndex' };
        },
    },
    methods: {
        loadMappingProviders(){
            this.venue = VenueModel.from({});

            return this.loadEntityMappingProviders(this.venue);
        },
        createVenue(){
            let queue = Promise.resolve();

            //submit venue
            if(!this.venue.id){
                queue = queue.then(() => this.$api.call.location.venueCreate(this.venue.toJSON()).then(data => {
                    this.venue.id = data.id;
                    this.venue.eqId = data.eqId;

                    //create mappings
                    return this.createMappings(this.venue);
                }));
            }

            //map venue to mapping providers
            if(this.venue.eqId){
                queue = this.createMappingsUsingQueue(this.venue, queue);
            }

            //handle request outcome
            queue = queue.then(() => {
                this.error = null;
                this.goToVenueDetail(this.venue.id);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('venue creation failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.venueCreation = queue;
        },
        goToVenueDetail(id){
            this.$router.push({ name: 'commonVenueDetail', params: { dataVenueId: id } });
        },
    },
}
</script>
