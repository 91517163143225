<script>
import itemList from '@/components/itemList.vue';
import tournamentDeclarationRow from '@/components/rows/tournamentDeclaration.vue';
import TournamentDeclarationModel from '@/models/declaration/tournament.js';
import TeamDeclarationModel from '@/models/declaration/team.js';

/**
 * complex tournament declaration list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'declarationTournamentList',
    extends: itemList,
    components: {
        tournamentDeclarationRow,
    },
    props: {
        statuses: {
            type: Array,
            default: null,
        },
        deadlineDateOrder: {
            type: String,
            default: null,
        },
    },
    data(){
        return {
            rowElement: tournamentDeclarationRow,
            rowItemPropName: 'tournamentDeclaration',
            selectItemProp: 'tournamentDeclaration',
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByTournamentName'),
                    label: false,
                },
                {
                    id: 'sport',
                    type: 'vselectSport',
                    placeholder: this.$t('filterBySport'),
                    label: false,
                },
                {
                    id: 'status',
                    type: 'vselectWorkflowStatus',
                    placeholder: this.$t('filterByStatus'),
                },
                {
                    id: 'tournament',
                    type: 'pickTournament',
                    placeholder: this.$t('filterByTournament'),
                },
                {
                    id: 'declaration',
                    type: 'vselectTournamentDeclaration',
                    placeholder: this.$t('filterByDeclaration'),
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load series declarations
            return this.$api.call.termsOfParticipation.tournamentDeclarationSearch({
                tournamentName: filters.name || null,
                tournamentEqId: filters.tournament ? filters.tournament.eqId : null,
                statuses: this.statuses || (filters.status ? [filters.status] : null),
                sport: filters.sport || null,
                declarationStepName: filters.declaration || null,
                deadlineDateOrder: this.deadlineDateOrder || undefined,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => {
                const declarations = (result.declarations || []);

                return Promise.resolve(declarations.map(declarationData => {
                    const tmpDeclaration = new TournamentDeclarationModel(declarationData);
                    const result = [];

                    (declarationData.teams || []).forEach(teamData => {
                        let stepCount = 1;
                        const steps = tmpDeclaration.getSteps(true);
                        let lastStepStatus = null;

                        steps.forEach(step => {
                            const declaration = new TournamentDeclarationModel(declarationData);
                            const teamDeclaration = new TeamDeclarationModel(teamData);

                            declaration.setTeamDeclaration(teamDeclaration);
                            declaration.step = step.name;
                            declaration.stepStatus = step.status;
                            declaration.stepCount = stepCount;
                            declaration.stepTotalCount = steps.length;

                            declaration.getTeamDeclaration().step = declaration.step;
                            declaration.getTeamDeclaration().stepStatus = declaration.getTeamDeclaration().getStepStatus();

                            if (lastStepStatus && lastStepStatus !== 'Approved'){
                                declaration.locked = true;
                            }

                            result.push(declaration);

                            stepCount += 1;
                            lastStepStatus = declaration.getTeamDeclaration().stepStatus;
                        });
                    });

                    return result;
                })).then(results => {
                    result.declarations = results.reduce((arr, data) => arr.concat(data.filter(content => content)), []);
                    return result;
                });
            }).then(result => ({
                items: this.sortItems(
                    result.declarations || [],
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
    },
};
</script>
