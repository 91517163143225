import Model from '@/models/model.js';

/**
 * list of property names used for model property change handling
 *
 * @type {object}
 */
const propNames = {
    changedGetter: '_changedProperties',
    proxyTypeGetter: '_proxyType',
    proxyChangedStorage: '__changedProperties',
};

/**
 * model proxy creating class
 * @abstract
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class ModelProxy {
    /**
     * init proxy for model
     * @static
     *
     * @param {Model} model
     *
     * @returns {Object}
     */
    static init(model){
        if(!(model instanceof Model)){
            throw TypeError('proxy expects model');
        }

        const proxy = new Proxy(model, this.getHandler());

        //init proxy storage
        proxy[propNames.proxyChangedStorage] = {};

        return proxy;
    }

    /**
     * get handler with traps for proxy
     * @static
     *
     * @returns {object}
     */
    static getHandler(){
        return {
            get: this.handleGet,
            set: this.handleSet,
            defineProperty: this.handleDefine,
            deleteProperty: this.handleDelete,
        };
    }

    /**
     * handle model proxy property get trap
     * @static
     *
     * @param {Model}  target
     * @param {string} property
     * @param {Proxy}  receiver
     *
     * @returns {*}
     */
    static handleGet(target, property, receiver){
        switch(property){
            case propNames.proxyTypeGetter:
                return 'ModelProxy';

            case propNames.changedGetter:
                return receiver[propNames.proxyChangedStorage] || {};
        }

        return target[property];
    }

    /**
     * handle model proxy property set trap
     * @static
     *
     * @param {Model}  target
     * @param {string} property
     * @param {*}      value
     * @param {Proxy}  receiver
     *
     * @returns {boolean}
     */
    static handleSet(target, property, value, receiver){
        //if no change in value, ignore
        if(target[property] === value){
            return true;
        }

        //ignore internal/private properties for the change check
        if(property[0] !== '_'){
            //if property changed back to original value, remove from storage
            if(property in receiver[propNames.proxyChangedStorage] && receiver[propNames.proxyChangedStorage][property] === value){
                delete receiver[propNames.proxyChangedStorage][property];
            }
            //otherwise, make sure the original value is stored
            else if(!(property in receiver[propNames.proxyChangedStorage])) {
                //store original value
                receiver[propNames.proxyChangedStorage][property] = target[property];
            }
        }

        //actually set value
        target[property] = value;

        return true;
    }

    /**
     * handle model proxy property define trap
     * @static
     *
     * @param {Model}  target
     * @param {string} property
     *
     * @returns {boolean}
     */
    static handleDefine(target, property){
        return true;
    }

    /**
     * handle model proxy property delete trap
     * @static
     *
     * @param {Model}  target
     * @param {string} property
     */
    static handleDelete(target, property){
        throw Error('can not delete property of model');
    }
}
