import PersonModel from '@/models/person.js';
import PersonRoleModel from '@/models/personRole.js';
import PlayerAttributesModel from '@/models/person/playerAttributes.js';
import PlayerRepresentingModel from '@/models/person/playerRepresenting.js';

/**
 * player model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class PlayerModel extends PersonModel {
    /**
     * basic role type
     *
     * @type {string}
     */
    roleType = 'Player';

    /**
     * player number
     *
     * @type {number|null}
     */
    number = null;

    /**
     * show replacement overlay
     *
     * @type {boolean|false}
     */
    showReplacementOverlay = false;

    /**
     * replacement overlay message
     *
     * @type {string|''}
     */
    replacementOverlayMessage = '';

    /**
     * replacement overlay abort method
     *
     * @type {function|null}
     */
    replacementOverlayUndo = null;

    /**
     * replacement overlay abort promise
     *
     * @type {Promise|null}
     */
    replacementOverlayUndoPromise = null;

    /**
     * allows the declaration sport to be set for person row logic
     *
     * @type {String}
     */
    declarationSport = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);
        if(data){
            this.id = data.matchTeamPlayerId || data.id || 0;

            this.personId = data.matchTeamPersonId || data.personId || 0;
            this.tournamentTeamPersonId = data.tournamentTeamPersonId || 0;
            this.eqId = data.eqId || data.personEqId || '';
            this.matchTeamPlayerId = data.matchTeamPlayerId || (data.playerAttributes ? data.playerAttributes.id : 0) || 0;

            this.displayName = (data.personRole ? data.personRole.displayName ? data.personRole.displayName : '' : '') || data.displayName || data.personName || '';
            this.matchTeamPlayerStatistics = data.matchTeamPlayerStatistics || {};
            this.personRole = PersonRoleModel.from(data.personRole || {});

            this.signedByDoctor = data.signedByDoctor || false;
            this.replacementNotApprovedByMedic = data.replacementNotApprovedByMedic || false;
            this.isDeclarationReplacement = data.isDeclarationReplacement || false;
            this.replacedById = data.replacedById || null;
            this.isHooker = data.isHooker || false;
            this.isLoosehead = data.isLoosehead || false;
            this.isTighthead = data.isTighthead || false;
            this.hasLeftSquad = data.hasLeftSquad || false;
            this.declarationSport = data.declarationSport || '';

            if (typeof data.playerAttributes === 'undefined') {
                if (data.isCaptain) {
                    this.setPlayerAttribute('isCaptain', data.isCaptain);
                }
                if (data.shirtNumber || data.jerseyNumber) {
                    this.setPlayerAttribute('shirtNumber', data.shirtNumber || data.jerseyNumber);
                }
                if (data.isReplacement) {
                    this.setPlayerAttribute('isReplacement', data.isReplacement);
                }
                if (data.isReplaced) {
                    this.setPlayerAttribute('isReplaced', data.isReplaced);
                }
                if (data.isUsedReplacement) {
                    this.setPlayerAttribute('isUsedReplacement', data.isUsedReplacement);
                }
            }
            else {
                this.playerAttributes = PlayerAttributesModel.from(data.playerAttributes || {});
            }
            this.playerOfTheMatch = data.playerAttributes ? data.playerAttributes.isPlayerOfTheMatch : false;
            this.positionName = data.positionName || '';
            this.positionNumber = (typeof data.position !== 'object' ? data.position : null) || data.positionNumber || null;
            this.positionOrder = data.positionOrder || null;
            this.representing = ((data.representing && Array.isArray(data.representing)) ? data.representing : []).map(team => PlayerRepresentingModel.from(team));
            this.teamsRepresented = (data.teamsRepresented && Array.isArray(data.teamsRepresented)) ? data.teamsRepresented.map(team => PlayerRepresentingModel.from(team)) : null;

            this.usedInMatch = data.playerAttributes ? data.playerAttributes.isUsedReplacement : false;

            this.height = this.playerAttributes?.height || this.height || null;
            this.weight = this.playerAttributes?.weight || this.weight || null;

            if(!this.headshotStorage && this.personRole){
                this.setHeadshotStorage(this.personRole.imageUrl || '');
            }
        }
    }

    setPlayerAttribute(attribute, value){
        if(!this.playerAttributes){
            this.playerAttributes = {};
        }

        this.playerAttributes[attribute] = value;
    }

    getPlayerAttribute(attribute){
        if(!this.playerAttributes){
            return null;
        }

        return this.playerAttributes[attribute];
    }

    setHeight(height){
        this.height = parseInt(height, 10) || null;
        return this.setPlayerAttribute('height', this.height);
    }

    setWeight(weight){
        this.weight = parseInt(weight, 10) || null;
        return this.setPlayerAttribute('weight', this.weight);
    }

    setIsCaptain(isCaptain){
        return this.setPlayerAttribute('isCaptain', isCaptain || false);
    }

    setIsLoosehead(isLoosehead){
        this.isLoosehead = isLoosehead;
    }

    setIsHooker(isHooker){
        this.isHooker = isHooker;
    }

    setIsTighthead(isTighthead){
        this.isTighthead = isTighthead;
    }

    setLeftSquad(leftSquad){
        return this.setPlayerAttribute('hasPlayerLeftSquad', leftSquad || false);
    }

    setReplacement(replacement){
        return this.setPlayerAttribute('isUsedReplacement', replacement || false);
    }

    toJSON(){
        if(!this.playerAttributes){
            return super.toJSON();
        }

        const playerAttributes = {
            clubApproved: this.playerAttributes.clubApproved || null,
            hasPlayerLeftSquad: this.playerAttributes.hasPlayerLeftSquad || false,
            height: this.playerAttributes.height || null,
            isCaptain: this.playerAttributes.isCaptain || false,
            isUsedReplacement: this.playerAttributes.isUsedReplacement || false,
            squadNumber: this.playerAttributes.squadNumber || null,
            weight: this.playerAttributes.weight || null,
        };

        return Object.assign(super.toJSON(), {
            playerAttributes: playerAttributes,
        });
    }
}
