import Model from '@/models/model.js';
import TeamModel from '@/models/team.js';

export default class RankingUnionModel extends Model {
    startDate = null;

    endDate = null;

    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.teamId || data.rankingTeamId || 0;
            this.teamId = data.teamId || 0;
            this.teamEqId = data.teamEqId || '';
            this.teamName = data.teamName || '';
            this.countryIsoCode = data.countryCode || '';
            this.startDate = this.toDate(data.startDate) || null;
            this.endDate = this.toDate(data.endDate) || null;
            this.startingPoints = data.startingPoints ? this.truncate(data.startingPoints) : 0;
            this.startingMatchesPlayed = data.startingMatchesPlayed || 0;
            this.startingPosition = data.startingPosition || 0;
            this.sport = data.sport || '';
            this.comment = data.comment || '';
            this.union = data.union ? TeamModel.from(data.union) : TeamModel.from({ teamId: data.teamId, eqId: data.teamEqId, teamName: data.teamName });
        }
    }

    /**
     * truncate the starting points number
     * for some reason it comes from API with more than 10 decimal places
     */
    truncate (num, places = 3) {
        return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    }

    toJSON() {
        return {
            countryCode: this.countryIsoCode,
            startDate: this.fromDate(this.startDate),
            endDate: this.fromDate(this.endDate),
            startingPoints: this.startingPoints,
            startingPosition: this.startingPosition,
            startingMatchesPlayed: this.startingMatchesPlayed,
            comment: this.comment,
            sport: this.sport,
            teamEqId: this.union?.eqId || null,
        };
    }
}
