<script>
import itemList from '@/components/itemList.vue';
import unionRow from '@/components/rows/union.vue';
import UnionModel from '@/models/union.js';

/**
 * complex team list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'unionList',
    extends: itemList,
    components: {
        unionRow,
    },
    data(){
        return {
            //overwrites
            rowElement: unionRow,
            rowItemPropName: 'union',
            selectItemProp: 'union',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByUnions'),
                },
                {
                    id: 'unionRegion',
                    type: 'vselectUnionRegion',
                    placeholder: this.$t('filterByRegionalAssociation'),
                    valueProperty: 'name',
                    //hidden: !this.combinedRowProps.showRegionalAssociation,
                },
                {
                    id: 'country',
                    type: 'vselectCountry',
                    valueProperty: 'isoCode',
                    placeholder: this.$t('filterByCountry'),
                    label: false,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load series
            return this.$api.call.union.unionSearch({
                unionName: filters.name ? '%' + filters.name + '%' : null,
                unionRegion: filters.unionRegion || null,
                countryCode: filters.country || null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result ? (result.data || []) : []).map(data => new UnionModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.union.unionDeleteById(item.id);
        },
    },
};
</script>
