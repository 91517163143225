import Model from '@/models/model.js';

/**
 * match status model class
 */
export default class MatchStatusModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * is result
     *
     * @type {bool}
     */
    isResult = false;

    /**
     * is in play
     *
     * @type {bool}
     */
    isInPlay = false;

    /**
     * is in progress
     *
     * @type {bool}
     */
    isInProgress = false;

    /**
     * is in extra time
     *
     * @type {bool}
     */
    isInExtraTime = false;

    /**
     * order
     *
     * @type {number}
     */
    order = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.eqId = data.eqId || '';
            this.name = data.name || '';
            this.isResult = data.isResult || false;
            this.isInPlay = data.isInPlay || false;
            this.isInProgress = data.isInProgress || false;
            this.isInExtraTime = data.isInExtraTime || false;
            this.order = data.order || 0;
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
