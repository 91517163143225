<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editTeam')"
            v-bind:waitFor="apiData.team"
            v-bind:waitForRetry="reloadTeam"
            v-on:submit="updateTeam"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTeamDetail"
                    v-bind:loadingWhile="apiData.teamUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.teamUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || mappingWarning">
                <validations
                    v-bind:warnings="mappingWarning ? [mappingWarning] : undefined"
                    v-bind:errors="error.getErrorMessages($t('requestError'))"
                    v-if="error || mappingWarning"/>
            </template>
            <template v-slot>
                <editTeamForm
                    v-bind:headline="$t('teamInformation')"
                    v-bind:model="team"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingEditMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import editTeamForm from '@/components/forms/editTeam.vue';

import TeamModel from '@/models/team.js';

export default {
    name: 'commonTeamEdit',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingEditMixins,
    ],
    components: {
        validations,
        editTeamForm,
    },
    props: {
        dataTeamId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                team: this.loadTeam(),
                teamUpdate: null,
            },
            team: null,
            error: null,
            mappingWarning: null,
        };
    },
    computed: {
        toTeamDetail(){
            return { name: 'commonTeamDetail', params: { dataTeamId: this.dataTeamId } };
        },
    },
    methods: {
        loadTeam(){
            return this.$api.call.union.unionTeamGetById(this.dataTeamId).then(data => {
                this.team = TeamModel.from(data);

                //get mapping providers
                return this.loadEntityMappingProviders(this.team);
            });
        },
        reloadTeam(){
            this.apiData.team = this.loadTeam();
        },
        updateTeam(){
            let queue = Promise.resolve();

            //if logo has changed and is now a File object, upload to storage and update team logo storage information
            if(this.team._changedProperties.logo !== undefined && this.team.logo instanceof File){
                queue = queue.then(() => this.$api.call.union.storeFiles(this.team.logo)).then(storageFile => {
                    this.team.setLogoStorage(storageFile);
                }, error => {
                    //set custom file upload validation error
                    error.errors.logo = this.$t('fileUploadError');

                    throw error;
                });
            }

            //update team
            queue = queue.then(() => this.$api.call.union.unionTeamUpdateById(this.team.id, this.team.toJSON()));

            //update mappings
            queue = this.manageMappings(this.team, queue);

            //handle request outcome
            queue = queue.then(() => {
                this.error = null;
                this.$router.push(this.toTeamDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('team update failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.teamUpdate = queue;
        },
    },
}
</script>
