<script>
import vselectElement from '../vselect.vue';
import UnionRegionModel from '@/models/unionRegion.js';

/**
 * vselect element for UnionModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectUnionRegionElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return this.$api.call.union.regionGetAll()
                    .then(result => (result.unionRegions || result.items || []).map(data => new UnionRegionModel(data)))
                    .then(items => items.filter(region => region.name));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'name':
                    default:
                        return this.$api.call.union.regionGetAll()
                            .then(result => (result.unionRegions || result.items || []).map(data => new UnionRegionModel(data)))
                            .then(items => items.filter(unionRegion => initValues.includes(this.getOptionKey(unionRegion))));
                }
            };

            return config;
        },
    },
};
</script>
