<script>
import itemList from '@/components/itemList.vue';
import disciplinaryRow from '@/components/rows/disciplinary.vue';
import DisciplinaryModel from '@/models/disciplinary.js';

/**
 * complex team list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'disciplinaryList',
    extends: itemList,
    components: {
        disciplinaryRow,
    },
    props: {
        matchEqId: {
            type: String,
            default: null,
        },
        tournamentEqId: {
            type: String,
            default: null,
        },
        teamEqId: {
            type: String,
            default: null,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            //overwrites
            rowElement: disciplinaryRow,
            rowItemPropName: 'disciplinary',
            selectItemProp: 'disciplinary',
            // sortKey: 'teamName',
            // sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: this.showDetail,
                showEdit: true,
                showAccordionToggle: true,
            };
        },
        defaultHeadlineConfig(){
            return {
                teamName: {
                    sortKey: 'matchTeamName',
                },
                personName: {
                    sortKey: 'playerName',
                },
                law: {
                    sortKey: 'law',
                },
            };
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load disciplinaries
            let request;
            if(this.tournamentEqId){
                request = this.$api.call.match.matchTimelineDisciplinaryByTournamentEqId(this.tournamentEqId);
            }
            else if(this.matchEqId){
                request = this.$api.call.match.matchTimelineDisciplinaryByMatchEqId(this.matchEqId);
            }
            else {
                return Promise.reject(Error('not implemented'));
            }

            return request.then(result => ({
                items: this.sortItems(
                    (result || []).map(data => new DisciplinaryModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result || []).length,
            }));
        },
        requestItemUpdate(item){
            return this.$api.call.match.matchTimelineGetAllByMatchId(item.matchTimelineId).then(matchTimelineData => {
                return matchTimelineData?.version;
            }).then(matchTimelineVersion => {
                item.version = matchTimelineVersion ? BigInt(matchTimelineVersion).toString() : null;

                return this.$api.call.match.matchTimelineUpdateByMatchId(item.matchTimelineId, item.toTimelineJSON()).then(data => {
                    return new DisciplinaryModel(data);
                });
            }).then(matchTimeline => {
                if (item.matchTimelineDisciplinaryId){
                    return this.$api.call.match.matchTimelineDisciplinaryUpdate(item.matchTimelineDisciplinaryId, item.toDisciplinaryJSON()).then(matchTimelineDisciplinary => {
                        return matchTimelineDisciplinary?.id;
                    });
                }
                else
                {
                    return this.$api.call.match.matchTimelineDisciplinaryCreate(matchTimeline.id, item.toDisciplinaryJSON()).then(matchTimelineDisciplinary => {
                        return matchTimelineDisciplinary?.id;
                    });
                }
            }).then(matchTimelineDisciplinaryId => {
                return this.$api.call.match.matchTimelineDisciplinaryById(matchTimelineDisciplinaryId).then(matchTimelineDisciplinary => {
                    return new DisciplinaryModel(matchTimelineDisciplinary);
                });
            }).then(disciplinary => {
                this.$emit('updated', disciplinary);
            });
        },
    },
};
</script>
