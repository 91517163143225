<script>
import itemList from '@/components/itemList.vue';
import matchRow from '@/components/rows/match.vue';
import MatchModel from '@/models/match.js';
import constants from '@/constants.js';

/**
 * complex match list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'matchList',
    extends: itemList,
    components: {
        matchRow,
    },
    props: {
        teamEqId: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            //overwrites
            rowElement: matchRow,
            rowItemPropName: 'match',
            selectItemProp: 'match',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showScore: true,
                showDetail: true,
                showPublishStatus: true,
                showRemove: true,
            };
        },
        listFilterConfig(){
            return [
                this.createFilterRow([
                    {
                        id: 'dateFrom',
                        type: 'date',
                        label: this.$t('dateFrom'),
                        placeholder: constants.dateFormat,
                    },
                    {
                        id: 'dateUntil',
                        type: 'date',
                        label: this.$t('dateUntil'),
                        placeholder: constants.dateFormat,
                    },
                ]),
                this.createFilterRow([
                    {
                        id: 'team1',
                        type: 'pickTeam',
                        label: this.$tc('team', 1) + ' 1',
                    },
                    {
                        id: 'team2',
                        type: 'pickTeam',
                        label: this.$tc('team', 1) + ' 2',
                    },
                    {
                        id: 'teamType',
                        name: 'filterTeamType',
                        type: 'vselectTeamType',
                        label: this.$t('teamType'),
                        placeholder: this.$t('filterByTeamType'),
                    },
                ]),
            ];
        },
        listFilterConfigRaw(){
            return true;
        },
        listFilterMoreConfig(){
            return [
                {
                    type: 'headline',
                    text: this.$t('additionalSearchOptions'),
                },
                this.createFilterRow([
                    {
                        id: 'tournament',
                        type: 'pickTournament',
                        label: this.$tc('tournament', 1),
                    },
                    {
                        id: 'venue',
                        type: 'pickVenue',
                        label: this.$tc('venue', 1),
                    },
                    {
                        id: 'sport',
                        name: 'filterSport',
                        type: 'vselectSport',
                        label: this.$tc('sport', 1),
                        placeholder: this.$t('filterBySport'),
                    },
                ]),
            ];
        },
        listFilterMoreConfigRaw(){
            return true;
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            // selected dateUntil = + 23 hours 59 minutes 59 seconds
            if (filters.dateUntil) {
                filters.dateUntil.setHours(23, 59, 59, 999)
            }

            //load matches
            return this.$api.call.match.matchSearch({
                dateFrom: this.fromDate(filters.dateFrom) || null,
                dateUntil: this.fromDate(filters.dateUntil) || null,
                team1EqId: filters.team1 ? filters.team1.eqId : null || this.teamEqId || null,
                team2EqId: filters.team2 ? filters.team2.eqId : null,
                teamTypeId: filters.teamType || null,
                tournamentEqId: filters.tournament ? filters.tournament.eqId : null,
                venueEqId: filters.venue ? filters.venue.eqId : (this.$route.query.venue ? this.$route.query.venue : null),
                sport: filters.sport || null,
                kickoffDateDirection: 'Desc',
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result ? (result.data || []) : []).map(data => new MatchModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: result ? (result.paging ? result.paging.totalRecordCount : null) : null,
            }));
        },
        requestItemRemove(item){
            return this.$api.call.match.matchDeleteById(item.id);
        },
    },
};
</script>
