<template>
    <div v-bind:class="elementClasses"></div>
</template>

<script>
const idSeparator = '_';

/**
 * base element component
 * @abstract
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    props: {
        /**
         * element configuration
         */
        dataConfig: {
            type: Object,
            required: true,
        },
        /**
         * initial value
         */
        dataValue: {
            type: [String, Number],
            required: false,
            default: undefined,
        },
        /**
         * values of all elements in the block by their id
         */
        dataAllValues: {
            type: Object,
            required: false,
            default(){
                return {};
            },
        },
        /**
         * loop index
         */
        dataIndex: {
            type: [Number, String],
            required: false,
            default: undefined,
        },
        /**
         * force error state
         */
        dataError: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data(){
        return {
            labelPropMobile: 'label-cols',
            labelPropDesktop: 'label-cols-lg',
        };
    },
    computed: {
        config(){
            return this.dataConfig || {};
        },
        elementId(){
            return (
                ((this.$parent && this.$parent.elementId) ? this.$parent.elementId : '') +
                idSeparator +
                (this.dataIndex !== undefined ? this.dataIndex + idSeparator : '') +
                this.config.id
            );
        },
        elementClasses(){
            const classes = {};

            //include config classes
            if(this.config.class){
                let configClasses = this.config.class;

                //split into array if string
                if(typeof configClasses === 'string'){
                    configClasses = configClasses.split(' ');
                }

                //convert to object if array
                if(configClasses instanceof Array){
                    configClasses = configClasses.reduce((result, current) => {
                        result[current] = true;
                        return result;
                    }, {});
                }

                //combine if object
                if(configClasses instanceof Object){
                    Object.assign(classes, configClasses);
                }
            }

            classes.element = true;

            if(this.$options.name){
                classes[this.$options.name] = true;
            }

            if(this.customElementClasses){
                Object.assign(classes, this.customElementClasses);
            }

            return classes;
        },
        customElementClasses(){
            return null;
        },
        labelText(){
            if(!this.config || !this.config.label){
                return null;
            }

            if(this.config.required && !this.config.disabled){
                return this.$t('requiredFormFieldLabel', { label: this.config.label });
            }
            return this.config.label;
        },
        labelClasses(){
            return Object.assign({
                'd-none': (this.config.label === false),
            }, this.customLabelClasses);
        },
        customLabelClasses(){
            return {};
        },
        labelColsMobile(){
            return (this.config.labelColsMobile !== undefined ? this.config.labelColsMobile : null);
        },
        labelColsDesktop(){
            return (this.config.labelColsDesktop !== undefined ? this.config.labelColsDesktop : null);
        },
        state(){
            return (this.dataError ? false : null);
        },
    },
    methods: {
        initValue(){
            //use data-value if given
            this.value = this.dataValue;

            //if not given, use default from config
            if(this.value === undefined){
                this.value = this.config.default;

                //if not given, use defined default value
                if(this.value === undefined){
                    this.value = this.defaultValue;

                    //if not given, use empty string
                    if(this.value === undefined){
                        this.value = '';
                    }
                }
            }

            //convert value if needed
            if(typeof this.convertValueIn === 'function'){
                this.value = this.convertValueIn();
            }
        },
        getSlotName(type){
            return this.config.id + '-' + type;
        },
        fromDate(date, nullable = true){
            //detect empty input
            if(nullable && !date){
                return null;
            }

            //needs to be date
            if(!(date instanceof Date)){
                return null;
            }

            //convert to UTC datetime string
            return (new Date(date.getTime() - date.getTimezoneOffset() * 60000)).toJSON();
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value){
                //convert value if needed
                if(typeof this.convertValueOut === 'function'){
                    value = this.convertValueOut();
                }

                /**
                 * element value changed
                 *
                 * @param {*} value Updated value
                 */
                this.$emit('change', value);
            },
        },
        dataValue: {
            deep: true,
            handler(value){
                //re-init value if property changes
                this.initValue();
            },
        },
    },
    created(){
        //init value if needed
        if(this.value !== undefined){
            this.initValue();
        }
    },
};
</script>
