<script>
import vselectElement from '../vselect.vue';
import SubscriptionTypeModel from '@/models/subscriptionType.js';

/**
 * vselect element for SubscriptionType items
 */
export default {
    name: 'vselectSubscriptionTypeElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            // add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return this.$api.call.admin.subscriptionTypesGetAll()
                    .then(result => ((result || []).map(data => new SubscriptionTypeModel(data))));
            };

            return config;
        },
    },
};
</script>
