<script>
import formComponent from '@/components/form.vue';
import VenueAliasModel from '@/models/venueAlias.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'createVenueAliasForm',
    extends: formComponent,
    props: {
        model: {
            type: VenueAliasModel,
        },
        currentlyActiveAlias: {
            type: String,
            mandatory: false,
            default: null,
        },
    },
    methods: {
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'currentAlias',
                        type: 'text',
                        label: this.$t('currentlyActiveAlias'),
                        default: this.currentlyActiveAlias,
                        plaintext: this.readonly,
                        disabled: true,
                    },
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('newAlias'),
                        placeholder: this.$t('enterStadiumName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'dateFrom',
                        type: 'date',
                        label: this.$t('newAliasValidFrom'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
