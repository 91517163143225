<script>
import itemList from '@/components/itemList.vue';
import permissionGroupRow from '@/components/rows/administration/permissiongroup.vue';
import PermissionGroupModel from '@/models/permissiongroup.js';

/**
 * complex permission group list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'permissionGroupList',
    extends: itemList,
    components: {
        permissionGroupRow,
    },
    data(){
        return {
            //overwrites
            rowElement: permissionGroupRow,
            rowItemPropName: 'permissionGroup',
            selectItemProp: 'permissionGroup',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
                showRemove: true,
                showPublishingAccessLevel: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    name: 'name',
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName')
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.admin.userRoles().then(result => {
                const userRoles = [];
                (result.userRoles || result || result.items || []).forEach(element => {
                    userRoles.push(new PermissionGroupModel(element));
                });

                const items = this.filterItems(userRoles, filters);

                return {
                    items: this.sortItems(
                        items,
                        sortKey,
                        sortAsc,
                        pageIndex,
                        pageSize,
                    ),
                    total: items.length,
                };
            });
        },
        requestItemRemove(item){
            return this.$api.call.admin.userRoleDeleteById(item.id);
        },
    },
};
</script>
