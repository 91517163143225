<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <v-select
            v-bind:id="elementId"
            v-model="value"
            v-bind:multiple="config.multiple"
            v-bind:options="optionsList"
            v-bind:reduce="data => getOptionKey(data)"
            v-bind:placeholder="config.placeholder"
            v-bind:disabled="config.disabled"
            v-bind:closeOnSelect="config.closeOnSelect"
            v-bind:selectable="option => !config.disabled"
            v-bind:label="label"
            v-bind:getOptionLabel="getOptionLabel"
            v-bind:class="selectClasses"
            v-bind:filterable="!config.requestOptions"
            v-on:input="onChange"
            v-on:search="onSearch"
            v-on:search:focus="onFocus"
            v-on:open="onOpen"
            v-on:close="onClose"
            ref="vselect"
        >
            <template #search="{attributes, events}">
                <input
                    class="vs__search"
                    v-bind:required="!value && (config.required || false)"
                    v-bind="attributes"
                    v-on="events"
                />
            </template>
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="icon-down" v-if="!isLoading"/>
                <span v-else></span>
            </template>
            <template #spinner="{ loading }">
                <throbber small class="mx-1" v-if="isLoading = loading"/>
            </template>
            <template #list-footer v-if="optionsLoadInit && optionsList.length && config.requestOptions">
                <li>
                    <infinite-loading
                        v-bind:distance="0"
                        v-on:infinite="loadMore"
                    />
                </li>
            </template>
        </v-select>
    </b-form-group>
</template>

<script>
import base from './base.vue';
import vSelect from 'vue-select';
import throbber from '@/components/throbber.vue';

import { RequestError } from '@/errors.js';

/**
 * select element component
 */
export default {
    name: 'vselectElement',
    extends: base,
    components: {
        vSelect,
        throbber,
    },
    props: {
        dataValue: {
            type: [String, Number, Array, Object, Date],
        },
    },
    data(){
        return {
            value: null,
            label: 'label',
            optionsLoadInit: false,
            optionsLoadRequest: null,
            optionsLoadLimit: 20,
            searchText: '',
            searchDebounce: null,
            searchDebounceTime: 500,
            options: [],
            selectedOptions: [],
            isLoading: false,
            optionReloadListeners: {},
        };
    },
    computed: {
        selectClasses(){
            return {
                'is-invalid': (this.state === false)
            };
        },
        customElementClasses(){
            return {
                vselectElement: true,
            };
        },
        valueProperty(){
            return this.config.valueProperty || 'id';
        },
        optionsList(){
            if(this.selectedOptions.length){
                //check what the options array contains
                const optionKeys = this.options.map(option => this.getOptionKey(option));

                //add the selected options at the front if they are not in the options already
                return this.selectedOptions.filter(option => !optionKeys.includes(this.getOptionKey(option))).concat(this.options);
            }

            return this.options;
        },
    },
    methods: {
        convertValueIn(){
            if(!this.config.returnFullOptions){
                return this.value;
            }

            if(this.value instanceof Array){
                this.selectedOptions = this.value;
                return this.value.map(option => this.getOptionKey(option));
            }
            if(this.value instanceof Object){
                this.selectedOptions = [this.value];
                return this.getOptionKey(this.value);
            }
            return this.value;
        },
        convertValueOut(){
            if(!this.config.returnFullOptions){
                return this.value;
            }

            if(this.config.multiple){
                return this.selectedOptions;
            }
            return this.selectedOptions[0] || null;
        },
        getOptionLabel(option){
            if(typeof option === 'object'){
                if(option[this.label] === undefined){
                    return console.warn(
                        `[vue-select warn]: Label key "option.${this.label}" does not` +
                        ` exist in options object ${JSON.stringify(option)}.\n` +
                        'https://vue-select.org/api/props.html#getoptionlabel'
                    );
                }
                if(typeof option[this.label] === 'function'){
                    return option[this.label](this);
                }
                return option[this.label];
            }
            return option;
        },
        onChange(value){
            if(this.config.disabled){
                return;
            }

            //can be array if multiselect is on
            const values = (value instanceof Array ? value : [value]);

            //update selected options
            this.selectedOptions = this.options.filter(option => values.includes(this.getOptionKey(option)));

            if(this.config.onChange instanceof Function){
                this.config.onChange.apply(this.config.onChange, [value, this.config.value, this.config.name]);
            }

            if(!this.config.name || this.config.value === undefined){
                return;
            }

            this.$emit('set', this.config.name, this.config.value);
        },
        onSearch(text, toggleLoading){
            //stop previous debounce timer
            if(this.searchDebounce){
                window.clearTimeout(this.searchDebounce);
            }

            //start debounce timer
            this.searchDebounce = window.setTimeout(() => {
                this.options = [];
                this.searchText = text;

                this.loadOptions(text, toggleLoading);

                this.searchDebounce = null;
            }, this.searchDebounceTime);
        },
        onFocus(){
            if(this.optionsLoadInit){
                return;
            }

            //focus does not provide the loading toggle method so we access it directly, which is a bit hacky
            this.searchText = '';
            this.loadOptions('', this.$refs.vselect.toggleLoading);
        },
        onOpen(){
            this.applyRowFix(true);
        },
        onClose(){
            this.applyRowFix(false);
        },
        getOptionKey(option){
            let key = (typeof option === 'object' ? option[this.valueProperty] : option);

            while(typeof key === 'function'){
                key = key();
            }

            return this.convertOptionKey(key);
        },
        convertOptionKey(key){
            return (key instanceof Date ? this.fromDate(key) : key);
        },
        loadOptions(text, toggleLoading, initRequest = false, loadMore = false){
            if((!initRequest && !this.config.requestOptions) || (initRequest && !this.config.requestInitOptions)){
                if(this.config.options){
                    this.options = this.config.options;

                    return Promise.resolve(this.options.length);
                }

                return Promise.resolve(false);
            }

            //start loading
            toggleLoading(true);

            //request options
            const handler = initRequest ? this.config.requestInitOptions(text, this.valueProperty) : this.config.requestOptions(text, this.options.length);

            if(handler instanceof Promise){
                this.optionsLoadRequest = handler;

                return handler.then(options => {
                    //ignore old requests
                    if(this.optionsLoadRequest !== handler){
                        return null;
                    }

                    if(!options){
                        options = [];
                    }

                    if(initRequest){
                        this.selectedOptions = options;
                    }
                    else if(loadMore){
                        this.options.push.apply(this.options, options);

                        //fix to prevent dropdown scroll position from jumping
                        if(this.$refs.vselect && this.$refs.vselect.$refs.dropdownMenu){
                            const dropdownMenu = this.$refs.vselect.$refs.dropdownMenu;
                            const scrollLeft = dropdownMenu.scrollLeft;
                            const scrollTop = dropdownMenu.scrollTop;

                            this.$nextTick(() => {
                                dropdownMenu.scrollTo(scrollLeft, scrollTop);
                            });
                        }
                    }
                    else {
                        this.options = options;
                    }
                    if(!initRequest){
                        this.optionsLoadInit = true;
                    }
                    toggleLoading(false);

                    return options.length;
                }).catch(error => {
                    //ignore old requests
                    if(this.optionsLoadRequest !== handler){
                        return null;
                    }

                    if(this.$log){
                        this.$log.warn(error);
                    }

                    if(!initRequest){
                        this.optionsLoadInit = true;
                    }
                    toggleLoading(false);

                    throw error;
                });
            }
            else {
                //if method returns array, use it for the options
                if(handler instanceof Array){
                    this.options = handler;
                }

                if(!initRequest){
                    this.optionsLoadInit = true;
                }
                toggleLoading(false);

                if(handler instanceof Array){
                    return Promise.resolve(handler.length);
                }
            }

            return Promise.resolve(null);
        },
        addOptionReloadListener(watchProp, emptyValue = false){
            if(this.optionReloadListeners[watchProp]){
                return false;
            }

            this.optionReloadListeners[watchProp] = this.$watch(watchProp, () => {
                if(emptyValue){
                    if(this.config.multiple){
                        this.value = [];
                    }
                    else {
                        //use default value from config
                        this.value = this.config.default;

                        //if not given, use defined default value
                        if(this.value === undefined){
                            this.value = this.defaultValue;

                            //if not given, use empty string
                            if(this.value === undefined){
                                this.value = '';
                            }
                        }
                    }

                    this.selectedOptions = [];
                }

                this.options = [];
                this.searchText = '';

                this.loadOptions('', this.$refs.vselect.toggleLoading);
            });

            return true;
        },
        removeOptionReloadListener(watchProp){
            if(!this.optionReloadListeners[watchProp]){
                return false;
            }

            this.optionReloadListeners[watchProp]();
            delete this.optionReloadListeners[watchProp];

            return true;
        },
        loadMore(state){
            this.loadOptions(this.searchText, () => {}, false, true).catch(error => {
                //if request fails with 404 error, interpret as no (more) results found. if 403 forbidden error show error
                if(error instanceof RequestError && error.status === 404){
                    return null;
                }

                throw error;
            }).then(loaded => {
                if(loaded){
                    state.loaded();
                }
                else {
                    state.complete();
                }
            }, error => {
                state.error(error);
            });
        },
        applyRowFix(open = false){
            //z-index fix for when vselects are used in rows
            if(this.$refs.vselect && this.$refs.vselect.$el){
                const column = this.$refs.vselect.$el.closest('.entryRow.card > .card-body > .card-text');
                if(column && column.classList){
                    column.classList[open ? 'add' : 'remove']('zi-dropdown');
                }
            }
        },
        usePagination(offset = 0, pageSize = this.optionsLoadLimit){
            //api page size cannot exceed 100
            pageSize = Math.min(pageSize, 100);

            return {
                pageIndex: Math.ceil(offset / pageSize),
                pageSize: pageSize,
            };
        },
    },
    mounted(){
        //if element has initial value, check if there are initial data to load so things have proper labels
        if(this.value && this.$refs.vselect){
            const values = (this.value instanceof Array ? this.value : [this.value]);
            this.loadOptions(values, this.$refs.vselect.toggleLoading, true);
        }
    },
};
</script>
