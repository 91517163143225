var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageContent"},[_c('detailsHeader',{attrs:{"title":_vm.$t('tournamentTeamDetails'),"name":(_vm.tournamentTeam && _vm.tournamentTeam.team) ? _vm.tournamentTeam.team.name : '',"nameSub":(_vm.tournamentTeam && _vm.tournamentTeam.team) ? _vm.tournamentTeam.team.sport + (_vm.tournamentTeam.team.countryName ? ', ' + _vm.tournamentTeam.team.countryName : '') : '',"waitFor":_vm.apiData.tournamentTeam,"waitForRetry":_vm.reloadTournamentTeam},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.hasTeamDeclaration)?_c('actionButton',{directives:[{name:"b-modal",rawName:"v-b-modal.confirmDeleteDeclaration",modifiers:{"confirmDeleteDeclaration":true}}],attrs:{"variant":"danger","permission":_vm.permissions.declarations.team.delete}},[_vm._v(" "+_vm._s(_vm.$t('deleteDeclaration'))+" ")]):_vm._e(),_c('actionButton',{attrs:{"variant":"secondary","to":_vm.editTournamentTeamLink()}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])]},proxy:true}])}),_c('subNavigation',{attrs:{"data-config":_vm.subnavigation}}),_c('list',{attrs:{"title":_vm.$t('tournamentTeamPlayers'),"items":this.tournamentTeam ? this.tournamentTeam.players : [],"waitFor":_vm.apiData.tournamentTeam,"waitForRetry":_vm.reloadTournamentTeam,"hide":_vm.hideElement(1)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('playerRow',{attrs:{"showNr":"","showDetails":"","hideDetailsAge":"","showPosition":"","showRepresenting":"","showActionsReplacementCheckbox":"","showActionsLeftSquadCheckbox":"","showDetail":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('playerRow',{attrs:{"showNr":"","showDetails":"","hideDetailsAge":"","showPosition":"","showRepresenting":"","showActionsReplacementCheckbox":"","showActionsLeftSquadCheckbox":"","showDetail":"","person":person,"role":role,"readonly":""}})]}}])}),_c('list',{attrs:{"title":_vm.$t('tournamentTeamManagement'),"items":this.tournamentTeam ? this.tournamentTeam.managers : [],"waitFor":_vm.apiData.tournamentTeam,"waitForRetry":_vm.reloadTournamentTeam,"hide":_vm.hideElement(2)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('managerRow',{attrs:{"showPosition":"","showDetail":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('managerRow',{attrs:{"showPosition":"","showDetail":"","person":person,"role":role,"readonly":""}})]}}])}),_c('list',{attrs:{"title":_vm.$t('tournamentTeamMedics'),"items":this.tournamentTeam ? this.tournamentTeam.medics : [],"waitFor":_vm.apiData.tournamentTeam,"waitForRetry":_vm.reloadTournamentTeam,"hide":_vm.hideElement(3)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('medicRow',{attrs:{"showPosition":"","showDetail":"","isHeadline":""}})]},proxy:true},{key:"default",fn:function(ref){
var person = ref.item;
var role = ref.role;
return [_c('medicRow',{attrs:{"showPosition":"","showDetail":"","person":person,"role":role,"readonly":""}})]}}])}),_c('list',{attrs:{"title":_vm.$t('tournamentTeamKits'),"items":_vm.tournamentTeamKits,"waitFor":_vm.apiData.tournamentTeamKits,"waitForRetry":_vm.reloadTournamentTeamKits,"isRow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var teamKit = ref.item;
var role = ref.role;
return [_c('teamKitRow',{attrs:{"teamKit":teamKit,"role":role,"readonly":""}})]}}])}),_c('confirmModal',{attrs:{"id":'confirmDeleteDeclaration',"text":_vm.$t('pleaseConfirmDeletion', { name: _vm.$t('teamDeclaration') }),"data":_vm.teamDeclaration,"confirmHandler":_vm.deleteTeamDeclarationHandler},on:{"confirmed":_vm.deleteTeamDeclarationComplete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }