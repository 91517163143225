<script>
import itemList from '@/components/itemList.vue';
import unmappedRow from '@/components/rows/administration/unmapped.vue';
import UnmappedModel from '@/models/unmapped.js';

/**
 * complex unmapped list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'unmappedList',
    extends: itemList,
    components: {
        unmappedRow,
    },
    data(){
        return {
            //overwrites
            rowElement: unmappedRow,
            rowItemPropName: 'unmapped',
            selectItemProp: 'unmapped',
        };
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.mapping.unmappedSearch({
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.data || []).map(data => new UnmappedModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.mapping.unmappedDelete(item.provider, item.entity, item.id);
        },
    },
};
</script>
