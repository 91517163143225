<script>
import itemList from '@/components/itemList.vue';
import seriesTypeRow from '@/components/rows/seriesType.vue';
import SeriesTypeModel from '@/models/seriesType.js';

/**
 * complex team list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'seriesTypeList',
    extends: itemList,
    components: {
        seriesTypeRow,
    },
    data(){
        return {
            //overwrites
            rowElement: seriesTypeRow,
            rowItemPropName: 'seriesType',
            selectItemProp: 'seriesType',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {

            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchBySeriesTypeName')
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.tournament.seriesTypeGetAll({
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => {
                const seriesTypes = [];
                result.forEach(element => {
                    seriesTypes.push(new SeriesTypeModel(element));
                });

                const items = this.filterItems(seriesTypes, filters);

                return {
                    items: this.sortItems(
                        items,
                        sortKey,
                        sortAsc,
                        pageIndex,
                        pageSize,
                    ),
                    total: items.length,
                };
            });
        },

        requestItemRemove(item){
            return this.$api.call.tournament.seriesTypeDeleteById(item.id);
        },
    },
};
</script>
