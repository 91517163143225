<script>
import formComponent from '@/components/form.vue';
import PersonModel from '@/models/person.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'editPersonForm',
    extends: formComponent,
    props: {
        model: {
            type: PersonModel,
        },
        hideMapping: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementsConfig(){
            return [
                {
                    type: 'headline',
                    text: this.$t('personInformation'),
                },
                this.createFormRow([
                    {
                        id: 'gender',
                        type: 'radioselect',
                        selected: 'gender',
                        options: [
                            {
                                value: constants.genders.male,
                                text: this.$t('male'),
                            },
                            {
                                value: constants.genders.female,
                                text: this.$t('female'),
                            },
                        ],
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        label: this.$t('gender'),
                        required: true,
                    },
                    {
                        id: 'displayName',
                        type: 'text',
                        label: this.$t('displayName'),
                        placeholder: this.$t('enterDisplayName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'lastName',
                        type: 'text',
                        label: this.$t('surnameAsPerPassport'),
                        placeholder: this.$t('enterNameAsPerPassport'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'knownAsLastname',
                        type: 'text',
                        label: this.$t('knownAsSurname'),
                        placeholder: this.$t('enterKnownAsSurname'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'firstName',
                        type: 'text',
                        label: this.$t('forenameAsPerPassport'),
                        placeholder: this.$t('enterNameAsPerPassport'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'knownAsFirstname',
                        type: 'text',
                        label: this.$t('knownAsForename'),
                        placeholder: this.$t('enterKnownAsForename'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'initials',
                        type: 'text',
                        label: this.$t('initials'),
                        placeholder: this.$t('enterInitials'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'maidenName',
                        type: 'text',
                        label: this.$t('maidenName'),
                        placeholder: this.$t('enterMaidenName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'nativeKnownAsLastname',
                        type: 'text',
                        label: this.$t('nativeKnownAsSurname'),
                        placeholder: this.$t('enterNativeKnownAsSurname'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'nativeKnownAsFirstname',
                        type: 'text',
                        label: this.$t('nativeKnownAs'),
                        placeholder: this.$t('enterKnownAsForename'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'dateOfBirth',
                        type: 'date',
                        label: this.$t('dateOfBirthAsPerPassport'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'dateOfDeath',
                        type: 'date',
                        label: this.$t('dateOfDeath'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                    },
                    {
                        id: 'hasBeenPlayerOfFinal',
                        type: 'checkbox',
                        label: this.$t('playerOfFinal'),
                        text: this.$t('hasBeenPlayerOfFinal'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'hasBeenImpactPlayer',
                        type: 'checkbox',
                        label: this.$t('impactPlayer'),
                        text: this.$t('hasBeenImpactPlayer'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'isRetired',
                        type: 'radioselect',
                        selected: 'retired',
                        options: [
                            { value: true, text: this.$t('yes') },
                            { value: false, text: this.$t('no') },
                        ],
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        label: this.$t('retired'),
                        //required: true,
                    },
                    {
                        id: 'hallOfFameInducted',
                        type: 'date',
                        label: this.$t('hallOfFameInducted'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                    },
                    {
                        id: 'lionsNumber',
                        type: 'text',
                        label: this.$t('lionsNumber'),
                        placeholder: this.$t('enterLionsNumber'),
                        laintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'prefix',
                        type: 'text',
                        label: this.$t('prefix'),
                        placeholder: this.$t('enterPrefix'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'suffix',
                        type: 'text',
                        label: this.$t('suffix'),
                        placeholder: this.$t('enterSuffix'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'emailAddress',
                        type: 'text',
                        label: this.$t('email'),
                        placeholder: this.$t('enterEmailAddress'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
                {
                    type: 'hline',
                },
                {
                    type: 'headline',
                    text: this.$t('passportInformation'),
                },
                this.createFormRow([
                    {
                        id: 'nationalityCountryIsoCode',
                        type: 'vselectCountry',
                        modalTitle: this.$t('selectPlace'),
                        label: this.$t('nationalityAsPerPassport'),
                        valueProperty: 'isoCode',
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'placeOfBirth',
                        type: 'pickLocation',
                        modalTitle: this.$t('selectPlace'),
                        label: this.$t('placeOfBirthAsPerPassport'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'passport',
                        type: 'file',
                        label: this.$t('passportScan'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        readonly: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'passportNo',
                        type: 'text',
                        label: this.$t('passportId'),
                        placeholder: this.$t('enterPassportId'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'passportExpiryDate',
                        type: 'date',
                        label: this.$t('expiryDate'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'passportIssueDate',
                        type: 'date',
                        label: this.$t('issueDate'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
                {
                    type: 'hline',
                },
                {
                    type: 'headline',
                    text: this.$t('personHeadshot'),
                },
                this.createFormRow([
                    {
                        id: 'headshot',
                        type: 'file',
                        label: this.$t('file'),
                        accept: constants.fileAcceptance.images,
                        readonly: this.readonly,
                        disabled: this.readonly,
                    },
                ]),

                {
                    type: 'hline',
                    hidden: this.hideMapping,
                },
                {
                    id: 'mapping',
                    type: 'headline',
                    text: this.$t('mapping'),
                    hidden: this.hideMapping,
                },
                this.createFormRow(this.generateMappingProviderControls(), null, this.hideMapping),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                forenames: 'firstName',
                knownAsForenames: 'knownAsFirstname',
                knownAsSurname: 'knownAsLastname',
                passportId: 'passportNo',
                passportScanUrl: 'passport',
                placeOfBirthLocationEqId: 'placeOfBirth',
                surname: 'lastName',
            };
        },
    },
};
</script>
