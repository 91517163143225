<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('predictor')"
            v-bind:waitFor="apiData.runMatches"
            v-bind:waitForRetry="reloadRunMatches"
            v-on:submit="predict"
            doubleButtons
            noPadding
            noShadow
            noLine
            isForm
        >
            <template v-slot:buttons>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.prediction"
                    class="d-print-none"
                    v-if="runMatchesItems.length"
                >
                    {{ $t('predict') }}
                </actionButton>
            </template>
            <template v-slot>
                <b-table
                    v-bind:items="infoItems"
                    v-bind:fields="infoFields"
                    table-class="w-auto"
                    thead-class="d-none"
                    borderless
                    outlined
                />

                <b-table
                    v-bind:items="runMatchesItems"
                    v-bind:fields="runMatchesFields"
                    class="runMatchTable"
                    head-variant="light"
                    hover
                    v-if="runMatchesItems.length"
                >
                    <template #cell(score)="data">
                        <input
                            v-model="data.item.runMatch[data.item.scoreKey]"
                            v-bind:disabled="data.item.runMatch.match.isCompleted()"
                            class="my-n2"
                            type="number"
                            min="0"
                            step="1"
                        />
                    </template>
                    <template #cell(home)="data">
                        <icon v-bind:type="data.value ? 'confirm-circle-full' : 'cancel'"/>
                    </template>
                    <template #cell(result)="data">
                        <loading v-bind="tableLoadingProps">
                            {{ data.value }}
                        </loading>
                    </template>
                    <template #cell(ptsBefore)="data">
                        <loading v-bind="tableLoadingProps">
                            {{ data.value }}
                        </loading>
                    </template>
                    <template #cell(ptsAfter)="data">
                        <loading v-bind="tableLoadingProps">
                            {{ data.value }}
                        </loading>
                    </template>
                    <template #cell(eventMultiplier)="data">
                        <loading v-bind="tableLoadingProps">
                            {{ data.value }}
                        </loading>
                    </template>
                    <template #cell(winMultiplier)="data">
                        <loading v-bind="tableLoadingProps">
                            {{ data.value }}
                        </loading>
                    </template>
                </b-table>
                <div class="p-3 text-center font-weight-bold" v-else>
                    {{ $t('noPredictMatches') }}
                </div>
            </template>
        </wrapper>
        <rankingList
            v-bind:title="$t('prediction', { type: sportId === constants.rankingTypes.mens ? $t('mensRanking') : $t('womensRanking') })"
            v-bind:content="rankingDataItems"
            v-bind:rowProps="{ showTeamCode: true }"
            v-bind:waitFor="apiData.prediction"
            v-bind:waitForRetry="predict"
            v-if="apiData.prediction"
            noFilters
        />
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';
import rankingList from '@/components/lists/administration/ranking.vue';
import loading from '@/components/loading.vue';
import icon from '@/components/icon.vue';

import RankingRunModel from '@/models/rankingRun.js';
import MatchModel from '@/models/match.js';
import RankingRunMatchModel from '@/models/rankingRunMatch.js';
import RankingDataModel from '@/models/rankingData.js';

export default {
    name: 'administrationRankingPredictor',
    extends: rankingBase,
    components: {
        rankingList,
        loading,
        icon,
    },
    data(){
        return {
            apiData: {
                runMatches: this.loadRunMatches(),
                prediction: null,
            },
            runMatches: [],
            rankingDataItems: [],
            startDate: null,
            endDate: null,
        };
    },
    computed: {
        runMatchesMap(){
            const map = {};

            this.runMatches.forEach(runMatch => {
                if(runMatch.team1EqId){
                    if(!map[runMatch.team1EqId]){
                        map[runMatch.team1EqId] = [];
                    }
                    map[runMatch.team1EqId].push(runMatch);
                }
                if(runMatch.team2EqId){
                    if(!map[runMatch.team2EqId]){
                        map[runMatch.team2EqId] = [];
                    }
                    map[runMatch.team2EqId].push(runMatch);
                }
            });

            return map;
        },
        infoFields(){
            return [
                {
                    key: 'label',
                    tdClass: 'py-2',
                },
                {
                    key: 'content',
                    tdClass: 'py-2',
                },
            ];
        },
        infoItems(){
            return [
                {
                    label: this.$tc('sport', 1),
                    content: this.ucFirst(this.dataRankingType),
                },
                {
                    label: this.$t('effectiveDate'),
                    content: this.$fd(this.endDate, 'dateTimeFull'),
                },
                {
                    label: this.$t('previousWeek'),
                    content: this.$fd(this.startDate, 'dateTimeFull'),
                },
            ];
        },
        runMatchesFields(){
            return [
                {
                    key: 'date',
                    label: this.$t('date'),
                },
                {
                    key: 'team',
                    label: this.$tc('team', 1),
                },
                {
                    key: 'home',
                    label: this.$t('home'),
                },
                {
                    key: 'score',
                    label: this.$t('score'),
                },
                {
                    key: 'result',
                    label: this.$t('result'),
                },
                {
                    key: 'ptsBefore',
                    label: this.$t('ptsBefore'),
                },
                {
                    key: 'ptsAfter',
                    label: this.$t('ptsAfter'),
                },
                {
                    key: 'eventMultiplier',
                    label: this.$t('eventMultiplierAbbr'),
                },
                {
                    key: 'winMultiplier',
                    label: this.$t('winMultiplierAbbr'),
                },
            ];
        },
        runMatchesItems(){
            const items = [];

            this.runMatches.forEach(runMatch => {
                const team1Result = (this.apiData.prediction ? runMatch.team1Result : '');
                const team2Result = (this.apiData.prediction ? runMatch.team2Result : '');

                items.push({
                    runMatch,
                    date: this.$fd(runMatch.match.kickOff, 'dateTime'),
                    team: runMatch.match.getTeamOneName(),
                    home: runMatch.team1IsAtHome,
                    score: runMatch.team1Score,
                    scoreKey: 'team1Score',
                    result: team1Result,
                    ptsBefore: (team1Result ? runMatch.team1PointsBefore : ''),
                    ptsAfter: (team1Result ? runMatch.team1Points : ''),
                    eventMultiplier: (team1Result ? runMatch.matchMultiplier : ''),
                    winMultiplier: (team1Result ? runMatch.winMultiplier : ''),
                });

                items.push({
                    runMatch,
                    teamNr: 2,
                    date: runMatch.match.tournaments.map(tournament => tournament.label()).join(', '),
                    team: runMatch.match.getTeamTwoName(),
                    home: runMatch.team2IsAtHome,
                    score: runMatch.team2Score,
                    scoreKey: 'team2Score',
                    result: team2Result,
                    ptsBefore: (team2Result ? runMatch.team2PointsBefore : ''),
                    ptsAfter: (team2Result ? runMatch.team2Points : ''),
                });
            });

            return items;
        },
        tableLoadingProps(){
            return {
                waitFor: this.apiData.prediction,
                class: 'd-inline-block',
                waitSilentError: true,
                throbberProps: {
                    small: true,
                },
            };
        },
    },
    methods: {
        loadRunMatches(){
            return new Promise((resolve, reject) => {
                //need to wait one tick for computed portId prop to work
                this.$nextTick(() => {
                    this.$api.call.rankings.rankingGetLatest(this.sportId).then(result => {
                        const rankingRun = new RankingRunModel(result);

                        const startDate = new Date(rankingRun.effectiveDate);
                        startDate.setHours(12);
                        startDate.setMinutes(0);
                        startDate.setSeconds(0);

                        const endDate = new Date(startDate);
                        endDate.setDate(endDate.getDate() + 7);
                        startDate.setSeconds(1);

                        this.startDate = startDate;
                        this.endDate = endDate;

                        return this.$api.call.match.matchSearch({
                            sport: this.sportId,
                            dateFrom: rankingRun.fromDate(startDate),
                            dateUntil: rankingRun.fromDate(endDate),
                            isExcludedFromWorldRankings: false,
                            isTestForBothTeams: true,
                            paging: {
                                pageIndex: 0,
                                pageSize: 100,
                            },
                        }).then(result => ((result.data || []).map(data => new MatchModel(data))));
                    }).then(resolve, reject);
                });
            }).then(matches => {
                this.runMatches = (matches || []).map(match => new RankingRunMatchModel({ match })).filter(runMatch => runMatch.team1EqId && runMatch.team2EqId);
            });
        },
        reloadRunMatches(){
            this.apiData.runMatches = this.loadRunMatches();
        },
        predict(event){
            event.preventDefault();

            const data = {
                matches: this.runMatches.map(runMatch => runMatch.toJSON()),
            };

            this.apiData.prediction = this.$api.call.rankings.runPredictBySport(this.sportId, data).then(result => {
                (result.matches || []).map(matchData => {
                    const resultRunMatch = new RankingRunMatchModel(matchData);
                    const runMatch = this.runMatches.filter(runMatch => resultRunMatch.match.eqId && resultRunMatch.match.eqId === runMatch.match.eqId).shift();

                    if(!runMatch){
                        return;
                    }

                    runMatch.winMultiplier = resultRunMatch.winMultiplier || 0;
                    runMatch.matchMultiplier = resultRunMatch.matchMultiplier || 0;

                    runMatch.team1Result = resultRunMatch.team1Result || '';
                    runMatch.team1Points = resultRunMatch.team1Points || 0;
                    runMatch.team1PointsBefore = resultRunMatch.team1PointsBefore || 0;

                    runMatch.team2Result = resultRunMatch.team2Result || '';
                    runMatch.team2Points = resultRunMatch.team2Points || 0;
                    runMatch.team2PointsBefore = resultRunMatch.team2PointsBefore || 0;
                });

                this.rankingDataItems = ((result || {}).runData || []).map(data => new RankingDataModel(data));

                this.rankingDataItems.forEach(item => {
                    if(!item.teamEqId || !this.runMatchesMap[item.teamEqId]){
                        return;
                    }

                    item.runMatches = this.runMatchesMap[item.teamEqId];
                });
            });
        },
        ucFirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
}
</script>
