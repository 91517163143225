<script>
import vselectElement from '../vselect.vue';
import SportModel from '@/models/sport.js';

/**
 * vselect element for SportModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectSportElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                //filtering done in frontend due to api not providing the functionality
                return this.$api.call.union.getSports()
                    .then(result => (result || []).map(data => new SportModel(data)))
                    .then(items => items.filter(sport => !config.filter || config.filter.includes(this.getOptionKey(sport))))
                    .then(items => items.filter(sport =>
                        sport.name.toLowerCase().includes(text.toLowerCase()) ||
                        sport.description.toLowerCase().includes(text.toLowerCase())
                    ));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'name':
                    default:
                        return this.$api.call.union.getSports()
                            .then(result => (result || []).map(data => new SportModel(data)))
                            .then(items => items.filter(sport => initValues.includes(this.getOptionKey(sport))));
                }
            };

            return config;
        },
    },
};
</script>
