<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editTournamentStandingType')"
            v-bind:waitFor="apiData.tournamentStandingType"
            v-bind:waitForRetry="reloadTournamentStandingType"
            v-on:submit="updateTournamentStandingType"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentStandingTypeDetail"
                    v-bind:loadingWhile="apiData.tournamentStandingTypeUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentStandingTypeUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <editTournamentStandingTypeForm
                    v-bind:headline="$t('tournamentStandingTypeInformation')"
                    v-bind:model="tournamentStandingType"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import editTournamentStandingTypeForm from '@/components/forms/editTournamentStandingType.vue';

import TournamentStandingTypeModel from '@/models/tournamentStandingType.js';

export default {
    name: 'administrationTournamentStandingTypeEdit',
    extends: base,
    components: {
        validations,
        editTournamentStandingTypeForm,
    },
    props: {
        dataTournamentStandingTypeId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                tournamentStandingType: this.loadTournamentStandingType(),
                tournamentStandingTypeUpdate: null,
            },
            tournamentStandingType: null,
            error: null,
        };
    },
    computed: {
        toTournamentStandingTypeDetail(){
            return { name: 'administrationTournamentStandingTypeDetail', params: { dataTournamentStandingTypeId: this.dataTournamentStandingTypeId } };
        },
    },
    methods: {
        loadTournamentStandingType(){
            return this.$api.call.tournament.tournamentStandingTypeGetById(this.dataTournamentStandingTypeId).then(data => {
                this.tournamentStandingType = TournamentStandingTypeModel.from(data);
            });
        },
        reloadTournamentStandingType(){
            this.apiData.tournamentStandingType = this.loadTournamentStandingType();
        },
        updateTournamentStandingType(){
            this.apiData.tournamentStandingTypeUpdate = this.$api.call.tournament.tournamentStandingTypeUpdateById(
                this.dataTournamentStandingTypeId,
                this.tournamentStandingType.toJSON()
            ).then(response => {
                this.error = null;
                this.$router.push(this.toTournamentStandingTypeDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentStandingType update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
