import Log from '../log.js';
import { RequestError } from '@/errors.js';

/**
 * base model class
 */
export default class BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    baseUrl = '';

    /**
     * auth lib
     *
     * @type {Auth}
     */
    auth = null;

    /**
     * log service instance
     *
     * @type {Log}
     */
    _log;

    /**
     * base api route
     *
     * @type {string}
     */
    _apiUrl = '/';

    /**
     * class constructor
     *
     * @param {string} baseUrl
     * @param {Auth} auth
     */
    constructor(baseUrl, auth){
        this.baseUrl = baseUrl;
        this.auth = auth;
    }

    /**
     *
     */
    post(url, data, headers = null){
        return this.request('POST', url, data, false, headers);
    }

    /**
     *
     */
    get(url, urlParameters = null){
        return this.request('GET', url, urlParameters);
    }

    /**
     *
     */
    getFile(url, fileName, urlParameters = null){
        return this.requestDownload('GET', url, fileName, urlParameters);
    }

    /**
     *
     */
    put(url, data){
        return this.request('PUT', url, data);
    }

    /**
     *
     */
    patch(url, data){
        return this.request('PATCH', url, data);
    }

    /**
     *
     */
    delete(url, data){
        return this.request('DELETE', url, data);
    }

    /**
     * send request to api
     *
     * @param {string} method
     * @param {string} url
     * @param {object} [data]
     * @param {boolean} [isRetry]
     * @param {object} [headers]
     */
    request(method, url, data = {}, isRetry = false, headers = null) {
        if(!this.auth){
            return Promise.reject(ReferenceError('missing auth handler'));
        }

        // POST request using fetch with error handling
        const requestOptions = {
                method: method,
                headers: Object.assign({}, headers, {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.auth.getIdToken()}`,
                }),
            },
            partialUrl = this.buildUrl(Array.isArray(url) ? url : [url], method === 'GET' ? data : null),
            requestUrl = encodeURI(this.baseUrl) + encodeURI(this._apiUrl) + partialUrl;

        if (method !== 'GET') {
            if (url === 'storage') {
                //add files to request using FormData, but they need to be File instances
                const formData = new FormData();
                (data.files || []).filter(file => file instanceof File).forEach(file => formData.append('files', file));
                requestOptions.body = formData;

                //remove content-type, for some reason this is required for proper FormData file transfer
                delete requestOptions.headers['Content-Type'];
            }
            else {
                requestOptions.body = JSON.stringify(data);
            }
        }

        return fetch(requestUrl, requestOptions)
            .catch(this.handleFetchError)
            .then(this.status)
            .then(this.json)
            .catch(error => {
                //if failed with unauthorized error, run authorize and try again
                if(error.status === 403 && error.reason === 'InvalidAccessToken' && !isRetry && this.auth){
                    return this.auth.authorize().then(() => {
                        return this.request(method, url, data, true, headers);
                    }, () => {
                        throw error;
                    });
                }
                throw error;
            });
    }

    /**
     * send request to api
     *
     * @param {string} method
     * @param {string} url
     * @param {string} fileName
     * @param {object} [data]
     * @param {boolean} [isRetry]
     */
    requestDownload(method, url, fileName, data = {}, isRetry = false) {
        if(!this.auth){
            return Promise.reject(ReferenceError('missing auth handler'));
        }

        const requestOptions = {
                method: method,
                headers: {
                    'Content-Type': 'text/csv;charset=UTF-8',
                    Authorization: `Bearer ${this.auth.getIdToken()}`,
                },
            },
            partialUrl = this.buildUrl(Array.isArray(url) ? url : [url], method === 'GET' ? data : null),
            requestUrl = this.baseUrl + this._apiUrl + partialUrl;

        return fetch(encodeURI(requestUrl), requestOptions)
            .catch(this.handleFetchError)
            .then(this.status)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                //if failed with unauthorized error, run authorize and try again
                if(error.status === 403 && error.reason === 'InvalidAccessToken' && !isRetry && this.auth){
                    return this.auth.authorize().then(() => {
                        return this.requestDownload(method, url, fileName, data, true);
                    }, () => {
                        throw error;
                    });
                }

                throw error;
            });
    }

    /**
     *
     */
    status(response) {
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response);
        }

        return response.json().then(data => {
            return Promise.reject(new RequestError(data?.title || data?.message || data?.Message || 'unknown error message', response?.status, data?.errors || data?.Errors, data?.reason));
        }, () => {
            return Promise.reject(new RequestError('unknown error'));
        });
    }

    /**
     *
     */
    json(response) {
        //status 204 has no response body
        if(response.status === 204){
            return null;
        }

        return response.json();
    }

    handleFetchError(error){
        throw new RequestError(error.message, 500);
    }

    /**
     *
     */
    buildUrl(array, data = null){
        let url = array.map(chunk => encodeURIComponent(chunk)).join('/');

        const params = Object.entries(data || {}).filter(([key, value]) => value !== undefined);
        if(params.length) {
            url += '?' + params.map(([key, value]) => {
                if(Array.isArray(value)){
                    return value.map(value => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&');
                }

                return encodeURIComponent(key) + '=' + encodeURIComponent(value);
            }).join('&');
        }

        return url;
    }

    /**
     * helper to resolve storage paths, to be inherited by all services
     *
     * @param {string} filePath
     *
     * @returns {string}
     */
    resolveStoragePath(filePath){
        if(!this.baseUrl || !this._apiUrl || !filePath){
            return '';
        }

        //check if external url
        try {
            const url = new URL(filePath);
            if(['http:', 'https:'].includes(url.protocol)){
                //seems to be external url
                return encodeURI(url.href);
            }
        }
        catch(error){
            //must be storage path
        }

        //resolve storage path
        return encodeURI(this.baseUrl + this._apiUrl + this.buildUrl(['storage', 'url']) + '/' + filePath + '?token=' + this.auth.getIdToken());
    }

    /**
     * helper to resolve paths, to be inherited by all services
     *
     * @param {string} filePath
     *
     * @returns {string}
     */
    resolvePath(filePath){
        if(!this.baseUrl || !this._apiUrl || !filePath){
            return '';
        }

        //check if external url
        try {
            const url = new URL(filePath);
            if(['http:', 'https:'].includes(url.protocol)){
                //seems to be external url
                return encodeURI(url.href);
            }
        }
        catch(error){
            //must be storage path
        }

        //resolve storage path
        return decodeURIComponent(this.baseUrl + this._apiUrl + this.buildUrl(Array.isArray(filePath) ? filePath : [filePath]) + '?token=' + this.auth.getIdToken());
    }

    /**
     * upload file to storage
     *
     * @param {File} file
     *
     * @returns {Promise<string>}
     */
    storeFile(file){
        return this.storeFiles(file);
    }

    /**
     * upload file(s) to storage
     *
     * @param {File|File[]} files
     *
     * @returns {Promise<string | string[]>}
     */
    storeFiles(files){
        const single = !(files instanceof Array);
        const simpleFileSizeLimit = 10 * 1024 * 1024; //need to use presigned upload if files total over 10mb
        files = (single ? [files] : files);

        //use simple upload if upload is below the file size limit
        if(files.reduce((sum, file) => sum + file.size, 0) < simpleFileSizeLimit){
            return this.post('storage', {
                files,
            }).then(results => single ? (results || []).shift() : (results || []));
        }

        //for each file: request presigned url and then upload the file to it
        return Promise.all(files.map(file => this.put(['storage', 'presigned-url'], {
            filename: file.name,
        }).then(result => {
            if(!result){
                throw new RequestError('unknown error');
            }

            const uploadUrl = result.url;
            const filePath = result.path;

            if(!uploadUrl || !filePath){
                throw new RequestError('unknown error');
            }

            const requestOptions = {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                },
            };

            //upload file
            return fetch(uploadUrl, requestOptions)
                .catch(this.handleFetchError)
                .then(this.status)
                .then(() => filePath);
        }))).then(results => single ? (results || []).shift() : (results || []));
    }

    /**
     * set log service instance
     *
     * @throws TypeError
     *
     * @param {Log} log
     */
    setLog(log){
        if(!(log instanceof Log)){
            throw TypeError('unable to set model log property: invalid argument');
        }

        this._log = log;
    }
}
