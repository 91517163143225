import Model from '@/models/model.js';
import PlayerModel from '@/models/person/player.js';
import ManagerModel from '@/models/person/manager.js';
import MedicModel from '@/models/person/medic.js';
import MatchModel from '@/models/match.js';
import constants from '../constants';

/**
 * team model class
 */
export default class TeamModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * teamName
     *
     * @type {string}
     */
    teamName = '';

    /**
     * position
     *
     * @type {string}
     */
    position = '';

    /**
     * points
     *
     * @type {int}
     */
    points = 0;

    /**
     * sup group
     *
     * @type {bool}
     */
    isTourMember = false;

    /**
     * players
     *
     * @type {array}
     */
    players = [];

    /**
     * management
     *
     * @type {array}
     */
    management = [];

    /**
     * mapping providers
     *
     * @type {array}
     */
    mappingProviders = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.countryIsoCode = data.countryIsoCode || '';
            this.countryName = data.countryName || '';
            this.dateFrom = data.dateFrom || '';
            this.dateUntil = data.dateUntil || '';
            this.eqId = data.eqId || data.teamEqId || data.sourceEqId || '';
            this.id = data.id || data.teamId || data.rankingTeamId || 0;
            this.isTourMember = data.isTourMember || false;
            this.isKitApproved = data.isKitApproved || false;
            this.isPlayingAtHome = data.isPlayingAtHome || false;
            this.isTeam1 = data.isTeam1 || false;
            this.isTest = data.isTest || false;
            this.managers = (data.managers || []).map(manager => new ManagerModel(manager));
            this.matchId = data.matchId || 0;
            this.medics = (data.medics || []).map(medic => new MedicModel(medic));
            this.name = data.name || data.teamName || '';
            this.nameAlternative = data.nameAlternative || data.teamNameAlternative || '';
            this.nameShort = data.nameShort || data.teamNameShort || data.teamAbbreviation || '';
            this.players = (data.players || []).map(player => new PlayerModel(player));
            this.points = data.points || 0;
            this.position = data.position || '';
            this.result = data.result || 'Draw';
            this.score = data.score || 0;
            this.seriesId = data.seriesId || 0;
            this.shortName = data.shortName || '';
            this.sourceEqId = data.sourceEqId || data.teamSourceEqId || '';
            this.sport = data.sport || '';
            this.team = data.team || null;
            this.teamAliasEqId = data.teamAliasEqId || '';
            this.teamAliasId = data.teamAliasId || 0;
            this.teamAliasSourceEqId = data.teamAliasSourceEqId || '';
            this.teamCode = data.teamCode || '';
            this.teamCodeAnnual = data.teamCodeAnnual || '';
            this.teamId = data.teamId || 0;
            this.teamName = data.teamName || '';
            this.teamNameHistory = data.teamNameHistory || [];
            this.teamType = data.teamTypeName || data.teamType || '';
            this.teamTypeId = data.teamTypeId || 0;
            this.tournamentId = data.tournamentId || 0;
            this.tournamentTeamStatus = data.tournamentTeamStatus || '';
            this.tournamentTeamId = data.tournamentTeamId || 0;
            this.union = data.union || data.unionName || '';

            this.typeId = data.typeId || 0;

            this.sportId = data.sportId || 0;

            this.unionId = data.unionId || 0;
            this.unionEqId = data.unionEqId || '';
            this.unionName = data.unionName || '';

            this.matchTeamId = data.matchTeamId || 0;
            this.lineUp = (data.lineUp && data.lineUp.length > 0) ? data.lineUp.map(item => item).join(', ') : '-';
            this.replacements = data.replacements || [];
            this.tries = this.groupMatchSummaryDataByDisplayName(data.tries);
            this.conversions = this.groupMatchSummaryDataByDisplayName(data.conversions);
            this.dropGoals = this.groupMatchSummaryDataByDisplayName(data.dropGoals);
            this.penalties = this.groupMatchSummaryDataByDisplayName(data.penalties);
            this.missedConversions = this.groupMatchSummaryDataByDisplayName(data.missedConversions);
            this.missedDropGoals = this.groupMatchSummaryDataByDisplayName(data.missedDropGoals);
            this.missedPenalties = this.groupMatchSummaryDataByDisplayName(data.missedPenalties);

            if (data.lastPlayedMatch) {
                const teams = [
                    {
                        id: this.id,
                        teamName: this.teamName,
                        teamEqId: this.eqId,
                        countryIsoCode: this.countryIsoCode,
                    },
                    {
                        teamName: data.lastPlayedMatch.againstTeam ? data.lastPlayedMatch.againstTeam.teamName : '',
                        teamCode: data.lastPlayedMatch.againstTeam ? data.lastPlayedMatch.againstTeam.teamCode : '',
                        teamEqId: data.lastPlayedMatch.againstTeam ? data.lastPlayedMatch.againstTeam.teamEqId : '',
                        countryIsoCode: data.lastPlayedMatch.againstTeam ? data.lastPlayedMatch.againstTeam.countryCode : '',
                    }
                ];
                data.lastPlayedMatch.teams = teams;
            }
            this.lastPlayedMatch = MatchModel.from(data.lastPlayedMatch);

            this.logoStorage = data.logoUrl || data.teamLogoUrl || '';
            this.logo = Model._api.call.union.resolveStoragePath(this.logoStorage) || data.logo || null;
        }
    }

    setLogoStorage(logoStorage){
        this.logoStorage = logoStorage;
        this.logo = Model._api.call.union.resolveStoragePath(this.logoStorage);
    }

    /**
     * get team name.
     * returns array of name segments if segmented is enabled.
     *
     * @param {boolean} [segmented]
     *
     * @returns {string|string[]}
     */
    getName(){
        return this.name || this.nameShort;
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPath(){
        return this.logo || require('@/assets/img/union.svg');
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.nameShort || this.name;
    }

    toJSON() {
        let teamType = this.teamType;
        if (teamType?.toString().indexOf(' ') >= 0){
            teamType = teamType.toString().toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
        }

        return {
            countryIsoCode: this.countryIsoCode,
            countryName: this.countryName,
            logoUrl: this.logoStorage,
            name: this.name,
            nameAlternative: this.nameAlternative,
            nameShort: this.nameShort,
            sport: this.sport,
            teamType: teamType,
            unionId: this.unionId,
            unionName: this.unionName,
        };
    }

    buildMappingPayloads() {
        if (!this.mappingProviders || !this.mappingProviders.length){
            return [];
        }

        return this.mappingProviders.map(mappingProvider => ({
            provider: mappingProvider.name,
            entity: constants.mappingEntityNames.team,
            id: this[mappingProvider.name.toLowerCase()],
            eqId: this.eqId,
        }));
    }

    /**
     * group match summary data by display name.
     *
     * @param {array} an array of action objects
     *
     * @returns {string} containing a comma seperated string or a dash if empty
     */
    groupMatchSummaryDataByDisplayName(matchSummaryActionData){
        if(!matchSummaryActionData || !matchSummaryActionData.length){
            return '-';
        }

        let matchSummaryActionDataGrouped = [];
        matchSummaryActionData.forEach((action) => {
            let foundActionGroup = matchSummaryActionDataGrouped.find(actionGroup => actionGroup.displayName === action.displayName);
            if (foundActionGroup && action.time){
                foundActionGroup.times.push(action.time);
            }
            else
            {
                action.times = (action.time ? [action.time] : []);
                matchSummaryActionDataGrouped.push(action);
            }
        });

        return matchSummaryActionDataGrouped.map(item => item.displayName + (item.times.length ? ' (' + item.times.join(', ') + ')' : '')).join(', ');
    }
}
