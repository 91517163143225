<template>
    <div v-bind:class="elementClasses">
        <label v-bind:for="elementId">{{ labelText }}</label>
        <input type="text" v-bind:id="elementId" v-model="value"/>
    </div>
</template>

<script>
import base from './base.vue';

/**
 * link select element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'linkElement',
    extends: base,
    data(){
        return {
            value: null
        };
    },
};
</script>
