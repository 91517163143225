<script>
import vselectElement from '../vselect.vue';
import TeamTypeModel from '@/models/teamType.js';

/**
 * vselect element for TeamType items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectTeamTypeElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                //filtering done in frontend due to api not providing the functionality
                return this.$api.call.union.getTeamTypes()
                    .then(result => (result || []).map(data => new TeamTypeModel(data)))
                    .then(items => items.filter(teamType => teamType.description.toLowerCase().includes(text.toLowerCase())));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'name':
                    default:
                        return this.$api.call.union.getTeamTypes()
                            .then(result => (result.teamTypes || result.items || []).map(data => new TeamTypeModel(data)))
                            .then(items => items.filter(teamType => initValues.includes(this.getOptionKey(teamType))));
                }
            };

            return config;
        },
    },
};
</script>
