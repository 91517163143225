<template>
    <div class="pageContent">
        <seriesList
            v-bind:title="$tc('series', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.series.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'commonSeriesCreate' }"
                >
                    {{ $t('createSeries') }}
                </actionButton>
            </template>
        </seriesList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import seriesList from '@/components/lists/series.vue';

export default {
    name: 'commonSeriesIndex',
    extends: base,
    components: {
        seriesList,
    },
    data(){
        return {
            rowProps: {
                showSport: true,
                showMainSeason: true,
            },
        };
    },
};
</script>
