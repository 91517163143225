<script>
import formComponent from '@/components/form.vue';
import TournamentTypeModel from '@/models/tournamentType.js';

/**
 * component
 */
export default {
    name: 'editTournamentTypeForm',
    extends: formComponent,
    props: {
        model: {
            type: TournamentTypeModel,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('name'),
                        placeholder: this.$t('enterName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'shortName',
                        type: 'text',
                        label: this.$t('nameShort'),
                        placeholder: this.$t('enterName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'tournamentTypeGroupId',
                        type: 'vselectTournamentTypeGroup',
                        label: this.$t('group'),
                        placeholder: this.$t('selectTournamentTypeGroup'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'notes',
                        type: 'text',
                        label: this.$t('notes'),
                        placeholder: this.$t('enterNotes'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'numberOfParticipants',
                        type: 'number',
                        label: this.$t('numberOfParticipants'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'radarThreeSixtyName',
                        type: 'text',
                        label: this.$t('radarThreeSixtyName'),
                        placeholder: this.$t('enterRadarThreeSixtyName'),
                        disabled: this.readonly,
                        maxlength: 50,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
