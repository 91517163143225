<template>
    <div class="pageContent">
        <seriesTypeList
            v-bind:title="$t('seriesTypes')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.seriesTypes.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'administrationSeriesTypesCreate' }"
                >
                    {{ $t('createSeriesType') }}
                </actionButton>
            </template>
        </seriesTypeList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import seriesTypeList from '@/components/lists/administration/seriesType.vue';

export default {
    name: 'administrationSeriesTypesIndex',
    extends: base,
    components: {
        seriesTypeList,
    },
    data(){
        return {
            rowProps: {
                showName: true,
                showDescription: true,
                showRemove: true,
                showEdit: true,
            }
        };
    }
};
</script>
