<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:waitFor="apiData.person"
            v-bind:waitForRetry="reloadPerson"
            v-bind:title="$t('personRoleDetails')"
            v-bind:name="person ? person.getName() : ''"
            v-bind:imagePath="person ? person.getImgPath() : null"
            v-bind:details="detailData"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="secondary"
                    v-bind:permission="permissions.persons.roles.update"
                    v-b-modal.modal-editPersonRole
                    v-if="person"
                >
                    {{ $t('edit') }}
                </actionButton>
                <actionButton
                    size="sm"
                    variant="danger"
                    v-bind:permission="permissions.persons.roles.delete"
                    v-bind:waitFor="apiData.person"
                    v-b-modal.modal-deletePersonRole
                    v-if="role"
                >
                    {{ $t('delete') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <list
            v-bind:title="$tc('match', 2)"
            v-bind:items="matches"
            v-bind:waitFor="apiData.matches"
            v-bind:waitForRetry="reloadMatches"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:header>
                <roleMatchRow
                    showScore showKickOffTime showPosition showDisciplinary showWarning showCiting showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: match, role }">
                <roleMatchRow
                    showScore showKickOffTime showPosition showDisciplinary showWarning showCiting showDetail
                    v-bind:match="match"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:title="$tc('tournament', 2)"
            v-bind:items="tournaments"
            v-bind:waitFor="apiData.tournaments"
            v-bind:waitForRetry="reloadTournaments"
            v-bind:hide="hideElement(2)"
        >
            <template v-slot:header>
                <tournamentRow
                    showSport showEventType showPosition showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: tournament, role }">
                <tournamentRow
                    showSport showEventType showPosition showDetail
                    v-bind:tournament="tournament"
                    v-bind:role="role"
                />
            </template>
        </list>

        <editPersonRoleModal
            id="modal-editPersonRole"
            v-bind:title="$t('editPersonRole')"
            v-bind:personRole="role"
            v-bind:create="false"
            v-bind:confirmHandler="updatePersonRole"
            v-on:confirmed="updatePersonRoleComplete"
            v-if="role"
        />

        <confirmModal
            id="modal-deletePersonRole"
            v-bind:waitFor="role"
            v-bind:text="$t('pleaseConfirmDeletion', { name: this.getTeamName() })"
            v-bind:data="role"
            v-bind:confirmHandler="deletePersonRole"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingEditMixins } from '@/mixins/entityMapping.js';
import subNavigation from '@/components/subnavigation.vue';
import editPersonRoleModal from '@/components/modals/editPersonRole.vue';
import detailsHeader from '@/components/detailsHeader.vue';
import confirmModal from '@/components/modals/confirm.vue';

import roleMatchRow from '@/components/rows/roleMatch.vue';
import tournamentRow from '@/components/rows/tournament.vue';

import PersonModel from '@/models/person.js';
import PersonRoleModel from '@/models/personRole.js';
import RoleMatchModel from '@/models/roleMatch.js';
import TeamModel from '@/models/team.js';

export default {
    name: 'commonPersonDetail',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingEditMixins,
    ],
    components: {
        subNavigation,
        editPersonRoleModal,
        detailsHeader,
        roleMatchRow,
        tournamentRow,
        confirmModal,
    },
    props: {
        dataPersonId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataPersonRoleId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            person: this.loadPerson(),
        };

        return {
            apiData: {
                person: this.apiData.person,
                matches: this.loadMatches(),
                tournaments: this.loadTournaments(),
            },
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$tc('match', 2),
                    },
                    2: {
                        label: this.$tc('tournament', 2),
                    },
                }
            },
            person: null,
            role: null,
            roleEqId: '',
            matches: [],
            tournaments: [],
        };
    },
    computed: {
        detailData(){
            if(!this.person){
                return [];
            }

            if (this.role.unionName && !this.role.name) {
                return [
                    {
                        label: this.$t('roleName'),
                        content: ((this.role && this.role.roleType) ? this.role.roleType : ''),
                    },
                    {
                        label: this.$t('unionName'),
                        content: (this.role && this.role.unionName ? this.role.unionName : ''),
                    },
                    {
                        label: this.$t('from'),
                        content: ((this.role && this.role.dateFrom) ? this.$fd(this.role.dateFrom, 'date') : ''),
                    },
                    {
                        label: this.$t('until'),
                        content: ((this.role && this.role.dateUntil) ? this.$fd(this.role.dateUntil, 'date') : ''),
                    },
                ];
            }

            return [
                {
                    label: this.$t('roleName'),
                    content: ((this.role && this.role.roleType) ? this.role.roleType : ''),
                },
                {
                    label: this.$t('teamName'),
                    content: ((this.role && this.role.name) ? this.role.name : ''),
                },
                {
                    label: this.$t('sportName'),
                    content: ((this.role && this.role.sport) ? this.role.sport : ''),
                },
                {
                    label: this.$t('from'),
                    content: ((this.role && this.role.dateFrom) ? this.$fd(this.role.dateFrom, 'date') : ''),
                },
                {
                    label: this.$t('until'),
                    content: ((this.role && this.role.dateUntil) ? this.$fd(this.role.dateUntil, 'date') : ''),
                },
            ];
        },
    },
    methods: {
        getTeamName() {
            return this.role ? (this.role.name.length > 0 ? this.role.name : this.$t('unknown')) : this.$t('unknown');
        },
        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },
        loadPerson(){
            return this.$api.call.person.personGetById(this.dataPersonId).then(data => {
                this.person = new PersonModel(data);

                let personRole = this.person.roles.filter(role => role.id === this.dataPersonRoleId)[0];

                this.role = personRole;
                this.roleEqId = this.role.eqId;

                // get the team to pre-fill edit form
                if (this.role && this.role.teamId) {
                    this.$api.call.union.teamById(this.role.teamId).then(teamData => {
                        this.role.team = new TeamModel(teamData);
                    });
                }

                this.reloadMatches();
                this.reloadTournaments();

                if (personRole){
                    //get mapping providers
                    return this.loadEntityMappingProviders(personRole);
                }
            });
        },
        reloadPerson(){
            this.apiData.person = this.loadPerson();
        },
        loadMatches(){
            if(!this.person){
                return new Promise((resolve, reject) => this.apiData.person.catch(reject));
            }
            return this.$api.call.match.matchesGetByPersonRoleEqId(this.roleEqId)
                .then(data => {
                    this.matches = (data || []).map(match => RoleMatchModel.from(match));
                });
        },
        reloadMatches(){
            this.apiData.matches = this.loadMatches();
        },
        loadTournaments(){
            if(!this.person){
                return new Promise((resolve, reject) => this.apiData.person.catch(reject));
            }

            return this.$api.call.tournament.tournamentsGetByPersonRoleEqId(
                this.roleEqId,
                {
                    pageIndex: 0,
                    pageSize: 5
                }).then(data => {
                this.tournaments = PersonRoleModel.from(data).tournaments;
            });
        },
        reloadTournaments(){
            this.apiData.tournaments = this.loadTournaments();
        },
        updatePersonRole(personRole) {
            let queue = Promise.resolve();

            //update team
            queue = queue.then(() => this.$api.call.person.personRoleUpdate(this.dataPersonRoleId, personRole.toJSON()));

            //update mappings
            queue = this.manageMappings(personRole, queue);

            queue = queue.then(() => {
                this.error = null;
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('venue update failed', error);
                }

                //show validation errors
                this.error = error;
            });

            return queue;
        },
        updatePersonRoleComplete(){
            this.reloadPerson();
        },
        deletePersonRole() {
            return this.$api.call.person.personRoleDeleteById(this.dataPersonRoleId).then(result => {
                this.$router.push({ name: 'commonPersonDetail', params: { dataPersonId: this.dataPersonId } });
            });
        },
    },
};
</script>
