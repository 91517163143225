import Log from './log';

import AdminService from './api/admin.js';
import LocationService from './api/location.js';

import ApiService from './api/api.js';
import KitService from './api/kit.js';
import MappingService from './api/mapping.js';
import MatchService from './api/match.js';
import NotificationService from './api/notification.js';
import PersonService from './api/person.js';
import PositionService from './api/position.js';
import RankingsService from './api/rankings.js';
import SeriesService from './api/series.js';
import StandingsService from './api/standings.js';
import TeamService from './api/team.js';
import TermsOfParticipationService from './api/terms-of-participation.js';
import TournamentService from './api/tournament.js';
import TranslationService from './api/translation.js';
import UnionService from './api/union.js';
import UserService from './api/user.js';

import testData from '../testData.json';
const testUser = testData.user;
const testUsers = testData.users;

const getNewUserId = function(){
    let i = 1;

    do{
        if(testUsers[i] === undefined){
            return i;
        }
    }while(i++);
};


/**
 * Api request service class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class Api {
    /**
     * api base url
     *
     * @type {string}
     */
    baseUrl = '';

    /**
     * auth lib
     *
     * @type {Auth}
     */
    auth = null;

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    /**
     *
     */
    call;

    /**
     * class constructor
     *
     * @param {string} baseUrl
     * @param {Auth} auth
     */
    constructor(baseUrl, auth){
        this.baseUrl = baseUrl;
        this.auth = auth;

        this.call = {
            admin: new AdminService(baseUrl, auth),
            location: new LocationService(baseUrl, auth),

            api: new ApiService(baseUrl, auth),
            kit: new KitService(baseUrl, auth),
            mapping: new MappingService(baseUrl, auth),
            match: new MatchService(baseUrl, auth),
            notification: new NotificationService(baseUrl, auth),
            person: new PersonService(baseUrl, auth),
            place: new LocationService(baseUrl, auth),
            position: new PositionService(baseUrl, auth),
            rankings: new RankingsService(baseUrl, auth),
            series: new SeriesService(baseUrl, auth),
            standings: new StandingsService(baseUrl, auth),
            team: new TeamService(baseUrl, auth),
            termsOfParticipation: new TermsOfParticipationService(baseUrl, auth),
            tournament: new TournamentService(baseUrl, auth),
            translation: new TranslationService(baseUrl, auth),
            union: new UnionService(baseUrl, auth),
            user: new UserService(baseUrl, auth)
        };
    }

    /**
     * request data from api
     *
     * @param {*} target
     *
     * @returns {Promise<object>}
     */
    request(target, data){
        if(this.log){
            this.log.debug('api request: %s %o', target, data);
        }

        let result = null;

        switch(target){
            case '/users/list':
                result = Object.values(testUsers);
                break;

            case '/users/id':
                if(!testUsers[data.id]){
                    result = Error('not found');
                }

                result = testUsers[data.id];
                break;
        }

        //if unknown action, get out
        if(result === null){
            return Promise.reject(Error('nope'));
        }

        //order
        if(data.sortKey && data.sortDir){
            result.sort((a, b) => {
                a = a[data.sortKey];
                b = b[data.sortKey];

                if(a < b){
                    return (data.sortDir === 'desc' ? 1 : -1);
                }
                if(a > b){
                    return (data.sortDir === 'desc' ? -1 : 1);
                }
                return 0;
            });
        }

        //limit
        if(data.offset !== undefined && data.limit){
            result = result.splice(data.offset || 0, data.limit || 999);
        }

        //return result
        return new Promise((resolve, reject) => {
            window.setTimeout(() => {
                if(result instanceof Error){
                    reject(result);
                }

                resolve(result);
            }, 500);
        }).then(response => {
            if(this.log){
                this.log.debug('api request result: %s %o %o', target, data, response);
            }

            return response;
        });
    }

    /**
     * submit data to api
     *
     * @param {*}      target
     * @param {object} data
     *
     * @returns {Promise<object>}
     */
    submit(target, data){
        if(this.log){
            this.log.debug('api submit: %s %o', target, data);
        }

        let action;

        switch(target){
            case '/users/login':
                action = (resolve, reject) => {
                    if(!data.name){
                        resolve(testUser);
                    }
                    else {
                        Object.values(testUsers).forEach(user => {
                            if (user.email === data.name) {
                                resolve(user);
                            }
                        });

                        reject(Error('no name'));
                    }
                };
                break;

            case '/users/create':
            case '/users/update':
                action = (resolve, reject) => {
                    if(!data.name || !data.email){
                        reject(Error('incomplete!'));
                        return;
                    }

                    if(!data.id){
                        data.id = getNewUserId();
                    }

                    testUsers[data.id] = data;

                    resolve({
                        result: true,
                        msg: ''
                    });
                };
                break;

            case '/users/delete':
                action = (resolve, reject) => {
                    delete testUsers[data.id];

                    resolve({
                        result: true,
                        msg: ''
                    });
                };
                break;
        }

        if(!action){
            return Promise.reject(Error('nope'));
        }

        return new Promise((resolve, reject) => {
            window.setTimeout(() => {
                action(resolve, reject);
            }, 500);
        }).then(response => {
            if(this.log){
                this.log.debug('api submit result: %s %o %o', target, data, response);
            }

            return response;
        });
    }

    /**
     * set log service instance
     *
     * @throws ReferenceError
     *
     * @param {Log} log
     */
    setLog(log){
        if(!(log instanceof Log)){
            throw ReferenceError('unable to set api log property: invalid argument');
        }

        this.log = log;
    }
}
