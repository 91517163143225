<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createSeriesType')"
            v-on:submit="createSeriesType"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toSeriesTypeList"
                    v-bind:loadingWhile="apiData.seriesTypeCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.seriesTypeCreation"
                >
                    {{ $t('createSeriesType') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createSeriesTypeForm
                    v-bind:headline="$t('seriesTypeInformation')"
                    v-bind:model="seriesType"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import createSeriesTypeForm from '@/components/forms/createSeriesType.vue';

import SeriesTypeModel from '@/models/seriesType.js';

export default {
    name: 'commonSeriesTypeCreate',
    extends: base,
    components: {
        validations,
        createSeriesTypeForm,
    },
    data(){
        return {
            apiData: {
                seriesTypeCreation: null,
            },
            seriesType: SeriesTypeModel.from({}),
            error: null,
        };
    },
    computed: {
        toSeriesTypeList(){
            return { name: 'administrationSeriesTypesIndex' };
        },
    },
    methods: {
        createSeriesType(){
            this.apiData.seriesTypeCreation = this.$api.call.tournament.seriesTypeCreate(this.seriesType.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toSeriesTypeList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('seriesType creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
