<script>
import itemList from '@/components/itemList.vue';
import userRow from '@/components/rows/user.vue';
import UserModel from '@/models/user.js';

/**
 * complex team list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'administrationUserList',
    extends: itemList,
    components: {
        userRow,
    },
    data(){
        return {
            //overwrites
            rowElement: userRow,
            rowItemPropName: 'user',
            selectItemProp: 'user',
            //ItemModel: UserModel,
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showNameSeparated: true,
                permissionGroup: true,
                showStatus: true,
                showDetail: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    name: 'name',
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName')
                },
                {
                    name: 'union',
                    id: 'union',
                    type: 'vselectUnion',
                    valueProperty: 'eqId',
                    placeholder: this.$t('filterByUnion')
                },
                {
                    name: 'accountType',
                    id: 'accountType',
                    type: 'vselectUserType',
                    placeholder: this.$t('filterByAccountType')
                },
                {
                    name: 'status',
                    id: 'status',
                    type: 'vselectUserStatus',
                    placeholder: this.$t('filterByStatus')
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load administration users
            return this.$api.call.admin.userSearch({
                userNames: filters.name || null,
                unionEqId: filters.union || null,
                userType: filters.accountType || null,
                userStatus: filters.status || null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.users || result.items || []).map(data => new UserModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
    },
};
</script>
