import DeclarationModel from '@/models/declaration.js';
import TournamentModel from '@/models/tournament.js';
import TeamDeclarationModel from '@/models/declaration/team.js';

/**
 * tournament declaration model class
 */
export default class TournamentDeclarationModel extends DeclarationModel {
    /**
     * id
     *
     * @type {int}
     */
    id = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.eqId = data.eqId || '';
            this.deadlineDate = this.toDate(data.deadlineDate);
            this.declarationWorkflowId = data.declarationWorkflowId || 0;
            this.openingDate = this.toDate(data.openingDate);
            this.sport = data.sport || '';
            this.status = data.status || '';
            this.steps = data.steps || [];
            this.teams = (data.teams || []).map(data => {
                data.step = this.step;
                return TeamDeclarationModel.from(data);
            });
            this.tournament = TournamentModel.from(data.tournament) || {};
            this.type = data.type || '';
            this.isSevens = this.checkIsSevens();
        }
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPathTournament(){
        if (this.tournament.logo) {
            return this.tournament.logo;
        }
        return require('@/assets/img/union.svg');
    }

    getTournament() {
        return this.tournament || new TournamentModel();
    }

    setTournament(tournament) {
        this.tournament = tournament;
    }

    getTeamDeclaration(){
        return this.teamDeclaration || new TeamDeclarationModel();
    }

    toJSON(){
        const data = super.toJSON();

        data.tournamentEqId = this.getTournament().eqId || null;

        return data;
    }
}
