<template>
    <div>
        <b-modal v-bind="modalProps" v-on:show="onOpen" v-on:ok="$emit('editRepresenting', representing)">
            <list v-bind:items="representing">
                <template v-slot="{ item: teamRepresenting, role }">
                    <playerRepresentingRow
                        v-bind:playerRepresenting="teamRepresenting"
                        v-bind:role="role"
                        v-bind:showRemove="true"
                        v-on:removeConfirmed="removeRepresenting"
                    />
                </template>
                <template v-slot:footer>
                    <simpleRow
                        v-bind:text="$t('clickToAdd', { type: $tc('team', 1) })"
                        showAdd
                        v-on:add="$bvModal.show('teamPick' + player.tournamentTeamPersonId)"
                    />
                </template>
            </list>
            <template v-slot:modal-header-close>
                <icon type="cancel" color="primary"/>
            </template>
        </b-modal>

        <teamPickModal
            v-bind:id="'teamPick' + player.tournamentTeamPersonId"
            v-bind:title="$t('searchTeams')"
            v-bind:filterValues="{ teamType: 'Club' }"
            v-on:select="addTeam"
        />
    </div>
</template>

<script>
import base from './base.vue';
import icon from '@/components/icon.vue';
import list from '@/components/list.vue';
import playerRepresentingRow from '@/components/rows/playerRepresenting.vue';
import simpleRow from '@/components/rows/simple.vue';
import teamPickModal from '@/components/modals/pick/team.vue';

import PlayerModel from '@/models/person/player.js';
import PlayerRepresentingModel from '@/models/person/playerRepresenting.js';

/**
 *
 */
export default {
    name: 'editPlayerRepresentingModal',
    extends: base,
    components: {
        icon,
        list,
        playerRepresentingRow,
        simpleRow,
        teamPickModal,
    },
    props: {
        player: {
            type: PlayerModel,
            required: false,
            default(){
                return new PlayerModel();
            },
        },
    },
    data() {
        return {
            representing: [],
        };
    },
    methods: {
        onOpen(){
            this.representing = this.player.representing;
        },
        addTeam(data) {
            this.representing.push(new PlayerRepresentingModel(data));
        },
        removeRepresenting(data){
            this.representing = this.representing.filter(function(e) { return e.teamAliasEqId !== data.representing.teamAliasEqId });
        },
    },
};
</script>
