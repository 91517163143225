<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
    <div
            v-bind="getColumnProps(null, 3, 1, 1)"
            class="d-flex flex-column flex-lg-row align-items-center flex-grow-1 flex-basis-0 p-0"
        >
            <div
                v-bind="getColumnProps(null, null, 1, 1,true)"
                class="card-img-wrapper mobile-center"
                v-if="true"
            >
                <b-card-img
                    v-bind="getColumnProps('image')"
                    data-type="image"
                    v-bind:src="liveIcon"
                    v-bind:alt="'Match is live'"
                    v-bind:width="imgWidth"
                    v-bind:height="20"
                    v-if="match.isLive()"
                />
            </div>
        </div>

        <div
            v-bind="getColumnProps(null, 3, showScore ? 5 : 6)"
            class="d-flex flex-column flex-lg-row align-items-center flex-grow-1 flex-basis-0 p-0"
            v-if="showTeamOneLogo || showTeamOneName"
        >
            <div v-bind="getColumnProps(null, null, null, null,true)" class="card-img-wrapper mobile-center" v-if="showTeamOneLogo">
                <b-card-img
                    v-bind="getColumnProps('image')"
                    data-type="image"
                    v-bind:src="match.getImgPathTeamOne()"
                    v-bind:alt="match.getTeamOneName()"
                    v-bind:width="imgWidth"
                    v-bind:height="imgHeight"
                    v-if="showTeamOneLogo"
                />
            </div>

            <b-card-title v-bind="getColumnProps('team1')" v-bind:title-tag="titleTag" v-if="showTeamOneName">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('team1')" v-if="isHeadline">{{ getHeadline('team1', $tc('team', 1) + ' 1') }}</label>
                <span class="text-uppercase" v-else>{{ match.getTeamOneName() }}</span>
            </b-card-title>
        </div>

        <b-card-text v-bind="getColumnProps('score', 4, 2, 1)" class="align-self-center" v-if="showScore">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('score')" v-if="isHeadline">{{ getHeadline('score', $t('score')) }}</label>
            <span
                class="d-block text-uppercase"
                v-else-if="match.teamOne !== null && match.teamTwo !== null"
            >
                {{ match.teamOneScore }} : {{ match.teamTwoScore }}
            </span>
            <span class="d-block text-center text-uppercase" v-else>{{ $t('vs') }}</span>
        </b-card-text>

        <div v-bind="getColumnProps(null, 5, showScore ? 5 : 6)" class="d-flex flex-column flex-lg-row align-items-center flex-grow-1 flex-basis-0 p-0" v-if="showTeamTwoLogo || showTeamTwoName">
            <div v-bind="getColumnProps(null, null,  null, null, true)" class="card-img-wrapper mobile-center" v-if="showTeamTwoLogo">
                <b-card-img
                    v-bind="getColumnProps('image')"
                    v-bind:src="match.getImgPathTeamTwo()"
                    v-bind:alt="match.getTeamTwoName()"
                    v-bind:width="imgWidth"
                    v-bind:height="imgHeight"
                    v-if="showTeamTwoLogo"
                />
            </div>

            <b-card-title v-bind="getColumnProps('team2')" v-bind:title-tag="titleTag" v-if="showTeamTwoName">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('team2')" v-if="isHeadline">{{ getHeadline('team2', $tc('team', 1) + ' 2') }}</label>
                <span class="text-uppercase" v-else>{{ match.getTeamTwoName() }}</span>
            </b-card-title>
        </div>

        <b-card-text v-bind="getColumnProps('number', 12, 6, 1)" v-if="showNumber">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('number')">{{ getHeadline('number', $t('number')) }}</label>
            <span v-if="!isHeadline">{{ match.matchNumber }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('tournament', 12, 6, 2)" v-if="showTournament">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('tournament')">{{ getHeadline('tournament', $tc('tournament', 1)) }}</label>
            <span v-if="!isHeadline">{{ match.tournamentName }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('stage', 12, 6, 1)" v-if="showStage">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('stage')">{{ getHeadline('stage', $t('stage')) }}</label>
            <span v-if="!isHeadline">{{ match.stageName }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('status', 12, 6, 1)" v-if="showStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('status')">{{ getHeadline('status', $t('status')) }}</label>
            <span v-if="!isHeadline">{{ match.matchStatus.name }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('group', 12, 6, 1)" v-if="showGroup">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('group')">{{ getHeadline('group', $t('group')) }}</label>
            <span v-if="!isHeadline">{{ match.group }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('venue', 12, 6, 2)" v-if="showVenue" class="match-venue">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('venue')">{{ getHeadline('venue', $t('stadiumName')) }}</label>
            <span v-if="!isHeadline">{{ match.venue ? match.venue.name : '' }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('publishStatus', 12, 6, 2)" v-if="showPublishStatus" class="match-publish-status">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('publishStatus')">{{ getHeadline('publishStatus', $t('publishStatus')) }}</label>
            <publishStatusIndicator v-bind:type="match.publishStatus" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('kickOffTime', 12, 6, 1)" v-if="showKickOffTime" class="match-kickoff">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('kickOffTime')">{{ getHeadline('kickOffTime', $t('kickOff')) }}</label>
            <span class="d-lg-none" v-if="!isHeadline">{{ $fd(match.kickOff, 'dateTime') }}</span>
            <span class="d-none d-lg-inline" v-if="!isHeadline">{{ $fd(match.kickOff, 'dateFor2Rows') }} <br> {{ $fd(match.kickOff, 'timeFor2Rows') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('position', 12, 6, 1)" v-if="showPosition">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('position')">{{ getHeadline('position', $t('position')) }}</label>
            <span v-if="!isHeadline">{{ match.position }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowStatus', 1, 6, 2)" v-if="showWorkflowStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowStatus')" v-if="isHeadline">{{ getHeadline('workflowStatus', $t('status')) }}</label>
            <statusIndicator v-bind:type="match.workflowStatus" v-else/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowDeadline', 12, 6, 2)" v-if="showWorkflowDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowDeadline')">{{ getHeadline('workflowDeadline', $t('deadline')) }}</label>
            <span v-if="!isHeadline">{{ $fd(match.workflowDeadline, 'time') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('icons', 12, 12, null, true)" class="text-center" v-if="hasVisibleIcons">
            <span
                class="btn btn-icon no-click"
                v-bind:class="buttonClasses"
                v-if="showDisciplinary"
            >
                <icon v-bind:type="disciplinaryIcon" large/>
            </span>
            <span
                class="btn btn-icon no-click"
                v-bind:class="buttonClasses"
                v-if="showWarning"
            >
                <icon type="warning" large/>
            </span>
            <span
                class="btn btn-icon no-click"
                v-bind:class="buttonClasses"
                v-if="showCiting"
            >
                <icon type="citing" large/>
            </span>
        </b-card-text>

        <b-card-text
            v-bind="getColumnProps('select', 3, null, null, true)"
            class="align-self-start align-self-lg-center text-right"
            v-if="showSelect"
        >
            <actionButton
                variant="outline-primary"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('select', $event)"
            >
                {{ $t('select') }}
            </actionButton>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, showWorkflowStatus ? 6 : 12, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <div :class="match.isLive() ? 'action-row-icon' : 'action-row-no-icon'">
                <b-card-img
                    class="live-icon"
                    v-bind="getColumnProps('image')"
                    data-type="image"
                    v-bind:src="liveIcon"
                    v-bind:alt="'Match is live'"
                    v-bind:width="imgWidth"
                    v-bind:height="20"
                    v-if="match.isLive()"
                />
                <div class="action-row-buttons">
                    <actionButton
                        variant="icon"
                        v-bind="buttonAttributes"
                        v-bind:permission="removePermission"
                        v-on:click="emitEvent('remove', $event)"
                        v-b-modal="modalName('confirmDelete', match.id)"
                        v-if="showRemove"
                    >
                        <icon type="cancel-circle-full" large/>
                    </actionButton>
                    <actionButton
                        variant="icon"
                        v-bind="buttonAttributes"
                        v-bind:to="detailLink"
                        v-on:click="emitEvent('view', $event)"
                        v-if="showDetail"
                    >
                        <icon type="view" large/>
                    </actionButton>
                    <actionButton
                        variant="icon"
                        v-bind="buttonAttributes"
                        v-on:click="emitEvent('add', $event)"
                        v-if="showAdd"
                    >
                        <icon type="add" large/>
                    </actionButton>
                    <actionButton
                        variant="icon"
                        v-bind="buttonAttributes"
                        v-bind:to="moveTo"
                        v-on:click="emitEvent('move', $event)"
                        v-if="showMove"
                    >
                        <icon type="view" large/>
                    </actionButton>
                </div>
            </div>
        </b-card-text>

        <div v-bind="getColumnProps(null, 6)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', match.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: match.getTeamOneName() + ' vs ' + match.getTeamTwoName() })"
            v-bind:data="match"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicator from '../status-indicator.vue';
import publishStatusIndicator from '../publish-status-indicator.vue';

import MatchModel from '@/models/match.js';

/**
 * match block component
 */
export default {
    name: 'matchRow',
    extends: base,
    components: {
        statusIndicator,
        publishStatusIndicator,
    },
    props: {
        match: {
            type: MatchModel,
            required: false,
            default(){
                return new MatchModel();
            }
        },

        showTeamOneLogo: {
            type: Boolean,
            default: true,
        },
        showTeamTwoLogo: {
            type: Boolean,
            default: true,
        },
        showTeamOneName: {
            type: Boolean,
            default: true,
        },
        showTeamTwoName: {
            type: Boolean,
            default: true,
        },
        showTournament: {
            type: Boolean,
            default: false,
        },
        showPosition: {
            type: Boolean,
            default: false,
        },
        showNumber: {
            type: Boolean,
            default: false,
        },
        showScore: {
            type: Boolean,
            default: false,
        },
        showStage: {
            type: Boolean,
            default: false,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        showPublishStatus: {
            type: Boolean,
            default: false,
        },
        showGroup: {
            type: Boolean,
            default: false,
        },
        showVenue: {
            type: Boolean,
            default: false,
        },
        showKickOffTime: {
            type: Boolean,
            default: false,
        },

        showWorkflowStatus: {
            type: Boolean,
            default: false,
        },
        showWorkflowDeadline: {
            type: Boolean,
            default: false,
        },

        showDetail: {
            type: Boolean,
            default: false,
        },
        showDisciplinary: {
            type: Boolean,
            default: false,
        },
        showWarning: {
            type: Boolean,
            default: false,
        },
        showCiting: {
            type: Boolean,
            default: false,
        },
        /**
         * show button to select item (used for picker)
         */
        showSelect: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showAdd: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with arrow icon
         * clicking it will trigger "move" event
         */
        showMove: {
            type: Boolean,
            default: false,
        },
        /**
         * to location for move button
         */
        moveTo: {
            type: [String, Object],
            default: null,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.match.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                match: this.match || null,
            };
        },
        customElementClasses(){
            return {
                matchRow: true,
            };
        },
        hasVisibleIcons(){
            return (this.showDisciplinary || this.showWarning || this.showCiting);
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showAdd || this.showRemove || this.showMove);
        },
        disciplinaryIcon(){
            if(!this.match.disciplinary){
                return 'card-none';
            }

            const icons = [
                'card-yellow',
                'cards',
                'card-red',
            ];

            return icons[this.match.disciplinary.action] || 'card-none';
        },
    },
    data() {
        return {
            liveIcon: require('@/assets/img/live.svg')
        }
    }
};
</script>
