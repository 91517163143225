<template>
    <div class="test">
        <h2 class="mb-4">pick modals</h2>

        <p><b-button v-b-modal.testPick>single</b-button></p>
        <p><b-button v-b-modal.testPick2>multi</b-button></p>
        <p><b-button v-b-modal.testPick3>single w. handler</b-button></p>
        <p><b-button v-b-modal.testPick4>multi w. handler</b-button></p>
        <teamPickModal id="testPick" title="Single" v-on:select="log('select-single', $event)"/>
        <teamPickModal id="testPick2" title="Multi" v-on:select="log('select-multi', $event)" multiselect/>
        <teamPickModal id="testPick3" title="Single w. handler" v-on:select="log('select-handler-single', $event)" v-bind:selectHandler="testSelectHandler"/>
        <teamPickModal id="testPick4" title="Multi w. handler" v-on:select="log('select-handler-multi', $event)" v-bind:selectHandler="testSelectHandler" multiselect/>

        <hr/>

        <h2>loading component (success)</h2>

        <loading v-bind:waitFor="loadingPromise">
            loading component promise handling: success
        </loading>
        <br/>

        <wrapper title="wrapper loading test" v-bind:waitFor="loadingPromise">
            wrapper component promise handling: success
        </wrapper>

        <list title="list loading test" v-bind:items="[1,2,3]" v-bind:waitFor="loadingPromise">
            <template v-slot="{ item }">
                <div>{{ item }}</div>
            </template>
        </list>

        <h2>loading component (error)</h2>

        <loading v-bind:waitFor="loadingPromiseError" v-bind:waitForRetry="restartLoadingPromiseError">
            this should never show
        </loading>
        <br/>

        <wrapper title="wrapper loading test" v-bind:waitFor="loadingPromiseError" v-bind:waitForRetry="restartLoadingPromiseError">
            this should never show
        </wrapper>

        <list title="list loading test (no retry button)" v-bind:items="[1,2,3]" v-bind:waitFor="loadingPromiseError">
            <template v-slot="{ item }">
                <div>{{ item }}</div>
            </template>
        </list>

        <hr/>

        <h2>Icon types</h2>
        <div class="d-flex flex-wrap">
            <div v-for="(type, i) in iconTypes" v-bind:key="i" class="d-flex flex-column m-1 p-2 border border-primary">
                <div class="flex-grow-1 text-center">
                    <icon v-bind:type="type" large/>
                </div>
                <div class="text-center">{{ type }}</div>
            </div>
        </div>

        <hr/>

        <h3>Icon aliases</h3>
        <div class="d-flex flex-wrap">
            <div v-for="(type, i) in iconAliases" v-bind:key="i" class="d-flex flex-column m-1 p-2 border border-primary">
                <div class="flex-grow-1 text-center">
                    <icon v-bind:type="type" large/>
                </div>
                <div class="text-center">{{ type }}</div>
            </div>
        </div>

        <hr/>

        <h3>Infinity load list</h3>
        <list
            v-bind:items="loadItemsList"
            v-bind:loadItems="loadItems"
            v-on:itemsLoaded="log('items loaded', loadItemsList)"
            v-on:itemsFullyLoaded="log('items fully loaded', loadItemsList)"
            v-on:itemsLoadError="log('items load error', $event)"
        >
            <template v-slot="{ item: person, role }">
                <medicRow v-bind:person="person" v-bind:role="role"/>
            </template>
            <template v-slot:empty>
                list empty
            </template>
            <template v-slot:footer>
                <div class="text-center py-3">showing {{ loadItemsList.length }} of {{ loadItemsList.length + loadItemsMore.length }} items</div>
            </template>
        </list>

        <hr/>

        <list v-bind:items="testList1" draggable v-on:change="listUpdate" largeTitle>
            <template v-slot:header>
                <p>
                    list can be reordered using drag & drop
                </p>
                <validations v-bind:items="testList1" type="player" v-bind:min="5"/>
            </template>
            <template v-slot="{ item: person, role }">
                <medicRow v-bind:person="person" v-bind:role="role"/>
            </template>
            <template v-slot:footer>
                <medicRow
                    showAssignMedic showAdd
                    v-bind:showImg="false" v-bind:showName="false"
                    v-on:click="onClick"
                />
            </template>
        </list>

        <hr/>

        <list v-bind:items="testList2" title="Drag & Drop target" draggable draggableGroup="test" draggableGroupInternal draggablePrevent draggableHover v-on:draggableDrop="listDropSwap">
            <template v-slot:header>
                <p>
                    drag & drop target. accepts drops from list below. allows items within to be swapped.
                </p>
            </template>
            <template v-slot="{ item: person, role }">
                <medicRow v-bind:person="person" v-bind:role="role"/>
            </template>
        </list>

        <list v-bind:items="testList3" title="Drag & Drop source" draggable draggableGroup="test" draggableGroupExternal draggablePrevent draggableHover v-on:draggableDrop="listDrop">
            <template v-slot:header>
                <p>
                    drag & drop source. can only be dropped in the list above as a clone.
                </p>
            </template>
            <template v-slot="{ item: person, role }">
                <medicRow v-bind:person="person" v-bind:role="role"/>
            </template>
        </list>

        <hr/>

        <b-row class="justify-content-start">
            <b-col>
                <div class="mb-3"><b-button variant="empty">default</b-button></div>
                <div class="mb-3"><b-button variant="empty">hover/active</b-button></div>
                <div class="mb-3"><b-button variant="empty">focused</b-button></div>
                <div class="mb-3"><b-button variant="empty">disabled</b-button></div>
            </b-col>
            <b-col v-for="(variant, i) in buttonVariants" v-bind:key="i">
                <div class="mb-3"><b-button v-bind:variant="variant">action</b-button></div>
                <div class="mb-3"><b-button v-bind:variant="variant" class="active">action</b-button></div>
                <div class="mb-3"><b-button v-bind:variant="variant" class="focus">action</b-button></div>
                <div class="mb-3"><b-button v-bind:variant="variant" disabled>action</b-button></div>
            </b-col>
        </b-row>

        <hr/>

        <wrapper title="all form elements example">
            <template v-slot:header>
                <div>
                    <b-button variant="outline-primary" class="mb-3 mr-3" v-on:click="testAllElementsDisabled = !testAllElementsDisabled">
                        <icon v-bind:type="testAllElementsDisabled ? 'confirm' : 'danger'"/> disabled
                    </b-button>
                    <b-button variant="outline-primary" class="mb-3 mr-3" v-on:click="testAllElementsError = !testAllElementsError">
                        <icon v-bind:type="testAllElementsError ? 'confirm' : 'danger'"/> error
                    </b-button>
                </div>
                <validations v-bind:errors="testAllFormElementErrorTexts" v-if="testAllFormElementErrorTexts.length"/>
            </template>
            <template v-slot>
                <formComponent v-bind:elements="testAllFormElements" v-bind:errors="testAllFormElementErrors"/>
                <hr>
                <formComponent v-bind:elements="testAllVselects" headline="vselects"/>
            </template>
        </wrapper>

        <hr/>

        <ul class="list-group">
            <li
                v-for="(item, i) in permissions"
                v-bind:key="i"
                class="list-group-item"
                v-bind:class="{
                    'list-group-item-success': item.permission && $isAllowed(item.permission.getFeature(), item.permission.getActions(), item.permission.getUserType()),
                    'list-group-item-danger': item.permission && !$isAllowed(item.permission.getFeature(), item.permission.getActions(), item.permission.getUserType()),
                    'list-group-item-warning': !item.permission || !item.permission.getFeature() || !item.permission.getActions().length,
                }"
            >
                <div class="h2 mb-0">{{ item.key }}</div>
                <div v-if="item.permission">
                    {{ item.permission.getFeature() }} {{ item.permission.getActions() }}
                    <span v-if="item.permission.getUserType()">{{ item.permission.getUserType() }}</span>
                </div>
                <div v-else>-</div>

                <ul class="list-group mt-2" v-if="item.permission && item.permission.getChildren().length">
                    <li
                        v-for="(subPermission, e) in item.permission.getChildren()"
                        v-bind:key="e"
                        class="list-group-item"
                        v-bind:class="{
                            'list-group-item-success': subPermission && $isAllowed(subPermission.getFeature(), subPermission.getActions(), subPermission.getUserType()),
                            'list-group-item-danger': subPermission && !$isAllowed(subPermission.getFeature(), subPermission.getActions(), subPermission.getUserType()),
                            'list-group-item-warning': !subPermission || !subPermission.getFeature() || !subPermission.getActions().length,
                        }"
                    >
                        <div v-if="subPermission">
                            {{ subPermission.getFeature() }} {{ subPermission.getActions() }}
                            <span v-if="subPermission.getUserType()">{{ subPermission.getUserType() }}</span>
                        </div>
                        <div v-else>-</div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import wrapper from '../components/wrapper.vue';
import list from '../components/list.vue';
import validations from '../components/validations.vue';
import icon from '../components/icon.vue';
import loading from '../components/loading.vue';

import medicRow from '../components/rows/medic.vue';
import formComponent from '@/components/form.vue';
import teamPickModal from '@/components/modals/pick/team.vue';

import MedicModel from '../models/person/medic.js';
import PermissionModel from '@/models/permission.js';

import permissions from '@/permissions.js';

export default {
    name: 'TestPage',
    components: {
        wrapper,
        list,
        validations,
        icon,
        loading,

        medicRow,

        formComponent,

        teamPickModal,
    },
    data(){
        const testPersons = {
            nr1: {
                id: 169335,
                nr: 17,
                firstname: 'Kitty',
                lastname: 'Cat',
                height: 23,
                weight: 8,
                age: 4,
                role: 'manager',
                team: 'teamA',
            },
            nr2: {
                id: 456,
                nr: 19,
                firstname: 'Another',
                lastname: 'Catini',
                height: 20,
                weight: 10,
                age: 15,
                role: 'goalkeeper',
                team: 'teamB',
            },
            nr3: {
                id: 84432,
                nr: 22,
                firstname: 'YetAnother',
                lastname: 'Catinger',
                height: 28,
                weight: 14,
                age: 22,
                role: 'manager',
                team: 'teamC',
            },
        };

        return {
            testPerson: testPersons.nr1,
            testPerson2: testPersons.nr2,
            testPerson3: testPersons.nr3,

            testList1: [],
            testList2: [],
            testList3: [],
            iconTypes: [
                'arrow-left',
                'arrow-right',
                'bell',
                'bin',
                'bin-circle',
                'bin-circle-full',
                'calendar',
                'cancel',
                'cancel-circle-full',
                'card',
                'cards',
                'chat',
                'citing',
                'clock',
                'confirm-circle-full',
                'cup',
                'down',
                'down-circle',
                'down-circle-full',
                'edit',
                'edit-circle',
                'edit-circle-full',
                'eye',
                'eye-circle',
                'eye-circle-full',
                'jersey',
                'left',
                'left-circle',
                'left-circle-full',
                'match',
                'menu',
                'minus',
                'minus-circle',
                'minus-circle-full',
                'player',
                'plus',
                'plus-circle',
                'plus-circle-full',
                'right',
                'right-circle',
                'right-circle-full',
                'search',
                'series',
                'union',
                'up',
                'up-circle',
                'up-circle-full',
                'warning-circle-full',
            ],
            iconAliases: [
                'card-red',
                'card-yellow',
                'card-none',
                'correct',
                'confirm',
                'success',
                'warning',
                'incorrect',
                'error',
                'danger',
                'add',
                'remove',
                'view',
            ],
            buttonVariants: [
                'primary',
                'secondary',
                'success',
                'warning',
                'danger',
                'outline-primary',
                'outline-secondary',
                'outline-success',
                'outline-warning',
                'outline-danger',
            ],
            loadItemsList: [
                new MedicModel(testPersons.nr1),
                new MedicModel(testPersons.nr2),
            ],
            loadItemsMore: [
                new MedicModel(testPersons.nr3),
                new MedicModel(testPersons.nr1),
                new MedicModel(testPersons.nr2),
            ],
            testAllElementsDisabled: false,
            testAllElementsError: false,
            loadingPromise: new Promise((resolve, reject) => { window.setTimeout(resolve, 4000); }),
            loadingPromiseError: new Promise((resolve, reject) => { window.setTimeout(reject, 5000); }),
        };
    },
    computed: {
        testAllVselects(){
            const types = [
                'vselectActionType',
                'vselectActionTypeSet',
                'vselectApiResourceMethod',
                'vselectCountry',
                'vselectKitColor',
                'vselectLocation',
                'vselectEventType',
                'vselectLaw',
                'vselectMatch',
                'vselectMatchOfficial',
                'vselectMatchStatus',
                'vselectMatchTeam',
                'vselectOfficial',
                'vselectOfficialPosition',
                'vselectParameterOrder',
                'vselectParameterPoint',
                'vselectPermissionGroup',
                'vselectPersonRole',
                'vselectPlayer',
                'vselectPosition',
                'vselectPublishingAccessLevel',
                'vselectRankingRun',
                'vselectSchool',
                'vselectSeries',
                'vselectSeriesType',
                'vselectSport',
                'vselectStandingType',
                'vselectStatusGroup',
                'vselectSubscriptionType',
                'vselectTeamKit',
                'vselectTournamentTeam',
                'vselectTeamType',
                'vselectTournament',
                'vselectTournamentStage',
                'vselectTournamentStatus',
                'vselectTournamentType',
                'vselectTournamentTypeGroup',
                'vselectUnion',
                'vselectUnionRegion',
                'vselectUserStatus',
                'vselectUserType',
            ];

            return [
                {
                    type: 'row',
                    props: {
                        cols: 1,
                        'cols-md': 2,
                        'cols-lg': 3,
                    },
                    cols: types.map(type => {
                        return {
                            elements: [
                                {
                                    id: 'vselect-example-' + type,
                                    type: type,
                                    label: type.replace('vselect', ''),
                                    disabled: this.testAllElementsDisabled,
                                    plaintext: this.testAllElementsDisabled,
                                },
                            ],
                        };
                    }),
                },
            ];
        },
        testAllFormElements(){
            const types = [
                'headline',
                'text',
                'password',
                'textarea',
                'email',
                'number',
                'search',
                'vselect',
                //'select',
                'date',
                'time',
                'file',
                'radioselect',
                'checkbox',
                'pickTeam',
                'row',
            ];

            let rowElement = null;
            const elements = types.map(type => {
                const config = {
                    id: 'example-' + type,
                    type: type,
                    label: type,
                    disabled: this.testAllElementsDisabled,
                    plaintext: this.testAllElementsDisabled,
                    required: true,
                };

                //vselect options
                if(['vselect'].includes(type)){
                    config.options = [
                        { id: 1, label: 'option 1' },
                        { id: 2, label: 'option 2' },
                        { id: 3, label: 'option 3' },
                    ];
                }

                //select options
                if(['select'].includes(type)){
                    config.options = {
                        1: 'option 1',
                        2: 'option 2',
                        3: 'option 3',
                    };
                }

                //radio options
                if(['radioselect'].includes(type)){
                    config.options = [
                        { value: 1, text: 'option 1' },
                        { value: 2, text: 'option 2' },
                        { value: 3, text: 'option 3 disabled', disabled: true },
                    ];
                    //config.default = 1;
                }

                if(['headline'].includes(type)){
                    config.text = 'Some headline text';
                    config.level = 2;
                }

                if(['checkbox'].includes(type)){
                    config.text = 'i am checkbox';
                }

                if(['pickTeam'].includes(type)){
                    config.modalTitle = 'demo picker';
                }

                if(['row'].includes(type)){
                    rowElement = config;
                    config.props = {
                        cols: 1,
                        'cols-md': 2,
                        'cols-lg': 3,
                    };
                    config.cols = [];
                }

                return config;
            });

            //add a copy of most elements to the row element
            const ignoreRowElementsFromType = ['headline', 'row'];
            rowElement.cols = elements.filter(element => !ignoreRowElementsFromType.includes(element.type)).map(element => ({
                elements: [Object.assign({}, element, { id: element.id + '-row' })],
            }));

            return elements;
        },
        testAllFormElementErrors(){
            if(!this.testAllElementsError){
                return [];
            }
            return Object.values(this.testAllFormElements.map(item => item.id));
        },
        testAllFormElementErrorTexts(){
            if(!this.testAllElementsError){
                return [];
            }
            const ignoreErrorsFromType = ['headline', 'row'];
            return Object.values(this.testAllFormElements.map(item => !ignoreErrorsFromType.includes(item.type) ? 'test error in ' + item.label : null)).filter(text => text);
        },
        permissions(){
            const toArray = (permissions, prefixes = []) => {
                let result = [];

                Object.entries(permissions).forEach(([key, data]) => {
                    if(data instanceof PermissionModel || data === null){
                        result.push({ key: prefixes.join('.') + '.' + key, permission: data });
                    }
                    else {
                        result = result.concat(toArray(data, [...prefixes, key]));
                    }
                });

                return result;
            };

            return toArray(permissions);
        },
    },
    methods: {
        loadItems(){
            //for testing
            return new Promise((resolve, reject) => {
                window.setTimeout(() => {
                    const items = this.loadItemsMore.splice(0, 2);
                    this.loadItemsList.push(...items);
                    if(this.loadItemsMore.length === 0) {
                        resolve(false);
                    }
                    resolve(items.length > 0);
                }, 1000);
            });
        },
        log(type, e){
            console.debug(type, e);
        },
        onClick(e){
            this.log('click', e);
        },
        createTestLists(){
            this.testList1.push(new MedicModel(this.testPerson));
            this.testList1.push(new MedicModel(this.testPerson2));
            this.testList1.push(new MedicModel(this.testPerson3));

            this.testList2.push(new MedicModel(this.testPerson));
            this.testList2.push(new MedicModel(this.testPerson2));
            this.testList2.push(new MedicModel(this.testPerson3));

            this.testList3.push(new MedicModel(this.testPerson));
            this.testList3.push(new MedicModel(this.testPerson2));
            this.testList3.push(new MedicModel(this.testPerson3));
        },
        listDrop(data){
            this.testList2.splice(data.targetIndex, 1, data.sourceItem);
        },
        listDropSwap(data){
            this.testList2.splice(data.sourceIndex, 1, data.targetItem);
            this.testList2.splice(data.targetIndex, 1, data.sourceItem);
        },
        listUpdate(items){
            this.testList1 = items;
        },
        restartLoadingPromiseError(){
            this.loadingPromiseError = new Promise((resolve, reject) => {
                window.setTimeout(reject, 5000);
            });
        },
        testSelectHandler(data){
            this.log('handler start', data);

            return new Promise((resolve, reject) => {
                window.setTimeout(() => {
                    const success = Math.random() > 0.5;

                    this.log('handler end', success);

                    if(success){
                        resolve();
                    }
                    else {
                        reject(Error('ups!'));
                    }
                }, 2000);
            });
        }
    },
    created(){
        this.createTestLists();
    },
}
</script>

<style lang="scss">
    hr {
        margin-top: 5rem;
        margin-bottom: 5rem;
        border-color: black;
        border-style: dashed;
    }
</style>
