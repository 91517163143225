<script>
import personRow from './person.vue';

import MedicModel from '@/models/person/medic.js';
import constants from '@/constants.js';

export default {
    name: 'medicRow',
    extends: personRow,
    props: {
        person: {
            type: MedicModel,
            default(){
                return new MedicModel();
            }
        },
    },
    data(){
        return {
            roleType: constants.group.medical,
        };
    },
}
</script>
