<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createPlace')"
            v-on:submit="createLocation"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toLocationList"
                    v-bind:loadingWhile="apiData.locationCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.locationCreation"
                >
                    {{ $t('createPlace') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createLocationForm
                    v-bind:headline="$t('placeInformation')"
                    v-bind:model="location"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import createLocationForm from '@/components/forms/createLocation.vue';

import LocationModel from '@/models/location.js';

export default {
    name: 'administrationLocationCreate',
    extends: base,
    components: {
        validations,
        createLocationForm,
    },
    data(){
        return {
            apiData: {
                locationCreation: null,
            },
            location: LocationModel.from({}),
            error: null,
        }
    },
    computed: {
        toLocationList(){
            return { name: 'administrationLocationIndex' };
        },
    },
    methods: {
        createLocation(){
            this.apiData.locationCreation = this.$api.call.location.locationCreate(this.location.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toLocationList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('location creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
