<template>
    <b-spinner v-bind="$props"></b-spinner>
</template>

<script>
/**
     * throbber component
     *
     * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
     */
export default {
    name: 'throbber',
    props: {
        /**
             * element boostrap variant
             */
        variant: {
            type: String,
            default: 'secondary',
        },
        /**
             * display small
             */
        small: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
