import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class UserService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/user-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // users

    /**
     *
     */
    usersGetByAuthenticationUsername(authenticationUsername){
        return this.get(['users', authenticationUsername]);
    }

    /**
     *
     */
    usersCreate(data){
        return this.post(['users'], data);
    }

    /**
     *
     */
    usersUpdateByAuthenticationUsername(authenticationUsername, data){
        return this.put(['users', authenticationUsername], data);
    }

    /**
     *
     */
    usersDeleteByAuthenticationUsername(authenticationUsername){
        return this.delete(['users', authenticationUsername]);
    }

    /**
     *
     */
    usersGetAllByUserType(userType){
        return this.get(['users', userType]);
    }

    // user type

    /**
     *
     */
    userTypeGetAll(){
        return this.get('user-type');
    }

    /**
     *
     */
    userTypeGetById(id){
        return this.get(['user-type', id]);
    }

    /**
     *
     */
    userTypeCreate(data){
        return this.post(['user-type'], data);
    }

    /**
     *
     */
    userTypeUpdateById(id, data){
        return this.put(['user-type', id], data);
    }

    /**
     *
     */
    userTypeDeleteById(id){
        return this.delete(['user-type', id]);
    }
}
