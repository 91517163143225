import Vue from 'vue';
import formatDate from './formatDate.js';
import routerTools from '@/router/tools.js';

/**
 * vue config instance handling
 */
Vue.mixin({
    beforeCreate(){
        const options = this.$options;
        if(options.config){
            this.$config = options.config;
        }
        else if(options.parent && options.parent.$config){
            this.$config = options.parent.$config;
        }
    }
});

/**
 * vue api instance handling
 */
Vue.mixin({
    beforeCreate(){
        const options = this.$options;
        if(options.api){
            this.$api = options.api;
        }
        else if(options.parent && options.parent.$api){
            this.$api = options.parent.$api;
        }
    }
});

/**
 * vue storage instance handling
 */
Vue.mixin({
    beforeCreate(){
        const options = this.$options;
        if(options.storage){
            this.$storage = options.storage;
        }
        else if(options.parent && options.parent.$storage){
            this.$storage = options.parent.$storage;
        }
    }
});

/**
 * vue log instance handling
 */
Vue.mixin({
    beforeCreate(){
        const options = this.$options;
        if(options.log){
            this.$log = options.log;
        }
        else if(options.parent && options.parent.$log){
            this.$log = options.parent.$log;
        }
    }
});

/**
 * vue auth instance handling
 */
Vue.mixin({
    beforeCreate(){
        const options = this.$options;
        if(options.auth){
            this.$auth = options.auth;
        }
        else if(options.parent && options.parent.$auth){
            this.$auth = options.parent.$auth;
        }
    }
});

/**
 * add date formating method to vue instances
 */
Vue.mixin({
    beforeCreate(){
        this.$fd = formatDate;
    }
});

/**
 * add $isAllowed, $areAllowed, $isRouteAllowed and $isRouteNameAllowed methods to vue instances to check for user access to features and routes
 */
const allowedFeature = {
    install(Vue, options, a, b, c){
        Vue.prototype.$isAllowed = function(feature, actions = [], userType = null){
            if(!this.$store || !this.$store.state.user){
                return false;
            }
            return this.$store.state.user.isAllowed(feature, actions, userType);
        };
        Vue.prototype.$areAllowed = function(permissions = []){
            if(!this.$store || !this.$store.state.user){
                return false;
            }
            return this.$store.state.user.areAllowed(permissions);
        };
        Vue.prototype.$isRouteAllowed = function(route){
            if(!this.$router){
                return false;
            }

            //get route information
            route = (this.$router.resolve(route) || {}).route;
            if(!route || !route.matched){
                return false;
            }

            //get route permissions
            const routePermissions = routerTools.getRoutePermissions(route);

            //check route permissions
            return (!routePermissions || this.$areAllowed(routePermissions.getWithChildren()));
        };
        Vue.prototype.$isRouteNameAllowed = function(routeName){
            return this.$isRouteAllowed({ name: routeName });
        };
    }
};
Vue.use(allowedFeature);
