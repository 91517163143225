import Model from '@/models/model.js';

/**
 * TournamentStage model class
 */
export default class TournamentStageModel extends Model {
    /**
     * id
     *
     * @type {number}
     */
    id = 0;

    /**
     * eqId
     *
     * @type {string}
     */
    eqId = '';

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * order
     *
     * @type {number}
     */
    order = 0;

    /**
     * abbreviation
     *
     * @type {string}
     */
    abbreviation = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.abbreviation = data.abbreviation || '';
            this.eqId = data.eqId || data.tournamentStageEqId || '';
            this.id = data.id || data.tournamentStageId || 0;
            this.name = data.name || '';
            this.order = data.order || 0;
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            abbreviation: this.abbreviation,
            name: this.name,
            order: this.order,
        };
    }
}
