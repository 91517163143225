import Model from '@/models/model.js';

/**
 * union region model class
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default class UserTypeModel extends Model {
    /**
     * description
     *
     * @type {string}
     */
    description = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.description = data.description || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.description;
    }
}
