<template>
    <div class="home">
        <wrapper v-bind:title="$t('home')" mainTitle noPadding noShadow/>

        <h2 class="h1">{{ $t('notifications') }}</h2>

        <div id="home-notification-list" class="mb-7">
            <div class="bg-primary px-4 py-3 notification-header">
                <div class="float-left"><h2 class="h1">{{ $tc('alert', 2) }}</h2></div>
                <div class="float-right">
                    <actionButton
                        type="button"
                        variant="primary"
                        class="my-2 my-sm-0 border-0 shadow-none"
                        v-bind:loadingWhile="apiData.removeNotifications"
                        v-on:click="removeAllNotifications"
                        v-if="hasNotifications"
                        loadingThrobber
                    >
                        {{ $t('removeAllAlerts') }}
                    </actionButton>
                </div>
            </div>
            <list
                v-bind:items="notifications"
                v-bind:waitFor="apiData.notifications"
            >
                <template v-slot="{ item: notification, role }">
                    <notificationRow
                        v-bind:notification="notification"
                        v-bind:role="role"
                        v-on:click="readNotification"
                    />
                </template>
                <template v-slot:empty>
                    <div class="bg-primary px-4 py-3">
                        {{ $t('noNotifications') }}
                    </div>
                </template>
            </list>
        </div>

        <h2 class="h1" v-if="hasAllowedDeclarations">{{ $t('latestDeclarations') }}</h2>

        <declarationMatchList
            v-bind:title="$t('matchDeclarations')"
            v-bind:statuses="declarationStatuses"
            v-bind:pageSizeLimit="declarationLimit"
            kickoffDateDirection="Desc"
            paginationSinglePage
            noItemsTotal
            noFilters
            v-if="$isRouteAllowed({ name: 'declarationMatchIndex' })"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'declarationMatchIndex' }"
                >
                    {{ $t('showAll') }}
                </actionButton>
            </template>
        </declarationMatchList>

        <reviewTournamentList
            v-bind:title="$t('tournamentMatchDeclarations')"
            v-bind:statuses="reviewStatuses"
            v-bind:pageSizeLimit="reviewLimit"
            deadlineDateOrder="Desc"
            isMatchReview
            paginationSinglePage
            noItemsTotal
            noFilters
            v-if="$isRouteAllowed({ name: 'reviewMatchHub' })"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'reviewMatchHub' }"
                >
                    {{ $t('showAll') }}
                </actionButton>
            </template>
        </reviewTournamentList>

        <declarationTournamentList
            v-bind:title="$t('tournamentDeclarations')"
            v-bind:statuses="declarationStatuses"
            v-bind:pageSizeLimit="declarationLimit"
            deadlineDateOrder="Desc"
            paginationSinglePage
            noItemsTotal
            noFilters
            v-if="$isRouteAllowed({ name: 'declarationTournamentIndex' })"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'declarationTournamentIndex' }"
                >
                    {{ $t('showAll') }}
                </actionButton>
            </template>
        </declarationTournamentList>

        <reviewTournamentList
            v-bind:title="$t('tournamentSquadDeclarations')"
            v-bind:statuses="reviewStatuses"
            v-bind:pageSizeLimit="reviewLimit"
            deadlineDateOrder="Desc"
            paginationSinglePage
            noItemsTotal
            noFilters
            v-if="$isRouteAllowed({ name: 'reviewTournamentHub' })"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'reviewTournamentHub' }"
                >
                    {{ $t('showAll') }}
                </actionButton>
            </template>
        </reviewTournamentList>

        <declarationSeriesList
            v-bind:title="$t('seriesDeclarations')"
            v-bind:statuses="declarationStatuses"
            v-bind:pageSizeLimit="declarationLimit"
            deadlineDateOrder="Desc"
            paginationSinglePage
            noItemsTotal
            noFilters
            v-if="$isRouteAllowed({ name: 'declarationSeriesIndex' })"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'declarationSeriesIndex' }"
                >
                    {{ $t('showAll') }}
                </actionButton>
            </template>
        </declarationSeriesList>

        <reviewSeriesList
            v-bind:title="$t('seriesDeclarations')"
            v-bind:statuses="reviewStatuses"
            v-bind:pageSizeLimit="reviewLimit"
            deadlineDateOrder="Desc"
            paginationSinglePage
            noItemsTotal
            noFilters
            v-if="$isRouteAllowed({ name: 'reviewSeriesHub' })"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'reviewSeriesHub' }"
                >
                    {{ $t('showAll') }}
                </actionButton>
            </template>
        </reviewSeriesList>

        <list
            v-bind:items="upcomingMatches"
            v-bind:title="$t('upcomingMatches')"
            v-bind:waitFor="apiData.upcomingMatches"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'commonMatchIndex' }"
                >
                    {{ $t('showAll') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <matchRow
                    showTournament showStage showKickOffTime showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: match, role }">
                <matchRow
                    showTournament showStage showKickOffTime showDetail
                    v-bind:match="match"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:items="upcomingTournaments"
            v-bind:title="$t('upcomingTournaments')"
            v-bind:waitFor="apiData.upcomingTournaments"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'commonTournamentIndex' }"
                >
                    {{ $t('showAll') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <tournamentRow
                    showTournament showSport showDateRange showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: tournament, role }">
                <tournamentRow
                    showTournament showSport showDateRange showDetail
                    v-bind:tournament="tournament"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:items="recentResultsMatches"
            v-bind:title="$t('recentResults')"
            v-bind:waitFor="apiData.recentResultsMatches"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat"
                    v-bind:to="{ name: 'commonMatchIndex' }"
                >
                    {{ $t('showAll') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <matchRow
                    showScore showTournament showStage showKickOffTime showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: match, role }">
                <matchRow
                    showScore showTournament showStage showKickOffTime showDetail
                    v-bind:match="match"
                    v-bind:role="role"
                />
            </template>
        </list>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import matchRow from '@/components/rows/match.vue';
import notificationRow from '@/components/rows/notification.vue';
import tournamentRow from '@/components/rows/tournament.vue';
import declarationSeriesList from '@/components/lists/declaration/series.vue';
import declarationTournamentList from '@/components/lists/declaration/tournament.vue';
import declarationMatchList from '@/components/lists/declaration/match.vue';
import reviewSeriesList from '@/components/lists/review/series.vue';
import reviewTournamentList from '@/components/lists/review/tournament.vue';

import MatchModel from '@/models/match.js';
import NotificationModel from '@/models/notification.js';
import TournamentModel from '@/models/tournament.js';

const dateCurrent = new Date();

export default {
    name: 'HomePage',
    extends: base,
    components: {
        declarationSeriesList,
        declarationMatchList,
        declarationTournamentList,
        reviewSeriesList,
        reviewTournamentList,
        matchRow,
        notificationRow,
        tournamentRow,
    },
    data(){
        return {
            rowProps: {
                showNumber: true,
                showWorkflowStatus: true,
                showWorkflowDeadline: true,
            },
            apiData: {
                upcomingMatches: this.loadUpcomingMatches(),
                upcomingTournaments: this.loadUpcomingTournaments(),
                recentResultsMatches: this.loadRecentResultsMatches(),
                notifications: this.loadNotifications(),
                removeNotifications: null,
            },
            upcomingMatches: [],
            upcomingTournaments: [],
            recentResultsMatches: [],
            notifications: [],
            declarationLimit: 5,
            reviewLimit: 10,
        };
    },
    computed: {
        declarationStatuses(){
            return [
                this.constants.declarationStatus.open,
                this.constants.declarationStatus.inProgress,
                this.constants.declarationStatus.submitted,
                this.constants.declarationStatus.rejected,
            ];
        },
        reviewStatuses(){
            return this.declarationStatuses;
        },
        hasAllowedDeclarations(){
            return (
                this.$isRouteAllowed({ name: 'declarationSeriesIndex' }) ||
                this.$isRouteAllowed({ name: 'declarationTournamentIndex' }) ||
                this.$isRouteAllowed({ name: 'declarationMatchIndex' }) ||
                this.$isRouteAllowed({ name: 'reviewSeriesHub' }) ||
                this.$isRouteAllowed({ name: 'reviewTournamentHub' }) ||
                this.$isRouteAllowed({ name: 'reviewMatchHub' })
            );
        },
        hasNotifications(){
            return this.notifications.length;
        },
    },
    methods: {
        loadUpcomingMatches(){
            return this.$api.call.match.matchSearch({
                dateFrom: dateCurrent,
                isResult: false,
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(results => {
                results.data.forEach(entry => {
                    const match = new MatchModel(entry);
                    if (entry.match && match.isNotStarted()) {
                        const kickOffDate = new Date(entry.match.kickOff);
                        if (kickOffDate > dateCurrent) {
                            this.upcomingMatches.push(match);
                        }
                    }
                });
                if (this.upcomingMatches.length > 5) {
                    this.upcomingMatches.length = 5;
                }
            });
        },
        loadUpcomingTournaments(){
            return this.$api.call.tournament.tournamentSearch({
                year: dateCurrent.getFullYear(),
                paging: {
                    pageIndex: 0,
                    pageSize: 50
                }
            }).then(results => {
                results.data.forEach(entry => {
                    const tournamentEndDate = new Date(entry.endDate);
                    if (tournamentEndDate > dateCurrent) {
                        this.upcomingTournaments.push(new TournamentModel(entry));
                    }
                });
                if (this.upcomingTournaments.length > 5) {
                    this.upcomingTournaments.length = 5;
                }
            });
        },
        loadRecentResultsMatches(){
            return this.$api.call.match.matchSearch({
                dateUntil: dateCurrent,
                isResult: true,
                kickoffDateDirection: 'Desc',
                paging: {
                    pageIndex: 0,
                    pageSize: 5
                }
            }).then(results => {
                results.data.forEach(entry => {
                    const match = new MatchModel(entry);
                    if (entry.match && match.isCompleted()) {
                        this.recentResultsMatches.push(match);
                    }
                });
                if (this.recentResultsMatches.length > 5) {
                    this.recentResultsMatches.length = 5;
                }
            });
        },
        loadNotifications(){
            return this.$api.call.notification.getAllNotifications().then(data => {
                this.notifications = (data || []).map(data => new NotificationModel(data)).sort((b, a) => a.timestamp - b.timestamp);
            });
        },
        readNotification(notificationData){
            if (!notificationData.notification.isRead){
                return this.$api.call.notification.updateNotification(notificationData.notification.id, {
                    isRead: true
                }).then(() => {
                    notificationData.notification.isRead = true;

                    this.$router.push(notificationData.notification.toRouterParamsJSON());
                });
            }
            else
            {
                this.$router.push(notificationData.notification.toRouterParamsJSON());
            }
        },
        removeAllNotifications(){
            return this.$api.call.notification.deleteAllNotifications().then(() => {
                this.$router.go(this.$router.currentRoute);
            });
        },
    },
}
</script>
