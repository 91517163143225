import Model from '@/models/model.js';
import ActionTypeSetModel from '@/models/actionTypeSet.js';
import MatchConditionModel from '@/models/matchCondition.js';
import MatchStatusModel from '@/models/matchStatus.js';
import MatchTeamModel from '@/models/matchTeam.js';
import StatusGroupModel from '@/models/statusGroup.js';
import TournamentModel from '@/models/tournament.js';
import VenueModel from '@/models/venue.js';
import { formatDateSimple } from '@/services/formatDate.js';
import constants from '../constants';

/**
 * match model class
 */
export default class MatchModel extends Model {
    /**
     * attendance
     *
     * @type {number|null}
     */
    attendance = null;

    /**
     * team 1 name
     *
     * @type {string}
     */
    teamOne = '';

    /**
     * team 2 name
     *
     * @type {string}
     */
    teamTwo = '';

    /**
     * tournaments
     *
     * @type {TournamentModel[]}
     */
    tournaments = [];

    /**
     * workflow status
     *
     * @type {string}
     */
    workflowStatus = '';

    /**
     * workflow deadline
     *
     * @type {string}
     */
    workflowDeadline = '';

    /**
     * mapping providers
     *
     * @type {array}
     */
    mappingProviders = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}, tournamentId = null){
        super(data);

        if(data){
            this.id = data.id || data.matchId || ((data.match) ? data.match.matchId : 0) || 0;
            this.eqId = data.eqId || data.matchEqId || (data.match ? data.match.eqId : '') || '';
            this.kickOff = this.toDate(data.kickOff) || (data.match ? this.toDate(data.match.kickOff) : null) || null;
            this.attendance = data.attendance || null;
            this.isUseWin = data.isUseWin || false;
            this.isManualScore = data.isManualScore || false;
            this.isTestForBothTeams = data.isTestForBothTeams || false;
            this.isExcludedFromRankings = data.isExcludedFromWorldRankings || data.isExcludedFromRankings || false;
            this.isCommissionersReportReceived = data.isCommissionersReportReceived || false;
            this.equivalentReport = data.equivalentReport || data.isEquivalentReportReceived || false;
            this.notes = data.notes || '';
            this.displayNotes = data.displayNotes || '';
            this.ticketUrl = data.ticketUrl || '';
            this.level = data.level || (data.match ? data.match.level : 0) || data.coverageLevel || 0;
            this.sport = data.sport || '';
            this.matchStatus = MatchStatusModel.from((typeof data.matchStatus === 'object' && data.matchStatus !== null) ? data.matchStatus : {
                id: data.matchStatusId || 0,
                name: data.matchStatus || data.match?.matchStatus || '',
            });
            this.venue = data.venue ? VenueModel.from(data.venue) : null;
            this.statusGroup = data.statusGroup ? StatusGroupModel.from(data.statusGroup) : {};
            this.actionTypeSet = data.actionTypeSet ? ActionTypeSetModel.from(data.actionTypeSet) : {};
            this.publishStatus = data.publishStatus || (data.match ? data.match.publishStatus : '') || '';
            this.teams = (data.teams || []).map(data => MatchTeamModel.from(data));
            this.matchCondition = data.matchCondition ? MatchConditionModel.from(data.matchCondition) : {};
            this.matchClock = data.matchClock || (data.match ? data.match.matchClock : '') || '';

            //data from match search
            this.venueId = (data.match ? data.match.venueId : 0) || (data.venue ? data.venue.id : 0) || 0;
            this.venueAliasEqId = data.venueAliasEqId || (data.match ? data.match.venueAliasEqId : '') || '';
            this.venueEqId = data.venueEqId || (data.venue ? data.venue.venueEqId : '') || '';
            this.venueName = data.venueName || (data.venue ? data.venue.venueName : '') || '';
            this.locationName = data.locationName || (data.venue ? data.venue.locationName : '') || '';
            this.countryCode = data.countryCode || (data.venue ? data.venue.countryCode : '') || '';
            this.countryName = data.countryName || (data.venue ? data.venue.countryName : '') || '';
            this.kickOffTime = data.kickOffTime || (data.match ? data.match.kickOffTime : '') || this.fromDate(this.kickOff)?.match(/\d{2}:\d{2}:\d{2}/)?.shift() || '';
            this.gmtOffset = this.toDateOffset(data.kickOff) || 0;
            this.matchStatusId = (this.matchStatus.id ? this.matchStatus.id : 0) || 0;
            this.teamOne = data.team1 ? new MatchTeamModel(data.team1) : null;
            this.teamTwo = data.team2 ? new MatchTeamModel(data.team2) : null;

            if (this.teamOne) {
                this.teamOneScore = this.teamOne.score || 0;
            }
            if (this.teamTwo) {
                this.teamTwoScore = this.teamTwo.score || 0;
            }

            this.tournaments = (data.tournaments || []).map(tournament => TournamentModel.from(tournament));

            //data from match tournament
            this.tournament = data.tournament ? TournamentModel.from(data.tournament) : null;
            this.setGroup(data, tournamentId);
            this.setMatchNumber(data, tournamentId);
            this.setStageName(tournamentId);
            this.setTournamentName(tournamentId);

            this.tournamentStageEqId = data.tournamentStageEqId || '';
            this.actionTypeSetId = (this.actionTypeSet.id ? this.actionTypeSet.id : 0) || data.actionTypeSetId || 0;
            this.pitchConditions = data.pitchConditions || (data.matchCondition ? data.matchCondition.pitchConditions : '') || '';
            this.windConditions = data.windConditions || (data.matchCondition ? data.matchCondition.windConditions : '') || '';
            this.weather = data.weather || (data.matchCondition ? data.matchCondition.weather : '') || '';
            this.minTemperature = data.minimumTemperature || (data.matchCondition ? data.matchCondition.minimumTemperature : '') || data.minTemperature || 0;
            this.maxTemperature = data.maximumTemperature || (data.matchCondition ? data.matchCondition.maximumTemperature : '') || data.maxTemperature || 0;
            this.publishStatusOffset = data.publishStatusOffset || null;
            this.teamSheetsPublishStatusOffset = data.teamSheetsPublishStatusOffset || null;

            this.statusGroupId = (this.statusGroup.id ? this.statusGroup.id : 0) || data.statusGroupId || 0;

            if (data.teams) {
                data.teams.forEach(team => {
                    if (team.isTeam1) {
                        this.teamOne = new MatchTeamModel(team);
                        this.teamOneScore = team.score || 0;
                    }
                    if (!team.isTeam1) {
                        this.teamTwo = new MatchTeamModel(team);
                        this.teamTwoScore = team.score || 0;
                    }
                });
            }

            this.team1 = this.teamOne?.team || {};
            this.team2 = this.teamTwo?.team || {};
            this.teamOneDisplayName = data.teamOneDisplayName || this.teamOne?.displayOverride || this.teamOne?.name || this.teamOne?.team?.teamCode || this.team1?.name || '';
            this.teamOneDisplayOverride = this.teamOne ? this.teamOne.displayOverride : '';
            this.teamOneHomeTeam = data.teamOneHomeTeam || (this.teamOne ? this.teamOne.isPlayingAtHome : false) || false;
            this.teamOneTestMatch = data.teamOneTestMatch || (this.teamOne ? this.teamOne.isTest : false) || false;
            this.teamOneResult = (this.teamOne ? this.teamOne.result : 'Draw') || data.teamOneResult || 'Draw';
            this.teamOneScore = data.teamOneScore || this.teamOneScore || 0;

            this.teamTwoDisplayName = data.teamTwoDisplayName || this.teamTwo?.displayOverride || this.teamTwo?.name || this.teamTwo?.team?.teamCode || this.team2?.name || '';
            this.teamTwoDisplayOverride = this.teamTwo ? this.teamTwo.displayOverride : '';
            this.teamTwoHomeTeam = data.teamTwoHomeTeam || (this.teamTwo ? this.teamTwo.isPlayingAtHome : false) || false;
            this.teamTwoTestMatch = data.teamTwoTestMatch || (this.teamTwo ? this.teamTwo.isTest : false) || false;
            this.teamTwoResult = (this.teamTwo ? this.teamTwo.result : 'Draw') || data.teamTwoResult || 'Draw';
            this.teamTwoScore = data.teamTwoScore || this.teamTwoScore || 0;

            // creating a match from the tournament detail page allows a tournament team to be selected
            this.team1TeamAliasEqId = null;
            this.team2TeamAliasEqId = null;

            this.tournamentName = data.tournamentName || (this.tournament ? this.tournament.name : '') || '';
            this.tournamentEqId = data.tournamentEqId || (this.tournament ? this.tournament.eqId : '') || '';

            this.workflowStatus = data.workflowStatus || this.fakeWorkflowStatus();
            this.workflowDeadline = this.toDate(data.workflowDeadline);

            this.applyTermsOfParticipationRegulations = data.applyTermsOfParticipationRegulations || false;
        }
    }

    setGroup(data, tournamentId){
        const groupFromTournament = this.tournament?.group || null;
        const tournamentTypeGroupFromTournament = this.tournament?.groupName || null;
        const groupFromMultipleTournaments = (tournamentId && this.tournaments.length) ? this.tournaments.find((t) => t.id === tournamentId)?.group : '';
        const tournamentTypeGroupFromMultipleTournaments = (tournamentId && this.tournaments.length) ? this.tournaments.find((t) => t.id === tournamentId)?.groupName : '';
        const groupFromSingleTournament = this.tournaments.length === 1 ? this.tournaments[0].group : '';
        const tournamentTypeGroupFromSingleTournament = this.tournaments.length === 1 ? this.tournaments[0].groupName : '';

        this.group = data.group ||
            groupFromTournament ||
            tournamentTypeGroupFromTournament ||
            groupFromMultipleTournaments ||
            tournamentTypeGroupFromMultipleTournaments ||
            groupFromSingleTournament ||
            tournamentTypeGroupFromSingleTournament ||
            '';
    }

    setMatchNumber(data, tournamentId){
        const fromTournament = this.tournament?.matchNumber || null;
        const fromMultipleTournaments = (tournamentId && this.tournaments.length) ? this.tournaments.find((t) => t.id === tournamentId)?.matchNumber : null;
        const fromMatchStatus = this.matchStatus?.order || null;
        const fromSingleTournament = this.tournaments.length === 1 ? this.tournaments[0]?.matchNumber : null;

        this.matchNumber =
            data.matchNumber ||
            fromTournament ||
            fromMultipleTournaments ||
            fromMatchStatus ||
            fromSingleTournament ||
            null;
    }

    setStageName(tournamentId){
        const fromTournament = this.tournament?.tournamentStageName || '';
        const fromMultipleTournaments = (tournamentId && this.tournaments.length) ? this.tournaments.find((t) => t.id === tournamentId)?.tournamentStageName : '';
        const fromSingleTournament = this.tournaments.length === 1 ? this.tournaments[0]?.tournamentStageName : '';

        this.stageName = fromTournament || fromMultipleTournaments || fromSingleTournament || '';
    }

    setTournamentName(tournamentId){
        const fromTournament = this.tournament?.name || '';
        const fromMultipleTournaments = (tournamentId && this.tournaments.length) ? this.tournaments.find((t) => t.id === tournamentId)?.name : '';
        const fromSingleTournament = this.tournaments.length === 1 ? this.tournaments[0]?.name : '';

        this.tournamentName = fromTournament || fromMultipleTournaments || fromSingleTournament || '';
    }

    getTeam1(teams){
        return teams?.find(team => team.isTeam1 === true) || null;
    }

    getTeam2(teams){
        return teams?.find(team => team.isTeam1 === false) || null;
    }

    fakeWorkflowStatus(){
        const array = ['open', 'progress', 'complete'];
        return array[Math.floor(Math.random() * array.length)];
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPathTeamOne(){
        if (this.teamOne && this.teamOne.team && this.teamOne.team.logo) {
            return this.teamOne.team.logo;
        }
        return require('@/assets/img/union.svg');
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPathTeamTwo(){
        if (this.teamTwo && this.teamTwo.team && this.teamTwo.team.logo) {
            return this.teamTwo.team.logo;
        }
        return require('@/assets/img/union.svg');
    }

    /**
     * get tournament image path
     *
     * @returns {string}
     */
    getTournamentImgPath(){
        if (this.tournament && this.tournament.logo) {
            return this.tournament.logo;
        }

        return this.tournaments.find(tournament => tournament.logo)?.logo || require('@/assets/img/tournament.svg');
    }

    toJSON() {
        const toSave = {};

        let kickOffDate = '';
        let kickOffTime = '';
        let kickOffTimeDifference = '';

        if (this.kickOff && this.kickOffTime && !isNaN(parseInt(this.gmtOffset, 10))) {
            const year = this.kickOff.getFullYear();
            const month = ('0' + (this.kickOff.getMonth() + 1)).slice(-2);
            const date = ('0' + this.kickOff.getDate()).slice(-2);
            const fullDate = year + '-' + month + '-' + date;

            kickOffDate = fullDate + 'T00:00:00.0000Z';
            kickOffTime = fullDate + 'T' + this.kickOffTime + '.0000Z';
            kickOffTimeDifference = this.gmtOffset;
        }

        toSave.match = {
            sport: this.sport,
            kickOffDate: kickOffDate,
            actionTypeSetId: this.actionTypeSetId,
            matchStatusGroupId: this.statusGroupId,
            matchStatusId: this.matchStatusId,
            kickOffTime: kickOffTime,
            kickOffTimeDifference: kickOffTimeDifference,
            isForceResult: this.isUseWin,
            isManualScore: this.isManualScore,
            notes: this.notes,
            displayNotes: this.displayNotes,
            coverageLevel: this.level,
            ticketUrl: this.ticketUrl,
            isExcludedFromRankings: this.isExcludedFromRankings,
            isCommissionersReportReceived: this.isCommissionersReportReceived,
            isEquivalentReportReceived: this.equivalentReport,
            publishStatusOffset: this.publishStatusOffset,
            teamSheetsPublishStatusOffset: this.teamSheetsPublishStatusOffset,
        };
        let teamOneAliasEqId = '';
        if (this.team1 && this.team1.teamAliasEqId) {
            teamOneAliasEqId = this.team1.teamAliasEqId;
        }
        if (this.team1TeamAliasEqId) {
            teamOneAliasEqId = this.team1TeamAliasEqId;
        }
        if (this.teamOne && this.teamOne.teamAliasEqId) {
            teamOneAliasEqId = this.teamOne.teamAliasEqId;
        }
        toSave.team1 = {
            teamAliasEqId: teamOneAliasEqId || null,
            displayName: this.teamOneDisplayOverride || null,
            result: this.isUseWin ? this.teamOneResult : 'Draw',
            isHomeTeam: this.teamOneHomeTeam,
            isTestMatch: this.teamOneTestMatch,
        }

        if (this.isManualScore) {
            toSave.team1.score = this.teamOneScore;
        }

        let teamTwoAliasEqId = '';
        if (this.team2 && this.team2.teamAliasEqId) {
            teamTwoAliasEqId = this.team2.teamAliasEqId;
        }
        if (this.team2TeamAliasEqId) {
            teamTwoAliasEqId = this.team2TeamAliasEqId;
        }
        if (this.teamTwo && this.teamTwo.teamAliasEqId) {
            teamTwoAliasEqId = this.teamTwo.teamAliasEqId;
        }
        toSave.team2 = {
            teamAliasEqId: teamTwoAliasEqId || null,
            displayName: this.teamTwoDisplayOverride || null,
            result: this.isUseWin ? this.teamTwoResult : 'Draw',
            isHomeTeam: this.teamTwoHomeTeam,
            isTestMatch: this.teamTwoTestMatch,
        }

        if (this.isManualScore) {
            toSave.team2.score = this.teamTwoScore;
        }
        if (this.venue && this.venue.id) {
            toSave.venue = {
                venueAliasEqId: this.venue.venueAliasEqId || '',
                attendance: this.attendance,
                weather: this.weather,
                pitchConditions: this.pitchConditions,
                windConditions: this.windConditions,
                minimumTemperature: this.minTemperature,
                maximumTemperature: this.maxTemperature,
            }
        }
        if (this.tournament) {
            toSave.tournament = {
                tournamentEqId: this.tournament.eqId || null,
                tournamentStageEqId: this.tournamentStageEqId || null,
                group: this.group || null,
                matchNumber: this.matchNumber,
            }
        }

        return toSave;
    }

    buildMappingPayloads() {
        if (!this.mappingProviders || !this.mappingProviders.length){
            return [];
        }

        return this.mappingProviders.map(mappingProvider => ({
            provider: mappingProvider.name,
            entity: constants.mappingEntityNames.match,
            id: this[mappingProvider.name.toLowerCase()],
            eqId: this.eqId,
        }));
    }

    /**
     * get team one name
     *
     * @returns {string}
     */
    getTeamOneName() {
        return this.teamOne?.displayOverride || this.teamOne?.name || '';
    }

    /**
     * get team two name
     *
     * @returns {string}
     */
    getTeamTwoName(){
        return this.teamTwo?.displayOverride || this.teamTwo?.name || '';
    }

    isNotStarted(){
        return this.matchStatus.name === 'Match Not Started';
    }

    isCompleted(){
        return this.matchStatus.name === 'Match Completed';
    }

    isLive() {
        if (this.matchStatus.name.toLowerCase().trim() === 'full time') {
            return false;
        }

        const liveStatuses = ['half', 'time', 'death', 'kicking'];
        for(const word of liveStatuses) {
            if (this.matchStatus.name.toLowerCase().trim().includes(word)) {
                return true;
            }
        };

        return false;
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        let date = '';
        if(this.kickOff){
            date = (vue ? vue.$fd(this.kickOff) : formatDateSimple(this.kickOff)) + ' - ';
        }

        return date + this.getTeamOneName() + ' v ' + this.getTeamTwoName();
    }
}
