<script>
import commonMatchCreate from '@/views/common/match/create.vue';

import TournamentModel from '@/models/tournament.js';
import MatchModel from '@/models/match.js';

export default {
    name: 'commonTournamentMatchCreate',
    extends: commonMatchCreate,
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            tournament: null,
            showTournamentTeamSelectors: false,
        };
    },
    computed: {
        toMatchList(){
            return { name: 'commonTournamentDetail', params: { dataTournamentId: this.dataTournamentId } };
        },
    },
    methods: {
        loadTournament(){
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId).then(data => {
                this.tournament = new TournamentModel(data);

                this.$api.call.tournament.tournamentTeamsGetAllByTournamentId(this.dataTournamentId).then(tournamentTeams => {
                    if (tournamentTeams && tournamentTeams.teams && tournamentTeams.teams.length > 1) {
                        this.showTournamentTeamSelectors = true;
                    }
                });
            });
        },
        loadDataInit(){
            return this.loadTournament().then(() => {
                this.match = MatchModel.from({
                    sport: this.tournament.sport,
                    tournament: this.tournament,
                    teamOneHomeTeam: true,
                    matchStatusId: 4, // default matchNotStarted: id=4
                });

                return this.loadEntityMappingProviders(this.match, 'match');
            });
        },
    },
};
</script>
