<script>
import formComponent from '@/components/form.vue';
import MatchModel from '@/models/match.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'createEditMatchForm',
    extends: formComponent,
    props: {
        model: {
            type: MatchModel,
        },
        hideTournamentAssignment: {
            type: Boolean,
            default: false,
        },
        showTournamentTeamSelectors: {
            type: Boolean,
            default: false,
        },
        hideMapping: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            dataModel: this.model,
        };
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'sport',
                        type: 'vselectSport',
                        label: this.$tc('sport', 1),
                        placeholder: this.$t('selectSport'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'actionTypeSetId',
                        type: 'vselectActionTypeSet',
                        label: this.$t('actionTypeSet'),
                        placeholder: this.$t('selectActionTypeSet'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'statusGroupId',
                        type: 'vselectStatusGroup',
                        label: this.$t('matchStatusGroup'),
                        placeholder: this.$t('selectMatchStatusGroup'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'matchStatusId',
                        type: 'vselectMatchStatus',
                        label: this.$t('matchStatus'),
                        placeholder: this.$t('selectMatchStatus'),
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
                {
                    type: 'hline',
                },
                {
                    id: 'matchInformation',
                    type: 'headline',
                    text: this.$t('matchInformation'),
                },
                this.createFormRow([
                    {
                        id: 'kickOff',
                        type: 'date',
                        label: this.$t('kickOffDate'),
                        placeholder: constants.dateFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'kickOffTime',
                        type: 'time',
                        label: this.$t('kickOffTime'),
                        placeholder: constants.timeFormat,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'gmtOffset',
                        type: 'select',
                        label: this.$t('gmtOffset'),
                        options: constants.gmtOffsets,
                        placeholder: this.$t('select'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'isManualScore',
                        type: 'checkbox',
                        label: this.$t('matchWillBeScoredManually'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'isUseWin',
                        type: 'checkbox',
                        label: this.$t('matchResultWillBeForced'),
                        disabled: this.readonly,
                    },
                ]),

                {
                    type: 'hline',
                },
                {
                    id: 'opponents',
                    type: 'headline',
                    text: this.$t('opponents'),
                },
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            id: 'team1headline',
                            type: 'headline',
                            text: this.$tc('team', 1) + ' 1',
                        },
                        {
                            id: 'teamOne',
                            type: 'pickTeam',
                            modalTitle: this.$t('selectTeam'),
                            label: this.$tc('team', 1),
                            disabled: this.readonly || !this.model.kickOff,
                            hidden: this.showTournamentTeamSelectors,
                            filterValues: {
                                sport: this.model.sport,
                                aliasDate: this.getAliasDate,
                            },
                            filterDisableElements: {
                                sport: Boolean(this.model.sport),
                            },
                        },
                        {
                            id: 'team1TeamAliasEqId',
                            type: 'vselectTournamentTeam',
                            label: this.$tc('team', 1),
                            valueProperty: 'teamAliasEqId',
                            disabled: this.readonly,
                            tournamentId: this.model.tournament ? this.model.tournament.id : 0,
                            dependency: 'tournament !== null',
                            hidden: !this.showTournamentTeamSelectors,
                        },
                        {
                            id: 'teamOneDisplayOverride',
                            type: 'text',
                            label: this.$t('displayName'),
                            placeholder: this.$t('enterAlternativeTeamName'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        ...this.createToggleable({
                            id: 'teamOneScore',
                            type: 'number',
                            label: this.$t('score'),
                            placeholder: '0',
                            plaintext: this.readonly,
                            disabled: this.readonly,
                            required: true,
                        }, 'isManualScore == "1"', 'isManualScore != "1"'),
                        ...this.createToggleable({
                            id: 'teamOneResult',
                            type: 'vselect',
                            options: [
                                { id: 'Win', label: this.$t('win') },
                                { id: 'Lose', label: this.$t('lose') },
                                { id: 'Draw', label: this.$tc('draw', 1) },
                            ],
                            label: this.$t('result'),
                            placeholder: this.$t('select'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                            required: true,
                        }, 'isUseWin == "1"', 'isUseWin != "1"'),
                        {
                            id: 'teamOneHomeTeam',
                            type: 'checkbox',
                            label: this.$t('homeTeam'),
                            text: this.$t('thisTeamIsTheHomeTeam'),
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamOneTestMatch',
                            type: 'checkbox',
                            label: this.$t('testMatch'),
                            text: this.$t('testMatch'),
                            disabled: this.readonly,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            id: 'team2headline',
                            type: 'headline',
                            text: this.$tc('team', 1) + ' 2',
                        },
                        {
                            id: 'teamTwo',
                            type: 'pickTeam',
                            modalTitle: this.$t('selectTeam'),
                            label: this.$tc('team', 1),
                            disabled: this.readonly || !this.model.kickOff,
                            hidden: this.showTournamentTeamSelectors,
                            filterValues: {
                                sport: this.model.sport,
                                aliasDate: this.getAliasDate,
                            },
                            filterDisableElements: {
                                sport: Boolean(this.model.sport),
                            },
                        },
                        {
                            id: 'team2TeamAliasEqId',
                            type: 'vselectTournamentTeam',
                            label: this.$tc('team', 1),
                            valueProperty: 'teamAliasEqId',
                            disabled: this.readonly,
                            tournamentId: this.model.tournament ? this.model.tournament.id : 0,
                            dependency: 'tournament !== null',
                            hidden: !this.showTournamentTeamSelectors,
                        },
                        {
                            id: 'teamTwoDisplayOverride',
                            type: 'text',
                            label: this.$t('displayName'),
                            placeholder: this.$t('enterAlternativeTeamName'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        ...this.createToggleable({
                            id: 'teamTwoScore',
                            type: 'number',
                            label: this.$t('score'),
                            placeholder: '0',
                            plaintext: this.readonly,
                            disabled: this.readonly,
                            required: true,
                        }, 'isManualScore == "1"', 'isManualScore != "1"'),
                        ...this.createToggleable({
                            id: 'teamTwoResult',
                            type: 'vselect',
                            options: [
                                { id: 'Win', label: this.$t('win') },
                                { id: 'Lose', label: this.$t('lose') },
                                { id: 'Draw', label: this.$tc('draw', 1) },
                            ],
                            label: this.$t('result'),
                            placeholder: this.$t('select'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                            required: true,
                        }, 'isUseWin == "1"', 'isUseWin != "1"'),
                        {
                            id: 'teamTwoHomeTeam',
                            type: 'checkbox',
                            label: this.$t('homeTeam'),
                            text: this.$t('thisTeamIsTheHomeTeam'),
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamTwoTestMatch',
                            type: 'checkbox',
                            label: this.$t('testMatch'),
                            text: this.$t('testMatch'),
                            disabled: this.readonly,
                        },
                    ]),
                ]),

                {
                    type: 'hline',
                },
                {
                    id: 'venueInformation',
                    type: 'headline',
                    text: this.$t('venueInformation'),
                },
                this.createFormRow([
                    ...this.createToggleable({
                        id: 'venue',
                        type: 'pickVenue',
                        modalTitle: this.$t('selectVenue'),
                        label: this.$tc('venue', 1),
                        disabled: this.readonly,
                        filterValues: {
                            aliasDate: this.getAliasDate,
                        },
                    }, 'Boolean(kickOff)', '!Boolean(kickOff)'),
                    {
                        id: 'attendance',
                        type: 'number',
                        label: this.$t('attendance'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'weather',
                        type: 'text',
                        label: this.$t('weather'),
                        placeholder: this.$t('enterWeatherInformation'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'pitchConditions',
                        type: 'text',
                        label: this.$t('pitchConditions'),
                        placeholder: this.$t('enterPitchConditions'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'windConditions',
                        type: 'text',
                        label: this.$t('windConditions'),
                        placeholder: this.$t('enterWindConditions'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'maxTemperature',
                        type: 'number',
                        label: this.$t('maxTemperature'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'minTemperature',
                        type: 'number',
                        label: this.$t('minTemperature'),
                        disabled: this.readonly,
                    },
                ]),

                {
                    type: 'hline',
                },
                {
                    id: 'additionalMatchInformation',
                    type: 'headline',
                    text: this.$t('additionalMatchInformation'),
                },
                this.createFormRow([
                    {
                        id: 'notes',
                        type: 'textarea',
                        label: this.$t('notes'),
                        placeholder: this.$t('addNotes'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'displayNotes',
                        type: 'textarea',
                        label: this.$t('displayNotes'),
                        placeholder: this.$t('addNotes'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'isCommissionersReportReceived',
                        type: 'checkbox',
                        label: this.$t('officialReport'),
                        text: this.$t('matchHasAnOfficialReport'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'equivalentReport',
                        type: 'checkbox',
                        label: this.$t('equivalentReport'),
                        text: this.$t('matchHasAnEquivalentReport'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'level',
                        type: 'vselect',
                        options: [
                            { id: 0, label: this.$t('noData') },
                            { id: 1, label: this.$t('score') },
                            { id: 2, label: this.$t('teamSheetsAndActions') },
                            { id: 3, label: this.$t('everything') },
                        ],
                        placeholder: this.$t('selectCoverageLevel'),
                        label: this.$t('coverageLevel'),
                    },
                    {
                        id: 'ticketUrl',
                        type: 'text',
                        label: this.$t('ticketUrl'),
                        placeholder: this.$t('enterTicketUrl'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'isExcludedFromRankings',
                        type: 'checkbox',
                        label: this.$t('rankings'),
                        text: this.$t('excludeThisMatchFromRankings'),
                        disabled: this.readonly,
                    },
                ]),

                {
                    type: 'hline',
                    hidden: this.hideTournamentAssignment,
                },
                {
                    id: 'tournamentAssignment',
                    type: 'headline',
                    text: this.$t('tournamentAssignment'),
                    hidden: this.hideTournamentAssignment,
                },
                this.createFormRow([
                    {
                        id: 'tournament',
                        type: 'pickTournament',
                        modalTitle: this.$t('selectTournament'),
                        label: this.$tc('tournament', 1),
                        disabled: this.readonly,
                        hidden: this.hideTournamentAssignment,
                    },
                    {
                        id: 'tournamentStageEqId',
                        type: 'vselectTournamentStage',
                        placeholder: this.$t('selectTournamentStage'),
                        label: this.$t('tournamentStage'),
                        valueProperty: 'eqId',
                        hidden: this.hideTournamentAssignment,
                    },
                    {
                        id: 'group',
                        type: 'text',
                        label: this.$t('group'),
                        placeholder: this.$t('enterGroup'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        hidden: this.hideTournamentAssignment,
                    },
                    {
                        id: 'matchNumber',
                        type: 'number',
                        label: this.$t('matchNumber'),
                        disabled: this.readonly,
                        hidden: this.hideTournamentAssignment,
                    },
                ]),

                {
                    type: 'hline',
                    hidden: this.hideMapping,
                },
                {
                    id: 'mapping',
                    type: 'headline',
                    text: this.$t('mapping'),
                    hidden: this.hideMapping,
                },
                this.createFormRow(this.generateMappingProviderControls(), null, this.hideMapping),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                kickOffDate: 'kickOff',
                matchStatusGroupId: 'statusGroupId',
            };
        },
        getAliasDate(){
            if (this.model.kickOff){
                let today = new Date();
                today.setHours(0, 0, 0, 0);

                if (this.model.kickOff < today){
                    return this.model.kickOff;
                }
            }

            return null;
        },
    },
    watch: {
        'dataModel.sport': {
            handler: function () {
                this.model.teamOne = null;
                this.model.teamTwo = null;
            },
        },
        'dataModel.kickOff': {
            handler: function () {
                this.model.teamOne = null;
                this.model.teamTwo = null;
                this.model.venue = null;
            },
        },
    },
};
</script>
