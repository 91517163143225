<template>
    <span v-bind:class="elementClasses">{{ config.icon }}</span>
</template>

<script>
import base from './base.vue';

/**
 * icon element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'iconElement',
    extends: base,
};
</script>
