<script>
import vselectElement from '../vselect.vue';
import constants from '@/constants';

/**
 * vselect element for Publishing Access Level, values from constants publishingAccessLevels
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default {
    name: 'vselectPublishingAccessLevelElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};
            config.valueProperty = 'label';

            const publishingAccessLevelItems = [];
            const keys = Object.keys(constants.publishingAccessLevels);

            keys.forEach((key) => {
                publishingAccessLevelItems.push({
                    id: key,
                    label: constants.publishingAccessLevels[key]
                });
            });

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return publishingAccessLevelItems;
            };

            return config;
        },
    },
};
</script>
