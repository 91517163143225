import Model from '@/models/model.js';

/**
 * union model class
 */
export default class NotificationModel extends Model {
    /**
     * user eq id
     *
     * @type {string}
     */
     userEqId = '';

     /**
     * time stamp
     *
     * @type {Date}
     */
     timestamp = '';

     /**
     * is read
     *
     * @type {boolean}
     */
     isRead = false;

     /**
     * entity type
     *
     * @type {string}
     */
     entityType = '';

     /**
     * entity eq id
     *
     * @type {string}
     */
     entityEqId = '';

     /**
     * message
     *
     * @type {string}
     */
     message = '';

     /**
     * router parameters
     *
     * @type {object}
     */
     routerParams = {};

     /**
     * entity type name to router config target paths
     * entity type names with removed dashes converted to camel case will equal the locales reference
     *
     * @type {object}
     */
    entityTypeRouterTargets = {
        MatchDeclaration: 'declarationMatchDetail',
        SeriesDeclaration: 'declarationSeriesDetail',
        TournamentDeclaration: 'declarationTournamentDetail',
        MatchReview: 'reviewMatchDetail',
        SeriesReview: 'reviewSeriesDetail',
        TournamentReview: 'reviewTournamentDetail',
    };

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.notificationId || null;
            this.userEqId = data.userEqId || '';
            this.timestamp = data.timestamp ? this.toDate(data.timestamp) : null;
            this.isRead = data.isRead || false;
            this.entityType = data.entityType || '';
            this.entityEqId = data.entityEqId || '';
            this.message = data.message || '';

            if (data.data){
                try {
                    this.routerParams = JSON.parse(data.data);
                } catch (error) {
                    this.routerParams = {};
                }
            }
        }
    }

    getRouterName() {
        return this.entityTypeRouterTargets[this.entityType] || '';
    }

    toJSON() {
        return {
            entityType: this.entityType || null,
            entityEqId: this.entityEqId,
            message: this.message,
            userEqId: this.userEqId,
        };
    }

    toRouterParamsJSON() {
        return {
            name: this.getRouterName(),
            params: this.toDataKeyJSON(this.routerParams),
        }
    }

    toDataKeyJSON(o) {
        let newO, origKey, newKey, value;

        if (o instanceof Array) {
            return o.map(function(value) {
                if (typeof value === 'object') {
                    value = this.toDataKeyJSON(value);
                }
                return value;
            }, this);
        }
        else
        {
            newO = {};
            for (origKey in o) {
                if (Object.prototype.hasOwnProperty.call(o, origKey)) {
                    newKey = ('data' + origKey || origKey).toString();
                    value = o[origKey];
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = this.toDataKeyJSON(value);
                    }
                    newO[newKey] = value;
                }
            }
        }
        return newO;
    }
}
