<script>
import formComponent from '@/components/form.vue';
import SeriesTeamModel from '@/models/seriesTeam.js';

/**
 * component
 */
export default {
    name: 'editSeriesTeamForm',
    extends: formComponent,
    props: {
        model: {
            type: SeriesTeamModel,
        },
        values: {
            type: Object,
            default(){
                return {};
            },
        },
    },
    methods: {
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
        showModal() {
            this.$root.$emit('bv::show::modal', 'addSeriesTeam');
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'team',
                        type: 'pickTeam',
                        label: this.$t('seriesTeam'),
                        disabled: true,
                        plaintext: true,
                    },
                    {
                        id: 'isTourMember',
                        type: 'checkbox',
                        label: this.$t('teamIsCoreMemberOfSeries'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
