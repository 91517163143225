<script>
import vselectElement from '../vselect.vue';
import WorkflowModel from '@/models/workflow.js';

/**
 * vselect element for WorkflowModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectWorkflow',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            config.requestOptions = (text = '', offset = 0) => {
                //sport can either be depending on a sport field in the form or provided as data. type must be provided as data.
                if(((config.dataDependency && config.dataDependency.sport) || (config.data && config.data.sport)) && (config.data && config.data.type)){
                    //request loads all entries, there is no pagination here
                    if(offset > 0){
                        return Promise.resolve(null);
                    }

                    //force reloading options when sport changes
                    this.addOptionReloadListener('dataAllValues.sport', true);
                    if(config.data && config.data.sport !== undefined){
                        this.addOptionReloadListener('dataConfig.data.sport', true);
                    }

                    const type = config.data.type;
                    const sport = config.data.sport || this.dataAllValues.sport;

                    if(sport){
                        return this.$api.call.termsOfParticipation.declarationWorkflowsByTypeAndSport(type, sport)
                            .then(result => (result.workflows || []).map(data => WorkflowModel.from(data)), error => {
                                //in case of error 422 it just means there are none
                                if(error.status === 422){
                                    return [];
                                }
                                throw error;
                            });
                    }
                }

                return Promise.resolve([]);
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                const config = this.dataConfig || {};

                const type = config.data.type;
                const sport = config.data.sport || this.dataAllValues.sport;

                switch(property){
                    case 'id':
                    default:
                        return this.$api.call.termsOfParticipation.declarationWorkflowsByTypeAndSport(type, sport)
                            .then(result => (result.workflows || []).map(data => WorkflowModel.from(data)), error => {
                                //in case of error 422 it just means there are none
                                if(error.status === 422){
                                    return [];
                                }
                                throw error;
                            }).then(workflows => workflows.filter(workflow => initValues.includes(workflow.id)));
                }
            };

            return config;
        },
    },
};
</script>
