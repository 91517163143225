import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationRankingCalculationsCalculationRatingCreate from '@/views/administration/rankings/calculations/calculationRating/create.vue';
import administrationRankingCalculationsCalculationRatingEdit from '@/views/administration/rankings/calculations/calculationRating/edit.vue';

export default {
    path: 'calculation-rating/',
    component: pageFrame,
    children: [
        {
            name: 'administrationRankingCalculationsCalculationRatingCreate',
            path: constants.routePaths.create,
            component: administrationRankingCalculationsCalculationRatingCreate,
            props: true,
            meta: {
                permission: permissions.rankings.calculations.calculationRating.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.calculationId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationRankingCalculationsCalculationRatingEdit',
                    path: constants.routePaths.edit,
                    component: administrationRankingCalculationsCalculationRatingEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.calculationId]),
                    meta: {
                        permission: permissions.rankings.calculations.calculationRating.update,
                    },
                },
            ],
        },
    ],
};
