<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'teamAliasesForm',
    extends: formComponent,
    props: {
        alias: {
            type: Object,
            default(){
                return [];
            },
        },
        values: {
            type: Object,
            default(){
                return [];
            },
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name_' + this.alias.id,
                        type: 'text',
                        label: false,
                        placeholder: '',
                        value: this.values['name_' + this.alias.id],
                        plaintext: true,
                        disabled: true,
                    },
                    {
                        id: 'validFromDisplay_' + this.alias.id,
                        type: 'text',
                        label: false,
                        placeholder: '',
                        value: this.values['validFromDisplay_' + this.alias.id],
                        plaintext: true,
                        disabled: true,
                    },
                    {
                        id: 'newValidFrom_' + this.alias.id,
                        type: 'date',
                        label: '',
                        plaintext: false,
                        disabled: false,
                    },
                    {
                        id: 'validFrom_' + this.alias.id,
                        type: 'hidden',
                        value: 'ThisHas a value',
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                aliases: 'newValidFrom_' + this.alias.id,
            };
        },
    },
};
</script>
