<script>
import textElement from './text.vue';

/**
 * password input element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'passwordElement',
    extends: textElement,
};
</script>
