<template>
    <div>
        <b-card v-bind="rowAttributes" v-on:click="emitEvent('click', $event)">
            <b-card-text v-bind="getColumnProps('position', 12, 6, 1)" v-if="showPositionNumber">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('position')">{{
                    getHeadline('position', '#') }}</label>
                <span v-if="!isHeadline">{{ person.positionNumber }}</span>
            </b-card-text>

            <div v-bind="getColumnProps(null, 1, null, null, true, { hasImg: showImg, noName: !showName, })"
                class="card-img-wrapper">
                <span v-bind="getColumnProps('number')" v-if="showNr" v-bind:data-number="displayNr">
                    <b-card-img src="@/assets/img/shirt-blue.svg" v-bind:alt="displayNr" v-bind:title="displayNr" />
                </span>

                <b-card-img v-bind="getColumnProps('image')" v-bind:src="person.getImgPath()"
                    v-bind:alt="person.getName()" v-bind:width="imgWidth" v-bind:height="imgHeight"
                    v-bind:role="editImg ? 'button' : null" v-bind:tabindex="editImg ? 0 : null"
                    v-on:keypress.enter="$bvModal.show(modalName('editHeadshot', person.id)); emitEvent('click', $event); $event.preventDefault()"
                    v-on:keypress.space="$bvModal.show(modalName('editHeadshot', person.id)); emitEvent('click', $event); $event.preventDefault()"
                    v-on:click="editImg && !squadPersonHeadshotsHidden ? $bvModal.show(modalName('editHeadshot', person.id)) : null"
                    v-if="showImg" />

                <statusIndicatorIcon v-bind:status="person.headshotStatus"
                    v-if="showImg && showValidationHeadshot && person.headshotStatus && !squadPersonHeadshotsHidden" />
            </div>

            <b-card-title v-bind="getColumnProps('name', 2)" v-bind:title-tag="titleTag" v-if="showName">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{
                    getHeadline('name', $t('name')) }}</label>
                <span class="text-uppercase" v-else>{{ person.getName(true).join('\n') }}<span
                        v-if="person.playerAttributes && person.playerAttributes.isCaptain"> (C)</span></span>
                <span v-if="!isHeadline && highlightUnderaged && person.isMinor(firstTournamentMatchDate)"
                    class="d-block text-danger">{{ $t('ageDisplay', { age: person.getAge() }) }}</span>
                <span v-if="!isHeadline && (person.position && person.position.isReplacement) && person.unavailable"
                    class="d-block text-danger">{{ $t('unavailable') }}</span>
            </b-card-title>

            <b-card-text v-bind="getColumnProps('unavailable', 12, 12, 3)"
                v-if="showMessageUnavailableForMatch && person.unavailable">
                <span role="alert" class="alert alert-warning p-4" v-if="!isHeadline">
                    <icon type="cancel-circle-full" color="warning" large /> <span class="pl-6">{{
                        $t('notAvailableForThisMatch') }}</span>
                </span>
            </b-card-text>

            <b-card-title v-bind="getColumnProps('assign', 2)" v-bind:title-tag="titleTag" v-if="hasVisibleAssigns">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('assign')" v-if="isHeadline">{{
                    getHeadline('assign', '') }}</label>
                <div class="text-uppercase text-nowrap text-center text-secondary"
                    v-if="!isHeadline && showAssignPlayer">{{ $t('clickToAssign', { type: this.$tc('player') }) }}</div>
                <div class="text-uppercase text-nowrap text-center text-secondary"
                    v-if="!isHeadline && showAssignManager">{{ $t('clickToAssign', { type: this.$tc('manager') }) }}
                </div>
                <div class="text-uppercase text-nowrap text-center text-secondary"
                    v-if="!isHeadline && showAssignMedic">{{ $t('clickToAssign', { type: this.$tc('medic') }) }}</div>
            </b-card-title>

            <b-card-text v-bind="getColumnProps('details', 12, 12, 2)"
                class="d-flex flex-row flex-lg-column justify-content-center justify-content-lg-start no-mobile-line"
                v-if="showDetails">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('details')" v-if="isHeadline">{{
                    getHeadline('details', '') }}</label>
                <div class="mx-3 mx-lg-0 my-2 my-lg-0 font-regular text-uppercase text-nowrap"
                    v-if="!isHeadline && person.height">
                    <label class="m-0 mr-1 font-heavy">{{ $t('height') }}</label> {{ $n(person.height, 'size') }} {{
                        $t('centimeterShort') }}
                </div>
                <div class="mx-3 mx-lg-0 my-2 my-lg-0 font-regular text-uppercase text-nowrap"
                    v-if="!isHeadline && person.weight">
                    <label class="m-0 mr-1 font-heavy">{{ $t('weight') }}</label> {{ $n(person.weight, 'weight') }} {{
                        $t('kilogramShort') }}
                </div>
                <div class="mx-3 mx-lg-0 my-2 my-lg-0 font-regular text-uppercase text-nowrap"
                    v-if="!isHeadline && !hideDetailsAge && person.getAge()">
                    <!-- <label class="m-0 mr-1 font-heavy">{{ $t('age') }}</label> --> {{ person.getAge() }}
                </div>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('detailInputs', 12, 12, 1)"
                class="d-flex flex-row flex-lg-column justify-content-center justify-content-lg-start no-mobile-line"
                v-if="showDetailInputs">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('detailInputs')"
                    v-if="isHeadline">{{ getHeadline('detailInputs', '') }}</label>
                <div class="mx-3 mx-lg-0 mb-0 mb-lg-2 mt-lg-n2 font-regular text-uppercase text-nowrap"
                    v-if="!isHeadline">
                    <label class="m-0 mr-1 font-heavy">{{ $t('heightIn', { unit: $t('centimeterShort') }) }}</label>
                    <b-form-input type="number" min="0" v-bind:value="person.height"
                        v-on:input="emitEvent('heightChange', null, { value: ($event || null) })" />
                </div>
                <div class="mx-3 mx-lg-0 font-regular text-uppercase text-nowrap" v-if="!isHeadline">
                    <label class="m-0 mr-1 font-heavy">{{ $t('weightIn', { unit: $t('kilogramShort') }) }}</label>
                    <b-form-input type="number" min="0" v-bind:value="person.weight"
                        v-on:input="emitEvent('weightChange', null, { value: ($event || null) })" />
                </div>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('dateOfBirth', 12, 6, 2)" v-if="showDateOfBirth">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateOfBirth')">{{
                    getHeadline('dateOfBirth', $t('dateOfBirth')) }}</label>
                <span v-if="!isHeadline">{{ $fd(person.dateOfBirth, 'date') }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('dateOfBirth', 12, 6, 2)" v-if="showDateOfBirthAndAge">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateOfBirth')">{{
                    getHeadline('dateOfBirth', $t('dateOfBirthAndAge')) }}</label>
                <span v-if="!isHeadline"
                    :class="highlightUnderagedWithDoB && person.isUnderage() ? 'text-danger' : ''">
                    {{ formattedDateOfBirthAndAge }}
                </span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('sport', 12, 6, 1)" v-if="showSport">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport',
                    $tc('sport', 1)) }}</label>
                <span v-if="!isHeadline">{{ (person.currentRole && person.currentRole.sport) ? person.currentRole.sport
                    : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('role', 12, 6, 1)" v-if="showRole">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('role')">{{ getHeadline('role',
                    $tc('role', 1)) }}</label>
                <span v-if="!isHeadline">{{ person.currentRole ? person.currentRole.roleType : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('jerseyNumber', 12, 6, 1)" v-if="showJerseyNumberDisplay">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('jerseyNumber')">{{
                    getHeadline('jerseyNumber', $tc('jerseyNumber')) }}</label>
                <span v-if="!isHeadline">{{ displayNr }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('jerseyNumber', 12, 6, 1)" v-if="showJerseyNumber">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('jerseyNumber')">{{
                    getHeadline('jerseyNumber', $t('jerseyNumber')) }}</label>
                <b-form-input type="number" min="0" v-if="!isHeadline" v-bind="inputAttributes" v-bind:value="displayNr"
                    v-on:blur="emitEvent('jerseyNumberToggle', 'jerseyNumber', { value: ($event || null) })" />
            </b-card-text>

            <b-card-text v-bind="getColumnProps('isCaptain', 12, 6, 1)" v-if="showIsCaptainCheckbox">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('isCaptain')">{{
                    getHeadline('isCaptain', $t('captain')) }}</label>
                <b-form-checkbox v-if="!isHeadline" v-bind="inputAttributes"
                    v-bind:checked="person.playerAttributes ? person.playerAttributes.isCaptain : false"
                    v-on:input="emitEvent('isCaptainToggle', 'isCaptain', { value: ($event || false) })"></b-form-checkbox>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('leftSquad', 12, 6, 1)" v-if="showActionsLeftSquadCheckbox">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('leftSquad')">{{
                    getHeadline('leftSquad', $t('leftSquad')) }}</label>
                <b-form-checkbox v-if="!isHeadline" v-bind="inputAttributes"
                    v-bind:checked="person.playerAttributes ? person.playerAttributes.hasPlayerLeftSquad : false"
                    v-on:input="emitEvent('leftSquadToggle', null, { value: ($event || false) })"></b-form-checkbox>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('replacement', 12, 6, 1)" v-if="showActionsReplacementCheckbox">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('replacement')">{{
                    getHeadline('replacement', $t('replacement')) }}</label>
                <b-form-checkbox v-if="!isHeadline" v-bind="inputAttributes"
                    v-bind:checked="person.playerAttributes ? person.playerAttributes.isUsedReplacement : false"
                    v-on:input="emitEvent('replacementToggle', null, { value: ($event || false) })"></b-form-checkbox>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('union', 12, 6, 2)" v-if="showUnionFromRootUnionObject">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('union')">{{ getHeadline('union',
                    $tc('union', 1)) }}</label>
                <span v-if="!isHeadline">{{ (person.union && person.union.name) ? person.union.name : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('position', 12, 6, 2)"
                v-if="showPosition && !squadPersonPositionHidden">
                <label v-if="isHeadline" v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('position')">{{
                    getHeadline('position', $t('position')) }}</label>
                <span v-if="!isHeadline && !showActionsPositionSelect">{{ person.getPositionName() }}</span>
                <vselectPositionElement class="mb-0" v-if="!isHeadline && showActionsPositionSelect" v-bind:dataConfig="{
                    id: 'position',
                    placeholder: this.$t('selectPositionByRoleType', { roleType: this.roleType ? this.roleType.toLowerCase() : 'player' }),
                    roleType: this.roleType,
                    bySport: this.actionsPositionSelectBySport,
                    returnFullOptions: true,
                    isGeneric: this.isGeneric,
                    isRemoveGeneric: this.isRemoveGeneric,
                    isDeclaration: isDeclaration,
                }" v-bind:dataValue="person.position"
                    v-on:change="emitEvent('positionSelect', 'changePosition', { value: ($event || null) })" />
            </b-card-text>

            <b-card-text v-bind="getColumnProps('eligibility', 12, 6, 1)" v-if="showEligibility">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('eligibility')">{{
                    getHeadline('eligibility', $tc('frEligibility', 1)) }}</label>
                <div v-if="!isHeadline">{{ person.isHooker ? $t('isHooker') : '' }}</div>
                <div v-if="!isHeadline">{{ person.isLoosehead ? $t('isLoosehead') : '' }}</div>
                <div v-if="!isHeadline">{{ person.isTighthead ? $t('isTighthead') : '' }}</div>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('eligibility', 12, 6, 1)" v-if="showEligibilityCheckbox">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('eligibility')">{{
                    getHeadline('eligibility', $t('frEligibility')) }}</label>
                <b-form-checkbox v-if="!isHeadline" v-bind="inputAttributes"
                    v-bind:checked="person.isHooker ? person.isHooker : false"
                    v-on:input="emitEvent('isHookerToggle', 'changeIsHooker', { value: ($event || false) })">{{
                    $t('isHooker') }}</b-form-checkbox>
                <b-form-checkbox v-if="!isHeadline" v-bind="inputAttributes"
                    v-bind:checked="person.isLoosehead ? person.isLoosehead : false"
                    v-on:input="emitEvent('isLooseheadToggle', 'changeIsLoosehead', { value: ($event || false) })">{{
                        $t('isLoosehead') }}</b-form-checkbox>
                <b-form-checkbox v-if="!isHeadline" v-bind="inputAttributes"
                    v-bind:checked="person.isTighthead ? person.isTighthead : false"
                    v-on:input="emitEvent('isTightheadToggle', 'changeIsTighthead', { value: ($event || false) })">{{
                        $t('isTighthead') }}</b-form-checkbox>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('playerOfTheMatch', 12, 6, 1)"
                v-if="showActionsPlayerOfTheMatchCheckbox">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('playerOfTheMatch')">{{
                    getHeadline('playerOfTheMatch', $t('playerOfMatch')) }}</label>
                <b-form-checkbox v-if="!isHeadline" v-bind="inputAttributes"
                    v-bind:checked="person.playerAttributes.isPlayerOfTheMatch" />
            </b-card-text>
            <b-card-text v-bind="getColumnProps('usedInMatch', 12, 6, 1)" v-if="showActionsUsedInMatchCheckbox">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('usedInMatch')">{{
                    getHeadline('usedInMatch', $t('used')) }}</label>
                <b-form-checkbox v-if="!isHeadline" v-bind="inputAttributes" v-bind:checked="person.usedInMatch" />
            </b-card-text>

            <b-card-text v-bind="getColumnProps('representing', 12, 6, 2)" v-if="showRepresenting">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('representing')">{{
                    getHeadline('representing', $tc('representing', 1)) }}</label>
                <span v-if="!isHeadline && !showEditTeamsRepresenting">{{ representedTeamName }}</span>
                <span v-if="!isHeadline && showEditTeamsRepresenting" class="ml-2">{{ representedTeamName }}<br></span>
                <actionButton variant="outline-primary" v-bind="buttonAttributes"
                    v-b-modal="modalName('editTeamsRepresenting', person.personId ? person.personId : person.id)"
                    v-if="!isHeadline && showEditTeamsRepresenting">
                    {{ $t('editRepresenting') }}
                </actionButton>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('team', 12, 6, 2)" v-if="showTeam">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('team')">{{ getHeadline('team',
                    $tc('team', 1)) }}</label>
                <span v-if="!isHeadline">{{ (person.currentRole && person.currentRole.name) ? person.currentRole.name :
                    '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('union', 12, 6, 2)" v-if="showUnion">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('union')">{{ getHeadline('union',
                    $tc('union', 1)) }}</label>
                <span v-if="!isHeadline">{{ (person.currentRole && person.currentRole.union) ?
                    person.currentRole.union.name : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('personId', 12, 6, 2)" v-if="showPersonId">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('personId')">{{
                    getHeadline('personId', $t('id')) }}</label>
                <span v-if="!isHeadline">{{ (person && person.id) ? person.id : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('teamNameShort', 12, 6, 2)" v-if="showTeamNameShort">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamNameShort')">{{
                    getHeadline('teamNameShort', $t('teamNameShort')) }}</label>
                <span v-if="!isHeadline">{{ (person.teamNameShort) ? person.teamNameShort : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('teamCode', 12, 6, 1)" v-if="showTeamCode">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamCode')">{{
                    getHeadline('teamCode', $t('teamCode')) }}</label>
                <span v-if="!isHeadline">{{ (person.teamCode) ? person.teamCode : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('tackles', 12, 6, 1)" v-if="showTackles">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('tackles')">{{
                    getHeadline('tackles', $t('tackles')) }}</label>
                <span v-if="!isHeadline">{{ (person.tackles) ? person.tackles : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('breaks', 12, 6, 1)" v-if="showBreaks">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('breaks')">{{ getHeadline('breaks',
                    $t('breaks')) }}</label>
                <span v-if="!isHeadline">{{ (person.breaks) ? person.breaks : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('offloads', 12, 6, 1)" v-if="showOffloads">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('offloads')">{{
                    getHeadline('offloads', $t('offloads')) }}</label>
                <span v-if="!isHeadline">{{ (person.offloads) ? person.offloads : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('carries', 12, 6, 1)" v-if="showCarries">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('carries')">{{
                    getHeadline('carries', $t('carries')) }}</label>
                <span v-if="!isHeadline">{{ (person.carries) ? person.carries : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('total', 12, 6, 1)" v-if="showTotal">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('total')">{{ getHeadline('total',
                    $t('total')) }}</label>
                <span v-if="!isHeadline">{{ (person.total) ? person.total : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('dateFrom', 12, 6, 1)" v-if="showDateFrom">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateFrom')">{{
                    getHeadline('dateFrom', $t('from')) }}</label>
                <span v-if="!isHeadline">{{ person.currentRole ? $fd(person.currentRole.dateFrom, 'date') : '' }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('dateUntil', 12, 6, 1)" v-if="showDateUntil">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateUntil')">{{
                    getHeadline('dateUntil', $t('until')) }}</label>
                <span v-if="!isHeadline">{{ person.currentRole ? $fd(person.currentRole.dateUntil, 'date') : ''
                    }}</span>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('validations', 12, 12, null, true)" class="text-center text-lg-left"
                v-if="hasVisibleValidations">
                <div class="mx-2 mx-lg-0 my-2" v-if="showValidationBiography && !squadBioHidden">
                    <actionButton variant="outline-primary" v-bind="buttonAttributes"
                        v-b-modal="modalName('editBiography', person.id)" v-on:click="emitEvent('biography', $event)">
                        <statusIndicatorIcon v-bind:status="person.biographyStatus ? person.biographyStatus : ''"
                            v-if="showValidationBiography" />
                        {{ $t('biographyAndPhoneticGuide') }}
                    </actionButton>
                </div>
                <div class="mx-2 mx-lg-0 my-2" v-if="showValidationPassport && !squadPassportInformationHidden">
                    <actionButton variant="outline-primary" v-bind="buttonAttributes"
                        v-b-modal="modalName('editPassport', person.id)" v-on:click="emitEvent('passport', $event)">
                        <statusIndicatorIcon v-bind:status="person.passportStatus ? person.passportStatus : ''"
                            v-if="showValidationPassport" />
                        {{ $t('passport') }}
                    </actionButton>
                </div>
                <div class="mx-2 mx-lg-0 my-2"
                    v-if="showValidationMedicalCertificate && !squadMedicalCertificatesHidden">
                    <actionButton variant="outline-primary" v-bind="buttonAttributes"
                        v-b-modal="modalName('editMedicalCertificate', person.id)"
                        v-on:click="emitEvent('medicalCertificate', $event)">
                        <statusIndicatorIcon
                            v-bind:status="person.medicalCertificateStatus ? person.medicalCertificateStatus : ''"
                            v-if="showValidationMedicalCertificate" />
                        {{ $t('medicalCertificate') }}
                    </actionButton>
                </div>
                <div class="mx-2 mx-lg-0 my-2"
                    v-if="(showValidationBiography || showValidationPassport || showValidationMedicalCertificate) && !squadPersonConfirmHidden && reviewMode">
                    <actionButton variant="outline-primary" v-bind="buttonAttributes"
                        v-b-modal="modalName('reviewPerson', person.id)">
                        <statusIndicatorIcon v-bind:status="person.status ? person.status : ''" />
                        {{ $t('reviewPerson') }}
                    </actionButton>
                </div>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('actions', 12, 12, null, true)" class="text-center text-lg-left"
                v-if="hasVisibleActions">
                <div class="mx-2 mx-lg-0 my-2" v-if="showActionsUnavailable && person.unavailable === false">
                    <actionButton variant="outline-primary" loadingThrobber
                        v-bind:loadingWhile="markPlayerUnavailableLoadingWhile" v-bind="actionButtonAttributes"
                        v-on:click="emitEvent('markUnavailable', $event)">
                        {{ $t('markUnavailable', { type: this.$tc('player') }) }}
                    </actionButton>
                </div>
                <div class="mx-2 mx-lg-0 my-2" v-if="showActionsAvailable && person.unavailable === true">
                    <actionButton variant="outline-primary" loadingThrobber
                        v-bind:loadingWhile="markPlayerAvailableLoadingWhile" v-bind="actionButtonAttributes"
                        v-on:click="emitEvent('markAvailable', $event)">
                        {{ $t('markAvailable', { type: this.$tc('player') }) }}
                    </actionButton>
                </div>
                <!-- <div class="mx-2 mx-lg-0 my-2" v-if="showActionsRoleToggle">
                <actionButton
                    variant="outline-primary"
                    v-bind="actionButtonAttributes"
                    v-on:click="emitEvent('roleToggle', $event)"
                >
                    (Toggle role)
                </actionButton>
            </div> -->
                <div class="mx-2 mx-lg-0 my-2" v-if="showActionsRoleSelect">
                    <b-form-select v-model="selectValue" v-bind="actionButtonAttributes" v-bind:options="selectOptions"
                        v-on:input="emitEvent('roleSelect', $event, { value: ($event.target.value || null) })" />
                </div>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('select', 3, null, null, true)"
                class="align-self-start align-self-lg-center text-right" v-if="showSelect">
                <actionButton variant="outline-primary" v-bind="buttonAttributes"
                    v-on:click="emitEvent('select', $event)">
                    {{ $t('select') }}
                </actionButton>
            </b-card-text>

            <b-card-text v-bind="getColumnProps('circles', 4, null, null, true)"
                class="align-self-start align-self-lg-center text-right" v-if="hasVisibleCircles">
                <actionButton variant="icon" v-bind="buttonAttributes" v-if="showAdd"
                    v-on:click="emitEvent('add', $event)">
                    <icon type="add" large />
                </actionButton>
                <actionButton variant="icon" v-bind="buttonAttributes" v-bind:permission="removePermission"
                    v-if="showRemove" v-on:click="emitEvent('remove', $event)"
                    v-b-modal="!removeSilent ? modalName('confirmDelete', person.personId ? person.personId : person.id) : null">
                    <icon type="cancel-circle-full" large v-if="!showRemoveIcon" />
                    <icon type="minus-circle-full" large v-if="showRemoveIcon" />
                </actionButton>
                <actionButton variant="icon" v-bind="buttonAttributes" v-if="showMove"
                    v-on:click="emitEvent('move', $event)" v-bind:to="moveTo">
                    <icon type="view" large />
                </actionButton>
                <actionButton variant="icon" v-bind="buttonAttributes" v-bind:disabled="false" v-if="showDetail"
                    v-bind:to="detailLink">
                    <icon type="view" large />
                </actionButton>
                <actionButton variant="icon" v-bind="buttonAttributes" v-if="showActionsRoleToggle"
                    v-b-toggle="'accordion-' + person.id">
                    <icon type="down-circle-full" large />
                </actionButton>
            </b-card-text>

            <div class="player-replaced d-flex flex-row align-items-center justify-content-center m-0 p-3"
                v-if="person.showReplacementOverlay && !isHeadline">
                <span class="text-center">
                    {{ person.replacementOverlayMessage }}
                </span>
                <actionButton class="ml-4" v-bind:loadingWhile="person.replacementOverlayUndoPromise" loadingThrobber
                    v-on:click="person.replacementOverlayUndo" v-if="person.replacementOverlayUndo">
                    {{ $t('undo') }}
                </actionButton>
                <icon type="arrow-right" color="success" class="replacement-both-on" large
                    v-if="person.isReplacement && !isHeadline" />
                <icon type="arrow-left" color="danger" class="replacement-both-off" large
                    v-if="person.isReplacement && !isHeadline" />
                <icon type="arrow-left" color="danger" class="replacement-single" large
                    v-if="!person.isReplacement && !isHeadline" />
            </div>

            <div class="player-replacement"
                v-if="person.isReplacement && !person.showReplacementOverlay && !isHeadline">
                <icon type="arrow-right" color="success" large />
            </div>

            <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>

            <confirmModal v-bind:id="modalName('confirmDelete', person.personId ? person.personId : person.id)"
                v-bind:text="$t(this.showRemoveIcon ? 'areYouSureToRemove' : 'areYouSureToDelete', { type: person.label() })"
                v-bind:data="person" v-bind:confirmHandler="removeHandler" v-on:confirmed="emitEvent('removed')"
                v-if="showRemove" />

            <!-- change image -->
            <editPlayerRepresentingModal
                v-bind:id="modalName('editTeamsRepresenting', person.personId ? person.personId : person.id)"
                v-bind:player="person" v-bind:reviewMode="reviewMode" v-bind:title="$t('editRepresenting')"
                v-on:editRepresenting="editRepresenting" v-if="showEditTeamsRepresenting" />

            <!-- Headshot -->
            <editHeadshotModal v-bind:id="modalName('editHeadshot', person.id)"
                v-bind:title="reviewMode ? $t('reviewHeadshot') : $t('submitHeadshot')" v-bind:person="person"
                v-bind:reviewMode="reviewMode" v-bind:declaration="declaration"
                v-bind:confirmHandler="reviewMode ? headshotAcceptHandler : headshotUpdateHandler"
                v-bind:cancelHandler="reviewMode ? headshotRejectHandler : undefined"
                v-on:confirmed="reviewMode ? emitSubEvent('headshotAccepted', true) : emitSubEvent('headshotUpdated', true)"
                v-on:cancel="reviewMode ? emitSubEvent('headshotRejected', false) : null" v-if="showImg" />

            <!-- Biography -->
            <personBiographyModal v-bind:id="modalName('editBiography', person.id)"
                v-bind:title="reviewMode ? $t('reviewBiographyAndPhoneticGuide') : $t('submitBiographyAndPhoneticGuide')"
                v-bind:person="person" v-bind:reviewMode="reviewMode" v-bind:declaration="declaration"
                v-bind:confirmHandler="reviewMode ? biographyAcceptHandler : biographyUpdateHandler"
                v-bind:cancelHandler="reviewMode ? biographyRejectHandler : undefined"
                v-on:confirmed="reviewMode ? emitSubEvent('biographyAccepted', true) : emitSubEvent('biographyUpdated', true)"
                v-on:cancel="reviewMode ? emitSubEvent('biographyRejected', false) : null" />

            <!-- Passport -->
            <editPassportModal v-bind:id="modalName('editPassport', person.id)"
                v-bind:title="reviewMode ? $t('reviewPassportInformation') : $t('submitPassportInformation')"
                v-bind:person="person" v-bind:reviewMode="reviewMode" v-bind:declaration="declaration"
                v-bind:confirmHandler="reviewMode ? passportAcceptHandler : passportUpdateHandler"
                v-bind:cancelHandler="reviewMode ? passportRejectHandler : undefined"
                v-on:confirmed="reviewMode ? emitSubEvent('passportAccepted', true) : emitSubEvent('passportUpdated', true)"
                v-on:cancel="reviewMode ? emitSubEvent('passportRejected', false) : null" />

            <!-- Medical Certificate -->
            <personMedicalCertificateModal v-bind:id="modalName('editMedicalCertificate', person.id)"
                v-bind:title="reviewMode ? $t('reviewMedicalCertificate') : $t('submitMedicalCertificate')"
                v-bind:person="person" v-bind:reviewMode="reviewMode" v-bind:declaration="declaration"
                v-bind:confirmHandler="reviewMode ? medicalCertificateAcceptHandler : medicalCertificateUpdateHandler"
                v-bind:cancelHandler="reviewMode ? medicalCertificateRejectHandler : undefined"
                v-on:confirmed="reviewMode ? emitSubEvent('medicalCertificateAccepted', true) : emitSubEvent('medicalCertificateUpdated', true)"
                v-on:cancel="reviewMode ? emitSubEvent('medicalCertificateRejected', false) : null" />

            <!-- person review -->
            <personReviewModal v-bind:id="modalName('reviewPerson', person.id)" v-bind:title="$t('reviewPerson')"
                v-bind:person="person" v-bind:reviewMode="reviewMode" v-bind:declaration="declaration"
                v-bind:confirmHandler="reviewMode ? personAcceptHandler : undefined"
                v-bind:cancelHandler="reviewMode ? personRejectHandler : undefined"
                v-on:confirmed="reviewMode ? emitSubEvent('personAccepted', true) : null"
                v-on:cancel="reviewMode ? emitSubEvent('personRejected', false) : null" />
        </b-card>
        <b-collapse :id="'accordion-' + person.id" accordion="personRoles" role="tabpanel" v-if="person && !isHeadline">
            <b-table head-variant="light" hover :items="rolesData" :fields="roleFields"></b-table>
        </b-collapse>
    </div>
</template>

<script>
import base from './base.vue';
import vselectPositionElement from '@/components/elements/vselect/position.vue';
import editPlayerRepresentingModal from '@/components/modals/editPlayerRepresenting.vue';
import personBiographyModal from '@/components/modals/personBiography.vue';
import editPassportModal from '@/components/modals/editPassport.vue';
import editHeadshotModal from '@/components/modals/editHeadshot.vue';
import personMedicalCertificateModal from '@/components/modals/personMedicalCertificate.vue';
import personReviewModal from '@/components/modals/personReview.vue';
import statusIndicatorIcon from '@/components/statusIndicatorIcon.vue';

import PersonModel from '@/models/person.js';
import DeclarationModel from '@/models/declaration.js';
import constants from '@/constants.js';

/**
 * person row component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'personRow',
    extends: base,
    components: {
        vselectPositionElement,
        editPlayerRepresentingModal,
        personBiographyModal,
        editPassportModal,
        editHeadshotModal,
        personMedicalCertificateModal,
        personReviewModal,
        statusIndicatorIcon,
    },
    props: {
        /**
         * person object
         */
        person: {
            type: PersonModel,
            default() {
                return new PersonModel();
            }
        },
        /**
         * number for the case when no person available
         */
        number: {
            type: Number,
            default: null,
        },

        /**
         * show person number
         */
        showNr: {
            type: Boolean,
            default: false,
        },
        /**
         * show person image
         */
        showImg: {
            type: Boolean,
            default: true,
        },
        /**
         * show person name
         */
        showName: {
            type: Boolean,
            default: true,
        },
        /**
         * show person details (size, weight, age)
         */
        showDetails: {
            type: Boolean,
            default: false,
        },
        hideDetailsAge: {
            type: Boolean,
            default: false,
        },
        showDetailInputs: {
            type: Boolean,
            default: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
        showDateOfBirth: {
            type: Boolean,
            default: false,
        },
        showDateOfBirthAndAge: {
            type: Boolean,
            default: false
        },
        showSport: {
            type: Boolean,
            default: false,
        },
        showRole: {
            type: Boolean,
            default: false,
        },
        showPosition: {
            type: Boolean,
            default: false,
        },
        showPositionNumber: {
            type: Boolean,
            default: false,
        },
        showEligibilityCheckbox: {
            type: Boolean,
            default: false,
        },
        showEligibility: {
            type: Boolean,
            default: false,
        },
        showTeam: {
            type: Boolean,
            default: false,
        },
        showUnion: {
            type: Boolean,
            default: false,
        },
        showUnionFromRootUnionObject: {
            type: Boolean,
            default: false,
        },
        showPersonId: {
            type: Boolean,
            default: false,
        },
        showTeamNameShort: {
            type: Boolean,
            default: false,
        },
        showTeamCode: {
            type: Boolean,
            default: false,
        },
        showDateFrom: {
            type: Boolean,
            default: false,
        },
        showDateUntil: {
            type: Boolean,
            default: false,
        },
        showTackles: {
            type: Boolean,
            default: false,
        },
        showBreaks: {
            type: Boolean,
            default: false,
        },
        showOffloads: {
            type: Boolean,
            default: false,
        },
        showCarries: {
            type: Boolean,
            default: false,
        },
        showTotal: {
            type: Boolean,
            default: false,
        },

        /**
         * show "assign player" text
         */
        showAssignPlayer: {
            type: Boolean,
            default: false,
        },
        /**
         * show "assign manager" text
         */
        showAssignManager: {
            type: Boolean,
            default: false,
        },
        /**
         * show "assign medic" text
         */
        showAssignMedic: {
            type: Boolean,
            default: false,
        },

        /**
         * show validation for biography
         * clicking it will trigger "biography" event
         */
        showValidationBiography: {
            type: Boolean,
            default: false,
        },

        /**
         * show validation for passport
         * clicking it will trigger "passport" event
         */
        showValidationPassport: {
            type: Boolean,
            default: false,
        },
        /**
         * show validation for medical certificate
         * clicking it will trigger "medicalCertificate" event
         */
        showValidationMedicalCertificate: {
            type: Boolean,
            default: false,
        },

        /**
         * show validation for check headshot
         */
        showValidationHeadshot: {
            type: Boolean,
            default: false,
        },
        /**
         * show set player available action button
         * clicking it will trigger "markAvailable" event
         */
        showActionsAvailable: {
            type: Boolean,
            default: false,
        },
        /**
         * show set player unavailable action button
         * clicking it will trigger "markUnavailable" event
         */
        showActionsUnavailable: {
            type: Boolean,
            default: false,
        },
        /**
         * show unavailable for match message
         */
        showMessageUnavailableForMatch: {
            type: Boolean,
            default: false,
        },
        /**
         * show role toggle button
         * clicking it will trigger "roleToggle" event
         */
        showActionsRoleToggle: {
            type: Boolean,
            default: false,
        },
        /**
         * show role select box
         * changing it will trigger "roleSelect" event
         */
        showActionsRoleSelect: {
            type: Boolean,
            default: false,
        },

        /**
         * show jersey number
         * changing it will trigger "jerseyNumberToggle" event
         */
        showJerseyNumber: {
            type: Boolean,
            default: false,
        },
        /**
         * show jersey number
         */
        showJerseyNumberDisplay: {
            type: Boolean,
            default: false,
        },

        highlightUnderaged: {
            type: Boolean,
            default: false,
        },
        highlightUnderagedWithDoB: {
            type: Boolean,
            default: false,
        },
        firstTournamentMatchDate: {
            type: Date,
            default() {
                return new Date();
            },
        },

        /**
         * show captain checkbox
         * changing it will trigger "isCaptainToggle" event
         */
        showIsCaptainCheckbox: {
            type: Boolean,
            default: false,
        },
        /**
         * show replacement checkbox
         * changing it will trigger "replacementToggle" event
         */
        showActionsReplacementCheckbox: {
            type: Boolean,
            default: false,
        },
        /**
         * show left squad checkbox
         * changing it will trigger "leftSquadToggle" event
         */
        showActionsLeftSquadCheckbox: {
            type: Boolean,
            default: false,
        },
        /**
         * show position select box
         * changing it will trigger "positionSelect" event
         */
        showActionsPositionSelect: {
            type: Boolean,
            default: false,
        },
        /**
         * use provided sport id for position select data request
         */
        actionsPositionSelectBySport: {
            type: String,
            default: null,
        },

        /**
         * we heve to send this parameter in vselectPosition
         * only for declarations
         */
        isGeneric: {
            type: Boolean,
            default: false,
        },
        /**
         * isGeneric is used as a parameter for vselectPosition
         * this option removes the parameter from the payload
         */
        isRemoveGeneric: {
            type: Boolean,
            default: false,
        },

        showActionsUsedInMatchCheckbox: {
            type: Boolean,
            default: false,
        },
        showActionsPlayerOfTheMatchCheckbox: {
            type: Boolean,
            default: false,
        },
        showRepresenting: {
            type: Boolean,
            default: false,
        },
        showEditTeamsRepresenting: {
            type: Boolean,
            default: false,
        },

        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showAdd: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with delete icon
         * clicking it will trigger "remove" event
         */
        showRemove: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with remove icon
         */
        showRemoveIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with arrow icon
         * clicking it will trigger "move" event
         */
        showMove: {
            type: Boolean,
            default: false,
        },
        /**
         * to location for move button
         */
        moveTo: {
            type: [String, Object],
            default: null,
        },
        /**
         * show button to select item (used for picker)
         */
        showSelect: {
            type: Boolean,
            default: false,
        },

        /**
         * hide confirmation window for remove process
         */
        removeSilent: {
            type: Boolean,
            default: false,
        },
        /**
         * enable image edit modal
         */
        editImg: {
            type: Boolean,
            default: false,
        },
        /**
         * image width
         */
        imgWidth: {
            type: Number,
            default: 100,
        },
        /**
         * image height
         */
        imgHeight: {
            type: Number,
            default: 100,
        },

        /**
         * is declaration
         * used with sport to restrict the positions select options for sevens match declaration
         */
        isDeclaration: {
            type: Boolean,
            default: false,
        },

        /**
         * person handler function that returns a Promise
         */
        personAcceptHandler: {
            type: Function,
            default: null,
        },
        personRejectHandler: {
            type: Function,
            default: null,
        },

        /**
         * headshot handler functions that return a Promises
         */
        headshotUpdateHandler: {
            type: Function,
            default: null,
        },
        headshotAcceptHandler: {
            type: Function,
            default: null,
        },
        headshotRejectHandler: {
            type: Function,
            default: null,
        },

        /**
         * biography handler functions that return a Promises
         */
        biographyUpdateHandler: {
            type: Function,
            default: null,
        },
        biographyAcceptHandler: {
            type: Function,
            default: null,
        },
        biographyRejectHandler: {
            type: Function,
            default: null,
        },

        /**
         * passport handler functions that return a Promises
         */
        passportUpdateHandler: {
            type: Function,
            default: null,
        },
        passportAcceptHandler: {
            type: Function,
            default: null,
        },
        passportRejectHandler: {
            type: Function,
            default: null,
        },

        /**
         * medical certificate handler functions that return a Promises
         */
        medicalCertificateUpdateHandler: {
            type: Function,
            default: null,
        },
        medicalCertificateAcceptHandler: {
            type: Function,
            default: null,
        },
        medicalCertificateRejectHandler: {
            type: Function,
            default: null,
        },

        /**
         * show declaration modals in review mode
         */
        reviewMode: {
            type: Boolean,
            default: false,
        },
        declaration: {
            type: DeclarationModel,
            default: null,
        },

        markPlayerAvailableLoadingWhile: {
            type: Promise,
            required: false,
            default: null,
        },
        markPlayerUnavailableLoadingWhile: {
            type: Promise,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            selectValue: null,
            roleType: null,
            selectOptions: [
                { value: null, text: '(Role select)' },
                { value: 1, text: 'option 1' },
                { value: 2, text: 'option 2' },
            ],
            roleFields: ['sport', 'role', 'team', 'dateFrom', 'dateUntil'],
            positionOptions: [
                { value: null, text: this.$t('selectPosition') },
                { value: 1, text: 'position 1' },
                { value: 2, text: 'position 2' },
            ],
        };
    },
    methods: {
        editRepresenting(data) {
            this.person.representing = data;
        },
        emitSubEvent(type, accepted = false) {
            //submit specific event
            this.emitEvent(type);

            //submit generic accepted/rejected event for reviews
            if (this.reviewMode && accepted) {
                this.emitEvent('reviewAccepted');
            }
            else if (this.reviewMode) {
                this.emitEvent('reviewRejected');
            }

            //submit generic reviewed/updated event
            this.emitEvent(this.reviewMode ? 'reviewHandled' : 'informationUpdated');
        },
    },
    computed: {
        detailLink() {
            return {
                name: this.detailLinkRoute || 'commonPersonDetail',
                params: Object.assign({
                    dataPersonId: this.person.getPersonId(),
                }, this.detailLinkParams),
            };
        },
        emitEventData() {
            return {
                person: this.person || null,
            };
        },
        customElementClasses() {
            return {
                personRow: true,
            };
        },
        hasVisibleAssigns() {
            return (this.showAssignPlayer || this.showAssignManager || this.showAssignMedic);
        },
        hasVisibleValidations() {
            return (this.showValidationBiography || this.showValidationPassport || this.showValidationMedicalCertificate);
        },
        hasVisibleActions() {
            return (
                this.showActionsRoleSelect ||
                this.showActionsAvailable ||
                this.showActionsUnavailable
            );
        },
        hasVisibleCircles() {
            return (this.showAdd || this.showRemove || this.showMove || this.showDetail || this.showActionsRoleToggle);
        },
        displayNr() {
            if (this.person && this.person.playerAttributes && (this.person.playerAttributes.shirtNumber || this.person.playerAttributes.squadNumber)) {
                return '' + (this.person.playerAttributes.shirtNumber || this.person.playerAttributes.squadNumber || '');
            }

            if (this.person.number) {
                return '' + this.person.number;
            }

            return '';
        },
        displayNrImg() {
            //this.person.number
            return 'https://placekitten.com/60/60';
        },
        rolesData() {
            if (!this.person) {
                return [];
            }
            return this.person.roles.map(item => (
                {
                    sport: item.sport ? item.sport : '',
                    role: item.roleType,
                    team: item.name ? item.name : '',
                    dateFrom: item.dateFrom ? item.dateFrom.getFullYear() : '',
                    dateUntil: item.dateUntil ? item.dateUntil.getFullYear() : '',
                }
            ));
        },
        rowButtonRole() {
            return this.hasVisibleAssigns;
        },
        actionButtonAttributes() {
            return Object.assign({}, this.buttonAttributes, { class: { 'ml-2': false } });
        },
        representedTeamName() {
            if (!this.person.representing) {
                return '';
            }
            return this.person.representing.map(item => item.teamName).join(', ')
        },
        squadPassportInformationHidden() {
            return (
                this.declaration &&
                !this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadPassportInformation) &&
                !this.declaration.existsInConfig(this.declaration.configTargets.squadPassportInformation)
            );
        },
        squadBioHidden() {
            return (
                this.declaration &&
                !this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadBio) &&
                !this.declaration.existsInConfig(this.declaration.configTargets.squadBio)
            );
        },
        squadMedicalCertificatesHidden() {
            return (
                this.declaration &&
                !this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadMedicalCertificates) &&
                !this.declaration.existsInConfig(this.declaration.configTargets.squadMedicalCertificates)
            );
        },
        squadPersonPositionHidden() {
            return (
                this.declaration &&
                !this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadPersonPosition) &&
                !this.declaration.existsInConfig(this.declaration.configTargets.squadPersonPosition)
            );
        },
        squadPersonHeadshotsHidden() {
            return (
                this.declaration &&
                !this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadPersonHeadshots) &&
                !this.declaration.existsInConfig(this.declaration.configTargets.squadPersonHeadshots)
            );
        },
        squadPersonConfirmHidden() {
            if (this.isOnlyMedicalCertificateModuleEnabled && this.roleType !== constants.group.medical) {
                return false;
            }

            return !(
                this.squadPassportInformationHidden &&
                this.squadBioHidden &&
                this.squadMedicalCertificatesHidden &&
                this.squadPersonHeadshotsHidden
            );
        },
        isOnlyMedicalCertificateModuleEnabled() {
            return (
                this.squadBioHidden &&
                !this.squadMedicalCertificatesHidden &&
                this.squadPassportInformationHidden &&
                this.squadPersonHeadshotsHidden
            );
        },
        formattedDateOfBirthAndAge() {
            const { age, dateOfBirth } = this.person.getDateOfBirthAndAge();
            if (!dateOfBirth && age === 0) {
                return '';
            }

            let ageDob = '';
            if (dateOfBirth) {
                ageDob += `${this.$fd(dateOfBirth, 'date')} `;
            }

            if (age !== 0) {
                ageDob += `(${age})`;
            }

            return ageDob;
        }
    },
};
</script>
