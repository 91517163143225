import Model from '@/models/model.js';

/**
 * union model class
 */
export default class UnionModel extends Model {
    /**
     * union id
     *
     * @type {int}
     */
    id = 0;

    /**
     * country
     *
     * @type {string}
     */
    countryName = '';

    /**
     * country code
     *
     * @type {string}
     */
    countryIsoCode = '';

    /**
     * eqId
     *
     * @type {string}
     */
    eqId = '';

    /**
     * union name
     *
     * @type {string}
     */
    name = '';

    /**
     * region
     *
     * @type {string}
     */
    unionRegion = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.countryName = data.countryName || '';
            this.countryIsoCode = data.countryIsoCode || '';
            this.eqId = data.eqId || data.sourceEqId || '';
            this.id = data.id || 0;
            this.name = data.name || data.unionName || '';
            this.unionRegion = data.unionRegion || data.regionName || '';
        }
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPath(){
        return require('@/assets/img/union.svg');
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            unionName: this.name,
            unionRegion: this.unionRegion,
            countryIsoCode: this.countryIsoCode,
        };
    }
}
