<script>
import editStandingModal from './editStanding.vue';

/**
 * standing edit modal component
 */
export default {
    name: 'createStandingModal',
    extends: editStandingModal,
    methods: {
        handleOpen(){
            this.standing.empty();
            this.addNewRow();
        },
    },
};
</script>
