import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationTournamentStageIndex from '@/views/administration/tournamentStage/index.vue';
import administrationTournamentStageCreate from '@/views/administration/tournamentStage/create.vue';
import administrationTournamentStageEdit from '@/views/administration/tournamentStage/edit.vue';

export default {
    path: 'tournament-stage/',
    component: pageFrame,
    children: [
        {
            name: 'administrationTournamentStageIndex',
            path: constants.routePaths.index,
            component: administrationTournamentStageIndex,
            meta: {
                permission: permissions.tournamentStages.list,
            },
        },
        {
            name: 'administrationTournamentStageCreate',
            path: constants.routePaths.create,
            component: administrationTournamentStageCreate,
            meta: {
                permission: permissions.tournamentStages.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.tournamentStageId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationTournamentStageEdit',
                    path: constants.routePaths.edit,
                    component: administrationTournamentStageEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentStageId]),
                    meta: {
                        permission: permissions.tournamentStages.update,
                    },
                },
            ],
        },
    ],
};
