<template>
    <div class="w-100 px-0">
        <b-nav vertical class="w-100 px-0 text-uppercase" v-for="(link, index) in links" v-bind:key="index">
            <b-nav-item
                v-bind:to="link.to"
                v-if="!link.children"
            >
                {{ link.label }}
            </b-nav-item>
            <b-nav-item-dropdown
                v-bind:text="link.label"
                menu-class="bg-primary p-0 m-0 border-0 rounded-0"
                dropright
                v-else
            >
                <b-dropdown-item
                    v-for="(child, i) in link.children"
                    v-bind:key="i"
                    v-bind:to="child.to"
                >
                    {{ child.label }}
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-nav>

        <b-nav vertical class="w-100 px-0 text-uppercase d-md-block d-lg-none bg-primary user-section">
            <div class="bg-primary text-uppercase px-5 py-4">
                <img v-bind:width="48" class="rounded-circle" src="@/assets/img/player.svg"/>
                <span class="px-4">User name</span>
            </div>
            <b-nav-item class="bg-primary text-uppercase" href="#">Profile</b-nav-item>
            <b-nav-item
                v-bind:to="{ name: 'logout' }"
                class="bg-primary text-uppercase"
            >
                {{ $t('logout') }}
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
import constants from '../constants.js';

export default {
    name: 'appNavigation',
    computed: {
        links(){
            if(!this.$store || !this.$store.state.user){
                return [];
            }

            const links = [
                {
                    label: this.$t('home'),
                    to: { name: constants.routeNames.home },
                },
                {
                    label: this.$tc('match', 2),
                    to: { name: 'commonMatchIndex' },
                },
                {
                    label: this.$t('personnel'),
                    to: { name: 'commonPersonIndex' },
                },
                {
                    label: this.$tc('series', 2),
                    to: { name: 'commonSeriesIndex' },
                },
                {
                    label: this.$tc('team', 2),
                    to: { name: 'commonTeamIndex' },
                },
                {
                    label: this.$tc('tournament', 2),
                    to: { name: 'commonTournamentIndex' },
                },
                {
                    label: this.$tc('union', 2),
                    to: { name: 'commonUnionIndex' },
                },
                {
                    label: this.$tc('venue', 2),
                    to: { name: 'commonVenueIndex' },
                },
                {
                    label: this.$tc('declaration', 2),
                    children: [
                        {
                            label: this.$t('seriesDeclaration'),
                            to: { name: 'declarationSeriesIndex' },
                        },
                        {
                            label: this.$t('tournamentDeclaration'),
                            to: { name: 'declarationTournamentIndex' },
                        },
                        {
                            label: this.$t('matchDeclaration'),
                            to: { name: 'declarationMatchIndex' },
                        },
                    ],
                },
                {
                    label: this.$t('declarationPortal'),
                    children: [
                        {
                            label: this.$t('seriesDeclarationHub'),
                            to: { name: 'reviewSeriesHub' },
                        },
                        {
                            label: this.$t('tournamentDeclarationHub'),
                            to: { name: 'reviewTournamentHub' },
                        },
                        {
                            label: this.$t('matchDeclarationHub'),
                            to: { name: 'reviewMatchHub' },
                        },
                    ],
                },
                {
                    label: this.$t('administration'),
                    children: [
                        {
                            label: this.$tc('place', 2),
                            to: { name: 'administrationLocationIndex' },
                        },
                        {
                            label: this.$t('tournamentTypes'),
                            to: { name: 'administrationTournamentTypeIndex' },
                        },
                        {
                            label: this.$t('tournamentTypeGroups'),
                            to: { name: 'administrationTournamentTypeGroupIndex' },
                        },
                        {
                            label: this.$t('tournamentTeamStatusTypes'),
                            to: { name: 'administrationTournamentTeamStatusTypesIndex' },
                        },
                        {
                            label: this.$t('tournamentStages'),
                            to: { name: 'administrationTournamentStageIndex' },
                        },
                        {
                            label: this.$t('tournamentStandingTypes'),
                            to: { name: 'administrationTournamentStandingTypeIndex' },
                        },
                        {
                            label: this.$t('apiManagement'),
                            to: { name: 'administrationApiConsumerIndex' },
                        },
                        {
                            label: this.$t('permissionGroupManagement'),
                            to: { name: 'administrationPermissionGroupIndex' },
                        },
                        {
                            label: this.$t('userManagement'),
                            to: { name: 'administrationUserIndex' },
                        },
                        {
                            label: this.$t('seriesTypes'),
                            to: { name: 'administrationSeriesTypesIndex' },
                        },
                        {
                            label: this.$t('managementPositions'),
                            to: { name: 'administrationManagementPositionsIndex' },
                        },
                        {
                            label: this.$t('mensRanking'),
                            to: { name: 'administrationRankingIndex', params: { dataRankingType: 'mens' } },
                        },
                        {
                            label: this.$t('womensRanking'),
                            to: { name: 'administrationRankingIndex', params: { dataRankingType: 'womens' } },
                        },
                        {
                            label: this.$t('mergePersonnel'),
                            to: { name: 'administrationMergePersonnelIndex' },
                        },
                        {
                            label: this.$t('failedDataIngestion'),
                            to: { name: 'administrationUnmappedIndex' },
                        },
                    ],
                },
            ];

            //filter out menu items the user has no permission to
            return links.map(item => {
                if(item.children){
                    //filter out menu item children the user has no permission to
                    item.children = item.children.filter(child => (child.to && this.$isRouteAllowed(child.to)));
                    return (item.children.length ? item : null);
                }
                return ((item.to && this.$isRouteAllowed(item.to)) ? item : null);
            }).filter(item => Boolean(item));
        },
    },
}
</script>
