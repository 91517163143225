<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="$t('seriesDeclaration')"
            v-bind:name="declaration ? declaration.getSeries().name : ''"
            v-bind:nameSub="declaration ? declaration.getSeries().seriesType.name : ''"
            v-bind:imagePath="declaration ? declaration.getTeamDeclaration().getUnionTeamLogo() : null"
            v-bind:details="detailData"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:disabled="!isAllowedToSubmitDeclaration"
                    loadingThrobber
                    v-b-modal.confirmSubmit
                >
                    {{ $t('submitSeriesDeclaration') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <declarationWorkflowSummaries
            v-bind:title="$t('squadAnnouncement')"
            v-bind:workflowSummaries="workflowSummaries"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:statusUpdate="apiData.workflowSummaryUpdate"
            v-bind:statusUpdateRetry="updateWorkflowSummaries"
            v-bind:hide="hideElement(1)"
            v-if="declaration && declaration.existsInConfig(declaration.getStepTarget(declaration.configTargets.squad))"
        />

        <list
            v-bind:items="players"
            v-bind:title="$t('declaredPlayers')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(1)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.squad)"
        >
            <template v-slot:header>
                <validations
                    ref="playerValidations"
                    v-bind:declaration="declaration"
                    v-bind:items="players"
                    type="player"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-players')) : 0"
                />
                <playerRow
                    showValidationBiography showValidationPassport showValidationHeadshot
                    showPosition showJerseyNumber showActionsPositionSelect
                    showRemove showDetail editImg
                    isHeadline
                    v-bind:declaration="declaration"
                />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow
                    showValidationBiography showValidationPassport showValidationHeadshot
                    showPosition showJerseyNumber showActionsPositionSelect
                    showRemove showDetail editImg
                    v-bind:person="person"
                    v-bind:role="role"
                    v-bind:declaration="declaration"
                    v-bind:removeHandler="removePerson"
                    v-bind:biographyUpdateHandler="updatePersonBiography"
                    v-bind:headshotUpdateHandler="updateHeadshot"
                    v-bind:passportUpdateHandler="updatePassport"
                    v-on:positionSelect="setPersonPosition"
                    v-on:informationUpdated="updateWorkflowSummaries"
                    v-on:jerseyNumberToggle="setJerseyNumber"
                />
            </template>
            <template v-slot:footer>
                <simpleRow
                    v-bind:text="$tc('addPlayer')"
                    showAdd
                    v-b-modal.addPlayerModal
                />
            </template>
            <template v-slot:empty>
                <div/>
            </template>
        </list>

        <list
            v-bind:items="managers"
            v-bind:title="$t('declaredManagement')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(1)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.squad)"
        >
            <template v-slot:header>
                <validations
                    ref="managerValidations"
                    v-bind:declaration="declaration"
                    v-bind:items="managers"
                    type="manager"
                    v-bind:totalManagement="totalManagement"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-managers')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-managers')) : 0"
                />
                <managerRow
                    showValidationBiography showValidationPassport showValidationHeadshot
                    showPosition showActionsPositionSelect
                    showRemove showDetail editImg
                    isHeadline
                    v-bind:declaration="declaration"
                />
            </template>
            <template v-slot="{ item: person, role }">
                <managerRow
                    showValidationBiography showValidationPassport showValidationHeadshot
                    showPosition showActionsPositionSelect
                    showRemove showDetail editImg
                    v-bind:person="person"
                    v-bind:role="role"
                    v-bind:declaration="declaration"
                    v-bind:removeHandler="removePerson"
                    v-bind:biographyUpdateHandler="updatePersonBiography"
                    v-bind:headshotUpdateHandler="updateHeadshot"
                    v-bind:passportUpdateHandler="updatePassport"
                    v-on:positionSelect="setPersonPosition"
                    v-on:informationUpdated="updateWorkflowSummaries"
                />
            </template>
            <template v-slot:footer>
                <simpleRow
                    v-bind:text="$tc('addManager')"
                    showAdd
                    v-b-modal.addManagerModal
                />
            </template>
            <template v-slot:empty>
                <div/>
            </template>
        </list>

        <list
            v-bind:items="medics"
            v-bind:title="$t('declaredMedicalTeam')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(1)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.squad)"
        >
            <template v-slot:header>
                <validations
                    ref="medicValidations"
                    v-bind:declaration="declaration"
                    v-bind:items="medics"
                    type="medic"
                    v-bind:totalManagement="totalManagement"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-medics')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-medics')) : 0"
                />
                <medicRow
                    showValidationBiography showValidationPassport showValidationMedicalCertificate showValidationHeadshot
                    showPosition showActionsPositionSelect
                    showRemove showDetail editImg
                    isHeadline
                    v-bind:declaration="declaration"
                />
            </template>
            <template v-slot="{ item: person, role }">
                <medicRow
                    showValidationBiography showValidationPassport showValidationMedicalCertificate showValidationHeadshot
                    showPosition showActionsPositionSelect
                    showRemove showDetail editImg
                    v-bind:person="person"
                    v-bind:role="role"
                    v-bind:declaration="declaration"
                    v-bind:removeHandler="removePerson"
                    v-bind:biographyUpdateHandler="updatePersonBiography"
                    v-bind:headshotUpdateHandler="updateHeadshot"
                    v-bind:passportUpdateHandler="updatePassport"
                    v-bind:medicalCertificateUpdateHandler="updateMedicalCertificate"
                    v-on:positionSelect="setPersonPosition"
                    v-on:informationUpdated="updateWorkflowSummaries"
                />
            </template>
            <template v-slot:footer>
                <simpleRow
                    v-bind:text="$tc('addMedic')"
                    showAdd
                    v-b-modal.addMedicModal
                />
            </template>
            <template v-slot:empty>
                <div/>
            </template>
        </list>

        <list
            v-bind:items="participationAgreements"
            v-bind:title="$t('participationAgreement')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(2)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.participationAgreement)"
        >
            <template v-slot:header v-if="errors.participationAgreement || !participationAgreementSubmitted || participationAgreementSubmitted">
                <validations
                    ref="participationAgreementValidations"
                    v-bind:acknowledgements="participationAgreementSubmitted ? [$t('submittingParticipationAgreementsInfo')] : undefined"
                    v-bind:warnings="!participationAgreementSubmitted ? [$t('submitParticipationAgreement')] : undefined"
                    v-bind:errors="errors.participationAgreement ? errors.participationAgreement.getErrorMessages($t('fileUploadError')) : undefined"
                    v-if="errors.participationAgreement || !participationAgreementSubmitted || participationAgreementSubmitted"
                />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow
                    showRemaining
                    loadingThrobber
                    v-bind:loadingWhile="apiData.participationAgreementUpdating"
                    v-bind:upload="upload"
                    v-bind:role="role"
                    v-bind:accept="constants.fileAcceptance.pdfAndTxt"
                    v-on:change="updateParticipationAgreement"
                />
            </template>
            <template v-slot:footer>
                <declarationComments
                    type="participationAgreement"
                    v-bind:declaration="declaration"
                    class="mt-3"
                    collapseEnabled
                    collapseHidden
                />
            </template>
        </list>

        <list
            v-bind:items="teamLogos"
            v-bind:title="$t('teamLogo')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(3)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.teamLogo)"
        >
            <template v-slot:header v-if="errors.teamLogo || teamLogoSubmitted || !teamLogoSubmitted">
                <validations
                    ref="teamLogoValidations"
                    v-bind:successes="(teamLogoSubmitted) ? [$t('submittedTeamLogo')] : undefined"
                    v-bind:warnings="!teamLogoSubmitted ? [$t('submitTeamLogo')] : undefined"
                    v-bind:errors="errors.teamLogo ? errors.teamLogo.getErrorMessages($t('fileUploadError')) : undefined"
                    v-if="errors.teamLogo || teamLogoSubmitted || !teamLogoSubmitted"
                />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow
                    showRemaining
                    loadingThrobber
                    v-bind:loadingWhile="apiData.teamLogoUpdating"
                    v-bind:upload="upload"
                    v-bind:role="role"
                    v-bind:accept="constants.fileAcceptance.images"
                    v-on:change="updateTeamLogo"
                />
            </template>
            <template v-slot:footer>
                <declarationComments
                    type="teamLogo"
                    v-bind:declaration="declaration"
                    class="mt-3"
                    collapseEnabled
                    collapseHidden
                />
            </template>
        </list>

        <list
            v-bind:items="nationalAnthems"
            v-bind:title="$t('nationalAnthem')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(4)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.nationalAnthem)"
        >
            <template v-slot:header v-if="errors.nationalAnthem">
                <validations
                    ref="nationalAnthemValidations"
                    v-bind:errors="errors.nationalAnthem ? errors.nationalAnthem.getErrorMessages($t('fileUploadError')) : undefined"
                    v-if="errors.nationalAnthem"
                />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow
                    showRemaining
                    showNationalAnthemSubmitMessages
                    loadingThrobber
                    v-bind:loadingWhile="apiData.nationalAnthemUpdating"
                    v-bind:upload="upload"
                    v-bind:role="role"
                    v-bind:accept="constants.fileAcceptance.audio"
                    v-on:change="updateNationalAnthem"
                />
            </template>
            <template v-slot:footer>
                <declarationComments
                    type="nationalAnthem"
                    v-bind:declaration="declaration"
                    class="mt-3"
                    collapseEnabled
                    collapseHidden
                />
            </template>
        </list>

        <list
            v-bind:items="medicalInsuranceCovers"
            v-bind:title="$t('medicalInsuranceCover')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(5)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.medicalInsuranceCover)"
        >
            <template v-slot:header v-if="errors.medicalInsuranceCover || !medicalInsuranceCoverSubmitted">
                <validations
                    ref="medicalInsuranceCoverValidations"
                    v-bind:warnings="!medicalInsuranceCoverSubmitted ? [$t('submitMedicalInsuranceCover')] : undefined"
                    v-bind:errors="errors.medicalInsuranceCover ? errors.medicalInsuranceCover.getErrorMessages($t('fileUploadError')) : undefined"
                    v-if="errors.medicalInsuranceCover || !medicalInsuranceCoverSubmitted"
                />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow
                    showRemaining
                    loadingThrobber
                    v-bind:loadingWhile="apiData.medicalInsuranceCoverUpdating"
                    v-bind:upload="upload"
                    v-bind:role="role"
                    v-bind:accept="constants.fileAcceptance.imagesAndPdf"
                    v-on:change="updateMedicalInsuranceCover"
                />
            </template>
            <template v-slot:footer>
                <declarationComments
                    type="medicalInsuranceCover"
                    v-bind:declaration="declaration"
                    class="mt-3"
                    collapseEnabled
                    collapseHidden
                />
            </template>
        </list>

        <list
            v-bind:items="teamDisciplinaryCertificates"
            v-bind:title="$t('teamDisciplinaryCertificates')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(6)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.teamDisciplinaryCertificate)"
        >
            <template v-slot:header v-if="errors.teamDisciplinaryCertificate || !teamDisciplinaryCertificateSubmitted">
                <validations
                    ref="teamDisciplinaryCertificateValidations"
                    v-bind:warnings="!teamDisciplinaryCertificateSubmitted ? [$t('submitTeamDisciplinaryCertificate')] : undefined"
                    v-bind:errors="errors.teamDisciplinaryCertificate ? errors.teamDisciplinaryCertificate.getErrorMessages($t('fileUploadError')) : undefined"
                    v-if="errors.teamDisciplinaryCertificate || !teamDisciplinaryCertificateSubmitted"
                />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow
                    showRemaining
                    loadingThrobber
                    v-bind:loadingWhile="apiData.teamDisciplinaryCertificateUpdating"
                    v-bind:upload="upload"
                    v-bind:role="role"
                    v-bind:accept="constants.fileAcceptance.imagesAndPdf"
                    v-on:change="updateTeamDisciplinaryCertificate"
                />
            </template>
            <template v-slot:footer>
                <declarationComments
                    type="teamDisciplinaryCertificate"
                    v-bind:declaration="declaration"
                    class="mt-3"
                    collapseEnabled
                    collapseHidden
                />
            </template>
        </list>

        <list
            v-bind:title="$t('kitDeclaration')"
            v-bind:items="kits"
            isRow
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(7)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.kit)"
        >
            <template v-slot:header>
                <validations
                    ref="kitValidations"
                    v-bind:items="kits"
                    type="teamKit"
                    v-bind:min="2"
                />
            </template>
            <template v-slot:top>
                <declarationStatusBlock v-bind:declarationStatus="kitsStatus"/>
            </template>
            <template v-slot="{ item: teamKit, role }">
                <teamKitRow
                    v-bind:teamKitPrimaryUpdate="apiData.teamKitPrimaryUpdating"
                    v-bind:teamKitSecondaryUpdate="apiData.teamKitSecondaryUpdating"
                    v-bind:teamKit="teamKit"
                    v-bind:role="role"
                    v-on:kitUpdate="updateTeamKit"
                />
            </template>
            <template v-slot:footer>
                <declarationComments
                    type="kit"
                    v-bind:declaration="declaration"
                    collapseEnabled
                    collapseHidden
                />
            </template>
        </list>

        <list
            v-bind:items="teamMemberAcceptanceForms"
            v-bind:title="$t('teamMemberAcceptanceForm')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(8)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.teamMemberAcceptanceForm)"
        >
            <template v-slot:header v-if="errors.teamMemberAcceptanceForm">
                <validations
                    ref="teamMemberAcceptanceFormValidations"
                    v-bind:errors="errors.teamMemberAcceptanceForm ? errors.teamMemberAcceptanceForm.getErrorMessages($t('fileUploadError')) : undefined"
                    v-if="errors.teamMemberAcceptanceForm"
                />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow
                    showRemaining
                    v-bind:upload="upload"
                    v-bind:role="role"
                    v-bind:accept="constants.fileAcceptance.imagesAndPdf"
                    v-on:change="updateTeamMemberAcceptanceForm"
                />
            </template>
            <template v-slot:footer>
                <declarationComments
                    type="teamMemberAcceptanceForm"
                    v-bind:declaration="declaration"
                    class="mt-3"
                    collapseEnabled
                    collapseHidden
                />
            </template>
        </list>

        <list
            v-bind:items="teamPhoneticGuides"
            v-bind:title="$t('teamPhoneticGuide')"
            v-bind:waitFor="apiData.seriesDeclaration"
            v-bind:waitForRetry="reloadSeriesDeclaration"
            v-bind:hide="hideElement(9)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.teamPhoneticGuide)"
        >
            <template v-slot:header v-if="errors.teamPhoneticGuide">
                <validations
                    ref="teamPhoneticGuideValidations"
                    v-bind:errors="errors.teamPhoneticGuide ? errors.teamPhoneticGuide.getErrorMessages($t('fileUploadError')) : undefined"
                    v-if="errors.teamPhoneticGuide"
                />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow
                    showRemaining
                    showTeamPhoneticGuideSubmitMessages
                    loadingThrobber
                    v-bind:loadingWhile="apiData.teamPhoneticGuideUpdating"
                    v-bind:upload="upload"
                    v-bind:role="role"
                    v-bind:accept="constants.fileAcceptance.documentAndAudio"
                    v-on:change="updateTeamPhoneticGuide"
                />
            </template>
            <template v-slot:footer>
                <declarationComments
                    type="teamPhoneticGuide"
                    v-bind:declaration="declaration"
                    class="mt-3"
                    collapseEnabled
                    collapseHidden
                />
            </template>
        </list>

        <playerPickModal
            id="addPlayerModal"
            multiselect isFullSearch
            v-bind:title="$t('addPlayer')"
            v-bind:disableSportFilter="true"
            v-bind:listNotices="pickerNotices"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:listHideItems="selectedPlayers"
            v-bind:filterValues="{ unionEqId, role: constants.personRoleType.player, isPersonActive: true, sport }"
            v-bind:selectHandler="addPlayers"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.squad)"
        />
        <managerPickModal
            id="addManagerModal"
            multiselect isFullSearch
            v-bind:title="$t('addManager')"
            v-bind:disableSportFilter="true"
            v-bind:listNotices="pickerNotices"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:listHideItems="selectedManagers"
            v-bind:filterValues="{ unionEqId, role: constants.personRoleType.management, isPersonActive: true, sport }"
            v-bind:selectHandler="addManagers"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.squad)"
        />
        <medicPickModal
            id="addMedicModal"
            multiselect isFullSearch
            v-bind:title="$t('addMedic')"
            v-bind:disableSportFilter="true"
            v-bind:listNotices="pickerNotices"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:listHideItems="selectedMedics"
            v-bind:filterValues="{ unionEqId, role: constants.personRoleType.management, isPersonActive: true, sport }"
            v-bind:selectHandler="addMedics"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.squad)"
        />
        <confirmModal
            id="confirmSubmit"
            v-bind:text="$t('areYouSureYouWantTo', { action: $t('submitSeriesDeclaration') }) + '?'"
            v-bind:confirmHandler="submitTeamDeclaration"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { declarationDetailDisplayMixins, declarationDetailEditMixins, declarationDetailSeriesMixins } from '@/mixins/declarationDetail.js';
import detailsHeader from '@/components/detailsHeader.vue';
import subNavigation from '@/components/subnavigation.vue';
import validations from '@/components/validations.vue';
import declarationComments from '@/components/declarationComments.vue';
import declarationWorkflowSummaries from '@/components/declarationWorkflowSummaries.vue';

import declarationStatusBlock from '@/components/blocks/declarationStatus.vue';

import playerRow from '@/components/rows/player.vue';
import managerRow from '@/components/rows/manager.vue';
import medicRow from '@/components/rows/medic.vue';
import uploadRow from '@/components/rows/upload.vue';
import simpleRow from '@/components/rows/simple.vue';
import teamKitRow from '@/components/rows/teamKit.vue';

import playerPickModal from '@/components/modals/pick/player.vue';
import managerPickModal from '@/components/modals/pick/manager.vue';
import medicPickModal from '@/components/modals/pick/medic.vue';
import confirmModal from '@/components/modals/confirm.vue';

export default {
    name: 'declarationSeriesDetail',
    extends: base,
    mixins: [
        declarationDetailDisplayMixins,
        declarationDetailEditMixins,
        declarationDetailSeriesMixins,
    ],
    components: {
        detailsHeader,
        subNavigation,
        validations,
        declarationComments,
        declarationWorkflowSummaries,

        declarationStatusBlock,

        playerRow,
        managerRow,
        medicRow,
        uploadRow,
        simpleRow,
        teamKitRow,

        playerPickModal,
        managerPickModal,
        medicPickModal,
        confirmModal,
    },
    data(){
        return {
            apiData: {
                isAllowedToSubmit: false,
                seriesDeclarationUpdate: null,
            },
            pickerNotices: [
                this.$t('unionPersonsOnly'),
            ],
            pickerFilterDisplay: {
                dateFrom: false,
                dateUntil: false,
                team: false,
                isPersonActive: true,
            },
        };
    },
};
</script>
