<script>
import vselectElement from '../vselect.vue';

/**
 * vselect element for PublishStatus items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectPublishStatusElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            const publishStatuses = {
                Private: {
                    id: 'Private',
                    label: this.$t('private'),
                },
                Unpublished: {
                    id: 'Unpublished',
                    label: this.$t('unpublished'),
                },
                Public: {
                    id: 'Public',
                    label: this.$t('public'),
                },
            };

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return Object.values(publishStatuses).filter(item => !text || item.label.toLowerCase().includes(text.toLowerCase()));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => Promise.resolve(publishStatuses[id])));
                }
            };

            return config;
        },
    },
};
</script>
