<script>
import formComponent from '@/components/form.vue';
import TournamentStandingModel from '@/models/tournamentStanding.js';

/**
 * component
 */
export default {
    name: 'editStandingForm',
    extends: formComponent,
    props: {
        model: {
            type: TournamentStandingModel,
        },
        sport: {
            type: String,
            default: null,
        },
    },
    methods: {
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('standingName'),
                        placeholder: this.$t('enterStandingName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'typeId',
                        type: 'vselectStandingType',
                        label: this.$t('standingType'),
                        placeholder: this.$t('selectStandingType'),
                        disabled: this.readonly,
                        required: true,
                        data: {
                            sport: this.sport,
                        },
                    },
                    {
                        id: 'stageId',
                        type: 'vselectTournamentStage',
                        label: this.$t('stage'),
                        placeholder: this.$t('selectStage'),
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                standingTypeId: 'typeId',
                tournamentStageId: 'stageId',
            };
        },
    },
};
</script>
