import Model from '@/models/model.js';
import MatchStatusModel from '@/models/matchStatus.js';
import VenueModel from '@/models/venue.js';
import StatusGroupModel from '@/models/statusGroup.js';
import ActionTypeSetModel from '@/models/actionTypeSet.js';
import TeamModel from '@/models/team.js';
import MatchConditionModel from '@/models/matchCondition.js';

/**
 * match summary model class
 */
export default class MatchSummaryModel extends Model {
    /**
     * attendance
     *
     * @type {number|null}
     */
    attendance = null;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.eqId = data.eqId || '';
            this.kickOff = this.toDate(data.kickOff) || '';
            this.attendance = data.attendance || null;
            this.isUseWin = data.isUseWin || false;
            this.isManualScore = data.isManualScore || false;
            this.isTestForBothTeams = data.isTestForBothTeams || false;
            this.isExcludedFromWorldRankings = data.isExcludedFromWorldRankings || false;
            this.isCommissionersReportReceived = data.isCommissionersReportReceived || false;
            this.isEquivalentReportReceived = data.isEquivalentReportReceived || false;
            this.notes = data.notes || '';
            this.displayNotes = data.displayNotes || '';
            this.level = data.level || 0;
            this.sport = data.sport || '';
            this.matchStatus = data.matchStatus ? MatchStatusModel.from(data.matchStatus) : null;
            this.venue = data.venue ? VenueModel.from(data.venue) : null;
            this.statusGroup = data.statusGroup ? StatusGroupModel.from(data.statusGroup) : null;
            this.actionTypeSet = data.actionTypeSet ? ActionTypeSetModel.from(data.actionTypeSet) : null;
            this.publishStatus = data.publishStatus || '';
            this.teams = (data.teams || []).map(team => TeamModel.from(team));
            this.matchCondition = data.matchCondition ? MatchConditionModel.from(data.matchCondition) : null;
            this.matchClock = data.matchClock || '';
            this.referees = (data.referees && data.referees.length > 0) ? data.referees.map(item => item).join(', ') : '-';
        }
    }

    getReplacementsTeam1(){
        let replacementsTeam1 = [];

        if (this.teams && this.teams[0] && this.teams[0].replacements) {
            replacementsTeam1 = this.teams[0].replacements.map(replacement => this.formatReplacement(replacement));
        }

        return replacementsTeam1.join(', ');
    }

    getReplacementsTeam2(){
        let replacementsTeam2 = [];

        if (this.teams && this.teams[1] && this.teams[1].replacements) {
            replacementsTeam2 = this.teams[1].replacements.map(replacement => this.formatReplacement(replacement));
        }

        return replacementsTeam2.join(', ');
    }

    formatReplacement(replacement){
        if(!replacement || (!replacement.subOn && !replacement.subOff)) {
            return '';
        }

        if(replacement.subOn && replacement.subOff) {
            return replacement.subOn + ' for ' + replacement.subOff + ' (' + replacement.time + ')';
        }

        return (replacement.subOn ? 'Sub on ' + replacement.subOn : 'Sub off ' + replacement.subOff) + ' (' + replacement.time + ')';
    }
}
