<script>
import vselectElement from '../vselect.vue';
import MatchModel from '@/models/match.js';

/**
 * vselect element for Match items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectMatchElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            // add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                return this.$api.call.match.matchSearch({
                    tournamentEqId: config.tournamentEqId || null,
                    paging: this.usePagination(offset, 100),
                }).then(result => ((result.data || []).map(data => new MatchModel(data))));
            };

            // add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    case 'matchId':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.match.matchGetById(id)))
                            .then(results => results.map(data => new MatchModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
