<template>
    <div class="pageContent">
        <matchList
            v-bind:title="$tc('match', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.matches.delete"
            v-bind:teamEqId="teamEqId"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'commonMatchCreate' }"
                >
                    {{ $t('createMatch') }}
                </actionButton>
            </template>
        </matchList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import matchList from '@/components/lists/match.vue';

export default {
    name: 'commonMatchIndex',
    extends: base,
    components: {
        matchList,
    },
    props: {
        teamEqId: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            rowProps: {
                showVenue: true,
                showKickOffTime: true,
            },
        };
    },
};
</script>
