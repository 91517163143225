<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('apiAccess')"
            v-on:submit="updateApiConsumer"
            mainTitle
            isForm
            doubleButtons
            noShadow
            noLine
            noPadding
        >
            <template v-slot:buttons v-if="apiConsumer">
                <actionButton
                    v-bind:to="toApiConsumerList"
                    v-bind:loadingWhile="apiData.apiConsumerUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.apiConsumerUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot>
                <wrapper
                    v-bind:title="$t('basicData')"
                    v-bind:waitFor="apiData.apiConsumer"
                    v-bind:waitForRetry="reloadApiConsumer"
                >
                    <template v-slot:header v-if="error">
                        <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
                    </template>
                    <template v-slot>
                        <apiConsumerForm
                            v-bind:model="apiConsumer"
                            v-bind:apiKeyLoadingWhile="apiData.apiKeyGeneration"
                            v-bind:errors="error ? error.getErrorIds() : undefined"
                            v-on:generateKey="generateKey"
                            v-on:deleteKey="deleteKey"
                        />
                    </template>
                </wrapper>

                <list
                    v-bind:title="$tc('ipWhitelist')"
                    v-bind:items="apiConsumer ? apiConsumer.whitelistedIpAddresses : []"
                    v-bind:waitFor="apiData.apiConsumer"
                    v-bind:waitForRetry="reloadApiConsumer"
                >
                    <template v-slot:header>
                        <ipAddressRow
                            showRemove
                            isHeadline
                        />
                    </template>
                    <template v-slot="{ item: ipAddress, role }">
                        <ipAddressRow
                            showRemove
                            v-bind:ipAddress="ipAddress"
                            v-bind:role="role"
                            v-bind:removeHandler="removeIpAddress"
                        />
                    </template>
                    <template v-slot:empty>
                        <div/>
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            v-bind:text="$tc('addIpWhitelist')"
                            showAdd
                            v-b-modal.ipWhitelistModal
                            v-if="apiConsumer"
                        />
                    </template>
                </list>

                <list
                    v-bind:title="$tc('tournament', 2)"
                    v-bind:items="apiConsumer ? apiConsumer.authorisedTournaments : []"
                    v-bind:waitFor="apiData.apiConsumer"
                    v-bind:waitForRetry="reloadApiConsumer"
                >
                    <template v-slot:header>
                        <tournamentRow
                            showDateRange showRemove
                            isHeadline
                        />
                    </template>
                    <template v-slot="{ item: tournament, role }">
                        <tournamentRow
                            showDateRange showRemove
                            v-bind:tournament="tournament"
                            v-bind:role="role"
                            v-bind:removeHandler="removeTournament"
                        />
                    </template>
                    <template v-slot:empty>
                        <div/>
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            v-bind:text="$t('clickToAdd', { type: $tc('tournament') })"
                            showAdd
                            v-b-modal.tournamentPick
                            v-if="apiConsumer"
                        />
                    </template>
                </list>

                <list
                    v-bind:title="$tc('apiService', 2)"
                    v-bind:items="apiResourceMethods"
                    v-bind:waitFor="apiData.apiConsumer"
                    v-bind:waitForRetry="reloadApiConsumer"
                >
                    <template v-slot:header>
                        <apiResourceMethodRow
                            showRemove
                            isHeadline
                        />
                    </template>
                    <template v-slot="{ item: apiResourceMethod, role }">
                        <apiResourceMethodRow
                            showRemove
                            v-bind:apiResourceMethod="apiResourceMethod"
                            v-bind:role="role"
                            v-on:removed="removeApiResourceMethod"
                        />
                    </template>
                    <template v-slot:empty>
                        <div/>
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            showAdd
                            v-bind:text="$tc('clickToAddApiService')"
                            v-b-modal.apiResourceMethodModal
                            v-if="apiConsumer"
                        />
                    </template>
                </list>
            </template>
        </wrapper>

        <ipWhitelistModal
            id="ipWhitelistModal"
            v-bind:title="$tc('ipWhitelist', 2)"
            v-on:confirmed="addIpAddress($event.payload)"
        />

        <tournamentPickModal
            id="tournamentPick"
            multiselect
            v-bind:title="$t('searchSeriesTournaments')"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:rowProps="pickerRowProps"
            v-bind:listHideItems="apiConsumer ? apiConsumer.authorisedTournaments : []"
            v-on:select="addTournaments"
        />

        <apiResourceMethodModal
            id="apiResourceMethodModal"
            v-bind:title="$tc('addApiService')"
            v-bind:confirmHandler="addApiResourceMethod"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';

import apiConsumerForm from '@/components/forms/apiConsumer.vue';

import tournamentRow from '@/components/rows/tournament.vue';
import apiResourceMethodRow from '@/components/rows/administration/apiResourceMethod.vue';
import ipAddressRow from '@/components/rows/administration/ipAddress.vue';
import simpleRow from '@/components/rows/simple.vue';

import apiResourceMethodModal from '@/components/modals/apiResourceMethod.vue';
import tournamentPickModal from '@/components/modals/pick/tournament.vue';
import ipWhitelistModal from '@/components/modals/ipWhitelist.vue';

import ApiConsumerModel from '@/models/apiConsumer.js';
import IpAddressModel from '@/models/ipAddress.js';

export default {
    name: 'administrationApiConsumerDetail',
    extends: base,
    components: {
        validations,
        apiConsumerForm,
        simpleRow,
        apiResourceMethodRow,
        ipAddressRow,
        tournamentRow,
        apiResourceMethodModal,
        tournamentPickModal,
        ipWhitelistModal,
    },
    props: {
        dataApiConsumerId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                apiConsumer: this.loadApiConsumer(),
                apiConsumerUpdate: null,
                apiKeyGeneration: null,
            },
            apiConsumer: null,
            error: null,
            pickerFilterDisplay: {
                lastAppearance: true,
                dateFrom: false,
                dateUntil: false,
                team: false,
            },
            pickerRowProps: {
                showDetails: true,
            },
        };
    },
    computed: {
        toApiConsumerList(){
            return { name: 'administrationApiConsumerIndex' };
        },
        apiResourceMethods(){
            if(!this.apiConsumer){
                return [];
            }

            return this.apiConsumer.getResourceMethods();
        },
    },
    methods: {
        loadApiConsumer(){
            return this.$api.call.admin.apiUserGetById(this.dataApiConsumerId).then(data => {
                return Promise.allSettled((data.authorisedTournaments || []).map(eqId => this.$api.call.tournament.tournamentGetByEqId(eqId))).then(tournaments => {
                    this.apiConsumer = new ApiConsumerModel(Object.assign({}, data, {
                        authorisedTournaments: tournaments.map(p => p.value).filter(tournament => tournament),
                    }));

                    //set isAuthorised in resourceMethods to prevent them getting filtered out in toJSON
                    this.apiConsumer.services.forEach(service => service.getResourceMethods().forEach(resourceMethod => (resourceMethod.isAuthorised = true)));
                });
            });
        },
        reloadApiConsumer(){
            this.apiData.apiConsumer = this.loadApiConsumer();
        },
        generateKey(){
            this.apiData.apiKeyGeneration = this.$api.call.admin.generateApiKey().then(response => {
                this.apiConsumer.apiKey = response.key;
            });
        },
        deleteKey(){
            this.apiConsumer.apiKey = null;
        },
        addIpAddress(formValues){
            if (formValues.ipType === 'ipSingle' && formValues.ipSingle.length > 0) {
                this.apiConsumer.addWhitelistedIpAddress(new IpAddressModel({
                    ipAddress: formValues.ipSingle,
                }));
            }
            if (formValues.ipType === 'ipRange') {
                if (formValues.ipRangeStart.length > 0 && formValues.ipRangeEnd.length > 0) {
                    this.apiConsumer.addWhitelistedIpAddress(new IpAddressModel({
                        ipAddress: formValues.ipRangeStart + ' - ' + formValues.ipRangeEnd,
                    }));
                }
            }
        },
        removeIpAddress(ipAddress) {
            this.apiConsumer.removeWhitelistedIpAddress(ipAddress);
        },
        addTournaments(tournaments){
            this.apiConsumer.addAuthorisedTournaments(tournaments);
        },
        removeTournament(tournament){
            this.apiConsumer.removeAuthorisedTournament(tournament);
        },
        addApiResourceMethod(apiResourceMethod){
            //set isAuthorised to prevent it getting filtered out in toJSON
            apiResourceMethod.isAuthorised = true;

            return this.apiConsumer.addResourceMethod(apiResourceMethod);
        },
        removeApiResourceMethod(data){
            this.apiConsumer.removeResourceMethod(data.apiResourceMethod);
        },
        updateApiConsumer(){
            this.apiData.apiConsumerUpdate = this.$api.call.admin.apiUserUpdate(this.dataApiConsumerId, this.apiConsumer.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toApiConsumerList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('apiConsumer update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
};
</script>
