import Vue from 'vue';
import VueRouter from 'vue-router';

import constants from '@/constants.js';

import homePage from '@/views/Home.vue';
import logoutPage from '@/views/logout.vue';
import errorPage from '@/views/error.vue';
import testPage from '@/views/test.vue';

import matchRoutes from './match.js';
import personRoutes from './person.js';
import seriesRoutes from './series.js';
import teamRoutes from './team.js';
import tournamentRoutes from './tournament.js';
import unionRoutes from './union.js';
import userRoutes from './user.js';
import venueRoutes from './venue.js';
import declarationRoutes from './declaration.js';
import reviewRoutes from './review.js';
import administrationRoutes from './administration.js';

Vue.use(VueRouter);

/**
 * vue router routes config
 *
 * @type {object}
 */
export const routes = [
    {
        path: '/home',
        name: constants.routeNames.home,
        component: homePage,
    },
    {
        path: '/logout',
        name: constants.routeNames.logout,
        component: logoutPage,
    },
    {
        path: '/error/:redirectPath*',
        name: constants.routeNames.error,
        component: errorPage,
        props: route => Object.assign({}, route.params, {
            redirectQuery: route.query,
        }),
    },
    {
        path: '/test',
        name: 'testPage',
        component: testPage,
    },
    {
        path: '*',
        redirect: '/home'
    },

    matchRoutes,
    personRoutes,
    seriesRoutes,
    teamRoutes,
    tournamentRoutes,
    unionRoutes,
    userRoutes,
    venueRoutes,
    declarationRoutes,
    reviewRoutes,
    administrationRoutes,
];

/**
 * vue router init
 *
 * @type {VueRouter}
 */
const router = new VueRouter({
    routes,
});

export default router;
