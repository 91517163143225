<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-bind:confirmHandler="confirmHandler"
        v-bind:confirmHandlerData="tournamentTeams"
    >
        <template v-slot="{ formId, submit }">
            <list v-bind:items="tournamentTeams">
                <template v-slot:header>
                    <listHeader
                        v-bind:elements="rowHeader"
                        class="d-none d-md-block"
                    />
                </template>
                <template v-slot="{ item: tournamentTeam }">
                    <changeTeamStatusForm
                        v-bind:dataId="formId"
                        v-bind:model="tournamentTeam"
                        v-bind:tournamentTeamStatuses="tournamentTeamStatuses"
                        v-bind:errors="errorIds"
                        v-on:submit="submit"
                    />
                </template>
            </list>
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import list from '@/components/list.vue';
import listHeader from '@/components/listHeader.vue';
import changeTeamStatusForm from '@/components/forms/changeTeamStatus.vue';

const rowHeaderClasses = 'card-text py-sm-4 py-md-2 w-20 col-6';

/**
 *
 */
export default {
    name: 'changeTeamStatusModal',
    extends: base,
    components: {
        list,
        listHeader,
        changeTeamStatusForm,
    },
    data() {
        return {
            rowHeader: [
                {
                    label: this.$t('tournamentTeamNameShort'),
                    cssclass: rowHeaderClasses,
                },
                {
                    label: this.$t('tournamentTeamStatus'),
                    cssclass: rowHeaderClasses,
                },
            ],
            teamStatuses: [],
        };
    },
    props: {
        tournamentTeams: {
            type: Array,
            required: true,
            default(){
                return [];
            }
        },
        tournamentTeamStatuses: {
            type: Array,
            required: true,
            default(){
                return [];
            }
        },
    },
};
</script>
