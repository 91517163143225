<script>
import formComponent from '@/components/form.vue';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'personHeadshotForm',
    extends: formComponent,
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'firstName',
                        type: 'text',
                        label: this.$t('firstName'),
                        placeholder: this.$t('firstName'),
                        plaintext: this.readonly,
                        disabled: true,
                    },
                    {
                        id: 'lastName',
                        type: 'text',
                        label: this.$t('lastName'),
                        placeholder: this.$t('lastName'),
                        plaintext: this.readonly,
                        disabled: true,
                    }
                ]),
                this.createFormRow([
                    {
                        id: 'headshot',
                        type: 'file',
                        label: this.readonly ? this.$t('headshot') : this.$t('file'),
                        accept: constants.fileAcceptance.images,
                        disabled: this.readonly,
                        readonly: this.readonly,
                        previewOnTop: true,
                        previewLarge: this.readonly,
                    }
                ], null, null, 12, 12, 12),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
