<script>
import vselectElement from '../vselect.vue';
import TournamentStatusModel from '@/models/tournamentStatus.js';

/**
 * vselect element for TournamentStatusModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectTournamentStatusElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return this.$api.call.tournament.tournamentStatusGetAll()
                    .then(result => ((result || {}) || []).map(data => new TournamentStatusModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return this.$api.call.tournament.tournamentStatusGetAll()
                            .then(result => ((result || {}) || []).map(data => new TournamentStatusModel(data)))
                            .then(items => items.filter(tournamentStatus => initValues.includes(tournamentStatus.id)));
                }
            };

            return config;
        },
    },
};
</script>
