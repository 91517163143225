<script>
import personPickModal from './person.vue';
import managerList from '@/components/lists/manager.vue';

/**
 * manager pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'managerPickModal',
    extends: personPickModal,
    components: {
        managerList,
    },
    data(){
        return {
            listElement: managerList,
        };
    },
};
</script>
