<script>
import formComponent from '@/components/form.vue';
import DeclarationTOPModel from '@/models/declarationTOP.js';
import constants from '@/constants';

const uploadDependencies = DeclarationTOPModel.joinFormDependencies([
    DeclarationTOPModel.formDependencies.participationAgreementEnabled,
    DeclarationTOPModel.formDependencies.teamLogoEnabled,
    DeclarationTOPModel.formDependencies.nationalAnthemEnabled,
    DeclarationTOPModel.formDependencies.teamDisciplinaryCertificateEnabled,
    DeclarationTOPModel.formDependencies.teamPhoneticGuideEnabled,
]);

/**
 * component
 */
export default {
    name: 'preliminarySquadDeclarationForm',
    extends: formComponent,
    props: {
        model: {
            type: DeclarationTOPModel,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            id: 'preliminarySquadEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            disabled: true,
                        },
                        {
                            id: 'preliminarySquadDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.preliminarySquadEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.workflowId),
                this.createFormRow([
                    {
                        id: 'preliminarySquadMinimumNumberOfPlayers',
                        type: 'number',
                        label: this.$t('minimumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'preliminarySquadMaximumNumberOfPlayers',
                        type: 'number',
                        label: this.$t('maximumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.preliminarySquadEnabled),
                this.createFormRow([
                    {
                        id: 'preliminarySquadMinimumNumberOfManagers',
                        type: 'number',
                        label: this.$t('minimumNumberOfManagers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'preliminarySquadMaximumNumberOfManagers',
                        type: 'number',
                        label: this.$t('maximumNumberOfManagers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.preliminarySquadEnabled),
                this.createFormRow([
                    {
                        id: 'preliminarySquadMinimumNumberOfMedics',
                        type: 'number',
                        label: this.$t('minimumNumberOfMedics'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'preliminarySquadMaximumNumberOfMedics',
                        type: 'number',
                        label: this.$t('maximumNumberOfMedics'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.preliminarySquadEnabled),
                this.createFormRow([
                    {
                        id: 'preliminarySquadMinimumTotalNumberOfManagement',
                        type: 'number',
                        label: this.$t('minimumTotalNumberOfManagement'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'preliminarySquadMaximumTotalNumberOfManagement',
                        type: 'number',
                        label: this.$t('maximumTotalNumberOfManagement'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.preliminarySquadEnabled),
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('personHeadshots'),
                        },
                        {
                            id: 'preliminarySquadPersonHeadshotsEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'preliminarySquadPersonHeadshotsDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.preliminarySquadPersonHeadshotsEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('passportInformation'),
                        },
                        {
                            id: 'preliminarySquadPassportInformationEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'preliminarySquadPassportInformationDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.preliminarySquadPassportInformationEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('medicalCertificates'),
                        },
                        {
                            id: 'preliminarySquadMedicalCertificatesEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'preliminarySquadMedicalCertificatesDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.preliminarySquadMedicalCertificatesEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('biographyAndPhoneticGuide'),
                        },
                        {
                            id: 'preliminarySquadBioEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'preliminarySquadBioDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.preliminarySquadBioEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('personPositions'),
                        },
                        {
                            id: 'preliminarySquadPersonPositionEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'preliminarySquadPersonPositionDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.preliminarySquadPersonPositionEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.preliminarySquadEnabled),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('participationAgreement'),
                        },
                        {
                            id: 'participationAgreementEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'participationAgreementDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.participationAgreementEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamLogo'),
                        },
                        {
                            id: 'teamLogoEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamLogoDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamLogoEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('nationalAnthem'),
                        },
                        {
                            id: 'nationalAnthemEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'nationalAnthemDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.nationalAnthemEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('medicalInsuranceCover'),
                        },
                        {
                            id: 'medicalInsuranceCoverEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'medicalInsuranceCoverDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.medicalInsuranceCoverEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamDisciplinaryCertificate'),
                        },
                        {
                            id: 'teamDisciplinaryCertificateEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamDisciplinaryCertificateDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamDisciplinaryCertificateEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamPhoneticGuide'),
                        },
                        {
                            id: 'teamPhoneticGuideEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamPhoneticGuideDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamPhoneticGuideEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.workflowId),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('kitDeclaration'),
                        },
                        {
                            id: 'kitEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'kitDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.kitEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.workflowId),

                {
                    type: 'hline',
                    dependency: uploadDependencies,
                },
                {
                    type: 'headline',
                    text: this.$t('uploadForTOPTemplates'),
                    dependency: uploadDependencies,
                },
                this.createFormRow([
                    {
                        id: 'participationAgreementTemplate',
                        type: 'file',
                        label: this.$t('participationAgreement'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.participationAgreementEnabled,
                    },
                    {
                        id: 'teamLogoTemplate',
                        type: 'file',
                        label: this.$t('teamLogo'),
                        accept: constants.fileAcceptance.images,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamLogoEnabled,
                    },
                    {
                        id: 'nationalAnthemTemplate',
                        type: 'file',
                        label: this.$t('nationalAnthem'),
                        accept: constants.fileAcceptance.documentAndAudio,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.nationalAnthemEnabled,
                    },
                    {
                        id: 'teamDisciplinaryCertificateTemplate',
                        type: 'file',
                        label: this.$t('disciplinaryCertificate'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamDisciplinaryCertificateEnabled,
                    },
                    {
                        id: 'teamPhoneticGuideTemplate',
                        type: 'file',
                        label: this.$t('teamPhoneticGuide'),
                        accept: constants.fileAcceptance.documentAndAudio,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamPhoneticGuideEnabled,
                    },
                ], uploadDependencies),
                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
