import Model from '@/models/model.js';
import CountryModel from '@/models/country.js';

/**
 * person school model class
 */
export default class PersonSchoolModel extends Model {
    /**
     * country
     *
     * @type {string}
     */
    country = '';

    /**
     * country iso code
     *
     * @type {string}
     */
    countryIsoCode = '';

    /**
     * country name
     *
     * @type {string}
     */
    countryName = '';

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.eqId = data.eqId || data.schoolEqId || '';
            this.id = data.id || data.schoolId || 0;

            this.name = data.name || '';
            this.country = CountryModel.from(data.country);
            this.countryIsoCode = data.countryIsoCode || '';
            this.countryName = data.countryName || '';
        }
    }
}
