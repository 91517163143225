import buttonElement from './button.vue';
import buttonsElement from './buttons.vue';
import checkboxElement from './checkbox.vue';
import colorElement from './color.vue';
import dateElement from './date.vue';
import emailElement from './email.vue';
import fileElement from './file.vue';
import headlineElement from './headline.vue';
import hiddenElement from './hidden.vue';
import hlineElement from './hline.vue';
import iconElement from './icon.vue';
import imageElement from './image.vue';
import linkElement from './link.vue';
import listElement from './list.vue';
import numberElement from './number.vue';
import passwordElement from './password.vue';
import pickTeamElement from './pick/team.vue';
import pickTournamentElement from './pick/tournament.vue';
import pickVenueElement from './pick/venue.vue';
import pickLocationElement from './pick/location.vue';
import radioselectElement from './radioselect.vue';
import searchElement from './search.vue';
import selectElement from './select.vue';
import slotElement from './slot.vue';
import staticElement from './static.vue';
import textElement from './text.vue';
import textareaElement from './textarea.vue';
import timeElement from './time.vue';
import vselectElement from './vselect.vue';
import vselectActionTypeElement from './vselect/actionType.vue';
import vselectActionTypeSetElement from './vselect/actionTypeSet.vue';
import vselectApiResourceMethodElement from './vselect/apiResourceMethod.vue';
import vselectCountryElement from './vselect/country.vue';
import vselectKitColorElement from './vselect/kitColor.vue';
import vselectLocationElement from './vselect/location.vue';
import vselectEventTypeElement from './vselect/eventType';
import vselectLawElement from './vselect/law.vue';
import vselectMatchElement from './vselect/match';
import vselectMatchOfficialElement from './vselect/matchOfficial';
import vselectMatchStatusElement from './vselect/matchStatus';
import vselectMatchTeamElement from './vselect/matchTeam';
import vselectOfficialElement from './vselect/official.vue';
import vselectOfficialPositionElement from './vselect/officialPosition.vue';
import vselectParameterOrderElement from './vselect/parameterOrder.vue';
import vselectParameterPointElement from './vselect/parameterPoint.vue';
import vselectPermissionGroupElement from './vselect/permissionGroup.vue';
import vselectPersonRoleElement from './vselect/personRole.vue';
import vselectPlayerElement from './vselect/player.vue';
import vselectPositionElement from './vselect/position.vue';
import vselectPublishingAccessLevelElement from './vselect/publishingAccessLevel.vue';
import vselectPublishStatusElement from './vselect/publishStatus.vue';
import vselectRankingRunElement from './vselect/rankingRun.vue';
import vselectSchoolElement from './vselect/school.vue';
import vselectSeriesElement from './vselect/series.vue';
import vselectSeriesTypeElement from './vselect/seriesType.vue';
import vselectSportElement from './vselect/sport.vue';
import vselectStandingTypeElement from './vselect/standingType.vue';
import vselectStatusGroupElement from './vselect/statusGroup.vue';
import vselectSubscriptionTypeElement from './vselect/subscriptionType.vue';
import vselectTeamKitElement from './vselect/teamKit.vue';
import vselectTournamentTeamElement from './vselect/tournamentTeam.vue';
import vselectTeamTypeElement from './vselect/teamType';
import vselectTournamentDeclarationElement from './vselect/tournamentDeclaration';
import vselectTournamentElement from './vselect/tournament';
import vselectTournamentStageElement from './vselect/tournamentStage.vue';
import vselectTournamentStatusElement from './vselect/tournamentStatus.vue';
import vselectTournamentTypeElement from './vselect/tournamentType.vue';
import vselectTournamentTypeGroupElement from './vselect/tournamentTypeGroup.vue';
import vselectUnionElement from './vselect/union.vue';
import vselectUnionRegionElement from './vselect/unionRegion.vue';
import vselectUserStatusElement from './vselect/userStatus.vue';
import vselectUserTypeElement from './vselect/userType.vue';
import vselectWorkflowElement from './vselect/workflow.vue';
import vselectWorkflowStatusElement from './vselect/workflowStatus.vue';

export default {
    buttonElement,
    buttonsElement,
    checkboxElement,
    colorElement,
    dateElement,
    emailElement,
    fileElement,
    headlineElement,
    hiddenElement,
    hlineElement,
    iconElement,
    imageElement,
    linkElement,
    listElement,
    numberElement,
    passwordElement,
    pickTeamElement,
    pickTournamentElement,
    pickVenueElement,
    pickLocationElement,
    radioselectElement,
    searchElement,
    selectElement,
    slotElement,
    staticElement,
    textElement,
    textareaElement,
    timeElement,
    vselectElement,
    vselectActionTypeElement,
    vselectActionTypeSetElement,
    vselectApiResourceMethodElement,
    vselectCountryElement,
    vselectKitColorElement,
    vselectLocationElement,
    vselectEventTypeElement,
    vselectLawElement,
    vselectMatchElement,
    vselectMatchOfficialElement,
    vselectMatchStatusElement,
    vselectMatchTeamElement,
    vselectOfficialElement,
    vselectOfficialPositionElement,
    vselectParameterOrderElement,
    vselectParameterPointElement,
    vselectPermissionGroupElement,
    vselectPersonRoleElement,
    vselectPlayerElement,
    vselectPositionElement,
    vselectPublishingAccessLevelElement,
    vselectPublishStatusElement,
    vselectRankingRunElement,
    vselectSchoolElement,
    vselectSeriesElement,
    vselectSeriesTypeElement,
    vselectSportElement,
    vselectStandingTypeElement,
    vselectStatusGroupElement,
    vselectSubscriptionTypeElement,
    vselectTeamKitElement,
    vselectTeamTypeElement,
    vselectTournamentDeclarationElement,
    vselectTournamentElement,
    vselectTournamentStageElement,
    vselectTournamentTeamElement,
    vselectTournamentStatusElement,
    vselectTournamentTypeElement,
    vselectTournamentTypeGroupElement,
    vselectUnionElement,
    vselectUnionRegionElement,
    vselectUserStatusElement,
    vselectUserTypeElement,
    vselectWorkflowElement,
    vselectWorkflowStatusElement,
};
