<script>
import vselectElement from '../vselect.vue';
import constants from '@/constants';

/**
 * vselect element for Kit Color, values from constants colors
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default {
    name: 'vselectKitColorElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};
            config.valueProperty = 'label';

            const kitColorItems = [];
            for (var color in constants.colors) {
                kitColorItems.push({
                    id: Number(color),
                    label: constants.colors[color]
                });
            }

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return kitColorItems;
            };

            return config;
        },
    },
};
</script>
