<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editSeries')"
            v-bind:waitFor="apiData.series"
            v-bind:waitForRetry="reloadSeries"
            v-on:submit="updateSeries"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toSeriesDetail"
                    v-bind:loadingWhile="apiData.seriesUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.seriesUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || declarationWarning || mappingWarning">
                <validations
                    v-bind:warnings="((declarationWarning || mappingWarning) ? [...(declarationWarning ? [declarationWarning] : []), ...(mappingWarning ? [mappingWarning] : [])] : undefined)"
                    v-bind:errors="error ? error.getErrorMessages($t('requestError')) : undefined"
                    v-if="error || declarationWarning || mappingWarning"
                />
            </template>
            <template v-slot>
                <editSeriesForm
                    v-bind:headline="$t('seriesInformation')"
                    v-bind:model="series"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-if="series"
                />
                <createEditSeriesTopForm
                    v-bind:headline="$t('seriesDeclarationTopInformation')"
                    v-bind:model="declarationTOP"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:sport="series.sport"
                    v-bind:isFixed="Boolean(declaration.id)"
                    v-if="series && declaration && declarationTOP"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingEditMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import editSeriesForm from '@/components/forms/editSeries.vue';
import createEditSeriesTopForm from '@/components/forms/createEditSeriesTop.vue';

import SeriesModel from '@/models/series.js';
import SeriesDeclarationModel from '@/models/declaration/series.js';
import WorkflowModel from '@/models/workflow.js';

export default {
    name: 'commonSeriesEdit',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingEditMixins,
    ],
    components: {
        validations,
        editSeriesForm,
        createEditSeriesTopForm,
    },
    props: {
        dataSeriesId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                series: this.loadSeries(),
                seriesUpdate: null,
            },
            series: null,
            declaration: null,
            declarationTOP: null,
            declarationWarning: null,
            mappingWarning: null,
            error: null,
        };
    },
    computed: {
        toSeriesDetail(){
            return { name: 'commonSeriesDetail', params: { dataSeriesId: this.dataSeriesId } };
        },
    },
    methods: {
        loadSeries(){
            return this.$api.call.tournament.seriesGetById(this.dataSeriesId, false).then(data => {
                this.series = SeriesModel.from(data);

                //get mapping providers
                this.loadEntityMappingProviders(this.series);

                return this.$api.call.termsOfParticipation.seriesDeclarationBySeriesEqId(this.series.eqId).catch(error => {
                    //if none found just use empty data
                    if(error.status === 404){
                        return {};
                    }
                    throw error;
                }).then(data => {
                    this.declaration = new SeriesDeclarationModel(data);
                    this.declarationTOP = this.declaration.getConfigurationFormModel();
                });
            });
        },
        reloadSeries(){
            this.apiData.series = this.loadSeries();
        },
        updateSeries(){
            let queue = Promise.resolve();

            //if logo upload file has been set, upload to storage and update series logo storage information
            if(this.series.logo instanceof File){
                queue = queue.then(() => this.$api.call.tournament.storeFiles(this.series.logo)).then(storageFile => {
                    this.series.setLogoStorage(storageFile);
                }, error => {
                    //set custom file upload validation error
                    error.errors.logo = this.$t('fileUploadError');

                    throw error;
                });
            }

            //check which files changed and are now File objects so they can be uploaded, only for enabled modules.
            const fileUploads = this.declarationTOP.getFileUploads();
            const fileUploadFiles = Object.values(fileUploads);

            //if files have changed, upload to storage and update series file storage information
            if(fileUploadFiles.length){
                queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles(fileUploadFiles)).then(storageFiles => {
                    this.declarationTOP.setFileStorages(fileUploads, storageFiles);
                }, error => {
                    throw this.declarationTOP.setFileErrors(fileUploads, error);
                });
            }

            //generate declaration config if enabled
            if(this.declarationTOP.enabled){
                //make sure series is set to declaration
                this.declaration.setSeries(this.series);

                //template
                if(this.declarationTOP.workflowId){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.declarationWorkflowsById(this.declarationTOP.workflowId)).then(data => {
                        const workflow = new WorkflowModel(data);

                        return this.$api.call.termsOfParticipation.declarationTemplateById(workflow.templateId).then(template => {
                            this.declaration.template = template;
                        });
                    });
                }

                //generate config from form data
                queue = queue.then(() => this.declaration.generateConfiguration(this.declarationTOP));
            }

            //update series
            queue = queue.then(() => this.$api.call.tournament.seriesUpdateById(this.dataSeriesId, this.series.toJSON()));

            //update mappings
            queue = this.manageMappings(this.series, queue);

            //submit declaration if enabled
            if(this.declarationTOP.enabled){
                const declarationErrorHandler = error => {
                    this.declarationWarning = this.$t('seriesDeclarationUpdateWarning');
                    error.errorIdParser = this.declaration.errorIdParser.bind(this.declaration);

                    throw error;
                };

                //update or create declaration
                if(this.declaration.id){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.seriesDeclarationUpdateById(this.declaration.id, this.declaration.toJSON()).catch(declarationErrorHandler));
                }
                else {
                    queue = queue.then(() => this.$api.call.termsOfParticipation.seriesDeclarationCreate(this.declaration.toJSON()).catch(declarationErrorHandler));
                }
            }

            //handle request outcome
            queue = queue.then(() => {
                this.error = null;
                this.$router.push(this.toSeriesDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('series update failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.seriesUpdate = queue;
        },
    },
}
</script>
