<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createUnion')"
            v-on:submit="createUnion"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toUnionsList"
                    v-bind:loadingWhile="apiData.unionCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.unionCreation"
                >
                    {{ $t('createUnion') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createUnionForm
                    v-bind:headline="$t('unionInformation')"
                    v-bind:model="union"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import createUnionForm from '@/components/forms/createUnion.vue';

import UnionModel from '@/models/union.js';

export default {
    name: 'commonUnionCreate',
    extends: base,
    components: {
        validations,
        createUnionForm,
    },
    data(){
        return {
            apiData: {
                unionCreation: null,
            },
            union: UnionModel.from({}),
            error: null,
        };
    },
    computed: {
        toUnionsList(){
            return { name: 'commonUnionIndex' };
        },
    },
    methods: {
        createUnion(){
            this.apiData.unionCreation = this.$api.call.union.unionCreate(this.union.toJSON()).then(response => {
                this.error = null;
                this.goToUnionDetail(response.id);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('union creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
        goToUnionDetail(id){
            this.$router.push({ name: 'commonUnionDetail', params: { dataUnionId: id } });
        },
    },
}
</script>
