<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('personInformation')"
            v-bind:waitFor="apiData.person"
            v-bind:waitForRetry="reloadPerson"
            v-on:submit="updatePerson"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toPersonDetail"
                    v-bind:loadingWhile="apiData.personUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.personUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || mappingWarning">
                <validations
                    v-bind:warnings="mappingWarning ? [mappingWarning] : undefined"
                    v-bind:errors="error ? error.getErrorMessages($t('requestError')) : []"
                    v-if="error || mappingWarning"/>
            </template>
            <template v-slot>
                <editPersonForm
                    v-bind:headline="$t('generalInformation')"
                    v-bind:model="person"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>

        <confirmModal
            id="confirmForce"
            v-bind:text="confirmForceMessage"
            v-bind:confirmHandler="forceUpdatePerson"
            v-if="$areAllowed(permissions.persons.forceUpdate.getWithChildren())"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingEditMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import editPersonForm from '@/components/forms/editPerson.vue';
import confirmModal from '@/components/modals/confirm.vue';

import PersonModel from '@/models/person.js';

export default {
    name: 'commonPersonEdit',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingEditMixins,
    ],
    components: {
        validations,
        editPersonForm,
        confirmModal,
    },
    props: {
        dataPersonId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                person: this.loadPerson(),
                personUpdate: null,
            },
            person: null,
            error: null,
            mappingWarning: null,
        };
    },
    computed: {
        toPersonDetail(){
            return { name: 'commonPersonDetail', params: { dataPersonId: this.dataPersonId } };
        },
        confirmForceMessage(){
            if(!this.error){
                return '';
            }

            return this.$t('updatePersonForce', { errorMsg: this.error.message });
        },
    },
    methods: {
        loadPerson(){
            return this.$api.call.person.personGetById(this.dataPersonId).then(data => {
                //also load identifiable info for passport data
                return this.$api.call.person.personIdentifiableInfoGetById(this.dataPersonId).then(pii => {
                    return Object.assign({ pii }, data);
                }, error => {
                    //ignore 404 errorsnull
                    if(error.status !== 404){
                        throw error;
                    }

                    if(this.$log){
                        this.$log.info('no person identifiable info found', error);
                    }

                    return Object.assign({}, data);
                });
            }).then(data => {
                this.person = PersonModel.from(data);

                //get mapping providers
                return this.loadEntityMappingProviders(this.person);
            });
        },
        reloadPerson(){
            this.apiData.person = this.loadPerson();
        },
        updatePerson(event, force = false){
            let queue = Promise.resolve();

            //check which files changed and are now File objects, so they can be uploaded
            const fileUploads = {};
            if(this.person._changedProperties.headshot !== undefined && this.person.headshot instanceof File){
                fileUploads.headshot = this.person.headshot;
            }
            if(this.person._changedProperties.passport !== undefined && this.person.passport instanceof File){
                fileUploads.passport = this.person.passport;
            }
            const fileUploadFiles = Object.values(fileUploads);

            //if files have changed, upload to storage and update series file storage information
            if(fileUploadFiles.length){
                queue = queue.then(() => this.$api.call.person.storeFiles(fileUploadFiles)).then(storageFiles => {
                    Object.keys(fileUploads).forEach((key, i) => {
                        const storageFile = storageFiles[i];
                        if(!storageFile){
                            return;
                        }

                        switch(key){
                            case 'headshot':
                                this.person.setHeadshotStorage(storageFile);
                                break;

                            case 'passport':
                                this.person.setPassportStorage(storageFile);
                                break;
                        }
                    });
                }, error => {
                    //set custom file upload validation error
                    if(fileUploads.headshot){
                        error.errors.headshot = this.$t('fileUploadError');
                    }
                    if(fileUploads.passport){
                        error.errors.passport = this.$t('fileUploadError');
                    }

                    throw error;
                });
            }

            //update person
            if(force){
                queue = queue.then(() => this.$api.call.person.forcePersonUpdateById(this.dataPersonId, this.person.toJSON()));
            }
            else {
                queue = queue.then(() => this.$api.call.person.personUpdateById(this.dataPersonId, this.person.toJSON()));
            }

            //update mappings
            queue = this.manageMappings(this.person, queue);

            queue = queue.then(() => {
                this.error = null;
                this.$router.push(this.toPersonDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('person update failed', error);
                }

                if(force){
                    throw error;
                }

                //show validation errors
                this.error = error;

                //show force update confirm message on 409 error
                if(error.status === 409 && this.$areAllowed(this.permissions.persons.forceUpdate.getWithChildren())){
                    this.$bvModal.show('confirmForce');
                }
            });

            if(force){
                return queue;
            }

            this.apiData.personUpdate = queue;
        },
        forceUpdatePerson(){
            return this.updatePerson(null, true);
        },
    },
}
</script>
