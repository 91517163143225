<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('seriesTeamEdit')"
            v-on:submit="updateSeriesTeam"
            mainTitle
            isForm
            doubleButtons
            noShadow
            noLine
            noPadding
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toSeriesTeamDetail"
                    v-bind:loadingWhile="apiData.seriesTeamUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.seriesTeamUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot>
                <wrapper
                    v-bind:waitFor="apiData.seriesTeam"
                    v-bind:waitForRetry="reloadSeriesTeam"
                >
                    <template v-slot:header v-if="error">
                        <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
                    </template>
                    <template v-slot>
                        <editSeriesTeamForm
                            v-bind:values="seriesTeamFormValues"
                            v-bind:model="seriesTeam"
                        />
                    </template>
                </wrapper>

                <subNavigation v-bind:data-config="subnavigation" />

                <list
                    v-bind:title="$t('seriesTeamPlayers')"
                    v-bind:items="seriesTeamPlayers"
                    v-bind:waitFor="apiData.seriesTeam"
                    v-bind:waitForRetry="reloadSeriesTeam"
                    draggable
                    v-on:change="updatePlayerOrder"
                    v-bind:hide="hideElement(1)"
                >
                    <template v-slot:header>
                        <playerRow
                            showDetail
                            isHeadline
                        />
                    </template>
                    <template v-slot="{ item: person, role }">
                        <playerRow
                            showDetail showRemove
                            v-bind:person="person"
                            v-bind:role="role"
                            v-bind:removeHandler="removePlayer"
                        />
                    </template>
                    <template v-slot:empty>
                        <div></div>
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            v-bind:text="$t('clickToAdd', { type: $tc('player') })"
                            showAdd
                            v-b-modal.playerPick
                        />
                    </template>
                </list>

                <list
                    v-bind:title="$t('seriesTeamManagement')"
                    v-bind:items="seriesTeamManagement"
                    v-bind:waitFor="apiData.seriesTeam"
                    v-bind:waitForRetry="reloadSeriesTeam"
                    draggable
                    v-on:change="updateManagerOrder"
                    v-bind:hide="hideElement(2)"
                >
                    <template v-slot:header>
                        <managerRow
                            showDetail
                            isHeadline
                        />
                    </template>
                    <template v-slot="{ item: person, role }">
                        <managerRow
                            showDetail showRemove
                            v-bind:person="person"
                            v-bind:role="role"
                            v-bind:removeHandler="removeManager"
                        />
                    </template>
                    <template v-slot:empty>
                        <div></div>
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            v-bind:text="$t('clickToAdd', { type: $tc('manager') })"
                            showAdd
                            v-b-modal.managerPick
                        />
                    </template>
                </list>

                <list
                    v-bind:title="$t('seriesTeamMedics')"
                    v-bind:items="seriesTeamMedic"
                    v-bind:waitFor="apiData.seriesTeam"
                    v-bind:waitForRetry="reloadSeriesTeam"
                    draggable
                    v-on:change="updateMedicOrder"
                    v-bind:hide="hideElement(3)"
                >
                    <template v-slot:header>
                        <medicRow
                            showDetail
                            isHeadline
                        />
                    </template>
                    <template v-slot="{ item: person, role }">
                        <medicRow
                            showDetail showRemove
                            v-bind:person="person"
                            v-bind:role="role"
                            v-bind:removeHandler="removeMedic"
                        />
                    </template>
                    <template v-slot:empty>
                        <div></div>
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            v-bind:text="$t('clickToAdd', { type: $tc('medic') })"
                            showAdd
                            v-b-modal.medicPick
                        />
                    </template>
                </list>
            </template>
        </wrapper>

        <playerPickModal
            id="playerPick"
            multiselect
            v-bind:title="$t('addPlayer')"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:filterValues="{ role: constants.personRoleType.player, sport, team: getTeam }"
            v-bind:rowProps="pickerRowProps"
            v-bind:listHideItems="selectedSeriesTeamPlayers"
            v-bind:selectHandler="addPlayers"
        />
        <managerPickModal
            id="managerPick"
            multiselect
            v-bind:title="$t('addManager')"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:filterValues="{ role: constants.personRoleType.management, sport, team: getTeam }"
            v-bind:rowProps="pickerRowProps"
            v-bind:listHideItems="selectedSeriesTeamManagementAndMedics"
            v-bind:selectHandler="addManagers"
        />
        <medicPickModal
            id="medicPick"
            multiselect
            v-bind:title="$t('addMedic')"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:filterValues="{ role: constants.personRoleType.management, sport, team: getTeam }"
            v-bind:rowProps="pickerRowProps"
            v-bind:listHideItems="selectedSeriesTeamManagementAndMedics"
            v-bind:selectHandler="addMedics"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import subNavigation from '@/components/subnavigation.vue';
import editSeriesTeamForm from '@/components/forms/series/team/edit.vue';
import playerRow from '@/components/rows/player.vue';
import managerRow from '@/components/rows/manager.vue';
import medicRow from '@/components/rows/medic.vue';
import simpleRow from '@/components/rows/simple.vue';
import constants from '@/constants.js';

import playerPickModal from '@/components/modals/pick/player.vue';
import managerPickModal from '@/components/modals/pick/manager.vue';
import medicPickModal from '@/components/modals/pick/medic.vue';

import SeriesTeamModel from '@/models/seriesTeam.js';
import SeriesModel from '@/models/series.js';
import PlayerModel from '@/models/person/player.js';
import ManagerModel from '@/models/person/manager.js';
import MedicModel from '@/models/person/medic.js';

export default {
    name: 'commonSeriesTeamEdit',
    extends: base,
    components: {
        validations,
        subNavigation,
        editSeriesTeamForm,
        playerRow,
        managerRow,
        medicRow,
        simpleRow,
        playerPickModal,
        managerPickModal,
        medicPickModal,
    },
    props: {
        dataSeriesId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataTeamId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            pickerFilterDisplay: {
                lastAppearance: false,
                dateFrom: true,
                dateUntil: true,
                team: true,
                isPersonActive: false,
            },
            pickerRowProps: {
                showDetails: true,
            },
            seriesTeamFormValues: null,
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$t('seriesTeamPlayers'),
                    },
                    2: {
                        label: this.$t('seriesTeamManagement'),
                    },
                    3: {
                        label: this.$t('seriesTeamMedics'),
                    },
                }
            },
            apiData: {
                seriesTeam: this.loadSeriesTeam(),
                series: this.loadSeries(),
                seriesTeamUpdate: null,
            },
            seriesTeam: null,
            series: null,
            error: null,
            seriesTeamPlayers: [],
            seriesTeamManagement: [],
            seriesTeamMedic: [],
        };
    },
    computed: {
        toSeriesTeamDetail(){
            return {
                name: 'commonSeriesTeamDetail',
                params: {
                    dataSeriesId: this.dataSeriesId,
                    dataTeamId: this.dataTeamId,
                },
            };
        },
        sport(){
            if(!this.series || !this.series.sport){
                return null;
            }

            return this.series.sport;
        },
        selectedSeriesTeamPlayers(){
            return this.seriesTeamPlayers.map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },
        selectedSeriesTeamManagement(){
            return this.seriesTeamManagement.map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },
        selectedSeriesTeamMedics(){
            return this.seriesTeamMedic.map(person => {
                person = person.clone();
                person.id = person.personId;
                return person;
            });
        },
        selectedSeriesTeamManagementAndMedics(){
            const management = this.selectedSeriesTeamManagement;

            return management.concat(this.selectedSeriesTeamMedics);
        },
        getTeam(){
            if(!this.seriesTeam || !this.seriesTeam.team){
                return null;
            }

            return this.seriesTeam.team || null;
        },
    },
    methods: {
        loadSeriesTeam(){
            return this.$api.call.tournament.seriesTeamGetById(this.dataTeamId).then(data => {
                if (data !== undefined) {
                    this.seriesTeam = SeriesTeamModel.from(data);
                    this.seriesTeamFormValues = this.getSeriesTeamFormValues();
                }

                this.reloadSeries();
                return this.loadSeriesTeamPersonnel();
            });
        },
        reloadSeriesTeam(){
            this.apiData.seriesTeam = this.loadSeriesTeam();
        },
        loadSeries(){
            // override get tournament to ensure we have a tournament to obtain the sport
            if(!this.seriesTeam){
                return new Promise((resolve, reject) => this.apiData ? this.apiData.seriesTeam.catch(reject) : '');
            }

            return this.$api.call.tournament.seriesGetById(this.seriesTeam.seriesId).then(data => {
                if (data !== undefined) {
                    this.series = SeriesModel.from(data);
                }
            });
        },
        reloadSeries(){
            this.apiData.series = this.loadSeries();
        },
        loadSeriesTeamPersonnel(){
            return this.$api.call.tournament.seriesTeamPersonnelBySeriesTeamId(this.dataTeamId).then(data => {
                if (data !== undefined) {
                    Object.values(data.seriesTeamPersonnel).forEach(entry => {
                        if(entry.roleName === constants.personRoleType.player){
                            this.seriesTeamPlayers.push(new PlayerModel(entry));
                        }
                        else if(entry.roleName === constants.personRoleType.management)
                        {
                            if(entry.group && entry.group === constants.group.medical){
                                this.seriesTeamMedic.push(new MedicModel(entry));
                            }
                            else
                            {
                                this.seriesTeamManagement.push(new ManagerModel(entry));
                            }
                        }
                    });
                }
            });
        },

        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },
        updateSeriesTeam(){
            let queue = Promise.resolve();

            queue = queue.then(() => this.$api.call.tournament.seriesTeamUpdate(this.dataTeamId, this.seriesTeam.isTourMember));

            queue = queue.then(() => {
                this.error = null;
                this.$router.push(this.toSeriesTeamDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('update series team failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.seriesTeamUpdate = queue;
        },
        setTeam(data){
            this.seriesTeam = new SeriesTeamModel(data.team);
            this.seriesTeamFormValues = this.getSeriesTeamFormValues();
        },
        getSeriesTeamFormValues(){
            if(!this.seriesTeam.teamName){
                return null;
            }

            return {
                team: this.seriesTeam,
                isTourMember: this.seriesTeam.isTourMember,
                points: this.seriesTeam.points,
                position: this.seriesTeam.position,
                seriesId: this.seriesTeam.seriesId,
                teamId: this.seriesTeam.teamId,
                teamName: this.seriesTeam.teamName,
                teamSourceEqId: this.seriesTeam.teamSourceEqId,
            };
        },
        addPerson(person){
            const personRole = person.getCurrentRole();

            if(!personRole){
                return Promise.reject(Error('player has no role'));
            }

            let dataToSave = {
                personEqId: person.eqId,
            }

            switch (true) {
                case person instanceof MedicModel:
                    dataToSave.group = constants.group.medical;
                    break;
                case person instanceof ManagerModel:
                    dataToSave.group = constants.group.management;
                    break;
                default:
                    dataToSave.group = constants.group.player;
                    break;
            }

            // save player
            return this.$api.call.tournament.seriesTeamAddPersonBySeriesTeamIdAndPersonEqId(this.dataTeamId, dataToSave).catch(err => {
                if(this.$log){
                    this.$log.error(err);
                }
                return Promise.reject(Error(this.$t('requestError')));
            });
        },
        removePerson(person){
            return this.$api.call.tournament.seriesTeamDeletePersonByPersonId(person.id).catch(err => {
                if(this.$log){
                    this.$log.error(err);
                }
                return Promise.reject(Error(this.$t('requestError')));
            });
        },
        addPlayers(players){
            /**
             * Add personRoleSourceEqId for each player.
             * We need this to create a new series team person.
             */
            return Promise.all(players.map(player => {
                return this.addPerson(player).then(data => {
                    this.seriesTeamPlayers.push(new PlayerModel(data));
                });
            }));
        },
        removePlayer(selectedPlayer) {
            return this.removePerson(selectedPlayer).then(() => {
                this.seriesTeamPlayers = this.seriesTeamPlayers.filter(player => player.id !== selectedPlayer.id);
            });
        },
        addManagers(managers){
            /**
             * Add personRoleSourceEqId for each manager.
             * We need this to create a new series team person.
             */
            return Promise.all(managers.map(manager => {
                return this.addPerson(manager).then(data => {
                    this.seriesTeamManagement.push(new ManagerModel(data));
                });
            }));
        },
        removeManager(selectedManager) {
            return this.removePerson(selectedManager).then(() => {
                this.seriesTeamManagement = this.seriesTeamManagement.filter(manager => manager.id !== selectedManager.id);
            });
        },
        addMedics(medics){
            /**
             * Add personRoleSourceEqId for each medic.
             * We need this to create a new series team person.
             */
            return Promise.all(medics.map(medic => {
                return this.addPerson(medic).then(data => {
                    this.seriesTeamMedic.push(new MedicModel(data));
                });
            }));
        },
        removeMedic(selectedMedic) {
            return this.removePerson(selectedMedic).then(() => {
                this.seriesTeamMedic = this.seriesTeamMedic.filter(medic => medic.id !== selectedMedic.id);
            });
        },
        updatePlayerOrder(players){
            this.seriesTeamPlayers = players;
        },
        updateManagerOrder(managers){
            this.seriesTeamManagement = managers;
        },
        updateMedicOrder(medics){
            this.seriesTeamMedic = medics;
        },
    },
};
</script>
