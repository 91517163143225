<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('activeUserAccess')"
            v-on:submit="updateUser"
            mainTitle
            isForm
            doubleButtons
            noShadow
            noLine
            noPadding
        >
            <template v-slot:buttons v-if="user">
                <actionButton
                    v-bind:to="toUserList"
                    v-bind:loadingWhile="apiData.userUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.userUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot>
                <wrapper
                    v-bind:waitFor="apiData.user"
                    v-bind:waitForRetry="reloadUser"
                    largeTitle
                >
                    <template v-slot:header v-if="error">
                        <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
                    </template>
                    <template v-slot>
                        <userEditForm
                            v-bind:headline="$t('externalUserAccount')"
                            v-bind:model="user"
                        />
                    </template>
                </wrapper>

                <list
                    v-bind:title="$t('rimsAccess')"
                    v-bind:items="user ? user.accesses : []"
                    v-bind:waitFor="apiData.user"
                    v-bind:waitForRetry="reloadUser"
                >
                    <template v-slot="{ item: userAccesses, role }">
                        <userAccessRow
                            v-bind:userAccesses="userAccesses"
                            v-bind:role="role"
                            v-bind:dataUserId="dataUserId"
                            @edit-access="setEditAccess"
                            @delete-access="confirmDeleteAccess"
                            readonly
                        />
                    </template>
                    <template v-slot:footer>
                        <simpleRow
                            v-bind:text="$t('clickToAddRimsAccess')"
                            showAdd
                            v-on:click="setNewRims()"
                            v-b-modal.modal-rims
                        />
                    </template>
                </list>
            </template>
        </wrapper>

        <confirmModal
            v-bind:id="'confirmDeleteRimsAccess'"
            v-bind:text="$t('pleaseConfirmDeletion', { name: selectedUserAccess ? selectedUserAccess.name : '' })"
            v-bind:data="selectedUserAccess"
            v-bind:confirmHandler="deleteAccessHandler"
            v-on:confirmed="emitEvent('removed')"
        />

        <userRimsModal
            v-bind:id="'modal-rims'"
            v-bind:title="userRimsModalTitle"

            v-bind:isEdit="userRimsModalIsEdit"
            v-bind:userAccesses="userAccesses"
            v-bind:values="rimsEdit"
            v-bind:enabledMatches="rimsMatches"
            v-bind:getFilteredMatches="filteredList"

            v-bind:confirmHandler="submitAccess"
            v-on:confirmed="submitAccessComplete"
            v-on:removeMatch="removeMatch"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import userEditForm from '@/components/forms/userEdit.vue';
import simpleRow from '@/components/rows/simple.vue';
import userAccessRow from '@/components/rows/administration/userAccess.vue';
import confirmModal from '@/components/modals/confirm.vue';

import userRimsModal from '@/components/modals/userRims.vue';
import UserModel from '@/models/user.js';
import UserAccessesModel from '@/models/userAccesses.js';
import MatchModel from '@/models/match.js';

export default {
    name: 'administrationUserDetail',
    extends: base,
    components: {
        validations,
        userEditForm,
        simpleRow,
        userAccessRow,
        userRimsModal,
        confirmModal,
    },
    props: {
        dataUserId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    data(){
        return {
            user: null,
            userAccesses: new UserAccessesModel(),
            error: null,
            rimsEdit: null,
            selectedUserAccess: null,
            matches: [],
            rimsMatches: [],
            userRimsModalTitle: 'Add RIMS access',
            userRimsModalIsEdit: false,
            apiData: {
                user: this.loadUser(),
                userUpdate: null,
            },
        };
    },
    computed: {
        toUserList(){
            return { name: 'administrationUserIndex' };
        },
    },
    methods: {
        loadUser(){
            return this.$api.call.admin.userGetById(this.dataUserId).then(data => {
                this.user = UserModel.from(data);
            });
        },
        reloadUser(){
            this.apiData.user = this.loadUser();
        },
        updateUser(){
            this.apiData.userUpdate = this.$api.call.admin.userUpdateById(this.user.id, this.user.toJSON()).then(() => {
                this.error = null;
                this.$router.push(this.toUserList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('user update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
        emitEvent(type, event, data){
            return this.$emit(type, Object.assign({
                tournament: this.tournament || null,
                event: event,
            }, data || {}));
        },
        setEditAccess: function(event, accessId){
            const userAccess = this.user.accesses.find(e => e.id === accessId).clone();
            const modalRimsName = 'modal-rims';
            if (userAccess) {
                if (!userAccess.allowAccessToAllMatches && userAccess.authorisedMatchEqIds.length) {
                    this.$api.call.match.matchSearch({
                        matchEqIds: userAccess.authorisedMatchEqIds,
                        paging: { pageIndex: 0, pageSize: 100 },
                    }).then(result => {
                        this.rimsMatches = (result ? (result.data || []) : []).map(data => new MatchModel(data));

                        this.userAccesses = userAccess;
                        this.userRimsModalIsEdit = true;
                        this.userRimsModalTitle = 'Edit RIMS access';
                        this.$bvModal.show(modalRimsName);
                    });
                }
                else
                {
                    this.userAccesses = userAccess;
                    this.userRimsModalIsEdit = true;
                    this.userRimsModalTitle = 'Edit RIMS access';
                    this.$bvModal.show(modalRimsName);
                }
            }
        },
        setSelectedUserAccess(id) {
            this.selectedUserAccess = this.user.accesses.filter(function (userAccess){
                return userAccess.id === id
            })[0];
        },
        submitAccess(userAccesses){
            if(this.userRimsModalIsEdit){
                return this.editUserAccess(userAccesses);
            } else {
                return this.createUserAccess(userAccesses);
            }
        },
        submitAccessComplete(){
            this.setNewRims();
            this.loadUser();
        },
        createUserAccess(newUserAccesses) {
            this.apiData.userUpdate = this.$api.call.admin.userAccessCreate(this.user.id, newUserAccesses.toJSON());

            return this.apiData.userUpdate;
        },
        editUserAccess(editUserAccesses) {
            this.apiData.userUpdate = this.$api.call.admin.userAccessUpdate(this.user.id, editUserAccesses.id, editUserAccesses.toJSON());

            return this.apiData.userUpdate;
        },
        confirmDeleteAccess: function(event, accessId){
            this.setSelectedUserAccess(accessId);
            this.$bvModal.show('confirmDeleteRimsAccess');
        },
        deleteAccessHandler(){
            this.apiData.userUpdate = this.$api.call.admin.userAccessDelete(this.user.id, this.selectedUserAccess.id).then(() => {
                this.loadUser();
            }, err => {
                this.$log.error(err);
            });
            this.selectedUserAccess = null;
        },
        modalName(type, id){
            return 'modal-' + type + '-' + id;
        },
        filteredList(values, enabled) {
            let matches = this.matches;

            // ignore matches already added
            const addedIds = this.rimsMatches.map(match => match.id);
            matches = matches.filter(match => (!addedIds.length || !addedIds.includes(match.id)));

            // free text filter
            const searchTerm = (values.filterName || '').toLowerCase();
            if (searchTerm) {
                matches = matches.filter(function(match){
                    return match.city.toLowerCase().indexOf(searchTerm) > -1 ||
                        match.kickOffTime.toLowerCase().indexOf(searchTerm) > -1 ||
                        match.serieName.toLowerCase().indexOf(searchTerm) > -1 ||
                        match.teamOne.toLowerCase().indexOf(searchTerm) > -1 ||
                        match.teamTwo.toLowerCase().indexOf(searchTerm) > -1 ||
                        match.tournamentName.toLowerCase().indexOf(searchTerm) > -1 ||
                        match.venue.toLowerCase().indexOf(searchTerm) > -1
                })
            }

            //filter for series, sport and tournament
            const filters = [
                {
                    key: 'serieId',
                    expected: values.filterSeries,

                },
                {
                    key: 'sportId',
                    expected: values.filterSports,

                },
                {
                    key: 'tournamentId',
                    expected: values.filterTournaments,

                },
            ];
            filters.forEach(filter => {
                if(!filter.key || !filter.expected){
                    return;
                }

                const expected = (filter.expected instanceof Array) ? filter.expected : [filter.expected];

                if(!expected.length || expected.includes(0)){
                    return;
                }

                matches = matches.filter(match => (match[filter.key] === undefined || expected.includes(match[filter.key])));
            });

            return matches;
        },
        removeMatch(selectedMatch) {
            this.rimsMatches = this.rimsMatches.filter(match => match.id !== selectedMatch.match.id);
        },
        setNewRims(){
            this.userAccesses = new UserAccessesModel();
            this.rimsEdit = null;
            this.rimsMatches = [];
            this.userRimsModalIsEdit = false;
            this.userRimsModalTitle = 'Add RIMS access';
        },
    },
};
</script>
