<script>
import itemList from '@/components/itemList.vue';
import tournamentTeamStatusTypesRow from '@/components/rows/administration/tournamentTeamStatusTypes.vue';
import TournamentTeamStatusTypeModel from '@/models/tournamentTeamStatusType.js';

/**
 * complex tournamentTeamStatusTypes list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'tournamentTeamStatusTypesList',
    extends: itemList,
    components: {
        tournamentTeamStatusTypesRow,
    },
    data(){
        return {
            //overwrites
            rowElement: tournamentTeamStatusTypesRow,
            rowItemPropName: 'tournamentTeamStatusTypes',
            selectItemProp: 'tournamentTeamStatusTypes',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.tournament.tournamentTeamStatusTypes().then(result => ({
                items: this.sortItems(
                    (result || []).map(data => new TournamentTeamStatusTypeModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: result.length,
            }));
        },
    },
};
</script>
