import Model from '@/models/model.js';

/**
 * series model class
 */
export default class SeriesTypeModel extends Model {
    /**
     * description
     *
     * @type {string}
     */
    description = '';

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * workflow status
     *
     * @type {string}
     */
    workflowStatus = '';

    /**
     * workflow deadline
     *
     * @type {string}
     */
    workflowDeadline = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.eqId = data.eqId || '';
            this.name = data.name || '';
            this.description = data.description || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            name: this.name || '',
            description: this.description || '',
        };
    }
}
