<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 1, null, null, true, { hasImg: showImg, noName: !showName, })" class="card-img-wrapper" v-if="showImg">
            <b-card-img
                v-bind="getColumnProps('image')"
                v-bind:src="tournamentDeclaration ? tournamentDeclaration.getTournament().logo : null"
                v-bind:alt="tournamentDeclaration.name"
                v-bind:width="imgWidth"
                v-bind:height="imgHeight"
                v-if="!hideNextStepValues"
            />
        </div>

        <b-card-title v-bind="getColumnProps('name', 2)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('seriesName')" v-if="isHeadline">{{ getHeadline('name', $t('tournamentName')) }}</label>
            <span v-if="!isHeadline && !hideNextStepValues">{{ tournamentDeclaration.tournament.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('dateRange', 12, 6, 2)" v-if="showDateRange">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateRange')">{{ getHeadline('dateRange', $t('tournamentDates')) }}</label>
            <span v-if="!isHeadline && !hideNextStepValues">{{ $fd(tournamentDeclaration.openingDate) }} - {{ $fd(tournamentDeclaration.getStepDeadlineDate()) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('declaration', 12, 6, 2)" v-if="showDeclaration">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('declaration')">{{ getHeadline('declaration', $tc('declaration', 1)) }}</label>
            <span v-bind:class="lockedClass" v-if="!isHeadline">{{ $t('declarationStep-' + tournamentDeclaration.step) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('status', 12, 6, 1)" v-if="showStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('status')">{{ getHeadline('status', $t('status')) }}</label>
            <statusIndicatorIcon
                v-bind:class="lockedClass"
                v-bind:status="tournamentDeclaration.getTeamDeclaration().getStepStatus()"
                v-bind:showText="true"
                v-bind:isLocked="tournamentDeclaration.locked"
                v-if="!isHeadline"
            />
        </b-card-text>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 1)" v-if="showSport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $tc('sport', 1)) }}</label>
            <span v-bind:class="lockedClass" v-if="!isHeadline">{{ tournamentDeclaration.sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('deadline', 12, 6, 2)" v-if="showDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('deadline')">{{ getHeadline('deadline', $t('deadline')) }}</label>
            <span v-bind:class="lockedClass" v-if="!isHeadline">{{ $fd(tournamentDeclaration.getStepDeadlineDate()) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 4, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-on:click="emitEvent('move', $event)"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 5)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicatorIcon from '../statusIndicatorIcon.vue';

import TournamentDeclarationModel from '@/models/declaration/tournament.js';

/**
 * tournament declaration block component
 */
export default {
    name: 'tournamentDeclarationRow',
    extends: base,
    components: {
        statusIndicatorIcon,
    },
    props: {
        tournamentDeclaration: {
            type: TournamentDeclarationModel,
            default(){
                return new TournamentDeclarationModel();
            }
        },

        showImg: {
            type: Boolean,
            default: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showDateRange: {
            type: Boolean,
            default: true,
        },
        showDeclaration: {
            type: Boolean,
            default: true,
        },
        showStatus: {
            type: Boolean,
            default: true,
        },
        showSport: {
            type: Boolean,
            default: true,
        },
        showDeadline: {
            type: Boolean,
            default: true,
        },

        /**
         * show button with arrow icon
         * clicking it will trigger "move" event
         */
        showDetail: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isReview: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        detailLink(){
            if (this.isReview) {
                return {
                    name: this.detailLinkRoute || 'reviewTournamentDetail',
                    params: Object.assign({
                        dataTournamentDeclarationId: this.tournamentDeclaration.id,
                    }, this.detailLinkParams),
                };
            }

            if (this.tournamentDeclaration.isSevens) {
                return {
                    name: this.detailLinkRoute || 'declarationTournamentDetail',
                    params: Object.assign({
                        dataTournamentDeclarationId: this.tournamentDeclaration.id,
                        dataTeamDeclarationId: (this.tournamentDeclaration.getTeamDeclaration() || {}).id,
                        dataDeclarationStep: this.tournamentDeclaration.step,
                    }, this.detailLinkParams),
                };
            }

            return {
                name: this.detailLinkRoute || 'declarationTournamentDetail',
                params: Object.assign({
                    dataTournamentDeclarationId: this.tournamentDeclaration.id,
                    dataTeamDeclarationId: (this.tournamentDeclaration.getTeamDeclaration() || {}).id,
                    dataDeclarationStep: this.tournamentDeclaration.step,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                tournamentDeclaration: this.tournamentDeclaration || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail);
        },
        customElementClasses(){
            return {
                noBottomBorderForFirstChild: this.hasNextStep,
            };
        },
        hasNextStep(){
            if (this.tournamentDeclaration.stepCount === 0 || this.tournamentDeclaration.stepTotalCount === 0){
                return false;
            }
            return this.tournamentDeclaration.stepCount < this.tournamentDeclaration.stepTotalCount;
        },
        hideNextStepValues(){
            if (this.tournamentDeclaration.stepCount === 0 || this.tournamentDeclaration.stepTotalCount === 0){
                return false;
            }
            return this.tournamentDeclaration.stepCount > 1;
        },
        lockedClass(){
            if(this.tournamentDeclaration.locked){
                return 'text-light';
            }
            return '';
        },
    },
};
</script>
