import Model from '@/models/model.js';
import LocationModel from '@/models/location.js';
import VenueAliasModel from '@/models/venueAlias.js';
import MatchModel from '@/models/match.js';
import constants from '@/constants';

/**
 * venue model class
 */
export default class VenueModel extends Model {
    /**
     * venueName
     *
     * @type {string}
     */
    name = '';

    /**
     * viewer capacity
     *
     * @type {number}
     */
    capacity = 0;

    /**
     * number of seats
     *
     * @type {number}
     */
    seats = 0;

    /**
     * record attendance
     *
     * @type {number}
     */
    recordAttendance = 0;

    /**
     * record detail
     *
     * @type {string}
     */
    recordDetail = '';

    /**
     * record game
     *
     * @type {null}
     */
    recordGame = null;

    /**
     * number of screens
     *
     * @type {number}
     */
    displayScreenCount = 0;

    /**
     * has lights
     *
     * @type {boolean}
     */
    hasLights = false;

    /**
     * has a roof
     *
     * @type {boolean}
     */
    hasRoof = false;

    /**
     * building date
     *
     * @type {Date|null}
     */
    built = null;

    /**
     * development date
     *
     * @type {Date|null}
     */
    developedDate = null;

    /**
     * construction cost
     *
     * @type {number}
     */
    cost = 0;

    /**
     * reconstruction cost
     *
     * @type {number}
     */
    redevelopmentCost = 0;

    /**
     * number of corporate boxes
     *
     * @type {number}
     */
    corporateBoxesCount = 0;

    /**
     * stadium website url
     *
     * @type {string}
     */
    stadiumWebsiteUrl = '';

    /**
     * Latitude
     *
     * @type {string}
     */
    latitude = '';

    /**
     * Longitude
     *
     * @type {string}
     */
    longitude = '';

    /**
     * alias
     *
     * @type {VenueAliasModel}
     */
     alias = null;

    /**
     * name aliases
     *
     * @type {VenueAliasModel[]}
     */
    aliases = [];

    /**
     * venue matches
     *
     * @type {MatchModel[]}
     */
    matches = [];

    /**
     * mapping providers
     *
     * @type {array}
     */
    mappingProviders = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.alias = (data.alias && typeof data.alias === 'object') ? VenueAliasModel.from(data.alias) : null;
            this.built = this.toDate(data.built);
            this.capacity = data.capacity || 0;
            this.cost = data.cost || 0;
            this.corporateBoxes = data.corporateBoxesCount > 0 || false;
            this.corporateBoxesCount = data.corporateBoxesCount || 0;
            this.countryIsoCode = data.countryIsoCode || data.countryCode || '';
            this.countryName = data.countryName || '';
            this.dateFrom = this.toDate(data.dateFrom);
            this.dateUntil = this.toDate(data.dateUntil);
            this.developedDate = this.toDate(data.developedDate);
            this.displayScreenCount = data.displayScreenCount || 0;
            this.displayScreens = data.displayScreenCount > 0 || false;
            this.eqId = data.eqId || data.sourceEqId || data.venueEqId || '';
            this.hasLights = data.hasLights || false;
            this.hasRoof = data.hasRoof || false;
            this.id = data.id || 0;
            this.lastDeveloped = this.toDate(data.lastDeveloped);
            this.location = LocationModel.from(data.location) || {};
            this.locationId = data.locationId || this.location?.id || 0;
            this.locationName = data.locationName || '';
            this.matches = (data.matches || []).map(match => new MatchModel(match));
            this.name = data.venueName || data.name || (this.alias ? this.alias.name : '') || '';
            this.redevelopmentCost = data.redevelopmentCost || 0;
            this.recordAttendance = data.recordAttendance || 0;
            this.recordDetail = data.recordDetail || '';
            this.recordGame = data.recordGame || 0;
            this.seats = data.seats || 0;
            this.sourceEqId = data.sourceEqId || '';
            this.stadiumWebsiteUrl = data.stadiumWebsiteUrl || '';
            this.latitude = data.latitude || '';
            this.longitude = data.longitude || '';
            this.venueAliasEqId = data.venueAliasEqId || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name || '';
    }

    toJSON() {
        return {
            locationId: this.location?.id || this.locationId || null,
            name: this.name,
            dateFrom: this.fromDate(this.dateFrom),
            dateUntil: this.fromDate(this.dateUntil),
            capacity: this.capacity,
            seats: this.seats,
            recordAttendance: this.recordAttendance,
            recordDetail: this.recordDetail,
            recordGame: this.recordGame,
            displayScreenCount: this.displayScreenCount,
            hasLights: this.hasLights,
            hasRoof: this.hasRoof,
            built: this.fromDate(this.built),
            lastDeveloped: this.fromDate(this.lastDeveloped),
            cost: this.cost,
            redevelopmentCost: this.redevelopmentCost,
            corporateBoxesCount: this.corporateBoxesCount,
            stadiumWebsiteUrl: this.stadiumWebsiteUrl,
            latitude: this.latitude === '' ? null : this.latitude,
            longitude: this.longitude === '' ? null : this.longitude,
        };
    }

    buildMappingPayloads() {
        if (!this.mappingProviders || !this.mappingProviders.length){
            return [];
        }

        return this.mappingProviders.map(mappingProvider => ({
            provider: mappingProvider.name,
            entity: constants.mappingEntityNames.venue,
            id: this[mappingProvider.name.toLowerCase()],
            eqId: this.eqId,
        }));
    }
}
