<template>
    <div class="pageContent">
        <administrationUserList
            v-bind:title="$t('userManagement')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.users.delete"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import administrationUserList from '@/components/lists/administration/user.vue';

export default {
    name: 'administrationUserIndex',
    extends: base,
    components: {
        administrationUserList,
    },
    data(){
        return {
            rowProps: {
                showNameSeparated: true,
                showAccountType: true,
                showPermissionGroup: true,
                showStatus: true,
            },
        };
    },
};
</script>
