<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-bind:scrollable="false"
    >
        <template v-slot="{ formId, submit }">
            <list v-bind:items="venueAliases" v-bind:title="$t('venueAliases')" noMargin>
                <template v-slot:header>
                    <listHeader v-bind:elements="rowHeader" class="d-none d-md-block"/>
                </template>
                <template v-slot="{ item: alias }">
                    <venueAliasesForm
                        v-bind:dataId="formId"
                        v-bind:alias="alias"
                        v-bind:values="values"
                        v-bind:aliasId="alias.id"
                        v-bind:errors="errorIds"
                        v-on:change="setFormData"
                        v-on:submit="submit"
                    />
                </template>
            </list>
        </template>
    </modal>
</template>
<script>
import base from '@/components/modals/base.vue';
import list from '@/components/list.vue';
import listHeader from '@/components/listHeader.vue';
import venueAliasesForm from '@/components/forms/venueAliases.vue';

export default {
    name: 'changeDateVenueAliasModal',
    extends: base,
    components: {
        list,
        listHeader,
        venueAliasesForm,
    },
    props: {
        venueAliases: {
            type: Array,
            default(){
                return [];
            },
        },
        dataVenueId: {
            type: Number,
            default(){
                return 0;
            }
        },
    },
    data(){
        return {
            dataForForm: {},
            formData: {},
            rowHeader: [
                {
                    label: this.$t('name'),
                    cssclass: 'card-text py-sm-4 py-md-2 w-20 col-4',
                },
                {
                    label: this.$t('validFrom'),
                    cssclass: 'card-text py-sm-4 py-md-2 w-20 col-5',
                },
                {
                    label: this.$t('newValidFrom'),
                    cssclass: 'card-text py-sm-4 py-md-2 w-20 col-3',
                },
            ],
        };
    },
    methods: {
        setFormData(data) {
            for (const [key, value] of Object.entries(data)) {
                this.dataForForm[key] = value;
            }
            this.formData = this.dataForForm;
        },
    },
    computed: {
        payload(){
            return this.formData;
        },
        values() {
            const values = {};

            if (this.venueAliases) {
                this.venueAliases.forEach(element => {
                    values['name_' + element.id] = element.name;
                    values['validFrom_' + element.id] = element.dateFrom ? this.$d(element.dateFrom, 'date') : '';
                    values['validFromDisplay_' + element.id] = element.dateFrom ? this.$fd(element.dateFrom, 'date') : null
                });
            }

            return values;
        },
    },
}
</script>
