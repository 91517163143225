import Model from '@/models/model.js';

export default class RatingChangeModel extends Model {
    resultType = '';

    lowerPointGapLimit = null;

    lowerRatingChange = null;

    upperPointGapLimit = null;

    upperRatingChange = null;

    lastUpdated = null;

    changedBy = '';


    constructor(data = {}) {
        super();
        this.id = data.id || 0;
        this.resultType = data.resultType || '';
        this.lowerPointGapLimit = data.lowerPointGapLimit || null;
        this.lowerRatingChange = data.lowerRatingChange || null;
        this.upperPointGapLimit = data.upperPointGapLimit || null;
        this.upperRatingChange = data.upperRatingChange || null;
    }
}
