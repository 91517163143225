<script>
import formComponent from '@/components/form.vue';
import UnionModel from '@/models/union.js';

/**
 * component
 */
export default {
    name: 'createUnionForm',
    extends: formComponent,
    props: {
        model: {
            type: UnionModel,
        },
    },
    methods: {
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('unionName'),
                        placeholder: this.$t('enterUnionName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'unionRegion',
                        type: 'vselectUnionRegion',
                        label: this.$t('regionalAssociation'),
                        placeholder: this.$t('selectRegion'),
                        disabled: this.readonly,
                        valueProperty: 'name',
                        required: true,
                    },
                    {
                        id: 'countryIsoCode',
                        name: 'countryIsoCode',
                        type: 'vselectCountry',
                        label: this.$t('country'),
                        placeholder: this.$t('selectCountry'),
                        disabled: this.readonly,
                        valueProperty: 'isoCode',
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                unionName: 'name',
            };
        },
    },
};
</script>
