<template>
    <div class="pageContent">
        <tournamentTypeList
            v-bind:title="$t('tournamentTypes')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.tournamentTypes.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'administrationTournamentTypeCreate' }"
                >
                    {{ $t('createType') }}
                </actionButton>
            </template>
        </tournamentTypeList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import tournamentTypeList from '@/components/lists/administration/tournamentType.vue';

export default {
    name: 'administrationTournamentTypeIndex',
    extends: base,
    components: {
        tournamentTypeList,
    },
    data(){
        return {
            rowProps: {
                showTournamentGroup: true,
                showParticipants: true,
                showDetail: true,
                showDelete: true,
            },
        };
    },
};
</script>
