<script>
import itemList from '@/components/itemList.vue';
import seriesReviewRow from '@/components/rows/seriesReview.vue';
import SeriesDeclarationModel from '@/models/declaration/series.js';

/**
 * complex series review list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'reviewSeriesList',
    extends: itemList,
    components: {
        seriesReviewRow,
    },
    props: {
        statuses: {
            type: Array,
            default: null,
        },
        deadlineDateOrder: {
            type: String,
            default: null,
        },
    },
    data(){
        return {
            //overwrites
            rowElement: seriesReviewRow,
            rowItemPropName: 'seriesDeclaration',
            selectItemProp: 'seriesDeclaration',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
                showStatus: true,
                showDeadline: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName'),
                },
                {
                    id: 'submissionDateOrder',
                    type: 'vselect',
                    placeholder: this.$t('dateOfSubmission'),
                    options: [
                        {
                            id: 'asc',
                            label: this.$t('ascending'),
                        },
                        {
                            id: 'desc',
                            label: this.$t('descending'),
                        },
                    ],
                },
                {
                    id: 'status',
                    type: 'vselectWorkflowStatus',
                    placeholder: this.$t('filterByStatus'),
                },
                {
                    id: 'series',
                    type: 'vselectSeries',
                    placeholder: this.$tc('series', 1),
                    valueProperty: 'eqId',
                },
                {
                    id: 'sport',
                    type: 'vselectSport',
                    placeholder: this.$tc('sport', 1),
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load series declarations
            return this.$api.call.termsOfParticipation.seriesDeclarationSearch({
                seriesEqId: filters.series || null,
                seriesName: filters.name || null,
                sport: filters.sport || null,
                statuses: this.statuses || (filters.status ? [filters.status] : null),
                deadlineDateOrder: this.deadlineDateOrder || undefined,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.declarations || []).map(data => new SeriesDeclarationModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
    },
};
</script>
