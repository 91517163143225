<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-bind:scrollable="false"
    >
        <template v-slot="{ formId, submit }">
            <addSchoolForm
                v-bind:dataId="formId"
                v-bind:errors="errorIds"
                v-on:change="setSchool"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import addSchoolForm from '@/components/forms/addSchool.vue';

export default {
    name: 'addSchoolModal',
    extends: base,
    components: {
        addSchoolForm,
    },
    data() {
        return {
            school: null,
        }
    },
    computed: {
        payload(){
            return this.school || 0;
        },
    },
    methods: {
        setSchool(formValues){
            this.school = formValues.school;
        },
    },
};
</script>
