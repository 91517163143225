<script>
import itemList from '@/components/itemList.vue';
import calculationsRow from '@/components/rows/administration/calculation.vue';
import CalculationModel from '@/models/calculations.js';

/**
 * complex calculation list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'calculationList',
    extends: itemList,
    components: {
        calculationsRow,
    },
    props: {
        /**
         * show card
         */
        sport: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            //overwrites
            rowElement: calculationsRow,
            rowItemPropName: 'calculation',
            selectItemProp: 'calculation',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showEdit: true,
                showRemove: true,
            };
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.rankings.calculationGetBySport(this.sport).then(result => {
                return {
                    items: this.sortItems(
                        (result || []).map(data => new CalculationModel(data)),
                        sortKey,
                        sortAsc,
                        pageIndex,
                        pageSize,
                    ),
                    total: result.length,
                };
            });
        },

        requestItemRemove(item){
            return this.$api.call.rankings.calculationDeleteById(item.id);
        },
    },
};
</script>
