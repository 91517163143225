import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonTournamentMatchCreate from '@/views/common/tournament/match/create.vue';

export default {
    path: 'match/',
    component: pageFrame,
    children: [
        {
            name: 'commonTournamentMatchCreate',
            path: constants.routePaths.create,
            component: commonTournamentMatchCreate,
            props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
            meta: {
                permission: permissions.tournaments.matches.create,
            },
        },
    ],
};
