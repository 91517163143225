<script>
import formComponent from '@/components/form.vue';
import VenueAliasModel from '@/models/venueAlias.js';

/**
 * component
 */
export default {
    name: 'editVenueAliasForm',
    extends: formComponent,
    props: {
        model: {
            type: VenueAliasModel,
        },
    },
    methods: {
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('alias'),
                        placeholder: this.$t('stadiumName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
