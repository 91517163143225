<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('addTournamentTeam')"
            v-bind:waitFor="apiData.tournament"
            v-bind:waitForRetry="reloadTournament"
            v-on:submit="createTournamentTeam"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentDetail"
                    v-bind:loadingWhile="apiData.tournamentTeamCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentTeamCreation"
                >
                    {{ $t('addTeam') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createTournamentTeamForm
                    v-bind:model="tournamentTeam"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:sport="tournament ? tournament.sport : null"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import createTournamentTeamForm from '@/components/forms/createTournamentTeam.vue';

import TournamentModel from '@/models/tournament.js';
import TournamentTeamModel from '@/models/tournamentTeam.js';

export default {
    name: 'commonTournamentTeamCreate',
    extends: base,
    components: {
        validations,
        createTournamentTeamForm,
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                tournament: this.loadTournament(),
                tournamentTeamCreation: null,
            },
            tournament: null,
            tournamentTeam: new TournamentTeamModel({
                tournamentId: this.dataTournamentId,
            }),
            error: null,
        };
    },
    computed: {
        toTournamentDetail(){
            return { name: 'commonTournamentDetail', params: { dataTournamentId: this.dataTournamentId } };
        },
    },
    methods: {
        loadTournament(){
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId).then(data => {
                this.tournament = new TournamentModel(data);
            });
        },
        reloadTournament(){
            this.apiData.tournament = this.loadTournament();
        },

        createTournamentTeam(){
            this.apiData.tournamentTeamCreation = this.$api.call.tournament.tournamentTeamCreateByTournamentId(this.tournamentTeam.toJSON()).then(response => {
                this.error = null;
                this.goToTournamentDetail();
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentTeam creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
        goToTournamentDetail(){
            this.$router.push({
                name: 'commonTournamentDetail',
                params: {
                    dataTournamentId: this.dataTournamentId,
                },
            });
        },
    },
};
</script>
