<template>
    <div class="pageContent">
        <permissionGroupList
            v-bind:title="$t('permissionGroups')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.permissionGroups.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'administrationPermissionGroupCreate' }"
                >
                    {{ $t('createPermissionGroup') }}
                </actionButton>
            </template>
        </permissionGroupList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import permissionGroupList from '@/components/lists/administration/permissionGroup.vue';

export default {
    name: 'administrationPermissionGroupIndex',
    extends: base,
    components: {
        permissionGroupList,
    },
    data(){
        return {
            rowProps: {
                showRemove: true,
            },
        };
    },
};
</script>
