<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('ip', 1)" v-bind:title-tag="titleTag">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('ip')" v-if="isHeadline">{{ getHeadline('ip', $t('ip')) }}</label>
            <span class="text-uppercase" v-else>{{ ipAddress.ip }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-if="showAdd"
                v-on:click="emitEvent('add', $event)"
            >
                <icon type="add" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-on:click="emitEvent('remove', $event)"
                v-b-modal="modalName('confirmDelete', uniqueId)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('move', $event)"
                v-bind:to="moveTo"
                v-if="showMove"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', uniqueId)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: ipAddress.ip })"
            v-bind:data="ipAddress"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
            v-if="!isHeadline"
        />
    </b-card>
</template>

<script>
import base from '../base.vue';

import IpAddressModel from '@/models/ipAddress.js';

/**
 * ipAddress row component
 */
export default {
    name: 'ipAddressRow',
    extends: base,
    props: {
        /**
         * ApiConsumer whitelist string
         */
        ipAddress: {
            type: IpAddressModel,
            default(){
                return new IpAddressModel();
            }
        },
        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showAdd: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with remove icon
         * clicking it will trigger "remove" event
         */
        showRemove: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with arrow icon
         * clicking it will trigger "move" event
         */
        showMove: {
            type: Boolean,
            default: false,
        },
        /**
         * to location for move button
         */
        moveTo: {
            type: [String, Object],
            default: null,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        emitEventData(){
            return {
                ipAddress: this.ipAddress || null,
            };
        },
        hasVisibleCircles(){
            return (this.showAdd || this.showRemove || this.showMove);
        },
        uniqueId(){
            if(this.ipAddress.id){
                return this.ipAddress.id;
            }
            return window.btoa(JSON.stringify(this.ipAddress));
        },
    },
};
</script>
