<script>
import formComponent from '@/components/form.vue';
import TournamentStandingTypeParameterModel from '@/models/tournamentStandingTypeParameter.js';

/**
 * component
 */
export default {
    name: 'addEditTournamentStandingTypeParameterForm',
    extends: formComponent,
    props: {
        model: {
            type: TournamentStandingTypeParameterModel,
        },
        hideValue: {
            type: Boolean,
            default: false,
        },
        hideDiscriminator: {
            type: Boolean,
            default: false,
        },
        parameterType: {
            type: String,
            default: '',
        },
    },
    methods: {
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'typeId',
                        type: 'vselectParameter' + this.parameterType[0].toUpperCase() + this.parameterType.slice(1),
                        label: this.$t('parameterType'),
                        placeholder: '',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'value',
                        type: 'number',
                        label: this.$t('value'),
                        placeholder: '',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        hidden: this.hideValue,
                        required: true,
                    },
                    {
                        id: 'discriminator',
                        type: 'number',
                        label: this.$t('discriminator'),
                        placeholder: '',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        hidden: this.hideDiscriminator,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                parameterTypeId: 'typeId',
            };
        },
    },
};
</script>
