<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editTournamentStage')"
            v-bind:waitFor="apiData.tournamentStage"
            v-bind:waitForRetry="reloadTournamentStage"
            v-on:submit="updateTournamentStage"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentStageList"
                    v-bind:loadingWhile="apiData.tournamentStageUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentStageUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <editTournamentStageForm
                    v-bind:headline="$t('tournamentStageInformation')"
                    v-bind:model="tournamentStage"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import editTournamentStageForm from '@/components/forms/editTournamentStage.vue';

import TournamentStageModel from '@/models/tournamentStage.js';

export default {
    name: 'administrationTournamentStageEdit',
    extends: base,
    components: {
        validations,
        editTournamentStageForm,
    },
    props: {
        dataTournamentStageId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                tournamentStage: this.loadTournamentStage(),
                tournamentStageUpdate: null,
            },
            tournamentStage: null,
            error: null,
        };
    },
    computed: {
        toTournamentStageList(){
            return { name: 'administrationTournamentStageIndex' };
        },
    },
    methods: {
        loadTournamentStage(){
            return this.$api.call.tournament.tournamentStageGetById(this.dataTournamentStageId).then(data => {
                this.tournamentStage = TournamentStageModel.from(data);
            });
        },
        reloadTournamentStage(){
            this.apiData.tournamentStage = this.loadTournamentStage();
        },
        updateTournamentStage(){
            this.apiData.tournamentStageUpdate = this.$api.call.tournament.tournamentStageUpdateById(this.dataTournamentStageId, this.tournamentStage.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toTournamentStageList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentStage update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
