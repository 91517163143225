<template>
    <div class="bg-primary px-4 py-3"
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)">
        <b-list-group>
            <b-list-group-item class="notification" data-icon="series">{{ notification.message || '' }}</b-list-group-item>
            <b-list-group-item><span>{{ $t(entityTypeToLocalReference) }}</span></b-list-group-item>
            <b-list-group-item class="notification-time" v-if="notification.isRead">{{ $fd(notification.timestamp, 'dateTime') }}</b-list-group-item>
            <b-list-group-item class="notification-time notification-new" v-if="!notification.isRead">{{ $fd(notification.timestamp, 'dateTime') }}</b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import base from './base.vue';

import NotificationModel from '@/models/notification.js';

/**
 * notifiation row component
 */
export default {
    name: 'notificationRow',
    extends: base,
    props: {
        notification: {
            type: NotificationModel,
            default(){
                return new NotificationModel();
            }
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        // timeSince(targetDate){
        //     var seconds = Math.floor((new Date() - targetDate) / 1000);

        //     var interval = seconds / 31536000;

        //     if (interval > 1) {
        //         return Math.floor(interval) + ' years ago';
        //     }
        //     interval = seconds / 2592000;
        //     if (interval > 1) {
        //         return Math.floor(interval) + ' months ago';
        //     }
        //     interval = seconds / 86400;
        //     if (interval > 1) {
        //         return Math.floor(interval) + ' days ago';
        //     }
        //     interval = seconds / 3600;
        //     if (interval > 1) {
        //         return Math.floor(interval) + ' hours ago';
        //     }
        //     interval = seconds / 60;
        //     if (interval > 1) {
        //         return Math.floor(interval) + ' minutes ago';
        //     }
        //     return Math.floor(seconds) + ' seconds ago';
        // },
    },
    computed: {
        emitEventData(){
            return {
                notification: this.notification || null,
            };
        },
        entityTypeToLocalReference(){
            const pascalCase = this.notification.entityType.split('-')
                .map(name => name.charAt(0).toUpperCase() + name.slice(1)).join('');

            return pascalCase.charAt(0).toLowerCase() + pascalCase.slice(1);
        }
    },
};
</script>
