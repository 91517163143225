<script>
import formComponent from '@/components/form.vue';
import TeamKitModel from '@/models/person/teamkit.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'teamKitForm',
    extends: formComponent,
    props: {
        model: {
            type: TeamKitModel,
        },
        matchTeamId: {
            type: Number,
            default: 0,
        },
        tournamentTeamId: {
            type: Number,
            default: 0,
        },
        hideSelectTeamKit: {
            type: Boolean,
            default() {
                return true;
            }
        },
    },
    computed: {
        elementsConfig(){
            return [
                {
                    id: 'image',
                    index: this.model.type,
                    type: 'file',
                    accept: constants.fileAcceptance.images,
                    label: this.$t('teamkitType' + this.model.type),
                    previewOnTop: true,
                    previewStyle: {
                        height: '100px',
                        border: '1px solid #e9ecef',
                    },
                    readonly: this.readonly,
                    disabled: this.readonly,
                },
                {
                    id: 'jerseyColor',
                    index: this.model.type,
                    type: 'vselectKitColor',
                    label: this.$t('jerseyColor'),
                    disabled: this.readonly,
                    required: true,
                },
                {
                    id: 'shortsColor',
                    index: this.model.type,
                    type: 'vselectKitColor',
                    label: this.$t('shortsColor'),
                    disabled: this.readonly,
                    required: true,
                },
                {
                    id: 'primarySocksColor',
                    index: this.model.type,
                    type: 'vselectKitColor',
                    label: this.$t('primarySocksColor'),
                    disabled: this.readonly,
                    required: true,
                },
                {
                    id: 'secondarySocksColor',
                    index: this.model.type,
                    type: 'vselectKitColor',
                    label: this.$t('secondarySocksColor'),
                    disabled: this.readonly,
                    required: true,
                },
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
