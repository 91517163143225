import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationSeriesTypesIndex from '@/views/administration/seriestypes/index.vue';
import administrationSeriesTypesCreate from '@/views/administration/seriestypes/create.vue';
import administrationSeriesTypesEdit from '@/views/administration/seriestypes/edit.vue';

export default {
    path: 'series-type/',
    component: pageFrame,
    children: [
        {
            name: 'administrationSeriesTypesIndex',
            path: constants.routePaths.index,
            component: administrationSeriesTypesIndex,
            meta: {
                permission: permissions.seriesTypes.list,
            },
        },
        {
            name: 'administrationSeriesTypesCreate',
            path: constants.routePaths.create,
            component: administrationSeriesTypesCreate,
            meta: {
                permission: permissions.seriesTypes.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.seriesTypeId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationSeriesTypesEdit',
                    path: constants.routePaths.edit,
                    component: administrationSeriesTypesEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.seriesTypeId]),
                    meta: {
                        permission: permissions.seriesTypes.update,
                    },
                },
            ],
        },
    ],
};
