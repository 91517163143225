<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 1, null, null, true, null)" class="card-img-wrapper">
            <span v-bind="getColumnProps('number')" v-bind:data-number="number" class="shirt-number-mobile">
                <b-card-img
                    src="@/assets/img/shirt-blue.svg"
                    v-bind:alt="number.toString()"
                />
            </span>
        </div>

        <b-card-title v-bind="getColumnProps('text', 1)" v-bind:title-tag="titleTag">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('text')" class="text-center" v-if="isHeadline">{{ getHeadline('text', '') }}</label>
            <span class="d-block text-center text-secondary" v-else>{{ text }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('add', $event)"
                v-if="showAdd"
            >
                <icon type="add" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from './base.vue';

/**
 * simple add player to match row component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'simpleAddPlayerToMatchRow',
    extends: base,
    props: {
        /**
         * text to show
         */
        text: {
            type: String,
            default: null,
        },
        /**
         * show the order number
         */
        number: {
            type: Number,
            default(){
                return null;
            },
        },
        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showAdd: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasVisibleCircles(){
            return (this.showAdd);
        },
    },
};
</script>
