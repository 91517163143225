import Model from '@/models/model.js';

/**
 * teamkit model class
 *
 * @author Abelardo Gil <a.jimenez@sportradar.com>
 */
export default class TeamKitModel extends Model {
    /**
     * possible teamkit types
     *
     * @type {object}
     */
    static types = {
        primary: 'Primary',
        secondary: 'Secondary',
    };

    /**
     * teamkit type
     *
     * @type {string}
     */
    type = '';

    /**
     * teamkit jersey color
     *
     * @type {string}
     */
    jerseyColor = '';

    /**
     * teamkit short color
     *
     * @type {string}
     */
    shortsColor = '';

    /**
     * teamkit primary socks color
     *
     * @type {string}
     */
    primarySocksColor = '';

    /**
     * teamkit secondary socks color
     *
     * @type {string}
     */
    secondarySocksColor = '';

    /**
     * teamkit image
     *
     * @type {string|File}
     */
    image = null;

    /**
     * teamkit image filename
     *
     * @type {string}
     */
    filename = null;

    /**
     * status
     *
     * @type {string}
     */
    status = null;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.tournamentTeamKitId || data.matchTeamKitId || data.eqId || 0;
            this.eqId = data.eqId || data.kitTypeEqId || '';
            this.type = data.type || data.kitType || this.convertTypeId(data.kitTypeId);
            this.jerseyColor = data.jerseyColour || data.jerseyColor || null;
            this.shortsColor = data.shortsColour || data.shortsColor || null;
            this.primarySocksColor = data.primarySocksColour || data.primarySocksColor || null;
            this.secondarySocksColor = data.secondarySocksColour || data.secondarySocksColor || null;
            this.tournamentTeamId = data.tournamentTeamId || 0;
            this.teamName = data.teamName || '';
            this.matchTeamId = data.matchTeamId || 0;
            this.usingDeclarationStorage = data.usingDeclarationStorage || false;

            this.setImageStorage(data.imageStorage || data.kitImageUrl || '');
        }
    }

    setImageStorage(imageStorage){
        this.imageStorage = imageStorage;

        //note: not sure why we need this check, it was implemented in WR-1655
        if(this.imageStorage.indexOf('_') > -1){
            if(this.usingDeclarationStorage){
                this.image = Model._api.call.termsOfParticipation.resolveStoragePath(this.imageStorage);
            }
            else {
                this.image = Model._api.call.tournament.resolveStoragePath(this.imageStorage);
            }
        }

        this.image = this.image || this.getDefaultImage();
    }

    getDefaultImage(){
        //add default image name to current base url without trailing slash
        return (window.location.origin + window.location.pathname).split('/').filter((part, i, parts) => (part || i < parts.length - 1)).join('/') + require('@/assets/img/shirt-blue.svg');
    }

    convertTypeId(typeId){
        switch (typeId){
            case 2:
                return TeamKitModel.types.secondary;

            case 1:
            default:
                return TeamKitModel.types.primary;
        }
    }

    isUndefined(){
        return !this.id;
    }

    /**
     * API: /terms-of-participation-service/api/team-declaration/{id}/modules/{id}/kit/Primary does not return an id for team kit
     * check property values exist for team kit row conditional statements
     */
    isUndefinedFromTop(){
        return (!this.jerseyColor && !this.shortsColor && !this.primarySocksColor && !this.secondarySocksColor && !this.imageStorage);
    }

    useDeclarationStorage(status = true){
        this.usingDeclarationStorage = Boolean(status);

        this.setImageStorage(this.imageStorage);
    }

    toJSON() {
        return {
            kitType: this.type,
            jerseyColour: this.jerseyColor || null,
            shortsColour: this.shortsColor || null,
            primarySocksColour: this.primarySocksColor || null,
            secondarySocksColour: this.secondarySocksColor || null,
            kitImageUrl: this.imageStorage || null,
            tournamentTeamId: this.tournamentTeamId || null,
        };
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.type;
    }
}
