<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToMatchDetailsLink"
        >
            <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
        </actionButton>

        <matchReportsHeader
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            v-bind:title="$t('teamsheets')"
            v-bind:data="teamsheets"
            v-bind:teamOneHeadCoach="teamOneHeadCoach"
            v-bind:teamTwoHeadCoach="teamTwoHeadCoach"
            v-bind:matchOfficials="matchOfficials"
        />

        <wrapper
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            noPadding noLine noShadow waitSilent
        >
            <h2 class="text-center mb-5 mt-5">{{ $t('lineup') }}</h2>
            <b-table
                v-bind:fields="tableFields"
                thead-class="d-none"
                v-bind:items="lineup"
                class="m-0"
                responsive
                hover
            >
                <template #cell(leftReplacement)="data">
                    <icon type="down-circle" color="danger" large v-if="data.item.leftReplacement"/>
                </template>
                <template #cell(rightReplacement)="data">
                    <icon type="down-circle" color="danger" large v-if="data.item.rightReplacement"/>
                </template>
                <template #cell(leftCard)="data">
                    <icon v-bind:type="'card-' + data.item.leftCard" large v-if="data.item.leftCard"/>
                </template>
                <template #cell(rightCard)="data">
                    <icon v-bind:type="'card-' + data.item.rightCard" large v-if="data.item.rightCard"/>
                </template>
                <template #cell(leftScoring)="data">
                    <icon type="ball" large v-if="data.item.leftScoring"/>
                </template>
                <template #cell(rightScoring)="data">
                    <icon type="ball" large v-if="data.item.rightScoring"/>
                </template>
            </b-table>

            <h2 class="text-center mb-5 mt-5">{{ $t('subs') }}</h2>
            <b-table
                v-bind:fields="tableFields"
                thead-class="d-none"
                v-bind:items="subs"
                class="m-0"
                responsive
                hover
            >
                <template #cell(leftReplacement)="data">
                    <icon type="up-circle" color="success" large v-if="data.item.leftReplacement"/>
                </template>
                <template #cell(rightReplacement)="data">
                    <icon type="up-circle" color="success" large v-if="data.item.rightReplacement"/>
                </template>
                <template #cell(leftCard)="data">
                    <icon v-bind:type="'card-' + data.item.leftCard" large v-if="data.item.leftCard"/>
                </template>
                <template #cell(rightCard)="data">
                    <icon v-bind:type="'card-' + data.item.rightCard" large v-if="data.item.rightCard"/>
                </template>
                <template #cell(leftScoring)="data">
                    <icon type="ball" large v-if="data.item.leftScoring"/>
                </template>
                <template #cell(rightScoring)="data">
                    <icon type="ball" large v-if="data.item.rightScoring"/>
                </template>
            </b-table>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import matchReportsHeader from '@/components/matchReportsHeader.vue';

import MatchTeamsheetModel from '@/models/matchTeamsheet.js';

export default {
    name: 'commonMatchTeamsheets',
    extends: base,
    components: {
        icon,
        matchReportsHeader,
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                teamsheets: this.loadTeamsheets(),
            },
            matchTournaments: [],
            matchOfficials: [],
            teamsheets: null,
            teamsheetTeam1Lineup: null,
            teamsheetTeam1Replacement: null,
            teamsheetTeam2Lineup: null,
            teamsheetTeam2Replacement: null,
            teamOneHeadCoach: '',
            teamTwoHeadCoach: '',
            tableFields: [
                {
                    key: 'leftReplacement',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'leftCard',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'leftScoring',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'leftCaptain',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'leftName',
                    label: '',
                    class: 'text-right',
                    tdClass: 'match-teamsheet-name-right',
                },
                {
                    key: 'number',
                    label: this.$t('lineup'),
                    class: 'text-center',
                },
                {
                    key: 'rightName',
                    label: '',
                    tdClass: 'match-teamsheet-name',
                },
                {
                    key: 'rightCaptain',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'rightScoring',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'rightCard',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'rightReplacement',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
            ],
        };
    },
    computed: {
        backToMatchDetailsLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
        lineup(){
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return [];
            }

            const teamsheetTeam1Lineup = this.teamsheets.teams[0].team.teamSheets.filter((item) => item.positionName !== 'Replacement');
            const teamsheetTeam2Lineup = this.teamsheets.teams[1].team.teamSheets.filter((item) => item.positionName !== 'Replacement');

            return this.formatTableData(teamsheetTeam1Lineup, teamsheetTeam2Lineup);
        },
        subs(){
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return [];
            }

            const teamsheetTeam1Subs = this.teamsheets.teams[0].team.teamSheets.filter((item) => item.positionName === 'Replacement');
            const teamsheetTeam2Subs = this.teamsheets.teams[1].team.teamSheets.filter((item) => item.positionName === 'Replacement');

            return this.formatTableData(teamsheetTeam1Subs, teamsheetTeam2Subs, true);
        },
        disciplinaries(){
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return {};
            }

            const map = {};

            [
                this.teamsheets.teams[0].team.disciplinary,
                this.teamsheets.teams[1].team.disciplinary,
            ].forEach(disciplinaryData => {
                ['yellow', 'red'].forEach(cardType => {
                    const disciplinaryList = disciplinaryData[cardType + 'Cards'] || [];

                    disciplinaryList.forEach(disciplinary => {
                        if(!disciplinary.personEqId){
                            return;
                        }

                        disciplinary.cardType = cardType;

                        map[disciplinary.personEqId] = disciplinary;
                    });
                });
            });

            return map;
        },
        scorings(){
            if(!this.teamsheets || this.teamsheets.teams.length < 2){
                return {};
            }

            const map = {};

            [
                this.teamsheets.teams[0].team.scoring,
                this.teamsheets.teams[1].team.scoring,
            ].forEach(scoreData => {
                ['conversion', 'tries', 'penalties', 'dropGoals'].forEach(scoreType => {
                    const scoreList = scoreData[scoreType] || [];

                    scoreList.forEach(score => {
                        if(!score.personEqId || !score.points){
                            return;
                        }

                        map[score.personEqId] = true;
                    });
                });
            });

            return map;
        },
    },
    methods: {
        loadTeamsheets(){
            return this.$api.call.match.matchReportsTeamsheetsGetByMatchId(this.dataMatchId).then(result => {
                this.teamsheets = new MatchTeamsheetModel(result);

                const teamOneHeadCoach = this.teamsheets.teams[0].team.teamSheets.filter((item) => item.positionName === 'Head Coach');
                this.teamOneHeadCoach = teamOneHeadCoach[0]?.displayName || '';

                const teamTwoHeadCoach = this.teamsheets.teams[1].team.teamSheets.filter((item) => item.positionName === 'Head Coach');
                this.teamTwoHeadCoach = teamTwoHeadCoach[0]?.displayName || '';

                return this.getMatchOfficials();
            });
        },
        reloadTeamsheets(){
            this.apiData.teamsheets = this.loadTeamsheets();
        },
        getMatchOfficials() {
            return this.$api.call.match.matchOfficialsGetByMatchId(this.dataMatchId).then(result => {
                this.matchOfficials = (result || []).map(official => ({
                    position: official.positionName,
                    name: official.displayName,
                    union: official.unionName,
                }));
            });
        },
        formatTableData(players1, players2, isSub = false){
            const map = {};
            const fillMap = (player, side) => {
                const number = parseInt(player.positionNumber) || player.getPlayerAttribute('shirtNumber');
                if(!number){
                    return;
                }
                if(!map[number]){
                    map[number] = {
                        number,
                        left: null,
                        right: null,
                    };
                }
                map[number][side] = player;
            };

            players1.forEach(player => fillMap(player, 'left'));
            players2.forEach(player => fillMap(player, 'right'));

            return Object.entries(map).sort(([number1], [number2]) => number1 - number2).map(([key, player]) => player).map(data => ({
                leftReplacement: isSub ? data.left?.getPlayerAttribute('isUsedReplacement') : data.left?.getPlayerAttribute('isReplaced'),
                leftCard: ((data.left?.eqId && this.disciplinaries[data.left?.eqId]) ? this.disciplinaries[data.left?.eqId].cardType : null),
                leftScoring: data.left?.eqId && this.scorings[data.left?.eqId],
                leftCaptain: data.left?.getPlayerAttribute('isCaptain') ? '(c)' : '',
                leftName: this.formatName(data.left, true),
                number: data.number,
                rightName: this.formatName(data.right),
                rightCaptain: data.right?.getPlayerAttribute('isCaptain') ? '(c)' : '',
                rightScoring: data.right?.eqId && this.scorings[data.right?.eqId],
                rightCard: ((data.right?.eqId && this.disciplinaries[data.right?.eqId]) ? this.disciplinaries[data.right?.eqId].cardType : null),
                rightReplacement: isSub ? data.right?.getPlayerAttribute('isUsedReplacement') : data.right?.getPlayerAttribute('isReplaced'),
            }));
        },
        formatName(teamsheet, isLeftSide = false){
            if(!teamsheet){
                return '';
            }

            let name = teamsheet.getName();

            if(teamsheet.teamsRepresented?.length){
                if(isLeftSide){
                    name = '(' + teamsheet.teamsRepresented[0].teamName + ') ' + name;
                }
                else
                {
                    name += ' (' + teamsheet.teamsRepresented[0].teamName + ')';
                }
            }

            return name;
        },
    },
};
</script>
