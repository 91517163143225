import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import { RequestError, CriticalError } from '@/errors.js';
import UserModel from '@/models/user.js';
import { initTracking } from '@/tracking.js';

export default class Auth {
    /**
     * auth base url
     *
     * @type {string}
     */
    baseUrl = '';

    /**
     * auth client id
     *
     * @type {string}
     */
    clientId = '';

    /**
     * auth client secret
     *
     * @type {string}
     */
    clientSecret = '';

    /**
     * instance of vue store
     *
     * @type {Vuex.Store}
     */
    store = null;

    /**
     * instance of log class
     *
     * @type {Log}
     */
    log = null;

    /**
     * instance of api class
     *
     * @type {Api}
     */
    api = null;

    /**
     * authenticated user
     *
     * @type {UserModel}
     */
    user = null;

    /**
     * user request promise
     *
     * @type {Promise}
     */
    userRequest = null;

    /**
     * request data scopes
     *
     * @type {String[]}
     */
    scopes = ['openid', 'email', 'profile', 'offline_access'];

    /**
     * Characters for generating code verifier defined in rfc7636
     *
     * @type {string}
     */
    codeVerifierChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

    /**
     * length of code verifier
     * rfc7636 defines min 43, max 128
     *
     * @type {number}
     */
    codeVerifierLength = 128;

    /**
     * list of api paths
     *
     * @type {Object}
     */
    paths = {
        authorize: '/authorize',
        token: '/token',
        logout: '/logout',
    };

    /**
     * list of token uses
     *
     * @type {Object}
     */
    tokenUses = {
        id: 'id',
        access: 'access',
    };

    /**
     * list of storage keys
     *
     * @type {Object}
     */
    storageKeys = {
        accessToken: 'accessToken',
        refreshToken: 'refreshToken',
        idToken: 'idToken',
        codeVerifier: 'codeVerifier',
        authorizeState: 'authorizeState',
        redirectPath: 'redirectPath',
    };

    /**
     * constructor
     *
     * @param {string} baseUrl
     * @param {string} clientId
     * @param {string} clientSecret
     * @param {Vuex.Store} store
     * @param {Log} log
     */
    constructor(baseUrl, clientId, clientSecret, store, log) {
        this.baseUrl = baseUrl;
        this.clientId = clientId;
        this.clientSecret = clientSecret;
        this.store = store;
        this.log = log;
    }

    /**
     * api setter
     *
     * @param {Api} api
     */
    setApi(api) {
        this.api = api;
    }

    /**
     * run authorization process
     *
     * @returns {Promise}
     */
    authorize() {
        //if auth code and state parameters are given after coming back from the login page, request idToken
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('code') && urlParams.get('state') && urlParams.get('state') === this.getAuthorizeState()) {
            return this.requestTokens(urlParams.get('code')).then(() => {
                //delete authorizeState after successful token request
                this.deleteStorage(this.storageKeys.authorizeState);
            }, err => {
                if (this.log) {
                    this.log.error(err);
                }
            }).then(() => {
                //get redirectPath from storage and delete it afterwards
                let redirectPath = this.readStorage(this.storageKeys.redirectPath) || '';
                this.deleteStorage(this.storageKeys.redirectPath);

                //if redirect is logout page, go to home page instead
                if(redirectPath === '#/logout'){
                    redirectPath = '#/home';
                }

                //redirect to a clear version of the url plus the redirectPath but without the login parameters
                this.redirect(location.pathname + redirectPath);
                return Promise.resolve();
            });
        }

        //check for valid idToken
        const idToken = this.getIdToken();
        if (idToken) {
            //request user data
            return this.requestUser().catch(err => {
                if (this.log) {
                    this.log.error(err);
                }

                //if user request failed, delete accessToken and idTokens
                this.deleteTokens();

                //try authorize process again
                return this.authorize();
            });
        }

        //request token refresh if possible
        return this.requestTokenRefresh().then(() => {
            //check for new valid idToken
            const idToken = this.getIdToken();
            if (idToken) {
                //request user data
                return this.requestUser().catch(err => {
                    if (this.log) {
                        this.log.error(err);
                    }

                    //if user request failed after token refresh, we have a critical error
                    throw new CriticalError(err.message);
                });
            }

            //if no valid idToken, throw error
            throw Error('auth: missing idToken after refresh');
        }).catch(err => {
            if (this.log) {
                this.log.error(err);
            }

            //if refresh failed, open login (unless critical error)
            if (!(err instanceof CriticalError)) {
                this.openLogin();
            }

            throw err;
        });
    }

    /**
     * logout
     *
     * @returns {Promise}
     */
    logout() {
        this.deleteTokens(true);
        this.openLogout();

        return new Promise(() => {});
    }

    /**
     * check if authorized
     *
     * @returns {Boolean}
     */
    isAuthorized() {
        return Boolean(this.getIdToken() || this.getRefreshToken());
    }

    /**
     * request user data
     *
     * @returns {Promise}
     */
    requestUser() {
        if (!this.api) {
            return Promise.reject(ReferenceError('auth: missing api handler'));
        }

        const idToken = this.getIdToken();
        if (!idToken) {
            return Promise.reject(Error('auth: missing idToken'));
        }

        //if already requested, use it
        if (this.userRequest) {
            return this.userRequest;
        }

        let user;

        //request user data
        this.userRequest = this.api.call.admin.userCurrent().then(userData => {
            user = UserModel.from(userData);

            //make sure user has been created properly
            if (!user) {
                throw new CriticalError('auth: unable to create user');
            }

            //get unique role ids from user accesses
            const roleIds = user.accesses.map(access => access.role ? access.role.id : null).filter((value, index, self) => {
                return (value && self.indexOf(value) === index);
            });

            //request full user role data
            return Promise.all(roleIds.map(roleId => this.api.call.admin.userRoleById(roleId)));
        }).then(userRoles => {
            //properly update user access roles
            user.updateAccessRoles(userRoles);

            //store user
            this.user = user;
            if (this.store) {
                this.store.dispatch('setUser', this.user);
            }

            //log on info level
            if (this.log) {
                this.log.info(
                    'auth: retrieved user',
                    user,
                    'with permissions',
                    JSON.parse(JSON.stringify(user.accesses.map(access => access.role ? access.role.permissions : null)))
                );
            }

            //init tracking
            try {
                initTracking(user);
            } catch (err) {
                if (this.log) {
                    this.log.error('auth: tracking init error', err);
                }
            }

            //return user
            return this.user;
        });

        return this.userRequest;
    }

    /**
     * get user
     *
     * @returns {UserModel}
     */
    getUser() {
        if (this.store) {
            return this.store.state.user;
        }

        return this.user;
    }

    /**
     * get valid access token from storage
     *
     * @returns {string|null}
     */
    getAccessToken() {
        const accessToken = this.readStorage(this.storageKeys.accessToken);
        if (!accessToken) {
            return null;
        }

        //if token invalid, delete it from storage
        if (!this.isTokenValid(accessToken, this.tokenUses.access)) {
            this.deleteStorage(this.storageKeys.accessToken);
            return null;
        }

        return accessToken;
    }

    /**
     * get refresh token from storage
     *
     * @returns {string|null}
     */
    getRefreshToken() {
        return this.readStorage(this.storageKeys.refreshToken) || null;
    }

    /**
     * get id token from storage
     *
     * @returns {string|null}
     */
    getIdToken() {
        const idToken = this.readStorage(this.storageKeys.idToken) || null;
        if (!idToken) {
            return null;
        }

        //if token invalid, delete it from storage
        if (!this.isTokenValid(idToken, this.tokenUses.id)) {
            this.deleteStorage(this.storageKeys.idToken);
            return null;
        }

        return idToken;
    }

    /**
     * delete tokens
     *
     * @param {Boolean} [deleteRefresh]
     */
    deleteTokens(deleteRefresh = false) {
        this.deleteStorage(this.storageKeys.accessToken);
        this.deleteStorage(this.storageKeys.idToken);

        if (deleteRefresh) {
            this.deleteStorage(this.storageKeys.refreshToken);
        }
    }

    /**
     * get code verifier from storage
     *
     * @returns {string|null}
     */
    getCodeVerifier() {
        return this.readStorage(this.storageKeys.codeVerifier) || null;
    }

    /**
     * get autorize state from storage
     *
     * @returns {string|null}
     */
    getAuthorizeState() {
        return this.readStorage(this.storageKeys.authorizeState) || null;
    }

    /**
     * get code challenge based on current code verifier
     *
     * @returns {string|null}
     */
    getCodeChallenge() {
        //get code verifier
        const codeVerifier = this.getCodeVerifier();
        if (!codeVerifier) {
            return null;
        }

        //generate challenge from code verifier
        return this.generateChallenge(codeVerifier);
    }

    /**
     * request tokens from api
     *
     * @param {string} code
     *
     * @returns {Promise}
     */
    requestTokens(code) {
        //get challenge
        const codeVerifier = this.getCodeVerifier();
        if (!codeVerifier) {
            return Promise.reject(Error('auth: unable to get codeVerifier'));
        }

        //log on debug level
        if (this.log) {
            this.log.debug('auth: requesting SSO tokens', code);
        }

        //execute request
        return this.executeRequest(this.paths.token, {
            grant_type: 'authorization_code',
            client_id: this.clientId,
            client_secret: this.clientSecret,
            code: code,
            code_verifier: codeVerifier,
            redirect_uri: window.location.origin + window.location.pathname,
            scope: this.scopes.join(' ') + ' ' + this.clientId
        }).then(result => {
            //check if returned idToken is valid
            if (!this.isTokenValid(result.id_token, this.tokenUses.id)) {
                throw Error('invalid idToken from api');
            }

            //store result tokens
            this.writeStorage(this.storageKeys.accessToken, result.access_token);
            this.writeStorage(this.storageKeys.refreshToken, result.refresh_token);
            this.writeStorage(this.storageKeys.idToken, result.id_token);

            //log on debug level
            if (this.log) {
                this.log.debug('auth: received SSO tokens', result);
            }
        });
    }

    /**
     * request token refresh from api
     *
     * @returns {Promise}
     */
    requestTokenRefresh() {
        //get refreshToken
        const refreshToken = this.getRefreshToken();
        if (!refreshToken) {
            return Promise.reject(Error('auth: missing refreshToken'));
        }

        return this.executeRequest(this.paths.token, {
            grant_type: 'refresh_token',
            client_id: this.clientId,
            client_secret: this.clientSecret,
            refresh_token: refreshToken
        }).then(result => {
            //check if returned idToken is valid
            if (!this.isTokenValid(result.id_token, this.tokenUses.id)) {
                throw Error('invalid idToken from api');
            }

            //store result tokens
            this.writeStorage(this.storageKeys.accessToken, result.access_token);
            this.writeStorage(this.storageKeys.idToken, result.id_token);
        });
    }

    /**
     * open login page
     * this will redirect the browser to another page
     */
    openLogin() {
        //generate fresh code verifier
        this.generateCodeVerifier();

        //get challenge
        const challenge = this.getCodeChallenge();

        //generate authorization state which will be returned after the redirect so we can compare it
        const authorizeState = this.generateAuthorizeState();

        //store current path for later redirect
        this.writeStorage(this.storageKeys.redirectPath, window.location.hash);

        //redirect to auth page
        this.redirect(this.getUrl(this.paths.authorize, {
            response_type: 'code id_token',
            response_mode: 'query',
            client_id: this.clientId,
            client_secret: this.clientSecret,
            redirect_uri: window.location.origin + window.location.pathname,
            state: authorizeState,
            scope: this.scopes.join(' ') + ' ' + this.clientId,
            code_challenge_method: 'S256',
            code_challenge: challenge,
        }));
    }

    /**
     * open logout page
     */
    openLogout() {
        this.redirect(this.getUrl(this.paths.logout, {
            response_type: 'code id_token',
            client_id: this.clientId,
            post_logout_redirect_uri: window.location.origin + window.location.pathname + '#/logout',
        }));
    }

    /**
     * get api url
     *
     * @param {string} path
     * @param {Object} [getParams]
     *
     * @returns {string|null}
     */
    getUrl(path, getParams = {}) {
        //only allow defined paths
        if (!Object.values(this.paths).includes(path)) {
            return null;
        }

        //properly encode get params
        getParams = getParams || {};
        getParams = Object.keys(getParams).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(getParams[key])).join('&');

        //stitch url together
        return this.baseUrl + path + (getParams ? '?' + getParams : '');
    }

    /**
     * generate random string
     *
     * @param {number} length
     * @param {string} [chars]
     *
     * @returns {string}
     */
    generateRandomString(length, chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') {
        return new Array(length)
            .fill('')
            .map(() => chars[Math.floor(Math.random() * chars.length)])
            .join('');
    }

    /**
     * generate random codeVerifier and store it, overwriting any old one
     *
     * @returns {string}
     */
    generateCodeVerifier() {
        const codeVerifier = this.generateRandomString(this.codeVerifierLength, this.codeVerifierChars);
        this.writeStorage('codeVerifier', codeVerifier);
        return codeVerifier;
    }

    /**
     * generate challenge based on codeVerifier using base64 encoding without padding as stated in rfc7636
     *
     * @param {string} codeVerifier
     *
     * @returns {string}
     */
    generateChallenge(codeVerifier) {
        return Base64.stringify(sha256(codeVerifier)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }

    /**
     * generate a random authorizeState and store it
     *
     * @returns {string}
     */
    generateAuthorizeState() {
        const authorizeState = this.generateRandomString(32);
        this.writeStorage('authorizeState', authorizeState);
        return authorizeState;
    }

    /**
     * get current timestamp
     *
     * @returns {number}
     */
    now() {
        return Math.floor((new Date()).getTime() / 1000);
    }

    /**
     * decode token
     *
     * @param {string} token
     *
     * @returns {Object|null}
     */
    decodeToken(token) {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        }
        catch (err) {
            if (this.log) {
                this.log.error(err);
            }
            return null;
        }
    }

    /**
     * check if token is valid by decoding it and checking its parameters
     *
     * @param {string} token
     * @param {string} use
     *
     * @returns {boolean}
     */
    isTokenValid(token, use) {
        const tokenData = this.decodeToken(token);

        return (
            tokenData &&
            //tokenData.token_use === use &&
            (tokenData.client_id || tokenData.aud) === this.clientId &&
            tokenData.exp > this.now()
        );
    }

    /**
     * read data from storage
     *
     * @param {string} key
     *
     * @returns {string|null}
     */
    readStorage(key) {
        //only allow defined storage keys
        if (!Object.values(this.storageKeys).includes(key)) {
            return null;
        }

        //read data from localStorage
        try {
            return window.localStorage.getItem(key);
        }
        catch (err) {
            return null;
        }
    }

    /**
     * write data to storage
     *
     * @param {string} key
     * @param {string} data
     *
     * @returns {boolean|null}
     */
    writeStorage(key, data) {
        //only allow defined storage keys
        if (!Object.values(this.storageKeys).includes(key)) {
            return null;
        }

        //write data to localStorage
        try {
            window.localStorage.setItem(key, data || null);
            return true;
        }
        catch (err) {
            return false;
        }
    }

    /**
     * delete data from storage
     *
     * @param {string} key
     *
     * @returns {boolean|null}
     */
    deleteStorage(key) {
        //only allow defined storage keys
        if (!Object.values(this.storageKeys).includes(key)) {
            return null;
        }

        //delete data from localStorage
        try {
            window.localStorage.removeItem(key);
            return true;
        }
        catch (err) {
            return false;
        }
    }

    /**
     * execute api request
     *
     * @param {string} urlPath
     * @param {Object} [data]
     *
     * @returns {Promise}
     */
    executeRequest(urlPath, data = {}) {
        const url = this.getUrl(urlPath);
        if (!url) {
            throw Error('auth: unknown request url type');
        }

        return fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&'),
        }).then(this.handleResponse);
    }

    /**
     * handle request result
     *
     * @param {Response} response
     *
     * @returns {*}
     */
    handleResponse(response) {
        //read result
        if (response.status === 204) {
            return null;
        }
        else if (response.status >= 200 && response.status < 300) {
            return response.json();
        }

        //read error
        return response.json().then(data => {
            throw RequestError(data.error, data.status || response.status);
        }, err => {
            throw RequestError(err, response.status);
        });
    }

    /**
     * redirect to another url
     *
     * @param {string} url
     */
    redirect(url) {
        window.location.replace(url);
    }
};
