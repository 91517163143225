<template>
    <div>
        <label class="d-block h2 text-center" v-if="title">{{ title }}</label>
        <ul class="d-flex mb-3 mb-lg-0 mx-n1 mx-lg-n2 list-unstyled text-center text-uppercase font-heavy" v-bind:class="wrapperClasses" v-bind:data-zero-number="wrapperZeroNumber" v-bind:data-zero-label="wrapperZeroLabel">
            <li
                v-for="(segment, i) in segments"
                v-bind:key="i"
                v-bind:class="getClassesOuter(segment.variant)"
                v-bind:style="{ width: segment.percentage + '%' }"
            >
                <div v-bind:class="getClassesInner(segment.id, barClasses, segment.variant)"></div>
                <label v-bind:class="getClassesInner(segment.id, numberClasses)">
                    <span v-bind:class="textClasses">
                        <slot name="label" v-bind:value="segment.label">{{ segment.label || emptyLabel }}</slot>
                    </span>
                </label>
                <p v-bind:class="getClassesInner(segment.id, labelClasses)">
                    <span v-bind:class="textClasses">
                        <slot name="number" v-bind:value="segment.number">{{ segment.number }}</slot>
                    </span>
                </p>
            </li>
            <li
                v-bind:style="{ width: '100%' }"
                v-bind:class="getClassesOuter(variantEmpty)"
                v-if="!segments.length && !pinOuter"
            >
                <div v-bind:class="getClassesInner(0, barClasses, variantEmpty)"></div>
                <label v-bind:class="getClassesInner(0, numberClasses)">
                    <span v-bind:class="textClasses">
                        <slot name="label" v-bind:value="null">{{ emptyLabel }}</slot>
                    </span>
                </label>
                <p v-bind:class="getClassesInner(0, labelClasses)">
                    <span v-bind:class="textClasses">
                        <slot name="number" v-bind:value="0">{{ zeroNumber }}</slot>
                    </span>
                </p>
            </li>
            <li
                v-bind:style="{ width: '50%' }"
                v-bind:class="getClassesOuter(variant1)"
                v-if="!segments.length && pinOuter"
            >
                <div v-bind:class="getClassesInner(1, barClasses, variant1)"></div>
                <label v-bind:class="getClassesInner(1, numberClasses)">
                    <span v-bind:class="textClasses">
                        <slot name="label" v-bind:value="label1">{{ label1 || emptyLabel }}</slot>
                    </span>
                </label>
                <p v-bind:class="getClassesInner(1, labelClasses)">
                    <span v-bind:class="textClasses">
                        <slot name="number" v-bind:value="0">{{ zeroNumber }}</slot>
                    </span>
                </p>
            </li>
            <li
                v-bind:style="{ width: '50%' }"
                v-bind:class="getClassesOuter(variant3)"
                v-if="!segments.length && pinOuter"
            >
                <div v-bind:class="getClassesInner(3, barClasses, variant3)"></div>
                <label v-bind:class="getClassesInner(3, numberClasses)">
                    <span v-bind:class="textClasses">
                        <slot name="label" v-bind:value="label3">{{ label3 || emptyLabel }}</slot>
                    </span>
                </label>
                <p v-bind:class="getClassesInner(3, labelClasses)">
                    <span v-bind:class="textClasses">
                        <slot name="number" v-bind:value="0">{{ zeroNumber }}</slot>
                    </span>
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
/**
 * bar component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'bar',
    props: {
        /**
         * bar title
         */
        title: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * first bar segment number
         */
        number1: {
            type: Number,
            required: false,
            default: null,
        },
        /**
         * second bar segment number
         */
        number2: {
            type: Number,
            required: false,
            default: null,
        },
        /**
         * third bar segment number
         */
        number3: {
            type: Number,
            required: false,
            default: null,
        },
        /**
         * first bar segment label
         */
        label1: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * second bar segment label
         */
        label2: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * third bar segment label
         */
        label3: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * first bar segment color variant
         */
        variant1: {
            type: String,
            required: false,
            default: 'success',
        },
        /**
         * second bar segment color variant
         */
        variant2: {
            type: String,
            required: false,
            default: 'light',
        },
        /**
         * third bar segment color variant
         */
        variant3: {
            type: String,
            required: false,
            default: 'secondary',
        },
        /**
         * empty bar (for when all numbers are 0) color variant
         */
        variantEmpty: {
            type: String,
            required: false,
            default: 'light',
        },
        /**
         * pin bar 1 and 3 text to the left and right
         */
        pinOuter: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * string used for displaying 0 values
         */
        zeroNumber: {
            type: [Number, String],
            required: false,
            default: 0,
        },
    },
    data(){
        return {
            barClasses: {
                'pt-2': true,
                'pb-2': true,
                'pb-sm-1': true,
                'pb-lg-2': true,
            },
            textClasses: {
                'text-nowrap': true,
                'test-ltr': true,
            },
            numberClasses: {
                'mt-2': true,
            },
            labelClasses: {
                h2: true,
            },
            barLeftZeroClass: 'bar-left-0',
            barRightZeroClass: 'bar-right-0',
        };
    },
    computed: {
        numberSum(){
            return ((this.number1 || 0) + (this.number2 || 0) + (this.number3 || 0));
        },
        segments(){
            return [
                {
                    id: 1,
                    label: this.label1,
                    number: (this.number1 || 0),
                    percentage: this.calculatePercentage(this.number1),
                    variant: this.variant1,
                },
                {
                    id: 2,
                    label: this.label2,
                    number: (this.number2 || 0),
                    percentage: this.calculatePercentage(this.number2),
                    variant: this.variant2,
                },
                {
                    id: 3,
                    label: this.label3,
                    number: (this.number3 || 0),
                    percentage: this.calculatePercentage(this.number3),
                    variant: this.variant3,
                },
            ].filter(item => item.number > 0);
        },
        wrapperClasses(){
            const classes = {};

            if(!this.pinOuter){
                return classes;
            }

            const hasSegment1 = this.segments.filter(segment => segment.id === 1).length;
            const hasSegment3 = this.segments.filter(segment => segment.id === 3).length;

            if(!hasSegment1 && hasSegment3){
                classes[this.barLeftZeroClass] = true;
                classes['text-' + (this.segments.length ? this.variant1 : this.variantEmpty)] = true;
            }
            if(!hasSegment3 && hasSegment1){
                classes[this.barRightZeroClass] = true;
                classes['text-' + (this.segments.length ? this.variant3 : this.variantEmpty)] = true;
            }

            return classes;
        },
        wrapperZeroNumber(){
            if(!this.pinOuter){
                return null;
            }

            return this.zeroNumber;
        },
        wrapperZeroLabel(){
            if(!this.pinOuter){
                return null;
            }

            if(this.wrapperClasses[this.barLeftZeroClass]){
                return this.label1 || null;
            }
            if(this.wrapperClasses[this.barRightZeroClass]){
                return this.label3 || null;
            }

            return null;
        },
        emptyLabel(){
            return ((this.label1 || this.label2 || this.label3) ? '\xa0' : '');
        },
    },
    methods: {
        getClassesOuter(textVariant){
            const classes = {
                'px-1': true,
                'px-lg-2': true,
            };

            if(textVariant){
                classes['text-' + textVariant] = true;
            }

            return classes;
        },
        getClassesInner(id = null, baseClasses = null, bgVariant = null){
            const classes = Object.assign({}, baseClasses, {
                'd-block': true,
                'm-0': true,
            });

            if(bgVariant){
                classes['bg-' + bgVariant] = true;
            }

            if(id === 3){
                classes['text-rtl'] = true;
            }

            if(this.pinOuter && id){
                if(id === 1){
                    classes['text-left'] = true;
                }
                if(id === 3){
                    classes['text-right'] = true;
                }
            }

            return classes;
        },
        calculatePercentage(number){
            return (number || 0) * 100 / this.numberSum;
        },
    },
};
</script>
