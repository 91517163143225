<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('name', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{ getHeadline('name', $t('name')) }}</label>
            <span class="text-uppercase" v-else>{{ permissionGroup.name }}</span>
        </b-card-title>

        <b-card-title v-bind="getColumnProps('publishingAccessLevel', 12, 6, 3)" v-bind:title-tag="titleTag" v-if="showPublishingAccessLevel">
            <label
                v-bind:class="headlineClasses"
                v-on:click="handleHeadlineSort('publishingAccessLevel')"
                v-if="isHeadline"
            >
                    {{ getHeadline('publishingAccessLevel', $t('publishingAccessLevel')) }}
            </label>
            <span class="text-uppercase" v-else>{{ permissionGroup.publishingAccessLevel }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-if="showRemove"
                v-b-modal="modalName('confirmDelete', permissionGroup.id)"
                v-on:click="emitEvent('remove', $event)"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', permissionGroup.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: permissionGroup.name })"
            v-bind:data="permissionGroup"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />
    </b-card>
</template>

<script>
import base from '../base.vue';

import PermissionGroupModel from '@/models/permissiongroup.js';

/**
 * union block component
 */
export default {
    name: 'permissionGroupRow',
    extends: base,
    props: {
        permissionGroup: {
            type: PermissionGroupModel,
            default(){
                return new PermissionGroupModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },

        showPublishingAccessLevel: {
            type: Boolean,
            default: false,
        },

        showActionsDelete: {
            type: Boolean,
            default: true,
        },

        showRemove: {
            type: Boolean,
            default: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 100,
        },
        imgHeight: {
            type: Number,
            default: 100,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'administrationPermissionGroupDetail',
                params: Object.assign({
                    dataPermissionGroupId: this.permissionGroup.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                permissionGroup: this.permissionGroup || null,
            };
        },
        hasVisibleCircles(){
            return (this.showRemove || this.showDetail);
        },
    },
};
</script>
