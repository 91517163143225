<template>
    <div>
        <list
            v-bind:items="comments"
            v-bind:title="$t('comments')"
            v-bind:doubleButtons="doubleButtons"
            v-bind:noShadow="noShadow"
            v-bind:noLine="noLine"
            v-bind:mobileBorder="mobileBorder"
            v-bind:noHorizontalPadding="noHorizontalPadding"
            v-bind:noVerticalPadding="noVerticalPadding"
            v-bind:noPadding="noPadding"
            v-bind:isForm="isForm"
            v-bind:waitFor="apiData.load"
            v-bind:collapseEnabled="collapseEnabled"
            v-bind:collapseHidden="collapseHidden"
            v-bind:itemsTotal="comments.length"
            class="listWrapperLoadingSmall"
            noMargin
        >
            <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
                <!-- @slot All slots are passed through to list component, except for: header, default, empty, footer -->
                <slot v-bind:name="name" v-bind="data"/>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot="{ item: comment }">
                <div class="mt-4 mb-5">
                    <div class="mb-1">
                        <strong class="text-secondary">{{ comment.userName || $t('unknown') }}</strong>
                        <span class="ml-2">{{ $fd(comment.date, 'dateTime') }}</span>
                        <span v-if="comment.isInternal" class="text-uppercase font-weight-bolder text-danger small">{{ ' - ' + $t('internalComment') }}</span>
                    </div>
                    <p class="text-pre m-0">{{ comment.text }}</p>
                </div>
            </template>
            <template v-slot:empty>
                <span/>
            </template>
            <template v-slot:footer>
                <form class="position-relative mt-3" v-on:submit="submitComment" v-if="!readonly">
                    <b-form-textarea
                        v-model="comment"
                        rows="3"
                        ref="textarea"
                    />
                    <div class="d-flex align-items-start mt-2" v-if="commentEnabled">
                        <span class="text-secondary" v-if="commentInternal">
                            {{ $t('internalComment') }}
                        </span>
                        <span class="mr-auto"/>
                        <actionButton
                            v-on:click="disableComment"
                        >
                            {{ $t('cancel') }}
                        </actionButton>
                        <actionButton
                            type="submit"
                            variant="success"
                            class="ml-2"
                            v-bind:loadingWhile="apiData.save"
                            v-bind:disabled="!comment.trim()"
                            loadingThrobber
                        >
                            {{ $t('sendComment') }}
                        </actionButton>
                    </div>

                    <div class="position-absolute position-cover bg-light o-50" v-if="!commentEnabled"/>
                    <div class="position-absolute position-cover d-flex align-items-center justify-content-center" v-if="!commentEnabled">
                        <actionButton
                            v-on:click="enableComment(false)"
                        >
                            {{ $t('comment') }}
                        </actionButton>
                        <actionButton
                            variant="outline-secondary"
                            class="ml-2"
                            v-on:click="enableComment(true)"
                            v-if="allowInternalComments"
                        >
                            {{ $t('internalComment') }}
                        </actionButton>
                    </div>
                </form>
            </template>
        </list>
    </div>
</template>

<script>
import list from '@/components/list.vue';
import validations from '@/components/validations.vue';
import actionButton from '@/components/actionButton.vue';

import DeclarationModel from '@/models/declaration.js';
import PersonModel from '@/models/person.js';
import DeclarationCommentModel from '@/models/declaration/comment.js';

import { RequestError } from '@/errors.js';

const types = {
    participationAgreement: 'participationAgreement',
    teamLogo: 'teamLogo',
    nationalAnthem: 'nationalAnthem',
    medicalInsuranceCover: 'medicalInsuranceCover',
    teamDisciplinaryCertificate: 'teamDisciplinaryCertificate',
    teamMemberAcceptanceForm: 'teamMemberAcceptanceForm',
    teamPhoneticGuide: 'teamPhoneticGuide',
    kit: 'kit',
    squad: 'squad',
    person: 'person',
    personHeadshot: 'personHeadshot',
    personBio: 'personBio',
    personPassport: 'personPassport',
    personMedicalCertificate: 'personMedicalCertificate',
};

export default {
    name: 'declarationComments',
    components: {
        list,
        validations,
        actionButton,
    },
    props: {
        type: {
            type: String,
            required: true,
            validator(value){
                return Object.values(types).includes(value);
            },
        },
        declaration: {
            type: DeclarationModel,
            required: true,
            default(){
                return new DeclarationModel();
            },
        },
        person: {
            type: PersonModel,
            required: false,
            default(){
                return new PersonModel();
            },
        },
        allowInternalComments: {
            type: Boolean,
            default: false,
        },
        isTeamTwo: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        /**
         * buttons slot content shows both in header and below the wrapper
         */
        doubleButtons: {
            type: Boolean,
            default: false,
        },
        /**
         * disable box shadow on desktop
         */
        noShadow: {
            type: Boolean,
            default: false,
        },
        /**
         * disable top line on desktop
         */
        noLine: {
            type: Boolean,
            default: false,
        },
        /**
         * show with border on mobile
         */
        mobileBorder: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce horizontal padding to minimum
         */
        noHorizontalPadding: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce vertical padding to minimum
         */
        noVerticalPadding: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce padding to minimum
         */
        noPadding: {
            type: Boolean,
            default: false,
        },
        /**
         * use form tag for wrapper element
         */
        isForm: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * enable the option to collapse everything below the headline
         */
        collapseEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * change default collapse state to hidden
         */
        collapseHidden: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data(){
        return {
            apiData: {
                load: null,
                save: null,
            },
            comment: '',
            commentEnabled: false,
            commentInternal: false,
            status: null,
            comments: [],
            error: null,
        };
    },
    computed: {
        teamDeclaration(){
            return this.isTeamTwo ? this.declaration.getTeamDeclarationTwo() : this.declaration.getTeamDeclaration();
        },
        teamDeclarationId(){
            return this.teamDeclaration?.id;
        },
        teamDeclarationModuleId(){
            let target;

            switch(this.type){
                case types.participationAgreement:
                    target = this.declaration.configTargets.participationAgreement;
                    break;

                case types.teamLogo:
                    target = this.declaration.configTargets.teamLogo;
                    break;

                case types.nationalAnthem:
                    target = this.declaration.configTargets.nationalAnthem;
                    break;

                case types.medicalInsuranceCover:
                    target = this.declaration.configTargets.medicalInsuranceCover;
                    break;

                case types.teamDisciplinaryCertificate:
                    target = this.declaration.configTargets.teamDisciplinaryCertificate;
                    break;

                case types.teamMemberAcceptanceForm:
                    target = this.declaration.configTargets.teamMemberAcceptanceForm;
                    break;

                case types.teamPhoneticGuide:
                    target = this.declaration.configTargets.teamPhoneticGuide;
                    break;

                case types.kit:
                    target = this.declaration.configTargets.kit;
                    break;

                case types.squad:
                case types.person:
                case types.personHeadshot:
                case types.personBio:
                case types.personPassport:
                case types.personMedicalCertificate:
                    target = this.declaration.configTargets.squad;
                    break;

                default:
                    return null;
            }
            return this.teamDeclaration.findInConfiguration(this.declaration.getStepTarget(target) + this.declaration.configProperties.id);
        },
        teamDeclarationPersonId(){
            return this.person.id;
        },
    },
    methods: {
        enableComment(isInternal = false){
            this.commentEnabled = true;
            this.commentInternal = Boolean(isInternal);

            this.$refs.textarea.focus();
        },
        disableComment(){
            this.comment = '';
            this.commentEnabled = false;
            this.commentInternal = false;
            this.error = null;
        },
        loadComments(){
            this.apiData.load = this.callLoad().catch(error => {
                if(error instanceof RequestError && error.status === 404){
                    //ignore 404 error;
                    return {};
                }
                throw error;
            }).then(data => {
                this.status = data.status || null;
                this.comments = (data.comments || []).map(data => DeclarationCommentModel.from(data)).filter(comment => comment.text);
            });
        },
        submitComment(e){
            e.preventDefault();

            if(!this.comment.trim()){
                return;
            }

            const data = {
                status: this.status || null,
                comment: this.comment || null,
                isInternal: this.commentInternal,
            };

            this.apiData.save = this.callSave(data).then(data => {
                this.status = data.status || null;
                this.comments = (data.comments || []).map(data => DeclarationCommentModel.from(data)).filter(comment => comment.text);

                this.disableComment();
            }).catch(error => {
                this.error = error;
            });
        },
        callLoad(){
            switch(this.type){
                case types.participationAgreement:
                case types.teamLogo:
                case types.nationalAnthem:
                case types.medicalInsuranceCover:
                case types.teamDisciplinaryCertificate:
                case types.teamMemberAcceptanceForm:
                case types.teamPhoneticGuide:
                case types.kit:
                case types.squad:
                    return this.$api.call.termsOfParticipation.teamDeclarationModuleStatus(this.teamDeclarationId, this.teamDeclarationModuleId);

                case types.person:
                    return this.$api.call.termsOfParticipation.teamDeclarationModulePersonStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId);

                case types.personHeadshot:
                    return this.$api.call.termsOfParticipation.teamDeclarationModulePersonHeadshotStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId);

                case types.personBio:
                    return this.$api.call.termsOfParticipation.teamDeclarationModulePersonBioStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId);

                case types.personPassport:
                    return this.$api.call.termsOfParticipation.teamDeclarationModulePersonPassportStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId);

                case types.personMedicalCertificate:
                    return this.$api.call.termsOfParticipation.teamDeclarationModulePersonMedicalCertificateStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId);

                default:
                    return Promise.reject(Error(this.$t('unexpectedError')));
            }
        },
        callSave(data){
            switch(this.type){
                case types.participationAgreement:
                case types.teamLogo:
                case types.nationalAnthem:
                case types.medicalInsuranceCover:
                case types.teamDisciplinaryCertificate:
                case types.teamMemberAcceptanceForm:
                case types.teamPhoneticGuide:
                case types.kit:
                case types.squad:
                    return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdateStatus(this.teamDeclarationId, this.teamDeclarationModuleId, data);

                case types.person:
                    return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId, data);

                case types.personHeadshot:
                    return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonHeadshotStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId, data);

                case types.personBio:
                    return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonBioStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId, data);

                case types.personPassport:
                    return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonPassportStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId, data);

                case types.personMedicalCertificate:
                    return this.$api.call.termsOfParticipation.teamDeclarationModuleUpdatePersonMedicalCertificateStatus(this.teamDeclarationId, this.teamDeclarationModuleId, this.teamDeclarationPersonId, data);

                default:
                    return Promise.reject(Error(this.$t('unexpectedError')));
            }
        },
    },
    created(){
        this.disableComment();
        this.loadComments();
    },
};
</script>
