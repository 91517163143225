<template>
    <div v-bind:class="blockClasses"></div>
</template>

<script>
/**
 * base block component
 * @abstract
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    computed: {
        blockClasses(){
            const classes = {
                block: true
            };

            if(this.$options.name){
                classes[this.$options.name] = true;
            }

            return classes;
        },
    },
};
</script>
