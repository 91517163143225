/**
 * constants list
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    options: {
        formValidationEnabled: false,
    },
    routeNames: {
        home: 'home',
        logout: 'logout',
        error: 'error',
    },
    routePaths: {
        index: '/',
        create: 'create/',
        edit: 'edit/',
        report: 'report/',
        stats: 'stats/',
        timeline: 'timeline/',
        teamsheetSummary: 'teamsheetSummary/',
        teamsheets: 'teamsheets/',
        highlights: 'highlights/',
    },
    routeProps: {
        apiConsumerId: 'dataApiConsumerId',
        calculationId: 'dataCalculationId',
        declarationStep: 'dataDeclarationStep',
        locationId: 'dataLocationId',
        matchId: 'dataMatchId',
        matchDeclarationId: 'dataMatchDeclarationId',
        penaltyId: 'dataPenaltyId',
        permissionGroupId: 'dataPermissionGroupId',
        personId: 'dataPersonId',
        personRoleId: 'dataPersonRoleId',
        rankingType: 'dataRankingType',
        rankingUnionId: 'dataRankingUnionId',
        ratingChangeId: 'dataRatingChangeId',
        seriesDeclarationId: 'dataSeriesDeclarationId',
        seriesId: 'dataSeriesId',
        seriesTeamId: 'dataTeamId',
        seriesTypeId: 'dataSeriesTypeId',
        teamDeclarationId: 'dataTeamDeclarationId',
        teamId: 'dataTeamId',
        tournamentDeclarationId: 'dataTournamentDeclarationId',
        tournamentId: 'dataTournamentId',
        tournamentStageId: 'dataTournamentStageId',
        tournamentStandingTypeId: 'dataTournamentStandingTypeId',
        tournamentTeamId: 'dataTournamentTeamId',
        tournamentTypeGroupId: 'dataTournamentTypeGroupId',
        tournamentTypeId: 'dataTournamentTypeId',
        unionId: 'dataUnionId',
        userId: 'dataUserId',
        venueId: 'dataVenueId',
    },
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
    gmtOffsets: {
        '-720': 'GMT−12:00',
        '-660': 'GMT−11:00',
        '-600': 'GMT−10:00',
        '-570': 'GMT−09:30',
        '-540': 'GMT−09:00',
        '-480': 'GMT−08:00',
        '-420': 'GMT−07:00',
        '-360': 'GMT−06:00',
        '-300': 'GMT−05:00',
        '-240': 'GMT−04:00',
        '-210': 'GMT−03:30',
        '-180': 'GMT−03:00',
        '-120': 'GMT−02:00',
        '-60': 'GMT−01:00',
        0: 'GMT±00:00',
        60: 'GMT+01:00',
        120: 'GMT+02:00',
        180: 'GMT+03:00',
        210: 'GMT+03:30',
        240: 'GMT+04:00',
        270: 'GMT+04:30',
        300: 'GMT+05:00',
        330: 'GMT+05:30',
        345: 'GMT+05:45',
        360: 'GMT+06:00',
        390: 'GMT+06:30',
        420: 'GMT+07:00',
        480: 'GMT+08:00',
        525: 'GMT+08:45',
        540: 'GMT+09:00',
        570: 'GMT+09:30',
        600: 'GMT+10:00',
        630: 'GMT+10:30',
        660: 'GMT+11:00',
        720: 'GMT+12:00',
        765: 'GMT+12:45',
        780: 'GMT+13:00',
        840: 'GMT+14:00',
    },
    userStatuses: {
        accessRequested: 'AccessRequested',
        active: 'Active',
        deletionRequested: 'DeletionRequested',
        inactive: 'Inactive',
    },
    inputMainYear: {
        min: 1800,
        max: new Date().getFullYear() + 10,
        default: new Date().getFullYear(),
    },
    genders: {
        male: 'Male',
        female: 'Female',
    },
    colors: {
        0: 'black',
        1: 'dark red',
        2: 'dark green',
        3: 'dark yellow',
        4: 'dark blue',
        5: 'dark magenta',
        6: 'dark cyan',
        7: 'light grey',
        8: 'money green',
        9: 'sky blue',
        246: 'cream',
        247: 'medium grey',
        248: 'dark grey',
        249: 'red',
        250: 'green',
        251: 'yellow',
        252: 'blue',
        253: 'magenta',
        254: 'cyan',
        255: 'white',
    },
    dominantSides: {
        left: 'Left',
        right: 'Right',
        both: 'Ambidextrous',
    },
    fileAcceptance: {
        documentAndAudio: 'audio/mpeg, audio/wav, application/pdf',
        images: 'image/jpeg, image/png',
        imagesAndPdf: 'image/jpeg, image/png, application/pdf',
        pdfAndTxt: 'application/pdf, text/plain',
        audio: 'audio/mpeg, audio/wav',
    },
    publishingAccessLevels: {
        private: 'Private',
        unpublished: 'Unpublished',
        public: 'Public',
    },
    actionTypeGroups: {
        1: 'Score',
        2: 'Card',
        3: 'Replacement',
        4: 'Clock',
        5: 'InPlay',
    },
    tournamentDeclarationTypes: {
        oneStep: 'oneStepDeclaration',
        twoStep: 'twoStepDeclaration',
    },
    rankingTypes: {
        mens: 'MRU',
        womens: 'WRU',
    },
    sevenSport: {
        mens: 'MRS',
        womens: 'WRS',
        mensJunior: 'MJS',
        womensJunior: 'WJS'
    },
    personRoleType: {
        player: 'Player',
        official: 'Official',
        management: 'Management',
    },
    group: {
        management: 'Management',
        medical: 'Medical',
        player: 'Player',
        replacementPlayer: 'ReplacementPlayer',
        nonPlayingReserve: 'NonPlayingReserve',
    },
    declarationStatus: {
        approved: 'Approved',
        inProgress: 'InProgress',
        open: 'Open',
        rejected: 'Rejected',
        submitted: 'Submitted',
    },
    declarationTypes: {
        match: 'Match',
        series: 'Series',
        tournament: 'Tournament',
    },
    mappingProviderNames: {
        aeLive: 'AELive',
        opta: 'Opta',
    },
    mappingEntityNames: {
        actionType: 'actionType',
        match: 'match',
        matchStatus: 'matchStatus',
        person: 'person',
        personRole: 'personRole',
        position: 'position',
        series: 'series',
        team: 'team',
        tournament: 'tournament',
        venue: 'venue',
    },
};
