import Model from '@/models/model.js';

/**
 * place model class
 */
export default class PlaceModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * countryName
     *
     * @type {string}
     */
     countryName = '';

     /**
     * countryIsoCode
     *
     * @type {string}
     */
     countryIsoCode = '';

     /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
     constructor(data = {}){
         super(data);

         if(data){
             this.countryIsoCode = data.countryIsoCode || data.isoCode || '';
             this.countryName = data.countryName || '';
             this.eqId = data.eqId || '';
             this.id = data.id || 0;
             this.name = data.name || data.locationName || '';
         }
     }

     /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
     label(vue = null){
         return this.name + (this.countryName ? ', ' + this.countryName : '');
     }

     toJSON() {
         return {
             isoCode: this.countryIsoCode,
             name: this.name,
         };
     }
}
