<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        size="xl"
    >
        <template v-slot="{ formId, submit }">
            <medicalCertificateForm
                v-bind:dataId="formId"
                v-bind:model="person"
                v-bind:errors="errorIds"
                v-bind:readonly="reviewMode"
                v-on:submit="submit"
            />
            <declarationComments
                type="personMedicalCertificate"
                v-bind:person="person"
                v-bind:declaration="declaration"
                v-bind:allowInternalComments="reviewMode"
            />
        </template>
    </modal>
</template>

<script>
import base from '@/components/modals/base.vue';
import medicalCertificateForm from '@/components/forms/medicalCertificate.vue';
import declarationComments from '@/components/declarationComments.vue';

import PersonModel from '@/models/person.js';
import DeclarationModel from '@/models/declaration.js';

/**
 * venue alias create modal component
 */
export default {
    name: 'personMedicalCertificateModal',
    extends: base,
    components: {
        medicalCertificateForm,
        declarationComments
    },
    props: {
        person: {
            type: PersonModel,
            default(){
                return new PersonModel();
            }
        },
        declaration: {
            type: DeclarationModel,
            default: null,
        },
    },
    computed: {
        payload(){
            return this.person;
        },
    },
};
</script>
