import Model from '@/models/model.js';

/**
 * player attributes model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class PlayerAttributesModel extends Model {
    /**
     * id
     *
     * @type {Number}
     */
    id = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.eqId = data.eqId || '';
            this.isCaptain = data.isCaptain || false;
            this.isFrontRowEligible = data.isFrontRowEligible || false;
            this.isHooker = data.isHooker || false;
            this.isLoosehead = data.isLoosehead || false;
            this.isPlayerOfTheMatch = data.isPlayerOfTheMatch || false;
            this.isPlayerOfTheMatchNominee = data.isPlayerOfTheMatchNominee || false;
            this.isTighthead = data.isTighthead || false;
            this.isUsedReplacement = data.isUsedReplacement || false;
            this.isReplaced = data.isReplaced || false;
            this.shirtNumber = data.shirtNumber || data.jerseyNumber || '';
            this.height = data.height || 0;
            this.weight = data.weight || 0;
            this.hasPlayerLeftSquad = data.hasPlayerLeftSquad || false;
            this.squadNumber = data.squadNumber || null;
            this.clubApproved = data.clubApproved || 0;
        }
    }
}
