import Model from '@/models/model.js';

/**
 * mapping provider model class
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default class MappingProviderModel extends Model {
    /**
     * description
     *
     * @type {string}
     */
    name = '';

    /**
     * description
     *
     * @type {string}
     */
    title = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.eqId = data.eqId || '';
            this.name = data.name || '';
            this.title = data.title || '';
        }
    }
}
