import Model from '@/models/model.js';

/**
 * action type set model class
 */
export default class ActionTypeSetModel extends Model {
    /**
     * id
     *
     * @type {int}
     */
    id = 0;

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * name sport
     *
     * @type {string}
     */
    sport = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.dateFrom = this.toDate(data.dateFrom) || null;
            this.dateUntil = this.toDate(data.dateUntil) || null;
            this.eqId = data.eqId || '';
            this.name = data.name || '';
            this.sport = data.sport || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
