import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationRankingIndex from '@/views/administration/rankings/index.vue';
import administrationRankingInactivity from '@/views/administration/rankings/inactivity.vue';
import administrationRankingPredictor from '@/views/administration/rankings/predictor.vue';

import administrationRankingCalculationRoutes from '@/router/administration/ranking/calculation.js';
import administrationRankingPenaltyRoutes from '@/router/administration/ranking/penalty.js';

export default {
    path: 'ranking/',
    component: pageFrame,
    children: [
        {
            path: routerTools.propRoute(constants.routeProps.rankingType),
            component: pageFrame,
            children: [
                {
                    name: 'administrationRankingIndex',
                    path: constants.routePaths.index,
                    component: administrationRankingIndex,
                    props: true,
                    meta: {
                        permission: permissions.rankings.list,
                    },
                },
                {
                    name: 'administrationRankingInactivity',
                    path: 'inactivity',
                    component: administrationRankingInactivity,
                    props: true,
                    meta: {
                        permission: permissions.rankings.inactivity,
                    },
                },
                {
                    name: 'administrationRankingPredictor',
                    path: 'predict',
                    component: administrationRankingPredictor,
                    props: true,
                    meta: {
                        permission: permissions.rankings.predict,
                    },
                },

                administrationRankingCalculationRoutes,
                administrationRankingPenaltyRoutes,
            ],
        },
    ],
};
