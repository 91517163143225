<script>
import itemList from '@/components/itemList.vue';
import tournamentStandingTypeRow from '@/components/rows/administration/tournamentStandingType.vue';
import TournamentStandingTypeModel from '@/models/tournamentStandingType.js';

/**
 * complex tournamentType list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'tournamentStandingTypeList',
    extends: itemList,
    components: {
        tournamentStandingTypeRow,
    },
    data(){
        return {
            //overwrites
            rowElement: tournamentStandingTypeRow,
            rowItemPropName: 'tournamentStandingType',
            selectItemProp: 'tournamentStandingType',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showSport: true,
                showDelete: true,
                showDetail: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName'),
                    label: false,
                },
                {
                    id: 'sport',
                    type: 'vselectSport',
                    placeholder: this.$t('filterBySport'),
                    disabled: this.readonly,
                    label: false,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            // load Tournament Standing Types
            return this.$api.call.tournament.tournamentStandingTypeSearch({
                name: filters.name ? '%' + filters.name + '%' : null,
                sport: filters.sport || undefined,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.standingTypes || result.items || []).map(data => new TournamentStandingTypeModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.tournament.tournamentStandingTypeDeleteById(item.id);
        },
    },
};
</script>
