import Model from '@/models/model.js';

/**
 * country model class
 */
export default class CountryModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * iso code
     *
     * @type {string}
     */
    isoCode = '';

    /**
     * nationality
     *
     * @type {string}
     */
    nationality = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.isoCode || data.countryId || 0;
            this.eqId = data.eqId || '';
            this.name = data.name || '';
            this.isoCode = data.isoCode || '';
            this.nationality = data.nationality || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
