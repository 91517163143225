<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createMatch')"
            v-bind:waitFor="apiData.dataInit"
            v-bind:waitForRetry="reloadDataInit"
            v-on:submit="createMatch"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toMatchList"
                    v-bind:loadingWhile="apiData.matchCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.matchCreation"
                >
                    {{ $t('createMatch') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || declarationWarning || mappingWarning">
                <validations
                    v-bind:warnings="((declarationWarning || mappingWarning) ? [...(declarationWarning ? [declarationWarning] : []), ...(mappingWarning ? [mappingWarning] : [])] : undefined)"
                    v-bind:errors="error ? error.getErrorMessages($t('requestError')) : undefined"
                    v-if="error || declarationWarning || mappingWarning"
                />
            </template>
            <template v-slot>
                <createEditMatchForm
                    v-bind:headline="$t('minimumMatchRequirements')"
                    v-bind:showTournamentTeamSelectors="showTournamentTeamSelectors"
                    v-bind:model="match"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
                <matchDeclarationForm
                    v-bind:headline="$t('matchDeclarationTopInformation')"
                    v-bind:model="declarationTOP"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:sport="match.sport"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingCreateMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import createEditMatchForm from '@/components/forms/createEditMatch.vue';
import matchDeclarationForm from '@/components/forms/matchDeclaration.vue';

import MatchModel from '@/models/match.js';
import MatchDeclarationModel from '@/models/declaration/match.js';
import WorkflowModel from '@/models/workflow.js';

export default {
    name: 'commonMatchCreate',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingCreateMixins,
    ],
    components: {
        validations,
        createEditMatchForm,
        matchDeclarationForm,
    },
    data(){
        const declaration = new MatchDeclarationModel();

        return {
            apiData: {
                dataInit: this.loadDataInit(),
                matchCreation: null,
            },
            match: new MatchModel({
                sport: 'MRU',
                matchStatusId: 4, // default matchNotStarted: id=4
            }),
            declaration: declaration,
            declarationTOP: declaration.getConfigurationFormModel(),
            declarationWarning: null,
            mappingWarning: null,
            error: null,
            showTournamentTeamSelectors: false,
        };
    },
    computed: {
        toMatchList(){
            return { name: 'commonMatchIndex' };
        },
    },
    methods: {
        loadDataInit(){
            //can be overwritten to do more async data init

            this.match = new MatchModel({
                sport: 'MRU',
                matchStatusId: 4, // default matchNotStarted: id=4
                actionTypeSetId: 27 // default actionTypeSetId: 27 (T5 C2 PT7 P3 DG3)
            });

            return this.loadEntityMappingProviders(this.match);
        },
        reloadDataInit(){
            this.apiData.dataInit = this.loadDataInit();
        },
        createMatch(){
            let queue = Promise.resolve();

            //generate declaration config if enabled
            if(this.declarationTOP.enabled){
                //make sure match is set to declaration
                this.declaration.setMatch(this.match);

                //template
                if(this.declarationTOP.workflowId){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.declarationWorkflowsById(this.declarationTOP.workflowId)).then(data => {
                        const workflow = new WorkflowModel(data);

                        return this.$api.call.termsOfParticipation.declarationTemplateById(workflow.templateId).then(template => {
                            this.declaration.template = template;
                        });
                    });
                }

                //generate config from form data
                queue = queue.then(() => this.declaration.generateConfiguration(this.declarationTOP));
            }

            //submit match, if not already
            if(!this.match.id){
                queue = queue.then(() => this.$api.call.match.matchCreate(this.match.toJSON()).then(data => {
                    this.match.id = data.id;
                    this.match.eqId = data.eqId;

                    //create mappings
                    return this.createMappings(this.match);
                }));
            }

            //map match to mapping providers
            if(this.match.eqId){
                queue = this.createMappingsUsingQueue(this.match, queue);
            }

            //submit declaration if enabled
            if(this.declarationTOP.enabled){
                queue = queue.then(() => this.$api.call.termsOfParticipation.matchDeclarationCreate(this.declaration.toJSON()).catch(error => {
                    this.declarationWarning = this.$t('matchDeclarationCreationWarning');
                    error.errorIdParser = this.declaration.errorIdParser.bind(this.declaration);

                    throw error;
                }));
            }

            //handle request outcome
            queue = queue.then(() => {
                this.error = null;
                this.goToMatchDetail(this.match.id);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('match creation failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.matchCreation = queue;
        },
        goToMatchDetail(id){
            this.$router.push({ name: 'commonMatchDetail', params: { dataMatchId: id } });
        },
    },
}
</script>
