<template>
    <wrapper
        v-bind:title="title"
        v-bind:itemsTotal="itemsTotal"
        v-bind:largeTitle="largeTitle"
        v-bind:mainTitle="mainTitle"
        v-bind:doubleButtons="doubleButtons"
        v-bind:noShadow="noShadow"
        v-bind:noLine="noLine"
        v-bind:mobileBorder="mobileBorder"
        v-bind:noHorizontalPadding="noHorizontalPadding"
        v-bind:noVerticalPadding="noVerticalPadding"
        v-bind:noPadding="noPadding"
        v-bind:noMargin="noMargin"
        v-bind:waitFor="waitFor"
        v-bind:waitForRetry="waitForRetry"
        v-bind:waitSilent="waitSilent"
        v-bind:waitSilentLoading="waitSilentLoading"
        v-bind:waitSilentError="waitSilentError"
        v-bind:isForm="isForm"
        v-bind:collapseEnabled="collapseEnabled"
        v-bind:collapseHidden="collapseHidden"
        v-bind:class="wrapperClass"
        v-on:submit="onSubmit"
        class="listWrapper"
        mainRole="list"
    >
        <template v-slot:title="{ title, itemsTotal }" v-if="$scopedSlots.title">
            <!--
            @slot Replace default module headline element
            @binding {string} title Title prop value
            -->
            <slot name="title" v-bind:title="title + itemsTotal"/>
        </template>
        <template v-slot:buttons v-if="$scopedSlots.buttons">
            <!--
            @slot Module buttons
            -->
            <slot name="buttons"/>
        </template>
        <template v-slot:header v-if="$scopedSlots.header">
            <!--
            @slot Header content
            -->
            <slot name="header"/>
        </template>
        <template v-slot:top v-if="$scopedSlots.top">
            <!--
            @slot Status content
            -->
            <slot name="top"/>
        </template>
        <template v-slot="{ collapsed }">
            <draggable v-if="draggable && itemList.length" v-model="itemList" v-bind:group="draggableGroup" v-bind:move="checkDragMove" v-on:end="onDragEnd" class="listWrapper">
                <!--
                @slot List item content
                @binding {Model} item Current list item
                @binding {number} index Current list item index
                @binding {boolean} isFirst Is first item
                @binding {boolean} isLast Is last item
                @binding {string} role Element role
                -->
                <slot
                    v-for="(item, index) in itemList"
                    v-bind:item="item"
                    v-bind:index="index"
                    v-bind:isFirst="index === 0"
                    v-bind:isLast="index === itemList.length - 1"
                    role="listitem"
                />
            </draggable>
            <div v-if="!draggable && itemList.length" class="listWrapper" v-bind:class="{ row: isRow }">
                <!--
                @slot List item content
                @binding {Model} item Current list item
                @binding {number} index Current list item index
                @binding {boolean} isFirst Is first item
                @binding {boolean} isLast Is last item
                @binding {string} role Element role
                -->
                <slot
                    v-for="(item, index) in itemList"
                    v-bind:collapsed="collapsed"
                    v-bind:item="item"
                    v-bind:index="index"
                    v-bind:isFirst="index === 0"
                    v-bind:isLast="index === itemList.length - 1"
                    role="listitem"
                />
            </div>
            <div v-if="(!itemList.length || considerEmpty) && (!loadItems || fullyLoaded)" class="listEmpty">
                <!--
                @slot List empty notice
                -->
                <slot name="empty">
                    <div class="mt-2 text-center">{{ $t('noData') }}</div>
                </slot>
            </div>
            <infinite-loading
                v-bind:identifier="dataId"
                v-on:infinite="infiniteHandler"
                v-if="loadItems"
            />
        </template>
        <template v-slot:footer v-if="$scopedSlots.footer">
            <!--
            @slot Footer content
            -->
            <slot name="footer"/>
        </template>
    </wrapper>
</template>

<script>
import draggable from 'vuedraggable';
import wrapper from './wrapper.vue';

/**
 * list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'list',
    components: {
        draggable,
        wrapper,
    },
    props: {
        /**
         * item array to be looped
         */
        items: {
            type: Array,
            required: true,
        },
        /**
         * total number of items returned from api call
         */
        itemsTotal: {
            type: Number,
            required: false,
        },
        /**
         * method for loading (more) items
         * must return a promise, which is resolved by a boolean value that indicates if there are more items to load
         * - promise resolving with true:  loading functionality stays active.                             event "itemsLoaded" is fired after list updated.
         * - promise resolving with false: list is considered fully loaded and further loading is stopped. event "itemsFullyLoaded" is fired after list updated.
         * - promise rejected with error:  show retry button and log error.                                event "itemsLoadError" is fired with error.
         */
        loadItems: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * id for infinite loader. loader will be reset if id changes.
         */
        dataId: {
            type: [String, Number],
            required: false,
            default: null,
        },
        /**
         * list title
         */
        title: {
            type: String,
            default: null,
        },
        /**
         * list title shown as large variant
         */
        largeTitle: {
            type: Boolean,
            default: false,
        },
        /**
         * list title is page main title (h1)
         */
        mainTitle: {
            type: Boolean,
            default: false,
        },
        /**
         * draggable enabled
         */
        draggable: {
            type: Boolean,
            default: false,
        },
        /**
         * prevent default draggable behaviour, only emit "draggableDrop" event.
         */
        draggablePrevent: {
            type: Boolean,
            default: false,
        },
        /**
         * set hover class "sortable-hover" during dragging over eligible elements. requires draggablePrevent to be true.
         */
        draggableHover: {
            type: Boolean,
            default: false,
        },
        /**
         * draggable group param
         */
        draggableGroup: {
            type: String,
            default: null,
        },
        /**
         * prevent dragging inside the list, only within other lists of the same draggableGroup. requires draggableGroup to be true.
         */
        draggableGroupExternal: {
            type: Boolean,
            default: false,
        },
        /**
         * prevent dragging outside the list to other lists of the same draggableGroup. requires draggableGroup to be true.
         */
        draggableGroupInternal: {
            type: Boolean,
            default: false,
        },
        /**
         *
         */
        isRow: {
            type: Boolean,
            default: false,
        },
        /**
         * buttons slot content shows both in header and below the wrapper
         */
        doubleButtons: {
            type: Boolean,
            default: false,
        },
        /**
         * disable box shadow on desktop
         */
        noShadow: {
            type: Boolean,
            default: false,
        },
        /**
         * disable top line on desktop
         */
        noLine: {
            type: Boolean,
            default: false,
        },
        /**
         * show with border on mobile
         */
        mobileBorder: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce horizontal padding to minimum
         */
        noHorizontalPadding: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce vertical padding to minimum
         */
        noVerticalPadding: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce padding to minimum
         */
        noPadding: {
            type: Boolean,
            default: false,
        },
        /**
         * reduce outer margin to minimum
         */
        noMargin: {
            type: Boolean,
            default: false,
        },
        /**
         * promise to wait for before showing top, default, and footer slot content
         */
        waitFor: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * method to bind to click action on error msg retry button
         * if not defined retry button is not shown
         */
        waitForRetry: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * stay silent while promise is loading or rejected (no loading icon/slot or error msg/slot)
         */
        waitSilent: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * stay silent while promise is loading (no loading icon/slot)
         */
        waitSilentLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * stay silent while promise is loading or rejected (no error msg/slot)
         */
        waitSilentError: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * use form tag for wrapper element
         */
        isForm: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * enable the option to collapse everything below the headline
         */
        collapseEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * change default collapse state to hidden
         */
        collapseHidden: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * wrapper class attribute
         */
        wrapperClass: {
            type: [String, Object],
            default: null,
        },
        /**
         * consider list as empty so empty slot shows if there is no loading going on
         */
        considerEmpty: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            drag: false,
            dragTarget: null,
            dragSourceIndex: null,
            dragTargetIndex: null,
            dragFutureIndex: null,
            dragSourceItem: null,
            dragTargetItem: null,
            dragFrom: null,
            dragTo: null,
            fullyLoaded: false,
            infiniteLoad: null,
            sortableHoverClass: 'sortable-hover',
        };
    },
    computed: {
        itemList: {
            get(){
                return [...this.items];
            },
            set(items){
                /**
                 * List items changed
                 *
                 * @param {Model[]} items Updated item list
                 */
                this.$emit('change', items);
            },
        },
    },
    methods: {
        checkDragMove(e){
            //if group-external is enabled, make sure that the target is in another list. if group-internal is enabled, make sure the opposite is true.
            if(this.draggableGroup && e.from && e.to && ((this.draggableGroupExternal && e.from.isSameNode(e.to)) || (this.draggableGroupInternal && !e.from.isSameNode(e.to)))){
                //reset drag target
                if(this.draggablePrevent && this.dragTarget){
                    //remove hover class
                    if(this.draggableHover){
                        this.dragTarget.classList.remove(this.sortableHoverClass);
                    }
                    this.dragTarget = null;
                }

                return false;
            }

            //get drag variables
            const draggedContext = e.draggedContext || {};
            this.dragSourceIndex = draggedContext.index;
            this.dragSourceItem = draggedContext.element;
            this.dragFutureIndex = draggedContext.futureIndex;

            const relatedContext = e.relatedContext || {};
            this.dragTargetIndex = relatedContext.index;
            this.dragTargetItem = relatedContext.element;

            this.dragFrom = e.from;
            this.dragTo = e.to;

            //get drag target
            if(this.draggablePrevent && e.related){
                //remove hover class
                if(this.draggableHover && this.dragTarget && !this.dragTarget.isSameNode(e.related)){
                    this.dragTarget.classList.remove(this.sortableHoverClass);
                }

                this.dragTarget = e.related;

                //add hover class
                if(this.draggableHover){
                    this.dragTarget.classList.add(this.sortableHoverClass);
                }
            }

            return !this.draggablePrevent;
        },
        onDragEnd(e){
            //remove hover class
            if(this.draggablePrevent && this.draggableHover && this.dragTarget){
                this.dragTarget.classList.remove(this.sortableHoverClass);
            }

            //dropping outside a possible target can be detected by checking if explicitOriginalTarget equals the dragged item
            if(!this.dragTarget || (e.item && (e.item.isSameNode(e.explicitOriginalTarget) || e.item.contains(e.explicitOriginalTarget)))){
                return;
            }

            /**
             * Successfully dropped item
             *
             * @param {object} properties Drop properties
             */
            this.$emit('draggableDrop', {
                sourceIndex: this.dragSourceIndex,
                targetIndex: this.dragTargetIndex,
                futureIndex: this.dragFutureIndex,
                sourceItem: this.dragSourceItem,
                targetItem: this.dragTargetItem,
                from: this.dragFrom,
                to: this.dragTo,
            });

            //reset drag variables
            this.dragTarget = null;
            this.dragSourceIndex = null;
            this.dragTargetIndex = null;
            this.dragFutureIndex = null;
            this.dragSourceItem = null;
            this.dragTargetItem = null;
            this.dragFrom = null;
            this.dragTo = null;
        },
        infiniteHandler(state){
            try {
                const infiniteLoad = this.loadItems();
                this.infiniteLoad = infiniteLoad;

                infiniteLoad.then(result => {
                    //if loading promise changed, ignore the old one
                    if(this.infiniteLoad !== infiniteLoad){
                        return;
                    }

                    if(result){
                        state.loaded();

                        this.$nextTick(() => {
                            /**
                             * Items successfully loaded
                             */
                            this.$emit('itemsLoaded');
                        });
                    }
                    else {
                        this.fullyLoaded = true;
                        state.complete();

                        this.$nextTick(() => {
                            /**
                             * Items Fully loaded
                             */
                            this.$emit('itemsFullyLoaded');
                        });
                    }
                }).catch(err => {
                    //if loading promise changed, ignore the old one
                    if(this.infiniteLoad !== infiniteLoad){
                        return;
                    }

                    state.error(err);
                    this.$log.error(err);

                    /**
                     * loading items has failed
                     *
                     * @param {Error} error Loading error
                     */
                    this.$emit('itemsLoadError', err);
                });
            }
            catch(err){
                state.error(err);
                this.$log.error(err);

                /**
                 * loading items has failed
                 *
                 * @param {Error} error Loading error
                 */
                this.$emit('itemsLoadError', err);
            }
        },
        onSubmit(event, validity){
            this.$emit('submit', event, validity);
        },
    },
    watch: {
        dataId(){
            this.fullyLoaded = false;
        },
    },
};
</script>
