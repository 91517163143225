import Model from '@/models/model.js';

/**
 * series tournament model class
 */
export default class SeriesTournamentModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.seriesTournamentId || 0;
            this.eqId = data.eqId || '';
            this.seriesId = data.seriesId || 0;
            this.tournamentId = data.tournamentId || 0;
            this.seriesEqId = data.seriesEqId || '';
            this.tournamentEqId = data.tournamentEqId || '';
        }
    }
}
