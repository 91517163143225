<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="$t('unionDetails')"
            v-bind:name="union ? union.name : ''"
            v-bind:waitFor="apiData.union"
            v-bind:waitForRetry="reloadUnion"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="secondary"
                    v-bind:to="editUnionLink(dataUnionId)"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <list
            v-bind:items="unionTeams"
            v-bind:title="$t('unionTeams')"
            v-bind:waitFor="apiData.unionTeams"
            v-bind:waitForRetry="reloadUnionTeams"
        >
            <template v-slot:header>
                <teamRow
                    showSport showType showCountry showDelete showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: team, role }">
                <teamRow
                    showSport showType showCountry showDelete showDetail
                    v-bind:team="team"
                    v-bind:role="role"
                />
            </template>
        </list>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import teamRow from '@/components/rows/team.vue';
import detailsHeader from '@/components/detailsHeader.vue';

import UnionModel from '@/models/union.js';
import TeamModel from '@/models/team.js';

export default {
    name: 'commonUnionDetail',
    extends: base,
    components: {
        teamRow,
        detailsHeader,
    },
    props: {
        dataUnionId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                union: this.loadUnion(),
                unionTeams: this.loadUnionTeams(),
            },
            union: null,
            unionTeams: [],
        };
    },
    methods: {
        editUnionLink(id){
            return { name: 'commonUnionEdit', params: { unionId: id } };
        },
        loadUnion(){
            return this.$api.call.union.unionGetById(this.dataUnionId).then(data => {
                this.union = new UnionModel(data);
            });
        },
        reloadUnion(){
            this.apiData.union = this.loadUnion();
        },

        loadUnionTeams(){
            return this.$api.call.union.teamSearch({
                unionId: this.dataUnionId,
                paging: {
                    pageIndex: 0,
                    pageSize: 100
                }
            }).then(result => {
                this.unionTeams = (result.data || []).map(data => new TeamModel(data));
            });
        },
        reloadUnionTeams(){
            this.apiData.unionTeams = this.loadUnionTeams();
        },
    },
};
</script>
