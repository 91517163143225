<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createSeries')"
            v-bind:waitFor="apiData.mappingProviders"
            v-bind:waitForRetry="reloadMappingProviders"
            v-on:submit="createSeries"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toSeriesList"
                    v-bind:loadingWhile="apiData.seriesCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.seriesCreation"
                >
                    {{ $t('createSeries') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || declarationWarning || mappingWarning">
                <validations
                    v-bind:warnings="((declarationWarning || mappingWarning) ? [...(declarationWarning ? [declarationWarning] : []), ...(mappingWarning ? [mappingWarning] : [])] : undefined)"
                    v-bind:errors="error ? error.getErrorMessages($t('requestError')) : undefined"
                    v-if="error || declarationWarning || mappingWarning"
                />
            </template>
            <template v-slot>
                <createSeriesForm
                    v-bind:headline="$t('seriesInformation')"
                    v-bind:model="series"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:readonly="series.id ? true : undefined"
                />
                <createEditSeriesTopForm
                    v-bind:headline="$t('seriesDeclarationTopInformation')"
                    v-bind:model="declarationTOP"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:sport="series.sport"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingCreateMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import createSeriesForm from '@/components/forms/createSeries.vue';
import createEditSeriesTopForm from '@/components/forms/createEditSeriesTop.vue';

import SeriesModel from '@/models/series.js';
import SeriesDeclarationModel from '@/models/declaration/series.js';
import WorkflowModel from '@/models/workflow.js';

export default {
    name: 'commonSeriesCreate',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingCreateMixins,
    ],
    components: {
        validations,
        createSeriesForm,
        createEditSeriesTopForm,
    },
    data(){
        const declaration = new SeriesDeclarationModel();

        return {
            apiData: {
                seriesCreation: null,
            },
            series: new SeriesModel(),
            declaration: declaration,
            declarationTOP: declaration.getConfigurationFormModel(),
            declarationWarning: null,
            mappingWarning: null,
            error: null,
        };
    },
    computed: {
        toSeriesList(){
            return { name: 'commonSeriesIndex' };
        },
    },
    methods: {
        loadMappingProviders(){
            this.series = new SeriesModel();

            return this.loadEntityMappingProviders(this.series);
        },
        createSeries(){
            let queue = Promise.resolve();

            //if logo upload file has been set, upload to storage and update series logo storage information
            if(this.series.logo instanceof File){
                queue = queue.then(() => this.$api.call.tournament.storeFiles(this.series.logo)).then(storageFile => {
                    this.series.setLogoStorage(storageFile);
                }, error => {
                    //set custom file upload validation error
                    error.errors.logo = this.$t('fileUploadError');

                    throw error;
                });
            }

            //check witch files chanced and are now File objects, so they can be uploaded. but only for enabled modules.
            const fileUploads = this.declarationTOP.getFileUploads();
            const fileUploadFiles = Object.values(fileUploads);

            //if files have changed, upload to storage and update series file storage information
            if(fileUploadFiles.length){
                queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles(fileUploadFiles)).then(storageFiles => {
                    this.declarationTOP.setFileStorages(fileUploads, storageFiles);
                }, error => {
                    throw this.declarationTOP.setFileErrors(fileUploads, error);
                });
            }

            //generate declaration config if enabled
            if(this.declarationTOP.enabled){
                //make sure series is set to declaration
                this.declaration.setSeries(this.series);

                //template
                if(this.declarationTOP.workflowId){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.declarationWorkflowsById(this.declarationTOP.workflowId)).then(data => {
                        const workflow = new WorkflowModel(data);

                        return this.$api.call.termsOfParticipation.declarationTemplateById(workflow.templateId).then(template => {
                            this.declaration.template = template;
                        });
                    });
                }

                //generate config from form data
                queue = queue.then(() => this.declaration.generateConfiguration(this.declarationTOP));
            }

            //submit series, if not already
            if(!this.series.id){
                queue = queue.then(() => this.$api.call.tournament.seriesCreate(this.series.toJSON()).then(data => {
                    this.series.id = data.id;
                    this.series.eqId = data.eqId;

                    //create mappings
                    return this.createMappings(this.series);
                }));
            }

            //map series to mapping providers
            if(this.series.eqId){
                queue = this.createMappingsUsingQueue(this.series, queue);
            }

            //submit declaration if enabled
            if(this.declarationTOP.enabled){
                //we need the series eqId so...
                if(!this.series.eqId){
                    queue = queue.then(() => this.$api.call.tournament.seriesGetById(this.series.id).then(data => {
                        this.series.eqId = data.eqId;
                    }));
                }

                queue = queue.then(() => this.$api.call.termsOfParticipation.seriesDeclarationCreate(this.declaration.toJSON()).catch(error => {
                    this.declarationWarning = this.$t('seriesDeclarationCreationWarning');
                    error.errorIdParser = this.declaration.errorIdParser.bind(this.declaration);

                    throw error;
                }));
            }

            //handle request outcome
            queue = queue.then(() => {
                this.error = null;
                this.goToSeriesDetail(this.series.id);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('series creation failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.seriesCreation = queue;
        },
        goToSeriesDetail(id){
            this.$router.push({ name: 'commonSeriesDetail', params: { dataSeriesId: id } });
        },
    },
}
</script>
