import Model from '@/models/model.js';

/**
 * tournament status model class
 */
export default class TournamentStatusModel extends Model {
    /**
     * id
     *
     * @type {number}
     */
    id = 0;

    /**
      * name
      *
      * @type {string}
      */
    name = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.statusId || data.tournamentStatusTypeId || 0;
            this.eqId = data.eqId || '';
            this.name = data.name || data.statusName || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
