<script>
import formComponent from '@/components/form.vue';
import TeamAliasModel from '@/models/teamAlias.js';
import constants from '@/constants';

/**
 * component
 */
export default {
    name: 'editTeamAliasForm',
    extends: formComponent,
    props: {
        model: {
            type: TeamAliasModel,
        },
        create: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'teamName',
                        type: 'text',
                        label: this.$t('teamName'),
                        placeholder: this.$t('enterTeamName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'teamNameShort',
                        type: 'text',
                        label: this.$t('teamNameShort'),
                        placeholder: this.$t('enterTeamNameShort'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'teamCode',
                        type: 'text',
                        label: this.$t('teamCode'),
                        placeholder: this.$t('enterTeamCode'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'teamCodeAnnual',
                        type: 'text',
                        label: this.$t('annualCode'),
                        placeholder: this.$t('enterAnnualCode'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'dateFrom',
                        type: 'date',
                        label: this.$t('validFrom'),
                        placeholder: constants.dateFormat,
                        hidden: !this.create,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                name: 'teamName',
                nameShort: 'teamNameShort',
            };
        },
    },
};
</script>
