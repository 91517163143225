import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationUserIndex from '@/views/administration/user/index.vue';
import administrationUserDetail from '@/views/administration/user/detail.vue';

export default {
    path: 'user/',
    component: pageFrame,
    children: [
        {
            name: 'administrationUserIndex',
            path: constants.routePaths.index,
            component: administrationUserIndex,
            meta: {
                permission: permissions.users.list,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.userId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationUserDetail',
                    path: constants.routePaths.index,
                    component: administrationUserDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.userId]),
                    meta: {
                        permission: permissions.users.update,
                    },
                },
            ],
        },
    ],
};
