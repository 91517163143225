<template>
    <div v-bind:class="elementClasses">
        <input type="hidden" v-bind:id="elementId" v-model="value"/>
    </div>
</template>

<script>
import base from './base.vue';

/**
 * hidden input element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'hiddenElement',
    extends: base,
    data(){
        return {
            value: null,
        };
    },
};
</script>
