<template>
    <div>
        <div>
            <icon type="warning"/> {{ message || $t('loadError') }}
        </div>
        <div class="mt-2" v-if="trigger">
            <b-button variant="secondary" v-on:click="trigger">{{ $t('retry') }}</b-button>
        </div>
    </div>
</template>

<script>
import icon from '@/components/icon.vue';

/**
 * list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'loadErrorSnippet',
    components: {
        icon,
    },
    props: {
        /**
         * retry button trigger function
         */
        trigger: Function,
        message: String,
    }
};
</script>
