<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <!--<b-form-input v-bind:id="elementId" v-bind:type="config.type" v-bind:required="config.required || false" v-bind:accept="config.accept"/>-->
        <img
            v-bind:src="filePreview || valuePreview"
            v-bind:style="config.previewStyle"
            alt=""
            class="mb-4 fileImagePreview"
            v-bind:class="{ fileImagePreviewLarge: Boolean(config.previewLarge) }"
            v-if="(filePreview || valuePreview) && config.previewOnTop"
        />
        <b-form-file
            v-model="file"
            v-bind:id="elementId"
            v-bind:browseText="$t('browse')"
            v-bind:accept="config.accept"
            v-bind:required="config.required || false"
            v-bind:disabled="config.disabled || false"
            v-bind:placeholder="$t('chooseAFileOrDropItHere')"
            v-bind:dropPlaceholder="$t('dropFileHere')"
            v-bind:state="state"
            v-if="!config.readonly"
        ></b-form-file>
        <div class="mt-3" v-if="fileName">{{ fileName }}</div>
        <img
            v-bind:src="filePreview || valuePreview"
            v-bind:style="config.previewStyle"
            alt=""
            class="mt-4 fileImagePreview"
            v-bind:class="{ fileImagePreviewLarge: Boolean(config.previewLarge) }"
            v-if="(filePreview || valuePreview) && !config.previewOnTop"
        />
    </b-form-group>
</template>

<script>
import base from './base.vue';

/**
 * file input element component
 */
export default {
    name: 'fileElement',
    extends: base,
    props: {
        dataValue: {
            type: [String, File],
        },
    },
    data(){
        return {
            value: null,
            defaultValue: null,
            valuePreview: null,
            file: null,
            filePreview: null,
            fileIsImage: false,
            fileName: null,
        };
    },
    computed: {
        state(){
            if(this.dataError){
                return false;
            }

            //return Boolean(this.file);
            return true;
        },
    },
    methods: {
        getValuePreview(){
            if(!this.value || typeof this.value !== 'string'){
                this.valuePreview = null;

                return;
            }

            try {
                //parse url
                const url = new URL(this.value);

                //only allow http/https protocol
                if(!['http:', 'https:'].includes(url.protocol)){
                    throw Error('invalid protocol: ' + url.protocol);
                }

                const imgTypes = ['png', 'jpg', 'svg', 'gif'];
                const extension = url.pathname.split('.').pop();

                if (imgTypes.includes(extension)) {
                    this.fileIsImage = true;
                }

                // if the file is not an image, get the file name
                if (!this.fileIsImage) {
                    const filename = url.pathname.substring(url.pathname.lastIndexOf('/') + 1);
                    const fileNameWithoutExtension = filename.split('.').slice(0, -1).join('.');

                    this.fileName = fileNameWithoutExtension.slice(0, -33) + '.' + extension;
                }
                else {
                    this.fileName = null;
                }

                this.valuePreview = url.toString();
            }
            catch(err){
                if(this.$log){
                    this.$log.error('unable to read file url:', err);
                }
            }
        },
    },
    watch: {
        value(value){
            this.getValuePreview();

            if(value === null){
                this.file = null;
            }
        },
        file(file){
            if(!(file instanceof File)){
                this.filePreview = null;

                return;
            }

            //update value
            this.value = file;

            //if image, generate preview
            if(file.type.match('^image.*')){
                try {
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.filePreview = e.target.result;
                    };
                    reader.readAsDataURL(file);
                }
                catch(err){
                    if(this.$log){
                        this.$log.error('error fetching image preview:', err);
                    }
                }
            }
        },
    },
    created(){
        this.getValuePreview();
    },
};
</script>
