<script>
import itemList from '@/components/itemList.vue';
import venueRow from '@/components/rows/venue.vue';
import VenueModel from '@/models/venue.js';

/**
 * complex venue list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'venueList',
    extends: itemList,
    components: {
        venueRow,
    },
    data(){
        return {
            //overwrites
            rowElement: venueRow,
            rowItemPropName: 'venue',
            selectItemProp: 'venue',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByVenue'),
                },
                {
                    id: 'countryIsoCode',
                    type: 'vselectCountry',
                    placeholder: this.$t('filterByCountry'),
                    valueProperty: 'isoCode',
                    //hidden: !this.combinedRowProps.showCountry,
                },
                {
                    id: 'location',
                    type: 'vselectLocation',
                    placeholder: this.$t('filterByPlace'),
                    valueProperty: 'name',
                    //hidden: !this.combinedRowProps.showPlace,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load venues
            return this.$api.call.location.venueSearch({
                venueName: filters.name || null,
                countryIsoCode: filters.countryIsoCode || null,
                locationName: filters.location || null,
                aliasDate: filters.aliasDate || null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.data || []).map(data => new VenueModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.location.venueDeleteById(item.id);
        },
    },
};
</script>
