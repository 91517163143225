<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createTournament')"
            v-bind:waitFor="apiData.mappingProviders"
            v-bind:waitForRetry="reloadMappingProviders"
            v-on:submit="createTournament"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentList"
                    v-bind:loadingWhile="apiData.tournamentCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentCreation"
                >
                    {{ $t('createTournament') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || declarationWarning || mappingWarning">
                <validations
                    v-bind:warnings="((declarationWarning || mappingWarning) ? [...(declarationWarning ? [declarationWarning] : []), ...(mappingWarning ? [mappingWarning] : [])] : undefined)"
                    v-bind:errors="error ? error.getErrorMessages($t('requestError')) : undefined"
                    v-if="error || declarationWarning || mappingWarning"
                />
            </template>
            <template v-slot>
                <createTournamentForm
                    v-bind:headline="$t('tournamentInformation')"
                    v-bind:model="tournament"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:readonly="tournament.id ? true : undefined"
                    v-on:change="updateDeclarationBasics"
                />
                <loading
                    v-bind:waitFor="apiData.declarationTemplate"
                    v-bind:waitForRetry="loadDeclarationTemplate"
                    v-bind:throbberProps="{ class: 'mb-3' }"
                    v-if="tournament.declarationEnabled && tournament.declarationWorkflowId"
                >
                    <preliminarySquadDeclarationForm
                        dataId="preliminary"
                        v-bind:headline="$t('preliminarySquadDeclaration')"
                        v-bind:model="declarationTOPPreliminary"
                        v-bind:errors="error ? error.getErrorIds() : undefined"
                        v-if="hasPreliminaryDeclaration"
                    />
                    <tournamentSquadDeclarationForm
                        dataId="main"
                        v-bind:headline="$t('tournamentSquadDeclaration')"
                        v-bind:model="declarationTOPMain"
                        v-bind:declaration="declaration"
                        v-bind:errors="error ? error.getErrorIds() : undefined"
                    />
                </loading>
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingCreateMixins } from '@/mixins/entityMapping.js';

import loading from '@/components/loading.vue';
import validations from '@/components/validations.vue';
import createTournamentForm from '@/components/forms/createTournament.vue';
import tournamentSquadDeclarationForm from '@/components/forms/tournamentSquadDeclaration.vue';
import preliminarySquadDeclarationForm from '@/components/forms/preliminarySquadDeclaration.vue';

import TournamentModel from '@/models/tournament.js';
import TournamentDeclarationModel from '@/models/declaration/tournament.js';
import WorkflowModel from '@/models/workflow.js';

export default {
    name: 'commonTournamentCreate',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingCreateMixins,
    ],
    components: {
        loading,
        validations,
        createTournamentForm,
        tournamentSquadDeclarationForm,
        preliminarySquadDeclarationForm,
    },
    data(){
        const declaration = new TournamentDeclarationModel();

        return {
            apiData: {
                tournamentCreation: null,
                declarationTemplate: null,
            },
            tournament: new TournamentModel(),
            declaration: declaration,
            declarationTOPPreliminary: declaration.getConfigurationFormModel(TournamentDeclarationModel.steps.preliminarySquad),
            declarationTOPMain: declaration.getConfigurationFormModel(TournamentDeclarationModel.steps.main),
            declarationWarning: null,
            mappingWarning: null,
            error: null,
        };
    },
    computed: {
        toTournamentList(){
            return { name: 'commonTournamentIndex' };
        },
        hasPreliminaryDeclaration(){
            return this.declaration.template && this.declaration.getTemplateSteps().includes(TournamentDeclarationModel.steps.preliminarySquad);
        },
    },
    methods: {
        updateDeclarationBasics(tournament){
            //update declarationTOP base data
            [this.declarationTOPPreliminary, this.declarationTOPMain].forEach(declarationTOP => {
                this.$set(declarationTOP, 'enabled', tournament.declarationEnabled);
                this.$set(declarationTOP, 'workflowId', tournament.declarationWorkflowId);
                this.$set(declarationTOP, 'openingDate', tournament.declarationOpeningDate);
                this.$set(declarationTOP, 'deadlineDate', tournament.declarationDeadlineDate);
            });

            this.loadDeclarationTemplate();
        },
        loadDeclarationTemplate(){
            if(!this.tournament.declarationEnabled || !this.tournament.declarationWorkflowId){
                this.apiData.declarationTemplate = null;
                this.declaration.template = null;

                return;
            }

            //method gets called on every form change, but we only need to request if workflowId changed
            if(this.declaration.workflowId === this.tournament.declarationWorkflowId){
                return;
            }

            this.apiData.declarationTemplate = this.$api.call.termsOfParticipation.declarationWorkflowsById(this.tournament.declarationWorkflowId).then(data => {
                const workflow = new WorkflowModel(data);

                return this.$api.call.termsOfParticipation.declarationTemplateById(workflow.templateId).then(template => {
                    this.declaration.workflowId = this.tournament.declarationWorkflowId;
                    this.declaration.template = template;

                    //if there is a "squad" step then change the main declarationTOP step to it, otherwise use "main" step
                    if(this.declaration.getTemplateSteps().includes(TournamentDeclarationModel.steps.squad)){
                        this.$set(this.declarationTOPMain, 'step', TournamentDeclarationModel.steps.squad);
                    }
                    else {
                        this.$set(this.declarationTOPMain, 'step', TournamentDeclarationModel.steps.main);
                    }
                });
            });
        },
        loadMappingProviders(){
            this.tournament = new TournamentModel();

            return this.loadEntityMappingProviders(this.tournament);
        },
        createTournament(){
            let queue = Promise.resolve();

            //if logo upload file has been set, upload to storage and update tournament logo storage information
            if(this.tournament.logo instanceof File){
                queue = queue.then(() => this.$api.call.tournament.storeFiles(this.tournament.logo)).then(storageFile => {
                    this.tournament.setLogoStorage(storageFile);
                }, error => {
                    //set custom file upload validation error
                    error.errors.logo = this.$t('fileUploadError');

                    throw error;
                });
            }

            //if preliminary declaration enabled and its templates have been set, upload to storage and update declaration information
            if(this.declarationTOPMain.enabled && this.hasPreliminaryDeclaration){
                const fileUploads = this.declarationTOPPreliminary.getFileUploads();
                const fileUploadFiles = Object.values(fileUploads);

                //upload files to storage and update preliminary declaration file storage information
                if(fileUploadFiles.length){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles(fileUploadFiles)).then(storageFiles => {
                        this.declarationTOPPreliminary.setFileStorages(fileUploads, storageFiles);
                    }, error => {
                        throw this.declarationTOPPreliminary.setFileErrors(fileUploads, error);
                    });
                }
            }

            //if main declaration enabled and its templates have been set, upload to storage and update declaration information
            if(this.declarationTOPMain.enabled){
                const fileUploads = this.declarationTOPMain.getFileUploads();
                const fileUploadFiles = Object.values(fileUploads);

                //upload files to storage and update declaration file storage information
                if(fileUploadFiles.length){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.storeFiles(fileUploadFiles)).then(storageFiles => {
                        this.declarationTOPMain.setFileStorages(fileUploads, storageFiles);
                    }, error => {
                        throw this.declarationTOPMain.setFileErrors(fileUploads, error);
                    });
                }
            }

            //generate declaration config if enabled
            if(this.declarationTOPMain.enabled){
                //make sure tournament is set to declaration
                this.declaration.setTournament(this.tournament);

                //generate config from form data
                queue = queue.then(() => {
                    //update declarationTOP base data
                    [this.declarationTOPPreliminary, this.declarationTOPMain].forEach(declarationTOP => {
                        this.$set(declarationTOP, 'enabled', this.tournament.declarationEnabled);
                        this.$set(declarationTOP, 'workflowId', this.tournament.declarationWorkflowId);
                        this.$set(declarationTOP, 'openingDate', this.tournament.declarationOpeningDate);
                        this.$set(declarationTOP, 'deadlineDate', this.tournament.declarationDeadlineDate);
                    });

                    this.declaration.generateConfiguration(this.hasPreliminaryDeclaration ? [this.declarationTOPPreliminary, this.declarationTOPMain] : this.declarationTOPMain);
                });
            }

            //submit tournament, if not already
            if(!this.tournament.id){
                queue = queue.then(() => this.$api.call.tournament.tournamentCreate(this.tournament.toJSON()).then(tournamentData => {
                    this.tournament.id = tournamentData.id;
                    this.tournament.eqId = tournamentData.eqId;

                    return this.createMappings(this.tournament);
                }));
            }

            //map tournament to mapping providers
            if(this.tournament.eqId){
                queue = this.createMappingsUsingQueue(this.tournament, queue);
            }

            //submit declaration if enabled
            if(this.declarationTOPMain.enabled){
                queue = queue.then(() => this.$api.call.termsOfParticipation.tournamentDeclarationCreate(this.declaration.toJSON()).catch(error => {
                    this.declarationWarning = this.$t('tournamentDeclarationCreationWarning');
                    error.errorIdParser = this.declaration.errorIdParser.bind(this.declaration);

                    throw error;
                }));
            }

            //handle request outcome
            queue = queue.then(() => {
                this.error = null;
                this.goToTournamentDetail(this.tournament.id);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournament creation failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.tournamentCreation = queue;
        },
        goToTournamentDetail(id){
            this.$router.push({ name: 'commonTournamentDetail', params: { dataTournamentId: id } });
        },
    },
}
</script>
