import Model from '@/models/model.js';
import TournamentTypeGroupModel from './tournamentTypeGroup';

/**
 * TournamentType model class
 */
export default class TournamentTypeModel extends Model {
    /**
     * id
     *
     * @type {number}
     */
    id = 0;

    /**
     * eqId
     *
     * @type {string}
     */
    eqId = '';

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * notes
     *
     * @type {string}
     */
    notes = '';

    /**
     * shortName
     *
     * @type {string}
     */
    shortName = '';

    /**
     * tournamentTypeGroupId
     *
     * @type {number}
     */
    tournamentTypeGroupId = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            if(!data.tournamentTypeGroup && data.tournamentGroupName){
                data.tournamentTypeGroup = {
                    name: data.tournamentGroupName,
                    sport: data.sport,
                };
            }

            this.eqId = data.eqId || data.tournamentTypeEqId || '';
            this.id = data.id || data.tournamentTypeId || 0;
            this.name = data.name || data.tournamentTypeName || '';
            this.notes = data.notes || '';
            this.numberOfParticipants = data.numberOfParticipants || 0;
            this.radarThreeSixtyName = data.radar360Name || '';
            this.shortName = data.shortName || '';
            this.tournamentTypeGroup = TournamentTypeGroupModel.from(data.tournamentTypeGroup);
            this.sport = (data.tournamentTypeGroup ? data.tournamentTypeGroup.sport : '') || data.sport || '';
            this.tournamentTypeGroupId = (data.tournamentTypeGroup ? data.tournamentTypeGroup.id ? data.tournamentTypeGroup.id : data.tournamentTypeGroup.tournamentTypeGroupId : 0) || data.tournamentTypeGroupId || 0;
        }
    }

    /**
     * get tournament type name
     *
     * @returns {string}
     */
    getTournamentTypeName(){
        return this.name || this.shortName || '';
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            name: this.name || '',
            notes: this.notes || '',
            numberOfParticipants: this.numberOfParticipants || null,
            radar360Name: this.radarThreeSixtyName || '',
            shortName: this.shortName || '',
            tournamentTypeGroupId: this.tournamentTypeGroupId || null,
        };
    }
}
