<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('name', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{ getHeadline('name', $t('companyName')) }}</label>
            <span class="text-uppercase" v-else>{{ apiConsumer.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('apiAccess', 12, 6, 3)" v-if="showApiAccess">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('apiAccess')">{{ getHeadline('apiAccess', $tc('apiAccess', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ apiConsumer.apiSubscriptionType }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('tournamentAccess', 12, 6, 3)" v-if="showTournamentAccess">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('tournamentAccess')">{{ getHeadline('tournamentAccess', $tc('tournamentAccess', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ getTournamentAccessDisplay() }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-bind:to="editLink"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from '../base.vue';

import ApiConsumerModel from '@/models/apiConsumer.js';

/**
 * union block component
 */
export default {
    name: 'apiConsumerRow',
    extends: base,
    props: {
        apiConsumer: {
            type: ApiConsumerModel,
            default(){
                return new ApiConsumerModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },
        showApiAccess: {
            type: Boolean,
            default: false,
        },
        showTournamentAccess: {
            type: Boolean,
            default: false,
        },

        showEdit: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 100,
        },
        imgHeight: {
            type: Number,
            default: 100,
        }
    },
    computed: {
        editLink(){
            return {
                name: this.detailLinkRoute || 'administrationApiConsumerDetail',
                params: Object.assign({
                    dataApiConsumerId: this.apiConsumer.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                apiConsumer: this.apiConsumer || null,
            };
        },
        hasVisibleCircles(){
            return (this.showEdit);
        }
    },
    methods: {
        getTournamentAccessDisplay(){
            let status = '';
            if (this.apiConsumer.allowAccessToAllTournaments) {
                status = this.$t('allTournaments');
            }
            else if (this.apiConsumer.tournamentCount > 1) {
                status = this.apiConsumer.tournamentCount + ' ' + this.$tc('tournament', 2);
            }
            else if (this.apiConsumer.tournamentCount <= 1) {
                status = '1 ' + this.$tc('tournament', 1);
            }
            return status;
        },
    }
};
</script>
