<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-bind:scrollable="false"
        size="xl"
    >
        <template v-slot="{ formId, submit }">
            <createVenueAliasForm
                v-bind:dataId="formId"
                v-bind:model="venueAlias"
                v-bind:errors="errorIds"
                v-bind:currentlyActiveAlias="currentlyActiveAlias"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from '@/components/modals/base.vue';
import createVenueAliasForm from '@/components/forms/createVenueAlias.vue';

import VenueAliasModel from '@/models/venueAlias.js';

/**
 * venue alias create modal component
 */
export default {
    name: 'createVenueAliasModal',
    extends: base,
    components: {
        createVenueAliasForm,
    },
    props: {
        venueAlias: {
            type: VenueAliasModel,
            default(){
                return new VenueAliasModel();
            }
        },
        currentlyActiveAlias: {
            type: String,
            default: null,
        },
    },
    computed: {
        payload(){
            return this.venueAlias;
        },
    },
};
</script>
