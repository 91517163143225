<template>
  <span class="status-indicator-icon">
    <icon class="d-inline-block align-middle mt-n1" v-bind:type="getIconType()" v-bind:color="iconColor" v-bind:title="this.status"/>
    <statusIndicator class="ml-2" v-bind:type="status" v-bind:dot="false" v-if="showText"/>
  </span>
</template>

<script>
import icon from '@/components/icon.vue';
import statusIndicator from '@/components/status-indicator.vue';

/**
 * status indicator icon component
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default {
    name: 'statusIndicatorIcon',
    components: {
        icon,
        statusIndicator,
    },
    props: {
        /**
         * status
         */
        status: {
            type: String,
            required: false,
            default: '',
        },
        showText: {
            type: Boolean,
            required: false,
            default: false,
        },
        isLocked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data(){
        return {
            iconColor: 'warning',
        };
    },
    methods: {
        getIconType(){
            let iconType = 'warning';

            switch(this.status){
                case 'Open':
                    iconType = 'warning';
                    this.iconColor = 'warning';
                    break;
                case 'InProgress':
                    iconType = 'correct';
                    this.iconColor = 'light';
                    break;
                case 'Submitted':
                    iconType = 'correct';
                    this.iconColor = 'secondary';
                    break;
                case 'Rejected':
                    iconType = 'incorrect';
                    this.iconColor = 'danger';
                    break;
                case 'Approved':
                    iconType = 'correct';
                    this.iconColor = 'success';
                    break;
                default:
                    iconType = 'warning';
                    this.iconColor = 'warning';
            }

            if (this.isLocked === true){
                this.iconColor = 'light';
            }
            return iconType;
        },
    },
}
</script>
