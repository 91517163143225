import Model from '@/models/model.js';

/**
 * role match model class
 */
export default class RoleMatchModel extends Model {
    /**
     * citing
     *
     * @type {boolean}
     */
    citing = false;

    /**
     * kick off date
     *
     * @type {string}
     */
    kickOff = '';

    /**
     * position
     *
     * @type {string}
     */
    position = '';

    /**
     * red card
     *
     * @type {boolean}
     */
    redCard = false;

    /**
     * team one code
     *
     * @type {string}
     */
    teamOneCode = '';

    /**
     * team one name
     *
     * @type {string}
     */
     teamOneName = '';

    /**
     * team one score
     *
     * @type {number}
     */
    teamOneScore = 0;

    /**
     * team two code
     *
     * @type {string}
     */
    teamTwoCode = '';

    /**
     * team two name
     *
     * @type {string}
     */
    teamTwoName = '';

    /**
     * team two score
     *
     * @type {number}
     */
    teamTwoScore = 0;

    /**
     * warning
     *
     * @type {boolean}
     */
    warning = false;

    /**
     * yellow card
     *
     * @type {boolean}
     */
    yellowCard = false;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.matchId || 0;
            this.citing = data.citing === true || false;
            this.kickOff = (data.matchDate ? this.toLocalDate(data.matchDate) : null) || '';
            this.position = data.position || '';
            this.redCard = data.redCards || 0;
            this.teamOneCode = data.team1Code || '';
            this.teamOneName = data.team1Name || '';
            this.teamOneScore = data.team1Score || 0;
            this.teamTwoCode = data.team2Code || '';
            this.teamTwoName = data.team2Name || '';
            this.teamTwoScore = data.team2Score || 0;
            this.warning = data.warning === true || false;
            this.yellowCard = data.yellowCards || 0;
        }
    }
}
