<template>
    <span v-bind:class="statusClasses">{{ type }}</span>
</template>

<script>
export default {
    name: 'statusIndicator',
    props: {
        /**
         * icon type/id
         */
        type: {
            type: String,
            required: true,
            default: '',
        },
        /**
         * icon color
         */
        color: {
            type: String,
            default: null,
        },
        /**
         * display dot
         */
        dot: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        statusClasses(){
            const classes = {
                'status-indicator': true,
                dot: this.dot,
            };
            let type = null;
            const color = null;

            switch(this.type){
                case 'open':
                case 'progress':
                case 'AccessRequested':
                    type = 'open';
                    break;
                case 'complete':
                case 'Active':
                    type = 'complete';
                    break;
                case 'Inactive':
                    type = 'open';
                    break;
                case 'InProgress':
                    type = 'progress';
                    break;
            }

            if(type){
                classes['status-' + type] = true;
            }

            if(this.color || color){
                classes['color-' + (this.color || color)] = true;
            }

            return classes;
        },
    },
}
</script>
