<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-text v-bind="getColumnProps('unmapped', 12, 12, 11)">
            <div v-if="!isHeadline"><span class="text-uppercase black">{{ $t('id') }}:</span> {{ unmapped.id }}</div>
            <div v-if="!isHeadline"><span class="text-uppercase">{{ $t('cloudWatchFilter') }}:</span> {{ unmapped.cloudWatchFilter  }}</div>
            <div v-if="!isHeadline"><span class="text-uppercase">{{ $t('correlationId') }}:</span> {{ unmapped.correlationId }}</div>
            <div v-if="!isHeadline"><span class="text-uppercase">{{ $t('creationDate') }}:</span> {{ $d(unmapped.creationDate, 'date') }}</div>
            <div v-if="!isHeadline"><span class="text-uppercase">{{ $t('description') }}:</span> {{ unmapped.description }}</div>
            <div v-if="!isHeadline"><span class="text-uppercase">{{ $t('entity') }}:</span> {{ unmapped.entity }}</div>
            <div v-if="!isHeadline"><span class="text-uppercase">{{ $t('file') }}:</span> <a v-bind:href="linkToFile" target="_blank" class="d-inline-block align-bottom">{{ unmapped.fileUrl }}</a></div>
            <div v-if="!isHeadline"><span class="text-uppercase">{{ $t('provider') }}:</span> {{ unmapped.provider }}</div>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 3, 12, 1, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', unmapped.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showDelete"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
        </b-card-text>
        <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>
        <confirmModal
            v-bind:id="modalName('confirmDelete', unmapped.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: unmapped.description })"
            v-bind:data="unmapped"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />
    </b-card>
</template>

<script>
import base from '../base.vue';

import UnmappedModel from '@/models/unmapped.js';

/**
 * unmapped block component
 */
export default {
    name: 'unmappedRow',
    extends: base,
    props: {
        unmapped: {
            type: UnmappedModel,
            required: false,
            default(){
                return new UnmappedModel();
            }
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        emitEventData(){
            return {
                unmapped: this.unmapped || null,
            };
        },
        hasVisibleCircles(){
            return this.showDelete;
        },
        linkToFile(){
            return this.$api.call.mapping.dataIngestionGetByPath(this.unmapped.fileUrl);
        },
    },
};
</script>
