<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 1, null, null, true, { hasImg: showImg, noName: !showName, })" class="card-img-wrapper" v-if="showImg">
            <b-card-img
                v-bind="getColumnProps('image')"
                v-bind:src="tournament.getImgPath()"
                v-bind:alt="tournament.name"
                v-bind:width="imgWidth"
                v-bind:height="imgHeight"
            />
        </div>

        <b-card-title v-bind="getColumnProps('name', 2)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('seriesName')" v-if="isHeadline">{{ getHeadline('name', $t('tournamentName')) }}</label>
            <span v-else>{{ tournament.name ? tournament.name : tournament.tournamentName }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('country', 12, 6, 2)" v-if="showCountry">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('country')">{{ getHeadline('country', $t('country')) }}</label>
            <span v-if="!isHeadline">{{ tournament.country }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 1)" v-if="showSport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $tc('sport', 1)) }}</label>
            <span v-if="!isHeadline">{{ tournament.sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('tournamentTypeName', 12, 6, 2)" v-if="showEventType">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('tournamentTypeName')">{{ getHeadline('tournamentTypeName', $t('eventType')) }}</label>
            <span v-if="!isHeadline">{{ tournament.getEventTypeName() }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('position', 12, 6, 2)" v-if="showPosition">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('position')">{{ getHeadline('position', $t('position')) }}</label>
            <span v-if="!isHeadline">{{ tournament.position }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('publishStatus', 12, 6, 2)" v-if="showPublishStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('publishStatus')">{{ getHeadline('publishStatus', $t('publishStatus')) }}</label>
            <publishStatusIndicator v-bind:type="tournament.publishStatus" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('startDate', 12, 6, 2)" v-if="showStartDate">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('startDate')">{{ getHeadline('startDate', $t('startDate')) }}</label>
            <span v-if="!isHeadline">{{ $fd(tournament.startDate, 'dateLong') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('endDate', 12, 6, 2)" v-if="showEndDate">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('endDate')">{{ getHeadline('endDate', $t('endDate')) }}</label>
            <span v-if="!isHeadline">{{ $fd(tournament.endDate, 'dateLong') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('dateRange', 12, 6, 2)" v-if="showDateRange">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateRange')">{{ getHeadline('dateRange', $t('tournamentDates')) }}</label>
            <span v-if="!isHeadline">{{ $fd(tournament.dateFrom) }} - {{ $fd(tournament.dateUntil) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('declaration', 12, 6, 1)" v-if="showDeclaration">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('declaration')">{{ getHeadline('declaration', $t('declaration')) }}</label>
            <span v-if="!isHeadline">{{ $fd(tournament.declaration) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('status', 12, 6, 1)" v-if="showStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('status')">{{ getHeadline('status', $t('status')) }}</label>
            <span v-if="!isHeadline">{{ $fd(tournament.status) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('deadline', 12, 6, 2)" v-if="showDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('deadline')">{{ getHeadline('deadline', $t('deadline')) }}</label>
            <span v-if="!isHeadline">{{ $fd(tournament.deadline) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowStatus', 12, 6, 2)" v-if="showWorkflowStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowStatus')">{{ getHeadline('workflowStatus', $t('status')) }}</label>
            <statusIndicator v-bind:type="tournament.workflowStatus" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowDeadline', 12, 6, 2)" v-if="showWorkflowDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowDeadline')">{{ getHeadline('workflowDeadline', $t('deadline')) }}</label>
            <span v-if="!isHeadline">{{ $fd(tournament.workflowDeadline, 'dateLong') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('select', 3, null, null, true)" class="align-self-start align-self-lg-center text-right" v-if="showSelect">
            <actionButton
                variant="outline-primary"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('select', $event)"
            >
                {{ $t('select') }}
            </actionButton>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 4, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('addTournament', $event)"
                v-if="showAdd"
            >
                <icon type="add" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-on:click="emitEvent('remove', $event)"
                v-b-modal="modalName('confirmDelete', tournament.id)"
                v-if="showRemove"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-on:click="emitEvent('move', $event)"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 5)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', tournament.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: tournament.name })"
            v-bind:data="tournament"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicator from '../status-indicator.vue';
import publishStatusIndicator from '../publish-status-indicator.vue';

import TournamentModel from '@/models/tournament.js';

/**
 * tournament block component
 */
export default {
    name: 'tournamentRow',
    extends: base,
    components: {
        statusIndicator,
        publishStatusIndicator,
    },
    props: {
        tournament: {
            type: TournamentModel,
            default(){
                return new TournamentModel();
            }
        },

        showImg: {
            type: Boolean,
            default: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showCountry: {
            type: Boolean,
            default: false,
        },
        showStartDate: {
            type: Boolean,
            default: false,
        },
        showEndDate: {
            type: Boolean,
            default: false,
        },
        showDateRange: {
            type: Boolean,
            default: false,
        },
        showDeclaration: {
            type: Boolean,
            default: false,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        showPublishStatus: {
            type: Boolean,
            default: false,
        },
        showSport: {
            type: Boolean,
            default: false,
        },
        showDeadline: {
            type: Boolean,
            default: false,
        },
        showEventType: {
            type: Boolean,
            default: false,
        },
        showPosition: {
            type: Boolean,
            default: false,
        },
        showWorkflowStatus: {
            type: Boolean,
            default: false,
        },
        showWorkflowDeadline: {
            type: Boolean,
            default: false,
        },

        showSelect: {
            type: Boolean,
            default: false,
        },

        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showAdd: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with remove icon
         * clicking it will trigger "remove" event
         */
        showRemove: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with arrow icon
         * clicking it will trigger "move" event
         */
        showDetail: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with arrow icon
         */
        showArrow: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },

        removeHandler: {
            type: Function,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonTournamentDetail',
                params: Object.assign({
                    dataTournamentId: this.tournament.id,
                }, this.detailLinkParams),
            };
        },
        arrowLink(){
            return {
                name: this.arrowLinkRoute || 'commonTournamentDetail',
                params: Object.assign({
                    dataTournamentId: this.tournament.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                tournament: this.tournament || null,
            };
        },
        hasVisibleCircles(){
            return (this.showAdd || this.showRemove || this.showDetail || this.showArrow);
        },
    },
};
</script>
