import Model from '@/models/model.js';

/**
 * place model class
 */
export default class IpAddressModel extends Model {
    /**
     * ip address
     *
     * @type {string}
     */
    ip = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.ip = data.ip || data.ipAddress || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.ip;
    }

    toJSON(){
        return {
            ipAddress: this.ip,
        }
    }
}
