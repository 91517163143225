<template>
    <loading
        tag="ul"
        class="d-flex flex-row flex-wrap mx-n2 my-0 p-0 list-unstyled text-center text-uppercase font-heavy"
        v-bind="{ waitFor, waitForRetry, waitSilent, waitSilentLoading, waitSilentError }"
    >
        <li
            v-for="(tile, i) in tiles"
            v-bind:key="i"
            v-bind:class="tileClasses"
            v-bind:style="tileStyles"
        >
            <label class="m-0">{{ tile.label || tile.name }}</label>
            <p class="h2 m-0">{{ tile.value }}</p>
        </li>
    </loading>
</template>

<script>
import loading from '@/components/loading.vue';

/**
 * tiles component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'tiles',
    components: {
        loading,
    },
    props: {
        /**
         * list of tiles to show.
         * has to be an array of objects. object properties: label/name, value
         */
        tiles: {
            type: Array,
            required: true,
        },
        /**
         * fixed tile width
         */
        tileWidth: {
            type: Number,
            required: false,
            default: 10,
        },
        /**
         * fixed tile height
         */
        tileHeight: {
            type: Number,
            required: false,
            default: null,
        },
        /**
         * unity used for tileWidth and tileHeight
         */
        tileSizeUnit: {
            type: String,
            required: false,
            default: 'rem',
        },
        /**
         * promise to wait for before showing top, default, and footer slot content
         */
        waitFor: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * method to bind to click action on error msg retry button
         * if not defined retry button is not shown
         */
        waitForRetry: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * stay silent while promise is loading or rejected (no loading icon/slot or error msg/slot)
         */
        waitSilent: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * stay silent while promise is loading (no loading icon/slot)
         */
        waitSilentLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * stay silent while promise is loading or rejected (no error msg/slot)
         */
        waitSilentError: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        tileClasses(){
            return {
                'd-flex': true,
                'flex-column': true,
                'justify-content-center': true,
                //'flex-grow-1': true,
                'm-2': true,
                'px-2': true,
                'py-3': true,
                border: true,
                'rounded-xl': true,
                'border-light': true,
            };
        },
        tileStyles(){
            return {
                width: this.tileWidth ? this.tileWidth + this.tileSizeUnit : null,
                height: this.tileHeight ? this.tileHeight + this.tileSizeUnit : null,
            };
        },
    },
};
</script>
