<script>
import vselectElement from '../vselect.vue';
import TournamentTeamModel from '@/models/tournamentTeam.js';

/**
 * vselect element for TournamentTeam items
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default {
    name: 'vselectTournamentTeamElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return this.$api.call.tournament.tournamentTeamsGetAllByTournamentId(config.tournamentId)
                    .then(result => (result || []).teams.map(data => new TournamentTeamModel(data)))
                    .then(items => items.filter(team =>
                        team.teamName.toLowerCase().includes(text.toLowerCase())
                    ));
            };

            return config;
        },
    },
};
</script>
