<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('teamName', 1)" v-bind:title-tag="titleTag" v-if="teamName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamName')" v-if="isHeadline">{{ getHeadline('teamName', $t('teamName')) }}</label>
            <span v-else>{{ playerRepresenting.teamName }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 2)" v-if="sport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $t('sport')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ playerRepresenting.sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('union', 12, 6, 2)" v-if="union">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('union')">{{ getHeadline('union', $t('union')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ playerRepresenting.union ? playerRepresenting.union.name : '' }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDeletePlayerRepresenting', playerRepresenting.teamAliasEqId)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <b-modal
            v-bind:id="modalName('confirmDeletePlayerRepresenting', playerRepresenting.teamAliasEqId)"
            title="Confirmation"
            v-on:ok="emitEvent('removeConfirmed', $event)"
            scrollable
            centered
            no-close-on-backdrop
            v-if="showRemove"
        >
            <template v-slot:modal-header-close>
                <icon type="cancel" color="primary"/>
            </template>
            <template v-slot>
                <p class="my-4">
                    {{ $t('areYouSureToDelete', { type: playerRepresenting.teamName }) }}
                </p>
            </template>
        </b-modal>

    </b-card>
</template>

<script>
import base from './base.vue';

import PlayerRepresentingModel from '@/models/person/playerRepresenting.js';

/**
 * player representing block component
 */
export default {
    name: 'playerRepresentingRow',
    extends: base,
    props: {
        playerRepresenting: {
            type: PlayerRepresentingModel,
            default(){
                return new PlayerRepresentingModel();
            }
        },

        teamName: {
            type: Boolean,
            default: true,
        },
        sport: {
            type: Boolean,
            default: false,
        },
        union: {
            type: Boolean,
            default: false,
        },

        showRemove: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        emitEventData(){
            return {
                representing: this.playerRepresenting || null,
            };
        },
        hasVisibleCircles(){
            return (this.showRemove);
        },
    },
};
</script>
