import constants from '@/constants.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationMergePersonnelIndex from '@/views/administration/mergePersonnel/index.vue';

export default {
    path: 'merge-personnel/',
    component: pageFrame,
    children: [
        {
            name: 'administrationMergePersonnelIndex',
            path: constants.routePaths.index,
            component: administrationMergePersonnelIndex,
            meta: {
                permission: permissions.mergePersons.merge,
            },
        },
    ],
};
