<script>
import formComponent from '@/components/form.vue';
import ApiConsumerModel from '@/models/apiConsumer.js';

/**
 * component
 */
export default {
    name: 'apiConsumerForm',
    extends: formComponent,
    props: {
        model: {
            type: ApiConsumerModel,
        },
        apiKeyLoadingWhile: {
            type: Promise,
            required: false,
            default: null,
        },
    },
    methods: {
        generateKey(e){
            this.$emit('generateKey', e);
        },
        deleteKey(e){
            this.$emit('deleteKey', e);
        },
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('companyName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'apiSubscriptionType',
                        type: 'vselectSubscriptionType',
                        label: this.$t('subscriptionType'),
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'accessDataFrom',
                        type: 'date',
                        label: this.$t('accessStartDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'accessDataTo',
                        type: 'date',
                        label: this.$t('accessEndDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'apiKey',
                        type: 'text',
                        label: this.$t('apiKey'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        readonly: true,
                    },
                    {
                        type: 'row',
                        props: {
                            'align-h': 'start',
                        },
                        cols: [
                            {
                                props: {
                                    cols: 'auto',
                                },
                                elements: [
                                    {
                                        id: 'apiKey',
                                        type: 'button',
                                        label: '\xa0',
                                        text: this.$t('generateNewKey'),
                                        variant: 'outline-primary',
                                        disabled: this.readonly,
                                        onClick: this.generateKey,
                                        loadingWhile: this.apiKeyLoadingWhile,
                                        loadingThrobber: true,
                                    },
                                ],
                            },
                            {
                                props: {
                                    cols: 'auto',
                                },
                                elements: [
                                    {
                                        id: 'deleteKey',
                                        type: 'button',
                                        label: '\xa0',
                                        text: this.$t('deleteKey'),
                                        variant: 'outline-primary',
                                        disabled: this.readonly,
                                        onClick: this.deleteKey,
                                        loadingWhile: this.apiKeyLoadingWhile,
                                    },
                                ],
                            },
                        ],
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
