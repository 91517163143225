<script>
import personList from './person.vue';
import medicRow from '@/components/rows/medic.vue';
import MedicModel from '@/models/person/medic.js';
import constants from '@/constants.js';

/**
 * complex medic list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'medicList',
    extends: personList,
    components: {
        medicRow,
    },
    data(){
        return {
            //overwrites
            rowElement: medicRow,
            ItemModel: MedicModel,
            personRole: constants.personRoleType.management,
        };
    },
};
</script>
