import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationRankingPenaltiesIndex from '@/views/administration/rankings/penalties/index.vue';
import administrationRankingPenaltiesCreate from '@/views/administration/rankings/penalties/create.vue';
import administrationRankingPenaltiesEdit from '@/views/administration/rankings/penalties/edit.vue';

export default {
    path: 'penalty/',
    component: pageFrame,
    children: [
        {
            name: 'administrationRankingPenaltiesIndex',
            path: constants.routePaths.index,
            component: administrationRankingPenaltiesIndex,
            props: true,
            meta: {
                permission: permissions.rankings.penalties.list,
            },
        },
        {
            name: 'administrationRankingPenaltiesCreate',
            path: constants.routePaths.create,
            component: administrationRankingPenaltiesCreate,
            props: true,
            meta: {
                permission: permissions.rankings.penalties.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.penaltyId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationRankingPenaltiesEdit',
                    path: constants.routePaths.edit,
                    component: administrationRankingPenaltiesEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.penaltyId]),
                    meta: {
                        permission: permissions.rankings.penalties.update,
                    },
                },
            ],
        },
    ],
};
