<script>
import vselectElement from '../vselect.vue';
import TournamentModel from '@/models/tournament.js';

/**
 * vselect element for TournamentModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectTournamentElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                return this.$api.call.tournament.tournamentSearch({
                    title: text || null,
                    paging: this.usePagination(offset),
                }).then(result => (result.tournaments || result.items || result.data || []).map(data => new TournamentModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    case 'eqId':
                        return Promise.all(initValues.map(eqId => this.$api.call.tournament.tournamentGetByEqId(eqId)))
                            .then(results => results.map(data => new TournamentModel(data)));
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.tournament.tournamentGetById(id)))
                            .then(results => results.map(data => new TournamentModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
