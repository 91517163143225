<template>
    <ul class="validations d-flex flex-column flex-lg-row flex-wrap list-unstyled m-0 p-0" v-bind:class="{ 'mb-3': !noMargin }">
        <li v-if="maxReached">
            <icon type="success"/>
            {{ $tc('validationMaxReached', maxReached, { type: $tc(type, 2), max: $n(max, 'nr'), count: $n(items.length, 'nr') }) }}
        </li>
        <li v-if="aboveMax">
            <icon type="warning"/>
            {{ $tc('validationAboveMax', aboveMax, { type: $tc(type, 2), max: $n(max, 'nr'), count: $n(items.length, 'nr') }) }}
        </li>
        <li v-if="belowMin">
            <icon type="warning"/>
            {{ $tc('validationBelowMin', belowMin, { type: $tc(type, 2), min: $n(min, 'nr'), count: $n(items.length, 'nr') }) }}
        </li>

        <li v-if="!isMatch && isBelowMinTotalNumberOfManagement">
            <icon type="warning"/>
            {{ $tc('validationBelowMinTotalNumberOfManagement', minimumTotalNumberOfManagement, { type: $t('management'), min: $n(minimumTotalNumberOfManagement, 'nr'), count: $n(totalManagement, 'nr') }) }}
        </li>
        <li v-if="!isMatch && isAboveMaxTotalNumberOfManagement">
            <icon type="warning"/>
            {{ $tc('validationAboveMaxTotalNumberOfManagement', maximumTotalNumberOfManagement, { type: $t('management'), max: $n(maximumTotalNumberOfManagement, 'nr'), count: $n(totalManagement, 'nr') }) }}
        </li>

        <li v-if="!isReview && !isMatch && missingBiographies">
            <icon type="warning"/>
            {{ $tc('validationMissingBiographies', missingBiographies, { type: $tc(type, missingBiographies), count: $n(missingBiographies, 'nr') }) }}
        </li>
        <li v-if="!isReview && !isMatch && missingBiographyData">
            <icon type="warning"/>
            {{ $tc('validationMissingBiographyData', missingBiographyData, { type: $tc(type, missingBiographyData), count: $n(missingBiographyData, 'nr') }) }}
        </li>
        <li v-if="isReview && !isMatch && unsubmittedBiographies">
            <icon type="warning"/>
            {{ $tc('validationNotSubmittedBiographies', unsubmittedBiographies, { type: $tc(type, unsubmittedBiographies), count: $n(unsubmittedBiographies, 'nr') }) }}
        </li>
        <li v-if="isReview && !isMatch && submittedBiographies">
            <icon type="warning"/>
            {{ $tc('validationNotVerifiedBiographies', submittedBiographies, { type: $tc(type, submittedBiographies), count: $n(submittedBiographies, 'nr') }) }}
        </li>
        <li v-if="isReview && !isMatch && rejectedBiographies">
            <icon type="danger"/>
            {{ $tc('validationRejectedBiographies', rejectedBiographies, { type: $tc(type, rejectedBiographies), count: $n(rejectedBiographies, 'nr') }) }}
        </li>

        <li v-if="!isReview && !isMatch && missingPassports">
            <icon type="warning"/>
            {{ $tc('validationMissingPassports', missingPassports, { type: $tc(type, missingPassports), count: $n(missingPassports, 'nr') }) }}
        </li>
        <li v-if="!isReview && !isMatch && missingPassportsData">
            <icon type="warning"/>
            {{ $tc('validationMissingPassportData', missingPassportsData, { type: $tc(type, missingPassportsData), count: $n(missingPassportsData, 'nr') }) }}
        </li>
        <li v-for="person in (!isMatch ? expiringPassports : [])" v-bind:key="person.id">
            <icon type="warning"/>
            {{ $t('validationExpiringPassport', { name: person.join(', ') }) }}
        </li>
        <li v-if="isReview && !isMatch && unsubmittedPassports">
            <icon type="warning"/>
            {{ $tc('validationNotSubmittedPassports', unsubmittedPassports, { type: $tc(type, unsubmittedPassports), count: $n(unsubmittedPassports, 'nr') }) }}
        </li>
        <li v-if="isReview && !isMatch && submittedPassports">
            <icon type="warning"/>
            {{ $tc('validationNotVerifiedPassports', submittedPassports, { type: $tc(type, submittedPassports), count: $n(submittedPassports, 'nr') }) }}
        </li>
        <li v-if="isReview && !isMatch && rejectedPassports">
            <icon type="danger"/>
            {{ $tc('validationRejectedPassports', rejectedPassports, { type: $tc(type, rejectedPassports), count: $n(rejectedPassports, 'nr') }) }}
        </li>

        <li v-if="!isReview && !isMatch && missingMedicalCertificates">
            <icon type="warning"/>
            {{ $tc('validationMissingMedicalCertificates', missingMedicalCertificates, { type: $tc(type, missingMedicalCertificates), count: $n(missingMedicalCertificates, 'nr') }) }}
        </li>
        <li v-if="!isReview && !isMatch && missingMedicalCertificatesData">
            <icon type="warning"/>
            {{ $tc('validationMissingMedicalCertificateData', missingMedicalCertificatesData, { type: $tc(type, missingMedicalCertificatesData), count: $n(missingMedicalCertificatesData, 'nr') }) }}
        </li>
        <li v-if="isReview && unsubmittedMedicalCertificates">
            <icon type="warning"/>
            {{ $tc('validationNotSubmittedMedicalCertificates', unsubmittedMedicalCertificates, { type: $tc(type, unsubmittedMedicalCertificates), count: $n(unsubmittedMedicalCertificates, 'nr') }) }}
        </li>
        <li v-if="isReview && submittedMedicalCertificates">
            <icon type="warning"/>
            {{ $tc('validationNotVerifiedMedicalCertificates', submittedMedicalCertificates, { type: $tc(type, submittedMedicalCertificates), count: $n(submittedMedicalCertificates, 'nr') }) }}
        </li>
        <li v-if="isReview && rejectedMedicalCertificates">
            <icon type="danger"/>
            {{ $tc('validationRejectedMedicalCertificates', rejectedMedicalCertificates, { type: $tc(type, rejectedMedicalCertificates), count: $n(rejectedMedicalCertificates, 'nr') }) }}
        </li>

        <li v-if="!isReview && !isMatch && missingPhotos">
            <icon type="warning"/>
            {{ $tc('validationMissingPhotos', missingPhotos, { type: $tc(type, missingPhotos), count: $n(missingPhotos, 'nr') }) }}
        </li>
        <li v-if="isReview && !isMatch && unsubmittedPhotos">
            <icon type="warning"/>
            {{ $tc('validationNotSubmittedPhotos', unsubmittedPhotos, { type: $tc(type, unsubmittedPhotos), count: $n(unsubmittedPhotos, 'nr') }) }}
        </li>
        <li v-if="isReview && !isMatch && submittedPhotos">
            <icon type="warning"/>
            {{ $tc('validationNotVerifiedPhotos', submittedPhotos, { type: $tc(type, submittedPhotos), count: $n(submittedPhotos, 'nr') }) }}
        </li>
        <li v-if="isReview && !isMatch && rejectedPhotos">
            <icon type="danger"/>
            {{ $tc('validationRejectedPhotos', rejectedPhotos, { type: $tc(type, rejectedPhotos), count: $n(rejectedPhotos, 'nr') }) }}
        </li>

        <li v-if="!isReview && missingPositions">
            <icon type="warning"/>
            {{ $tc('validationMissingPosition', missingPositions, { type: $tc(type, missingPositions), count: $n(missingPositions, 'nr') }) }}
        </li>
        <li v-if="isReview && unsubmittedPositions">
            <icon type="warning"/>
            {{ $tc('validationNotSubmittedPosition', unsubmittedPositions, { type: $tc(type, unsubmittedPositions), count: $n(unsubmittedPositions, 'nr') }) }}
        </li>
        <li v-if="isReview && submittedPositions">
            <icon type="warning"/>
            {{ $tc('validationNotVerifiedPosition', submittedPositions, { type: $tc(type, submittedPositions), count: $n(submittedPositions, 'nr') }) }}
        </li>
        <li v-if="rejectedPositions">
            <icon type="danger"/>
            {{ $tc('validationRejectedPosition', rejectedPositions, { type: $tc(type, rejectedPositions), count: $n(rejectedPositions, 'nr') }) }}
        </li>

        <li v-for="person in (showUnapprovedMedicReplacements ? unapprovedMedicReplacements : [])" v-bind:key="person.id">
            <icon type="warning"/>
            {{ $t('validationUnapprovedMedicReplacement', { type: $tc(type, 1), name: person }) }}
        </li>

        <li v-if="!isReview && type === 'teamKit'">
            <span class="icon icon-utf8 text-info text-lowercase">ⓘ</span> {{ $t('kitDescription') }}
        </li>
        <li v-if="missingPrimaryKitImage">
            <icon type="danger"/>
            {{ $t('validationPrimaryKitNotUploaded') }}
        </li>
        <li v-if="missingSecondaryKitImage">
            <icon type="danger"/>
            {{ $t('validationSecondaryKitNotUploaded') }}
        </li>
        <li v-if="missingPrimaryKitColours">
            <icon type="danger"/>
            {{ $t('validationPrimaryKitColoursNotDeclared') }}
        </li>
        <li v-if="missingSecondaryKitColours">
            <icon type="danger"/>
            {{ $t('validationSecondaryKitColoursNotDeclared') }}
        </li>
        <li v-if="isReview && (unsubmittedTeamKitPrimary || unsubmittedTeamKitSecondary)">
            <icon type="warning"/>
            {{ $t('validationNotSubmittedTeamKit', { type: $tc('teamkit', 2) }) }}
        </li>
        <li v-if="isReview && (submittedTeamKitPrimary || submittedTeamKitSecondary)">
            <icon type="warning"/>
            {{ $t('validationNotVerifiedTeamKit', { type: $tc('teamkit', 2) }) }}
        </li>
        <li v-if="isReview && (rejectedTeamKitPrimary || rejectedTeamKitSecondary)">
            <icon type="danger"/>
            {{ $t('validationRejectedTeamKit', { type: $tc('teamkit', 2) }) }}
        </li>

        <li v-if="!isReview && missingParticipationAgreements">
            <icon type="warning"/>
            {{ $t('submitParticipationAgreement') }}
        </li>
        <li v-if="isReview && unsubmittedParticipationAgreement">
            <icon type="warning"/>
            {{ $t('validationNotSubmittedParticipationAgreement') }}
        </li>
        <li v-if="isReview && submittedParticipationAgreement">
            <icon type="warning"/>
            {{ $t('validationNotVerifiedParticipationAgreement') }}
        </li>
        <li v-if="isReview && rejectedParticipationAgreement">
            <icon type="danger"/>
            {{ $t('validationRejectedParticipationAgreement') }}
        </li>

        <li v-if="isReview && unsubmittedTeamLogo">
            <icon type="warning"/>
            {{ $t('validationNotSubmittedTeamLogo') }}
        </li>
        <li v-if="isReview && submittedTeamLogo">
            <icon type="warning"/>
            {{ $t('validationNotVerifiedTeamLogo') }}
        </li>
        <li v-if="isReview && rejectedTeamLogo">
            <icon type="danger"/>
            {{ $t('validationRejectedTeamLogo') }}
        </li>

        <li v-if="isReview && unsubmittedNationalAnthem">
            <icon type="warning"/>
            {{ $t('validationNotSubmittedNationalAnthem') }}
        </li>
        <li v-if="isReview && submittedNationalAnthem">
            <icon type="warning"/>
            {{ $t('validationNotVerifiedNationalAnthem') }}
        </li>
        <li v-if="isReview && rejectedNationalAnthem">
            <icon type="danger"/>
            {{ $t('validationRejectedNationalAnthem') }}
        </li>

        <li v-if="isReview && unsubmittedMedicalInsuranceCover">
            <icon type="warning"/>
            {{ $t('validationNotSubmittedMedicalInsuranceCover') }}
        </li>
        <li v-if="isReview && submittedMedicalInsuranceCover">
            <icon type="warning"/>
            {{ $t('validationNotVerifiedMedicalInsuranceCover') }}
        </li>
        <li v-if="isReview && rejectedMedicalInsuranceCover">
            <icon type="danger"/>
            {{ $t('validationRejectedMedicalInsuranceCover') }}
        </li>

        <li v-if="isReview && unsubmittedTeamDisciplinaryCertificate">
            <icon type="warning"/>
            {{ $t('validationNotSubmittedTeamDisciplinaryCertificate') }}
        </li>
        <li v-if="isReview && submittedTeamDisciplinaryCertificate">
            <icon type="warning"/>
            {{ $t('validationNotVerifiedTeamDisciplinaryCertificate') }}
        </li>
        <li v-if="isReview && rejectedTeamDisciplinaryCertificate">
            <icon type="danger"/>
            {{ $t('validationRejectedTeamDisciplinaryCertificate') }}
        </li>

        <li v-if="isReview && unsubmittedTeamMemberAcceptanceForm">
            <icon type="warning"/>
            {{ $t('validationNotSubmittedTeamMemberAcceptanceForm') }}
        </li>
        <li v-if="isReview && submittedTeamMemberAcceptanceForm">
            <icon type="warning"/>
            {{ $t('validationNotVerifiedTeamMemberAcceptanceForm') }}
        </li>
        <li v-if="isReview && rejectedTeamMemberAcceptanceForm">
            <icon type="danger"/>
            {{ $t('validationRejectedTeamMemberAcceptanceForm') }}
        </li>

        <li v-if="isReview && unsubmittedTeamPhoneticGuide">
            <icon type="warning"/>
            {{ $t('validationNotSubmittedTeamPhoneticGuide') }}
        </li>
        <li v-if="isReview && submittedTeamPhoneticGuide">
            <icon type="warning"/>
            {{ $t('validationNotVerifiedTeamPhoneticGuide') }}
        </li>
        <li v-if="isReview && rejectedTeamPhoneticGuide">
            <icon type="danger"/>
            {{ $t('validationRejectedTeamPhoneticGuide') }}
        </li>

        <li v-for="(text, i) in notices" v-bind:key="'notice' + i">
            <span class="icon icon-utf8 text-info text-lowercase">ⓘ</span> {{ text }}
        </li>
        <li v-for="(text, i) in successes" v-bind:key="'success' + i">
            <icon type="confirm"/> {{ text }}
        </li>
        <li v-for="(text, i) in acknowledgements" v-bind:key="'acknowledgement' + i">
            <icon type="warning"/> {{ text }}
        </li>
        <li v-for="(text, i) in warnings" v-bind:key="'warning' + i">
            <icon type="warning"/> {{ text }}
        </li>
        <li v-for="(text, i) in errors" v-bind:key="'error' + i">
            <icon type="danger"/> {{ text }}
        </li>
    </ul>
</template>

<script>
import icon from './icon.vue';
import constants from '../constants.js';

import Model from '@/models/model.js';
import DeclarationModel from '@/models/declaration.js';

export default {
    name: 'validations',
    components: {
        icon,
    },
    props: {
        /**
         * list of items to validate
         */
        items: {
            type: Array,
            required: false,
            default(){
                return [];
            }
        },
        /**
         * type of validation handling
         */
        type: {
            type: String,
            required: false,
            validator(value){
                return ['player', 'manager', 'medic', 'replacementPlayers', 'teamKit', 'participationAgreement', 'teamLogo', 'nationalAnthem', 'medicalInsuranceCover', 'teamDisciplinaryCertificate', 'teamMemberAcceptanceForm', 'teamPhoneticGuide'].includes(value);
            },
        },
        /**
         * required minimum number of items
         */
        min: {
            type: Number,
            default: null,
        },
        /**
         * maximum allowed number of items
         */
        max: {
            type: Number,
            default: null,
        },
        /**
         * static notice messages
         */
        notices: {
            type: Array,
            default(){
                return [];
            }
        },
        /**
         * static success messages
         */
        successes: {
            type: Array,
            default(){
                return [];
            }
        },
        /**
         * static acknowledgement messages
         */
        acknowledgements: {
            type: Array,
            default(){
                return [];
            }
        },
        /**
         * static warning messages
         */
        warnings: {
            type: Array,
            default(){
                return [];
            }
        },
        /**
         * static error messages
         */
        errors: {
            type: Array,
            default(){
                return [];
            }
        },
        /**
         * remove margin
         */
        noMargin: {
            type: Boolean,
            default: false,
        },
        showUnapprovedMedicReplacements: {
            type: Boolean,
            default: false,
        },
        isReview: {
            type: Boolean,
            default: false,
        },
        isMatch: {
            type: Boolean,
            default: false,
        },
        declaration: {
            type: DeclarationModel,
            default: null,
        },
        /**
         * the number of managers and medics combined
         */
        totalManagement: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        maxReached(){
            if(!this.max){
                return false;
            }
            return this.items.length === this.max;
        },
        aboveMax(){
            if(!this.max){
                return false;
            }
            return this.items.length > this.max;
        },
        belowMin(){
            if(!this.min || (this.min && this.min === 0)){
                return false;
            }
            return this.items.length < this.min;
        },

        missingBiographies(){
            return this.items.filter(item =>
                item instanceof Model &&
                this.hasBiographyModule &&
                !item.biographyStatus &&
                (item.fileId !== 'participationAgreement') &&
                (this.type !== 'teamKit')).length;
        },
        missingBiographyData(){
            return this.items.filter(item =>
                item instanceof Model &&
                this.hasBiographyModule &&
                item.biographyStatus !== constants.declarationStatus.approved &&
                (
                    typeof item.nickname === 'undefined' ||
                    typeof item.startPlaying === 'undefined' ||
                    typeof item.rugbyFirstSteps === 'undefined' ||
                    typeof item.highlightsCareer === 'undefined' ||
                    typeof item.favouriteSeriesLocation === 'undefined' ||
                    typeof item.favouriteAthletes === 'undefined' ||
                    typeof item.rugbyPlayingFamily === 'undefined' ||
                    typeof item.bestAdviceGiven === 'undefined' ||
                    typeof item.favouriteFood === 'undefined' ||
                    typeof item.favouriteMovie === 'undefined' ||
                    typeof item.namePronunciation === 'undefined' ||
                    typeof item.tournamentsPlayedPoints === 'undefined' ||
                    typeof item.height === 'undefined' ||
                    typeof item.weight === 'undefined' ||
                    typeof item.hand === 'undefined' ||
                    typeof item.foot === 'undefined'
                )
            ).length;
        },
        unsubmittedBiographies(){
            if (!this.type){
                return 0;
            }
            return this.items.filter(
                item => item instanceof Model &&
                this.hasBiographyModule &&
                item.biographyStatus &&
                item.fileId !== 'participationAgreement' &&
                this.type !== 'teamKit' &&
                (item.biographyStatus === constants.declarationStatus.open || item.biographyStatus === constants.declarationStatus.inProgress)).length;
        },
        submittedBiographies(){
            if (!this.type){
                return 0;
            }
            return this.items.filter(
                item => item instanceof Model &&
                this.hasBiographyModule &&
                item.biographyStatus &&
                item.fileId !== 'participationAgreement' &&
                this.type !== 'teamKit' &&
                item.biographyStatus === constants.declarationStatus.submitted).length;
        },
        rejectedBiographies(){
            if (!this.type){
                return 0;
            }
            return this.items.filter(
                item => item instanceof Model &&
                this.hasBiographyModule &&
                item.biographyStatus &&
                item.fileId !== 'participationAgreement' &&
                this.type !== 'teamKit' &&
                item.biographyStatus === constants.declarationStatus.rejected).length;
        },

        missingPassports(){
            return this.items.filter(item => item instanceof Model && this.hasPassportModule && !item.passportStatus && (this.fileId !== 'participationAgreement') && (this.type !== 'teamKit')).length;
        },
        missingPassportsData(){
            return this.items.filter(item =>
                item.status !== constants.declarationStatus.approved &&
                this.isMissingPassportData(item)
            ).length;
        },
        expiringPassports() {
            let expiringPassports = [];

            this.items.filter(item => {
                if (
                    item instanceof Model &&
                    this.hasPassportModule &&
                    item.passportExpiryDate !== null &&
                    this.passportIsCloseToExpiring(item.passportExpiryDate)
                ) {
                    expiringPassports.push([item.firstName, item.lastName])
                }
            });

            return expiringPassports;
        },
        unsubmittedPassports(){
            return this.items.filter(item => item instanceof Model && this.hasPassportModule && item.passportStatus && (item.passportStatus === constants.declarationStatus.open || item.passportStatus === constants.declarationStatus.inProgress) && item.fileId !== 'participationAgreement' && this.type !== 'teamKit').length;
        },
        submittedPassports(){
            return this.items.filter(item => item instanceof Model && this.hasPassportModule && item.passportStatus && item.passportStatus === constants.declarationStatus.submitted && item.fileId !== 'participationAgreement' && this.type !== 'teamKit').length;
        },
        rejectedPassports(){
            return this.items.filter(item => item instanceof Model && item.passportStatus && item.passportStatus === constants.declarationStatus.rejected && item.fileId !== 'participationAgreement' && this.type !== 'teamKit').length;
        },

        unapprovedMedicReplacements() {
            let medicReplacementsNotApproved = [];

            this.items.filter(item => {
                if (
                    item instanceof Model &&
                    item.roleType === 'Player' &&
                    item.replacedById &&
                    item.status !== constants.declarationStatus.approved
                ) {
                    medicReplacementsNotApproved.push([item.displayName]);
                }
            });

            return medicReplacementsNotApproved;
        },

        missingMedicalCertificates(){
            return this.items.filter(item =>
                item instanceof Model && this.hasMedicalCertificateModule &&
                item.roleType === 'Medical' &&
                !item.medicalCertificateStatus
            ).length;
        },
        missingMedicalCertificatesData(){
            return this.items.filter(item =>
                item instanceof Model &&
                this.hasMedicalCertificateModule &&
                item.roleType === 'Medical' &&
                item.status !== constants.declarationStatus.approved &&
                (
                    !item.medicalCertificateUrl ||
                    !item.medicalCertificateUploadDate
                )
            ).length;
        },
        unsubmittedMedicalCertificates(){
            return this.items.filter(item =>
                item instanceof Model &&
                this.hasMedicalCertificateModule &&
                item.roleType === 'Medical' &&
                item.medicalCertificateStatus &&
                (item.medicalCertificateStatus === constants.declarationStatus.open || item.medicalCertificateStatus === constants.declarationStatus.inProgress)
            ).length;
        },
        submittedMedicalCertificates(){
            return this.items.filter(item => item instanceof Model && this.hasMedicalCertificateModule && item.roleType === 'Medical' && item.medicalCertificateStatus && item.medicalCertificateStatus === constants.declarationStatus.submitted).length;
        },
        rejectedMedicalCertificates(){
            return this.items.filter(item => item instanceof Model && this.hasMedicalCertificateModule && item.roleType === 'Medical' && item.medicalCertificateStatus && item.medicalCertificateStatus === constants.declarationStatus.rejected).length;
        },

        missingPhotos(){
            return this.items.filter(item => item instanceof Model && this.hasHeadshotModule && !item.headshot && (item.fileId !== 'participationAgreement') && (this.type !== 'teamKit')).length;
        },
        unsubmittedPhotos(){
            return this.items.filter(item => item instanceof Model && this.hasHeadshotModule && item.headshot && item.headshotStatus && (item.headshotStatus === constants.declarationStatus.open || item.headshotStatus === constants.declarationStatus.inProgress) && item.fileId !== 'participationAgreement' && this.type !== 'teamKit').length;
        },
        submittedPhotos(){
            return this.items.filter(item => item instanceof Model && this.hasHeadshotModule && item.headshot && item.headshotStatus && item.headshotStatus === constants.declarationStatus.submitted && item.fileId !== 'participationAgreement' && this.type !== 'teamKit').length;
        },
        rejectedPhotos(){
            return this.items.filter(item => item instanceof Model && this.hasHeadshotModule && item.headshot && item.headshotStatus && item.headshotStatus === constants.declarationStatus.rejected && item.fileId !== 'participationAgreement' && this.type !== 'teamKit').length;
        },

        missingPositions(){
            return this.items.filter(item => item instanceof Model && this.hasPositionModule && !item.position && (item.fileId !== 'participationAgreement') && (this.type !== 'teamKit')).length;
        },
        unsubmittedPositions(){
            return this.items.filter(item => item instanceof Model && this.hasPositionModule && item.position && item.position.status && (item.position.status === constants.declarationStatus.open || item.position.status === constants.declarationStatus.inProgress) && item.fileId !== 'participationAgreement' && this.type !== 'teamKit').length;
        },
        submittedPositions(){
            return this.items.filter(item => item instanceof Model && this.hasPositionModule && item.position && item.position.status && item.position.status === constants.declarationStatus.submitted && item.fileId !== 'participationAgreement' && this.type !== 'teamKit').length;
        },
        rejectedPositions(){
            return this.items.filter(item => item instanceof Model && this.hasPositionModule && item.position && item.position.status && item.position.status === constants.declarationStatus.rejected && item.fileId !== 'participationAgreement' && this.type !== 'teamKit').length;
        },

        missingTeamKits(){
            return this.items.filter(item =>
                this.isMissingKitData(item)
            ).length;
        },
        missingPrimaryKitImage(){
            return this.items.filter(item => this.isMissingKitImage(item, 'Primary')).length;
        },
        missingSecondaryKitImage(){
            return this.items.filter(item => this.isMissingKitImage(item, 'Secondary')).length;
        },
        missingPrimaryKitColours(){
            return this.items.filter(item => this.isMissingKitColours(item, 'Primary')).length;
        },
        missingSecondaryKitColours(){
            return this.items.filter(item => this.isMissingKitColours(item, 'Secondary')).length;
        },
        unsubmittedTeamKitPrimary(){
            if (this.type !== 'teamKit'){
                return false;
            }
            if (!this.items.length || (this.items.length && !this.items.some(item => item.type === 'Primary'))){
                return true;
            }
            return this.items.some(item =>
                item.type === 'Primary' && (item.status === constants.declarationStatus.open || item.status === constants.declarationStatus.inProgress)
            );
        },
        unsubmittedTeamKitSecondary(){
            if (this.type !== 'teamKit'){
                return false;
            }
            if (!this.items.length || (this.items.length && !this.items.some(item => item.type === 'Secondary'))){
                return true;
            }
            return this.items.some(item =>
                item.type === 'Secondary' && (item.status === constants.declarationStatus.open || item.status === constants.declarationStatus.inProgress)
            );
        },
        submittedTeamKitPrimary(){
            return this.items.some(item =>
                this.type === 'teamKit' && item.type === 'Primary' && item.status === constants.declarationStatus.submitted
            );
        },
        submittedTeamKitSecondary(){
            return this.items.some(item =>
                this.type === 'teamKit' && item.type === 'Secondary' && item.status === constants.declarationStatus.submitted
            );
        },
        rejectedTeamKitPrimary(){
            return this.items.some(item =>
                this.type === 'teamKit' && item.type === 'Primary' && item.status === constants.declarationStatus.rejected
            );
        },
        rejectedTeamKitSecondary(){
            return this.items.some(item =>
                this.type === 'teamKit' && item.type === 'Secondary' && item.status === constants.declarationStatus.rejected
            );
        },

        missingParticipationAgreements(){
            return this.items.filter(item =>
                item instanceof Model &&
                item.fileId === 'participationAgreement' &&
                (
                    !item.template ||
                    !item.filePath
                )
            ).length;
        },
        unsubmittedParticipationAgreement(){
            return this.items.some(item => item instanceof Model && item.fileId === 'participationAgreement' && item.status && (item.status === constants.declarationStatus.open || item.status === constants.declarationStatus.inProgress));
        },
        submittedParticipationAgreement(){
            return this.items.some(item => item instanceof Model && item.fileId === 'participationAgreement' && item.status && item.status === constants.declarationStatus.submitted);
        },
        rejectedParticipationAgreement(){
            return this.items.some(item => item instanceof Model && item.fileId === 'participationAgreement' && item.status && item.status === constants.declarationStatus.rejected);
        },

        unsubmittedTeamLogo(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamLogo' && item.status && (item.status === constants.declarationStatus.open || item.status === constants.declarationStatus.inProgress));
        },
        submittedTeamLogo(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamLogo' && item.status && item.status === constants.declarationStatus.submitted);
        },
        rejectedTeamLogo(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamLogo' && item.status && item.status === constants.declarationStatus.rejected);
        },

        unsubmittedNationalAnthem(){
            return this.items.some(item => item instanceof Model && item.fileId === 'nationalAnthem' && item.status && (item.status === constants.declarationStatus.open || item.status === constants.declarationStatus.inProgress));
        },
        submittedNationalAnthem(){
            return this.items.some(item => item instanceof Model && item.fileId === 'nationalAnthem' && item.status && item.status === constants.declarationStatus.submitted);
        },
        rejectedNationalAnthem(){
            return this.items.some(item => item instanceof Model && item.fileId === 'nationalAnthem' && item.status && item.status === constants.declarationStatus.rejected);
        },

        unsubmittedMedicalInsuranceCover(){
            return this.items.some(item => item instanceof Model && item.fileId === 'medicalInsuranceCover' && item.status && (item.status === constants.declarationStatus.open || item.status === constants.declarationStatus.inProgress));
        },
        submittedMedicalInsuranceCover(){
            return this.items.some(item => item instanceof Model && item.fileId === 'medicalInsuranceCover' && item.status && item.status === constants.declarationStatus.submitted);
        },
        rejectedMedicalInsuranceCover(){
            return this.items.some(item => item instanceof Model && item.fileId === 'medicalInsuranceCover' && item.status && item.status === constants.declarationStatus.rejected);
        },

        unsubmittedTeamDisciplinaryCertificate(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamDisciplinaryCertificate' && item.status && (item.status === constants.declarationStatus.open || item.status === constants.declarationStatus.inProgress));
        },
        submittedTeamDisciplinaryCertificate(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamDisciplinaryCertificate' && item.status && item.status === constants.declarationStatus.submitted);
        },
        rejectedTeamDisciplinaryCertificate(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamDisciplinaryCertificate' && item.status && item.status === constants.declarationStatus.rejected);
        },

        unsubmittedTeamMemberAcceptanceForm(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamMemberAcceptanceForm' && item.status && (item.status === constants.declarationStatus.open || item.status === constants.declarationStatus.inProgress));
        },
        submittedTeamMemberAcceptanceForm(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamMemberAcceptanceForm' && item.status && item.status === constants.declarationStatus.submitted);
        },
        rejectedTeamMemberAcceptanceForm(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamMemberAcceptanceForm' && item.status && item.status === constants.declarationStatus.rejected);
        },

        unsubmittedTeamPhoneticGuide(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamPhoneticGuide' && item.status && (item.status === constants.declarationStatus.open || item.status === constants.declarationStatus.inProgress));
        },
        submittedTeamPhoneticGuide(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamPhoneticGuide' && item.status && item.status === constants.declarationStatus.submitted);
        },
        rejectedTeamPhoneticGuide(){
            return this.items.some(item => item instanceof Model && item.fileId === 'teamPhoneticGuide' && item.status && item.status === constants.declarationStatus.rejected);
        },

        hasBiographyModule(){
            return (
                this.declaration &&
                (this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadBio) ||
                this.declaration.existsInConfig(this.declaration.configTargets.squadBio))
            );
        },
        hasHeadshotModule(){
            return (
                this.declaration &&
                (this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadPersonHeadshots) ||
                this.declaration.existsInConfig(this.declaration.configTargets.squadPersonHeadshots))
            );
        },
        hasMedicalCertificateModule(){
            return (
                this.declaration &&
                (this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadMedicalCertificates) ||
                this.declaration.existsInConfig(this.declaration.configTargets.squadMedicalCertificates))
            );
        },
        hasPassportModule(){
            return (
                this.declaration &&
                (this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadPassportInformation) ||
                this.declaration.existsInConfig(this.declaration.configTargets.squadPassportInformation))
            );
        },
        hasPositionModule(){
            return (
                this.declaration &&
                (this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquadPersonPosition) ||
                this.declaration.existsInConfig(this.declaration.configTargets.squadPersonPosition))
            );
        },
        minimumTotalNumberOfManagement(){
            let minimumTotalNumberOfManagement = null;

            if(this.declaration && this.declaration.existsInConfig(this.declaration.configTargets.squad)){
                minimumTotalNumberOfManagement = Number(this.declaration.findInConfiguration(this.declaration.configTargets.squad + '.minimum-total-number-of-management')) || null;
            }
            else if(this.declaration && this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquad))
            {
                minimumTotalNumberOfManagement = Number(this.declaration.findInConfiguration(this.declaration.configTargets.preliminarySquad + '.minimum-total-number-of-management')) || null;
            }

            return minimumTotalNumberOfManagement;
        },
        isBelowMinTotalNumberOfManagement(){
            if (!this.minimumTotalNumberOfManagement || (this.type !== 'manager' && this.type !== 'medic')){
                return false;
            }

            return this.totalManagement < this.minimumTotalNumberOfManagement;
        },
        maximumTotalNumberOfManagement(){
            let maximumTotalNumberOfManagement = null;

            if(this.declaration && this.declaration.existsInConfig(this.declaration.configTargets.squad)){
                maximumTotalNumberOfManagement = Number(this.declaration.findInConfiguration(this.declaration.configTargets.squad + '.maximum-total-number-of-management')) || null;
            }
            else if(this.declaration && this.declaration.existsInConfig(this.declaration.configTargets.preliminarySquad))
            {
                maximumTotalNumberOfManagement = Number(this.declaration.findInConfiguration(this.declaration.configTargets.preliminarySquad + '.maximum-total-number-of-management')) || null;
            }

            return maximumTotalNumberOfManagement;
        },
        isAboveMaxTotalNumberOfManagement(){
            if (!this.maximumTotalNumberOfManagement || (this.type !== 'manager' && this.type !== 'medic')){
                return false;
            }

            return this.totalManagement > this.maximumTotalNumberOfManagement;
        },
    },
    methods: {
        passportIsCloseToExpiring(passportExpiryDate) {
            const difference = new Date(passportExpiryDate) - new Date();
            const differenceRound = Math.round(difference / (1000 * 60 * 60 * 24));

            return (differenceRound < 365);
        },
        isSubmitAllowed() {
            if(this.aboveMax === true) return false;
            if(this.belowMin === true) return false;
            if(this.missingBiographies > 0) return false;
            if(this.missingBiographyData > 0) return false;
            if(this.missingMedicalCertificates > 0) return false;
            if(this.missingMedicalCertificatesData > 0) return false;
            if(this.missingParticipationAgreements > 0) return false;
            if(this.missingPassports > 0) return false;
            if(this.missingPassportsData > 0) return false;
            if(this.missingPhotos > 0) return false;
            if(this.missingPositions > 0) return false;
            if(this.missingTeamKits > 0) return false;
            if(this.rejectedBiographies > 0) return false;
            if(this.rejectedMedicalCertificates > 0) return false;
            if(this.rejectedPassports > 0) return false;

            return true;
        },
        isMissingPassportData(item){
            if(item instanceof Model && this.hasPassportModule && this.type !== 'teamKit' && this.fileId !== 'participationAgreement' &&
            (
                typeof item.firstName === 'undefined' ||
                typeof item.lastName === 'undefined' ||
                typeof item.nationalityCountryIsoCode === 'undefined' ||
                typeof item.passportNo === 'undefined' ||
                typeof item.passportIssueDate === 'undefined' ||
                typeof item.passportExpiryDate === 'undefined' ||
                typeof item.passportStorage === 'undefined' ||
                typeof item.passportUploadDate === 'undefined'
            )){
                return true;
            }

            return false;
        },
        isMissingKitData(item){
            if(item instanceof Model && this.type === 'teamKit' &&
            (
                !item.imageStorage ||
                !item.jerseyColor ||
                !item.primarySocksColor ||
                !item.secondarySocksColor ||
                !item.shortsColor
            )){
                return true;
            }

            return false;
        },
        isMissingKitImage(item, type){
            return (
                this.type === 'teamKit' &&
                item instanceof Model &&
                item.type === type &&
                !item.imageStorage
            );
        },
        isMissingKitColours(item, type){
            return (
                this.type === 'teamKit' &&
                item instanceof Model &&
                item.type === type &&
                (
                    !item.jerseyColor ||
                    !item.primarySocksColor ||
                    !item.secondarySocksColor ||
                    !item.shortsColor
                )
            );
        },
    },
}
</script>
