<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('permissionGroups')"
            v-on:submit="createPermissionGroup"
            mainTitle
            isForm
            doubleButtons
            noShadow
            noLine
            noPadding
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toPermissionGroupList"
                    v-bind:loadingWhile="apiData.permissionGroupCreation"
                    v-if="permissionGroup"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.permissionGroupCreation"
                    v-if="permissionGroup"
                >
                    {{ $t('createPermissionGroup') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <wrapper
                    v-bind:waitFor="apiData.services"
                    v-bind:waitForRetry="reloadServices"
                >
                    <createEditPermissionGroupForm
                        v-bind:model="permissionGroup"
                        v-bind:errors="error ? error.getErrorIds() : undefined"
                    />
                </wrapper>
                <div
                    class="permissionSummary py-3"
                    v-if="permissionGroup"
                >
                    <ul class="validations d-flex flex-column flex-lg-row flex-wrap list-unstyled m-0 p-0 mb-3">
                        <li v-for="(item, key) in summary" v-bind:key="key">
                            <span v-if="item.permission">
                                <icon type="success"/>
                                {{ $t('permissionGrantedTo', { name: item.service }) }}
                            </span>
                            <span v-else>
                                <icon type="danger"/>
                                {{ $t('noPermissionGrantedTo', { name: item.service }) }}
                            </span>
                        </li>
                    </ul>
                </div>
                <wrapper
                    v-bind:waitFor="apiData.services"
                    v-bind:waitForRetry="reloadServices"
                    v-if="permissionGroup"
                >
                    <list
                        v-for="(apiService, key) in permissionGroup.services"
                        v-bind:key="key"
                        v-bind:items="apiService.resources"
                        v-bind:title="apiService.id"
                    >
                        <template v-slot:header>
                            <apiPermissionsRow
                                isHeadline
                            />
                        </template>
                        <template v-slot="{ item: apiResource, role }">
                            <apiPermissionsRow
                                v-bind:apiResource="apiResource"
                                v-bind:role="role"
                                v-on:update="updateSummary"
                            />
                        </template>
                    </list>
                </wrapper>
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import icon from '@/components/icon.vue';
import apiPermissionsRow from '@/components/rows/administration/apipermissions.vue';
import createEditPermissionGroupForm from '@/components/forms/createEditPermissionGroup.vue';

import PermissionGroupModel from '@/models/permissiongroup.js';

export default {
    name: 'administrationPermissionGroupCreate',
    extends: base,
    components: {
        validations,
        icon,
        apiPermissionsRow,
        createEditPermissionGroupForm,
    },
    data(){
        return {
            apiData: {
                services: this.loadServices(),
                permissionGroupCreation: null,
            },
            permissionGroup: null,
            error: null,
            summary: {},
        };
    },
    computed: {
        toPermissionGroupList(){
            return { name: 'administrationPermissionGroupIndex' };
        },
    },
    methods: {
        loadServices(){
            return this.$api.call.admin.services(false).then(data => {
                if (data !== undefined) {
                    this.permissionGroup = new PermissionGroupModel(data);
                    this.updateSummary();
                }
            });
        },
        reloadServices(){
            this.apiData.services = this.loadServices();
        },
        updateSummary(){
            this.permissionGroup.services.forEach((apiService, index) => {
                const item = {
                    service: apiService.id,
                    permission: false
                };

                apiService.getResourceMethods().forEach(apiResourceMethod => {
                    if (apiResourceMethod.isAuthorised) {
                        item.permission = true;
                    }
                });

                this.$set(this.summary, apiService.id, item);
            });
        },
        createPermissionGroup(){
            this.apiData.permissionGroupCreation = this.$api.call.admin.userRoleCreate(this.permissionGroup.toJSON()).then(result => {
                this.error = null;
                this.$router.push(this.toPermissionGroupList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('permissionGroup creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
