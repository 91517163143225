<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editSeriesType')"
            v-bind:waitFor="apiData.seriesType"
            v-bind:waitForRetry="reloadSeriesType"
            v-on:submit="updateSeriesType"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toSeriesTypeList"
                    v-bind:loadingWhile="apiData.seriesTypeUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.seriesTypeUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <editSeriesTypeForm
                    v-bind:headline="$t('seriesTypeInformation')"
                    v-bind:model="seriesType"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import editSeriesTypeForm from '@/components/forms/editSeriesType.vue';

import SeriesTypeModel from '@/models/seriesType.js';

export default {
    name: 'administrationSeriesTypesEdit',
    extends: base,
    components: {
        validations,
        editSeriesTypeForm,
    },
    props: {
        dataSeriesTypeId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                seriesType: this.loadSeriesType(),
                seriesTypeUpdate: null,
            },
            seriesType: null,
            error: null,
        };
    },
    computed: {
        toSeriesTypeList(){
            return { name: 'administrationSeriesTypesIndex' };
        },
    },
    methods: {
        loadSeriesType(){
            return this.$api.call.tournament.seriesTypeGetById(this.dataSeriesTypeId).then(data => {
                this.seriesType = SeriesTypeModel.from(data);
            });
        },
        reloadSeriesType(){
            this.apiData.seriesType = this.loadSeriesType();
        },
        updateSeriesType() {
            this.apiData.seriesTypeUpdate = this.$api.call.tournament.seriesTypeUpdateById(this.dataSeriesTypeId, this.seriesType.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toSeriesTypeList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('seriesType update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
