<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'changePublishStatusForm',
    extends: formComponent,
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'publishStatus',
                        type: 'vselectPublishStatus',
                        label: null,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
