<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('teamName', 2)" v-bind:title-tag="titleTag">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamName')">{{ getHeadline('teamName', $t('teamName')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ rankingUnion.teamName }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('country', 12, 6, 1)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('country')">{{ getHeadline('country', $t('country')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ rankingUnion.countryIsoCode }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('startDate', 12, 6, 1)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('startDate')">{{ getHeadline('startDate', $t('startDate')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ $d(rankingUnion.startDate, 'date') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('endDate', 12, 6, 1)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('endDate')">{{ getHeadline('endDate', $t('endDate')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ $d(rankingUnion.endDate, 'date') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('startingPoints', 12, 6, 1)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('startingPoints')">{{ getHeadline('startingPoints', $t('startingPoints')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ rankingUnion.startingPoints }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('startingMatchesPlayed', 12, 6, 1)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('startingMatchesPlayed')">{{ getHeadline('startingMatchesPlayed', $t('startingMatchesPlayed')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ rankingUnion.startingMatchesPlayed }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('startingPosition', 12, 6, 1)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('startingPosition')">{{ getHeadline('startingPosition', $t('startingPosition')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ rankingUnion.startingPosition }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="editLink"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from '../base.vue';

import RankingUnionModel from '@/models/rankingUnion.js';

/**
 * rankingUnion block component
 */
export default {
    name: 'rankingUnionRow',
    extends: base,
    props: {
        rankingUnion: {
            type: RankingUnionModel,
            default(){
                return new RankingUnionModel();
            }
        },
        showEdit: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        editLink(){
            return {
                name: this.editLinkRoute || 'administrationRankingCalculationsRankingUnionEdit',
                params: Object.assign({
                    dataRankingUnionId: this.rankingUnion.id,
                }, this.editLinkParams),
            };
        },
        emitEventData(){
            return {
                rankingUnion: this.rankingUnion || null,
            };
        },
        hasVisibleCircles(){
            return (this.showEdit);
        },
    },
};
</script>
