<script>
import formComponent from '@/components/form.vue';
import TournamentTeamModel from '@/models/tournamentTeam.js';

/**
 * component
 */
export default {
    name: 'editTournamentTeamForm',
    extends: formComponent,
    props: {
        model: {
            type: TournamentTeamModel,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'team',
                        type: 'pickTeam',
                        label: this.$t('tournamentTeam'),
                        plaintext: true,
                        disabled: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                teamAliasEqId: 'team',
            }
        },
    },
};
</script>
