import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class PositionService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/position-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // position

    /**
     *
     */
    positionGetById(id){
        return this.get(['position', id]);
    }

    /**
     *
     */
    positionUpdateById(id, data){
        return this.put(['position', id], data);
    }

    /**
     *
     */
    positionSearch(query){
        return this.post(['position', 'search', query]);
    }

    /**
     *
     */
    positionCreate(data){
        return this.post('position', data);
    }

    /**
     *
     */
    positionDeleteById(id){
        return this.delete(['position', id]);
    }

    // OfficialPosition

    /**
     *
     */
    officialPositionGetById(id){
        return this.get(['official-position', id]);
    }

    /**
     *
     */
    officialPositionUpdateById(id, data){
        return this.put(['official-position', id], data);
    }

    /**
     *
     */
    officialPositionSearch(query){
        return this.post(['official-position', 'search', query]);
    }

    /**
     *
     */
    officialPositionCreate(data){
        return this.post('official-position', data);
    }

    /**
     *
     */
    officialPositionDeleteById(id){
        return this.delete(['official-position', id]);
    }

    // ManagementPosition

    /**
     *
     */
    managementPositionGetById(id){
        return this.get(['management-position', id]);
    }

    /**
     *
     */
    managementPositionUpdateById(id, data){
        return this.put(['management-position', id], data);
    }

    /**
     *
     */
    managementPositionSearch(query){
        return this.post(['management-position', 'search', query]);
    }

    /**
     *
     */
    managementPositionCreate(data){
        return this.post('management-position', data);
    }

    /**
     *
     */
    managementPositionDeleteById(id){
        return this.delete(['management-position', id]);
    }
}
