import constants from '../constants';
import { CriticalError } from '@/errors.js';
import routerTools from '@/router/tools.js';

export const initRouterGuards = (router, store, auth, log) => {
    /**
     * vue router auth and feature permission guard
     */
    router.beforeEach((to, from, next) => {
        //error and logout page ignores any and all further checks
        if([constants.routeNames.error, constants.routeNames.logout].includes(to.name)){
            return next();
        }

        //if error param from SSO, redirect to error page
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('error')){
            //hard redirect to error page to get rid of error param
            window.location.replace(location.pathname + router.resolve({
                name: constants.routeNames.error,
                params: {
                    redirectPath: (to.path !== '/' ? to.path.substr(1).split('/') : null),
                },
                query: Object.assign({}, to.query, {
                    error: urlParams.get('error'),
                    errorDesc: urlParams.get('error_description'),
                }),
            }).href);

            return;
        }

        //make sure user is authorized
        auth.authorize().then(user => {
            //get route permissions
            const routePermissions = routerTools.getRoutePermissions(to);
            const isAllowed = (!routePermissions || user.areAllowed(routePermissions.getWithChildren()));

            if(log){
                log.info('opening', to.path, 'requiring permissions', routePermissions ? routePermissions.toJSON() : null, ':', (isAllowed ? 'allowed' : 'denied'));
            }

            //check if user has access
            if(isAllowed){
                next();
            }
            else {
                //redirect to home page if access denied
                next({
                    name: constants.routeNames.home,
                });
            }
        }).catch(err => {
            //on critical errors, redirect to error page
            if(err instanceof CriticalError){
                next({
                    name: constants.routeNames.error,
                    params: {
                        redirectPath: (to.path !== '/' ? to.path.substr(1).split('/') : null),
                    },
                    query: to.query,
                });
            }
        });
    });
};
