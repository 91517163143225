import Model from '@/models/model.js';

/**
 * Api resource method model class
 */
export default class ApiResourceMethodModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * http method
     *
     * @type {string}
     */
    httpMethod = '';

    /**
     * api resources
     *
     * @type {boolean}
     */
    isAuthorised = false;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     * @param {boolean} [isAuthorised = false]
     */
    constructor(data = {}, isAuthorised = false){
        super(data);

        if(data){
            this.id = data.id || data.apiResourceMethodId || 0;
            this.apiResourceMethodId = data.apiResourceMethodId;
            this.displayName = data.displayName || '';
            this.name = data.name || data.methodName || '';

            this.resource = data.resource || null;

            this.httpMethod = data.httpMethod || '';
            this.isAuthorised = isAuthorised || data.isAuthorised || false;
        }
    }

    setResource(resource){
        this.resource = resource;

        this.setMethodId(this.resource.apiResourceId);

        return this;
    }

    setMethodId(resourceId = '', serviceId = ''){
        const seperator = '-';
        this.id = serviceId + seperator + resourceId + seperator + this.apiResourceMethodId;
    }

    /**
     * get api compatible object json
     *
     * @returns {object}
     */
    toJSON(){
        if(!this.isAuthorised){
            return null;
        }

        return {
            apiResourceMethodId: this.apiResourceMethodId,
            httpMethod: this.httpMethod,
            methodName: this.name,
            isAuthorised: this.isAuthorised,
            displayName: this.displayName,
        };
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return ((this.resource && this.resource.name) ? this.resource.name + ' - ' : '') + this.name;
    }
}
