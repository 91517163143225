<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('createTournamentType')"
            v-on:submit="createTournamentType"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toTournamentTypeList"
                    v-bind:loadingWhile="apiData.tournamentTypeCreation"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.tournamentTypeCreation"
                >
                    {{ $t('createTournamentType') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createTournamentTypeForm
                    v-bind:headline="$t('tournamentTypeInformation')"
                    v-bind:model="tournamentType"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import createTournamentTypeForm from '@/components/forms/createTournamentType.vue';

import TournamentTypeModel from '@/models/tournamentType.js';

export default {
    name: 'administrationTournamentTypeCreate',
    extends: base,
    components: {
        validations,
        createTournamentTypeForm,
    },
    data(){
        return {
            apiData: {
                tournamentTypeCreation: null,
            },
            tournamentType: TournamentTypeModel.from({}),
            error: null,
        };
    },
    computed: {
        toTournamentTypeList(){
            return { name: 'administrationTournamentTypeIndex' };
        },
    },
    methods: {
        createTournamentType(){
            this.apiData.tournamentTypeCreation = this.$api.call.tournament.tournamentTypeCreate(this.tournamentType.toJSON()).then(response => {
                this.error = null;
                this.goToTournamentTypeDetail(response.id);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('tournamentType creation failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
        goToTournamentTypeDetail(id){
            this.$router.push({ name: 'administrationTournamentTypeDetail', params: { dataTournamentTypeId: id } });
        },
    },
}
</script>
