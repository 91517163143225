<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-bind:okTitle="okButtonText"
        v-bind:cancelTitle="cancelButtonText"
        v-bind:confirmHandler="confirmHandler"
        v-bind:confirmHandlerData="data"
    >
        <p class="my-4">
            {{ text }}
        </p>
        <slot/>
    </modal>
</template>

<script>
import base from './base.vue';

export default {
    name: 'confirmModal',
    extends: base,
    props: {
        title: {
            required: false,
            default() {
                return this.$t('confirmation');
            },
        },
        /**
         * confirmation prompt text
         */
        text: {
            type: String,
            required: true,
            default: null,
        },
        /**
         * Ok button text
         */
        okButtonText: {
            type: String,
            required: false,
            default(){
                return this.$t('yes');
            },
        },
        /**
         * Cancel button text
         */
        cancelButtonText: {
            type: String,
            required: false,
            default(){
                return this.$t('cancel');
            },
        },
        /**
         * confirm handler data
         */
        data: {
            default: null,
        },
    },
};
</script>
