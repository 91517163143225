<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 1, null, null, true, { hasImg: showImg, noName: !showName, })" class="card-img-wrapper" v-if="showImg">
            <b-card-img
                v-bind="getColumnProps('image')"
                v-bind:src="matchTournament.getImgPath()"
                v-bind:alt="matchTournament.name"
                v-bind:width="imgWidth"
                v-bind:height="imgHeight"
            />
        </div>

        <b-card-title v-bind="getColumnProps('name', 2)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{ getHeadline('name', $t('tournamentName')) }}</label>
            <span v-else>{{ matchTournament.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 1)" v-if="showSport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $tc('sport', 1)) }}</label>
            <span v-if="!isHeadline">{{ matchTournament.sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('groupName', 12, 6, 1)" v-if="showGroupName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('groupName')">{{ getHeadline('groupName', $t('group')) }}</label>
            <span v-if="!isHeadline">{{ matchTournament.groupName }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('matchNumber', 12, 6, 1)" v-if="showMatchNumber">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('matchNumber')">{{ getHeadline('matchNumber', $t('matchNumber')) }}</label>
            <span v-if="!isHeadline">{{ matchTournament.matchNumber }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('startDate', 12, 6, 2)" v-if="showStartDate">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('startDate')">{{ getHeadline('startDate', $t('startDate')) }}</label>
            <span v-if="!isHeadline">{{ $fd(matchTournament.startDate, 'dateLong') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('endDate', 12, 6, 2)" v-if="showEndDate">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('endDate')">{{ getHeadline('endDate', $t('endDate')) }}</label>
            <span v-if="!isHeadline">{{ $fd(matchTournament.endDate, 'dateLong') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('dateRange', 12, 6, 2)" v-if="showDateRange">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateRange')">{{ getHeadline('dateRange', $t('tournamentDates')) }}</label>
            <span v-if="!isHeadline">{{ $fd(matchTournament.startDate) }} - {{ $fd(matchTournament.endDate) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('select', 3, null, null, true)" class="align-self-start align-self-lg-center text-right" v-if="showSelect">
            <b-button
                variant="outline-primary"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('select', $event)"
            >
                {{ $t('select') }}
            </b-button>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 4, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <b-button
                variant="icon"
                v-bind="buttonAttributes"
                v-b-modal="modalName('modal-editTournamentAssignment', matchTournament.id)"
                v-if="showEditTournamentAssignment"
            >
                <icon type="edit-circle-full" large/>
            </b-button>
            <b-button
                variant="icon"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('addTournament', $event)"
                v-if="showAdd"
            >
                <icon type="add" large/>
            </b-button>
            <b-button
                variant="icon"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('remove', $event)"
                v-b-modal="modalName('confirmDelete', matchTournament.id)"
                v-if="showRemove"
            >
                <icon type="cancel-circle-full" large/>
            </b-button>
            <b-button
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-on:click="emitEvent('move', $event)"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </b-button>
        </b-card-text>

        <div v-bind="getColumnProps(null, 5)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', matchTournament.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: matchTournament.name })"
            v-bind:data="matchTournament"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />

        <assignMatchToTournamentModal
            v-bind:id="modalName('modal-editTournamentAssignment', matchTournament.id)"
            v-bind:title="$t('editTournamentAssignment')"
            v-bind:matchTournamentToEdit="matchTournament"
            v-bind:confirmHandler="editTournamentAssignmentHandler"
        />
    </b-card>
</template>

<script>
import base from './base.vue';
import MatchTournamentModel from '@/models/matchTournament.js';
import icon from '../icon.vue';
import confirmModal from '@/components/modals/confirm.vue';
import assignMatchToTournamentModal from '@/components/modals/assignMatchToTournament.vue';

/**
 * match tournament block component
 */
export default {
    name: 'matchTournamentRow',
    extends: base,
    components: {
        icon,
        confirmModal,
        assignMatchToTournamentModal,
    },
    props: {
        matchTournament: {
            type: MatchTournamentModel,
            default(){
                return new MatchTournamentModel();
            }
        },

        showImg: {
            type: Boolean,
            default: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showStartDate: {
            type: Boolean,
            default: false,
        },
        showEndDate: {
            type: Boolean,
            default: false,
        },
        showDateRange: {
            type: Boolean,
            default: false,
        },
        showMatchNumber: {
            type: Boolean,
            default: false,
        },
        showGroupName: {
            type: Boolean,
            default: false,
        },
        showSport: {
            type: Boolean,
            default: false,
        },
        showSelect: {
            type: Boolean,
            default: false,
        },

        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showAdd: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with remove icon
         * clicking it will trigger "remove" event
         */
        showRemove: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with arrow icon
         * clicking it will trigger "move" event
         */
        showDetail: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },

        removeHandler: {
            type: Function,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showEditTournamentAssignment: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        editTournamentAssignmentHandler(){
            this.emitEvent('editTournamentAssignment', this.matchTournament);
        }
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonTournamentDetail',
                params: Object.assign({
                    dataTournamentId: this.matchTournament.tournamentId,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                matchTournament: this.matchTournament || null,
            };
        },
        hasVisibleCircles(){
            return (this.showAdd || this.showRemove || this.showDetail);
        },
    },
};
</script>
