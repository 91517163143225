import constants from '@/constants.js';
// import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationRankingCalculationsIndex from '@/views/administration/rankings/calculations/index.vue';

import calculationRatingRoutes from './calculation/calculationRating.js';
import rankingUnionRoutes from './calculation/rankingUnion.js';

export default {
    path: 'calculation/',
    component: pageFrame,
    children: [
        {
            name: 'administrationRankingCalculationsIndex',
            path: constants.routePaths.index,
            component: administrationRankingCalculationsIndex,
            props: true,
            meta: {
                permission: permissions.rankings.calculations.list,
            },
        },

        calculationRatingRoutes,
        rankingUnionRoutes,
    ],
};
