<script>
import formComponent from '@/components/form.vue';
import VenueModel from '@/models/venue.js';

/**
 * component
 */
export default {
    name: 'editVenueForm',
    extends: formComponent,
    props: {
        model: {
            type: VenueModel,
        },
        places: {
            type: Array,
            default(){
                return [];
            },
        },
        countries: {
            type: Array,
            default(){
                return [];
            },
        },
        hideMapping: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onChange(data){
            this.formValues = data;
            this.$emit('change', this.formValues);
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'location',
                        type: 'pickLocation',
                        label: this.$t('placeOfVenue'),
                        disabled: this.readonly,
                        required: true,
                    },
                ]),

                {
                    type: 'hline',
                },
                {
                    id: 'additionalVenueInformation',
                    type: 'headline',
                    text: this.$t('additionalVenueInformation'),
                },
                this.createFormRow([
                    {
                        id: 'capacity',
                        type: 'number',
                        label: this.$t('capacity'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'seats',
                        type: 'number',
                        label: this.$t('seats'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'recordAttendance',
                        type: 'number',
                        label: this.$t('recordAttendance'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'recordDetail',
                        type: 'text',
                        label: this.$t('recordDetails'),
                        placeholder: this.$t('enterRecordDetails'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'recordGame',
                        type: 'number',
                        label: this.$t('recordGame'),
                        placeholder: this.$t('enterMatchId'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'displayScreens',
                        type: 'checkbox',
                        label: this.$t('displayScreens'),
                        text: this.$t('venueHasDisplayScreens'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'displayScreenCount',
                        type: 'number',
                        label: this.$t('numberOfScreens'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'hasLights',
                        type: 'checkbox',
                        label: this.$t('lights'),
                        text: this.$t('venueHasLights'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'hasRoof',
                        type: 'checkbox',
                        label: this.$t('roof'),
                        text: this.$t('venueHasRoof'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'built',
                        type: 'date',
                        label: this.$t('venueBuilt'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'lastDeveloped',
                        type: 'date',
                        label: this.$t('lastDeveloped'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'cost',
                        type: 'number',
                        label: this.$t('constructionCosts'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'redevelopmentCost',
                        type: 'number',
                        label: this.$t('reconstructionCosts'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'corporateBoxes',
                        type: 'checkbox',
                        label: this.$t('corporateBoxes'),
                        text: this.$t('venueHasCorporateBoxes'),
                        disabled: this.readonly,
                    },
                    {
                        id: 'corporateBoxesCount',
                        type: 'number',
                        label: this.$t('numberOfBoxes'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'stadiumWebsiteUrl',
                        type: 'text',
                        label: this.$t('venueWebsite'),
                        placeholder: this.$t('enterUrlOfWebsite'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'latitude',
                        type: 'text',
                        label: this.$t('latitude'),
                        placeholder: this.$t('enterLatitude'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'longitude',
                        type: 'text',
                        label: this.$t('longitude'),
                        placeholder: this.$t('enterLongitude'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ]),

                {
                    type: 'hline',
                    hidden: this.hideMapping,
                },
                {
                    id: 'mapping',
                    type: 'headline',
                    text: this.$t('mapping'),
                    hidden: this.hideMapping,
                },
                this.createFormRow(this.generateMappingProviderControls(), null, this.hideMapping),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                locationId: 'location',
            };
        },
    },
};
</script>
