<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editUnion')"
            v-bind:waitFor="apiData.union"
            v-bind:waitForRetry="reloadUnion"
            v-on:submit="updateUnion"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toDetail"
                    v-bind:loadingWhile="apiData.unionUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.unionUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <editUnionForm
                    v-bind:headline="$t('unionInformation')"
                    v-bind:model="union"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import validations from '@/components/validations.vue';
import editUnionForm from '@/components/forms/editUnion.vue';

import UnionModel from '@/models/union.js';

export default {
    name: 'commonUnionEdit',
    extends: base,
    components: {
        validations,
        editUnionForm,
    },
    props: {
        dataUnionId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                union: this.loadUnion(),
                unionUpdate: null,
            },
            union: null,
            error: null,
        };
    },
    computed: {
        toDetail(){
            return { name: 'commonUnionDetail', params: { dataUnionId: this.dataUnionId } };
        },
    },
    methods: {
        loadUnion(){
            return this.$api.call.union.unionGetById(this.dataUnionId).then(data => {
                this.union = UnionModel.from(data);
            });
        },
        reloadUnion(){
            this.apiData.union = this.loadUnion();
        },
        updateUnion(){
            this.apiData.unionUpdate = this.$api.call.union.unionUpdateById(this.dataUnionId, this.union.toJSON()).then(response => {
                this.error = null;
                this.$router.push(this.toDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('union update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
