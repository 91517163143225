<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
    >
        <template v-slot="{ formId, submit }">
            <list v-bind:items="teamNameHistory" v-bind:title="$t('teamAliases')" noMargin>
                <template v-slot:header>
                    <listHeader v-bind:elements="rowHeader" class="d-none d-md-block"/>
                </template>
                <template v-slot="{ item: alias }">
                    <teamAliasesForm
                        v-bind:dataId="formId"
                        v-bind:alias="alias"
                        v-bind:values="values"
                        v-bind:errors="errorIds"
                        v-on:change="setFormData"
                        v-on:submit="submit"
                    />
                </template>
            </list>
        </template>
    </modal>
</template>
<script>
import baseModal from './base.vue';
import list from '@/components/list.vue';
import listHeader from '@/components/listHeader.vue';
import teamAliasesForm from '@/components/forms/teamAliases.vue'

export default {
    name: 'teamAliasesDateChange',
    extends: baseModal,
    components: {
        list,
        listHeader,
        teamAliasesForm,
    },
    data(){
        return {
            rowHeader: [
                {
                    label: this.$t('name'),
                    cssclass: 'card-text py-sm-4 py-md-2 w-20 col-4',
                },
                {
                    label: this.$t('validFrom'),
                    cssclass: 'card-text py-sm-4 py-md-2 w-20 col-5',
                },
                {
                    label: this.$t('newValidFrom'),
                    cssclass: 'card-text py-sm-4 py-md-2 w-20 col-3',
                },
            ],
            formValues: {},
        };
    },
    props: {
        teamNameHistory: {
            type: Array,
            required: true,
            default(){
                return [];
            }
        },
        teamId: {
            type: Number,
            default(){
                return 0;
            }
        },
    },
    methods: {
        setFormData(data) {
            this.teamNameHistory.forEach(element => {
                this.formValues['name_' + element.id] = data['name_' + element.id]
                this.formValues['validFrom_' + element.id] = data['validFrom_' + element.id]
                if (this.formValues['validFrom_' + element.id]) {
                    this.formValues['validFromDisplay_' + element.id] = element.dateFrom ? this.$fd(element.dateFrom, 'date') : null
                }
                if (data['newValidFrom_' + element.id]) {
                    this.formValues['newValidFrom_' + element.id] = this.convertLocalDateToUTCIgnoringTimezone(data['newValidFrom_' + element.id])
                }
            });
        },
        convertLocalDateToUTCIgnoringTimezone(date) {
            const utcTimeStamp = Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds(),
            );
            return new Date(utcTimeStamp);
        },
    },
    computed: {
        payload(){
            return {
                aliases: this.teamNameHistory.map(element => {
                    const oldValue = this.formValues['validFrom_' + element.id];
                    const newValue = this.formValues['newValidFrom_' + element.id];
                    let validFrom = null;

                    if (oldValue) {
                        validFrom = new Date(oldValue).toISOString();
                    }

                    if (newValue !== undefined) {
                        validFrom = newValue.toISOString();
                    }

                    return {
                        teamAliasId: element.id,
                        name: element.name,
                        nameShort: element.nameShort,
                        nameAlternative: element.nameAlternative,
                        teamCode: element.teamCode,
                        teamCodeAnnual: element.teamCodeAnnual,
                        dateFrom: validFrom
                    }
                }),
            };
        },
        values() {
            const values = {};
            if (this.teamNameHistory) {
                this.teamNameHistory.forEach(element => {
                    values['name_' + element.id] = element.name
                    values['validFrom_' + element.id] = element.dateFrom ? this.$d(element.dateFrom, 'date') : null
                    values['newValidFrom_' + element.id] = null
                    values['validFromDisplay_' + element.id] = element.dateFrom ? this.$fd(element.dateFrom, 'date') : null
                });
            }

            return values;
        },
    },
}
</script>
