<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('teamName', 1)" v-bind:title-tag="titleTag" v-if="showTeamName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamName')" v-if="isHeadline">{{ getHeadline('teamName', $t('teamName')) }}</label>
            <span v-else>{{ (role.team || role || {}).name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('unionName', 12, 6, 2)" v-if="showUnionName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('unionName')">{{ getHeadline('unionName', $t('unionName')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ (role.union || role || {}).unionName }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('teamType', 12, 6, 2)" v-if="showTeamType">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamType')">{{ getHeadline('teamType', $t('teamType')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ (role.team || role || {}).teamType }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 1)" v-if="showSport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $tc('sport', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ (role.team || role || {}).sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('role', 12, 6, 1)" v-if="showRole">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('role')">{{ getHeadline('role', $tc('role', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ role.roleType }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('dateFrom', 12, 6, 1)" v-if="showDateFrom">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateFrom')">{{ getHeadline('dateFrom', $t('from')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ $fd(role.dateFrom, 'year') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('dateUntil', 12, 6, 1)" v-if="showDateUntil">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateUntil')">{{ getHeadline('dateUntil', $t('until')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ $fd(role.dateUntil, 'year') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDeleteRole', role.role)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-b-modal="'modal-editPersonRole-' + role.id"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <editPersonRoleModal
            v-bind:id="'modal-editPersonRole-' + role.id"
            v-bind:title="$t('editPersonRole')"
            v-bind:personRole="role.clone()"
            v-bind:create="false"
            v-bind:confirmHandler="updateHandler"
            v-on:confirmed="emitEvent('updated', $event)"
        />
    </b-card>
</template>

<script>
import base from './base.vue';
import editPersonRoleModal from '@/components/modals/editPersonRole.vue';

import PersonModel from '@/models/person.js';
import PersonRoleModel from '@/models/personRole.js';

/**
 * team block component
 */
export default {
    name: 'roleRow',
    extends: base,
    components: {
        editPersonRoleModal,
    },
    props: {
        role: {
            type: PersonRoleModel,
            default(){
                return new PersonRoleModel();
            }
        },
        person: {
            type: PersonModel,
            default(){
                return new PersonModel();
            }
        },
        dataPersonId: {
            type: Number,
            mandatory: true,
            default: null,
        },

        showTeamName: {
            type: Boolean,
            default: true,
        },
        showUnionName: {
            type: Boolean,
            default: true,
        },
        showTeamType: {
            type: Boolean,
            default: true,
        },
        showSport: {
            type: Boolean,
            default: true,
        },
        showRole: {
            type: Boolean,
            default: true,
        },
        showDateFrom: {
            type: Boolean,
            default: true,
        },
        showDateUntil: {
            type: Boolean,
            default: true,
        },

        showDetail: {
            type: Boolean,
            default: true,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
        showEdit: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonPersonRoleDetail',
                params: Object.assign({
                    dataPersonId: this.person.id,
                    dataPersonRoleId: this.role.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                role: this.role || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showRemove);
        },
    },
};
</script>
