import Model from '@/models/model.js';

/**
 * workflow model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class WorkflowModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.declarationWorkflowId || 0;
            this.eqId = data.eqId || '';
            this.name = data.name || '';
            this.type = data.type || '';
            this.sport = data.sport || '';
            this.templateId = data.templateId || data.declarationTemplateId || 0;
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name || this.type || '';
    }
}
