import pageFrame from '@/views/frame.vue';

import declarationMatchRoutes from './declaration/match.js';
import declarationSeriesRoutes from './declaration/series.js';
import declarationTournamentRoutes from './declaration/tournament.js';

export default {
    path: '/declaration/',
    component: pageFrame,
    children: [
        declarationMatchRoutes,
        declarationSeriesRoutes,
        declarationTournamentRoutes,
    ],
};
