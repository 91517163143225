<template>
    <div>
        <modal
            v-bind="modalProps"
            v-on="modalEvents"
            v-bind:scrollable="false"
        >
            <template v-slot="{ formId, submit }">
                <administrationUserRimsForm
                    v-bind:dataId="formId"
                    v-bind:model="userAccesses"
                    v-bind:values="values"
                    v-bind:errors="errorIds"
                    v-on:submit="submit"
                >
                    <!-- <template v-slot:filteredMatches="{ values, enabled }">
                        <list v-bind:items="getFilteredMatches(values, enabled)">
                            <template v-slot="{ item: match, role }">
                                <matchRow
                                    showScore showVenue showKickOffTime showSelect
                                    v-bind:match="match"
                                    v-bind:role="role"
                                    v-on:add="$emit('addMatch', $event)"
                                />
                            </template>
                            <template v-slot:empty>
                                <div></div>
                            </template>
                            <template v-slot:footer>
                                <simpleRow
                                    v-bind:text="$t('clickToAdd', { type: $tc('match', 2) })"
                                    showAdd
                                    v-b-modal.matchPick
                                />
                            </template>
                        </list>
                    </template> -->
                    <template v-slot:enabledMatches>
                        <list v-bind:items="enabledMatches">
                            <template v-slot="{ item: match, role }">
                                <matchRow
                                    showScore showVenue showKickOffTime showRemove
                                    v-bind:match="match"
                                    v-bind:role="role"
                                    v-on:remove="$emit('removeMatch', $event)"
                                />
                            </template>
                            <template v-slot:empty>
                                <div></div>
                            </template>
                            <template v-slot:footer>
                                <simpleRow
                                    v-bind:text="$t('clickToAdd', { type: $tc('match', 2) })"
                                    showAdd
                                    v-b-modal.matchPick
                                />
                            </template>
                        </list>
                    </template>
                </administrationUserRimsForm>
            </template>
        </modal>

        <matchPickModal
            id="matchPick"
            multiselect
            v-bind:title="$t('searchMatches')"
            v-bind:listHideItems="enabledMatches"
            v-on:select="addMatch"
        />
    </div>
</template>

<script>
import base from './base.vue';
import list from '@/components/list.vue';

import simpleRow from '@/components/rows/simple.vue';
import matchRow from '@/components/rows/match.vue';

import administrationUserRimsForm from '@/components/forms/userRims.vue';

import matchPickModal from '@/components/modals/pick/match.vue';
import UserAccessesModel from '@/models/userAccesses.js';

/**
 * user rims modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'userRimsModal',
    extends: base,
    components: {
        list,
        simpleRow,
        matchRow,
        administrationUserRimsForm,
        matchPickModal,
    },
    props: {
        userAccesses: {
            type: Object,
            default(){
                return new UserAccessesModel();
            },
        },
        values: {
            type: Object,
            default(){
                return {};
            },
        },
        enabledMatches: {
            type: Array,
            default(){
                return [];
            },
        },
        getFilteredMatches: {
            type: Function,
            required: true,
        },
        isEdit: {
            type: Boolean,
            required: false,
            default(){
                return false;
            },
        },
    },
    computed: {
        payload(){
            return this.userAccesses;
        },
    },
    methods: {
        addMatch(data){
            data.forEach(element => {
                this.enabledMatches.push(element);
                this.userAccesses.authorisedMatchEqIds = this.enabledMatches.map(item => item.eqId);
            });
        },
    },
};
</script>
