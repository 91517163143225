import Model from '@/models/model.js';

/**
 * tournament standing team model class
 */
export default class TournamentStandingTeamModel extends Model {
    /**
     * tournament team id
     *
     * @type {number}
     */
     tournamentTeamId = 0;

    /**
     * forced order
     *
     * @type {number}
     */
    forceOrder = 0;

    /**
     * points to be deducted
     *
     * @type {number}
     */
    deductPoints = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.tournamentTeamId = data.tournamentTeamId || 0;
            this.teamName = data.teamName || '';
            this.forceOrder = data.forceOrder || null;
            this.deductPoints = data.deductPoints || null;
            this.isDeleted = data.isDeleted || false;
        }
    }

    toJSON(){
        return {
            tournamentTeamId: this.tournamentTeamId || 0,
            teamName: this.teamName || '',
            forceOrder: this.forceOrder || 0,
            deductPoints: this.deductPoints || 0,
            isDeleted: this.isDeleted || false,
        };
    }
}
