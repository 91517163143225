<script>
import vselectElement from '../vselect.vue';
import ApiServiceModel from '@/models/apiService.js';

/**
 * vselect element for ApiResourceModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectApiResourceMethodElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};
            let apiResult = null;
            const requestData = () => {
                //only request once as we request all
                if(!apiResult){
                    apiResult = this.$api.call.admin.services(true)
                        .then(result => Object.entries(result.services || result.items || {}).map(([key, data]) => new ApiServiceModel(Object.assign({ id: key }, data))));
                }

                return apiResult;
            };

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return requestData().then(services => {
                    return services.map(service => service.getResourceMethods(method => {
                        return !text || method.label().toLowerCase().includes(text.toLowerCase());
                    })).reduce((arr, methods) => arr.concat(methods), []);
                });
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return requestData().then(services => {
                            return services.map(service => service.getResourceMethods(method => {
                                return initValues.includes(method.id);
                            })).reduce((arr, methods) => arr.concat(methods), []);
                        });
                }
            };

            return config;
        },
    },
};
</script>
