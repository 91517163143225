<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
    >
        <template v-slot="{ formId, submit }">
            <administrationIpWhitelistForm
                v-bind:dataId="formId"
                v-bind:values="values"
                v-bind:errors="errorIds"
                v-on:change="setFormData"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import administrationIpWhitelistForm from '@/components/forms/ipWhitelist.vue';

/**
 * api service modal component
 */
export default {
    name: 'ipWhitelistModal',
    extends: base,
    components: {
        administrationIpWhitelistForm,
    },
    data() {
        return {
            values: {
                ipType: 'ipSingle'
            },
        };
    },
    computed: {
        payload(){
            return this.values;
        },
    },
    methods: {
        setFormData(values) {
            this.values = values;
        },
    },
};
</script>
