<script>
import itemList from '@/components/itemList.vue';
import apiConsumerRow from '@/components/rows/administration/apiConsumer.vue';
import ApiConsumerModel from '@/models/apiConsumer.js';

/**
 * complex team list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'administrationApiConsumerList',
    extends: itemList,
    components: {
        apiConsumerRow,
    },
    data(){
        return {
            //overwrites
            rowElement: apiConsumerRow,
            rowItemPropName: 'apiConsumer',
            selectItemProp: 'apiConsumer',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showApiAccess: true,
                showTournamentAccess: true,
                showEdit: true,
            };
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load apis
            return this.$api.call.admin.apiUserGetAll().then(result => ({
                items: this.sortItems(
                    (result.apiUsers || result.items || []).map(data => new ApiConsumerModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.apiUsers || result.items || []).length,
            }));
        },
    },
};
</script>
