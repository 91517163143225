import Model from '@/models/model.js';
import TournamentStandingTeamModel from '@/models/tournamentStandingTeam.js';
import TournamentStandingTableModel from '@/models/tournamentStandingTable.js';

/**
 * tournament standing model class
 */
export default class TournamentStandingModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.eqId = data.eqId || data.standingEqId || data.tournamentStandingEqId || '';
            this.id = data.id || data.standingId || data.tournamentStandingId || 0;
            this.lastGenerated = this.toDate(data.lastGenerated);
            this.name = data.name || data.standingName || data.tournamentStandingName || '';
            this.stageId = data.stageId || data.tournamentStageId || null;
            this.stageName = data.eventStage || data.tournamentStageName || data.tournamentStage || '';
            this.teams = data.teams ? data.teams.map(item => TournamentStandingTeamModel.from(item)) : [];
            this.table = data.table ? data.table.map(item => TournamentStandingTableModel.from(item)) : [];
            this.tournamentEqId = data.tournamentEqId || '';
            this.tournamentId = data.tournamentId || 0;
            this.tournamentStageEqId = data.tournamentStageEqId || '';
            this.tournamentStandingFullName = data.tournamentStandingFullName || '';
            this.typeEqId = data.standingTypeEqId || '';
            this.typeId = data.standingTypeId || 0;
            this.typeName = data.type || data.standingType || data.standingTypeName || '';
        }
    }

    empty(){
        this.id = 0;
        this.name = '';
        this.typeId = '';
        this.stageId = '';
        this.teams = [];
    }

    toJSON(){
        return {
            standingName: this.name,
            standingTypeId: this.typeId,
            tournamentStageId: this.stageId,
            tournamentId: this.tournamentId,
            teams: this.teams.map(team => team.toJSON()),
        };
    }
}
