import Model from '@/models/model.js';

/**
 * player summary breakdown model class
 */
export default class PlayerSummaryBreakdownModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.conversions = data.conversions || 0;
            this.drawn = data.drawn || 0;
            this.dropGoals = data.dropGoals || 0;
            this.lost = data.lost || 0;
            this.matches = data.matches || 0;
            this.missedConversions = data.missedConversions || 0;
            this.missedDropGoals = data.missedDropGoals || 0;
            this.missedPenalties = data.missedPenalties || 0;
            this.oppositionTeamCode = data.oppositionTeamCode || '';
            this.oppositionTeamEqId = data.oppositionTeamEqId || '';
            this.oppositionTeamName = data.oppositionTeamName || '';
            this.penalties = data.penalties || 0;
            this.pointsAgainst = data.pointsAgainst || 0;
            this.pointsFor = data.pointsFor || 0;
            this.pointsScored = data.pointsScored || 0;
            this.redCards = data.redCards || 0;
            this.teamCode = data.teamCode || '';
            this.teamEqId = data.teamEqId || '';
            this.teamName = data.teamName || '';
            this.tries = data.tries || 0;
            this.won = data.won || 0;
            this.yellowCards = data.yellowCards || 0;
        }
    }
}
