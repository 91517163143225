<script>
import itemList from '@/components/itemList.vue';
import SeriesDeclarationModel from '@/models/declaration/series.js';
import TeamDeclarationModel from '@/models/declaration/team.js';
import seriesDeclarationRow from '@/components/rows/seriesDeclaration.vue';
import constants from '@/constants';

import TeamModel from '@/models/team.js';

/**
 * complex series declaration list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'declarationSeriesList',
    extends: itemList,
    components: {
        seriesDeclarationRow,
    },
    props: {
        statuses: {
            type: Array,
            default: null,
        },
        deadlineDateOrder: {
            type: String,
            default: null,
        },
    },
    data(){
        return {
            //overwrites
            rowElement: seriesDeclarationRow,
            rowItemPropName: 'seriesDeclaration',
            selectItemProp: 'seriesDeclaration',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
                showDeadline: true,
                showStatus: true,
                showSport: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName'),
                },
                {
                    id: 'deadlineDate',
                    type: 'vselect',
                    placeholder: this.$t('filterByDeadlineDate'),
                    options: [
                        {
                            id: 'asc',
                            label: this.$t('ascending'),
                        },
                        {
                            id: 'desc',
                            label: this.$t('descending'),
                        },
                    ],
                },
                {
                    id: 'status',
                    type: 'vselectWorkflowStatus',
                    placeholder: this.$t('filterByStatus'),
                },
                {
                    id: 'series',
                    type: 'vselectSeries',
                    placeholder: this.$tc('series', 1),
                    valueProperty: 'eqId',
                },
                {
                    id: 'sport',
                    type: 'vselectSport',
                    placeholder: this.$tc('sport', 1),
                    filter: Object.values(constants.sevenSport),
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load series declarations
            return this.$api.call.termsOfParticipation.seriesDeclarationSearch({
                seriesEqId: filters.series || null,
                seriesName: filters.name || null,
                sport: filters.sport || null,
                statuses: this.statuses || (filters.status ? [filters.status] : null),
                deadlineDate: filters.deadlineDate || null,
                deadlineDateOrder: this.deadlineDateOrder || undefined,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => {
                const declarations = (result.declarations || result.items || []);

                return Promise.resolve(declarations.map(declarationData => {
                    if (!declarationData.teams.length) {
                        const declaration = new SeriesDeclarationModel(declarationData);

                        const newTeamDeclaration = new TeamDeclarationModel();
                        const newTeam = new TeamModel();

                        newTeam.name = this.$t('noTeam');
                        newTeamDeclaration.id = 0;
                        newTeamDeclaration.team = newTeam;
                        declaration.setTeamDeclaration(newTeamDeclaration);

                        return [declaration];
                    }

                    return (declarationData.teams || []).map(teamData => {
                        const declaration = new SeriesDeclarationModel(declarationData);
                        declaration.setTeamDeclaration(new TeamDeclarationModel(teamData));

                        return declaration;
                    });
                })).then(results => {
                    result.declarations = results.reduce((arr, data) => arr.concat(data.filter(content => content)), []);
                    return result;
                });
            }).then(result => ({
                items: this.sortItems(
                    result.declarations || [],
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
    },
};
</script>
