<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editMatch')"
            v-bind:waitFor="apiData.match"
            v-bind:waitForRetry="reloadMatch"
            v-on:submit="updateMatch"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toMatchDetail"
                    v-bind:loadingWhile="apiData.matchUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.matchUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error || declarationWarning || mappingWarning">
                <validations
                    v-bind:warnings="((declarationWarning || mappingWarning) ? [...(declarationWarning ? [declarationWarning] : []), ...(mappingWarning ? [mappingWarning] : [])] : undefined)"
                    v-bind:errors="error ? error.getErrorMessages($t('requestError')) : undefined"
                    v-if="error || declarationWarning || mappingWarning"
                />
            </template>
            <template v-slot>
                <createEditMatchForm
                    v-bind:headline="$t('minimumMatchRequirements')"
                    v-bind:model="match"
                    v-bind:hideTournamentAssignment="true"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-on:change="toggleDeclarations"
                />
                <matchDeclarationForm
                    v-bind:headline="$t('matchDeclarationTopInformation')"
                    v-bind:model="declarationTOP"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                    v-bind:sport="match.sport"
                    v-bind:isFixed="Boolean(declaration.id)"
                    v-if="match && declaration && declarationTOP"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { entityMappingHelperMixins, entityMappingEditMixins } from '@/mixins/entityMapping.js';
import validations from '@/components/validations.vue';
import createEditMatchForm from '@/components/forms/createEditMatch.vue';
import matchDeclarationForm from '@/components/forms/matchDeclaration.vue';

import MatchModel from '@/models/match.js';
import MatchDeclarationModel from '@/models/declaration/match.js';
import WorkflowModel from '@/models/workflow.js';

export default {
    name: 'commonMatchEdit',
    extends: base,
    mixins: [
        entityMappingHelperMixins,
        entityMappingEditMixins,
    ],
    components: {
        validations,
        createEditMatchForm,
        matchDeclarationForm,
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                match: this.loadMatch(),
                matchUpdate: null,
            },
            match: null,
            declaration: null,
            declarationTOP: null,
            declarationWarning: null,
            error: null,
            showDeclarationForm: false,
            mappingWarning: null,
        };
    },
    computed: {
        toMatchDetail(){
            return { name: 'commonMatchDetail', params: { dataMatchId: this.dataMatchId } };
        },
    },
    methods: {
        loadMatch(){
            return this.$api.call.match.matchGetById(this.dataMatchId).then(matchData => {
                this.match = new MatchModel(matchData);

                //get mapping providers
                this.loadEntityMappingProviders(this.match);

                return this.$api.call.termsOfParticipation.matchDeclarationByMatchEqId(this.match.eqId).catch(error => {
                    //if none found just use empty data
                    if(error.status === 404){
                        return {};
                    }
                    throw error;
                }).then(matchDeclarationData => {
                    this.declaration = new MatchDeclarationModel(matchDeclarationData);
                    this.declarationTOP = this.declaration.getConfigurationFormModel();
                });
            });
        },
        reloadMatch(){
            this.apiData.match = this.loadMatch();
        },
        toggleDeclarations(values) {
            this.showDeclarationForm = values.applyTermsOfParticipationRegulations;
        },
        updateMatch(){
            let queue = Promise.resolve();

            //generate declaration config if enabled
            if(this.declarationTOP.enabled){
                //make sure match is set to declaration
                this.declaration.setMatch(this.match);

                //template
                if(this.declarationTOP.workflowId){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.declarationWorkflowsById(this.declarationTOP.workflowId)).then(data => {
                        const workflow = new WorkflowModel(data);

                        return this.$api.call.termsOfParticipation.declarationTemplateById(workflow.templateId).then(template => {
                            this.declaration.template = template;
                        });
                    });
                }

                //generate config from form data
                queue = queue.then(() => this.declaration.generateConfiguration(this.declarationTOP));
            }

            //update match
            queue = queue.then(() => this.$api.call.match.matchUpdateById(this.dataMatchId, this.match.toJSON()));

            //update mappings
            queue = this.manageMappings(this.match, queue);

            //submit declaration if enabled
            if(this.declarationTOP.enabled){
                const declarationErrorHandler = error => {
                    this.declarationWarning = this.$t('matchDeclarationUpdateWarning');
                    error.errorIdParser = this.declaration.errorIdParser.bind(this.declaration);

                    throw error;
                };

                //update or create declaration
                if(this.declaration.id){
                    queue = queue.then(() => this.$api.call.termsOfParticipation.matchDeclarationUpdateById(this.declaration.id, this.declaration.toJSON()).catch(declarationErrorHandler));
                }
                else {
                    queue = queue.then(() => this.$api.call.termsOfParticipation.matchDeclarationCreate(this.declaration.toJSON()).catch(declarationErrorHandler));
                }
            }

            //handle request outcome
            queue = queue.then(() => {
                this.error = null;
                this.$router.push(this.toMatchDetail);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('match update failed', error);
                }

                //show validation errors
                this.error = error;
            });

            this.apiData.matchUpdate = queue;
        },
    },
}
</script>
