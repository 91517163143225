import constants from '@/constants.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationManagementPositionsIndex from '@/views/administration/managementPositions/index.vue';

export default {
    path: 'management-position/',
    component: pageFrame,
    children: [
        {
            name: 'administrationManagementPositionsIndex',
            path: constants.routePaths.index,
            component: administrationManagementPositionsIndex,
            meta: {
                permission: permissions.managementPositions.list,
            },
        },
    ],
};
