import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationTournamentTypeGroupIndex from '@/views/administration/tournamentTypeGroup/index.vue';
import administrationTournamentTypeGroupCreate from '@/views/administration/tournamentTypeGroup/create.vue';
import administrationTournamentTypeGroupEdit from '@/views/administration/tournamentTypeGroup/edit.vue';

export default {
    path: 'tournament-type-group/',
    component: pageFrame,
    children: [
        {
            name: 'administrationTournamentTypeGroupIndex',
            path: constants.routePaths.index,
            component: administrationTournamentTypeGroupIndex,
            meta: {
                permission: permissions.tournamentTypeGroups.list,
            },
        },
        {
            name: 'administrationTournamentTypeGroupCreate',
            path: constants.routePaths.create,
            component: administrationTournamentTypeGroupCreate,
            meta: {
                permission: permissions.tournamentTypeGroups.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.tournamentTypeGroupId),
            component: pageFrame,
            children: [
                {
                    name: 'administrationTournamentTypeGroupEdit',
                    path: constants.routePaths.edit,
                    component: administrationTournamentTypeGroupEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentTypeGroupId]),
                    meta: {
                        permission: permissions.tournamentTypeGroups.update,
                    },
                },
            ],
        },
    ],
};
