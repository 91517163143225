import constants from '@/constants.js';

export default {
    propRoute(prop){
        return ':' + prop + '/';
    },

    /**
     * helper function to convert certain properties to numerical values for the page
     *
     * @param {string[]} props
     * @param {object}   route
     *
     * @returns {object}
     */
    convertNumericalProps(props, route){
        props.forEach(prop => {
            route.params[prop] = Number(route.params[prop]);
        });

        return route.params;
    },

    convertAlphanumericalProps(props, route){
        return route.params;
    },

    setProps(props = {}, route = {}){
        Object.assign(route.params, props);

        return route.params;
    },

    getRouteByName(router, name) {
        return router.resolve({ name }).route;
    },

    /**
     * get route permissions
     *
     * @param route
     *
     * @returns {PermissionModel|null}
     */
    getRoutePermissions(route){
        const routeParts = [...route.matched].reverse();
        return ((routeParts.find(route => route.meta.permission !== undefined) || {}).meta || {}).permission || null;
    },

    /**
     * create fallback redirect for router configuration
     *
     * @param {string} [redirect='/']
     * @param {string} [path='*']
     *
     * @returns {{redirect: string, path: string}}
     */
    createFallbackRedirect(redirect = '/', path = '*'){
        return {
            path: path,
            redirect: redirect,
        };
    },

    /**
     * create fallback redirect for router configuration for when there is no index path
     *
     * @param {string} [redirect='/']
     *
     * @returns {{redirect: string, path: string}}
     */
    createIndexRedirect(redirect = '/'){
        return this.createFallbackRedirect(redirect, constants.routePaths.index);
    },
};
