import Model from '@/models/model.js';

/**
 * TournamentTeamStatusType model class
 */
export default class TournamentTeamStatusTypeModel extends Model {
    /**
     * id
     *
     * @type {number}
     */
    id = 0;

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.statusId || 0;
            this.name = data.name || data.statusName || '';
        }
    }
}
