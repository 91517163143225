<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
    >
        <template v-slot="{ formId, submit }">
            <editVenueAliasForm
                v-bind:dataId="formId"
                v-bind:model="venueAlias"
                v-bind:errors="errorIds"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from '@/components/modals/base.vue';
import editVenueAliasForm from '@/components/forms/editVenueAlias.vue';

import VenueAliasModel from '@/models/venueAlias.js';

/**
 * venue alias edit modal component
 */
export default {
    name: 'editVenueAliasModal',
    extends: base,
    components: {
        editVenueAliasForm,
    },
    props: {
        venueAlias: {
            type: VenueAliasModel,
            default(){
                return new VenueAliasModel();
            }
        },
    },
    computed: {
        payload(){
            return this.venueAlias;
        },
    },
};
</script>
