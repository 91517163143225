import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class TranslationService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/translation-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // translation

    /**
     *
     */
    translationGetById(id){
        return this.get(['translation', id]);
    }

    /**
     *
     */
    translationGetByEqId(id){
        return this.get(['translation', 'eq-id/', id]);
    }

    /**
     *
     */
    translationSearch(query){
        return this.post(['translation', 'search', query]);
    }
}
