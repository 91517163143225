<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="$t('seriesTeamDetails')"
            v-bind:details="detailData"
            v-bind:waitFor="apiData.seriesTeam"
            v-bind:waitForRetry="reloadSeriesTeam"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="danger"
                    v-bind:permission="permissions.declarations.team.delete"
                    v-b-modal.confirmDeleteDeclaration
                    v-if="hasTeamDeclaration"
                >
                    {{ $t('deleteDeclaration') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:to="editSeriesTeamLink"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <list
            v-bind:title="$t('seriesTeamPlayers')"
            v-bind:items="seriesTeamPlayers"
            v-bind:waitFor="apiData.seriesTeamPersonnel"
            v-bind:waitForRetry="reloadSeriesTeamPersonnel"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:header>
                <playerRow
                    showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow
                    showDetail
                    v-bind:person="person"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:title="$t('seriesTeamManagement')"
            v-bind:items="seriesTeamManagement"
            v-bind:waitFor="apiData.seriesTeamPersonnel"
            v-bind:waitForRetry="reloadSeriesTeamPersonnel"
            v-bind:hide="hideElement(2)"
        >
            <template v-slot:header>
                <managerRow
                    showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <managerRow
                    showDetail
                    v-bind:person="person"
                    v-bind:role="role"
                />
            </template>
        </list>

        <list
            v-bind:title="$t('seriesTeamMedics')"
            v-bind:items="seriesTeamMedic"
            v-bind:waitFor="apiData.seriesTeamPersonnel"
            v-bind:waitForRetry="reloadSeriesTeamPersonnel"
            v-bind:hide="hideElement(3)"
        >
            <template v-slot:header>
                <medicRow
                    showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <medicRow
                    showDetail
                    v-bind:person="person"
                    v-bind:role="role"
                />
            </template>
        </list>

        <confirmModal
            v-bind:id="'confirmDeleteDeclaration'"
            v-bind:text="$t('pleaseConfirmDeletion', { name: $t('teamDeclaration') })"
            v-bind:data="teamDeclaration"
            v-bind:confirmHandler="deleteTeamDeclarationHandler"
            v-on:confirmed="deleteTeamDeclarationComplete"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import detailsHeader from '@/components/detailsHeader.vue';
import subNavigation from '@/components/subnavigation.vue';
import playerRow from '@/components/rows/player.vue';
import managerRow from '@/components/rows/manager.vue';
import medicRow from '@/components/rows/medic.vue';
import constants from '@/constants.js';

import SeriesTeamModel from '@/models/seriesTeam.js';
import PlayerModel from '@/models/person/player.js';
import ManagerModel from '@/models/person/manager.js';
import MedicModel from '@/models/person/medic.js';
import SeriesModel from '@/models/series.js';
import SeriesDeclarationModel from '@/models/declaration/series.js';
import { RequestError } from '@/errors.js';

import confirmModal from '@/components/modals/confirm.vue';

export default {
    name: 'commonSeriesTeamDetail',
    extends: base,
    components: {
        detailsHeader,
        subNavigation,
        playerRow,
        managerRow,
        medicRow,
        confirmModal,
    },
    props: {
        dataSeriesId: {
            type: Number,
            mandatory: true,
            default: null,
        },
        dataTeamId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            seriesTeam: this.loadSeriesTeam(),
        };

        return {
            apiData: {
                seriesTeam: this.apiData.seriesTeam,
                seriesTeamPersonnel: this.loadSeriesTeamPersonnel(),
                series: this.loadSeries(),
            },
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$t('seriesTeamPlayers'),
                    },
                    2: {
                        label: this.$t('seriesTeamManagement'),
                    },
                    3: {
                        label: this.$t('seriesTeamMedics'),
                    },
                }
            },
            seriesTeam: null,
            seriesTeamPlayers: [],
            seriesTeamManagement: [],
            seriesTeamMedic: [],
            seriesDeclaration: null,
            teamDeclaration: null,
        };
    },
    methods: {
        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },
        loadSeriesTeam(){
            return this.$api.call.tournament.seriesTeamGetById(this.dataTeamId).then(data => {
                this.seriesTeam = new SeriesTeamModel(data);

                //reload stuff
                this.reloadSeriesTeamPersonnel();
            });
        },
        reloadSeriesTeam(){
            this.apiData.seriesTeam = this.loadSeriesTeam();
        },
        loadSeriesTeamPersonnel(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.seriesTeam){
                return new Promise((resolve, reject) => this.apiData.seriesTeam.catch(reject));
            }

            this.seriesTeamPlayers = [];
            this.seriesTeamManagement = [];
            this.seriesTeamMedic = [];

            return this.$api.call.tournament.seriesTeamPersonnelBySeriesTeamId(this.dataTeamId).then(data => {
                if (data !== undefined) {
                    Object.values(data.seriesTeamPersonnel).forEach(entry => {
                        if(entry.roleName === constants.personRoleType.player){
                            this.seriesTeamPlayers.push(new PlayerModel(entry));
                        }
                        else if(entry.roleName === constants.personRoleType.management)
                        {
                            if(entry.group && entry.group === constants.group.medical){
                                this.seriesTeamMedic.push(new MedicModel(entry));
                            }
                            else
                            {
                                this.seriesTeamManagement.push(new ManagerModel(entry));
                            }
                        }
                    });
                }
            }).catch(error => {
                //ignore 404 error
                if(error instanceof RequestError && error.status === 404){
                    return;
                }
                throw error;
            });
        },
        reloadSeriesTeamPersonnel(){
            this.apiData.seriesTeamPersonnel = this.loadSeriesTeamPersonnel();
        },
        loadSeries(){
            return this.$api.call.tournament.seriesGetById(this.dataSeriesId).then(data => {
                this.series = new SeriesModel(data);

                return this.$api.call.termsOfParticipation.seriesDeclarationBySeriesEqId(this.series.eqId).catch(error => {
                    //if none found just use empty data
                    if(error.status === 404){
                        return {};
                    }
                    throw error;
                }).then(data => {
                    this.seriesDeclaration = new SeriesDeclarationModel(data);
                    this.teamDeclaration = this.seriesDeclaration?.teams?.find(seriesTeam => seriesTeam?.team?.eqId === this.seriesTeam?.team?.eqId);

                    return this.series;
                });
            });
        },
        reloadSeries(){
            this.apiData.series = this.loadSeries();
        },
        deleteTeamDeclarationHandler(data){
            return this.$api.call.termsOfParticipation.declarationTeamDeclarationDeleteById(data?.id);
        },
        deleteTeamDeclarationComplete(){
            this.teamDeclaration = null;

            this.reloadSeriesTeam();
        },
    },
    computed: {
        editSeriesTeamLink(){
            return {
                name: 'commonSeriesTeamEdit',
                params: {
                    dataSeriesId: this.dataSeriesId,
                    dataTeamId: this.dataTeamId,
                }
            };
        },
        detailData(){
            if(!this.seriesTeam){
                return [];
            }

            return [
                {
                    label: this.$t('seriesTeam'),
                    content: this.seriesTeam.teamName + ', ' + this.seriesTeam.sport + ', ' + this.seriesTeam.countryName,
                },
                {
                    label: this.$t('coreTeam'),
                    content: this.seriesTeam.isTourMember,
                    type: 'boolean',
                },
            ];
        },
        hasTeamDeclaration(){
            return this.teamDeclaration;
        },
    },
};
</script>
