<script>
import personPickModal from '@/components/modals/pick/person.vue';
import declarationPersonList from '@/components/lists/declaration/person.vue';

/**
 * declaration person pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'declarationPersonPickModal',
    extends: personPickModal,
    components: {
        declarationPersonList,
    },
    data(){
        return {
            listElement: declarationPersonList,
        };
    },
};
</script>
