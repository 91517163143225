<script>
import personList from './person.vue';
import managerRow from '@/components/rows/manager.vue';
import ManagerModel from '@/models/person/manager.js';
import constants from '@/constants.js';

/**
 * complex manager list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'managerList',
    extends: personList,
    components: {
        managerRow,
    },
    data(){
        return {
            //overwrites
            rowElement: managerRow,
            ItemModel: ManagerModel,
            personRole: constants.personRoleType.management,
        };
    },
};
</script>
