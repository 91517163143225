import Model from '@/models/model.js';
import TeamModel from '@/models/team.js';
import PlayerModel from '@/models/person/player.js';
import ManagerModel from '@/models/person/manager.js';
import MedicModel from '@/models/person/medic.js';

/**
 * tournament team model class
 */
export default class TournamentTeamModel extends Model {
    /**
     * players
     *
     * @type {array}
     */
    players = [];

    /**
     * management
     *
     * @type {array}
     */
    managers = [];

    /**
     * medics
     *
     * @type {array}
     */
    medics = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.active = data.active || false;
            this.countryCode = data.countryCode || '';
            this.countryName = data.countryName || '';
            this.eqId = data.eqId || data.tournamentTeamEqId || '';
            this.id = data.id || data.tournamentTeamId || 0;
            this.points = data.points || 0;
            this.status = data.tournamentTeamStatus || '';
            this.teamAliasEqId = data.teamAliasEqId || '';
            this.teamEqId = data.teamEqId || '';
            this.teamCode = data.teamCode || '';
            this.teamName = data.teamName || '';
            this.teamId = data.teamId || 0;
            this.teamType = data.teamType || '';
            this.team = TeamModel.from(data.team);
            this.teamStatus = data.teamStatus || '';
            this.tournamentId = data.tournamentId || 0;

            this.unionEqId = data.unionEqId || '';
            this.unionName = data.unionName || '';

            this.logoUrl = data.logoUrl || '';

            this.players = (data.players || []).map(player => new PlayerModel(player)).sort((a, b) => a.position?.name - b.position?.name);
            this.managers = (data.managers || []).map(manager => new ManagerModel(manager)).sort(this.sortPositionObjByNameAlpha);
            this.medics = (data.medics || []).map(medic => new MedicModel(medic)).sort(this.sortPositionObjByNameAlpha);

            if (this.team) {
                this.teamName = this.team.name;
                this.teamAliasEqId = this.team.teamAliasEqId;
                this.teamEqId = this.team.eqId;
                this.teamCode = this.team.teamCode;
            }
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.teamName;
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPath(){
        return require('@/assets/img/union.svg');
    }

    toJSON() {
        return {
            tournamentId: this.tournamentId || 0,
            teamAliasEqId: (this.teamAliasEqId ? this.teamAliasEqId : (this.team ? this.team.teamAliasEqId : this.teamEqId)) || null,
        };
    }

    sortPositionObjByNameAlpha(a, b){
        const nameA = (a.position?.name || '').toLowerCase();
        const nameB = (b.position?.name || '').toLowerCase();

        if(nameA < nameB){
            return -1;
        }
        if(nameA > nameB){
            return 1;
        }
        return 0;
    }
}
