import Model from '@/models/model.js';

/**
 * declaration status model class
 */
export default class DeclarationStatusModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.status = data.status || '';
            this.deadline = this.toDate(data.deadline);
        }
    }
}
