import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class AdminService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/admin-service/api/';

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // ApiResource

    /**
     *
     */
    services(userIsExternal = undefined){
        if(userIsExternal !== undefined && userIsExternal !== null){
            return this.get('api-resource', {
                external: Boolean(userIsExternal),
            });
        }

        return this.get(['api-resource']);
    }

    // ApiUser

    /**
     *
     */
    apiUserCreate(data){
        return this.post('api-user', data);
    }

    /**
     *
     */
    apiUserUpdate(id, data){
        return this.put(['api-user', id], data);
    }

    /**
     *
     */
    apiUserGetAll(){
        return this.get(['api-user']);
    }

    /**
     *
     */
    apiUserGetById(id){
        return this.get(['api-user', id]);
    }

    /**
     *
     */
    generateApiKey() {
        return this.get(['api-user', 'generate']);
    }

    // PermissionGroup

    /**
     *
     */
    permissionGroupGetByName(id){
        return this.get(['permission-group', 'user-role', id]);
    }

    /**
     *
     */
    permissionGroupCreate(data){
        return this.post('permission-group', data);
    }

    /**
     *
     */
    permissionGroupUpdate(id, data){
        return this.put(['permission-group', 'user-role', id], data);
    }

    /**
     *
     */
    permissionGroupDelete(id){
        return this.delete(['permission-group', 'user-role', id]);
    }

    // Service

    /**
     *
     */
    //service(){
    //    return this.get('service');
    //}

    // ThirdPartyConsumer

    /**
     *
     */
    thirdPartyConsumerCreate(data){
        return this.post('third-party-consumer', data);
    }

    /**
     *
     */
    thirdPartyConsumerByName(consumerName){
        return this.get(['third-party-consumer', consumerName]);
    }

    /**
     *
     */
    thirdPartyConsumerUpdateById(id, data){
        return this.put(['third-party-consumer', id], data);
    }

    /**
     *
     */
    thirdPartyConsumers(pageNo, pageSize){
        return this.get(['third-party-consumer', 'page-no', pageNo, 'page-size', pageSize]);
    }

    // UserManagement

    userCreate(data){
        return this.post('user', data);
    }

    userSearch(data){
        return this.post(['user', 'search'], data);
    }

    /**
     *
     */
    userUpdateById(id, data){
        return this.put(['user', id], data);
    }

    /**
     *
     */
    userGetById(id){
        return this.get(['user', id]);
    }

    /**
     *
     */
    getUserByUserId(id){
        return this.get(['user', id]);
    }

    /**
     *
     */
    userByExternalId(id){
        return this.get(['user', 'external', id]);
    }

    userCurrent(){
        return this.get(['user', 'current']);
    }

    /**
     *
     */
    userAccessCreate(id, data){
        return this.post(['user', id, 'accesses'], data);
    }

    /**
     *
     */
    userAccessUpdate(userId, userAccessId, data){
        return this.put(['user', userId, 'accesses', userAccessId], data);
    }

    /**
     *
     */
    userAccessDelete(userId, accessId){
        return this.delete(['user', userId, 'accesses', accessId]);
    }

    // UserRoles

    /**
     *
     */
    userRoles(){
        return this.get(['user-role']);
    }

    /**
     *
     */
    userRoleById(id){
        return this.get(['user-role', id]);
    }

    /**
     *
     */
    userRoleCreate(data){
        return this.post('user-role', data);
    }

    /**
     *
     */
    userRoleUpdateById(id, data){
        return this.put(['user-role', id], data);
    }

    /**
     *
     */
    userRoleDeleteById(id){
        return this.delete(['user-role', id]);
    }

    // subscriptionType

    /**
     *
     */
    subscriptionTypesGetAll(){
        return this.get(['api-subscription-type']);
    }

    // UserStatus

    /**
     *
     */
    userStatuses(){
        return this.get(['user-status']);
    }

    /**
     *
     */
    userTypes(){
        return this.get(['user-type']);
    }

    // task

    /**
     *
     */
    personMerge(data){
        return this.post(['task', 'person-merge'], data);
    }
}
