import Model from '@/models/model.js';

/**
 * team model class
 */
export default class TeamAliasModel extends Model {
    /**
     * team id
     *
     * @type {int}
     */
    teamId = 0;

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * short name
     *
     * @type {string}
     */
    nameShort = '';

    /**
     * alternative name
     *
     * @type {string}
     */
    nameAlternative = '';

    /**
     * eqId
     *
     * @type {string}
     */
    eqId = '';

    /**
     * team code
     *
     * @type {string}
     */
    teamCode = '';

    /**
     * team code annual
     *
     * @type {string}
     */
    teamCodeAnnual = '';

    /**
     * valid from
     *
     * @type {Date}
     */
    dateFrom = null;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.teamAliasId || 0;
            this.teamId = data.teamId || 0;
            this.eqId = data.eqId || '';

            this.dateFrom = this.toDate(data.dateFrom);
            this.name = data.teamName || data.name || '';
            this.nameAlternative = data.nameAlternative || '';
            this.nameShort = data.teamNameShort || data.nameShort || '';
            this.teamCode = data.teamCode || '';
            this.teamCodeAnnual = data.teamCodeAnnual || '';
        }
    }

    /**
     * get team image path
     *
     * @returns {string}
     */
    getImgPath(){
        return require('@/assets/img/union.svg');
    }

    toJSON() {
        return {
            teamId: this.teamId,
            eqId: this.eqId,
            name: this.name,
            nameShort: this.nameShort,
            nameAlternative: this.nameAlternative,
            dateFrom: this.fromDate(this.dateFrom),
            teamCode: this.teamCode,
            teamCodeAnnual: this.teamCodeAnnual
        };
    }
}
