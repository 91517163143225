import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class PersonService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/person-service/api/';

    /**
     * base path parts
     *
     * @type {object}
     */
    _paths = {
        personSchool: 'person-school',
    };

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // person

    /**
     *
     */
    personCreate(data){
        return this.post('person', data);
    }

    /**
     *
     */
    forcePersonCreate(data){
        return this.post(['person', 'suppress-validation'], data);
    }

    /**
     *
     */
    personGetById(id){
        return this.get(['person', id]);
    }

    /**
     *
     */
    personGetByEqId(eqId){
        return this.get(['person', 'eq-id', eqId]);
    }

    /**
     *
     */
    personUpdateBodyMeasurements(id, data){
        return this.patch(['person', 'body-measurements', id], data);
    }

    /**
     *
     */
    personDeleteById(id){
        return this.delete(['person', id]);
    }

    /**
     *
     */
    personUndoDeleteById(id){
        return this.patch(['person', id, 'undo-delete']);
    }

    /**
     *
     */
    personRetirePerson(id, data){
        return this.patch(['person', 'retire', id], data);
    }

    /**
     *
     */
    personUpdateById(id, data){
        return this.put(['person', id], data);
    }

    /**
     *
     */
    forcePersonUpdateById(id, data){
        return this.put(['person', id, 'suppress-validation'], data);
    }

    /**
     *
     */
    personSearch(query){
        return this.post(['person', 'search'], query);
    }

    /**
     *
     */
    personMerge(data){
        return this.post(['person', 'merge'], data);
    }

    // PersonRole

    /**
     *
     */
    personRoleCreate(data){
        return this.post('person-role', data);
    }

    /**
     *
     */
    personRolesByPersonId(id){
        return this.get(['person', id, 'roles']);
    }

    /**
     *
     */
    personRoleGetById(id){
        return this.get(['person-role', id]);
    }

    /**
     *
     */
    personRoleUpdate(id, data){
        return this.put(['person-role', id], data);
    }

    /**
     *
     */
    personRoleDeleteById(id){
        return this.delete(['person-role', id]);
    }

    /**
     *
     */
    personRoleSearch(){
        return this.get(['role']);
    }

    // Personally identifiable info

    personIdentifiableInfoGetById(personId){
        return this.get(['person', personId, 'personally-identifiable-info']);
    }

    // Position

    /**
     *
     */
    positionGetById(id){
        return this.get(['position', id]);
    }

    /**
     *
     */
    positionGetByEqId(eqId){
        return this.get(['position', 'eq-id', eqId]);
    }

    /**
     *
     */
    positionGetBySport(sport){
        return this.get(['position', 'get-playing-positions-for-sport', sport]);
    }

    /**
     *
     */
    positionSearch(query){
        return this.post(['position', 'search'], query);
    }

    // PersonSchool

    /**
     *
     */
    personSchoolCreate(personId, schoolId){
        return this.patch(['person', personId, 'school', schoolId]);
    }

    /**
     *
     */
    personSchoolGetById(id){
        return this.get([this._paths.personSchool, id]);
    }

    /**
     *
     */
    personSchoolsByPersonId(id){
        return this.get(['person', id, 'schools']);
    }

    /**
     *
     */
    personSchoolUpdateById(id, data){
        return this.put([this._paths.personSchool, id], data);
    }

    /**
     *
     */
    personSchoolDelete(personId, schoolId){
        return this.delete([this._paths.personSchool, personId, schoolId]);
    }

    // school

    /**
     *
     */
    schoolCreate(data){
        return this.post('school', data);
    }

    /**
     *
     */
    schoolGetById(id){
        return this.get(['school', id]);
    }

    /**
     *
     */
    schoolSearch(query){
        return this.post(['school', 'search'], query);
    }

    /**
     *
     */
    schoolUpdateById(id, data){
        return this.put(['school', id], data);
    }

    /**
     *
     */
    schoolDeleteById(id){
        return this.delete(['school', id]);
    }
}
