<script>
import formComponent from '@/components/form.vue';
import constants from '@/constants';

import PersonModel from '@/models/person.js';

/**
 * component
 */
export default {
    name: 'medicalCertificateForm',
    extends: formComponent,
    props: {
        model: {
            type: PersonModel,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'medicalCertificate',
                        type: 'file',
                        label: this.readonly ? this.$t('front') : this.$t('file'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        readonly: this.readonly,
                        previewOnTop: true,
                        previewLarge: this.readonly,
                        required: this.isFileRequired,
                    },
                ]),
            ];
        },
        isFileRequired(){
            return (this.model?.medicalCertificateStorage === null || !this.model?.medicalCertificateStorage?.length);
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
