import Model from '@/models/model.js';

/**
 * person mapping model class
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default class PersonMappingModel extends Model {
    /**
     * person mapping
     *
     * @type {array}
     */
    personMapping = [];

    /**
     * person role mapping
     *
     * @type {array}
     */
    personRoleMapping = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.name = data.displayName || '';
            this.personEqId = data.personEqId || '';
            this.personMapping = data.personMapping || [];
            this.personRoleEqId = data.personRoleEqId || '';
            this.personRoleMapping = data.personRoleMapping || [];
            this.role = data.role || '';
        }
    }
}
