import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class TournamentService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/tournament-service/api/';

    /**
     * base path parts
     *
     * @type {object}
     */
    _paths = {
        seriesType: 'series-type',
        seriesTeam: 'series-team',
        tournamentStanding: 'tournament-standing',
        tournamentTeamPerson: 'tournament-team-person',
        standingType: 'standing-type',
        standingTypeParameter: 'standing-type-parameter',
        tournamentTeam: 'tournament-team',
        tournamentTeamKit: 'tournament-team-kit',
        tournamentOfficial: 'tournament-official',
    };

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // tournament

    /**
     *
     */
    tournamentCreate(data){
        return this.post('tournament', data);
    }

    /**
     *
     */
    tournamentUpdateById(id, data){
        return this.put(['tournament', id], data);
    }

    /**
     *
     */
    tournamentGetById(id){
        return this.get(['tournament', id]);
    }

    /**
     *
     */
    tournamentGetByEqId(eqId){
        return this.get(['tournament', 'eq-id', eqId]);
    }

    /**
     *
     */
    tournamentGetByTournamentTypeEqId(eqId){
        return this.get(['lookups', 'Tournament', 'byTournamentTypeEqId', eqId]);
    }

    /**
     *
     */
    tournamentDeleteById(id){
        return this.delete(['tournament', id]);
    }

    /**
     *
     */
    tournamentSearch(query){
        return this.post(['tournament', 'search'], query);
    }

    /**
     *
     */
    publishStatusTournamentUpdate(id, data){
        return this.put(['tournament', id, 'publish-status'], data);
    }

    /**
     *
     */
    tournamentTeamStatusesUpdate(id, data){
        return this.put(['tournament', id, 'team-statuses'], data);
    }

    // Series

    /**
     *
     */
    seriesCreate(data){
        return this.post('series', data);
    }

    /**
     * (params tbc)
     */
    seriesSearch(query){
        return this.post(['series', 'search'], query);
    }

    /**
     *
     */
    seriesGetById(id){
        return this.get(['series', id]);
    }

    /**
     *
     */
    seriesGetByEqId(eqId){
        return this.get(['lookups', 'Series', 'byEqId', eqId]);
    }

    /**
     *
     */
    seriesTypeGetAll(){
        return this.get(this._paths.seriesType);
    }

    /**
     *
     */
    seriesTypeGetById(id){
        return this.get([this._paths.seriesType, id]);
    }

    /**
     *
     */
    seriesUpdateById(id, data){
        return this.put(['series', id], data);
    }

    /**
     *
     */
    seriesTournamentsGetBySeriesId(id){
        return this.get(['series', id, 'tournaments']);
    }

    /**
     *
     */
    seriesAddTournaments(id, data){
        return this.patch(['series', id, 'tournaments'], data);
    }

    /**
     *
     */
    seriesTournamentDeleteBySeriesIdAndTournamentId(seriesId, tournamentId){
        return this.delete(['series', seriesId, 'tournament', tournamentId]);
    }

    /**
     *
     */
    publishStatusSeriesUpdate(id, data){
        return this.put(['series', id, 'publish-status'], data);
    }

    // SeriesStanding

    /**
     *
     */
    seriesStandingsTableBySeriesId(id){
        return this.get(['series', id, 'table']);
    }

    recalculateSeriesStanding(standingId){
        return this.post(['series', standingId, 'recalculate']);
    }

    // SeriesTeam

    /**
     *
     */
    seriesTeamGetById(id){
        return this.get([this._paths.seriesTeam, id]);
    }

    /**
     *
     */
    seriesTeamsGetBySeriesId(id){
        return this.get(['series', id, 'teams']);
    }

    /**
     *
     */
    seriesTeamGetBySeriesEqId(eqId){
        return this.get(['lookups', 'SeriesTeam', 'bySeriesEqId', eqId]);
    }

    /**
     *
     */
    seriesTeamSearch(query){
        return this.post([this._paths.seriesTeam, 'search'], query);
    }

    /**
     *
     */
    seriesTeamPersonnelBySeriesTeamId(id){
        return this.get([this._paths.seriesTeam, id, 'persons']);
    }

    /**
     *
     */
    seriesTeamCreate(seriesId, query){
        return this.post([this._paths.seriesTeam, 'series-id', seriesId], query);
    }

    /**
     *
     */
    seriesTeamUpdate(id, isTourMember){
        return this.patch([this._paths.seriesTeam, id, 'is-tour-member', isTourMember]);
    }

    /**
     *
     */
    seriesTeamsDeleteById(id){
        return this.delete([this._paths.seriesTeam, id]);
    }

    // Series Tournament

    /**
     *
     */
    seriesTournamentByTournamentEqId(tournamentEqId){
        return this.get(['lookups', 'SeriesTournament', 'byTournamentEqId', tournamentEqId]);
    }

    // Series Team Personnel

    /**
     *
     */
    seriesTeamAddPersonBySeriesTeamId(id, query){
        return this.post([this._paths.seriesTeam, id, 'personnel'], query);
    }

    /**
     *
     */
    seriesTeamAddPersonBySeriesTeamIdAndPersonEqId(id, query){
        return this.post([this._paths.seriesTeam, id, 'personnel', 'by-person'], query);
    }

    /**
     *
     */
    seriesTeamDeletePersonByPersonId(id){
        return this.delete(['series-team-personnel', id]);
    }

    // SeriesTeamEvolution

    /**
     *
     */
    seriesTeamEvolutionBySeriesEqId(EqId){
        return this.get(['series', EqId, 'team-evolution']);
    }

    // Series Type

    /**
     *
     */
    seriesTypeCreate(data){
        return this.post([this._paths.seriesType], data);
    }

    /**
     *
     */
    seriesTypeUpdateById(id, data){
        return this.put([this._paths.seriesType, id], data);
    }

    /**
     *
     */
    seriesTypeDeleteById(id){
        return this.delete([this._paths.seriesType, id]);
    }

    // TournamentTypeGroup

    /**
     *
     */
    tournamentTypeGroupCreate(data){
        return this.post('tournament-type-group', data);
    }

    /**
     *
     */
    tournamentTypeGroupGetById(id){
        return this.get(['tournament-type-group', id]);
    }

    /**
     *
     */
    tournamentTypeGroupSearch(query){
        return this.post(['tournament-type-group', 'search'], query);
    }

    /**
     *
     */
    tournamentTypeGroupUpdateById(id, data){
        return this.put(['tournament-type-group', id], data);
    }

    /**
     *
     */
    tournamentTypeGroupDeleteById(id){
        return this.delete(['tournament-type-group', id]);
    }

    // TournamentType

    /**
     *
     */
    tournamentTypeCreate(data){
        return this.post('tournament-type', data);
    }

    /**
     *
     */
    tournamentTypeGetById(id){
        return this.get(['tournament-type', id]);
    }

    /**
     *
     */
    tournamentTypeGetByEqId(eqId){
        return this.get(['lookups', 'TournamentType', 'byEqId', eqId]);
    }

    /**
     *
     */
    tournamentTypeSearch(data){
        return this.post(['tournament-type', 'search'], data);
    }

    /**
     *
     */
    tournamentTypeUpdateById(id, data){
        return this.put(['tournament-type', id], data);
    }

    /**
     *
     */
    tournamentTypeDeleteById(id){
        return this.delete(['tournament-type', id]);
    }

    // Tournament Standings

    tournamentStandingsGetById(id){
        return this.get([this._paths.tournamentStanding, id]);
    }

    tournamentStandingsGetByTournamentEqId(eqId){
        return this.get(['tournament', eqId, 'standings']);
    }

    tournamentStandingCreate(data){
        return this.post([this._paths.tournamentStanding], data);
    }

    tournamentStandingUpdate(id, data){
        return this.put([this._paths.tournamentStanding, id], data);
    }

    tournamentStandingDeleteById(id){
        return this.delete([this._paths.tournamentStanding, id]);
    }

    recalculateTournamentStanding(standingId){
        return this.post(['tournament-standing', standingId, 'recalculate']);
    }

    // Tournament Standing Type

    /**
     *
     */
    tournamentStandingTypeCreate(data){
        return this.post(this._paths.standingType, data);
    }

    /**
     *
     */
    tournamentStandingTypeGetById(id){
        return this.get([this._paths.standingType, id]);
    }

    /**
     *
     */
    tournamentStandingTypeUpdateById(id, data){
        return this.put([this._paths.standingType, id], data);
    }

    /**
     *
     */
    tournamentStandingTypeSearch(query){
        return this.post([this._paths.standingType, 'search'], query);
    }

    /**
     *
     */
    tournamentStandingTypeDeleteById(id){
        return this.delete([this._paths.standingType, id]);
    }

    // Tournament Standing Type Parameter

    /**
     *
     */
    parameterTypesGetByTypeGroup(group){
        return this.get([this._paths.standingTypeParameter, 'types', group]);
    }

    /**
     *
     */
    parameterTypeGetById(id){
        return this.get([this._paths.standingTypeParameter, id]);
    }

    /**
     *
     */
    tournamentStandingTypeParameterCreate(type, data){
        return this.post([this._paths.standingTypeParameter, type], data);
    }

    /**
     *
     */
    tournamentStandingTypeParameterPointUpdateById(id, data){
        return this.put([this._paths.standingTypeParameter, 'point', id], data);
    }

    /**
     *
     */
    tournamentStandingTypeParameterOrderUpdate(data){
        return this.put([this._paths.standingTypeParameter, 'order'], data);
    }

    /**
     *
     */
    standingTypeParameterDeleteByParameterId(standingTypeId, parameterid){
        return this.delete([this._paths.standingTypeParameter, standingTypeId, parameterid]);
    }

    // TournamentStage

    /**
     *
     */
    tournamentStageCreate(data){
        return this.post('tournament-stage', data);
    }

    /**
     *
     */
    tournamentStageGetById(id){
        return this.get(['tournament-stage', id]);
    }

    /**
     *
     */
    tournamentStageGetByEqId(eqId){
        return this.get(['lookups', 'TournamentStage', 'byEqId', eqId]);
    }

    /**
     *
     */
    tournamentStageGetAll(){
        return this.get(['tournament-stage']);
    }

    /**
     *
     */
    tournamentStageSearch(data){
        return this.post(['tournament-stage', 'search'], data);
    }

    /**
     *
     */
    tournamentStageUpdateById(id, data){
        return this.put(['tournament-stage', id], data);
    }

    /**
     *
     */
    tournamentStageDeleteById(id){
        return this.delete(['tournament-stage', id]);
    }

    // Tournament Status

    /**
     *
     */
    tournamentStatusGetAll(){
        return this.get(['tournament-status']);
    }

    /**
     *
     */
    tournamentStatusGetById(id){
        return this.get(['lookups', 'TournamentStatusType', 'byId', id]);
    }

    /**
     *
     */
    tournamentTeamStatusGetAll(){
        return this.get(['tournament-status', 'team']);
    }

    // TournamentTeam

    /**
     *
     */
    tournamentTeamCreateByTournamentId(data){
        return this.post([this._paths.tournamentTeam], data);
    }

    /**
     *
     */
    tournamentTeamGetById(id){
        return this.get([this._paths.tournamentTeam, id]);
    }

    /**
     *
     */
    tournamentTeamsGetAllByTournamentId(id){
        return this.get(['tournament', id, 'teams']);
    }

    /**
     *
     */
    tournamentTeamGetSquadById(id){
        return this.get([this._paths.tournamentTeam, 'squad', id]);
    }

    /**
     *
     */
    tournamentTeamUpdateById(id, data){
        return this.put([this._paths.tournamentTeam, id], data);
    }

    /**
     *
     */
    tournamentTeamDeleteByTournamentTeamId(id){
        return this.delete([this._paths.tournamentTeam, id]);
    }

    /**
     *
     */
    tournamentTeamsGetByTournamentEqId(eqId){
        return this.get(['lookups', 'TournamentTeam', 'byTournamentEqId', eqId]);
    }

    tournamentTeamAssignRepresentingToMatches(id){
        return this.post(['tournament-team', id, 'matches-representation-assign']);
    }

    /**
     *
     */
    tournamentTeamUpdatePointsById(id, data){
        return this.put([this._paths.tournamentTeam, id, 'points'], data);
    }

    // Tournament Team Person

    /**
     *
     */
    tournamentTeamPersonCreate(data){
        return this.post(['tournament-team-person'], data);
    }

    /**
     *
     */
    tournamentTeamPersonUpdateById(id, data){
        return this.put([this._paths.tournamentTeamPerson, id], data);
    }

    /**
     *
     */
    tournamentTeamPersonnelByTournamentTeamEqId(eqId){
        return this.get(['lookups', 'TournamentTeamPerson', 'byTournamentTeamEqId', eqId]);
    }

    /**
     *
     */
    tournamentTeamAddPerson(data){
        return this.post([this._paths.tournamentTeamPerson], data);
    }

    /**
     *
     */
    tournamentTeamAddPersonByPersonEqId(data){
        return this.post([this._paths.tournamentTeamPerson, 'by-person'], data);
    }

    /**
     *
     */
    tournamentTeamDeletePersonByTeamPersonId(id){
        return this.delete([this._paths.tournamentTeamPerson, id]);
    }

    // TournamentTeamType

    /**
     *
     */
    tournamentTeamStatusTypes(){
        return this.get(['tournament-status', 'team']);
    }

    // Tournament Team Kit
    /**
     *
     */
    tournamentTeamAddKitByTeamId(id, data){
        return this.patch([this._paths.tournamentTeam, id, 'add-kit'], data);
    }

    /**
     *
     */
    tournamentTeamAddKit(data){
        return this.post(this._paths.tournamentTeamKit, data);
    }

    /**
     *
     */
    tournamentTeamKitsGetByTournamentTeamEqId(eqId){
        return this.get(['lookups', 'TournamentTeamKit', 'byTournamentTeamEqId', eqId]);
    }

    // TournamentPlayer

    /**
     *
     */
    tournamentPlayerCreate(data){
        return this.post('tournament-player', data);
    }

    /**
     *
     */
    tournamentPlayerUpdateById(id, data){
        return this.put(['tournament-player', id], data);
    }

    /**
     *
     */
    tournamentPlayerDeleteById(id){
        return this.delete(['tournament-player', id]);
    }

    /**
     *
     */
    tournamentPlayerUpdateBodyMeasurments(id, data){
        return this.patch(['tournament-player', id], data);
    }

    /**
     *
     */
    tournamentPlayerGetAllByTournamentTeamId(id){
        return this.get(['tournament-player', id]);
    }

    // TournamentTeamManagement

    /**
     *
     */
    tournamentTeamManagementCreate(data){
        return this.post('tournament-team-management', data);
    }

    /**
     *
     */
    tournamentTeamManagementUpdateById(id, data){
        return this.put(['tournament-team-management', id], data);
    }

    /**
     *
     */
    tournamentTeamManagementDeleteById(id){
        return this.delete(['tournament-team-management', id]);
    }

    /**
     *
     */
    tournamentTeamManagementGetById(id){
        return this.get(['tournament-team-management', id]);
    }

    /**
     *
     */
    tournamentTeamManagementGetAllByTournamentTeamId(id){
        return this.get(['tournament-team-management', 'all-by-team', id]);
    }

    // TournamentTeamKit

    /**
     *
     */
    tournamentTeamKitGetById(id){
        return this.get([this._paths.tournamentTeamKit, id]);
    }

    /**
     *
     */
    tournamentTeamKitUpdateById(id, data){
        return this.put([this._paths.tournamentTeamKit, id], data);
    }

    /**
     *
     */
    tournamentTeamKitDeleteById(id){
        return this.delete([this._paths.tournamentTeamKit, id]);
    }

    // TournamentOfficial

    /**
     *
     */
    tournamentAddOfficial(tournamentId, personSourceEqId, data){
        return this.patch(['tournament', tournamentId, 'official', personSourceEqId, 'add'], data);
    }

    /**
     *
     */
    tournamentOfficialGetById(id){
        return this.get([this._paths.tournamentOfficial, id]);
    }

    /**
     *
     */
    tournamentOfficialsGetAllByTournamentId(id){
        return this.get(['tournament', id, 'officials']);
    }

    /**
     *
     */
    tournamentOfficialUpdateById(id, data){
        return this.put([this._paths.tournamentOfficial, id], data);
    }

    /**
     *
     */
    tournamentOfficialDeleteById(id){
        return this.delete([this._paths.tournamentOfficial, id]);
    }

    // TournamentDirector

    /**
     *
     */
    tournamentDirectorGetById(id){
        return this.get(['tournament-director', id]);
    }

    /**
     *
     */
    tournamentDirectorCreate(data){
        return this.post('tournament-director', data);
    }

    /**
     *
     */
    tournamentDirectorUpdateById(id, data){
        return this.put(['tournament-director', id], data);
    }

    /**
     *
     */
    tournamentDirectorGetAllByTournamentId(id){
        return this.get(['tournament-director', 'all-by-tournament', id]);
    }

    /**
     *
     */
    tournamentDirectorDeleteById(id){
        return this.delete(['tournament-director', id]);
    }

    // TournamentVenue

    /**
     *
     */
    tournamentVenueGetById(id){
        return this.get(['tournament-venue', id]);
    }

    /**
     *
     */
    tournamentVenueCreate(data){
        return this.post('tournament-venue', data);
    }

    /**
     *
     */
    tournamentVenueUpdateById(id, data){
        return this.put(['tournament-venue', id], data);
    }

    /**
     *
     */
    tournamentVenueDeleteById(id){
        return this.delete(['tournament-venue', id]);
    }

    // person role

    tournamentsGetByPersonRoleEqId(personRoleEqId){
        return this.get(['person-role', 'eq-id', personRoleEqId, 'tournaments']);
    }
}
