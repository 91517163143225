<script>
import itemList from '@/components/itemList.vue';
import matchReviewRow from '@/components/rows/matchReview.vue';
import MatchDeclarationModel from '@/models/declaration/match.js';

/**
 * complex match review list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'reviewMatchList',
    extends: itemList,
    components: {
        matchReviewRow,
    },
    props: {
        dataTournamentDeclarationId: {
            type: Number,
            default: null,
        },
        dataTournamentEqId: {
            type: String,
            default: null,
        },
    },
    data(){
        return {
            //overwrites
            rowElement: matchReviewRow,
            rowItemPropName: 'matchDeclaration',
            selectItemProp: 'matchDeclaration',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showMatchNumber: true,
                showStatus: true,
                showKickOff: true,
                showDetail: true,
                showWhichTeamDetailLink: true,
            };
        },
        listFilterConfig(){
            return [
                this.createFilterRow([
                    {
                        id: 'name',
                        type: 'text',
                        placeholder: this.$t('searchByTeamName'),
                    },
                    {
                        id: 'kickoffDateOrder',
                        type: 'vselect',
                        placeholder: this.$t('filterByKickoffDate'),
                        options: [
                            {
                                id: 'asc',
                                label: this.$t('ascending'),
                            },
                            {
                                id: 'desc',
                                label: this.$t('descending'),
                            },
                        ],
                    },
                    {
                        id: 'status',
                        type: 'vselectWorkflowStatus',
                        placeholder: this.$t('filterByStatus'),
                    },

                ]),
                this.createFilterRow([
                    {
                        id: 'team',
                        type: 'pickTeam',
                        placeholder: this.$t('filterByTeamName'),
                        // label: this.$t('searchByTeam', 1),
                    },
                ]),
            ];
        },
        listFilterConfigRaw(){
            return true;
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load matches declarations
            return this.$api.call.termsOfParticipation.matchDeclarationSearch({
                tournamentEqId: this.dataTournamentEqId || null,
                tournamentName: this.dataTournamentName || null,
                teamName: filters.team ? filters.team.name : (filters.name ? filters.name : null) || null,
                sport: filters.sport || null,
                statuses: filters.status ? [filters.status] : null,
                kickoffDateDirection: filters.kickoffDateOrder || 'Desc',
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.declarations || result.items || []).map(data => {
                        let declaration = new MatchDeclarationModel(data);
                        declaration.linkedTournamentDeclarationId = this.dataTournamentDeclarationId;
                        return declaration;
                    }),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
    },
};
</script>
