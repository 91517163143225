<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'addEditTournamentTeamStandingForm',
    extends: formComponent,
    props: {
        standingTeams: {
            type: Array,
            default(){
                return [];
            },
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'tournamentTeamId',
                        type: 'text',
                        label: false,
                        placeholder: '',
                        class: 'mb-0',
                        text: '7',
                        value: '6',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        readonly: true,
                    },
                    {
                        id: 'tournamentTeamId',
                        type: 'vselect',
                        label: false,
                        class: 'mb-0',
                        placeholder: this.$t('selectTeamName'),
                        options: this.standingTeams,
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'forceOrder',
                        type: 'number',
                        label: false,
                        placeholder: '',
                        class: 'mb-0',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        hidden: this.hideValue,
                    },
                    {
                        id: 'deductPoints',
                        type: 'number',
                        label: false,
                        class: 'mb-0',
                        placeholder: '',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], null, null, 4, 4, 4),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
