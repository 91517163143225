<template>
    <div class="pageContent">
        <declarationMatchList
            v-bind:title="$t('matchDeclarations')"
            mainTitle
            v-bind:rowProps="rowProps"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import declarationMatchList from '@/components/lists/declaration/match.vue';

export default {
    name: 'declarationMatchIndex',
    extends: base,
    components: {
        declarationMatchList,
    },
    data(){
        return {
            rowProps: {
                showWorkflowStatus: false,
                showWorkflowDeadline: false,
            },
        };
    },
};
</script>
