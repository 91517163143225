<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="(declaration ? declaration.getTournament().name : '') + ' | ' + (declaration ? $t('declarationStep-' + declaration.step) : '') + ' Declaration'"
            v-bind:name="tournamentName + ' | ' + (declaration ? declaration.teamDeclaration.team.name : '-')"
            v-bind:imagePath="declaration ? declaration.getTeamDeclaration().getUnionTeamLogo() : null"
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration">
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="{ name: 'declarationTournamentDetail', params: { dataTournamentDeclarationId, dataTeamDeclarationId, dataDeclarationStep: (declaration ? declaration.step : null) } }"
                    v-if="declaration">
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <declarationWorkflowSummaries v-bind:title="(declaration ? $t('declarationStep-' + declaration.step) : '')"
            v-bind:workflowSummaries="workflowSummaries" v-bind:waitFor="apiData.tournamentDeclaration"
            v-bind:waitForRetry="reloadTournamentDeclaration" v-bind:statusUpdate="apiData.workflowSummaryUpdate"
            v-bind:statusUpdateRetry="updateWorkflowSummaries" v-bind:hide="hideElement(1)"
            v-if="declaration && declaration.existsInConfig(declaration.getStepTarget(declaration.configTargets.squad))" />

        <list v-bind:items="players" v-bind:title="$t('declaredPlayers')" v-bind:waitFor="apiData.tournamentDeclaration"
            v-bind:waitForRetry="reloadTournamentDeclaration" v-bind:hide="hideElement(1)"
            v-if="declaration && declaration.existsInConfig(declaration.getStepTarget(declaration.configTargets.squad))">
            <template v-slot:header>
                <validations ref="playerValidations" v-bind:declaration="declaration" v-bind:items="players"
                    type="player" v-bind:isReview="true"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-players')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-players')) : 0" />
                <playerRow showNr showValidationBiography showValidationPassport showValidationHeadshot
                    showDateOfBirthAndAge showPosition showDetail editImg reviewMode isHeadline
                    v-bind:declaration="declaration" />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow showNr showValidationBiography showValidationPassport showValidationHeadshot
                    showDateOfBirthAndAge showPosition showDetail editImg reviewMode highlightUnderagedWithDoB
                    v-bind:person="person" v-bind:role="role" v-bind:declaration="declaration"
                    v-bind:personAcceptHandler="person => showStatusChangeModal('person', true, $t('confirmTypePerson', { name: person.getName() }), person.id, true)"
                    v-bind:personRejectHandler="person => showStatusChangeModal('person', false, $t('confirmTypePerson', { name: person.getName() }), person.id, true)"
                    v-bind:headshotAcceptHandler="person => showStatusChangeModal('personHeadshot', true, $t('confirmTypeHeadshot', { name: person.getName() }), person.id, true)"
                    v-bind:headshotRejectHandler="person => showStatusChangeModal('personHeadshot', false, $t('confirmTypeHeadshot', { name: person.getName() }), person.id, true)"
                    v-bind:biographyAcceptHandler="person => showStatusChangeModal('PersonBio', true, $t('confirmTypeBio', { name: person.getName() }), person.id, true)"
                    v-bind:biographyRejectHandler="person => showStatusChangeModal('PersonBio', false, $t('confirmTypeBio', { name: person.getName() }), person.id, true)"
                    v-bind:passportAcceptHandler="person => showStatusChangeModal('PersonPassport', true, $t('confirmTypePassport', { name: person.getName() }), person.id, true)"
                    v-bind:passportRejectHandler="person => showStatusChangeModal('PersonPassport', false, $t('confirmTypePassport', { name: person.getName() }), person.id, true)"
                    v-on:reviewHandled="updateWorkflowSummaries" />
            </template>
            <template v-slot:empty>
                <div />
            </template>
        </list>

        <list v-bind:items="managers" v-bind:title="$t('declaredManagement')"
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration"
            v-bind:hide="hideElement(1)"
            v-if="declaration && declaration.existsInConfig(declaration.getStepTarget(declaration.configTargets.squad))">
            <template v-slot:header>
                <validations ref="managerValidations" v-bind:declaration="declaration" v-bind:items="managers"
                    type="manager" v-bind:isReview="true" v-bind:totalManagement="totalManagement"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-managers')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-managers')) : 0" />
                <managerRow showValidationBiography showValidationPassport showValidationHeadshot showPosition
                    showDetail editImg reviewMode isHeadline v-bind:declaration="declaration" />
            </template>
            <template v-slot="{ item: person, role }">
                <managerRow showValidationBiography showValidationPassport showValidationHeadshot showPosition
                    showDetail editImg reviewMode v-bind:person="person" v-bind:role="role"
                    v-bind:declaration="declaration"
                    v-bind:personAcceptHandler="person => showStatusChangeModal('person', true, $t('confirmTypePerson', { name: person.getName() }), person.id, true)"
                    v-bind:personRejectHandler="person => showStatusChangeModal('person', false, $t('confirmTypePerson', { name: person.getName() }), person.id, true)"
                    v-bind:headshotAcceptHandler="person => showStatusChangeModal('personHeadshot', true, $t('confirmTypeHeadshot', { name: person.getName() }), person.id, true)"
                    v-bind:headshotRejectHandler="person => showStatusChangeModal('personHeadshot', false, $t('confirmTypeHeadshot', { name: person.getName() }), person.id, true)"
                    v-bind:biographyAcceptHandler="person => showStatusChangeModal('PersonBio', true, $t('confirmTypeBio', { name: person.getName() }), person.id, true)"
                    v-bind:biographyRejectHandler="person => showStatusChangeModal('PersonBio', false, $t('confirmTypeBio', { name: person.getName() }), person.id, true)"
                    v-bind:passportAcceptHandler="person => showStatusChangeModal('PersonPassport', true, $t('confirmTypePassport', { name: person.getName() }), person.id, true)"
                    v-bind:passportRejectHandler="person => showStatusChangeModal('PersonPassport', false, $t('confirmTypePassport', { name: person.getName() }), person.id, true)"
                    v-on:reviewHandled="updateWorkflowSummaries" />
            </template>
            <template v-slot:empty>
                <div />
            </template>
        </list>

        <list v-bind:items="medics" v-bind:title="$t('declaredMedicalTeam')"
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration"
            v-bind:hide="hideElement(1)"
            v-if="declaration && declaration.existsInConfig(declaration.getStepTarget(declaration.configTargets.squad))">
            <template v-slot:header>
                <validations ref="medicValidations" v-bind:declaration="declaration" v-bind:items="medics" type="medic"
                    v-bind:isReview="true" v-bind:totalManagement="totalManagement"
                    v-bind:min="declaration ? Number(declaration.findInConfiguration('squad.minimum-number-of-medics')) : 0"
                    v-bind:max="declaration ? Number(declaration.findInConfiguration('squad.maximum-number-of-medics')) : 0" />
                <medicRow showValidationBiography showValidationPassport showValidationMedicalCertificate
                    showValidationHeadshot showPosition showDetail editImg reviewMode isHeadline
                    v-bind:declaration="declaration" />
            </template>
            <template v-slot="{ item: person, role }">
                <medicRow showValidationBiography showValidationPassport showValidationMedicalCertificate
                    showValidationHeadshot showPosition showDetail editImg reviewMode v-bind:person="person"
                    v-bind:role="role" v-bind:declaration="declaration"
                    v-bind:personAcceptHandler="person => showStatusChangeModal('person', true, $t('confirmTypePerson', { name: person.getName() }), person.id, true)"
                    v-bind:personRejectHandler="person => showStatusChangeModal('person', false, $t('confirmTypePerson', { name: person.getName() }), person.id, true)"
                    v-bind:headshotAcceptHandler="person => showStatusChangeModal('personHeadshot', true, $t('confirmTypeHeadshot', { name: person.getName() }), person.id, true)"
                    v-bind:headshotRejectHandler="person => showStatusChangeModal('personHeadshot', false, $t('confirmTypeHeadshot', { name: person.getName() }), person.id, true)"
                    v-bind:biographyAcceptHandler="person => showStatusChangeModal('PersonBio', true, $t('confirmTypeBio', { name: person.getName() }), person.id, true)"
                    v-bind:biographyRejectHandler="person => showStatusChangeModal('PersonBio', false, $t('confirmTypeBio', { name: person.getName() }), person.id, true)"
                    v-bind:passportAcceptHandler="person => showStatusChangeModal('PersonPassport', true, $t('confirmTypePassport', { name: person.getName() }), person.id, true)"
                    v-bind:passportRejectHandler="person => showStatusChangeModal('PersonPassport', false, $t('confirmTypePassport', { name: person.getName() }), person.id, true)"
                    v-bind:medicalCertificateAcceptHandler="person => showStatusChangeModal('PersonMedicalCertificate', true, $t('confirmTypeMedicalCertificate', { name: person.getName() }), person.id, true)"
                    v-bind:medicalCertificateRejectHandler="person => showStatusChangeModal('PersonMedicalCertificate', false, $t('confirmTypeMedicalCertificate', { name: person.getName() }), person.id, true)"
                    v-on:reviewHandled="updateWorkflowSummaries" />
            </template>
            <template v-slot:empty>
                <div />
            </template>
        </list>

        <list v-bind:items="participationAgreements" v-bind:title="$t('participationAgreement')"
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration"
            v-bind:hide="hideElement(2)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.participationAgreement)">
            <template v-slot:buttons>
                <actionButton variant="danger" v-on:click="showStatusChangeModal('participationAgreement', false)">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success" v-on:click="showStatusChangeModal('participationAgreement', true)">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations v-bind:items="participationAgreements" v-bind:isReview="true"
                    type="participationAgreement" />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow showRemaining loadingThrobber v-bind:loadingWhile="apiData.participationAgreementUpdating"
                    v-bind:upload="upload" v-bind:role="role" readonly />
            </template>
            <template v-slot:footer>
                <declarationComments type="participationAgreement" v-bind:declaration="declaration" class="mt-3"
                    allowInternalComments collapseEnabled collapseHidden />
            </template>
        </list>

        <list v-bind:items="teamLogos" v-bind:title="$t('teamLogo')" v-bind:waitFor="apiData.tournamentDeclaration"
            v-bind:waitForRetry="reloadTournamentDeclaration" v-bind:hide="hideElement(3)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.teamLogo)">
            <template v-slot:buttons>
                <actionButton variant="danger" v-on:click="showStatusChangeModal('teamLogo', false)">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success" v-on:click="showStatusChangeModal('teamLogo', true)">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations v-bind:items="teamLogos" v-bind:isReview="true" type="teamLogo" />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow showRemaining loadingThrobber showTeamLogoSubmitMessages
                    v-bind:loadingWhile="apiData.teamLogoUpdating" v-bind:upload="upload" v-bind:role="role" readonly />
            </template>
            <template v-slot:footer>
                <declarationComments type="teamLogo" v-bind:declaration="declaration" class="mt-3" allowInternalComments
                    collapseEnabled collapseHidden />
            </template>
        </list>

        <list v-bind:items="nationalAnthems" v-bind:title="$t('nationalAnthem')"
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration"
            v-bind:hide="hideElement(4)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.nationalAnthem)">
            <template v-slot:buttons>
                <actionButton variant="danger" v-on:click="showStatusChangeModal('nationalAnthem', false)">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success" v-on:click="showStatusChangeModal('nationalAnthem', true)">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations v-bind:items="nationalAnthems" v-bind:isReview="true" type="nationalAnthem" />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow showRemaining loadingThrobber v-bind:loadingWhile="apiData.nationalAnthemUpdating"
                    v-bind:upload="upload" v-bind:role="role" readonly />
            </template>
            <template v-slot:footer>
                <declarationComments type="nationalAnthem" v-bind:declaration="declaration" class="mt-3"
                    allowInternalComments collapseEnabled collapseHidden />
            </template>
        </list>

        <list v-bind:items="medicalInsuranceCovers" v-bind:title="$t('medicalInsuranceCover')"
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration"
            v-bind:hide="hideElement(5)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.medicalInsuranceCover)">
            <template v-slot:buttons>
                <actionButton variant="danger" v-on:click="showStatusChangeModal('medicalInsuranceCover', false)">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success" v-on:click="showStatusChangeModal('medicalInsuranceCover', true)">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations v-bind:items="medicalInsuranceCovers" v-bind:isReview="true"
                    type="medicalInsuranceCover" />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow showRemaining loadingThrobber v-bind:loadingWhile="apiData.medicalInsuranceCoverUpdating"
                    v-bind:upload="upload" v-bind:role="role" readonly />
            </template>
            <template v-slot:footer>
                <declarationComments type="medicalInsuranceCover" v-bind:declaration="declaration" class="mt-3"
                    allowInternalComments collapseEnabled collapseHidden />
            </template>
        </list>

        <list v-bind:title="$t('kitDeclaration')" v-bind:items="kits" isRow
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration"
            v-bind:hide="hideElement(6)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.kit)">
            <template v-slot:buttons>
                <actionButton variant="danger" v-on:click="showStatusChangeModal('kit', false, $t('kitDeclaration'))">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success" v-on:click="showStatusChangeModal('kit', true, $t('kitDeclaration'))">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template v-slot:top>
                <declarationStatusBlock v-bind:declarationStatus="kitsStatus" />
            </template>
            <template v-slot:header>
                <validations ref="kitValidations"
                    v-bind:items="declaration ? declaration.getTeamDeclaration().getKits() : []" type="teamKit"
                    v-bind:min="2" v-bind:isReview="true" />
            </template>
            <template v-slot="{ item: teamKit, role }">
                <teamKitRow v-bind:teamKit="teamKit" v-bind:role="role" readonly />
            </template>
            <template v-slot:footer>
                <declarationComments type="kit" v-bind:declaration="declaration" allowInternalComments collapseEnabled
                    collapseHidden />
            </template>
        </list>

        <list v-bind:items="teamMemberAcceptanceForms" v-bind:title="$t('teamMemberAcceptanceForm')"
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration"
            v-bind:hide="hideElement(7)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.teamMemberAcceptanceForm)">
            <template v-slot:buttons>
                <actionButton variant="danger" v-on:click="showStatusChangeModal('teamMemberAcceptanceForm', false)">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success" v-on:click="showStatusChangeModal('teamMemberAcceptanceForm', true)">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations v-bind:items="teamMemberAcceptanceForms" v-bind:isReview="true"
                    type="teamMemberAcceptanceForm" />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow showRemaining v-bind:upload="upload" v-bind:role="role" readonly />
            </template>
            <template v-slot:footer>
                <declarationComments type="teamMemberAcceptanceForm" v-bind:declaration="declaration" class="mt-3"
                    allowInternalComments collapseEnabled collapseHidden />
            </template>
        </list>

        <list v-bind:items="teamDisciplinaryCertificates" v-bind:title="$t('teamDisciplinaryCertificates')"
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration"
            v-bind:hide="hideElement(8)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.teamDisciplinaryCertificate)">
            <template v-slot:buttons>
                <actionButton variant="danger"
                    v-on:click="showStatusChangeModal('teamDisciplinaryCertificate', false, $t('teamDisciplinaryCertificates'))">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success"
                    v-on:click="showStatusChangeModal('teamDisciplinaryCertificate', true, $t('teamDisciplinaryCertificates'))">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations v-bind:items="teamDisciplinaryCertificates" v-bind:isReview="true"
                    type="teamDisciplinaryCertificate" />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow showRemaining loadingThrobber
                    v-bind:loadingWhile="apiData.teamDisciplinaryCertificateUpdating" v-bind:upload="upload"
                    v-bind:role="role" readonly />
            </template>
            <template v-slot:footer>
                <declarationComments type="teamDisciplinaryCertificate" v-bind:declaration="declaration" class="mt-3"
                    allowInternalComments collapseEnabled collapseHidden />
            </template>
        </list>

        <list v-bind:items="teamPhoneticGuides" v-bind:title="$t('teamPhoneticGuide')"
            v-bind:waitFor="apiData.tournamentDeclaration" v-bind:waitForRetry="reloadTournamentDeclaration"
            v-bind:hide="hideElement(9)"
            v-if="declaration && declaration.existsInConfig(declaration.configTargets.teamPhoneticGuide)">
            <template v-slot:buttons>
                <actionButton variant="danger" v-on:click="showStatusChangeModal('teamPhoneticGuide', false)">
                    {{ $t('reject') }}
                </actionButton>
                <actionButton variant="success" v-on:click="showStatusChangeModal('teamPhoneticGuide', true)">
                    {{ $t('accept') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <validations v-bind:items="teamPhoneticGuides" v-bind:isReview="true" type="teamPhoneticGuide" />
            </template>
            <template v-slot="{ item: upload, role }">
                <uploadRow showRemaining loadingThrobber v-bind:loadingWhile="apiData.teamPhoneticGuideUpdating"
                    v-bind:upload="upload" v-bind:role="role" readonly />
            </template>
            <template v-slot:footer>
                <declarationComments type="teamPhoneticGuide" v-bind:declaration="declaration" class="mt-3"
                    allowInternalComments collapseEnabled collapseHidden />
            </template>
        </list>

        <confirmModal id="statusChangeModal" v-bind:text="statusChangeModalText"
            v-bind:confirmHandler="statusChangeModalHandler" v-on:hidden="statusChangeModalClosedHandler" />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import { declarationDetailDisplayMixins, declarationDetailReviewMixins, declarationDetailTournamentMixins } from '@/mixins/declarationDetail.js';
import detailsHeader from '@/components/detailsHeader.vue';
import subNavigation from '@/components/subnavigation.vue';
import declarationComments from '@/components/declarationComments.vue';
import declarationWorkflowSummaries from '@/components/declarationWorkflowSummaries.vue';
import validations from '@/components/validations.vue';

import playerRow from '@/components/rows/player.vue';
import managerRow from '@/components/rows/manager.vue';
import medicRow from '@/components/rows/medic.vue';
import uploadRow from '@/components/rows/upload.vue';
import teamKitRow from '@/components/rows/teamKit.vue';

import confirmModal from '@/components/modals/confirm.vue';

import declarationStatusBlock from '@/components/blocks/declarationStatus.vue';

export default {
    name: 'declarationTournamentDetail',
    extends: base,
    mixins: [
        declarationDetailDisplayMixins,
        declarationDetailReviewMixins,
        declarationDetailTournamentMixins,
    ],
    components: {
        detailsHeader,
        subNavigation,
        declarationComments,
        declarationWorkflowSummaries,
        validations,

        playerRow,
        managerRow,
        medicRow,
        uploadRow,
        teamKitRow,

        confirmModal,

        declarationStatusBlock,
    },
};
</script>
