export const numberFormats = {
    en: {
        nr: {
            style: 'decimal',
        },
        currency: {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
        },
        size: {
            style: 'decimal',
            /* browser support does not allow this yet sadly
            style: 'unit',
            unit: 'meter',
            unitDisplay: 'short',
            */
        },
        weight: {
            style: 'decimal',
            /* browser support does not allow this yet sadly
            style: 'unit',
            unit: 'kilogram',
            unitDisplay: 'short',
            */
        }
    }
};

export const dateTimeFormats = {
    en: {
        date: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        dateLong: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
        },
        year: {
            year: 'numeric',
        },
        time: {
            hour: '2-digit',
            minute: '2-digit',
        },
        dateTime: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        },
        dateTimeFull: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        },
    }
};
