<template>
    <div class="pageContent">
        <unionList
            v-bind:title="$tc('union', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.unions.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'commonUnionCreate' }"
                >
                    {{ $t('createUnion') }}
                </actionButton>
            </template>
        </unionList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import unionList from '@/components/lists/union.vue';

export default {
    name: 'commonUnionIndex',
    extends: base,
    components: {
        unionList,
    },
    data(){
        return {
            rowProps: {
                showRegionalAssociation: true,
                showRemove: true,
            },
        };
    },
};
</script>
