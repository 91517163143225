export class RequestError extends Error {
    /**
     * status number
     *
     * @type {null|number}
     */
    status = null;

    /**
     * list of errors
     *
     * @type {object}
     */
    errors = {};

    /**
     * reason
     *
     * @type {null|string}
     */
    reason = null;

    /**
     * custom error id parser
     *
     * @type {function}
     */
    errorIdParser = null;

    /**
     * custom error ids
     *
     * @type {array}
     */
    errorIds = [];

    /**
     * custom error messages
     *
     * @type {array}
     */
    errorMessages = [];

    /**
     * unrelated step names
     *
     * @type {array}
     */
    unrelatedStepNames = ['ParticipationAgreement', 'TeamLogo', 'NationalAnthem', 'MedicalInsuranceCover', 'TeamDisciplinaryCertificate', 'TeamMemberAcceptanceForm', 'TeamPhoneticGuide', 'Kit'];

    constructor(message, status, errors, reason){
        super(message);

        this.name = 'RequestError';
        this.status = status || null;
        this.errors = errors || {};
        this.reason = reason || null;
    }

    getErrorMessage(value){
        if(typeof value === 'string' || value instanceof String){
            this.errorMessages.push(value);
            return;
        }

        Object.values(value).forEach(valueItem => {
            if (typeof valueItem === 'object' || typeof valueItem === 'string' || valueItem instanceof String){
                this.getErrorMessage(valueItem);
            }
        });
    }

    getErrorMessages(fallbackMessage = null, hideMessage = false){
        const prefix = (!hideMessage ? [this.message] : []);

        this.errorMessages = [];
        Object.values(this.errors || {}).forEach((value) => {
            this.getErrorMessage(value);
        });

        if(!prefix.length && !this.errorMessages.length && fallbackMessage){
            return [fallbackMessage];
        }

        return prefix.concat(this.errorMessages);
    }

    formatKey(key){
        const splitKey = key.toString().split('-');
        if (splitKey.length){
            return splitKey.map(name => name.charAt(0).toUpperCase() + name.slice(1)).join('');
        }
        return key.toString();
    }

    getErrorId(id, key, value, upperMostParentId){
        if (!value.length) {
            this.errorIds.push(id);
            return id;
        }

        value.forEach(valueItem => {
            if (typeof valueItem === 'object'){
                Object.entries(valueItem).forEach(([innerKey, innerValue]) => {
                    let addInnerId = this.formatKey(innerKey);
                    if (addInnerId === 'DeadlineDate'){
                        addInnerId = 'Deadline';
                    }

                    if (id.includes(addInnerId) || addInnerId === 'PersonDeclarationItems'){
                        this.getErrorId(id, innerKey, innerValue, upperMostParentId);
                    }
                    else{
                        this.getErrorId(id + addInnerId, innerKey, innerValue, upperMostParentId);
                    }
                });
            }

            if(typeof key === 'string' || key instanceof String){
                let idToAdd = id;

                if (this.unrelatedStepNames.some(v => idToAdd.includes(v))) {
                    idToAdd.replace(upperMostParentId, '');
                }

                if (idToAdd.includes('MinimumNumberOfReplacementPlayers')){
                    idToAdd = 'replacementsMinimumNumberOfPlayers';
                }
                if (idToAdd.includes('MaximumNumberOfReplacementPlayers')){
                    idToAdd = 'replacementsMaximumNumberOfPlayers';
                }
                if (idToAdd.includes('MinimumNumberOfNonPlayers')){
                    idToAdd = 'replacementsMinimumNumberOfNonPlayers';
                }
                if (idToAdd.includes('MaximumNumberOfNonPlayers')){
                    idToAdd = 'replacementsMaximumNumberOfNonPlayers';
                }

                this.errorIds.push(idToAdd);
            }
        });
    }

    getErrorIds(){
        if(!this.errors){
            return [];
        }

        if(this.errorIdParser){
            this.errorIds = [];

            Object.entries(this.errors).forEach(([key, value]) => {
                let id = this.formatKey(key);
                this.getErrorId(id === 'Main' ? '' : id, key, value, id);
            });

            return this.errorIdParser([...new Set(this.errorIds)]);
        }

        return Object.keys(this.errors)
            .map(name => String(name))
            .map(name => name.split('.').pop())
            .map(name => name.charAt(0).toLowerCase() + name.slice(1));
    }
}

export class CriticalError extends Error {

}
