<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToMatchDetailsLink"
        >
            <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
        </actionButton>

        <matchReportsHeader
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStats"
            v-bind:title="$t('matchReport')"
            v-bind:data="stats"
        />

        <wrapper
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStats"
            noPadding noShadow noLine
            v-if="tableValuesPlayerLineup.length"
        >
            <b-table
                head-variant="light"
                responsive
                hover
                v-bind:items="tableValuesPlayerLineup"
                v-bind:fields="tableFieldsPlayerLineup"
            >
                <template #head()="data">
                    <span v-b-tooltip.hover.top="data.headerTitle">{{ data.label.toString().toUpperCase() }}</span>
                </template>
                <template #cell(teamOnePlayerName)="data">
                    <span>{{ data.item.teamOneTeamRepresenting }}</span>
                    <span class="font-heavy">{{ data.item.teamOnePlayerName }}</span>
                </template>
                <template #cell(lu)="data">
                    <span class="font-heavy">{{ data.item.lu }}</span>
                </template>
                <template #cell(teamTwoPlayerName)="data">
                    <span class="font-heavy">{{ data.item.teamTwoPlayerName }}</span>
                    <span>{{ data.item.teamTwoTeamRepresenting }}</span>
                </template>
<!--                     <template #cell(teamOneC)="data">
                    <span v-if="data.item.teamOneC">
                        <span
                            v-for="(value, i) in data.item.teamOneC.split('')"
                            v-bind:key="i">
                            <icon v-bind:type="cardIcon(value)"/>
                        </span>
                    </span>
                </template> -->
<!--                     <template #cell(teamTwoC)="data">
                    <span v-if="data.item.teamTwoC">
                        <span
                            v-for="(value, i) in data.item.teamTwoC.split('')"
                            v-bind:key="i">
                            <icon v-bind:type="cardIcon(value)"/>
                        </span>
                    </span>
                </template> -->
            </b-table>
        </wrapper>

        <wrapper
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStats"
            noPadding noShadow noLine
            v-if="tableValuesReplacementLineup.length"
        >
            <b-table
                head-variant="light"
                responsive
                hover
                v-bind:items="tableValuesReplacementLineup"
                v-bind:fields="tableFieldsReplacementLineup"
            >
                <template #head()="data">
                    <span v-b-tooltip.hover.top="data.headerTitle">{{ data.label.toString().toUpperCase() }}</span>
                </template>
                <template #cell(teamOnePlayerName)="data">
                    <span>{{ data.item.teamOneTeamRepresenting }}</span>
                    <span class="font-heavy">{{ data.item.teamOnePlayerName }}</span>
                </template>
                <template #cell(lu)="data">
                    <span class="font-heavy">{{ data.item.lu }}</span>
                </template>
                <template #cell(teamTwoPlayerName)="data">
                    <span class="font-heavy">{{ data.item.teamTwoPlayerName }}</span>
                    <span>{{ data.item.teamTwoTeamRepresenting }}</span>
                </template>
            </b-table>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import matchReportsHeader from '@/components/matchReportsHeader.vue';
import constants from '@/constants.js';

import MatchStatsModel from '@/models/matchStats.js';

export default {
    name: 'commonMatchReport',
    extends: base,
    components: {
        icon,
        matchReportsHeader,
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                stats: this.loadStats(),
            },
            stats: null,
            matchTournaments: [],
            tableValuesPlayerLineup: [],
            tableFieldsPlayerLineup: [
                { key: 'teamOneC', label: 'C', headerTitle: this.$t('cards'), class: 'text-center' },
                { key: 'teamOneDG', label: 'DG', headerTitle: this.$t('dropGoals'), class: 'text-center' },
                { key: 'teamOnePen', label: 'Pen', headerTitle: this.$t('penalties'), class: 'text-center' },
                { key: 'teamOneCon', label: 'Con', headerTitle: this.$t('conversions'), class: 'text-center' },
                { key: 'teamOneTry', label: 'Try', headerTitle: this.$t('tries'), class: 'text-center' },
                { key: 'teamOnePts', label: 'Pts', headerTitle: this.$t('points'), class: 'text-center' },
                { key: 'teamOnePlayerName', stickyColumn: false, label: '', headerTitle: 'Player Name', class: 'text-right', thStyle: 'width:250px' },
                { key: 'lu', stickyColumn: false, label: this.$t('lineup'), headerTitle: '', class: 'text-center', thStyle: 'width:50px' },
                { key: 'teamTwoPlayerName', stickyColumn: false, label: '', headerTitle: 'Player Name', thStyle: 'width:250px' },
                { key: 'teamTwoPts', label: 'Pts', headerTitle: this.$t('points'), class: 'text-center' },
                { key: 'teamTwoTry', label: 'Try', headerTitle: this.$t('tries'), class: 'text-center' },
                { key: 'teamTwoCon', label: 'Con', headerTitle: this.$t('conversions'), class: 'text-center' },
                { key: 'teamTwoPen', label: 'Pen', headerTitle: this.$t('penalties'), class: 'text-center' },
                { key: 'teamTwoDG', label: 'DG', headerTitle: this.$t('dropGoals'), class: 'text-center' },
                { key: 'teamTwoC', label: 'C', headerTitle: this.$t('cards'), class: 'text-center' },
            ],
            tableValuesReplacementLineup: [],
            maxPlayerLength: 0,
        };
    },
    computed: {
        backToMatchDetailsLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
        tableFieldsReplacementLineup(){
            const tableFieldsSubsLineup = JSON.parse(JSON.stringify(this.tableFieldsPlayerLineup));
            let tableValueLu = tableFieldsSubsLineup.find(tableValue => tableValue.key === 'lu');

            if(tableValueLu){
                tableValueLu.label = this.$t('subs');
            }

            return tableFieldsSubsLineup;
        }
    },
    methods: {
        loadStats(){
            return this.$api.call.match.matchReportsStatsGetByMatchId(this.dataMatchId).then(data => {
                this.stats = new MatchStatsModel(data);

                this.buildPlayerItems();
                this.buildReplacementItems();
            });
        },
        reloadStats(){
            this.apiData.stats = this.loadStats();
        },
        buildPlayerItems(){
            const teamOnePlayers = this.stats.teams[0].players.filter(p => !p.player.isReplacement && p.player.roleName !== constants.personRoleType.management).sort((a, b) => a.player.positionOrder - b.player.positionOrder);
            const teamTwoPlayers = this.stats.teams[1].players.filter(p => !p.player.isReplacement && p.player.roleName !== constants.personRoleType.management).sort((a, b) => a.player.positionOrder - b.player.positionOrder);

            this.tableValuesPlayerLineup = this.buildPersonItems(teamOnePlayers, teamTwoPlayers);
        },
        buildReplacementItems(){
            const teamOneReplacements = this.stats.teams[0].players.filter(p => p.player.isReplacement).sort((a, b) => a.player.positionOrder - b.player.positionOrder);
            const teamTwoReplacements = this.stats.teams[1].players.filter(p => p.player.isReplacement).sort((a, b) => a.player.positionOrder - b.player.positionOrder);

            this.tableValuesReplacementLineup = this.buildPersonItems(teamOneReplacements, teamTwoReplacements, true);
        },
        buildPersonItems(teamOnePersons, teamTwoPersons, isReplacements = false){
            let personItems = [];

            const maxCollectionLength = teamOnePersons.length > teamTwoPersons.length ? teamOnePersons.length : teamTwoPersons.length;
            if(!isReplacements){
                this.maxPlayerLength = maxCollectionLength;
            }

            for (let i = 0; i < maxCollectionLength; i++) {
                let teamOnePlayer = teamOnePersons[i] || null;
                let teamTwoPlayer = null;

                if(teamTwoPersons[i]){
                    if(!teamOnePersons || (teamOnePersons[i]?.player?.positionNumber === teamTwoPersons[i]?.player?.positionNumber)){
                        teamTwoPlayer = teamTwoPersons[i];
                    }
                    else
                    {
                        teamTwoPlayer = teamTwoPersons.find(p => p.player?.positionNumber === teamOnePlayer?.player?.positionNumber);
                    }
                }

                personItems.push({
                    teamOneC: this.getCardLetterRepresentations(teamOnePlayer),
                    teamOneDG: teamOnePlayer?.statistics?.dropGoals || '',
                    teamOnePen: teamOnePlayer?.statistics?.penalties || '',
                    teamOneCon: teamOnePlayer?.statistics?.conversions || '',
                    teamOneTry: teamOnePlayer?.statistics?.tries || '',
                    teamOnePts: teamOnePlayer?.statistics?.points || '',
                    teamOnePlayerName: this.getPlayerName(teamOnePlayer),
                    teamOneTeamRepresenting: this.getTeamRepresenting(teamOnePlayer),
                    lu: teamOnePlayer?.player?.positionNumber || teamTwoPlayer?.player?.positionNumber || (isReplacements ? this.maxPlayerLength + (i + 1) : i + 1) || '',
                    teamTwoPlayerName: this.getPlayerName(teamTwoPlayer, true),
                    teamTwoTeamRepresenting: this.getTeamRepresenting(teamTwoPlayer, true),
                    teamTwoPts: teamTwoPlayer?.statistics?.points || '',
                    teamTwoTry: teamTwoPlayer?.statistics?.tries || '',
                    teamTwoCon: teamTwoPlayer?.statistics?.conversions || '',
                    teamTwoPen: teamTwoPlayer?.statistics?.penalties || '',
                    teamTwoDG: teamTwoPlayer?.statistics?.dropGoals || '',
                    teamTwoC: this.getCardLetterRepresentations(teamTwoPlayer),
                });
            }

            return personItems;
        },
        getPlayerName(person, isTeamTwo = false){
            let playerName = person?.player?.displayName || '';

            if (!playerName){
                playerName = person?.player?.forenames || '';
                playerName += person?.player?.surname ? ' ' + person.player.surname : '';
            }

            if (isTeamTwo){
                playerName += person?.player?.isCaptain ? ' (c)' : '';
            }
            else
            {
                playerName = (person?.player?.isCaptain ? '(c) ' : '') + playerName;
            }

            return playerName;
        },
        getTeamRepresenting(person, isTeamTwo = false){
            let teamRepresenting = '';

            if (person?.player?.teamsRepresented?.length){
                teamRepresenting = isTeamTwo ? (' (' + person?.player?.teamsRepresented[0]?.teamName + ')') : ('(' + person?.player?.teamsRepresented[0]?.teamName + ') ');
            }

            return teamRepresenting;
        },
        getCardLetterRepresentations(player){
            let cardLetters = player?.statistics?.yellowCards ? Array(player.statistics.yellowCards + 1).join('Y') : '';
            cardLetters += player?.statistics?.redCards ? Array(player.statistics.redCards + 1).join('R') : '';
            return cardLetters;
        },
        cardIcon(cardLetter){
            let cardIcon = 'card-yellow';

            if (cardLetter === 'R')
            {
                cardIcon = 'card-red';
            }

            return cardIcon;
        },
    },
};
</script>
