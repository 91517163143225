<template>
    <div class="pageContent">
        <tournamentTypeGroupList
            v-bind:title="$t('tournamentTypeGroups')"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.tournamentTypeGroups.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'administrationTournamentTypeGroupCreate' }"
                >
                    {{ $t('createGroup') }}
                </actionButton>
            </template>
        </tournamentTypeGroupList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import tournamentTypeGroupList from '@/components/lists/administration/tournamentTypeGroup.vue';

export default {
    name: 'administrationTournamentTypeGroupIndex',
    extends: base,
    components: {
        tournamentTypeGroupList,
    },
    data(){
        return {
            rowProps: {
                showEdit: true,
                showDelete: true,
            },
        };
    },
};
</script>
