<script>
import itemList from '@/components/itemList.vue';
import tournamentTypeRow from '@/components/rows/administration/tournamentType.vue';
import TournamentTypeModel from '@/models/tournamentType.js';

/**
 * complex tournamentType list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'tournamentTypeList',
    extends: itemList,
    components: {
        tournamentTypeRow,
    },
    data(){
        return {
            //overwrites
            rowElement: tournamentTypeRow,
            rowItemPropName: 'tournamentType',
            selectItemProp: 'tournamentType',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showSelect: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByName'),
                    label: false,
                },
                {
                    id: 'sport',
                    type: 'vselectSport',
                    placeholder: this.$t('selectSport'),
                    disabled: this.readonly,
                    label: false,
                },
                {
                    id: 'tournamentTypeGroup',
                    type: 'vselectTournamentTypeGroup',
                    placeholder: this.$t('filterByTournamentGroup'),
                    disabled: this.readonly,
                    label: false,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            return this.$api.call.tournament.tournamentTypeSearch({
                name: filters.name || null,
                sport: filters.sport || null,
                tournamentTypeGroupId: filters.tournamentTypeGroup || null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    ((result || {}).data || []).map(data => new TournamentTypeModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.tournament.tournamentTypeDeleteById(item.id);
        },
    },
};
</script>
