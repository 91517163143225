<script>
import vselectElement from '../vselect.vue';
import PositionModel from '@/models/position.js';

/**
 * vselect element for PositionModel  Officials items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectOfficialPositionElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                return this.$api.call.person.positionSearch({
                    roleType: 'Official',
                    name: text || null,
                }).then(result => (result.positions || result.items || []).map(data => new PositionModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.person.positionGetById(id)))
                            .then(results => results.map(data => new PositionModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
