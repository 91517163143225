<script>
import itemList from '@/components/itemList.vue';
import teamRow from '@/components/rows/team.vue';
import TeamModel from '@/models/team.js';

/**
 * complex team list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'teamList',
    extends: itemList,
    components: {
        teamRow,
    },
    data(){
        return {
            //overwrites
            rowElement: teamRow,
            rowItemPropName: 'team',
            selectItemProp: 'team',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
                showSport: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchByTeamName'),
                    label: false,
                },
                {
                    id: 'sport',
                    type: 'vselectSport',
                    placeholder: this.$t('filterBySport'),
                    label: false,
                    //hidden: !this.combinedRowProps.showSport,
                },
                {
                    id: 'teamType',
                    type: 'vselectTeamType',
                    placeholder: this.$t('filterByTeamType'),
                    label: false,
                    valueProperty: 'descriptionCamelCase',
                    //hidden: !this.combinedRowProps.showType,
                },
                {
                    id: 'country',
                    type: 'vselectCountry',
                    valueProperty: 'name',
                    placeholder: this.$t('filterByCountry'),
                    label: false,
                    //hidden: !this.combinedRowProps.showCountry,
                },
                {
                    id: 'union',
                    type: 'vselectUnion',
                    placeholder: this.$t('filterByUnion'),
                    label: false,
                    //hidden: !this.combinedRowProps.showUnion,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load teams
            return this.$api.call.union.teamSearch({
                teamName: filters.name || null,
                teamType: filters.teamType || null,
                countryName: filters.country || null,
                sport: filters.sport || null,
                unionId: filters.union || null,
                aliasDate: filters.aliasDate || null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.data || []).map(data => new TeamModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
        requestItemRemove(item){
            return this.$api.call.union.teamDeleteById(item.id);
        },
    },
};
</script>
