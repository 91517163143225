<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('union', 1)" v-bind:title-tag="titleTag" v-if="showUnion">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('union')" v-if="isHeadline">{{ getHeadline('union', $tc('union', 1)) }}</label>
            <span v-else>{{ penalty.teamName }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('dateApplied', 12, 6, 3)" v-if="showStartDate">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('dateApplied')">{{ getHeadline('dateApplied', $t('dateApplied')) }}</label>
            <span v-if="!isHeadline">{{ $d(penalty.dateApplied, 'date') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('amount', 12, 6, 3)" v-if="showAmount">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('amount')">{{ getHeadline('amount', $tc('penalty', 1)) }}</label>
            <span v-if="!isHeadline">{{ penalty.amount }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="editLink"
                v-on:click="emitEvent('edit', $event)"
                v-if="showView"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', penalty.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', penalty.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: penalty.name })"
            v-bind:data="penalty"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />
    </b-card>
</template>

<script>
import base from '../base.vue';

import PenaltyModel from '@/models/penalty';

/**
 * match block component
 */
export default {
    name: 'penaltiesRow',
    extends: base,
    props: {
        penalty: {
            type: PenaltyModel,
            default() {
                return new PenaltyModel();
            },
        },

        /**
         * show union
         */
        showUnion: {
            type: Boolean,
            default: true,
        },
        /**
         * show start date
         */
        showStartDate: {
            type: Boolean,
            default: true,
        },
        /**
         * show penalty change
         */
        showAmount: {
            type: Boolean,
            default: true,
        },

        /**
         * show view button
         * clicking it will trigger "view" event
         */
        showView: {
            type: Boolean,
            default: false,
        },
        /**
         * show remove button
         * clicking it will trigger "remove" event
         */
        showRemove: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        editLink(){
            return {
                name: this.editLinkRoute || 'administrationRankingPenaltiesEdit',
                params: Object.assign({
                    dataPenaltyId: this.penalty.id,
                }, this.editLinkParams),
            };
        },
        emitEventData(){
            return {
                penalty: this.penalty || null,
            };
        },
        hasVisibleCircles(){
            return (this.showRemove || this.showMove);
        },
    },
};
</script>
