import PermissionModel from '@/models/permission.js';
import UserModel from '@/models/user.js';

export const features = {
    apiResource: 'Admin/api-resource',
    apiUser: 'Admin/api-user',
    declaration: 'TermsOfParticipation/declaration',
    location: 'Location/location',
    match: 'Match/match',
    matchDeclaration: 'TermsOfParticipation/declaration/match',
    matchDisciplinary: 'Match/match-timeline-disciplinary',
    matchDisciplinaryMatchTimeline: 'Match/match-timeline-disciplinary/match-timeline',
    matchMapping: 'Mapping/match',
    matchOfficial: 'Match/match-official',
    matchTeamPerson: 'Match/match-team-person',
    matchTournament: 'Match/match-tournament',
    person: 'Person/person',
    personPosition: 'Person/position',
    personRole: 'Person/person-role',
    personSchool: 'Person/person-school',
    personStorage: 'Person/storage',
    ranking: 'Ranking/ranking',
    rankingAlteration: 'Ranking/ranking-alteration',
    rankingCalculation: 'Ranking/calculation',
    rankingRun: 'Ranking/run',
    rankingTeam: 'Ranking/team',
    rankingUnion: 'Ranking/team',
    rankingWeighting: 'Ranking/weighting',
    series: 'Tournament/series',
    seriesDeclaration: 'TermsOfParticipation/declaration/series',
    seriesLookups: 'Tournament/lookups/Series',
    seriesTeam: 'Tournament/series-team',
    seriesType: 'Tournament/series-type',
    team: 'Union/team',
    teamDeclaration: 'TermsOfParticipation/team-declaration',
    tournament: 'Tournament/tournament',
    tournamentDeclaration: 'TermsOfParticipation/declaration/tournament',
    tournamentOfficial: 'Tournament/tournament-official',
    tournamentStage: 'Tournament/tournament-stage',
    tournamentStanding: 'Tournament/tournament-standing',
    tournamentStandingType: 'Tournament/standing-type',
    tournamentStandingTypeParameter: 'Tournament/standing-type-parameter',
    tournamentStatus: 'Tournament/tournament-status',
    tournamentStorage: 'Tournament/storage',
    tournamentTeam: 'Tournament/tournament-team',
    tournamentTeamKit: 'Tournament/tournament-team-kit',
    tournamentTeamPerson: 'Tournament/tournament-team-person',
    tournamentType: 'Tournament/tournament-type',
    tournamentTypeGroup: 'Tournament/tournament-type-group',
    union: 'Union/union',
    unionStorage: 'Union/storage',
    unmapped: 'Mapping/unmapped',
    user: 'Admin/user',
    userRole: 'Admin/user-role',
    venue: 'Location/venue',
};

export const actions = {
    create: 'Create',
    delete: 'Delete',
    download: 'Download',
    export: 'Export',
    getAll: 'GetAll',
    retrieve: 'Retrieve',
    retrieveAll: 'RetrieveAll',
    retrieveByEqId: 'RetrieveByEqId',
    retrieveById: 'RetrieveById',
    RetrieveModulePersons: 'RetrieveModulePersons',
    search: 'Search',
    update: 'Update',
    upload: 'Upload',
};

export default {
    mapping: {
        match: {
            retrieve: new PermissionModel(features.matchMapping, 'GetMatchMapping'),
        },
    },
    matches: {
        list: new PermissionModel(features.match, actions.search),
        create: new PermissionModel(features.match, 'CreateMatch'),
        delete: new PermissionModel(features.match, 'DeleteById'),
        detail: new PermissionModel(features.match, actions.retrieveById),
        report: new PermissionModel(features.match, actions.retrieveById),
        stats: new PermissionModel(features.match, actions.retrieveById),
        timeline: new PermissionModel(features.match, actions.retrieveById),
        teamsheets: new PermissionModel(features.match, actions.retrieveById),
        highlights: new PermissionModel(features.match, actions.retrieveById),
        update: new PermissionModel(features.match, [actions.retrieveById, 'UpdateMatch']),
        publishStatus: new PermissionModel(features.match, 'UpdatePublishStatus'),
        tournaments: {
            create: new PermissionModel(features.matchTournament, actions.create),
            delete: new PermissionModel(features.matchTournament, actions.delete),
        },
        officials: {
            create: new PermissionModel(features.matchOfficial, actions.create),
            delete: new PermissionModel(features.matchOfficial, 'DeleteById'),
        },
        disciplinaries: {
            create: new PermissionModel(features.matchDisciplinary, [actions.create, 'CreateFullMatchTimelineDisciplinary']),
        },
    },
    persons: {
        list: new PermissionModel(features.person, actions.search),
        create: new PermissionModel(features.person, actions.create, [
            new PermissionModel(features.personStorage, actions.upload),
        ]),
        forceCreate: new PermissionModel(features.person, 'CreateWithoutValidation', [
            new PermissionModel(features.personStorage, actions.upload),
        ]),
        delete: new PermissionModel(features.person, actions.delete),
        undelete: new PermissionModel(features.person, 'UndoDelete'),
        detail: new PermissionModel(features.person, actions.retrieve),
        update: new PermissionModel(features.person, [actions.retrieve, actions.update], [
            new PermissionModel(features.personStorage, actions.upload),
        ]),
        forceUpdate: new PermissionModel(features.person, [actions.retrieve, 'UpdateWithoutValidation'], [
            new PermissionModel(features.personStorage, actions.upload),
        ]),
        roles: {
            create: new PermissionModel(features.personRole, actions.create),
            detail: new PermissionModel(features.personRole, actions.retrieve),
            update: new PermissionModel(features.personRole, [actions.retrieve, actions.update]),
        },
        schools: {
            create: new PermissionModel(features.person, 'AddSchool'),
            delete: new PermissionModel(features.personSchool, actions.delete),
        },
    },
    series: {
        list: new PermissionModel(features.series, actions.search),
        create: new PermissionModel(features.series, actions.create, [
            new PermissionModel(features.tournamentStorage, actions.upload),
        ]),
        delete: new PermissionModel(features.series, actions.delete),
        detail: new PermissionModel(features.series, actions.retrieve),
        update: new PermissionModel(features.series, [actions.retrieve, actions.update], [
            new PermissionModel(features.tournamentStorage, actions.upload),
        ]),
        publishStatus: new PermissionModel(features.match, 'UpdatePublishStatus'),
        disciplinaries: {
            download: new PermissionModel(features.matchDisciplinary, actions.export),
        },
        standings: {
            generate: new PermissionModel(features.series, 'RecalculateSeriesStandings'),
        },
        tournaments: {
            create: new PermissionModel(features.series, 'TournamentsAdd'),
            delete: new PermissionModel(features.series, 'DeleteTournament'),
        },
        teams: {
            create: new PermissionModel(features.seriesTeam, actions.create),
            delete: new PermissionModel(features.seriesTeam, actions.delete),
            detail: new PermissionModel(features.seriesTeam, actions.retrieve),
            update: new PermissionModel(features.seriesTeam, [actions.retrieve, actions.update]),
        },
    },
    teams: {
        list: new PermissionModel(features.team, actions.search),
        create: new PermissionModel(features.team, actions.create, [
            new PermissionModel(features.unionStorage, actions.upload),
        ]),
        delete: new PermissionModel(features.team, actions.delete),
        detail: new PermissionModel(features.team, actions.retrieve),
        update: new PermissionModel(features.team, [actions.retrieve, actions.update], [
            new PermissionModel(features.unionStorage, actions.upload),
        ]),
        aliases: {
            create: new PermissionModel(features.team, 'CreateAlias'),
            update: new PermissionModel(features.team, 'UpdateAliases'),
        },
    },
    tournaments: {
        list: new PermissionModel(features.tournament, actions.search),
        create: new PermissionModel(features.tournament, actions.create, [
            new PermissionModel(features.tournamentStorage, actions.upload),
        ]),
        delete: new PermissionModel(features.tournament, actions.delete),
        detail: new PermissionModel(features.tournament, actions.retrieve),
        update: new PermissionModel(features.tournament, [actions.retrieve, actions.update], [
            new PermissionModel(features.tournamentStorage, actions.upload),
        ]),
        publishStatus: new PermissionModel(features.match, 'UpdatePublishStatus'),
        changeTeamStatuses: new PermissionModel(features.tournament, 'SetTournamentTeamStatuses'),
        matches: {
            create: new PermissionModel(features.match, 'CreateMatch'),
            representing: new PermissionModel(features.tournamentTeam, 'CreateMatchesRepresentationAssign'),
        },
        teams: {
            create: new PermissionModel(features.tournamentTeam, actions.create),
            delete: new PermissionModel(features.tournamentTeam, actions.delete),
            detail: new PermissionModel(features.tournamentTeam, actions.retrieve),
            update: new PermissionModel(features.tournamentTeam, actions.retrieve, [
                new PermissionModel(features.tournamentStorage, actions.upload),
                new PermissionModel(features.tournamentTeamKit, actions.create),
                new PermissionModel(features.tournamentTeamKit, actions.update),
                new PermissionModel(features.tournamentTeamPerson, actions.update),
            ]),
        },
        standings: {
            create: new PermissionModel(features.tournamentStanding, 'CreateStanding'),
            retrieve: new PermissionModel(features.tournamentStanding, 'RetrieveStanding'),
            update: new PermissionModel(features.tournamentStanding, 'UpdateStanding'),
            delete: new PermissionModel(features.tournamentStanding, 'DeleteTournamentStanding'),
            generate: new PermissionModel(features.tournamentStanding, 'RecalculateStanding'),
        },
        officials: {
            create: new PermissionModel(features.tournament, 'AddOfficial'),
            delete: new PermissionModel(features.tournamentOfficial, actions.delete),
        },
        disciplinaries: {
            create: new PermissionModel(features.matchDisciplinary, actions.create),
            //delete: null,
            //update: null,
            download: new PermissionModel(features.matchDisciplinary, actions.export),
        },
    },
    unions: {
        list: new PermissionModel(features.union, actions.search),
        create: new PermissionModel(features.union, actions.create),
        delete: new PermissionModel(features.union, actions.delete),
        detail: new PermissionModel(features.union, actions.retrieve),
        update: new PermissionModel(features.union, [actions.retrieve, actions.update]),
    },
    venues: {
        list: new PermissionModel(features.venue, actions.search),
        create: new PermissionModel(features.venue, actions.create),
        delete: new PermissionModel(features.venue, actions.delete),
        detail: new PermissionModel(features.venue, actions.retrieve),
        update: new PermissionModel(features.venue, [actions.retrieve, actions.update]),
        aliases: {
            create: new PermissionModel(features.venue, 'CreateVenueAlias'),
            update: new PermissionModel(features.venue, 'UpdateVenueAliases'),
        },
    },
    declarations: {
        delete: new PermissionModel(features.declaration, actions.delete),
        series: {
            list: new PermissionModel(features.seriesDeclaration, actions.search, null, UserModel.types.unionAffiliated),
            detail: new PermissionModel(features.seriesDeclaration, actions.retrieve, [
                new PermissionModel(features.seriesLookups, actions.retrieveByEqId),
                new PermissionModel(features.teamDeclaration, [actions.retrieve, actions.RetrieveModulePersons]),
            ]),
        },
        tournaments: {
            list: new PermissionModel(features.tournamentDeclaration, actions.search, null, UserModel.types.unionAffiliated),
            detail: new PermissionModel(features.tournamentDeclaration, actions.retrieve, [
                new PermissionModel(features.tournament, actions.retrieveByEqId),
                new PermissionModel(features.teamDeclaration, [actions.retrieve, actions.RetrieveModulePersons]),
            ]),
        },
        matches: {
            list: new PermissionModel(features.matchDeclaration, actions.search, null, UserModel.types.unionAffiliated),
            detail: new PermissionModel(features.matchDeclaration, actions.retrieve, [
                new PermissionModel(features.match, actions.retrieveByEqId),
                new PermissionModel(features.teamDeclaration, [actions.retrieve, actions.RetrieveModulePersons]),
            ]),
        },
        team: {
            delete: new PermissionModel(features.teamDeclaration, actions.delete),
        }
    },
    reviews: {
        series: {
            list: new PermissionModel(features.seriesDeclaration, actions.search, null, UserModel.types.worldRugby),
            detail: new PermissionModel(features.seriesDeclaration, actions.retrieve, [
                new PermissionModel(features.seriesLookups, actions.retrieveByEqId),
                new PermissionModel(features.teamDeclaration, [actions.retrieve, actions.RetrieveModulePersons]),
            ], UserModel.types.worldRugby),
        },
        tournaments: {
            list: new PermissionModel(features.tournamentDeclaration, actions.search, null, UserModel.types.worldRugby),
            detail: new PermissionModel(features.tournamentDeclaration, actions.retrieve, [
                new PermissionModel(features.tournament, actions.retrieveByEqId),
                new PermissionModel(features.teamDeclaration, [actions.retrieve, actions.RetrieveModulePersons]),
            ], UserModel.types.worldRugby),
        },
        matches: {
            list: new PermissionModel(features.matchDeclaration, actions.search, null, UserModel.types.worldRugby),
            detail: new PermissionModel(features.matchDeclaration, actions.retrieve, [
                new PermissionModel(features.match, actions.retrieveByEqId),
                new PermissionModel(features.teamDeclaration, [actions.retrieve, actions.RetrieveModulePersons]),
            ], UserModel.types.worldRugby),
        },
    },
    locations: {
        list: new PermissionModel(features.location, actions.search),
        create: new PermissionModel(features.location, actions.create),
        delete: new PermissionModel(features.location, actions.delete),
        update: new PermissionModel(features.location, [actions.retrieve, actions.update]),
    },
    tournamentTypes: {
        list: new PermissionModel(features.tournamentType, actions.search),
        create: new PermissionModel(features.tournamentType, actions.create),
        delete: new PermissionModel(features.tournamentType, actions.delete),
        detail: new PermissionModel(features.tournamentType, actions.retrieve),
        update: new PermissionModel(features.tournamentType, [actions.retrieve, actions.update]),
    },
    tournamentTypeGroups: {
        list: new PermissionModel(features.tournamentTypeGroup, actions.search),
        create: new PermissionModel(features.tournamentTypeGroup, actions.create),
        delete: new PermissionModel(features.tournamentTypeGroup, actions.delete),
        update: new PermissionModel(features.tournamentTypeGroup, [actions.retrieve, actions.update]),
    },
    tournamentTeamStatusTypes: {
        list: new PermissionModel(features.tournamentStatus, 'GetTournamentTeamStatus'),
    },
    tournamentStages: {
        list: new PermissionModel(features.tournamentStage, actions.retrieveAll),
        create: new PermissionModel(features.tournamentStage, actions.create),
        delete: new PermissionModel(features.tournamentStage, actions.delete),
        update: new PermissionModel(features.tournamentStage, [actions.retrieveById, actions.update]),
    },
    tournamentStandingTypes: {
        list: new PermissionModel(features.tournamentStandingType, actions.search),
        create: new PermissionModel(features.tournamentStandingType, actions.create),
        delete: new PermissionModel(features.tournamentStandingType, actions.delete),
        detail: new PermissionModel(features.tournamentStandingType, actions.retrieve),
        update: new PermissionModel(features.tournamentStandingType, [actions.retrieve, actions.update]),
        pointParameters: {
            create: new PermissionModel(features.tournamentStandingTypeParameter, 'CreatePointsParameter'),
            delete: new PermissionModel(features.tournamentStandingTypeParameter, 'DeleteParameter'),
        },
        orderParameters: {
            create: new PermissionModel(features.tournamentStandingTypeParameter, 'CreateOrderParameter'),
            delete: new PermissionModel(features.tournamentStandingTypeParameter, 'DeleteParameter'),
            update: new PermissionModel(features.tournamentStandingTypeParameter, 'UpdateParameterOrder'),
        },
    },
    apiConsumer: {
        list: new PermissionModel(features.apiUser, actions.getAll),
        create: new PermissionModel(features.apiUser, actions.create),
        delete: new PermissionModel(features.apiUser, actions.delete),
        update: new PermissionModel(features.apiUser, [actions.retrieve, actions.update]),
    },
    permissionGroups: {
        list: new PermissionModel(features.userRole, actions.getAll),
        create: new PermissionModel(features.userRole, actions.create, [
            new PermissionModel(features.apiResource, actions.retrieveAll),
        ]),
        delete: new PermissionModel(features.userRole, actions.delete),
        update: new PermissionModel(features.userRole, [actions.retrieve, actions.update], [
            new PermissionModel(features.apiResource, actions.retrieveAll),
        ]),
    },
    users: {
        list: new PermissionModel(features.user, actions.search),
        update: new PermissionModel(features.user, actions.retrieveById),
    },
    seriesTypes: {
        list: new PermissionModel(features.seriesType, actions.retrieveAll),
        create: new PermissionModel(features.seriesType, actions.create),
        delete: new PermissionModel(features.seriesType, actions.delete),
        update: new PermissionModel(features.seriesType, [actions.retrieve, actions.update]),
    },
    managementPositions: {
        list: new PermissionModel(features.personPosition, actions.search),
    },
    rankings: {
        list: new PermissionModel(features.ranking, 'GetLatestRankings', [
            new PermissionModel(features.rankingRun, 'GetRankingRun'),
        ]),
        delete: new PermissionModel(features.rankingRun, 'DeleteRun'),
        generate: new PermissionModel(features.rankingRun, 'RunCalculation'),
        inactivity: new PermissionModel(features.rankingTeam, 'RetrieveInactivity'),
        predict: new PermissionModel(features.rankingRun, 'Predict', [
            new PermissionModel(features.match, actions.search),
        ]),
        penalties: {
            list: new PermissionModel(features.rankingAlteration, actions.retrieveAll),
            create: new PermissionModel(features.rankingAlteration, actions.create),
            delete: new PermissionModel(features.rankingAlteration, actions.delete),
            update: new PermissionModel(features.rankingAlteration, [actions.retrieveById, actions.update]),
        },
        calculations: {
            calculationRating: {
                list: new PermissionModel(features.rankingCalculation, actions.retrieveAll),
                create: new PermissionModel(features.rankingCalculation, actions.create),
                delete: new PermissionModel(features.rankingCalculation, actions.delete),
                update: new PermissionModel(features.rankingCalculation, [actions.retrieve, actions.update]),
            },
        },
        teams: {
            list: new PermissionModel(features.rankingUnion, actions.retrieveAll),
            create: new PermissionModel(features.rankingUnion, actions.create),
            update: new PermissionModel(features.rankingUnion, [actions.retrieve, actions.update]),
        },
        weightings: {
            list: new PermissionModel(features.rankingWeighting, actions.retrieveAll),
            create: new PermissionModel(features.rankingWeighting, actions.create),
        },
    },
    mergePersons: {
        merge: new PermissionModel(features.person, 'MergePersons'),
    },
    unmapped: {
        list: new PermissionModel(features.unmapped, actions.search),
        create: new PermissionModel(features.unmapped, actions.create),
        delete: new PermissionModel(features.unmapped, actions.delete),
    },
};
