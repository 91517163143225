<template>
    <div class="pageContent">
        <personList
            v-bind:title="$tc('person', 2)"
            mainTitle
            v-bind:rowProps="rowProps"
            v-bind:rowRemovePermission="permissions.persons.delete"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="success"
                    v-bind:to="{ name: 'commonPersonCreate' }"
                >
                    {{ $t('createPerson') }}
                </actionButton>
            </template>
        </personList>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import personList from '@/components/lists/person.vue';

export default {
    name: 'commonPersonIndex',
    extends: base,
    components: {
        personList,
    },
    data(){
        return {
            rowProps: {
                showDateOfBirth: true,
                showSport: true,
                showRole: true,
                showTeam: true,
                showDateFrom: true,
                showDateUntil: true,
                showActionsRoleToggle: true,
            },
        };
    },
};
</script>
