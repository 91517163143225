<script>
import personList from './person.vue';
import playerRow from '@/components/rows/player.vue';
import PlayerModel from '@/models/person/player.js';
import constants from '@/constants.js';

/**
 * complex player list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'playerList',
    extends: personList,
    components: {
        playerRow,
    },
    data(){
        return {
            //overwrites
            rowElement: playerRow,
            ItemModel: PlayerModel,
            personRole: constants.personRoleType.player,
        };
    },
};
</script>
