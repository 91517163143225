import Model from '@/models/model.js';
import TournamentStandingTypeParameterModel from '@/models/tournamentStandingTypeParameter.js';

/**
 * TournamentType model class
 */
export default class TournamentStandingTypeModel extends Model {
    /**
     * id
     *
     * @type {number}
     */
    id = 0;

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * sport
     *
     * @type {string}
     */
    sport = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.name = data.name || '';
            this.pointParameters = (data.parameters || []).filter(element => element.group === 'P').map(element => new TournamentStandingTypeParameterModel(element));
            this.orderParameters = this.sortedOrderParameters(data);
            this.sport = data.sport || '';
        }
    }

    sortedOrderParameters(data){
        const givenOrderParameters = (data.parameters || []).filter(element => element.group === 'O').map(element => new TournamentStandingTypeParameterModel(element));
        var sortBy = require('lodash.sortby');

        return sortBy(givenOrderParameters, 'value');
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            name: this.name || '',
            sport: this.sport || null,
        };
    }
}
