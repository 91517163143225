import Model from '@/models/model.js';
import RatingChangeModel from '@/models/ratingChange.js';

export default class CalculationModel extends Model {
    /**
     * @type {RatingChangeModel[]}
     */
    ratingChanges = [];

    startDate = null;

    endDate = null;

    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || 0;
            this.homeTeamPointsBoost = data.homeTeamPointsBoost || 0;
            this.winByMoreThreshold = data.winByMoreThreshold || 0;
            this.winByMoreMultiplier = data.winByMoreMultiplier || 0;
            this.startDate = this.toDate(data.startDate) || null;
            this.endDate = this.toDate(data.endDate) || null;
            this.ratingChanges = (data.ratingChanges || []).map(data => RatingChangeModel.from(data));
            this.sport = data.sport || '';

            this.winLowerPointGapLimit = null;
            this.winLowerRatingChange = null;
            this.winUpperPointGapLimit = null;
            this.winUpperRatingChange = null;

            this.loseLowerPointGapLimit = null;
            this.loseLowerRatingChange = null;
            this.loseUpperPointGapLimit = null;
            this.loseUpperRatingChange = null;

            this.drawLowerPointGapLimit = null;
            this.drawLowerRatingChange = null;
            this.drawUpperPointGapLimit = null;
            this.drawUpperRatingChange = null;

            if (this.ratingChanges && this.ratingChanges.length) {
                const winRatingChanges = this.ratingChanges.find(ratingChange => ratingChange.resultType === 'W');
                if (winRatingChanges) {
                    this.winLowerPointGapLimit = winRatingChanges.lowerPointGapLimit;
                    this.winLowerRatingChange = winRatingChanges.lowerRatingChange;
                    this.winUpperPointGapLimit = winRatingChanges.upperPointGapLimit;
                    this.winUpperRatingChange = winRatingChanges.upperRatingChange;
                }

                const loseRatingChanges = this.ratingChanges.find(ratingChange => ratingChange.resultType === 'L');
                if (loseRatingChanges) {
                    this.loseLowerPointGapLimit = loseRatingChanges.lowerPointGapLimit;
                    this.loseLowerRatingChange = loseRatingChanges.lowerRatingChange;
                    this.loseUpperPointGapLimit = loseRatingChanges.upperPointGapLimit;
                    this.loseUpperRatingChange = loseRatingChanges.upperRatingChange;
                }

                const drawRatingChanges = this.ratingChanges.find(ratingChange => ratingChange.resultType === 'D');
                if (drawRatingChanges) {
                    this.drawLowerPointGapLimit = drawRatingChanges.lowerPointGapLimit;
                    this.drawLowerRatingChange = drawRatingChanges.lowerRatingChange;
                    this.drawUpperPointGapLimit = drawRatingChanges.upperPointGapLimit;
                    this.drawUpperRatingChange = drawRatingChanges.upperRatingChange;
                }
            }
        }
    }

    toJSON(){
        return {
            homeTeamPointsBoost: this.homeTeamPointsBoost,
            winByMoreThreshold: this.winByMoreThreshold,
            winByMoreMultiplier: this.winByMoreMultiplier,
            startDate: this.fromDate(this.startDate),
            endDate: this.fromDate(this.endDate),
            ratingChanges: [{
                resultType: 'W',
                lowerPointGapLimit: this.winLowerPointGapLimit,
                lowerRatingChange: this.winLowerRatingChange,
                upperPointGapLimit: this.winUpperPointGapLimit,
                upperRatingChange: this.winUpperRatingChange,
            },
            {
                resultType: 'L',
                lowerPointGapLimit: this.loseLowerPointGapLimit,
                lowerRatingChange: this.loseLowerRatingChange,
                upperPointGapLimit: this.loseUpperPointGapLimit,
                upperRatingChange: this.loseUpperRatingChange,
            },
            {
                resultType: 'D',
                lowerPointGapLimit: this.drawLowerPointGapLimit,
                lowerRatingChange: this.drawLowerRatingChange,
                upperPointGapLimit: this.drawUpperPointGapLimit,
                upperRatingChange: this.drawUpperRatingChange,
            }],
            sport: this.sport,
        };
    }
}
