<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'changeTeamsSheetsPublishStatusForm',
    extends: formComponent,
    props: {
        team1Name: {
            type: String,
            default: null,
        },
        team2Name: {
            type: String,
            default: null,
        },
    },
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'team1SheetPublishStatus',
                        type: 'vselectPublishStatus',
                        label: this.team1Name,
                        required: true,
                    },
                    {
                        id: 'team2SheetPublishStatus',
                        type: 'vselectPublishStatus',
                        label: this.team2Name,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
