<script>
import wrapper from '@/components/wrapper.vue';
import list from '@/components/list.vue';
import actionButton from '@/components/actionButton.vue';

import constants from '@/constants.js';
import permissions from '@/permissions.js';

export default {
    name: 'basePage',
    components: {
        wrapper,
        list,
        actionButton,
    },
    data(){
        return {
            apiData: {},
            constants,
            permissions,
        };
    },
    methods: {
        hideElement(index){
            if(!this.subnavigation){
                return false;
            }

            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },
        printSegment(index = null){
            //if there is no subnavigation just print whole page
            if(!this.subnavigation){
                this.printPage();
                return;
            }

            //get current active segment and scroll information
            const activeIndex = this.subnavigation.active;
            const currentScroll = {
                x: window.pageXOffset,
                y: window.pageYOffset,
            };

            //show segment
            this.subnavigation.active = index;

            //wait one tick
            this.$nextTick(() => {
                //then show print view and reset active segment to previous value
                window.print();
                this.subnavigation.active = activeIndex;

                //another tick later reset scroll
                this.$nextTick(() => {
                    window.scrollTo(currentScroll.x, currentScroll.y);
                });
            });
        },
        printPage(){
            window.print();
        },
    },
    computed: {
    },
    watch: {
        'subnavigation.active'(){
            //on subnavigation change: wait for UI update tick, then trigger scroll event to force lazy loading checks
            this.$nextTick(() => {
                window.dispatchEvent(new CustomEvent('scroll'));
            });
        },
        dataPersonId() {
            if (typeof this.reloadPerson === 'function') {
                this.reloadPerson();
            }
        },
        dataTeamId() {
            if (typeof this.reloadTeam === 'function') {
                this.reloadTeam();
            }
        },
        dataTournamentId() {
            if (typeof this.reloadTournament === 'function') {
                this.reloadTournament();
            }
        },
        dataSeriesId() {
            if (typeof this.reloadSeries === 'function') {
                this.reloadSeries();
            }
        },
        dataUnionId() {
            if (typeof this.reloadUnion === 'function') {
                this.reloadUnion();
            }
        },
        dataVenueId() {
            if (typeof this.reloadVenue === 'function') {
                this.reloadVenue();
            }
        },
    },
    created(){
        //defined for children to override
    },
};
</script>
