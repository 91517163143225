<script>
import itemList from '@/components/itemList.vue';
import seriesRow from '@/components/rows/series.vue';
import SeriesModel from '@/models/series.js';
import constants from '@/constants';

/**
 * complex team list component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'seriesList',
    extends: itemList,
    components: {
        seriesRow,
    },
    data(){
        return {
            //overwrites
            rowElement: seriesRow,
            rowItemPropName: 'series',
            selectItemProp: 'series',
            //sortKey: 'name',
            //sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return {
                showDetail: true,
                showPublishStatus: true,
            };
        },
        listFilterConfig(){
            return this.filterDisplayCheck([
                {
                    id: 'name',
                    type: 'text',
                    placeholder: this.$t('searchBySeries'),
                },
                {
                    id: 'sport',
                    type: 'vselectSport',
                    placeholder: this.$t('filterBySport'),
                    filter: Object.values(constants.sevenSport),
                    //hidden: !this.combinedRowProps.showSport,
                },
                {
                    id: 'type',
                    type: 'vselectSeriesType',
                    placeholder: this.$t('filterBySeriesType'),
                },
                {
                    id: 'mainSeason',
                    type: 'number',
                    min: constants.inputMainYear.min,
                    max: constants.inputMainYear.max,
                    default: constants.inputMainYear.default,
                    placeholder: this.$t('filterByMainYear'),
                    //hidden: !this.combinedRowProps.showMainSeason,
                },
            ]);
        },
    },
    methods: {
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //load series
            return this.$api.call.tournament.seriesSearch({
                name: filters.name ? '%' + filters.name + '%' : null,
                sport: filters.sport || null,
                seriesTypeId: filters.type || null,
                year: filters.mainSeason || null,
                paging: this.usePagination(pageIndex, pageSize, sortKey),
            }).then(result => ({
                items: this.sortItems(
                    (result.series || result.items || []).map(data => new SeriesModel(data)),
                    sortKey,
                    sortAsc,
                    pageIndex,
                    pageSize,
                ),
                total: (result.paging ? result.paging.totalRecordCount : null),
            }));
        },
    },
};
</script>
