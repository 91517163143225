<script>
import formComponent from '@/components/form.vue';
import DeclarationTOPModel from '@/models/declarationTOP.js';
import constants from '@/constants';

const uploadDependencies = DeclarationTOPModel.joinFormDependencies([
    DeclarationTOPModel.formDependencies.participationAgreementEnabled,
    DeclarationTOPModel.formDependencies.teamLogoEnabled,
    DeclarationTOPModel.formDependencies.nationalAnthemEnabled,
    DeclarationTOPModel.formDependencies.teamDisciplinaryCertificateEnabled,
    DeclarationTOPModel.formDependencies.teamMemberAcceptanceFormEnabled,
    DeclarationTOPModel.formDependencies.teamPhoneticGuideEnabled,
]);

/**
 * component
 */
export default {
    name: 'createEditSeriesTopForm',
    extends: formComponent,
    props: {
        model: {
            type: DeclarationTOPModel,
        },
        sport: {
            type: String,
            default: null,
        },
        isFixed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'enabled',
                        type: 'checkbox',
                        text: this.$t('applyTermsOfParticipationRegulations'),
                        disabled: this.isFixed || this.readonly,
                    },
                ]),
                this.createFormRow([
                    {
                        id: 'workflowId',
                        type: 'vselectWorkflow',
                        label: this.$t('declarationType'),
                        disabled: this.isFixed || this.readonly,
                        required: true,
                        data: {
                            type: 'Series',
                            sport: this.sport,
                        },
                    },
                ], DeclarationTOPModel.formDependencies.enabled),
                this.createFormRow([
                    {
                        id: 'openingDate',
                        type: 'date',
                        label: this.$t('openingOfDeclaration'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'deadlineDate',
                        type: 'date',
                        label: this.$t('deadlineOfDeclaration'),
                        placeholder: constants.dateFormat,
                        disabled: this.readonly,
                        required: true,
                    },
                ], DeclarationTOPModel.formDependencies.workflowId),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
                {
                    type: 'headline',
                    text: this.$t('squadAnnouncement'),
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            id: 'squadEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            disabled: true,
                        },
                        {
                            id: 'squadDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.workflowId),
                this.createFormRow([
                    {
                        id: 'squadMinimumNumberOfPlayers',
                        type: 'number',
                        label: this.$t('minimumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'squadMaximumNumberOfPlayers',
                        type: 'number',
                        label: this.$t('maximumNumberOfPlayers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled),
                this.createFormRow([
                    {
                        id: 'squadMinimumNumberOfManagers',
                        type: 'number',
                        label: this.$t('minimumNumberOfManagers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'squadMaximumNumberOfManagers',
                        type: 'number',
                        label: this.$t('maximumNumberOfManagers'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled),
                this.createFormRow([
                    {
                        id: 'squadMinimumNumberOfMedics',
                        type: 'number',
                        label: this.$t('minimumNumberOfMedics'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'squadMaximumNumberOfMedics',
                        type: 'number',
                        label: this.$t('maximumNumberOfMedics'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled),
                this.createFormRow([
                    {
                        id: 'squadMinimumTotalNumberOfManagement',
                        type: 'number',
                        label: this.$t('minimumTotalNumberOfManagement'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'squadMaximumTotalNumberOfManagement',
                        type: 'number',
                        label: this.$t('maximumTotalNumberOfManagement'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                ], DeclarationTOPModel.formDependencies.squadEnabled),
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('personHeadshots'),
                        },
                        {
                            id: 'squadPersonHeadshotsEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadPersonHeadshotsDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadPersonHeadshotsEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('passportInformation'),
                        },
                        {
                            id: 'squadPassportInformationEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadPassportInformationDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadPassportInformationEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('medicalCertificates'),
                        },
                        {
                            id: 'squadMedicalCertificatesEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadMedicalCertificatesDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadMedicalCertificatesEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('biographyAndPhoneticGuide'),
                        },
                        {
                            id: 'squadBioEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadBioDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadBioEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('personPositions'),
                        },
                        {
                            id: 'squadPersonPositionEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'squadPersonPositionDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.squadPersonPositionEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.squadEnabled),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('participationAgreement'),
                        },
                        {
                            id: 'participationAgreementEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'participationAgreementDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.participationAgreementEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamLogo'),
                        },
                        {
                            id: 'teamLogoEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamLogoDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamLogoEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('nationalAnthem'),
                        },
                        {
                            id: 'nationalAnthemEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'nationalAnthemDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.nationalAnthemEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('medicalInsuranceCover'),
                        },
                        {
                            id: 'medicalInsuranceCoverEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'medicalInsuranceCoverDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.medicalInsuranceCoverEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamDisciplinaryCertificate'),
                        },
                        {
                            id: 'teamDisciplinaryCertificateEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamDisciplinaryCertificateDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamDisciplinaryCertificateEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamMemberAcceptanceForm'),
                        },
                        {
                            id: 'teamMemberAcceptanceFormEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamMemberAcceptanceFormDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamMemberAcceptanceFormEnabled,
                        },
                    ]),
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('teamPhoneticGuide'),
                        },
                        {
                            id: 'teamPhoneticGuideEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'teamPhoneticGuideDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.teamPhoneticGuideEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.workflowId),

                {
                    type: 'hline',
                    dependency: DeclarationTOPModel.formDependencies.workflowId,
                },
                this.createFormRow([
                    this.createFormRowColumn([
                        {
                            type: 'headline',
                            text: this.$t('kitDeclaration'),
                        },
                        {
                            id: 'kitEnabled',
                            type: 'checkbox',
                            text: this.$t('enableModule'),
                            plaintext: this.readonly,
                            disabled: this.readonly,
                        },
                        {
                            id: 'kitDeadline',
                            type: 'date',
                            label: this.$t('deadlineToSubmit'),
                            placeholder: constants.dateFormat,
                            disabled: this.readonly,
                            required: true,
                            dependency: DeclarationTOPModel.formDependencies.kitEnabled,
                        },
                    ]),
                ], DeclarationTOPModel.formDependencies.workflowId),

                {
                    type: 'hline',
                    dependency: uploadDependencies,
                },
                {
                    type: 'headline',
                    text: this.$t('uploadForTOPTemplates'),
                    dependency: uploadDependencies,
                },
                this.createFormRow([
                    {
                        id: 'participationAgreementTemplate',
                        type: 'file',
                        label: this.$t('participationAgreement'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.participationAgreementEnabled,
                    },
                    {
                        id: 'teamLogoTemplate',
                        type: 'file',
                        label: this.$t('teamLogo'),
                        accept: constants.fileAcceptance.images,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamLogoEnabled,
                    },
                    {
                        id: 'nationalAnthemTemplate',
                        type: 'file',
                        label: this.$t('nationalAnthem'),
                        accept: constants.fileAcceptance.documentAndAudio,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.nationalAnthemEnabled,
                    },
                    {
                        id: 'teamDisciplinaryCertificateTemplate',
                        type: 'file',
                        label: this.$t('disciplinaryCertificate'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamDisciplinaryCertificateEnabled,
                    },
                    {
                        id: 'teamMemberAcceptanceFormTemplate',
                        type: 'file',
                        label: this.$t('teamMemberAcceptanceForm'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamMemberAcceptanceFormEnabled,
                    },
                    {
                        id: 'teamPhoneticGuideTemplate',
                        type: 'file',
                        label: this.$t('teamPhoneticGuide'),
                        accept: constants.fileAcceptance.imagesAndPdf,
                        disabled: this.readonly,
                        dependency: DeclarationTOPModel.formDependencies.teamPhoneticGuideEnabled,
                    },
                ], uploadDependencies),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
        errorIdMap(){
            return {
                declarationWorkflowId: 'workflowId',
            };
        },
    },
};
</script>
