<script>
import vselectElement from '../vselect.vue';
import ActionTypeSetModel from '@/models/actionTypeSet.js';

/**
 * vselect element for ActionTypeType items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectActionTypeSetElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            // add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //request loads all entries, there is no pagination here
                if(offset > 0){
                    return Promise.resolve(null);
                }

                //force reloading options when sport changes
                this.addOptionReloadListener('dataAllValues.sport', true);

                return this.$api.call.match.actionTypeSetGetAll().then(result =>
                    ((result || [])
                        .map(data => new ActionTypeSetModel(data)))
                        .filter(item => (!this.dataAllValues.sport || item.sport === this.dataAllValues.sport))
                );
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.match.actionTypeSetGetById(id)))
                            .then(results => results.map(data => new ActionTypeSetModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
