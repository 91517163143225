<template>
    <component
        v-bind:is="tag || 'div'"
        class="loadingWrapper"
        v-bind:data-state="state"
        v-if="!waitHidden || state === states.complete || (state === states.error && !waitSilent && !waitSilentError)"
    >
        <!--
        @slot Content to be shown when loading complete
        @binding {*} result Loading promise result
        -->
        <slot
            v-bind:result="result"
            v-if="complete"
        />
        <!--
        @slot Replace loading icon
        -->
        <slot
            name="loading"
            v-bind:throbberProps="throbberProps"
            v-if="state === states.loading && !waitSilent && !waitSilentLoading"
        >
            <throbber v-bind="throbberProps"/>
        </slot>
        <!--
        @slot Replace loading error message
        @binding {Error} error Loading promise error
        @binding {function|null} retryAction Method to be executed to retry the loading
        -->
        <slot
            name="error"
            v-bind:error="error"
            v-bind:retryAction="waitForRetry"
            v-if="state === states.error && !waitSilent && !waitSilentError"
        >
            <loadErrorSnippet
                v-bind:trigger="errorIs404 ? undefined : waitForRetry"
                v-bind:message="errorMessage"
            />
        </slot>
    </component>
</template>

<script>
import throbber from '@/components/throbber.vue';
import loadErrorSnippet from '@/components/snippets/loadError.vue';
import { RequestError } from '@/errors.js';

export default {
    name: 'loading',
    components: {
        throbber,
        loadErrorSnippet,
    },
    props: {
        /**
         * promise to wait for before showing default slot content
         */
        waitFor: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * method to bind to click action on error msg retry button
         * if not defined retry button is not shown in error slot default content
         */
        waitForRetry: {
            type: Function,
            required: false,
            default: null,
        },
        /**
         * stay silent while promise is loading or rejected (no loading icon/slot or error msg/slot)
         */
        waitSilent: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * stay silent while promise is loading (no loading icon/slot)
         */
        waitSilentLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * stay silent while promise is loading or rejected (no error msg/slot)
         */
        waitSilentError: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * fully hide while waiting
         */
        waitHidden: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * wrapper element tag
         */
        tag: {
            type: String,
            required: false,
            default: 'div',
        },
        /**
         * props for loading slot default throbber
         */
        throbberProps: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    data(){
        return {
            complete: false,
            waitingFor: null,
            result: null,
            error: null,
            states: {
                loading: 'loading',
                complete: 'complete',
                error: 'error',
            },
        };
    },
    methods: {
        waitForPromise(promise){
            //reset variables
            this.complete = false;
            this.waitingFor = null;
            this.result = null;
            this.error = null;

            //if no promise given, set as complete
            if(promise === null){
                this.complete = true;

                return;
            }

            //if promise given, wait for it
            this.complete = false;
            this.waitingFor = promise;

            //waiting...
            promise.then(result => {
                //gather success information
                return {
                    complete: true,
                    result: result,
                };
            }, error => {
                //gather error information
                return {
                    complete: false,
                    error: error,
                };
            }).then(data => {
                //make sure the promise did not change in the meantime
                if(this.waitingFor !== promise){
                    return;
                }

                //update variables with the gathered information
                this.complete = data.complete || false;
                this.waitingFor = null;
                this.result = data.result || null;
                this.error = data.error || null;
            });
        },
    },
    computed: {
        state(){
            if(this.complete){
                return this.states.complete;
            }
            if(this.waitingFor){
                return this.states.loading;
            }
            return this.states.error;
        },
        errorMessage() {
            if(this.error instanceof RequestError && this.error.status === 403){
                return this.$t('userHasNoPermission');
            }
            if(this.errorIs404){
                return this.$t('notFoundError');
            }
            return null;
        },
        errorIs404(){
            return (this.error instanceof RequestError && this.error.status === 404);
        },
    },
    watch: {
        waitFor(promise){
            this.waitForPromise(promise);
        },
    },
    created(){
        this.waitForPromise(this.waitFor);
    },
    destroyed(){
        this.waitForPromise(null);
    },
}
</script>
