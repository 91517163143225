<script>
import formComponent from '@/components/form.vue';
import TournamentTypeGroupModel from '@/models/tournamentTypeGroup.js';

/**
 * component
 */
export default {
    name: 'createTournamentTypeGroupForm',
    extends: formComponent,
    props: {
        model: {
            type: TournamentTypeGroupModel,
        },
    },
    methods: {
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'name',
                        type: 'text',
                        label: this.$t('name'),
                        placeholder: this.$t('enterName'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        required: true,
                    },
                    {
                        id: 'sport',
                        type: 'vselectSport',
                        label: this.$tc('sport', 1),
                        placeholder: this.$t('selectSport'),
                        disabled: this.readonly,
                        required: true,
                    },
                ]),
            ];
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
