<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="$t('editCalculation')"
            v-bind:waitFor="apiData.calculation"
            v-bind:waitForRetry="reloadCalculation"
            v-on:submit="updateCalculation"
            mainTitle
            isForm
            doubleButtons
        >
            <template v-slot:buttons>
                <actionButton
                    v-bind:to="toCalculationList"
                    v-bind:loadingWhile="apiData.calculationUpdate"
                >
                    {{ $t('cancel') }}
                </actionButton>
                <actionButton
                    type="submit"
                    v-bind:loadingWhile="apiData.calculationUpdate"
                >
                    {{ $t('save') }}
                </actionButton>
            </template>
            <template v-slot:header v-if="error">
                <validations v-bind:errors="error.getErrorMessages($t('requestError'))" v-if="error"/>
            </template>
            <template v-slot>
                <createEditRankingCalculationForm
                    v-bind:model="calculation"
                    v-bind:errors="error ? error.getErrorIds() : undefined"
                />
            </template>
        </wrapper>
    </div>
</template>

<script>
import rankingBase from '@/views/administration/rankings/base.vue';
import wrapper from '@/components/wrapper.vue';
import validations from '@/components/validations.vue';
import actionButton from '@/components/actionButton.vue';
import createEditRankingCalculationForm from '@/components/forms/createEditRankingCalculation.vue';

import CalculationModel from '@/models/calculations.js';

export default {
    name: 'administrationRankingCalculationUnionEdit',
    extends: rankingBase,
    components: {
        wrapper,
        validations,
        actionButton,
        createEditRankingCalculationForm
    },
    props: {
        dataCalculationId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                calculation: this.loadCalculation(),
                calculationUpdate: null,
            },
            calculation: null,
            error: null,
            rowProps: {
            },
        };
    },
    computed: {
        toCalculationList(){
            return { name: 'administrationRankingCalculationsIndex' };
        },
    },
    methods: {
        loadCalculation(){
            return this.$api.call.rankings.calculationGetById(this.dataCalculationId).then(data => {
                this.calculation = CalculationModel.from(data);
            });
        },
        reloadCalculation(){
            this.apiData.calculation = this.loadCalculation();
        },
        updateCalculation() {
            this.calculation.sport = this.sportId;

            this.apiData.calculationUpdate = this.$api.call.rankings.calculationUpdateById(this.dataCalculationId, this.calculation.toJSON()).then(result => {
                this.error = null;
                this.$router.push(this.toCalculationList);
            }).catch(error => {
                if(this.$log){
                    this.$log.warn('calculation update failed', error);
                }

                //show validation errors
                this.error = error;
            });
        },
    },
}
</script>
