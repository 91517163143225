<script>
import declarationPersonPickModal from './person.vue';
import declarationPlayerList from '@/components/lists/declaration/player.vue';

/**
 * declaration player pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'declarationPlayerPickModal',
    extends: declarationPersonPickModal,
    components: {
        declarationPlayerList,
    },
    data(){
        return {
            listElement: declarationPlayerList,
        };
    },
};
</script>
