import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonTournamentTeamCreate from '@/views/common/tournament/team/create.vue';
import commonTournamentTeamDetail from '@/views/common/tournament/team/detail.vue';
import commonTournamentTeamEdit from '@/views/common/tournament/team/edit.vue';

export default {
    path: 'team/',
    component: pageFrame,
    children: [
        {
            name: 'commonTournamentTeamCreate',
            path: constants.routePaths.create,
            component: commonTournamentTeamCreate,
            props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
            meta: {
                permission: permissions.tournaments.teams.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.tournamentTeamId),
            component: pageFrame,
            children: [
                {
                    name: 'commonTournamentTeamDetail',
                    path: constants.routePaths.index,
                    component: commonTournamentTeamDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId, constants.routeProps.tournamentTeamId]),
                    meta: {
                        permission: permissions.tournaments.teams.detail,
                    },
                },
                {
                    name: 'commonTournamentTeamEdit',
                    path: constants.routePaths.edit,
                    component: commonTournamentTeamEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId, constants.routeProps.tournamentTeamId]),
                    meta: {
                        permission: permissions.tournaments.teams.update,
                    },
                },
            ],
        },
    ],
};
