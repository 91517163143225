import Model from '@/models/model.js';
import SeriesType from '@/models/seriesType.js';
import constants from '../constants';

/**
 * series model class
 */
export default class SeriesModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * sport
     *
     * @type {string}
     */
    sport = '';

    /**
     * season
     *
     * @type {number}
     */
    mainSeason = 0;

    /**
     * mapping providers
     *
     * @type {array}
     */
    mappingProviders = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            let seriesType = null;
            if(typeof data.seriesType === 'object'){
                seriesType = SeriesType.from(data.seriesType);
            }
            else if(Number.isInteger(data.seriesType)){
                seriesType = SeriesType.from({ id: data.seriesType });
            }
            else if(data.seriesTypeId) {
                seriesType = SeriesType.from({ id: data.seriesTypeId });
            }

            this.eqId = data.eqId || data.seriesEqId || '';
            this.id = data.id || data.seriesId || 0;
            this.sport = data.sport || '';
            this.name = data.name || '';
            this.mainSeason = data.mainSeason || '';
            this.hashtag = data.hashtag || '';
            this.seriesType = seriesType;
            this.seriesTypeId = (seriesType || {}).id || null;
            this.standings = data.standings || [];
            this.teams = data.teams || [];
            this.tournaments = data.tournaments || [];
            this.teamEvolution = data.teamEvolution || [];
            this.impactPlayers = data.impactPlayers || [];
            this.publishStatus = data.publishStatus || '';

            this.logoStorage = data.logoUrl || '';
            this.logo = Model._api.call.tournament.resolveStoragePath(this.logoStorage);
        }
    }

    setLogoStorage(logoStorage){
        this.logoStorage = logoStorage;
        this.logo = Model._api.call.tournament.resolveStoragePath(this.logoStorage);
    }

    /**
     * get series logo path
     *
     * @returns {string}
     */
    getImgPath(){
        return this.logo || require('@/assets/img/series.svg');
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            id: this.id || null,
            hashtag: this.hashtag || '',
            impactPlayers: this.impactPlayers,
            mainSeason: this.mainSeason || null,
            name: this.name || '',
            logoUrl: this.logoStorage || null,
            seriesTypeId: this.seriesTypeId || this.seriesType?.id || null,
            sport: this.sport || null,
            standings: this.standings,
            teamEvolution: this.teamEvolution,
            teams: this.teams,
        };
    }

    buildMappingPayloads() {
        if (!this.mappingProviders || !this.mappingProviders.length){
            return [];
        }

        return this.mappingProviders.map(mappingProvider => ({
            provider: mappingProvider.name,
            entity: constants.mappingEntityNames.series,
            id: this[mappingProvider.name.toLowerCase()],
            eqId: this.eqId,
        }));
    }
}
