<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
    >
        <template v-slot="{ formId, submit }">
            <changeTeamsSheetsPublishStatusForm
                v-bind:dataId="formId"
                v-bind:values="formValues"
                v-bind:team1Name="team1Name"
                v-bind:team2Name="team2Name"
                v-bind:errors="errorIds"
                v-on:change="updateTeamsSheetsPublishStatus"
                v-on:submit="submit"
            />
        </template>
    </modal>
</template>

<script>
import base from './base.vue';
import changeTeamsSheetsPublishStatusForm from '@/components/forms/changeTeamsSheetsPublishStatus.vue';

/**
 *
 */
export default {
    name: 'changeMatchTeamsSheetsPublishStatusModal',
    extends: base,
    components: {
        changeTeamsSheetsPublishStatusForm,
    },
    data() {
        return {
            teamsSheetsPublishStatuses: {},
        }
    },
    props: {
        team1SheetPublishStatus: {
            type: String,
            default: null,
        },
        team2SheetPublishStatus: {
            type: String,
            default: null,
        },
        team1Name: {
            type: String,
            default: null,
        },
        team2Name: {
            type: String,
            default: null,
        },
    },
    methods: {
        updateTeamsSheetsPublishStatus(values){
            this.teamsSheetsPublishStatuses.team1SheetPublishStatus = values.team1SheetPublishStatus;
            this.teamsSheetsPublishStatuses.team2SheetPublishStatus = values.team2SheetPublishStatus;
        },
    },
    computed: {
        payload(){
            return this.teamsSheetsPublishStatuses;
        },
        formValues(){
            return {
                team1SheetPublishStatus: this.team1SheetPublishStatus,
                team2SheetPublishStatus: this.team2SheetPublishStatus,
                team1Name: this.team1Name,
                team2Name: this.team2Name,
            };
        },
    },
};
</script>
