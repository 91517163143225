<script>
import pickModal from '@/components/modals/pick.vue';
import tournamentList from '@/components/lists/tournament.vue';

/**
 * tournament pick modal component
 *
 * @author Alexandru Cionca <a.cionca@sportradar.com>
 */
export default {
    name: 'tournamentPickModal',
    extends: pickModal,
    components: {
        tournamentList,
    },
    props: {
        showCancel: {
            type: Boolean,
            required: false,
            default: true,
        },
        showClearSelection: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data(){
        return {
            listElement: tournamentList,
        };
    },
};
</script>
