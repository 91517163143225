<script>
import pickModal from '@/components/modals/pick.vue';
import personList from '@/components/lists/person.vue';

/**
 * person pick modal component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'personPickModal',
    extends: pickModal,
    components: {
        personList,
    },
    props: {
        showCancel: {
            type: Boolean,
            required: false,
            default: true,
        },
        showClearSelection: {
            type: Boolean,
            required: false,
            default: true,
        },
        isFullSearch: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableSportFilter: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data(){
        return {
            listElement: personList,
        };
    },
    computed: {
        defaultRowProps(){
            if(this.isFullSearch){
                return {
                    showDetail: true,
                    showSelect: true,
                    showDateOfBirth: true,
                    disableSportFilter: this.disableSportFilter,
                };
            }

            return {
                showDetail: false,
                showSelect: true,
            };
        },
    },
};
</script>
