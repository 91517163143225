import constants from '@/constants.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import administrationUnmappedIndex from '@/views/administration/unmapped/index.vue';

export default {
    path: 'unmapped/',
    component: pageFrame,
    children: [
        {
            name: 'administrationUnmappedIndex',
            path: constants.routePaths.index,
            component: administrationUnmappedIndex,
            meta: {
                permission: permissions.unmapped.list,
            },
        },
    ],
};
