import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonSeriesTeamCreate from '@/views/common/series/team/create.vue';
import commonSeriesTeamDetail from '@/views/common/series/team/detail.vue';
import commonSeriesTeamEdit from '@/views/common/series/team/edit.vue';

export default {
    path: 'team/',
    component: pageFrame,
    children: [
        {
            name: 'commonSeriesTeamCreate',
            path: constants.routePaths.create,
            component: commonSeriesTeamCreate,
            props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.seriesId]),
            meta: {
                permission: permissions.series.teams.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.seriesTeamId),
            component: pageFrame,
            children: [
                {
                    name: 'commonSeriesTeamDetail',
                    path: constants.routePaths.index,
                    component: commonSeriesTeamDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.seriesId, constants.routeProps.seriesTeamId]),
                    meta: {
                        permission: permissions.series.teams.detail,
                    },
                },
                {
                    name: 'commonSeriesTeamEdit',
                    path: constants.routePaths.edit,
                    component: commonSeriesTeamEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.seriesId, constants.routeProps.seriesTeamId]),
                    meta: {
                        permission: permissions.series.teams.update,
                    },
                },
            ],
        },
    ],
};
