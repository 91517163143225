import Model from '@/models/model.js';

/**
 * team type model class
 */
export default class TeamTypeModel extends Model {
    /**
     * description
     *
     * @type {string}
     */
    description = '';

    /**
     * description camel case
     *
     * @type {string}
     */
    descriptionCamelCase = '';

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);
        if(data){
            this.id = data.id || 0;
            this.description = data.description || '';
            this.descriptionCamelCase = data.description?.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()) || '';
        }
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.description;
    }
}
