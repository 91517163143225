import Model from '@/models/model.js';

/**
 * upload model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class UploadModel extends Model {
    /**
     * string id to differentiate between file controls, inherited id from model cannot be used as it's an int
     *
     * @type {string}
     */
    fileId = '';

    /**
     * upload title
     *
     * @type {string}
     */
    title = '';

    /**
     * upload status
     *
     * @type {string}
     */
    status = '';

    /**
     * upload file
     *
     * @type {File}
     */
    file = null;

    /**
     * file name
     *
     * @type {string}
     */
    fileName = '';

    /**
     * path to file
     *
     * @type {string}
     */
    filePath = '';

    /**
     * file type
     *
     * @type {string}
     */
    fileType = '';

    /**
     * file size
     *
     * @type {number}
     */
    fileSize = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.fileId = data.fileId || '';
            this.title = data.title || '';
            this.status = data.status || '';
            this.file = null;
            this.fileName = data.name || '';
            this.filePath = data.filePath || data.path || '';
            this.fileType = data.type || this.getFileType() || '';
            this.fileSize = data.size || 0;
            this.deadline = this.toDate(data.deadline);
            this.template = data.template || '';
        }
    }

    /**
     * get allowed format list
     *
     * @returns {string[]}
     */
    getFormats(){
        return [];
    }

    setFile(file){
        this.file = file;
    }

    getFileType(){
        if (!this.filePath){
            return '';
        }

        const fileExt = this.filePath.split(/[#?]/)[0].split('.').pop();

        switch (fileExt?.trim().toLowerCase()) {
            case 'mp3':
                return 'audio/mpeg';
            case 'wav':
                return 'audio/wav';
            case 'bmp':
                return 'image/bmp';
            case 'gif':
                return 'image/gif';
            case 'png':
                return 'image/png';
            case 'jpg':
                return 'image/jpeg';
            case 'jpeg':
                return 'image/jpeg';
            default:
                return '';
        }
    }
}
