import Model from '@/models/model.js';

import ApiServiceModel from '@/models/apiService.js';
import IpAddressModel from '@/models/ipAddress.js';
import TournamentModel from '@/models/tournament.js';
import UserRoleModel from '@/models/userRole.js';

/**
 * ApiConsumer model class
 */
export default class ApiConsumerModel extends Model {
    /**
     * id
     *
     * @type {Number}
     */
    id = 0;

    /**
     * name
     *
     * @type {String}
     */
    name = '';

    /**
     * accessDataFrom
     *
     * @type {Date|null}
     */
    accessDataFrom = null;

    /**
     * accessDataTo
     *
     * @type {Date|null}
     */
    accessDataTo = null;

    /**
     * api key
     *
     * @type {String}
     */
    apiKey = '';

    /**
     * @type {ApiServiceModel[]}
     */
    services = [];

    /**
     * authorised tournaments
     *
     * @type {TournamentModel[]}
     */
    authorisedTournaments = [];

    /**
     * whitelisted ip addresses
     *
     * @type {IpAddressModel[]}
     */
    whitelistedIpAddresses = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.accessDataFrom = this.toDate(data.accessDataFrom);
            this.accessDataTo = this.toDate(data.accessDataTo);
            this.allowAccessToAllTournaments = data.allowAccessToAllTournaments || false;
            this.apiKey = data.apiKey || null;
            this.apiSubscriptionType = data.apiSubscriptionType || '';
            this.authorisedTournaments = (data.authorisedTournaments || []).map(data => TournamentModel.from(data));
            this.eqId = data.eqId || '';
            this.id = data.id || data.userAccessId || 0;
            this.name = data.name || '';
            this.role = UserRoleModel.from(data.role || data.userRole);
            this.services = Object.entries(data.services || {}).map(([key, data]) => ApiServiceModel.from(data).setId(key));
            // remove empty services
            this.services.forEach((data, key) => {
                if (data.resources.length === 0) {
                    this.services.splice(key, 1);
                }
            });
            this.tournamentCount = data.tournamentCount || 0;
            this.whitelistedIpAddresses = (data.whitelist || []).map(data => IpAddressModel.from(data));
        }
    }

    getAuthorisedTournaments(){
        return this.authorisedTournaments;
    }

    getServices(){
        return this.services;
    }

    getWhitelistedIpAddresses(){
        return this.whitelistedIpAddresses;
    }

    addAuthorisedTournament(tournament){
        this.authorisedTournaments.push(tournament);
    }

    addAuthorisedTournaments(tournaments){
        tournaments.forEach(tournament => this.addAuthorisedTournament(tournament));
    }

    removeAuthorisedTournament(tournament){
        this.authorisedTournaments = this.authorisedTournaments.filter(tournamentItem => tournamentItem.eqId !== tournament.eqId);
    }

    getResourceMethods(filter = null){
        return this.services.map(service => service.getResourceMethods(filter)).reduce((arr, methods) => arr.concat(methods), []);
    }

    addResourceMethod(apiResourceMethod){
        if(!apiResourceMethod || !apiResourceMethod.resource || !apiResourceMethod.resource.service){
            return false;
        }

        //find service
        let service = this.services.filter(service => service.id === apiResourceMethod.resource.service.id).shift();

        //add if not found
        if(!service){
            service = apiResourceMethod.resource.service.clone();
            service.setResources([]);
            this.services.push(service);
        }

        //tell service to add
        return service.addResourceMethod(apiResourceMethod);
    }

    removeResourceMethod(apiResourceMethod){
        if(!apiResourceMethod || !apiResourceMethod.resource || !apiResourceMethod.resource.service){
            return false;
        }

        //find service
        const service = this.services.filter(service => service.id === apiResourceMethod.resource.service.id).shift();

        //if not found, all is well
        if(!service){
            return true;
        }

        //tell service to remove
        if(!service.removeResourceMethod(apiResourceMethod)){
            return false;
        }

        //if removed and service now empty, remove it
        if(service.getResources().length === 0){
            this.removeService(apiResourceMethod.resource.service);
        }

        return true;
    }

    addWhitelistedIpAddress(ipAddress){
        this.whitelistedIpAddresses.push(ipAddress);
    }

    removeWhitelistedIpAddress(ipAddress){
        this.whitelistedIpAddresses = this.whitelistedIpAddresses.filter(ipAddressItem => {
            if(ipAddress.id){
                return (ipAddressItem.id !== ipAddress.id);
            }
            return (JSON.stringify(ipAddressItem) !== JSON.stringify(ipAddress));
        });
    }

    removeService(serviceItem){
        //find and remove item
        const index = this.services.map(service => service.id).indexOf(serviceItem.id);
        if(index !== -1){
            this.services.splice(index, 1);
        }
    }

    /**
     * update user role
     *
     * @param {object} data
     */
    updateRole(data){
        this.role = UserRoleModel.from(data);
    }

    /**
     * check if feature is allowed for user
     *
     * @param {string} feature
     * @param {string[]} [actions]
     *
     * @returns {boolean}
     */
    isAllowed(feature, actions = []){
        return Boolean(
            this.role &&
            this.role.allows(feature, actions)
        );
    }

    toJSON(create = false) {
        const authorisedTournaments = [];
        this.authorisedTournaments.forEach(tournament => {
            authorisedTournaments.push(tournament.eqId);
        });
        const tournamentCount = authorisedTournaments.length;

        const services = {};
        this.services.forEach(service => {
            services[service.id] = {};
            services[service.id].resources = {};
            services[service.id].resources = service.resources;
        });

        let toSave = {
            name: this.name,
            apiSubscriptionType: this.apiSubscriptionType,
            accessDataFrom: this.fromDate(this.accessDataFrom),
            accessDataTo: this.fromDate(this.accessDataTo),
            allowAccessToAllTournaments: tournamentCount === 0,
            authorisedTournaments: authorisedTournaments,
            tournamentCount: tournamentCount,
            apiKey: this.apiKey,
            whitelist: this.whitelistedIpAddresses,
            services: services,
        };

        if (create) {
            return toSave;
        }

        toSave.id = this.id;
        toSave.eqId = this.eqId.length > 0 ? this.eqId : null;

        return toSave;
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }
}
