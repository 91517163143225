<script>
import vselectElement from '../vselect.vue';
import TournamentTypeGroupModel from '@/models/tournamentTypeGroup.js';

/**
 * vselect element for TournamentTypeGroupModel items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectTournamentTypeGroupElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            //add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                return this.$api.call.tournament.tournamentTypeGroupSearch({
                    name: text,
                    paging: this.usePagination(offset),
                }).then(result => (result.data || []).map(data => new TournamentTypeGroupModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.tournament.tournamentTypeGroupGetById(id)))
                            .then(results => results.map(data => new TournamentTypeGroupModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
