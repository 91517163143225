<template>
    <div class="pageContent">
        <wrapper
            v-bind:title="displayTitle"
        >
            <loadError
                v-bind:message="displayMessage"
                v-bind:trigger="retry"
                class="text-center mt-4 mb-2"
            />
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import loadError from '@/components/snippets/loadError.vue';
import constants from '@/constants.js';

export default {
    name: 'ErrorPage',
    extends: base,
    components: {
        loadError,
    },
    props: {
        /**
         * redirect path property
         */
        redirectPath: {
            type: [String, Array],
            mandatory: false,
            default: null,
        },
        /**
         * redirect query property
         */
        redirectQuery: {
            type: [Object],
            mandatory: false,
            default: null,
        },
    },
    computed: {
        redirectLocation(){
            const location = {};

            //set location path/name
            if(typeof this.redirectPath === 'string'){
                location.path = '/' + this.redirectPath;
            }
            else if(this.redirectPath && this.redirectPath.length){
                location.path = '/' + this.redirectPath.join('/');
            }
            else {
                location.name = constants.routeNames.home;
            }

            //set location query
            if(this.redirectQuery){
                location.query = this.redirectQuery;
                delete location.query.error;
                delete location.query.errorDesc;
            }

            return location;
        },
        displayTitle(){
            return this.$t('systemError');
        },
        displayMessage(){
            return undefined;
        },
        error(){
            if(!this.redirectQuery){
                return null;
            }

            return this.redirectQuery.error;
        },
        errorDesc(){
            if(!this.redirectQuery){
                return null;
            }

            return this.redirectQuery.errorDesc;
        },
    },
    methods: {
        retry(){
            this.$router.push(this.redirectLocation);
        },
    },
}
</script>
