import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonSeriesIndex from '@/views/common/series/index.vue';
import commonSeriesCreate from '@/views/common/series/create.vue';
import commonSeriesDetail from '@/views/common/series/detail.vue';
import commonSeriesEdit from '@/views/common/series/edit.vue';

import seriesTeamRoutes from './series/team.js';

export default {
    path: '/series/',
    component: pageFrame,
    children: [
        {
            name: 'commonSeriesIndex',
            path: constants.routePaths.index,
            component: commonSeriesIndex,
            meta: {
                permission: permissions.series.list,
            },
        },
        {
            name: 'commonSeriesCreate',
            path: constants.routePaths.create,
            component: commonSeriesCreate,
            meta: {
                permission: permissions.series.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.seriesId),
            component: pageFrame,
            children: [
                {
                    name: 'commonSeriesDetail',
                    path: constants.routePaths.index,
                    component: commonSeriesDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.seriesId]),
                    meta: {
                        permission: permissions.series.detail,
                    },
                },
                {
                    name: 'commonSeriesEdit',
                    path: constants.routePaths.edit,
                    component: commonSeriesEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.seriesId]),
                    meta: {
                        permission: permissions.series.update,
                    },
                },
                seriesTeamRoutes,
            ],
        },
    ],
};
