import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonUnionIndex from '@/views/common/union/index.vue';
import commonUnionCreate from '@/views/common/union/create.vue';
import commonUnionDetail from '@/views/common/union/detail.vue';
import commonUnionEdit from '@/views/common/union/edit.vue';

export default {
    path: '/union/',
    component: pageFrame,
    children: [
        {
            name: 'commonUnionIndex',
            path: constants.routePaths.index,
            component: commonUnionIndex,
            meta: {
                permission: permissions.unions.list,
            },
        },
        {
            name: 'commonUnionCreate',
            path: constants.routePaths.create,
            component: commonUnionCreate,
            meta: {
                permission: permissions.unions.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.unionId),
            component: pageFrame,
            children: [
                {
                    name: 'commonUnionDetail',
                    path: constants.routePaths.index,
                    component: commonUnionDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.unionId]),
                    meta: {
                        permission: permissions.unions.detail,
                    },
                },
                {
                    name: 'commonUnionEdit',
                    path: constants.routePaths.edit,
                    component: commonUnionEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.unionId]),
                    meta: {
                        permission: permissions.unions.update,
                    },
                },
            ],
        },
    ],
};
