<script>
import vselectElement from '../vselect.vue';
import EventTypeModel from '@/models/eventType.js';

/**
 * vselect element for EventType (Tournament Type) items
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'vselectEventTypeElement',
    extends: vselectElement,
    computed: {
        config(){
            const config = this.dataConfig || {};

            // add request method to config
            config.requestOptions = (text = '', offset = 0) => {
                //force reloading options when sport changes
                if (config.dataDependency?.sport){
                    this.addOptionReloadListener('dataAllValues.sport', true);
                }

                return this.$api.call.tournament.tournamentTypeSearch({
                    name: text || null,
                    sport: (config.dataDependency?.sport ? this.dataAllValues.sport : null) || null,
                    paging: this.usePagination(offset),
                }).then(result => ((result || {}).data || []).map(data => new EventTypeModel(data)));
            };

            //add request method for initial value information to config
            config.requestInitOptions = (initValues, property) => {
                switch(property){
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.tournament.tournamentTypeGetById(id)))
                            .then(results => results.map(data => new EventTypeModel(data)));
                }
            };

            return config;
        },
    },
};
</script>
