import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonTeamIndex from '@/views/common/team/index.vue';
import commonTeamCreate from '@/views/common/team/create.vue';
import commonTeamDetail from '@/views/common/team/detail.vue';
import commonTeamEdit from '@/views/common/team/edit.vue';

export default {
    path: '/team/',
    component: pageFrame,
    children: [
        {
            name: 'commonTeamIndex',
            path: constants.routePaths.index,
            component: commonTeamIndex,
            meta: {
                permission: permissions.teams.list,
            },
        },
        {
            name: 'commonTeamCreate',
            path: constants.routePaths.create,
            component: commonTeamCreate,
            meta: {
                permission: permissions.teams.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.teamId),
            component: pageFrame,
            children: [
                {
                    name: 'commonTeamDetail',
                    path: constants.routePaths.index,
                    component: commonTeamDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.teamId]),
                    meta: {
                        permission: permissions.teams.detail,
                    },
                },
                {
                    name: 'commonTeamEdit',
                    path: constants.routePaths.edit,
                    component: commonTeamEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.teamId]),
                    meta: {
                        permission: permissions.teams.update,
                    },
                },
            ],
        },
    ],
};
