import Model from '@/models/model.js';
import TeamMappingModel from '@/models/mapping/team.js';

/**
 * match mapping model class
 *
 * @author Jonathan Maher <j.maher@sportradar.com>
 */
export default class MatchMappingModel extends Model {
    /**
     * kick off
     *
     * @type {date|null}
     */
    kickOff = null;

    /**
     * mapping
     *
     * @type {array}
     */
    mapping = [];

    /**
     * teams
     *
     * @type {array}
     */
    teams = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.eqId = data.matchEqId || '';
            this.kickOff = this.toDate(data.kickOff) || null;
            this.mapping = data.mapping || [];
            this.teams = (data.teams || []).map(data => TeamMappingModel.from(data)).sort((a, b) => {
                const isTeam1A = a?.isTeam1;
                const isTeam1B = b?.isTeam1;

                if(isTeam1A && isTeam1B){
                    return 0;
                }

                return isTeam1A ? -1 : 1;
            });
        }
    }
}
