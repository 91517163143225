import UploadModel from '@/models/upload.js';

/**
 * image upload model class
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default class ImageUploadModel extends UploadModel {
    /**
     * get allowed format list
     *
     * @returns {string[]}
     */
    getFormats(){
        return ['.png', '.jpg'];
    }
}
