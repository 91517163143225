import Model from '@/models/model.js';
import MatchTeamModel from '@/models/matchTeam.js';

/**
 * match stats team model class
 */
export default class MatchStatsTeamModel extends Model {
    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.team = data.team ? MatchTeamModel.from(data.team) : {};
            this.statistics = data.statistics || null;
            this.extendedData = data.extendedData || null;
            this.players = data.players || null;
        }
    }
}
