<template>
    <div>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('name', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{ getHeadline('name', $t('name')) }}</label>
            <span v-else>{{ personMapping.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-if="showActionsRoleToggle"
                v-b-toggle="'accordion-' + personMapping.personRoleEqId"
            >
                <icon type="down-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 6)" class="flex-break d-lg-none"></div>
    </b-card>
    <b-collapse :id="'accordion-' + personMapping.personRoleEqId" accordion="personRoles" role="tabpanel" v-if="personMapping && !isHeadline">
        <h3 class="text-center mb-2 mt-2" v-if="personMappingProviderData.length">{{ $t('personMapping') }}</h3>
        <b-table head-variant="light" :items="personMappingProviderData" :fields="personMappingProviderFields" v-if="personMappingProviderData.length"/>
        <h3 class="text-center mb-2 mt-2" v-if="personRoleMappingProviderData.length">{{ $t('personRoleMapping') }}</h3>
        <b-table head-variant="light" :items="personRoleMappingProviderData" :fields="personMappingProviderFields" v-if="personRoleMappingProviderData.length"/>
        <div v-if="!personMappingProviderData.length && !personRoleMappingProviderData.length">No data</div>
    </b-collapse>
    </div>
</template>

<script>
import base from './base.vue';

import PersonMappingModel from '@/models/mapping/person.js';

/**
 * person mapping block component
 */
export default {
    name: 'venueRow',
    extends: base,
    props: {
        personMapping: {
            type: PersonMappingModel,
            default(){
                return new PersonMappingModel();
            }
        },
        showName: {
            type: Boolean,
            default: true,
        },
        /**
         * show role toggle button
         * clicking it will trigger "roleToggle" event
         */
        showActionsRoleToggle: {
            type: Boolean,
            default: true,
        },
    },
    data(){
        return {
            personMappingProviderFields: ['provider', 'id'],
        };
    },
    computed: {
        hasVisibleCircles(){
            return (this.showActionsRoleToggle);
        },
        personMappingProviderData(){
            if (!this.personMapping.personMapping) {
                return [];
            }
            return this.personMapping.personMapping.map(item => (
                {
                    provider: item.provider || '',
                    id: item.id || '',
                }
            ));
        },
        personRoleMappingProviderData(){
            if (!this.personMapping.personRoleMapping) {
                return [];
            }
            return this.personMapping.personRoleMapping.map(item => (
                {
                    provider: item.provider || '',
                    id: item.id || '',
                }
            ));
        },
    },
};
</script>
