import pageFrame from '@/views/frame.vue';

import administrationApiConsumerRoutes from './administration/apiConsumer.js';
import administrationLocationRoutes from './administration/location.js';
import administrationManagementPositionRoutes from './administration/managementPosition.js';
import administrationMergePersonnelRoutes from './administration/mergePersonnel.js';
import administrationPermissionGroupIdRoutes from './administration/permissionGroup.js';
import administrationRankingRoutes from './administration/ranking.js';
import administrationSeriesTypeRoutes from './administration/seriesType.js';
import administrationTournamentStageRoutes from './administration/tournamentStage.js';
import administrationTournamentStandingType from './administration/tournamentStandingType.js';
import administrationTournamentTeamStatusTypeRoutes from './administration/tournamentTeamStatusType.js';
import administrationTournamentTypeRoutes from './administration/tournamentType.js';
import administrationTournamentTypeGroupRoutes from './administration/tournamentTypeGroup.js';
import administrationUnmapped from './administration/unmapped.js';
import administrationUserRoutes from './administration/user.js';

export default {
    path: '/administration/',
    component: pageFrame,
    children: [
        administrationApiConsumerRoutes,
        administrationLocationRoutes,
        administrationManagementPositionRoutes,
        administrationMergePersonnelRoutes,
        administrationPermissionGroupIdRoutes,
        administrationRankingRoutes,
        administrationSeriesTypeRoutes,
        administrationTournamentStageRoutes,
        administrationTournamentStandingType,
        administrationTournamentTeamStatusTypeRoutes,
        administrationTournamentTypeRoutes,
        administrationTournamentTypeGroupRoutes,
        administrationUnmapped,
        administrationUserRoutes,
    ],
};
