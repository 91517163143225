<template>
    <b-card
        v-bind="rowAttributes"
    >
        <b-card-text v-bind="getColumnProps('position', 12, 6, 1)" v-if="showPosition">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('position')">{{ getHeadline('position', '#') }}</label>
            <span v-if="!isHeadline">{{ seriesTeamEvo.position }}</span>
        </b-card-text>

        <b-card-title v-bind="getColumnProps('teamNameShort', 2)" v-bind:title-tag="titleTag" v-if="showTeamNameShort">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamNameShort')" v-if="isHeadline">{{ getHeadline('teamNameShort', $t('teamNameShort')) }}</label>
            <span v-else>{{ seriesTeamEvo.teamName }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('tar', 12, 6, 1)" v-if="showTar">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('tar')" v-b-tooltip.hover.topleft="$t('totalAwardRanking')">{{ getHeadline('tar', 'TAR') }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ seriesTeamEvo.totalAwardRanking }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('aar', 12, 6, 1)" v-if="showAar">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('aar')" v-b-tooltip.hover.topleft="$t('averageAwardRanking')">{{ getHeadline('aar', 'AAR') }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ seriesTeamEvo.averageAwardRanking }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('pssr', 12, 6, 1)" v-if="showPssr">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('pssr')" v-b-tooltip.hover.topleft="$t('previousSeasonSeriesRanking')">{{ getHeadline('pssr', 'PSSR') }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ seriesTeamEvo.previousSeasonSeriesRanking }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('csr', 12, 6, 1)" v-if="showCsr">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('csr')" v-b-tooltip.hover.topleft="$t('currentSeriesRanking')">{{ getHeadline('csr', 'CSR') }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ seriesTeamEvo.currentSeriesRanking }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('eis', 12, 6, 1)" v-if="showEis">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('eis')" v-b-tooltip.hover.topleft="$t('evolutionInSeries')">{{ getHeadline('eis', 'EIS') }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ seriesTeamEvo.evolutionInSeries }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('smt', 12, 6, 1)" v-if="showSmt">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('smt')" v-b-tooltip.hover.topleft="$t('seasonMomentumTracker')">{{ getHeadline('smt', 'SMT') }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ seriesTeamEvo.seasonMomentumTracker }}</span>
        </b-card-text>

        <div v-bind="getColumnProps(null, 5)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from './base.vue';

import SeriesTeamEvolutionModel from '@/models/seriesTeamEvolution.js';

/**
 * series team evolution block component
 */
export default {
    name: 'seriesTeamEvolutionRow',
    extends: base,
    props: {
        seriesTeamEvo: {
            type: SeriesTeamEvolutionModel,
            required: false,
            default(){
                return new SeriesTeamEvolutionModel();
            }
        },

        showPosition: {
            type: Boolean,
            default: true,
        },
        showTeamNameShort: {
            type: Boolean,
            default: true,
        },
        showTar: {
            type: Boolean,
            default: true,
        },
        showAar: {
            type: Boolean,
            default: true,
        },
        showPssr: {
            type: Boolean,
            default: true,
        },
        showCsr: {
            type: Boolean,
            default: true,
        },
        showEis: {
            type: Boolean,
            default: true,
        },
        showSmt: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonTeamDetail',
                params: Object.assign({
                    dataTeamId: this.team.id,
                }, this.detailLinkParams),
            };
        },
        customElementClasses(){
            return {
                teamRow: true,
            };
        },
    },
};
</script>
