import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import commonMatchIndex from '@/views/common/match/index.vue';
import commonMatchCreate from '@/views/common/match/create.vue';
import commonMatchDetail from '@/views/common/match/detail.vue';
import commonMatchEdit from '@/views/common/match/edit.vue';
import commonMatchReport from '@/views/common/match/report.vue';
import commonMatchStats from '@/views/common/match/stats.vue';
import commonMatchTimeline from '@/views/common/match/timeline.vue';
import commonMatchHighlights from '@/views/common/match/highlights.vue';
import commonMatchTeamsheets from '@/views/common/match/teamsheets.vue';
import commonMatchTeamsheetSummary from '@/views/common/match/teamsheetSummary.vue';

export default {
    path: '/match/',
    component: pageFrame,
    children: [
        {
            name: 'commonMatchIndex',
            path: constants.routePaths.index,
            component: commonMatchIndex,
            props: (route) => ({ teamEqId: route.query.teamEqId }),
            meta: {
                permission: permissions.matches.list,
            },
        },
        {
            name: 'commonMatchCreate',
            path: constants.routePaths.create,
            component: commonMatchCreate,
            meta: {
                permission: permissions.matches.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.matchId),
            component: pageFrame,
            children: [
                {
                    name: 'commonMatchDetail',
                    path: constants.routePaths.index,
                    component: commonMatchDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.matchId]),
                    meta: {
                        permission: permissions.matches.detail,
                    },
                },
                {
                    name: 'commonMatchEdit',
                    path: constants.routePaths.edit,
                    component: commonMatchEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.matchId]),
                    meta: {
                        permission: permissions.matches.update,
                    },
                },
                {
                    name: 'commonMatchReport',
                    path: constants.routePaths.report,
                    component: commonMatchReport,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.matchId]),
                    meta: {
                        permission: permissions.matches.report,
                    },
                },
                {
                    name: 'commonMatchStats',
                    path: constants.routePaths.stats,
                    component: commonMatchStats,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.matchId]),
                    meta: {
                        permission: permissions.matches.stats,
                    },
                },
                {
                    name: 'commonMatchTimeline',
                    path: constants.routePaths.timeline,
                    component: commonMatchTimeline,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.matchId]),
                    meta: {
                        permission: permissions.matches.timeline,
                    },
                },
                {
                    name: 'commonMatchHighlights',
                    path: constants.routePaths.highlights,
                    component: commonMatchHighlights,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.matchId]),
                    meta: {
                        permission: permissions.matches.highlights,
                    },
                },
                {
                    name: 'commonMatchTeamsheets',
                    path: constants.routePaths.teamsheets,
                    component: commonMatchTeamsheets,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.matchId]),
                    meta: {
                        permission: permissions.matches.teamsheets,
                    },
                },
                {
                    name: 'commonMatchTeamsheetSummary',
                    path: constants.routePaths.teamsheetSummary,
                    component: commonMatchTeamsheetSummary,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.matchId]),
                    meta: {
                        permission: permissions.matches.teamsheets,
                    },
                },
            ],
        },
    ],
};
