import constants from '@/constants.js';
import routerTools from '@/router/tools.js';
import pageFrame from '@/views/frame.vue';
import permissions from '@/permissions.js';

import reviewSeriesHub from '@/views/review/series/hub.vue';
import reviewSeriesIndex from '@/views/review/series/index.vue';
import reviewSeriesDetail from '@/views/review/series/detail.vue';

export default {
    path: 'series/',
    component: pageFrame,
    children: [
        {
            name: 'reviewSeriesHub',
            path: constants.routePaths.index,
            component: reviewSeriesHub,
            meta: {
                permission: permissions.reviews.series.list,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.seriesDeclarationId),
            component: pageFrame,
            children: [
                {
                    name: 'reviewSeriesIndex',
                    path: constants.routePaths.index,
                    component: reviewSeriesIndex,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.seriesDeclarationId]),
                    meta: {
                        permission: permissions.reviews.series.list,
                    },
                },
                {
                    path: routerTools.propRoute(constants.routeProps.teamDeclarationId),
                    component: pageFrame,
                    children: [
                        {
                            name: 'reviewSeriesDetail',
                            path: constants.routePaths.index,
                            component: reviewSeriesDetail,
                            props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.seriesDeclarationId, constants.routeProps.teamDeclarationId]),
                            meta: {
                                permission: permissions.reviews.series.detail,
                            },
                        },
                        routerTools.createFallbackRedirect(),
                    ],
                },
            ],
        },
    ],
};
