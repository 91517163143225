<script>
import formComponent from '@/components/form.vue';

const levels = {
    categories: 'SeriesAndTournament',
    matches: 'Matches',
};

/**
 * user rims profile display form
 */
export default {
    name: 'userRimsProfileDisplayForm',
    extends: formComponent,
    props: {
        matches: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementsConfig(){
            return [
                this.createFormRow([
                    {
                        id: 'accessType',
                        type: 'vselect',
                        options: [
                            {
                                id: levels.categories,
                                label: this.$t('seriesAndTournaments'),
                            },
                            {
                                id: levels.matches,
                                label: this.$tc('match'),
                            },
                        ],
                        label: this.$t('accessLevel'),
                        disabled: true,
                    },
                    {
                        id: 'allowAccessToAllSportsDisplay',
                        type: 'text',
                        label: this.$tc('sport', 2),
                        dependency: 'allowAccessToAllSports && !this.isMatchesOnly',
                        disabled: true,
                    },
                    {
                        id: 'accessToAllSeriesDisplay',
                        type: 'text',
                        label: this.$tc('series', 1),
                        dependency: 'allowAccessToAllSeries === true',
                        disabled: true,
                    },
                    {
                        id: 'accessToAllTournamentsDisplay',
                        type: 'text',
                        label: this.$tc('tournament', 2),
                        dependency: 'allowAccessToAllTournaments === true',
                        disabled: true,
                    },
                    {
                        id: 'allowAccessToAllTournamentTypesDisplay',
                        type: 'text',
                        label: this.$tc('tournamentTypes'),
                        dependency: 'allowAccessToAllTournamentTypes === true',
                        disabled: true,
                    },
                    {
                        id: 'authorisedSports',
                        type: 'vselectSport',
                        placeholder: this.$t('select'),
                        label: this.$tc('sport', 2),
                        closeOnSelect: false,
                        multiple: true,
                        dependency: '!allowAccessToAllSports && !this.isMatchesOnly && authorisedSports.length',
                        disabled: true,
                    },
                    {
                        id: 'authorisedSeriesEqIds',
                        type: 'vselectSeries',
                        placeholder: this.$t('select'),
                        label: this.$tc('series', 2),
                        closeOnSelect: false,
                        multiple: true,
                        valueProperty: 'eqId',
                        dependency: '!allowAccessToAllSeries && !this.isMatchesOnly && authorisedSeriesEqIds.length',
                        disabled: true,
                    },
                    {
                        id: 'authorisedTournamentEqIds',
                        type: 'vselectTournament',
                        placeholder: this.$t('select'),
                        label: this.$tc('tournament', 2),
                        closeOnSelect: false,
                        multiple: true,
                        valueProperty: 'eqId',
                        dependency: '!allowAccessToAllTournaments && !this.isMatchesOnly && authorisedTournamentEqIds.length',
                        disabled: true,
                    },
                    {
                        id: 'authorisedTournamentTypeEqIds',
                        type: 'vselectTournamentType',
                        placeholder: this.$t('select'),
                        label: this.$t('tournamentTypes'),
                        closeOnSelect: false,
                        multiple: true,
                        valueProperty: 'eqId',
                        dependency: '!allowAccessToAllTournamentTypes && !this.isMatchesOnly && authorisedTournamentTypeEqIds.length',
                        disabled: true,
                    },
                    {
                        id: 'allowAccessToAllMatchesDisplay',
                        type: 'text',
                        label: this.$tc('match', 2),
                        disabled: this.readonly,
                    },
                ]),
                {
                    id: 'buttonsMobile',
                    type: 'slot',
                    class: 'd-flex d-lg-none flex-wrap',
                },
                {
                    id: 'matches',
                    type: 'slot',
                    label: this.$tc('match', 2),
                    dependency: 'allowAccessToAllMatches === false',
                },
            ];
        },
        initValues(){
            return Object.assign({}, this.values, {
                isMatchesOnly: this.model.accessType === levels.matches,
            });
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>
